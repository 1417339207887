"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addRepair = addRepair;
exports.aloneRepair = aloneRepair;
exports.changeRepair = changeRepair;
exports.deleRepair = deleRepair;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询结算信息
function getLists(data) {
  return (0, _request.default)({
    url: 'order/query/repair/all',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增维修记录
function addRepair(data) {
  return (0, _request.default)({
    url: 'order/add/repair',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//根据ID查询维修记录
function aloneRepair(id) {
  return (0, _request.default)({
    url: 'order/query/repair/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改维修记录
function changeRepair(data) {
  return (0, _request.default)({
    url: 'order/repair',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//根据ID删除维修记录
function deleRepair(id) {
  return (0, _request.default)({
    url: 'order/repair/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}