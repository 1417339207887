"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _faq = require("../../api/faq.js");
var _index = require("../../utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      value: "",
      tableData: [],
      tableData1: [],
      activeName: "first",
      formLabelWidth: "120px",
      dialogFormVisible: false,
      faq: [],
      dialogFormVisibles: false,
      loading: false,
      isDisable: false,
      flag: false,
      addform: {
        seq: "",
        line: "2",
        titleCn: "",
        titleEn: "",
        titleJa: "",
        type: ""
      },
      addRules: {
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "blur"
        }]
      },
      changeform: {
        seq: "",
        titleCn: "",
        titleEn: "",
        titleJa: "",
        type: ""
      },
      changeRules: {
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "blur"
        }]
      },
      changeid: "",
      form: {
        line: "",
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      forms: {
        line: "",
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      types: [{
        value: "0",
        name: "FAQ"
      }, {
        value: "1",
        name: "故障排除"
      }],
      option: [{
        type: 0,
        name: "上线"
      }, {
        type: 1,
        name: "预览"
      }, {
        type: 2,
        name: "下线"
      }],
      total: 0,
      currentPage: 1,
      isShow: false
    };
  },
  ceated: function ceated() {},
  mounted: function mounted() {
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //请求列表数据
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _faq.getLists)(_this.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.forms.pageNo = 1;
            _this.currentPage = 1;
            (0, _faq.getLists)(_this.forms).then(function (res) {
              _this.tableData = res.voList;
              _this.total = res.total;
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    //判断类型
    stateFormat: function stateFormat(row) {
      if (row.type === "0") {
        return "FAQ";
      } else {
        return "故障排除";
      }
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line == '1') {
        return "预览";
      } else {
        return "下线";
      }
    },
    //查询
    search: function search() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.line = this.form.line;
      this.forms.type = this.form.type;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.line = "";
      this.currentPage = 1;
      this.currentPage = 1;
      this.form.type = "";
      this.forms.line = "";
      this.forms.type = "";
      this.forms.pageNo = 1;
      this.getList();
    },
    //新增
    add: function add() {
      this.dialogFormVisible = true;
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/faq/title/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this2 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
      } else if (!/(^[1-9]\d*$)/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
      } else {
        (0, _faq.changeSeq)({
          seq: val.seq
        }, val.id).then(function (res) {
          if (res.success) {
            _this2.msgTip("success", "修改成功");
            _this2.isShow = false;
            _this2.getList();
          } else {
            _this2.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //新增关闭
    addClose: function addClose() {
      this.dialogFormVisible = false;
      this.$refs.addform.resetFields();
      this.addform.seq = "";
    },
    //新增确定
    sure: function sure() {
      var _this3 = this;
      if (!/(^[1-9]\d*$)/.test(this.addform.seq) && this.addform.seq !== "") {
        this.msgTip("warning", "序号只能为正整数");
        return false;
      }
      this.$refs.addform.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          _this3.isDisable = false;
          (0, _faq.addList)(_this3.addform).then(function (res) {
            if (res.success) {
              _this3.$message({
                type: "success",
                message: "添加成功",
                center: true
              });
              _this3.dialogFormVisible = false;
              _this3.getList();
              _this3.addform.seq = "";
              (0, _faq.dataManipulation)(_this3.tableData);
            } else {
              _this3.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
            }
          });
        } else {
          _this3.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //修改
    change: function change(id) {
      var _this4 = this;
      this.dialogFormVisibles = true;
      this.changeid = id;
      (0, _faq.aloneTit)(id).then(function (res) {
        if (res.success) {
          _this4.changeform = res.voT;
        }
      });
    },
    //修改关闭
    changeClose: function changeClose() {
      this.dialogFormVisibles = false;
      this.$refs.changeform.resetFields();
    },
    //修改确定
    keep: function keep() {
      var _this5 = this;
      this.$refs.changeform.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          _this5.isDisable = false;
          (0, _faq.changeList)(_this5.changeform, _this5.changeid).then(function (res) {
            if (res.success) {
              _this5.msgTip("success", "修改成功");
              _this5.dialogFormVisibles = false;
              _this5.getList();
              (0, _faq.dataManipulation)(_this5.tableData);
            } else {
              _this5.msgTip("error", res.errorMsg);
              // this.isRepeat = false;
              _this5.isDisable = true;
            }
          });
        } else {
          _this5.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //上线
    online: function online(row) {
      var _this6 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _faq.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "上线成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this7 = this;
      if (row.line == '1') {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _faq.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "操作成功");
            _this7.getList();
          } else {
            _this7.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(row) {
      var _this8 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _faq.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "下线成功");
            _this8.getList();
          } else {
            _this8.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this9 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _faq.deleTit)(id).then(function (res) {
          if (res.success) {
            _this9.msgTip("success", "删除成功");
            _this9.getList();
          } else {
            _this9.msgTip("error", res.errorMsg);
          }
        });
      });
    }
  }
};