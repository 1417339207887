"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _blog = require("../../api/blog.js");
var _index = require("../../utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      formLabelWidth: "130px",
      isDisable: false,
      addApprove: {
        seq: ""
      },
      dialogFormVisible1: false,
      titleCns: [],
      lines: [{
        line: "0",
        label: "上线"
      }, {
        line: "1",
        label: "预览"
      }, {
        line: "2",
        label: "下线"
      }],
      lineStatus: {
        line: ""
      },
      recommendFlags: [{
        recommendFlag: "",
        label: "所有"
      }, {
        recommendFlag: "Y",
        label: "推荐"
      }, {
        recommendFlag: "N",
        label: "不推荐"
      }],
      isFlag: true,
      currentPage: this.$route.query.pageNo ? this.$route.query.pageNo : 1,
      total: 0,
      loading: false,
      form: {
        language: "",
        line: "",
        titleId: "",
        recommendFlag: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      form1: {
        language: "",
        line: "",
        titleId: "",
        recommendFlag: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      forms: {
        language: "",
        line: "",
        titleCn: "",
        recommendFlag: "",
        pageNo: 0,
        pageSize: 0,
        sortField: "",
        sortRules: ""
      },
      recommendParam: {
        recommendFlag: "",
        id: ""
      },
      value: "",
      value1: true,
      value2: false,
      tableData: [{
        name: "123",
        remark: "123"
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.pageNo) {
      (0, _blog.queryBlog)({
        language: "",
        line: "",
        pageNo: 0,
        pageSize: 0,
        recommendFlag: "",
        sortField: "",
        sortRules: "",
        titleCn: ""
      }).then(function (res) {
        _this.titleCns = res.voList;
      });
      this.form.line = this.$route.query.line ? this.$route.query.line : '';
      this.form.titleId = this.$route.query.titleId ? this.$route.query.titleId : '';
      this.form.recommendFlag = this.$route.query.recommendFlag ? this.$route.query.recommendFlag : '';
      this.form.pageNo = this.$route.query.pageNo ? this.$route.query.pageNo : 1;
    }
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //修改关闭按钮
    changeClose: function changeClose() {
      this.dialogFormVisible1 = false;
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/blog/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this2 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
        return;
      }
      if (!/^[1-9]{1,}[\d]*$/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
        return;
      }
      this.addApprove.seq = val.seq;
      (0, _blog.updateSeq)(this.addApprove, val.id).then(function (res) {
        if (res.success) {
          _this2.msgTip("success", "修改成功");
          _this2.empty();
          _this2.isShow = false;
        } else {
          _this2.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    //判断上下线
    lineFormatter: function lineFormatter(row, column) {
      if (row.line == "0") {
        return "上线";
      } else if (row.line == "1") {
        return "预览";
      } else if (row.line == "2") {
        return "下线";
      }
    },
    languageFormatter: function languageFormatter(row, column) {
      if (row.language.toLocaleLowerCase() == "zh-cn") {
        return "中文版";
      } else if (row.language.toLocaleLowerCase() == "en") {
        return "英文版";
      } else if (row.language.toLocaleLowerCase() == "ja") {
        return "日文版";
      }
    },
    //判断上下线
    recommendFlagformatter: function recommendFlagformatter(row, column) {
      if (row.recommendFlag == "Y") {
        return "推荐";
      } else if (row.recommendFlag == "N") {
        return "不推荐";
      }
    },
    //删除
    dele: function dele(v) {
      var _this3 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _blog.delBlog)(v.id).then(function (res) {
          if (res.success) {
            _this3.msgTip("success", "操作成功");
            _this3.getList();
          } else {
            _this3.msgTip("warning", res.errorMsg);
          }
        });
      });
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //下线
    offline: function offline(v) {
      var _this4 = this;
      if (v.line == 2) {
        this.msgTip("error", "已是下线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 2;
        (0, _blog.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "操作成功");
            _this4.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "2",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this4.isFlag = true;
          } else {
            _this4.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this4.isFlag = true;
          }
        });
      }
    },
    //预览
    preview: function preview(v) {
      var _this5 = this;
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 1;
        (0, _blog.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "操作成功");
            _this5.empty();
            _this5.isFlag = true;
          } else {
            _this5.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this5.isFlag = true;
          }
        });
      }
    },
    empty: function empty() {
      var _this6 = this;
      this.form1.pageNo = 1;
      this.currentPage = 1;
      this.form.line = "";
      this.form.recommendFlag = "";
      this.form.titleId = "";
      this.form1.line = "";
      this.form1.recommendFlag = "";
      this.form1.titleId = "";
      window.location.href = window.location.href.split('?')[0];
      (0, _blog.queryBlog)({
        language: "",
        line: "",
        pageNo: 0,
        pageSize: 0,
        recommendFlag: "",
        sortField: "",
        sortRules: "",
        titleCn: ""
      }).then(function (res) {
        _this6.titleCns = res.voList;
      });
      this.getList();
    },
    getList: function getList() {
      var _this7 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _blog.queryBlog)(_this7.form1).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this7.form1.pageNo = 1;
            _this7.currentPage = 1;
            (0, _blog.queryBlog)(_this7.form1).then(function (res) {
              _this7.tableData = res.voList;
              _this7.tableData.map(function (element) {
                if (element.recommendFlag == "Y") {
                  _this7.$set(element, "recommendFlagType", true);
                } else if (element.recommendFlag == "N") {
                  _this7.$set(element, "recommendFlagType", false);
                }
              });
              _this7.total = Number(res.total);
              _this7.loading = false;
            });
          }
          _this7.tableData = res.voList;
          _this7.tableData.map(function (element) {
            if (element.recommendFlag == "Y") {
              _this7.$set(element, "recommendFlagType", true);
            } else if (element.recommendFlag == "N") {
              _this7.$set(element, "recommendFlagType", false);
            }
          });
          _this7.total = Number(res.total);
          _this7.loading = false;
        });
        (0, _blog.queryBlog)(_this7.forms).then(function (res) {
          _this7.titleCns = res.voList;
        });
      }, 500);
    },
    //上线
    online: function online(v) {
      var _this8 = this;
      if (v.line == 0) {
        this.msgTip("error", "已是上线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 0;
        (0, _blog.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "操作成功");
            _this8.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "0",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this8.isFlag = true;
          } else {
            _this8.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this8.isFlag = true;
          }
        });
      }
    },
    //查询
    search: function search() {
      var _this9 = this;
      this.loading = true;
      this.currentPage = 1;
      this.form1.pageNo = 1;
      this.form1.line = this.form.line;
      this.form1.titleId = this.form.titleId;
      setTimeout(function () {
        (0, _blog.queryBlog)({
          line: _this9.form.line,
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          language: "",
          sortRules: "",
          titleId: _this9.form.titleId,
          recommendFlag: _this9.form.recommendFlag
        }).then(function (res) {
          _this9.form.pageNo = 1;
          _this9.tableData = res.voList;
          _this9.tableData.map(function (element) {
            if (element.recommendFlag == "Y") {
              _this9.$set(element, "recommendFlagType", true);
            } else if (element.recommendFlag == "N") {
              _this9.$set(element, "recommendFlagType", false);
            }
          });
          _this9.total = res.total;
        });
        _this9.currentPage = 1;
        _this9.loading = false;
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form1.pageNo = currentPage;
      this.getList();
    },
    recommendeTrue: function recommendeTrue(v) {
      var _this10 = this;
      this.recommendParam.recommendFlag = "N";
      this.recommendParam.id = v.id;
      (0, _blog.updateRecommend)(this.recommendParam).then(function (res) {
        if (res.success) {
          _this10.getList();
          _this10.msgTip("success", "操作成功");
        } else {
          _this10.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    recommendeFalse: function recommendeFalse(v) {
      var _this11 = this;
      this.recommendParam.recommendFlag = "Y";
      this.recommendParam.id = v.id;
      (0, _blog.updateRecommend)(this.recommendParam).then(function (res) {
        if (res.success) {
          _this11.getList();
          _this11.msgTip("success", "操作成功");
        } else {
          _this11.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "addblog",
        query: {
          line: this.form.line,
          titleId: this.form.titleId,
          recommendFlag: this.form.recommendFlag,
          pageNo: this.form.pageNo
        }
      });
    },
    //修改
    change: function change(v) {
      this.$router.push({
        path: "changeblog",
        query: {
          id: v.id,
          line: this.form.line,
          titleId: this.form.titleId,
          recommendFlag: this.form.recommendFlag,
          pageNo: this.form.pageNo
        }
      });
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}