"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _abouts = require("../../api/abouts.js");
var _index = require("../../utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      value: "",
      show: "",
      currentPage: this.$route.query.cur ? this.$route.query.cur : 1,
      total: 0,
      value1: true,
      tableData: [],
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      form: {
        pageNo: 1,
        pageSize: 10,
        id: "",
        line: ""
      },
      forms: {
        pageNo: 1,
        pageSize: 10,
        id: "",
        line: ""
      },
      lineForm: {
        line: ""
      },
      isShow: false
    };
  },
  created: function created() {
    this.getList();
  },
  mounted: function mounted() {
    if (this.$route.query.cur) {
      this.form.line = this.$route.query.line;
      this.forms.line = this.$route.query.line;
      this.form.pageNo = this.$route.query.cur;
      this.forms.pageNo = this.$route.query.cur;
    }
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //查询
    search: function search() {
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.line = this.form.line;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.forms.line = "";
      this.currentPage = 1;
      this.form.line = "";
      this.forms.pageNo = 1;
      window.location.href = window.location.href.split('?')[0];
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _abouts.queryAboutAll)(this.forms).then(function (res) {
        if (res.voList == null || res.voList == "") {
          _this.forms.pageNo = 1;
          _this.currentPage = 1;
          (0, _abouts.queryAboutAll)(_this.forms).then(function (res) {
            _this.tableData = res.voList;
            _this.total = res.total;
            _this.loading = false;
          });
        }
        _this.tableData = res.voList;
        _this.total = res.total;
        _this.loading = false;
      });
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/about/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this2 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
      } else if (!/(^[1-9]\d*$)/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
      } else {
        (0, _abouts.updateSeq)({
          seq: val.seq
        }, val.id).then(function (res) {
          if (res.success) {
            _this2.msgTip("success", "修改成功");
            _this2.isShow = false;
            _this2.getList();
          } else {
            _this2.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "addabout",
        query: {
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changeabout",
        query: {
          id: id,
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //上线
    online: function online(row) {
      var _this3 = this;
      if (row.line == "0" || row.line == 0) {
        this.msgTip("error", "该数据已上线");
        return;
      }
      //上线
      this.lineForm.line = "0";
      (0, _abouts.updateAboutLine)(this.lineForm, row.id).then(function (res) {
        if (res.success) {
          _this3.$message({
            type: "success",
            message: "上线成功",
            center: true
          });
          _this3.lineForm.line = "";
          _this3.getList();
        } else {
          //上线失败
          _this3.$message({
            type: "warning",
            message: "操作失败",
            center: true
          });
        }
      });
    },
    //预览
    preview: function preview(row) {
      var _this4 = this;
      if (row.line == "1" || row.line == 1) {
        this.msgTip("error", "该数据已预览");
        return;
      }
      //预览
      this.lineForm.line = "1";
      (0, _abouts.updateAboutLine)(this.lineForm, row.id).then(function (res) {
        if (res.success) {
          _this4.$message({
            type: "success",
            message: "操作成功",
            center: true
          });
          _this4.lineForm.line = "";
          _this4.getList();
        } else {
          //下线失败
          _this4.$message({
            type: "warning",
            message: "操作失败",
            center: true
          });
        }
      });
    },
    //下线 
    offline: function offline(row) {
      var _this5 = this;
      if (row.line == "2" || row.line == 2) {
        this.msgTip("error", "该数据已下线");
        return;
      }
      //下线
      this.lineForm.line = "2";
      (0, _abouts.updateAboutLine)(this.lineForm, row.id).then(function (res) {
        if (res.success) {
          _this5.$message({
            type: "success",
            message: "下线成功",
            center: true
          });
          _this5.lineForm.line = "";
          _this5.getList();
        } else {
          //下线失败
          _this5.$message({
            type: "warning",
            message: "操作失败",
            center: true
          });
        }
      });
    },
    //删除
    dele: function dele(id) {
      var _this6 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _abouts.deleAbouts)(id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "删除成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    //判断类型
    state: function state(row) {
      if (row.line == "0") {
        return "上线";
      } else if (row.line == "1") {
        return "预览";
      } else {
        return "下线";
      }
    },
    //获取页码
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};