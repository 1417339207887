"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addtrends = addtrends;
exports.deletetrends = deletetrends;
exports.price = price;
exports.priceRange = priceRange;
exports.updatePrice = updatePrice;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询价格区间信息
function priceRange(data) {
  return (0, _request.default)({
    url: '/cms/priceRange/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加价格区间信息
function addtrends(data) {
  return (0, _request.default)({
    url: '/cms/priceRange',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除价格区间信息
function deletetrends(id) {
  return (0, _request.default)({
    url: '/cms/priceRange/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//查询单个价格区间信息
function price(id) {
  return (0, _request.default)({
    url: '/cms/priceRange/' + id,
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改价格区间信息
function updatePrice(data, id) {
  return (0, _request.default)({
    url: '/cms/priceRange/' + id,
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}