"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.split");
var _quill = _interopRequireDefault(require("../common/quill.vue"));
var _upload = require("../../api/upload.js");
var _productMess = require("../../api/productMess.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    quill: _quill.default
  },
  data: function data() {
    return {
      value: "",
      type: [],
      form: {
        contentMobileCn: "",
        contentMobileEn: "",
        contentMobileJa: "",
        contentPcCn: "",
        contentPcEn: "",
        contentPcJa: "",
        descriptionCn: "",
        descriptionEn: "",
        descriptionJa: "",
        titleCn: "",
        titleEn: "",
        titleJa: "",
        type: ""
      },
      formLabelWidth: "120px",
      formRules: {
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "change"
        }]
      },
      isDisable: false,
      uploadUrl: "".concat(process.env.VUE_APP_BASE_API, "cms/picture/upload"),
      uplaod_data: {
        "x-access-token": window.localStorage.getItem("token")
      },
      fileList: [],
      fileListEn: [],
      fileListJa: [],
      fileListMen: [],
      fileListMcn: [],
      fileListMja: [],
      fileLists: [],
      fileListsEn: [],
      fileListsJa: [],
      fileListsMen: [],
      fileListsMcn: [],
      fileListsMja: [],
      dialogVisible: false,
      fileupload: "",
      dialogImageUrl: "",
      dialogVisibleEn: false,
      fileuploadEn: "",
      dialogImageUrlEn: "",
      dialogVisibleJa: false,
      fileuploadJa: "",
      dialogImageUrlJa: "",
      dialogVisibleMen: false,
      fileuploadMen: "",
      dialogImageUrlMen: "",
      dialogVisibleMcn: false,
      fileuploadMcn: "",
      dialogImageUrlMcn: "",
      dialogVisibleMja: false,
      fileuploadMja: "",
      dialogImageUrlMja: "",
      url: '',
      urlen: '',
      urlja: '',
      urlMcn: '',
      urlMen: '',
      urlMja: '',
      id: "",
      imageUrl1: ""
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.id = this.$route.query.id;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
    (0, _productMess.aloneproduct)(this.id).then(function (res) {
      _this.form = res.voT;
      _this.fileList = res.voT.contentPcCn.map(function (v) {
        _this.url = v.split('===')[0];
        return {
          name: "",
          url: _this.url,
          href: v
        };
      });
      _this.fileListEn = res.voT.contentPcEn.map(function (v) {
        _this.urlen = v.split('===')[0];
        return {
          name: "",
          url: _this.urlen,
          href: v
        };
      });
      _this.fileListJa = res.voT.contentPcJa.map(function (v) {
        _this.urlja = v.split('===')[0];
        return {
          name: "",
          url: _this.urlja,
          href: v
        };
      });
      _this.fileListMcn = res.voT.contentMobileCn.map(function (v) {
        _this.urlMcn = v.split('===')[0];
        return {
          name: "",
          url: _this.urlMcn,
          href: v
        };
      });
      _this.fileListMen = res.voT.contentMobileEn.map(function (v) {
        _this.urlMen = v.split('===')[0];
        return {
          name: "",
          url: _this.urlMen,
          href: v
        };
      });
      _this.fileListMja = res.voT.contentMobileJa.map(function (v) {
        _this.urlMja = v.split('===')[0];
        return {
          name: "",
          url: _this.urlMja,
          href: v
        };
      });
    });
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      this.fileList = fileList;
      // this.fileLists.push(res.voT.downloadUrl)
    },
    handleRemoves: function handleRemoves(file, fileList) {
      var _this2 = this;
      var mutiUrls = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this2.form.contentPcCn = mutiUrls.join(",");
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var str = [];
      this.fileList.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileList = str;
    },
    changePicture: function changePicture(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      this.fileupload = this.fileList.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccess: function uploadSuccess(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileList.splice(this.fileupload, 1, file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    //pc内容en
    handleUploadSuccessEn: function handleUploadSuccessEn(res, file, fileList) {
      this.fileListEn = fileList;
      //this.fileListsEn.push(res.voT.downloadUrl);
    },
    handleRemovesEn: function handleRemovesEn(file, fileList) {
      var _this3 = this;
      var mutiUrls = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this3.form.contentPcEn = mutiUrls.join(",");
      });
    },
    handleRemoveEn: function handleRemoveEn(file, fileList) {
      var str = [];
      this.fileListEn.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListEn = str;
    },
    changePictureEn: function changePictureEn(file) {
      this.dialogVisibleEn = true;
      this.dialogImageUrlEn = file.url;
      this.fileuploadEn = this.fileListEn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessEn: function uploadSuccessEn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListEn.splice(this.fileuploadEn, 1, file);
      this.dialogImageUrlEn = file.url;
      this.dialogVisibleEn = false;
    },
    closeEn: function closeEn() {
      this.dialogVisibleEn = false;
    },
    //pc内容ja
    handleUploadSuccessJa: function handleUploadSuccessJa(res, file, fileList) {
      this.fileListJa = fileList;
    },
    handleRemovesJa: function handleRemovesJa(file, fileList) {
      var _this4 = this;
      var mutiUrls = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this4.form.contentPcJa = mutiUrls.join(",");
      });
    },
    handleRemoveJa: function handleRemoveJa(file, fileList) {
      var str = [];
      this.fileListJa.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListJa = str;
    },
    changePictureJa: function changePictureJa(file) {
      this.dialogVisibleJa = true;
      this.dialogImageUrlJa = file.url;
      this.fileuploadJa = this.fileListJa.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessJa: function uploadSuccessJa(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListJa.splice(this.fileuploadJa, 1, file);
      this.dialogImageUrlJa = file.url;
      this.dialogVisibleJa = false;
    },
    closeJa: function closeJa() {
      this.dialogVisibleJa = false;
    },
    //移动端内容cn
    handleUploadSuccessMcn: function handleUploadSuccessMcn(res, file, fileList) {
      this.fileListMcn = fileList;
    },
    handleRemovesMcn: function handleRemovesMcn(file, fileList) {
      var _this5 = this;
      var mutiUrls = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this5.form.contentMobileCn = mutiUrls.join(",");
      });
    },
    handleRemoveMcn: function handleRemoveMcn(file, fileList) {
      var str = [];
      this.fileListMcn.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListMcn = str;
    },
    changePictureMcn: function changePictureMcn(file) {
      this.dialogVisibleMcn = true;
      this.dialogImageUrlMcn = file.url;
      this.fileuploadMcn = this.fileListMcn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMcn: function uploadSuccessMcn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListMcn.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMcn = file.url;
      this.dialogVisibleMcn = false;
    },
    closeMcn: function closeMcn() {
      this.dialogVisibleMcn = false;
    },
    //移动端内容en
    handleUploadSuccessMen: function handleUploadSuccessMen(res, file, fileList) {
      this.fileListMen = fileList;
      //this.fileListsMen.push(res.voT.downloadUrl);
    },
    handleRemovesMen: function handleRemovesMen(file, fileList) {
      var _this6 = this;
      var mutiUrls = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this6.form.contentMobileEn = mutiUrls.join(",");
      });
    },
    handleRemoveMen: function handleRemoveMen(file, fileList) {
      var str = [];
      this.fileListMen.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListEn = str;
    },
    changePictureMen: function changePictureMen(file) {
      this.dialogVisibleMen = true;
      this.dialogImageUrlMen = file.url;
      this.fileuploadMen = this.fileListMen.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMen: function uploadSuccessMen(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListMen.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMen = file.url;
      this.dialogVisibleMen = false;
    },
    closeMen: function closeMen() {
      this.dialogVisibleMen = false;
    },
    //移动端内容ja
    handleUploadSuccessMja: function handleUploadSuccessMja(res, file, fileList) {
      this.fileListMja = fileList;
      //this.fileListsMja.push(res.voT.downloadUrl);
    },
    handleRemovesMja: function handleRemovesMja(file, fileList) {
      var _this7 = this;
      var mutiUrls = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this7.form.contentMobileJa = mutiUrls.join(",");
      });
    },
    handleRemoveMja: function handleRemoveMja(file, fileList) {
      var str = [];
      this.fileListMja.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListMja = str;
    },
    changePictureMja: function changePictureMja(file) {
      this.dialogVisibleMja = true;
      this.dialogImageUrlMja = file.url;
      this.fileuploadMja = this.fileListMja.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMja: function uploadSuccessMja(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListMja.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMja = file.url;
      this.dialogVisibleMja = false;
    },
    closeMja: function closeMja() {
      this.dialogVisibleMja = false;
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //提交
    submit: function submit() {
      var _this8 = this;
      if (this.fileList == [] && this.form.contentPcCn == "") {
        this.msgTip("warning", "请上传PC内容CN");
        return false;
      } else if (this.fileListEn == [] && this.form.contentPcEn == "") {
        this.msgTip("warning", "请上传PC内容EN");
        return false;
      } else if (this.fileListJa == [] && this.form.contentPcJa == "") {
        this.msgTip("warning", "请上传PC内容JA");
        return false;
      } else if (this.fileListMcn == [] && this.form.contentMobileCn == "") {
        this.msgTip("warning", "请上传移动端内容CN");
        return false;
      } else if (this.fileListMen == [] && this.form.contentMobileEn == "") {
        this.msgTip("warning", "请上传移动端内容EN");
        return false;
      } else if (this.fileListMja == [] && this.form.contentMobileJa == "") {
        this.msgTip("warning", "请上传移动端内容JA");
        return false;
      } else {
        this.$refs.form.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            // this.isRepeat = true;
            var str = [];
            _this8.fileList.map(function (item) {
              if (item.response) {
                str.push(item.response.voT.downloadUrl);
              } else {
                str.push(item.href);
              }
            });
            _this8.form.contentPcCn = str.toString();
            var arr = [];
            _this8.fileListEn.map(function (item) {
              if (item.response) {
                arr.push(item.response.voT.downloadUrl);
              } else {
                arr.push(item.href);
              }
            });
            _this8.form.contentPcEn = arr.toString();
            var arrJa = [];
            _this8.fileListJa.map(function (item) {
              if (item.response) {
                arrJa.push(item.response.voT.downloadUrl);
              } else {
                arrJa.push(item.href);
              }
            });
            _this8.form.contentPcJa = arrJa.toString();
            var arrMcn = [];
            _this8.fileListMcn.map(function (item) {
              if (item.response) {
                arrMcn.push(item.response.voT.downloadUrl);
              } else {
                arrMcn.push(item.href);
              }
            });
            _this8.form.contentMobileCn = arrMcn.toString();
            var arrMen = [];
            _this8.fileListMen.map(function (item) {
              if (item.response) {
                arrMen.push(item.response.voT.downloadUrl);
              } else {
                arrMen.push(item.href);
              }
            });
            _this8.form.contentMobileEn = arrMen.toString();
            var arrMja = [];
            _this8.fileListMja.map(function (item) {
              if (item.response) {
                arrMja.push(item.response.voT.downloadUrl);
              } else {
                arrMja.push(item.href);
              }
            });
            _this8.form.contentMobileJa = arrMja.toString();
            _this8.isDisable = false;
            (0, _productMess.changeBanner)(_this8.id, _this8.form).then(function (res) {
              if (res.success) {
                _this8.msgTip("success", "修改成功");
                _this8.$store.dispatch("tagsView/delView", _this8.$route).then(function () {
                  _this8.$nextTick(function () {
                    _this8.$router.replace({
                      path: "/product/summarize",
                      query: {
                        line: _this8.$route.query.line,
                        type: _this8.$route.query.type,
                        cur: _this8.$route.query.cur
                      }
                    });
                  });
                });
              } else {
                _this8.msgTip("error", res.errorMsg);
                // this.isRepeat = false;
                _this8.isDisable = false;
              }
            });
          } else {
            _this8.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    }
  }
};