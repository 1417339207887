"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var Quill = _interopRequireWildcard(require("quill"));
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _detail = require("../../api/detail.js");
var _upload2 = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
var _axios = _interopRequireDefault(require("axios"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { upload } from "../../api/awards.js";

// 标题
var titleConfig = [{
  Choice: ".ql-bold",
  title: "加粗"
}, {
  Choice: ".ql-italic",
  title: "斜体"
}, {
  Choice: ".ql-underline",
  title: "下划线"
}, {
  Choice: ".ql-header",
  title: "段落格式"
}, {
  Choice: ".ql-strike",
  title: "删除线"
}, {
  Choice: ".ql-blockquote",
  title: "块引用"
}, {
  Choice: ".ql-code",
  title: "插入代码"
}, {
  Choice: ".ql-code-block",
  title: "插入代码段"
}, {
  Choice: ".ql-font",
  title: "字体"
}, {
  Choice: ".ql-size",
  title: "字体大小"
}, {
  Choice: '.ql-list[value="ordered"]',
  title: "编号列表"
}, {
  Choice: '.ql-list[value="bullet"]',
  title: "项目列表"
}, {
  Choice: ".ql-direction",
  title: "文本方向"
}, {
  Choice: '.ql-header[value="1"]',
  title: "h1"
}, {
  Choice: '.ql-header[value="2"]',
  title: "h2"
}, {
  Choice: ".ql-align",
  title: "对齐方式"
}, {
  Choice: ".ql-color",
  title: "字体颜色"
}, {
  Choice: ".ql-background",
  title: "背景颜色"
}, {
  Choice: ".ql-image",
  title: "图像"
}, {
  Choice: ".ql-video",
  title: "视频"
}, {
  Choice: ".ql-link",
  title: "添加链接"
}, {
  Choice: ".ql-formula",
  title: "插入公式"
}, {
  Choice: ".ql-clean",
  title: "清除字体格式"
}, {
  Choice: '.ql-script[value="sub"]',
  title: "下标"
}, {
  Choice: '.ql-script[value="super"]',
  title: "上标"
}, {
  Choice: '.ql-indent[value="-1"]',
  title: "向左缩进"
}, {
  Choice: '.ql-indent[value="+1"]',
  title: "向右缩进"
}, {
  Choice: ".ql-header .ql-picker-label",
  title: "标题大小"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="1"]',
  title: "标题一"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="2"]',
  title: "标题二"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="3"]',
  title: "标题三"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="4"]',
  title: "标题四"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="5"]',
  title: "标题五"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="6"]',
  title: "标题六"
}, {
  Choice: ".ql-header .ql-picker-item:last-child",
  title: "标准"
}, {
  Choice: '.ql-size .ql-picker-item[data-value="small"]',
  title: "小号"
}, {
  Choice: '.ql-size .ql-picker-item[data-value="large"]',
  title: "大号"
}, {
  Choice: '.ql-size .ql-picker-item[data-value="huge"]',
  title: "超大号"
}, {
  Choice: ".ql-size .ql-picker-item:nth-child(2)",
  title: "标准"
}, {
  Choice: ".ql-align .ql-picker-item:first-child",
  title: "居左对齐"
}, {
  Choice: '.ql-align .ql-picker-item[data-value="center"]',
  title: "居中对齐"
}, {
  Choice: '.ql-align .ql-picker-item[data-value="right"]',
  title: "居右对齐"
}, {
  Choice: '.ql-align .ql-picker-item[data-value="justify"]',
  title: "两端对齐"
}];
var _default = exports.default = {
  data: function data() {
    return {
      editorOption: {
        placeholder: "请输入文本信息..."
      },
      formLabelWidth: "120px",
      flags: false,
      flag: true,
      loading: false,
      loadings: false,
      loading1: false,
      disab: false,
      savedisab: true,
      savedisabs: true,
      show: false,
      buttonUpload: "上传",
      buttonUploads: "上传",
      buttonUpload1: "上传",
      showSize: "",
      showSizes: "",
      Sizes: "",
      strName: "",
      videoShow: false,
      loadingCn: false,
      loadingEn: false,
      loadingJa: false,
      videoflagCn: true,
      videoflagsCn: false,
      videoSrcCn: "",
      videoflagEn: true,
      videoflagsEn: false,
      videoSrcEn: "",
      videoflagJa: true,
      videoflagsJa: false,
      videoSrcJa: "",
      form: {
        descriptionCn: "",
        descriptionEn: "",
        descriptionJa: "",
        line: "2",
        seq: "",
        sizeCn: "",
        sizeEn: "",
        sizeJa: "",
        titleCn: "",
        titleEn: "",
        titleJa: "",
        type: "",
        urlCn: "",
        urlEn: "",
        urlJa: "",
        versionCn: "",
        versionEn: "",
        versionJa: "",
        thumbnailUrl: "",
        videoCn: null,
        videoEn: null,
        videoJa: null
      },
      option: [{
        name: "说明书",
        type: "0"
      }, {
        name: "固件",
        type: "1"
      }],
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "下线",
        type: "1"
      }],
      addRules: {
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "blur"
        }],
        urlCn: [{
          required: true,
          trigger: "blur"
        }],
        versionCn: [{
          required: true,
          trigger: "blur"
        }],
        versionEn: [{
          required: true,
          trigger: "blur"
        }],
        versionJa: [{
          required: true,
          trigger: "blur"
        }]
      },
      productType: [],
      imageUrl1: "",
      detailId: "",
      addRange: "",
      addRange1: "",
      addRange2: "",
      Rangeflag: "",
      Rangeflag1: "",
      Rangeflag2: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor1.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor2.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.productType = res.voList;
    });
    autotip: {
      document.getElementsByClassName("ql-editor")[0].dataset.placeholder = "";
      for (var _i = 0, _titleConfig = titleConfig; _i < _titleConfig.length; _i++) {
        var item = _titleConfig[_i];
        var tip = document.querySelector(".quill-editor " + item.Choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    }
  },
  methods: {
    // 点击图片ICON触发事件
    imgHandler: function imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (this.addRange != "" && this.addRange != null) {
        this.Rangeflag = true;
      }
      this.addRange1 = this.$refs.myQuillEditor1.quill.getSelection();
      if (this.addRange1 != "" && this.addRange1 != null) {
        this.Rangeflag1 = true;
      }
      this.addRange2 = this.$refs.myQuillEditor2.quill.getSelection();
      if (this.addRange2 != "" && this.addRange2 != null) {
        this.Rangeflag2 = true;
      }
      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        (0, _upload2.uploads)(fd).then(function (res) {
          if (_this2.Rangeflag) {
            _this2.addRange = _this2.$refs.myQuillEditor.quill.getSelection();
            _this2.$refs.myQuillEditor.quill.insertEmbed(_this2.addRange !== null ? _this2.addRange.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag = false;
          }
          if (_this2.Rangeflag1) {
            _this2.addRange1 = _this2.$refs.myQuillEditor1.quill.getSelection();
            _this2.$refs.myQuillEditor1.quill.insertEmbed(_this2.addRange1 !== null ? _this2.addRange1.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag1 = false;
          }
          if (_this2.Rangeflag2) {
            _this2.addRange2 = _this2.$refs.myQuillEditor2.quill.getSelection();
            _this2.$refs.myQuillEditor2.quill.insertEmbed(_this2.addRange2 !== null ? _this2.addRange2.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag2 = false;
          }
        });
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //下拉框内容判断
    changes: function changes(v) {
      this.detailId = v;
      if (v == 0) {
        this.show = true;
        this.videoShow = false;
      } else {
        this.show = false;
        this.videoShow = true;
      }
      this.buttonUpload = "上传";
      this.buttonUploads = "上传";
      this.buttonUpload1 = "上传";
      this.showSize = "";
      this.showSizes = "";
      this.Sizes = "";
      this.form.urlCn = "";
      this.form.urlEn = "";
      this.form.urlJa = "";
    },
    //上传固件包
    submitUpload: function submitUpload() {
      var _this3 = this;
      var fileData = this.$refs.uploadFile.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension = str === "zip";
      var extension1 = str === "pdf";
      this.strName = str;
      if (str == 'zip') {
        this.loading = true;
        this.savedisab = true;
        (0, _detail.getUpload)({
          fileName: fileData.name,
          folder: "1"
        }).then(function (res) {
          if (res.success) {
            var type = fileData.type;
            if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "rar" || fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "tar.gz") {
              type = "application/octet-stream";
              (0, _axios.default)({
                method: "put",
                url: res.ufileUrl,
                headers: {
                  Authorization: res.putAuthorization2,
                  "Content-Type": type
                },
                data: fileData,
                timeout: 1200000
              }).then(function (data) {
                (0, _axios.default)({
                  method: "put",
                  url: res.s3Url,
                  headers: {
                    Authorization: res.s3Authorization,
                    "Content-Type": type,
                    'x-amz-date': res.xamzDate,
                    'x-amz-content-sha256': 'UNSIGNED-PAYLOAD'
                  },
                  data: fileData,
                  timeout: 1200000
                }).then(function (data) {
                  _this3.loading = false;
                  _this3.savedisab = false;
                  _this3.$refs.uploadFile.value = null;
                  _this3.showSize = _this3.change(fileData.size);
                  _this3.showSizes = _this3.change(fileData.size);
                  _this3.Sizes = _this3.change(fileData.size);
                  _this3.form.sizeCn = fileData.size.toString();
                  _this3.form.sizeEn = fileData.size.toString();
                  _this3.form.sizeJa = fileData.size.toString();
                  _this3.buttonUpload = fileData.name;
                  _this3.form.urlCn = res.s3Url.split('.com/')[1];
                  _this3.form.urlEn = res.s3Url.split('.com/')[1];
                  _this3.form.urlJa = res.s3Url.split('.com/')[1];
                });
              });
            } else if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "gz") {
              type = "application/x-gzip";
              (0, _axios.default)({
                method: "put",
                url: res.ufileUrl,
                headers: {
                  Authorization: res.putAuthorization2,
                  "Content-Type": type
                },
                data: fileData,
                timeout: 1200000
              }).then(function (data) {
                (0, _axios.default)({
                  method: "put",
                  url: res.s3Url,
                  headers: {
                    Authorization: res.s3Authorization,
                    "Content-Type": type,
                    'x-amz-date': res.xamzDate,
                    'x-amz-content-sha256': 'UNSIGNED-PAYLOAD'
                  },
                  data: fileData,
                  timeout: 1200000
                }).then(function (data) {
                  _this3.loading = false;
                  _this3.savedisab = false;
                  _this3.$refs.uploadFile.value = null;
                  _this3.showSize = _this3.change(fileData.size);
                  _this3.showSizes = _this3.change(fileData.size);
                  _this3.Sizes = _this3.change(fileData.size);
                  _this3.form.sizeCn = fileData.size.toString();
                  _this3.form.sizeEn = fileData.size.toString();
                  _this3.form.sizeJa = fileData.size.toString();
                  _this3.buttonUpload = fileData.name;
                  _this3.form.urlCn = res.s3Url.split('.com/')[1];
                  _this3.form.urlEn = res.s3Url.split('.com/')[1];
                  _this3.form.urlJa = res.s3Url.split('.com/')[1];
                });
              });
            } else if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "zip") {
              type = "application/x-zip-compressed";
              (0, _axios.default)({
                method: "put",
                url: res.ufileUrl,
                headers: {
                  Authorization: res.putAuthorization2,
                  "Content-Type": type
                },
                data: fileData,
                timeout: 1200000
              }).then(function (data) {
                (0, _axios.default)({
                  method: "put",
                  url: res.s3Url,
                  headers: {
                    Authorization: res.s3Authorization,
                    "Content-Type": type,
                    'x-amz-date': res.xamzDate,
                    'x-amz-content-sha256': 'UNSIGNED-PAYLOAD'
                  },
                  data: fileData,
                  timeout: 1200000
                }).then(function (data) {
                  _this3.loading = false;
                  _this3.savedisab = false;
                  _this3.$refs.uploadFile.value = null;
                  _this3.showSize = _this3.change(fileData.size);
                  _this3.showSizes = _this3.change(fileData.size);
                  _this3.Sizes = _this3.change(fileData.size);
                  _this3.form.sizeCn = fileData.size.toString();
                  _this3.form.sizeEn = fileData.size.toString();
                  _this3.form.sizeJa = fileData.size.toString();
                  _this3.buttonUpload = fileData.name;
                  _this3.form.urlCn = res.s3Url.split('.com/')[1];
                  _this3.form.urlEn = res.s3Url.split('.com/')[1];
                  _this3.form.urlJa = res.s3Url.split('.com/')[1];
                });
              });
            } else if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "pdf") {
              //type = "application/pdf";
              (0, _upload2.upload)(formFile).then(function (res) {
                if (res.success) {
                  var _type = fileData.type;
                  if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "rar") {
                    _type = "application/octet-stream";
                  } else if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "tar.gz") {
                    _type = "application/octet-stream";
                  } else if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "gz") {
                    _type = "application/x-gzip";
                  } else if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "zip") {
                    _type = "application/x-zip-compressed";
                  } else if (fileData.name.split(".")[fileData.name.split(".").length - 1].toLowerCase() == "pdf") {
                    _type = "application/pdf";
                  }
                  (0, _axios.default)({
                    method: "put",
                    url: res.ufileUrl,
                    headers: {
                      Authorization: res.putAuthorization2,
                      "Content-Type": _type
                    },
                    data: fileData
                  }).then(function (data) {
                    (0, _axios.default)({
                      method: "put",
                      url: res.s3Url,
                      headers: {
                        Authorization: res.s3Authorization,
                        "Content-Type": _type,
                        'x-amz-date': res.xamzDate,
                        'x-amz-content-sha256': 'UNSIGNED-PAYLOAD'
                      },
                      data: fileData
                    }).then(function (data) {
                      _this3.loading = false;
                      _this3.savedisab = false;
                      _this3.$refs.uploadFile.value = null;
                      _this3.showSize = _this3.change(fileData.size);
                      _this3.form.sizeCn = fileData.size.toString();
                      _this3.buttonUpload = fileData.name;
                      _this3.form.urlCn = fileData.name;
                    });
                  });

                  // this.form.urlCn = res.voT.downloadUrl;
                  // if(this.detailId == '1'){
                  //   this.form.urlJa = res.voT.downloadUrl;
                  //   this.form.sizeJa = (fileData.size).toString()
                  //   this.Sizes = this.change(fileData.size)
                  //   this.form.urlEn = res.voT.downloadUrl;
                  //   this.form.sizeEn = (fileData.size).toString()
                  //   this.showSizes = this.change(fileData.size)
                  // }
                } else {
                  _this3.loading = false;
                  _this3.msgTip("error", res.errorMsg);
                  _this3.$refs.uploadFile.value = null;
                }
              });
            }
          } else {
            _this3.loading = false;
            _this3.msgTip("error", res.errorMsg);
            _this3.$refs.uploadFile.value = null;
          }
        });
      } else if (str == 'pdf') {
        this.loading = true;
        this.savedisab = true;
        (0, _upload2.upload)(formFile).then(function (res) {
          if (res.success) {
            _this3.loading = false;
            _this3.savedisab = false;
            _this3.$refs.uploadFile.value = null;
            _this3.form.urlCn = res.voT.downloadUrl;
            _this3.showSize = _this3.change(fileData.size);
            _this3.form.sizeCn = fileData.size.toString();
            _this3.buttonUpload = fileData.name;
          } else {
            _this3.loadings = false;
            _this3.msgTip("error", res.errorMsg);
            _this3.$refs.uploadFiles.value = null;
          }
        });
      } else {
        this.msgTip("error", "上传文件只能是zip/pdf格式!");
        return;
      }
    },
    submitUploads: function submitUploads() {
      var _this4 = this;
      var fileDatas = this.$refs.uploadFiles.files[0];
      var formFiles = new FormData(); //加入文件对象
      formFiles.append("file", fileDatas);
      var index = fileDatas.name.lastIndexOf(".");
      var str = fileDatas.name.substring(index + 1, fileDatas.name.length);
      var extension1 = str === "pdf";
      this.strName = str;
      if (extension1) {
        this.loadings = true;
        this.savedisabs = true;
        (0, _upload2.upload)(formFiles).then(function (res) {
          if (res.success) {
            _this4.loadings = false;
            _this4.savedisabs = false;
            _this4.$refs.uploadFiles.value = null;
            _this4.form.urlEn = res.voT.downloadUrl;
            _this4.buttonUploads = fileDatas.name;
            _this4.showSizes = _this4.change(fileDatas.size);
            _this4.form.sizeEn = fileDatas.size.toString();
          } else {
            _this4.loadings = false;
            _this4.msgTip("error", res.errorMsg);
            _this4.$refs.uploadFiles.value = null;
          }
        });
      } else {
        this.msgTip("error", "上传文件只能是zip/pdf格式!");
        return;
      }
    },
    upload: function upload() {
      var _this5 = this;
      var fileDatas = this.$refs.uploadFiled.files[0];
      var formFiles = new FormData(); //加入文件对象
      formFiles.append("file", fileDatas);
      var index = fileDatas.name.lastIndexOf(".");
      var str = fileDatas.name.substring(index + 1, fileDatas.name.length);
      var extension1 = str === "pdf";
      this.strName = str;
      if (extension1) {
        this.loading1 = true;
        this.savedisabs = true;
        (0, _upload2.upload)(formFiles).then(function (res) {
          if (res.success) {
            _this5.loading1 = false;
            _this5.savedisabs = false;
            _this5.$refs.uploadFiled.value = null;
            _this5.form.urlJa = res.voT.downloadUrl;
            _this5.buttonUpload1 = fileDatas.name;
            _this5.Sizes = _this5.change(fileDatas.size);
            _this5.form.sizeJa = fileDatas.size.toString();
          } else {
            _this5.loadings = false;
            _this5.msgTip("error", res.errorMsg);
            _this5.$refs.uploadFiled.value = null;
          }
        });
      } else {
        this.msgTip("error", "上传文件只能是zip/pdf格式!");
        return;
      }
    },
    //上传视频
    submitUploadCn: function submitUploadCn() {
      var _this6 = this;
      var fileData = this.$refs.uploadFileCn.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingCn = true;
        // this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _upload2.upload)(formFile).then(function (res) {
        if (res.success) {
          _this6.loadingCn = false;
          if (res.valueCn !== '') {
            _this6.videoflagCn = false;
            _this6.videoflagsCn = true;
            _this6.videoSrcCn = res.voT.downloadUrl.split('===')[0];
            _this6.form.videoCn = res.voT.downloadUrl;
          }
        } else {
          _this6.loadingCn = false;
          _this6.msgTip("error", res.errorMsg);
          _this6.$refs.uploadFileCn.value = null;
        }
      });
    },
    //上传视频
    submitUploadEn: function submitUploadEn() {
      var _this7 = this;
      var fileData = this.$refs.uploadFileEn.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingEn = true;
        // this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _upload2.upload)(formFile).then(function (res) {
        if (res.success) {
          _this7.loadingEn = false;
          if (res.valueCn !== '') {
            _this7.videoflagEn = false;
            _this7.videoflagsEn = true;
            _this7.videoSrcEn = res.voT.downloadUrl.split('===')[0];
            _this7.form.videoEn = res.voT.downloadUrl;
          }
        } else {
          _this7.loadingEn = false;
          _this7.msgTip("error", res.errorMsg);
          _this7.$refs.uploadFileEn.value = null;
        }
      });
    },
    //上传视频JA
    submitUploadJa: function submitUploadJa() {
      var _this8 = this;
      var fileData = this.$refs.uploadFileJa.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingJa = true;
        // this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _upload2.upload)(formFile).then(function (res) {
        if (res.success) {
          _this8.loadingJa = false;
          if (res.valueCn !== '') {
            _this8.videoflagJa = false;
            _this8.videoflagsJa = true;
            _this8.videoSrcJa = res.voT.downloadUrl.split('===')[0];
            _this8.form.videoJa = res.voT.downloadUrl;
          }
        } else {
          _this8.loadingJa = false;
          _this8.msgTip("error", res.errorMsg);
          _this8.$refs.uploadFileJa.value = null;
        }
      });
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this9 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload2.upload)(fd).then(function (res) {
        _this9.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this9.form.thumbnailUrl = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //提交
    submit: function submit() {
      var _this10 = this;
      // this.form.thumbnailUrl = this.imageUrl1;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this10.form.type == 0 || _this10.form.type == "0") {
            if (_this10.strName != "pdf") {
              _this10.msgTip("error", "说明书只能是pdf格式!");
              return false;
            }
          }
          if (_this10.form.type == 1 || _this10.form.type == "1") {
            if (_this10.strName != "zip") {
              _this10.msgTip("error", "固件只能是zip格式!");
              return false;
            }
          }
          if (!/(^[1-9]\d*$)/.test(_this10.form.seq) && _this10.form.seq !== "") {
            _this10.msgTip("warning", "序号只能为正整数");
            return false;
          }
          if (_this10.form.descriptionCn == "" || _this10.form.descriptionEn == "" || _this10.form.descriptionJa == "") {
            _this10.msgTip("warning", "描述不能为空");
            return false;
          }
          if (_this10.form.thumbnailUrl == "") {
            _this10.msgTip("warning", "缩略图不能为空");
            return false;
          }
          // 表单验证通过之后的操作
          (0, _detail.addList)(_this10.form).then(function (res) {
            if (res.success) {
              _this10.msgTip("success", "添加成功");
              _this10.$store.dispatch("tagsView/delView", _this10.$route).then(function () {
                _this10.$nextTick(function () {
                  _this10.$router.replace({
                    path: "/serve/detail",
                    query: {
                      line: _this10.$route.query.line,
                      type: _this10.$route.query.type,
                      pageNo: _this10.$route.query.pageNo
                    }
                  });
                });
              });
            } else {
              _this10.msgTip("error", res.errorMsg);
            }
          });
        } else {
          _this10.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    change: function change(limit) {
      var size = "";
      if (limit < 0.1 * 1024) {
        //小于0.1KB，则转化成B
        size = limit.toFixed(2) + "B";
      } else if (limit < 0.1 * 1024 * 1024) {
        //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + "KB";
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizeStr = size + ""; //转成字符串
      var index = sizeStr.indexOf("."); //获取小数点处的索引
      var dou = sizeStr.substr(index + 1, 2); //获取小数点后两位的值
      if (dou == "00") {
        //判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
      }
      return size;
    }
  }
};