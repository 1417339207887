"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _part = require("../../api/part.js");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formLabelWidth: "120px",
      dialogImageUrl: "",
      dialogVisible: false,
      form: {
        colourUrl: "",
        homeFlag: "",
        line: "2",
        productNumber: "",
        productType: "",
        serialNumber: "",
        letteringFlag: "",
        chooseUrl: "",
        weight: "",
        priceCn: "",
        priceCnUnit: "",
        priceEn: "",
        priceEnUnit: "",
        priceJa: "",
        priceJaUnit: "",
        type: '',
        valueCn: '',
        valueEn: '',
        valueJa: '',
        discountCn: '',
        discountEn: '',
        discountJa: '',
        startTime: '',
        endTime: ''
      },
      seq: "",
      addRules: {
        line: [{
          required: true,
          trigger: "change"
        }],
        productNumber: [{
          required: true,
          trigger: "change"
        }],
        productType: [{
          required: true,
          trigger: "change"
        }],
        serialNumber: [{
          required: true,
          trigger: "change"
        }],
        homeFlag: [{
          required: true,
          trigger: "change"
        }],
        letteringFlag: [{
          required: true,
          trigger: "change"
        }],
        weight: [{
          required: true,
          trigger: "blur"
        }],
        priceCn: [{
          required: true,
          trigger: "change"
        }],
        priceCnUnit: [{
          required: true,
          trigger: "change"
        }],
        priceEn: [{
          required: true,
          trigger: "change"
        }],
        priceEnUnit: [{
          required: true,
          trigger: "change"
        }],
        priceJa: [{
          required: true,
          trigger: "change"
        }],
        priceJaUnit: [{
          required: true,
          trigger: "change"
        }],
        type: [{
          required: true,
          trigger: "change"
        }]
      },
      homeFlag: [{
        name: "是",
        type: "0"
      }, {
        name: "否",
        type: "1"
      }],
      letterFlag: [{
        name: "是",
        type: "0"
      }, {
        name: "否",
        type: "1"
      }],
      price: [{
        value: "RMB",
        label: "RMB"
      }, {
        value: "USD",
        label: "USD"
      }, {
        value: "JPY",
        label: "JPY"
      }],
      types: [{
        value: "0",
        label: "无优惠"
      }, {
        value: "1",
        label: "减免优惠"
      }, {
        value: "2",
        label: "折扣优惠"
      }],
      imageUrl1: "",
      imageUrl2: "",
      imageUrl3: "",
      imageUrl4: "",
      imageUrl5: "",
      rules: [],
      newList: [],
      proList: [],
      type: []
    };
  },
  created: function created() {
    var _this = this;
    this.loadAll();
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
  },
  mounted: function mounted() {
    var _this2 = this;
    this.id = this.$route.query.id;
    (0, _part.aloneList)(this.id).then(function (res) {
      _this2.form = res.voT;
      var item = res.voT;
      // this.imageUrl1 = item.colourUrl;
      _this2.imageUrl2 = item.chooseUrl.split('===')[0];
      _this2.form.productNumber = item.productNumber + "_" + item.name;
      _this2.form.serialNumber = item.serialNumber + "_" + item.serialName;
    });
  },
  methods: {
    //模糊搜索
    querySearch: function querySearch(queryString, cb, list) {
      this.loadAll();
      var restaurants = this[list];
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter: function createFilter(queryString) {
      return function (restaurant) {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect: function handleSelect(e, type) {
      this.form[type] = e.value;
    },
    loadAll: function loadAll() {
      var _this3 = this;
      (0, _dictionary.searchDiclist)({
        name: "",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this3.newList = b["PART_SERIES"];
          _this3.proList = b["PART"];
        }
      });
    },
    // 上传文件之前的钩子
    // beforeUploadPicture1(file) {
    //   if (
    //     !(
    //       file.type === "image/png" ||
    //       file.type === "image/gif" ||
    //       file.type === "image/jpg" ||
    //       file.type === "image/jpeg"
    //     )
    //   ) {
    //     this.$notify.warning({
    //       title: "警告",
    //       message: "请上传格式为png, gif, jpg, jpeg的图片"
    //     });
    //     return;
    //   }
    //   let fd = new FormData();
    //   fd.append("file", file); //传文件
    //   uploads(fd).then(res => {
    //     this.imageUrl1 = res.voT.downloadUrl;
    //   });
    //   return false; //屏蔽了action的默认上传
    // },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this4 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.$notify.warning({
          title: "警告",
          message: "请上传格式为png, gif, jpg, jpeg的图片"
        });
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this4.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this4.form.chooseUrl = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    submit: function submit() {
      var _this5 = this;
      // this.form.colourUrl = this.imageUrl1;

      this.form.productNumber = this.form.productNumber.split("_")[0];
      this.form.serialNumber = this.form.serialNumber.split("_")[0];
      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          _this5.isDisable = true;
          (0, _part.changePart)(_this5.id, _this5.form).then(function (res) {
            if (res.success) {
              _this5.msgTip("success", "修改成功");
              _this5.$store.dispatch("tagsView/delView", _this5.$route).then(function () {
                _this5.$nextTick(function () {
                  _this5.$router.replace({
                    path: "/parts/partsMess",
                    query: {
                      cur: _this5.$route.query.cur,
                      productNumber: _this5.$route.query.productNumber,
                      serialNumber: _this5.$route.query.serialNumber,
                      homeFlag: _this5.$route.query.homeFlag,
                      line: _this5.$route.query.line,
                      productType: _this5.$route.query.productType
                    }
                  });
                });
              });
            } else {
              _this5.msgTip("error", res.errorMsg);
              // this.isRepeat = false;
              _this5.isDisable = false;
            }
          });
        } else {
          _this5.msgTip("warning", "请填写完整");
          return false;
        }
      });
    }
  }
};