"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addDiclist = addDiclist;
exports.amendDiclist = amendDiclist;
exports.dataManipulation = dataManipulation;
exports.deleteDiclist = deleteDiclist;
exports.getDiclist = getDiclist;
exports.searchDic = searchDic;
exports.searchDiclist = searchDiclist;
exports.typeDiclist = typeDiclist;
var _request = _interopRequireDefault(require("@/utils/request"));
//根据id查询数据字典信息接口
function getDiclist(id) {
  return (0, _request.default)({
    url: 'order/dictionary/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//添加数据字典信息接口
function addDiclist(data) {
  return (0, _request.default)({
    url: 'order/dictionary/add',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改数据字典信息接口
function amendDiclist(id, data) {
  return (0, _request.default)({
    url: 'order/dictionary/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除数据字典信息接口
function deleteDiclist(id) {
  return (0, _request.default)({
    url: 'order/dictionary/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//根据参数模糊查询接口
function searchDiclist(data) {
  return (0, _request.default)({
    url: 'order/dictionary',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据数据名称类型查询接口
function typeDiclist() {
  return (0, _request.default)({
    url: 'order/dictionary',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
function searchDic(data) {
  return (0, _request.default)({
    url: 'system/base/dictionary',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, remark, valueCn, valueEn, updateUser, updateTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == rowData.id) {
      if (remark != null && remark != "") {
        element.remark = remark;
      }
      if (valueCn != null && valueCn != "") {
        element.valueCn = valueCn;
      }
      if (valueEn != null && valueEn != "") {
        element.valueEn = valueEn;
      }
      if (updateUser != null && updateUser != "") {
        element.opUser = updateUser;
      }
      if (updateTime != null && updateTime != "") {
        element.opTime = updateTime;
      }
    }
  });
  return tableData;
}