"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _orderLine = require("../../api/orderLine.js");
var _orderInfo = require("../../api/orderInfo.js");
var _timers = require("timers");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        orderNo: "",
        productNo: "",
        pageNo: 1,
        pageSize: 10
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      datas: [],
      loadings: false,
      load: false,
      dialogFormVisible: false,
      tableDatas: [],
      productName: ""
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _timers.setTimeout)(function () {
        (0, _orderLine.getLists)(_this.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.form.pageNo = 1;
            _this.currentPage = 1;
            (0, _orderLine.getLists)(_this.form).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.orderNo = "";
      this.form.productNo = "";
      this.getList();
    },
    //查询子菜单
    rowExpand: function rowExpand(row) {
      var _this2 = this;
      (0, _orderLine.searchBag)(row.id).then(function (res) {
        _this2.loadings = true;
        if (res.success) {
          _this2.tableData.map(function (item) {
            item.children = [];
            if (row.id == item.id) {
              item.children = res.voList;
              _this2.loadings = false;
            }
          });
        } else {
          _this2.$message({
            type: "error",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    serve: function serve(v) {
      var _this3 = this;
      this.dialogFormVisible = true;
      this.load = true;
      (0, _orderInfo.getServe)(v.id).then(function (res) {
        if (res.success) {
          _this3.productName = v.productNo;
          (0, _timers.setTimeout)(function () {
            _this3.load = false;
            _this3.tableDatas = res.voList;
          }, 250);
        }
      });
    },
    serveclose: function serveclose() {
      this.dialogFormVisible = false;
    }
  }
};