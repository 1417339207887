"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneList = aloneList;
exports.changeOn = changeOn;
exports.changePart = changePart;
exports.changeSeq = changeSeq;
exports.changeall = changeall;
exports.delePart = delePart;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询配件列表(cms系统)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/part/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增配件
function addList(data) {
  return (0, _request.default)({
    url: 'cms/part',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改配件上线下线
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/part/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改配件序号
function changeSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/part/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个配件信息
function aloneList(id) {
  return (0, _request.default)({
    url: 'cms/part/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改配件
function changePart(id, data) {
  return (0, _request.default)({
    url: 'cms/part/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除配件
function delePart(id) {
  return (0, _request.default)({
    url: 'cms/part/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//批量修改
function changeall(data) {
  return (0, _request.default)({
    url: 'cms/part/batch',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}