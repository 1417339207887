"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _additional = require("../../api/additional.js");
var _index = require("../../utils/index.js");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      rules: {
        seq: [{
          required: false,
          trigger: "blur"
        }],
        titleCn: [{
          required: false,
          trigger: "blur"
        }],
        titleEn: [{
          required: false,
          trigger: "blur"
        }],
        titleJa: [{
          required: false,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "change"
        }]
      },
      addform: {
        seq: "",
        titleCn: "",
        titleEn: "",
        titleJa: "",
        type: ""
      },
      isDisable: false,
      addApprove: {
        seq: ""
      },
      dialogFormVisible: false,
      lines: [{
        line: "0",
        label: "上线"
      }, {
        line: "1",
        label: "预览"
      }, {
        line: "2",
        label: "下线"
      }],
      tableData: [],
      currentPage: 1,
      total: 0,
      loading: false,
      form: {
        line: "",
        pageNo: 1,
        pageSize: 10,
        productType: ""
      },
      forms: {
        line: "",
        pageNo: 1,
        pageSize: 10,
        productType: ""
      },
      lineStatus: {
        line: ""
      },
      isFlag: true,
      value: "",
      addRules: [],
      formLabelWidth: "110px",
      changeRules: [],
      changeid: "",
      titletype: "",
      type: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _additional.getLists)(_this2.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.forms.pageNo = 1;
            _this2.currentPage = 1;
            (0, _additional.getLists)(_this2.forms).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //查询
    search: function search() {
      var _this3 = this;
      this.loading = true;
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.line = this.form.line;
      //    this.forms.productType = this.form.productType;
      setTimeout(function () {
        (0, _additional.getLists)({
          line: _this3.form.line,
          pageNo: 1,
          pageSize: 10,
          productType: _this3.form.productType
        }).then(function (res) {
          _this3.form.pageNo = 1;
          _this3.tableData = res.voList;
          _this3.total = res.total;
        });
        _this3.currentPage = 1;
        _this3.loading = false;
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //删除
    dele: function dele(v) {
      var _this4 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _additional.deleadditional)(v.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "操作成功");
            _this4.getList();
          } else {
            _this4.msgTip("warning", res.errorMsg);
          }
        });
      });
    },
    addApproveClose: function addApproveClose(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible1 = false;
    },
    empty: function empty() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.line = "";
      this.form.line = "";
      this.form.productType = "";
      this.forms.productType = "";
      this.getList();
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //上线
    online: function online(v) {
      var _this5 = this;
      if (v.line == 0) {
        this.msgTip("error", "已是上线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 0;
        (0, _additional.changeOn)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "操作成功");
            _this5.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "0",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this5.isFlag = true;
          } else {
            _this5.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this5.isFlag = true;
          }
        });
      }
    },
    //判断上下线
    lineFormatter: function lineFormatter(row, column) {
      if (row.line == "0") {
        return "上线";
      } else if (row.line == "1") {
        return "预览";
      } else if (row.line == "2") {
        return "下线";
      }
    },
    //下线
    offline: function offline(v) {
      var _this6 = this;
      if (v.line == 2) {
        this.msgTip("error", "已是下线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 2;
        (0, _additional.changeOn)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "操作成功");
            _this6.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "2",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this6.isFlag = true;
          } else {
            _this6.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this6.isFlag = true;
          }
        });
      }
    },
    //预览
    preview: function preview(v) {
      var _this7 = this;
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 1;
        (0, _additional.changeOn)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "操作成功");
            _this7.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "1",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this7.isFlag = true;
          } else {
            _this7.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this7.isFlag = true;
          }
        });
      }
    },
    //新增
    add: function add() {
      this.dialogFormVisible = true;
      this.isDisable = false;
      this.titletype = "新增";
    },
    //新增/修改关闭按钮
    addClose: function addClose(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },
    //修改
    change: function change(v) {
      var _this8 = this;
      this.dialogFormVisible = true;
      this.titletype = "修改";
      (0, _additional.aloneadditional)(v.id).then(function (res) {
        _this8.addform = res.voT;
        // this.addform.type = res.voT.type;
      });
      this.changeid = v.id;
    },
    //新增/修改确定按钮
    sure: function sure(formName) {
      var _this9 = this;
      if (this.titletype == "新增") {
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            if (!/^[1-9]{1,}[\d]*$/.test(_this9.addform.seq) && _this9.addform.seq !== "") {
              _this9.msgTip("warning", "序号只能为正整数");
              return;
            }
            (0, _additional.addList)(_this9.addform).then(function (res) {
              if (res.success) {
                _this9.msgTip("success", "添加成功");
                _this9.getList();
                _this9.addform.seq = "";
                _this9.dialogFormVisible = false;
                _this9.$refs[formName].resetFields();
                // 前端刷新
                dataManipulation(_this9.tableData, window.localStorage.getItem("username"));
              } else {
                _this9.$message({
                  type: "warning",
                  message: res.errorMsg,
                  center: true
                });
                _this9.isDisable = false;
              }
            });
          }
        });
      } else if (this.titletype == "修改") {
        this.$refs[formName].validate(function (valid) {
          if (valid) {
            (0, _additional.changeadditional)(_this9.addform, _this9.changeid).then(function (res) {
              if (res.success) {
                _this9.msgTip("success", "修改成功");
                _this9.dialogFormVisible = false;
                _this9.getList();
                _this9.$refs[formName].resetFields();
                // 前端刷新
                dataManipulation(_this9.tableData, window.localStorage.getItem("username"));
              } else {
                _this9.$message({
                  type: "warning",
                  message: res.errorMsg,
                  center: true
                });
                _this9.isDisable = false;
              }
            });
          }
        });
      }
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/cms/additional/item/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this10 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
        return;
      }
      if (!/^[1-9]{1,}[\d]*$/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
        return;
      }
      this.addApprove.seq = val.seq;
      (0, _additional.changeSeq)(this.addApprove, val.id).then(function (res) {
        if (res.success) {
          _this10.msgTip("success", "修改成功");
          _this10.empty();
          _this10.isShow = false;
        } else {
          _this10.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}