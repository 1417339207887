var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "updateabout" }, [
    _c(
      "div",
      { staticClass: "addhead" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              "status-icon": true,
              "hide-required-asterisk": "",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "标题CN",
                  "label-width": _vm.formLabelWidth,
                  prop: "titleCn",
                },
              },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off", type: "text", maxlength: "50" },
                  model: {
                    value: _vm.form.titleCn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "titleCn", $$v)
                    },
                    expression: "form.titleCn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "标题EN",
                  "label-width": _vm.formLabelWidth,
                  prop: "titleEn",
                },
              },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off", type: "text", maxlength: "50" },
                  model: {
                    value: _vm.form.titleEn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "titleEn", $$v)
                    },
                    expression: "form.titleEn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "标题EN",
                  "label-width": _vm.formLabelWidth,
                  prop: "titleJa",
                },
              },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off", type: "text", maxlength: "50" },
                  model: {
                    value: _vm.form.titleJa,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "titleJa", $$v)
                    },
                    expression: "form.titleJa",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("PC端描述CN")]),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  staticStyle: { display: "none" },
                  attrs: {
                    action: "",
                    "http-request": _vm.to_upload_img,
                    "show-file-list": false,
                  },
                },
                [
                  _vm._v("\n          >\n          "),
                  _c("i", {
                    staticClass: "el-icon-plus avatar-uploader-icon",
                    attrs: { id: "imgInput" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor1",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.descriptionPcCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descriptionPcCn", $$v)
                  },
                  expression: "form.descriptionPcCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("PC端描述EN")]),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor2",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.descriptionPcEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descriptionPcEn", $$v)
                  },
                  expression: "form.descriptionPcEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("PC端描述JA")]),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor5",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.descriptionPcJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descriptionPcJa", $$v)
                  },
                  expression: "form.descriptionPcJa",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("移动端描述CN")]),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor3",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.descriptionMobileCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descriptionMobileCn", $$v)
                  },
                  expression: "form.descriptionMobileCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("移动端描述EN")]),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor4",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.descriptionMobileEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descriptionMobileEn", $$v)
                  },
                  expression: "form.descriptionMobileEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("移动端描述JA")]),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor6",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.descriptionMobileJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "descriptionMobileJa", $$v)
                  },
                  expression: "form.descriptionMobileJa",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: _vm.isDisable },
            on: { click: _vm.submit },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }