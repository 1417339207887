var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("品质仓")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.position,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "position", $$v)
                    },
                    expression: "form.position",
                  },
                },
                _vm._l(_vm.dealers, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("区域仓")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.locationPosition,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "locationPosition", $$v)
                    },
                    expression: "form.locationPosition",
                  },
                },
                _vm._l(_vm.locationPositions, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("产品编号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.productNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productNo", $$v)
                  },
                  expression: "form.productNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("类型")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.type,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "type", $$v)
                  },
                  expression: "form.type",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("出入库明细ID")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.id,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "id", $$v)
                  },
                  expression: "form.id",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("经销商/供应商")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.dealerSupplier,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "dealerSupplier", $$v)
                  },
                  expression: "form.dealerSupplier",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dealer/stock/detail/undo",
                  expression: "'/order/dealer/stock/detail/undo'",
                },
              ],
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.repeal } },
                [_vm._v("撤销")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.derive } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: {
            data: _vm.tableData,
            border: "",
            "highlight-current-row": "",
          },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "current-change": _vm.handleClick,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "出入库明细ID",
              "show-overflow-tooltip": "",
              prop: "id",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品编号",
              "show-overflow-tooltip": "",
              prop: "productNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品名称",
              "show-overflow-tooltip": "",
              prop: "productName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              "show-overflow-tooltip": "",
              prop: "type",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "经销/供货商",
              "show-overflow-tooltip": "",
              prop: "dealerSupplier",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "品质仓",
              "show-overflow-tooltip": "",
              prop: "position",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "区域仓",
              "show-overflow-tooltip": "",
              prop: "locationPosition",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "数量",
              "show-overflow-tooltip": "",
              prop: "counts",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "实际入仓数量",
              "show-overflow-tooltip": "",
              prop: "usRealChargeCounts",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "入仓编号",
              "show-overflow-tooltip": "",
              prop: "shipmentId",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "物流名称",
              "show-overflow-tooltip": "",
              prop: "logisticsName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "物流单号",
              "show-overflow-tooltip": "",
              prop: "logisticsNumber",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              "show-overflow-tooltip": "",
              prop: "createTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "修改时间",
              "show-overflow-tooltip": "",
              prop: "updateTime",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }