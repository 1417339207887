"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var req = new XMLHttpRequest();
req.open('GET', document.location, false);
req.send(null);
var userType = req.getResponseHeader('Domain') ? req.getResponseHeader('Domain') : '';
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title1: 'CMS - 国内',
      title: 'CMS - 国外',
      title2: 'CMS',
      userType: "",
      logo: require("../../../assets/img/logo.png")
    };
  },
  mounted: function mounted() {
    this.getDomain();
  },
  methods: {
    close: function close() {
      this.loading = false;
    },
    getDomain: function getDomain() {
      this.userType = userType;
    }
  }
};