"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addlist = addlist;
exports.alonelist = alonelist;
exports.changelist = changelist;
exports.deleLists = deleLists;
exports.getLanList = getLanList;
exports.getLists = getLists;
exports.getOut = getOut;
exports.getstockIn = getstockIn;
exports.getstockOut = getstockOut;
exports.importLists = importLists;
exports.inModeldownload = inModeldownload;
exports.locationChangelist = locationChangelist;
exports.outdownload = outdownload;
exports.querySkuList = querySkuList;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取渠道库存
function getLists(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}
//删除
function deleLists(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/delete',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//批量调整
function importLists(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/import',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 600000,
    data: data
  });
}
//批入模板下载
function inModeldownload() {
  return (0, _request.default)({
    url: 'order/download/import/xlsx',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob'
  });
}
//批出模板下载
function outdownload() {
  return (0, _request.default)({
    url: 'order/download/out/xlsx',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob'
  });
}
//批出
function getOut(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/out/import',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 600000,
    data: data
  });
}
//查询单个信息
function alonelist(id) {
  return (0, _request.default)({
    url: 'order/dealer/stock/query/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//新增
function addlist(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/save',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//修改品质仓
function changelist(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/update',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改区域仓
function locationChangelist(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/location/update',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//入库
function getstockIn(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/in',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 600000,
    data: data
  });
}

//出库
function getstockOut(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/out',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 600000,
    data: data
  });
}
//获取设备用途
function getLanList(data) {
  return (0, _request.default)({
    url: 'system/base/dictionary/param',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询美国仓库存
function querySkuList(data) {
  return (0, _request.default)({
    url: 'order/stock/sku/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}