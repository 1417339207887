"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.directive = directive;
exports.setInput = setInput;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _vue = _interopRequireDefault(require("vue"));
var _store = _interopRequireDefault(require("../store"));
/**
 * @export 自定义指令
 */
function directive() {
  _vue.default.directive('permit', {
    bind: function bind(el, binding) {
      var arr = _store.default.state.user.permission.map(function (v) {
        return v.attributes.url;
      });
      if (arr.indexOf(binding.value) !== -1) {
        el.style.display = "block";
      } else {
        el.style.display = "none";
        //  el.parentNode && el.parentNode.removeChild(el)
      }
    }
  });
}
function setInput() {
  _vue.default.directive('setInputValue', {
    bind: function bind(el) {
      // 一挂载input先绑定value值，解决不修改input值时input无法打印
      el.setAttribute('value', el.value);
    },
    inserted: function inserted(el) {
      //监听每次改变input的值时动态绑定进value
      el.addEventListener('change', function (val) {
        // 要给对应的input绑定相同的name值，表示是一致的元素，改变其中一个，其他的也会跟着改动
        if (el.name) {
          var name = document.getElementsByName(el.name);
          name.forEach(function (item) {
            item.setAttribute('value', val, target.value);
          });
        } else {
          console.log(val, '90909090');
          el.setAttribute('value', val, target.value);
        }
      });
    }
  });
}