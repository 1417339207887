"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAbout = addAbout;
exports.deleAbouts = deleAbouts;
exports.queryAboutAll = queryAboutAll;
exports.updateAbout = updateAbout;
exports.updateAboutLine = updateAboutLine;
exports.updateSeq = updateSeq;
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询关于页数据接口
function queryAboutAll(data) {
  return (0, _request.default)({
    url: '/cms/about/all',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//上线、下线关于页数据接口
function updateAboutLine(data, id) {
  return (0, _request.default)({
    url: '/cms/about/line/' + id,
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加关于页数据接口
function addAbout(data) {
  return (0, _request.default)({
    url: '/cms/about',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改关于页数据接口
function updateAbout(data, id) {
  return (0, _request.default)({
    url: '/cms/about/' + id,
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改关于页数据序号
function updateSeq(data, id) {
  return (0, _request.default)({
    url: '/cms/about/seq/' + id,
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//图片上传接口
function upload(data) {
  return (0, _request.default)({
    url: 'cms/picture/upload',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除产品页详情接口
function deleAbouts(id) {
  return (0, _request.default)({
    url: '/cms/about/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}