"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getWarrantyCard = getWarrantyCard;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询保修卡
function getWarrantyCard(data) {
  return (0, _request.default)({
    url: 'equipment/warranty',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}