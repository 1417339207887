var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderInfo" },
    [
      _c("div", { staticClass: "setSearch", on: { click: _vm.setSearch } }, [
        _c("img", { attrs: { src: require("../../assets/img/setting.png") } }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _vm.flagList[0].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("渠道")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.dealer,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dealer", $$v)
                        },
                        expression: "form.dealer",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[1].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("订单号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderNo", $$v)
                      },
                      expression: "form.orderNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[2].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("订单序号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.serial,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "serial", $$v)
                      },
                      expression: "form.serial",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[3].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("交易号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.tradeNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tradeNo", $$v)
                      },
                      expression: "form.tradeNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[4].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("优惠码")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.promoCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "promoCode", $$v)
                      },
                      expression: "form.promoCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[5].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("类型")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.orderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "orderType", $$v)
                        },
                        expression: "form.orderType",
                      },
                    },
                    _vm._l(_vm.type, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[6].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("联系人邮箱")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.contactEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactEmail", $$v)
                      },
                      expression: "form.contactEmail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[7].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("联系人手机号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.contactTelephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactTelephone", $$v)
                      },
                      expression: "form.contactTelephone",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[8].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("流水号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.serialNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "serialNumber", $$v)
                      },
                      expression: "form.serialNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[9].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("订单状态")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.types, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[10].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("支付方式")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payType", $$v)
                        },
                        expression: "form.payType",
                      },
                    },
                    _vm._l(_vm.payTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[11].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("下单设备")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.payEquipment,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payEquipment", $$v)
                        },
                        expression: "form.payEquipment",
                      },
                    },
                    _vm._l(_vm.equipments, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[12].flag
            ? _c("li", [
                _c("h5", [_vm._v("支付起始时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.startPayTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startPayTime", $$v)
                        },
                        expression: "form.startPayTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[13].flag
            ? _c("li", [
                _c("h5", [_vm._v("支付截止时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.endPayTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endPayTime", $$v)
                        },
                        expression: "form.endPayTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/info/export",
                  expression: "'/order/info/export'",
                },
              ],
              on: { click: _vm.derive },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/derive.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("导出")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/info/pay/callback",
                  expression: "'/order/info/pay/callback'",
                },
              ],
              on: { click: _vm.callBack },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/account.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("支付回调")]),
            ]
          ),
          _vm._v(" "),
          _c("li", { on: { click: _vm.details } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/detail.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("订单详情")]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _vm.showColumn.operate
            ? _c("el-table-column", {
                attrs: { align: "center", label: "操作", width: "150" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            { staticClass: "function-icon" },
                            [
                              !scope.row.orderNo.includes("~")
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "复制链接",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard",
                                            value: function () {
                                              return _vm.handleCopy(scope)
                                            },
                                            expression:
                                              "() => handleCopy(scope)",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.clipboardSuccessHandler,
                                            expression:
                                              "clipboardSuccessHandler",
                                            arg: "success",
                                          },
                                        ],
                                        attrs: {
                                          src: require("../../assets/iconfont/copy.png"),
                                          alt: "",
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !scope.row.orderNo.includes("~")
                                ? _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "邮件补发",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/iconfont/emails.png"),
                                          alt: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.emailReissue(scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !scope.row.orderNo.includes("~")
                                ? _c(
                                    "el-tooltip",
                                    {
                                      directives: [
                                        {
                                          name: "permit",
                                          rawName: "v-permit",
                                          value:
                                            "/order/info/update/logistics/price",
                                          expression:
                                            "'/order/info/update/logistics/price'",
                                        },
                                      ],
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "修改运费",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/iconfont/amend.png"),
                                          alt: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.change(scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !scope.row.orderNo.includes("~")
                                ? _c(
                                    "el-tooltip",
                                    {
                                      directives: [
                                        {
                                          name: "permit",
                                          rawName: "v-permit",
                                          value: "/order/info/change/group",
                                          expression:
                                            "'/order/info/change/group'",
                                        },
                                      ],
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "修改套餐",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/iconfont/changeorder.png"),
                                          alt: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.changeOrder(scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              scope.row.status == "待发货" &&
                              !scope.row.orderNo.includes("~")
                                ? _c(
                                    "el-tooltip",
                                    {
                                      directives: [
                                        {
                                          name: "permit",
                                          rawName: "v-permit",
                                          value: "/order/customer/return/money",
                                          expression:
                                            "'/order/customer/return/money'",
                                        },
                                      ],
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "申请退款",
                                        placement: "bottom",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: require("../../assets/iconfont/returnMoney.png"),
                                          alt: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.orderApply(scope.row)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  367852795
                ),
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.dealer
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "渠道",
                  "show-overflow-tooltip": "",
                  prop: "dealer",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.orderNo
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单号",
                  "show-overflow-tooltip": "",
                  prop: "orderNo",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.serial
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单序号",
                  "show-overflow-tooltip": "",
                  prop: "serial",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.tradeNo
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "交易号",
                  "show-overflow-tooltip": "",
                  prop: "tradeNo",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.orderType
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "类型",
                  "show-overflow-tooltip": "",
                  formatter: _vm.ordertype,
                  prop: "orderType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.unit
            ? _c("el-table-column", {
                attrs: { align: "center", label: "币种", prop: "unit" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.price
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "价格",
                  "show-overflow-tooltip": "",
                  prop: "price",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.productTotalPrice
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品总价",
                  "show-overflow-tooltip": "",
                  prop: "productTotalPrice",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.discountPrice
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "优惠金额",
                  "show-overflow-tooltip": "",
                  prop: "discountPrice",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.contactEmail
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系人邮箱",
                  "show-overflow-tooltip": "",
                  prop: "contactEmail",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.contactTelephone
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系人手机号",
                  "show-overflow-tooltip": "",
                  prop: "contactTelephone",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.status
            ? _c("el-table-column", {
                attrs: { align: "center", label: "订单状态", prop: "status" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.promoCode
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "优惠码",
                  "show-overflow-tooltip": "",
                  prop: "promoCode",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.payType
            ? _c("el-table-column", {
                attrs: { align: "center", label: "支付方式", prop: "payType" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.payEquipment
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "下单设备",
                  formatter: _vm.equipment,
                  prop: "payEquipment",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.payCounts
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "支付次数",
                  prop: "payCounts",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.payBrowser
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "支付浏览器",
                  "show-overflow-tooltip": "",
                  prop: "payBrowser",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.repeatPayType
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "多次支付方式",
                  "show-overflow-tooltip": "",
                  prop: "repeatPayType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.serialNumber
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "流水号",
                  "show-overflow-tooltip": "",
                  prop: "serialNumber",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.pendingCode
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "paypal实际支付状态",
                  "show-overflow-tooltip": "",
                  prop: "pendingCode",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.batchNo
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "预售批次",
                  "show-overflow-tooltip": "",
                  prop: "batchNo",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.deliverCycle
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "预售时间",
                  "show-overflow-tooltip": "",
                  prop: "deliverCycle",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.payTime
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "支付时间",
                  "show-overflow-tooltip": "",
                  prop: "payTime",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.updateTime
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "状态时间",
                  "show-overflow-tooltip": "",
                  prop: "updateTime",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showColumn.remark
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "备注",
                  "show-overflow-tooltip": "",
                  prop: "remark",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next,jumper",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支付回调",
            visible: _vm.dialogForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogForm = $event
            },
            close: _vm.logisticsClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "callBacks",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.callBacks,
                rules: _vm.payrule,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "支付方式",
                    "label-width": _vm.formLabelWidth,
                    prop: "payId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.callBacks.payId,
                        callback: function ($$v) {
                          _vm.$set(_vm.callBacks, "payId", $$v)
                        },
                        expression: "callBacks.payId",
                      },
                    },
                    _vm._l(_vm.payTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "交易号",
                    "label-width": _vm.formLabelWidth,
                    prop: "outTradeNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.callBacks.outTradeNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.callBacks, "outTradeNo", $$v)
                      },
                      expression: "callBacks.outTradeNo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.logisticsClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keeps },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改运费",
            visible: _vm.dialogForms,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogForms = $event
            },
            close: _vm.changeClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "changeform",
              attrs: {
                model: _vm.changeform,
                rules: _vm.changerule,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.changeform.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "orderNo", $$v)
                      },
                      expression: "changeform.orderNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "币种",
                    "label-width": _vm.formLabelWidth,
                    prop: "unit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.changeform.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "unit", $$v)
                      },
                      expression: "changeform.unit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "运费",
                    "label-width": _vm.formLabelWidth,
                    prop: "logisticsPrice",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      oninput:
                        "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                      maxlength: "9",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.changeform.logisticsPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "logisticsPrice", $$v)
                      },
                      expression: "changeform.logisticsPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.changeform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "remark", $$v)
                      },
                      expression: "changeform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.changeClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sure },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "detail",
          attrs: {
            title: "订单详情",
            visible: _vm.dialogsForm,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogsForm = $event
            },
            close: _vm.detailClose,
          },
        },
        [
          _vm._l(_vm.detailList, function (res) {
            return _c(
              "el-form",
              {
                ref: "detailform",
                refInFor: true,
                staticStyle: {
                  "border-bottom": "5px solid #000",
                  "padding-bottom": "20px",
                },
                attrs: {
                  model: _vm.detailform,
                  "status-icon": true,
                  "show-message": false,
                  "hide-required-asterisk": "",
                },
              },
              [
                _vm._l(res.orderInfoDetailList, function (item) {
                  return _c("div", [
                    !item.orderNo.includes("~")
                      ? _c(
                          "div",
                          [
                            _c(
                              "table",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "border-spacing": "0px 10px",
                                  "margin-top": "10px",
                                },
                                attrs: { border: "0", cellspacing: "1" },
                              },
                              [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { staticStyle: { width: "400px" } },
                                    [
                                      _vm._v("订单号："),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            background: "rgb(83,217,213)",
                                            padding: "5px 10px",
                                            "font-weight": "bold",
                                            color: "#fff",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.orderNo))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  item.orderType == "0"
                                    ? _c("td", [_vm._v("订单类型：预售")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.orderType == "1"
                                    ? _c("td", [_vm._v("订单类型：订购")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("状态：" + _vm._s(item.status)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v("订单总价：" + _vm._s(item.price)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "商品总价： " + _vm._s(item.productPrice)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("币种：" + _vm._s(item.unit)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v("渠道：" + _vm._s(item.dealer)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "支付方式： " + _vm._s(item.payType)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  item.payEquipment == "1"
                                    ? _c("td", [_vm._v("支付设备：PC")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.payEquipment == "2"
                                    ? _c("td", [_vm._v("支付设备：移动端")])
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v("交易号：" + _vm._s(item.tradeNo)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("支付时间：" + _vm._s(item.payTime)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("收货人：" + _vm._s(item.name)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      "收货人手机号：" + _vm._s(item.telephone)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "联系人邮箱：" + _vm._s(item.contactEmail)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "联系人手机号：" +
                                        _vm._s(item.contactTelephone)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      "物流单号(名称)：" +
                                        _vm._s(item.logisticsNumber) +
                                        "(" +
                                        _vm._s(item.logisticsName) +
                                        ")"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "发货时间：" + _vm._s(item.deliverTime)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "运费：" + _vm._s(item.logisticsPrice)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      "优惠金额：" + _vm._s(item.discountPrice)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "设备号：" + _vm._s(item.equipmentNumber)
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("地址：" + _vm._s(item.address)),
                            ]),
                            _vm._v(" "),
                            item.promoDecide
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "20px",
                                      color: "#000",
                                      "font-size": "16px",
                                    },
                                  },
                                  [_vm._v("优惠码")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.promoDecide
                              ? _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    staticStyle: {
                                      width: "100%",
                                      "margin-top": "30px",
                                    },
                                    attrs: { data: item.promoList, border: "" },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "优惠类型",
                                        "show-overflow-tooltip": "",
                                        prop: "promoType",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "优惠码",
                                        "show-overflow-tooltip": "",
                                        prop: "promoCode",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "币种",
                                        "show-overflow-tooltip": "",
                                        prop: "unit",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "金额",
                                        "show-overflow-tooltip": "",
                                        prop: "promoPrice",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "礼品名称",
                                        "show-overflow-tooltip": "",
                                        prop: "giftName",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "20px",
                                  color: "#000",
                                  "font-size": "18px",
                                },
                              },
                              [_vm._v("订单摘要")]
                            ),
                            _vm._v(" "),
                            _vm._l(item.productList, function (result) {
                              return _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "border-spacing": "0px 10px",
                                      },
                                      attrs: { border: "0", cellspacing: "1" },
                                    },
                                    [
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: {
                                            color: "#000",
                                            "font-weight": "500",
                                          },
                                        },
                                        [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "400px" } },
                                            [
                                              _vm._v(
                                                "产品名称：" +
                                                  _vm._s(result.productName)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "金额：" + _vm._s(result.price)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "数量：" + _vm._s(result.count)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "15px",
                                        "line-height": "20px",
                                      },
                                    },
                                    [
                                      _c("div", [_vm._v("包含：")]),
                                      _vm._v(" "),
                                      _vm._l(
                                        result.productContainsVOList,
                                        function (ite, index) {
                                          return _c("div", [
                                            ite
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(index + 1) +
                                                      ". " +
                                                      _vm._s(ite.productNo) +
                                                      "   " +
                                                      _vm._s(
                                                        ite.productNameCn
                                                      ) +
                                                      "-1"
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [_vm._v(_vm._s(ite.position))]
                                            ),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      result.additionalVOList &&
                                      result.additionalVOList.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "auto",
                                                borderTop: "1px solid #707070",
                                                marginTop: "20px",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                result.additionalVOList,
                                                function (ite) {
                                                  return ite.type == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            paddingTop: "5px",
                                                            borderBottom:
                                                              "1px solid #707070",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "space-between",
                                                                alignItems:
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    fontSize:
                                                                      "32",
                                                                    fontWeight:
                                                                      "400",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "免费激光镌刻"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                padding:
                                                                  "5px 0",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "space-between",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "内容：" +
                                                                    _vm._s(
                                                                      ite.content
                                                                    )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "字体：" +
                                                                    _vm._s(
                                                                      ite.font
                                                                    )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "位置：" +
                                                                    _vm._s(
                                                                      ite.position
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                result.additionalVOList,
                                                function (ite) {
                                                  return ite.type == 3
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            height: "50px",
                                                            display: "flex",
                                                            justifyContent:
                                                              "space-between",
                                                            alignItems:
                                                              "center",
                                                            borderBottom:
                                                              "1px solid #707070",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                fontSize:
                                                                  "16px",
                                                                fontWeight:
                                                                  "400",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "免费包装选择"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                paddingLeft:
                                                                  "13px",
                                                                border:
                                                                  "1px solid #707070",
                                                                paddingRight:
                                                                  "13px",
                                                                height: "35px",
                                                                fontSize:
                                                                  "13px",
                                                                backgroundColor:
                                                                  "#707070",
                                                                fontWeight:
                                                                  "400",
                                                                marginRight:
                                                                  "16px",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: require("../../assets/img/check-white.png"),
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    marginLeft:
                                                                      "10px",
                                                                    color:
                                                                      "#ffffff",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "礼品包装"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "备注：",
                                  "label-width": "58px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n           " +
                                    _vm._s(item.remark) +
                                    "\n        "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            res.flag == "Y"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "15px",
                                      "margin-bottom": "7px",
                                      color: "#409EFF",
                                      "font-size": "18px",
                                    },
                                  },
                                  [_vm._v("补录单信息：")]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.orderNo.includes("~")
                      ? _c("div", [
                          _c("table", [
                            _c("th", [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "font-weight": "200",
                                    "font-size": "13px",
                                  },
                                  attrs: { width: "205" },
                                },
                                [_vm._v("订单编号：  " + _vm._s(item.orderNo))]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "font-weight": "200",
                                    "font-size": "13px",
                                  },
                                  attrs: { width: "300" },
                                },
                                [_vm._v("金额：" + _vm._s(item.price))]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                  },
                                  attrs: { width: "200" },
                                },
                                [_vm._v(" 付款日期： " + _vm._s(item.payTime))]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "table",
                            { staticStyle: { "margin-top": "-8px" } },
                            [
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "200" },
                                  },
                                  [_vm._v(" 付款渠道：" + _vm._s(item.payType))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "300" },
                                  },
                                  [_vm._v(" 交易号：" + _vm._s(item.tradeNo))]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            { staticStyle: { "margin-top": "-8px" } },
                            [
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(" 备注：" + _vm._s(item.remark))]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              key: _vm.index,
                              staticStyle: {
                                border: "1px solid skyblue",
                                "margin-top": "2px",
                                width: "100%",
                              },
                              attrs: { border: "1", cellspacing: "0" },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("序号")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v("商品名称")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "80" },
                                  },
                                  [_vm._v("规格型号")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("数量")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("金额")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v("备注")]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                item.productList,
                                function (result, index) {
                                  return _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(index + 1))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.productName))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.productNo))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.count))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.price))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.productContains))]
                                    ),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "15px",
                      "margin-bottom": "15px",
                      color: "#115c3b",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v("订单实际商品：")]
                ),
                _vm._v(" "),
                _c(
                  "table",
                  {
                    staticStyle: {
                      border: "1px solid #e8f2ee",
                      "margin-top": "2px",
                      width: "100%",
                    },
                    attrs: { border: "1", cellspacing: "0" },
                  },
                  [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                          attrs: { width: "50" },
                        },
                        [_vm._v("产品名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                          attrs: { width: "50" },
                        },
                        [_vm._v("产品型号")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                          attrs: { width: "50" },
                        },
                        [_vm._v("数量")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(res.realOrderList, function (real) {
                      return _c("tr", [
                        _c(
                          "th",
                          {
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(real.productName))]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(real.productNo))]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(real.count))]
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.detailClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改套餐",
            visible: _vm.dialogFormVisibles,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibles = $event
            },
            close: _vm.salesClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "salesform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.salesform,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderNo",
                  },
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.saleOrderNo) + "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "255",
                      type: "textarea",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.salesform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.salesform, "remark", $$v)
                      },
                      expression: "salesform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", { staticStyle: { "padding-left": "50px" } }, [
                _vm._v("退回摘要"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.isNo },
                      model: {
                        value: _vm.product,
                        callback: function ($$v) {
                          _vm.product = $$v
                        },
                        expression: "product",
                      },
                    },
                    [_vm._v("设备")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      on: { change: _vm.isNo },
                      model: {
                        value: _vm.product,
                        callback: function ($$v) {
                          _vm.product = $$v
                        },
                        expression: "product",
                      },
                    },
                    [_vm._v("封套")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      on: { change: _vm.isNo },
                      model: {
                        value: _vm.product,
                        callback: function ($$v) {
                          _vm.product = $$v
                        },
                        expression: "product",
                      },
                    },
                    [_vm._v("笔")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "4" },
                      on: { change: _vm.isNo },
                      model: {
                        value: _vm.product,
                        callback: function ($$v) {
                          _vm.product = $$v
                        },
                        expression: "product",
                      },
                    },
                    [_vm._v("笔芯")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退货产品",
                        "label-width": _vm.formLabelWidth,
                        prop: "product",
                      },
                    },
                    [
                      _vm.typeflag
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择", filterable: "" },
                              on: { change: _vm.changeProduct },
                              model: {
                                value: _vm.productNos,
                                callback: function ($$v) {
                                  _vm.productNos = $$v
                                },
                                expression: "productNos",
                              },
                            },
                            _vm._l(_vm.saletype, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.coverflag
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProduct },
                              model: {
                                value: _vm.productNos,
                                callback: function ($$v) {
                                  _vm.productNos = $$v
                                },
                                expression: "productNos",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return !item.value.includes("SP") &&
                                !item.value.includes("SR")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.penflag
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProduct },
                              model: {
                                value: _vm.productNos,
                                callback: function ($$v) {
                                  _vm.productNos = $$v
                                },
                                expression: "productNos",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return item.value.includes("SP")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.refillflag
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProduct },
                              model: {
                                value: _vm.productNos,
                                callback: function ($$v) {
                                  _vm.productNos = $$v
                                },
                                expression: "productNos",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return item.value.includes("SR")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", { staticStyle: { "padding-left": "50px" } }, [
                _vm._v("换出摘要"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("设备")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("封套")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("笔")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "4" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("笔芯")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "换货产品",
                        "label-width": _vm.formLabelWidth,
                        prop: "product",
                      },
                    },
                    [
                      _vm.typeflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择", filterable: "" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.saletype, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.coverflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return !item.value.includes("SP") &&
                                !item.value.includes("SR")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.penflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return item.value.includes("SP")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.refillflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return item.value.includes("SR")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.salesClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.changeSure },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "searchName",
          attrs: {
            title: "设置",
            width: "20%",
            visible: _vm.dialogFormSearch,
            "close-on-click-modal": false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormSearch = $event
            },
          },
        },
        [
          _vm._l(_vm.flagList, function (item, i) {
            return _c(
              "div",
              {
                key: i,
                staticStyle: { width: "100%", display: "inline-block" },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "margin-top": "10px",
                      "font-weight": "500",
                    },
                  },
                  [
                    _c("div", [_vm._v(_vm._s(item.value))]),
                    _vm._v(" "),
                    _c("el-switch", {
                      attrs: {
                        "active-color": "#13ce66",
                        "inactive-color": "#bfbfbf",
                      },
                      model: {
                        value: item.flag,
                        callback: function ($$v) {
                          _vm.$set(item, "flag", $$v)
                        },
                        expression: "item.flag",
                      },
                    }),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-bottom": "5px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.searchSure },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "字段配置", visible: _vm.visible },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.visible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveColumn },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c("div", [
              _c("div", [_vm._v("选择显示字段")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.showColumn.operate,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "operate", $$v)
                        },
                        expression: "showColumn.operate",
                      },
                    },
                    [_vm._v("操作")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.dealer,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "dealer", $$v)
                        },
                        expression: "showColumn.dealer",
                      },
                    },
                    [_vm._v("渠道")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.orderNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "orderNo", $$v)
                        },
                        expression: "showColumn.orderNo",
                      },
                    },
                    [_vm._v("订单号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.serial,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "serial", $$v)
                        },
                        expression: "showColumn.serial",
                      },
                    },
                    [_vm._v("订单序号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.tradeNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "tradeNo", $$v)
                        },
                        expression: "showColumn.tradeNo",
                      },
                    },
                    [_vm._v("交易号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.orderType,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "orderType", $$v)
                        },
                        expression: "showColumn.orderType",
                      },
                    },
                    [_vm._v("类型")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.unit,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "unit", $$v)
                        },
                        expression: "showColumn.unit",
                      },
                    },
                    [_vm._v("币种")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.price,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "price", $$v)
                        },
                        expression: "showColumn.price",
                      },
                    },
                    [_vm._v("价格")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.productTotalPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "productTotalPrice", $$v)
                        },
                        expression: "showColumn.productTotalPrice",
                      },
                    },
                    [_vm._v("商品总价")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.discountPrice,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "discountPrice", $$v)
                        },
                        expression: "showColumn.discountPrice",
                      },
                    },
                    [_vm._v("优惠金额")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.contactEmail,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "contactEmail", $$v)
                        },
                        expression: "showColumn.contactEmail",
                      },
                    },
                    [_vm._v("联系人邮箱")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.contactTelephone,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "contactTelephone", $$v)
                        },
                        expression: "showColumn.contactTelephone",
                      },
                    },
                    [_vm._v("联系人手机号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "status", $$v)
                        },
                        expression: "showColumn.status",
                      },
                    },
                    [_vm._v("订单状态")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.promoCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "promoCode", $$v)
                        },
                        expression: "showColumn.promoCode",
                      },
                    },
                    [_vm._v("优惠码")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "payType", $$v)
                        },
                        expression: "showColumn.payType",
                      },
                    },
                    [_vm._v("支付方式")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.payEquipment,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "payEquipment", $$v)
                        },
                        expression: "showColumn.payEquipment",
                      },
                    },
                    [_vm._v("下单设备")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.payCounts,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "payCounts", $$v)
                        },
                        expression: "showColumn.payCounts",
                      },
                    },
                    [_vm._v("支付次数")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.payBrowser,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "payBrowser", $$v)
                        },
                        expression: "showColumn.payBrowser",
                      },
                    },
                    [_vm._v("支付浏览器")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.repeatPayType,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "repeatPayType", $$v)
                        },
                        expression: "showColumn.repeatPayType",
                      },
                    },
                    [_vm._v("多次支付方式")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.serialNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "serialNumber", $$v)
                        },
                        expression: "showColumn.serialNumber",
                      },
                    },
                    [_vm._v("流水号")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.pendingCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "pendingCode", $$v)
                        },
                        expression: "showColumn.pendingCode",
                      },
                    },
                    [_vm._v("paypal实际支付状态")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.batchNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "batchNo", $$v)
                        },
                        expression: "showColumn.batchNo",
                      },
                    },
                    [_vm._v("预售批次")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.deliverCycle,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "deliverCycle", $$v)
                        },
                        expression: "showColumn.deliverCycle",
                      },
                    },
                    [_vm._v("预售时间")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.payTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "payTime", $$v)
                        },
                        expression: "showColumn.payTime",
                      },
                    },
                    [_vm._v("支付时间")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.updateTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "updateTime", $$v)
                        },
                        expression: "showColumn.updateTime",
                      },
                    },
                    [_vm._v("状态时间")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.showColumn.remark,
                        callback: function ($$v) {
                          _vm.$set(_vm.showColumn, "remark", $$v)
                        },
                        expression: "showColumn.remark",
                      },
                    },
                    [_vm._v("备注")]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }