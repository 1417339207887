var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "changeapp" },
    [
      _c(
        "el-form",
        {
          ref: "changeform",
          attrs: {
            model: _vm.changeform,
            rules: _vm.changeRules,
            "status-icon": true,
            "show-message": false,
            "hide-required-asterisk": "",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题CN",
                "label-width": _vm.formLabelWidth,
                prop: "titleCn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.changeform.titleCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.changeform, "titleCn", $$v)
                  },
                  expression: "changeform.titleCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题EN",
                "label-width": _vm.formLabelWidth,
                prop: "titleEn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.changeform.titleEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.changeform, "titleEn", $$v)
                  },
                  expression: "changeform.titleEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题JA",
                "label-width": _vm.formLabelWidth,
                prop: "titleJa",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.changeform.titleJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.changeform, "titleJa", $$v)
                  },
                  expression: "changeform.titleJa",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "ios跳转地址",
                "label-width": _vm.formLabelWidth,
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.changeform.urlIpa,
                  callback: function ($$v) {
                    _vm.$set(_vm.changeform, "urlIpa", $$v)
                  },
                  expression: "changeform.urlIpa",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commont" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "区域",
                    "label-width": _vm.formLabelWidth,
                    prop: "area",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.changeform.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.changeform, "area", $$v)
                        },
                        expression: "changeform.area",
                      },
                    },
                    _vm._l(_vm.areas, function (item) {
                      return _c("el-option", {
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "安卓下载",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "upload-wrapper" }, [
                    _c(
                      "a",
                      { attrs: { href: _vm.urlApk } },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_vm._v("下载")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commont" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "缩略图",
                    "label-width": _vm.formLabelWidth,
                    prop: "thumbnailUrl",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "show-file-list": false,
                        "before-upload": _vm.beforeUploadPicture1,
                      },
                    },
                    [
                      _vm.imageUrl1
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl1 },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "pc二维码地址",
                    "label-width": _vm.formLabelWidth,
                    prop: "qrCodeUrl",
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "show-file-list": false,
                        "before-upload": _vm.beforeUploadPicture2,
                      },
                    },
                    [
                      _vm.imageUrl2
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl2 },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ul", [
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述CN")]),
            _vm._v(" "),
            _c(
              "el-upload",
              {
                staticClass: "avatar-uploader",
                staticStyle: { display: "none" },
                attrs: {
                  action: "",
                  "http-request": _vm.to_upload_img,
                  "show-file-list": false,
                },
              },
              [
                _vm._v("\n      >\n      "),
                _c("i", {
                  staticClass: "el-icon-plus avatar-uploader-icon",
                  attrs: { id: "imgInput" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.changeform.descriptionCn,
                callback: function ($$v) {
                  _vm.$set(_vm.changeform, "descriptionCn", $$v)
                },
                expression: "changeform.descriptionCn",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述EN")]),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor1",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.changeform.descriptionEn,
                callback: function ($$v) {
                  _vm.$set(_vm.changeform, "descriptionEn", $$v)
                },
                expression: "changeform.descriptionEn",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述JA")]),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor2",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.changeform.descriptionJa,
                callback: function ($$v) {
                  _vm.$set(_vm.changeform, "descriptionJa", $$v)
                },
                expression: "changeform.descriptionJa",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.savedisab },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }