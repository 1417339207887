"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取订单信息
function getLists(data) {
  return (0, _request.default)({
    url: '/order/life/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}