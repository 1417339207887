var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addpro" }, [
    _c(
      "div",
      { staticClass: "addhead" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              "status-icon": true,
              "show-message": false,
              "hide-required-asterisk": "",
            },
          },
          [
            _c(
              "div",
              { staticClass: "commont" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "序号", "label-width": _vm.formLabelWidth },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        autocomplete: "off",
                        type: "text",
                        maxlength: "9",
                      },
                      model: {
                        value: _vm.form.seq,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "seq", $$v)
                        },
                        expression: "form.seq",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: { label: "类型", "label-width": _vm.formLabelWidth },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.handleChangeFirst },
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      _vm._l(_vm.typelist, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.type },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.type != ""
              ? _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "标题",
                      "label-width": _vm.formLabelWidth,
                      prop: "faqTitleId",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.faqTitleId,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "faqTitleId", $$v)
                          },
                          expression: "form.faqTitleId",
                        },
                      },
                      _vm._l(_vm.faqTit, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.titleCn, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "问题CN",
                  "label-width": _vm.formLabelWidth,
                  prop: "questionCn",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "255",
                    autocomplete: "off",
                    type: "text",
                  },
                  model: {
                    value: _vm.form.questionCn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "questionCn", $$v)
                    },
                    expression: "form.questionCn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "问题EN",
                  "label-width": _vm.formLabelWidth,
                  prop: "questionEn",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "255",
                    autocomplete: "off",
                    type: "text",
                  },
                  model: {
                    value: _vm.form.questionEn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "questionEn", $$v)
                    },
                    expression: "form.questionEn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "问题JA",
                  "label-width": _vm.formLabelWidth,
                  prop: "questionJa",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "255",
                    autocomplete: "off",
                    type: "text",
                  },
                  model: {
                    value: _vm.form.questionJa,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "questionJa", $$v)
                    },
                    expression: "form.questionJa",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "commont" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "视频CN",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "upload-wrapper" },
                      [
                        _c("input", {
                          ref: "uploadFileCn",
                          staticClass: "upload-file-cn",
                          attrs: {
                            id: "fileCn",
                            type: "file",
                            name: "fileCn",
                            multiple: "",
                          },
                          on: { change: _vm.submitUploadCn },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingCn,
                                expression: "loadingCn",
                              },
                            ],
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_vm._v("上传")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.videoflagsCn
                      ? _c("video", {
                          staticClass: "video",
                          attrs: {
                            width: "300px",
                            src: _vm.videoSrcCn,
                            controls: "controls",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "视频EN",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "upload-wrapper" },
                      [
                        _c("input", {
                          ref: "uploadFileEn",
                          staticClass: "upload-file-en",
                          attrs: {
                            id: "fileEn",
                            type: "file",
                            name: "fileEn",
                            multiple: "",
                          },
                          on: { change: _vm.submitUploadEn },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingEn,
                                expression: "loadingEn",
                              },
                            ],
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_vm._v("上传")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.videoflagsEn
                      ? _c("video", {
                          staticClass: "video",
                          attrs: {
                            width: "300px",
                            src: _vm.videoSrcEn,
                            controls: "controls",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "视频JA",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "upload-wrapper" },
                      [
                        _c("input", {
                          ref: "uploadFileJa",
                          staticClass: "upload-file-ja",
                          attrs: {
                            id: "fileJa",
                            type: "file",
                            name: "fileJa",
                            multiple: "",
                          },
                          on: { change: _vm.submitUploadJa },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingJa,
                                expression: "loadingJa",
                              },
                            ],
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_vm._v("上传")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.videoflagsJa
                      ? _c("video", {
                          staticClass: "video",
                          attrs: {
                            width: "300px",
                            src: _vm.videoSrcJa,
                            controls: "controls",
                          },
                        })
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("答案CN")]),
              _vm._v(" "),
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  staticStyle: { display: "none" },
                  attrs: {
                    action: "",
                    "http-request": _vm.to_upload_img,
                    "show-file-list": false,
                  },
                },
                [
                  _vm._v("\n          >\n          "),
                  _c("i", {
                    staticClass: "el-icon-plus avatar-uploader-icon",
                    attrs: { id: "imgInput" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                on: {
                  change: function ($event) {
                    return _vm.onEditchange($event)
                  },
                },
                model: {
                  value: _vm.form.answerCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "answerCn", $$v)
                  },
                  expression: "form.answerCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("答案EN")]),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor1",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.answerEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "answerEn", $$v)
                  },
                  expression: "form.answerEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("答案JA")]),
              _vm._v(" "),
              _c("quill-editor", {
                ref: "myQuillEditor2",
                attrs: { options: _vm.editorOption, "show-word-limit": "" },
                model: {
                  value: _vm.form.answerJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "answerJa", $$v)
                  },
                  expression: "form.answerJa",
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: _vm.isDisable },
            on: { click: _vm.submit },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }