"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _trends = require("../../api/trends.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      value: "",
      id: "",
      formDate: [],
      form: {
        id: "",
        priceRangeCn: "",
        priceRangeEn: "",
        priceRangeJa: "",
        serialNumber: "",
        type: ""
      },
      formLabelWidth: "120px",
      formRules: {
        priceRangeCn: [{
          required: true,
          trigger: "blur"
        }],
        priceRangeEn: [{
          required: true,
          trigger: "blur"
        }],
        priceRangeJa: [{
          required: true,
          trigger: "blur"
        }]
      },
      isDisable: false
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.getList();
  },
  mounted: function mounted() {},
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _trends.price)(this.id).then(function (res) {
        _this.form = res.voT;
        _this.loading = false;
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //提交
    submit: function submit() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this2.isDisable = true;
          (0, _trends.updatePrice)(_this2.form, _this2.id).then(function (res) {
            if (res.success) {
              _this2.msgTip("success", "修改成功");
              _this2.$store.dispatch("tagsView/delView", _this2.$route).then(function () {
                _this2.$nextTick(function () {
                  _this2.$router.replace({
                    path: "/trends",
                    query: {
                      cur: _this2.$route.query.cur,
                      line: _this2.$route.query.line
                    }
                  });
                });
              });
            } else {
              _this2.msgTip("error", res.errorMsg);
              _this2.isDisable = false;
            }
          });
        }
      });
    }
  }
};