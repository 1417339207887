"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.login = login;
var _request = _interopRequireDefault(require("@/utils/request"));
function login(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/login',
    method: 'post',
    data: data
  });
}
function getInfo(id) {
  return (0, _request.default)({
    url: 'system/base/resource/left/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 600000
  });
}

// export function logout() {
//     return request({
//         url: '/user/logout',
//         headers: {
//             "x-access-token": window.localStorage.getItem('token')
//         },
//         method: 'post'
//     })
// }