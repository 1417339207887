"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _sallCode = require("../../api/sallCode.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        endDate: "",
        isSend: "",
        pageNo: 1,
        pageSize: 10,
        orderNo: "",
        promoCode: "",
        promoRule: "",
        startDate: "",
        useState: ""
      },
      isCodes: [{
        value: "1",
        valueCn: "是"
      }, {
        value: "2",
        valueCn: "否"
      }],
      isCode: "2",
      isSends: [{
        value: "0",
        valueCn: "未发送"
      }, {
        value: "1",
        valueCn: "已发送"
      }],
      useStates: [{
        value: "0",
        valueCn: "未使用"
      }, {
        value: "1",
        valueCn: "正在使用"
      }, {
        value: "2",
        valueCn: "已使用"
      }],
      promoRule: [],
      promoRules: [],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      dialogFormVisible: false,
      formLabelWidth: "140px",
      addform: {
        endDate: "",
        generateCount: "",
        promoCode: "",
        promoRule: "",
        startDate: "",
        useTimes: ""
      },
      addRules: {
        promoRule: [{
          required: true,
          trigger: "change"
        }],
        generateCount: [{
          required: true,
          trigger: "blur"
        }],
        useTimes: [{
          required: true,
          trigger: "blur"
        }],
        startDate: [{
          required: true,
          trigger: "blur"
        }],
        endDate: [{
          required: true,
          trigger: "blur"
        }],
        promoCode: [{
          required: true,
          trigger: "blur"
        }]
      },
      availableCount: "",
      totalCount: "",
      codeflag: false,
      isDisable: false,
      dialogFormVisibles: false,
      dialogForm: false,
      changeform: {
        endDate: '',
        remark: '',
        startDate: '',
        useTimes: ''
      },
      changeRules: {
        endDate: [{
          required: true,
          trigger: "change"
        }],
        remark: [{
          required: true,
          trigger: "blur"
        }],
        useTimes: [{
          required: true,
          trigger: "blur"
        }],
        startDate: [{
          required: true,
          trigger: "change"
        }]
      },
      sendform: {
        coverNo: '',
        email: '',
        isLink: '0',
        isPackage: '',
        language: '',
        penNo: '',
        productNo: '',
        promoCode: '',
        remark: ''
      },
      sendRules: {
        coverNo: [{
          required: true,
          trigger: "change"
        }],
        isLink: [{
          required: true,
          trigger: "change"
        }],
        isPackage: [{
          required: true,
          trigger: "change"
        }],
        language: [{
          required: true,
          trigger: "change"
        }],
        penNo: [{
          required: true,
          trigger: "change"
        }],
        productNo: [{
          required: true,
          trigger: "change"
        }],
        promoCode: [{
          required: true,
          trigger: "blur"
        }]
      },
      languages: [{
        language: "ZH-CN",
        label: "中文"
      }, {
        language: "EN",
        label: "英文"
      }, {
        language: "JA",
        label: "日文"
      }],
      flag: false,
      flag1: false,
      flag2: false,
      type: [],
      jacket: [],
      newList: [],
      multipleSelection: [],
      idList: [],
      id: '',
      sendId: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _sallCode.searchRule)(0).then(function (res) {
      if (res.success) {
        _this.promoRules = res.voList;
      }
    });
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _sallCode.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _sallCode.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    isSend: function isSend(row) {
      if (row.isSend == "0") {
        return "未发送";
      } else if (row.isSend == "1") {
        return "已发送";
      }
    },
    useState: function useState(row) {
      if (row.useState == "0") {
        return "未使用";
      } else if (row.useState == "1") {
        return "正在使用";
      } else if (row.useState == "2") {
        return "已使用";
      }
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.endDate = "";
      this.form.isSend = "";
      this.form.orderNo = "";
      this.form.promoCode = "";
      this.form.promoRule = "";
      this.form.startDate = "";
      this.form.useState = "";
      this.getList();
    },
    changeNum: function changeNum(v) {
      var _this3 = this;
      (0, _sallCode.searchCode)({
        promoRule: v
      }).then(function (res) {
        _this3.totalCount = res.totalCount;
        _this3.availableCount = res.availableCount;
      });
    },
    changeCodes: function changeCodes(v) {
      if (v == "1") {
        this.codeflag = true;
        this.addform.generateCount = 1;
      } else {
        this.codeflag = false;
        this.addform.generateCount = "";
      }
    },
    //发放
    grant: function grant(v) {
      this.dialogFormVisibles = true;
      this.sendform.promoCode = v.promoCode;
      this.sendform.remark = v.remark;
      this.sendId = v.id;
      if (v.isSend == "1") {
        this.dialogFormVisibles = false;
        this.$message({
          type: "warning",
          message: '该优惠码已发送',
          center: true
        });
      }
    },
    link: function link(v) {
      if (v == '1') {
        this.flag = true;
      } else {
        this.flag = false;
        this.flag1 = false;
        this.flag2 = false;
      }
    },
    //发放关闭
    closes: function closes() {
      this.dialogFormVisibles = false;
      this.$refs.sendform.resetFields();
      this.sendform.email = '';
      this.flag = false;
      this.flag1 = false;
      this.flag2 = false;
    },
    isPack: function isPack(v) {
      var _this4 = this;
      if (v == '0') {
        this.flag1 = true;
        this.flag2 = false;
        (0, _dictionary.searchDiclist)({
          name: "PRODUCT_TYPE",
          pageNo: 1,
          pageSize: 100,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this4.type = res.voList;
        });
        (0, _dictionary.searchDiclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this4.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this4.jacket = _this4.newList;
          }
        });
      } else if (v == '1') {
        this.flag2 = true;
        this.flag1 = false;
        (0, _dictionary.searchDiclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this4.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this4.jacket = _this4.newList;
          }
        });
      }
    },
    //发放确定
    sendCode: function sendCode() {
      var _this5 = this;
      this.$refs.sendform.validate(function (valid) {
        if (valid) {
          _this5.isDisable = true;
          (0, _sallCode.sendCodes)(_this5.sendform, _this5.sendId).then(function (res) {
            if (res.success) {
              _this5.$message({
                type: "success",
                message: "发放成功",
                center: true
              });
              _this5.dialogFormVisibles = false;
              _this5.getList();
              _this5.isDisable = false;
              _this5.$refs.sendform.resetFields();
              _this5.sendform.remark = '';
              _this5.sendform.email = '';
              _this5.flag = false;
              _this5.flag1 = false;
              _this5.flag2 = false;
            } else {
              _this5.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this5.isDisable = false;
            }
          });
        }
      });
    },
    //生成优惠码
    add: function add() {
      var _this6 = this;
      this.dialogFormVisible = true;
      (0, _sallCode.searchRule)(1).then(function (res) {
        if (res.success) {
          _this6.promoRule = res.voList;
        }
      });
    },
    changeGenerateCount: function changeGenerateCount(v) {
      console.log(v, '123213');
      if (v > this.availableCount) {
        this.$message({
          type: "warning",
          message: "生成数量不能大于可用数量",
          center: true
        });
      } else {
        return false;
      }
    },
    //生成优惠码关闭
    close: function close() {
      this.dialogFormVisible = false;
    },
    //生成优惠码确定
    sure: function sure() {
      var _this7 = this;
      this.$refs.addform.validate(function (valid) {
        if (valid) {
          _this7.isDisable = true;
          (0, _sallCode.generateCode)(_this7.addform).then(function (res) {
            if (res.success) {
              _this7.$message({
                type: "success",
                message: "生成优惠码",
                center: true
              });
              _this7.dialogFormVisible = false;
              _this7.getList();
              _this7.isDisable = false;
              _this7.$refs.addform.resetFields();
              _this7.totalCount = "";
              _this7.availableCount = "";
              _this7.addform.generateCount = "";
            } else {
              _this7.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this7.isDisable = false;
            }
          });
        }
      });
    },
    //删除
    dele: function dele() {
      var _this8 = this;
      if (this.multipleSelection.length < 1) {
        this.$message({
          type: "warning",
          message: "请选择至少一条数据",
          center: true
        });
      } else {
        this.$confirm("您是否要删除当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this8.multipleSelection.map(function (res) {
            _this8.idList.push(res.id);
            console.log(res.isSend, '00000');
            if (res.isSend == "0") {
              _this8.$message({
                type: "warning",
                message: "不可以删除未发送的数据",
                center: true
              });
              return false;
            } else {
              (0, _sallCode.deleCode)({
                idList: _this8.idList
              }).then(function (res) {
                if (res.success) {
                  _this8.$message({
                    type: "success",
                    message: "删除成功",
                    center: true
                  });
                  _this8.getList();
                  _this8.idList = [];
                } else {
                  _this8.$message({
                    type: "error",
                    message: res.errorMsg,
                    center: true
                  });
                }
              });
            }
          });
        });
      }
    },
    //修改
    change: function change(v) {
      var _this9 = this;
      this.dialogForm = true;
      this.id = v.id;
      (0, _sallCode.aloneCode)(v.id).then(function (res) {
        if (res.success) {
          _this9.changeform.endDate = res.voT.endDate;
          _this9.changeform.startDate = res.voT.startDate;
          _this9.changeform.remark = res.voT.remark;
          _this9.changeform.useTimes = res.voT.useTimes;
        }
      });
    },
    keep: function keep() {
      var _this10 = this;
      this.$refs.changeform.validate(function (valid) {
        if (valid) {
          _this10.isDisable = true;
          (0, _sallCode.changeCode)(_this10.changeform, _this10.id).then(function (res) {
            if (res.success) {
              _this10.$message({
                type: "success",
                message: "修改成功",
                center: true
              });
              _this10.dialogForm = false;
              _this10.getList();
              _this10.isDisable = false;
              _this10.$refs.changeform.resetFields();
            } else {
              _this10.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this10.isDisable = false;
            }
          });
        }
      });
    },
    changeclose: function changeclose() {
      this.dialogForm = false;
      this.$refs.changeform.resetFields();
    }
  }
};