"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var echarts = _interopRequireWildcard(require("echarts"));
var _charts = require("../../api/charts.js");
//
//
//
//

require("echarts/theme/macarons"); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "97%"
    },
    height: {
      type: String,
      default: "500px"
    },
    dayArr: {
      type: Array
      // default: []
    }
  },
  watch: {
    dayArr: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(val, oldVal) {
        var _this = this;
        var productList = [];
        var countList = [];
        val.map(function (item) {
          productList.push(item.name);
          countList.push(item.value);
          _this.chart.setOption({
            title: {
              text: "销售分析图表(￥)",
              textStyle: {
                color: "#8d9d7b"
              },
              left: "20px"
            },
            tooltip: {
              trigger: "axis"
            },
            xAxis: [{
              type: "category",
              data: productList,
              axisLabel: {
                interval: 0,
                rotate: 30
              }
            }],
            yAxis: [{
              type: "value"
            }],
            series: [{
              data: countList,
              type: "line",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: "#8d9d7b"
                }, {
                  offset: 0.5,
                  color: "#89c345"
                }, {
                  offset: 1,
                  color: "#87e51a"
                }])
              }
            }]
          });
        });
      },
      deep: true //true 深度监听
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this3 = this;
      this.chart = echarts.init(this.$el);
      var productList = [];
      var countList = [];
      this.dayArr.map(function (item) {
        productList.push(item.name);
        countList.push(item.value);
        _this3.chart.setOption({
          title: {
            text: "销售分析图表(￥)",
            textStyle: {
              color: "#8d9d7b"
            },
            left: "20px"
          },
          tooltip: {
            trigger: "axis"
          },
          xAxis: [{
            type: "category",
            data: productList
          }],
          yAxis: [{
            type: "value"
          }],
          series: [{
            data: countList,
            type: "line",
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: "#8d9d7b"
              }, {
                offset: 0.5,
                color: "#89c345"
              }, {
                offset: 1,
                color: "#87e51a"
              }])
            }
          }]
        });
      });
    }
  }
};