"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  routers: function routers(state) {
    return state.user.routers;
  }
};
var _default = exports.default = getters;