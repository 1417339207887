"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _accservice = require("../../api/accservice.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        line: "",
        pageNo: 1,
        pageSize: 10
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      rules: {
        titleCn: [{
          required: false,
          trigger: "blur"
        }],
        titleEn: [{
          required: false,
          trigger: "blur"
        }],
        titleJa: [{
          required: false,
          trigger: "blur"
        }]
      },
      addform: {
        titleCn: '',
        titleEn: '',
        titleJa: ''
      },
      dialogFormVisibles: false,
      isDisable: false,
      changeid: '',
      formLabelWidth: '110px'
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _accservice.getLists)(_this.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.form.pageNo = 1;
            _this.currentPage = 1;
            (0, _accservice.getLists)(_this.form).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //查询
    search: function search() {
      this.getList();
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line == '2') {
        return "下线";
      } else if (row.line == '1') {
        return "预览";
      }
    },
    // //判断上下线
    // typestate(row) {
    //   if (row.type === "1") {
    //     return "激光刻字";
    //   } else if(row.type=='2') {
    //     return "祝福贺卡";
    //   } else if(row.type == '3'){
    //      return "商品包装";
    //   }
    // },
    //清空
    empty: function empty() {
      this.form.line = '';
      this.getList();
    },
    //上线
    online: function online(row) {
      var _this2 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _accservice.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this2.msgTip("success", "上线成功");
            _this2.getList();
          } else {
            _this2.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this3 = this;
      if (row.line == '1') {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _accservice.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this3.msgTip("success", "操作成功");
            _this3.getList();
          } else {
            _this3.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(row) {
      var _this4 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _accservice.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "下线成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //修改
    change: function change(id) {
      var _this5 = this;
      this.dialogFormVisibles = true;
      (0, _accservice.aloneservice)(id).then(function (res) {
        if (res.success) {
          _this5.addform = res.voT;
        } else {
          _this5.msgTip("error", res.errorMsg);
        }
      });
      this.changeid = id;
    },
    //修改确定按钮
    keep: function keep() {
      var _this6 = this;
      this.isDisable = true;
      (0, _accservice.changeservice)(this.changeid, this.addform).then(function (res) {
        if (res.success) {
          _this6.dialogFormVisibles = false;
          _this6.msgTip("success", "修改成功");
          _this6.getList();
        } else {
          _this6.msgTip("error", res.errorMsg);
          _this6.isDisable = true;
        }
      });
    },
    //修改关闭按钮
    addClose: function addClose() {
      this.dialogFormVisibles = false;
      this.$refs.addform.resetFields();
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};