"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.find-index");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("quill/dist/quill.snow.css");
var _partlist = require("../../api/partlist.js");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    var _ref;
    return _ref = {
      formLabelWidth: "120px",
      editorOption: {},
      restaurants: [],
      state: "",
      timeout: null,
      isDisable: false,
      form: {
        buyDescriptionCn: '',
        buyDescriptionEn: '',
        buyDescriptionJa: '',
        contentMobileCn: "",
        contentMobileEn: "",
        contentMobileJa: "",
        contentPcCn: "",
        contentPcEn: "",
        contentPcJa: "",
        descriptionCn: "",
        descriptionEn: "",
        descriptionJa: "",
        line: "2",
        seq: "",
        serialNumber: "",
        type: "",
        seoTitleCn: "",
        seoTitleEn: "",
        seoTitleJa: "",
        seoKeywordCn: "",
        seoKeywordEn: "",
        seoKeywordJa: "",
        seoDescriptionCn: "",
        seoDescriptionEn: "",
        seoDescriptionJa: ""
      },
      addRules: {
        serialNumber: [{
          required: true,
          trigger: "change"
        }],
        type: [{
          required: true,
          trigger: "blur"
        }]
      },
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "下线",
        type: "1"
      }],
      type: [{
        name: "笔",
        type: "0"
      }, {
        name: "笔芯",
        type: "1"
      }, {
        name: "封套",
        type: "2"
      }]
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "editorOption", {
      placeholder: "请输入文本信息..."
    }), "forms", {
      name: "",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }), "rules", []), "restaurants", []), "multiple", true), "uploadUrl", "".concat(process.env.VUE_APP_BASE_API, "cms/picture/upload")), "uplaod_data", {
      "x-access-token": window.localStorage.getItem("token")
    }), "fileList", []), "fileListEn", []), "fileListJa", []), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "fileListMen", []), "fileListMcn", []), "fileListMja", []), "dialogVisible", false), "fileupload", ""), "dialogImageUrl", ""), "dialogVisibleEn", false), "fileuploadEn", ""), "dialogImageUrlEn", ""), "dialogVisibleJa", false), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "fileuploadJa", ""), "dialogImageUrlJa", ""), "dialogVisibleMen", false), "fileuploadMen", ""), "dialogImageUrlMen", ""), "dialogVisibleMcn", false), "fileuploadMcn", ""), "dialogImageUrlMcn", ""), "dialogVisibleMja", false), "fileuploadMja", ""), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "dialogImageUrlMja", ""), "serial", []), "recommend", []);
  },
  created: function created() {
    var _this = this;
    this.loadAll();
    (0, _dictionary.searchDiclist)({
      name: "",
      pageNo: 1,
      pageSize: 1000,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      if (res.success) {
        var b = {};
        res.voList.forEach(function (obj) {
          var array = b[obj["name"]] || [];
          array.push(obj);
          b[obj["name"]] = array;
        });
        _this.newList = b["PART_SERIES"].map(function (v) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
            value: v.value
          });
        });
        _this.serial = _this.newList;
      }
    });
    recommend().then(function (res) {
      if (res.success) {
        _this.recommend = res.voList;
      }
    });
  },
  mounted: function mounted() {},
  methods: {
    loadAll: function loadAll() {
      var _this2 = this;
      var newList = [];
      (0, _dictionary.searchDiclist)({
        name: "",
        pageNo: 1,
        pageSize: 500,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          res.voList.map(function (partItem) {
            if (partItem.name == "PART_SERIES") {
              newList.push(partItem);
              return newList;
            }
          });
          _this2.restaurants = newList;
        } else {
          _this2.restaurants = [];
        }
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      console.log(fileList, '成功');
      this.fileList = fileList;
    },
    handleRemoves: function handleRemoves(file, fileList) {
      var _this3 = this;
      //this.addform.mutiUrl = [];
      var mutiUrls = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this3.form.contentPcCn = mutiUrls.join(",");
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this4 = this;
      //    this.addform.mutiUrl = [];
      var mutiUrl1 = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this4.form.contentPcCn = mutiUrl1.join(',');
      });
    },
    changePicture: function changePicture(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      this.fileupload = this.fileList.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccess: function uploadSuccess(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileList.splice(this.fileupload, 1, file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    //pc内容en
    handleUploadSuccessEn: function handleUploadSuccessEn(res, file, fileList) {
      this.fileListEn = fileList;
    },
    handleRemovesEn: function handleRemovesEn(file, fileList) {
      var _this5 = this;
      var mutiUrls = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this5.form.contentPcEn = mutiUrls.join(",");
      });
    },
    handleRemoveEn: function handleRemoveEn(file, fileList) {
      var _this6 = this;
      var mutiUrl1 = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this6.form.contentPcEn = mutiUrl1.join(',');
      });
    },
    changePictureEn: function changePictureEn(file) {
      this.dialogVisibleEn = true;
      this.dialogImageUrlEn = file.url;
      this.fileuploadEn = this.fileListEn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessEn: function uploadSuccessEn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListEn.splice(this.fileuploadEn, 1, file);
      this.dialogImageUrlEn = file.url;
      this.dialogVisibleEn = false;
    },
    closeEn: function closeEn() {
      this.dialogVisibleEn = false;
    },
    //pc内容ja
    handleUploadSuccessJa: function handleUploadSuccessJa(res, file, fileList) {
      this.fileListJa = fileList;
    },
    handleRemovesJa: function handleRemovesJa(file, fileList) {
      var _this7 = this;
      var mutiUrls = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this7.form.contentPcJa = mutiUrls.join(",");
      });
    },
    handleRemoveJa: function handleRemoveJa(file, fileList) {
      var _this8 = this;
      var mutiUrl1 = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this8.form.contentPcJa = mutiUrl1.join(',');
      });
    },
    changePictureJa: function changePictureJa(file) {
      this.dialogVisibleJa = true;
      this.dialogImageUrlJa = file.url;
      this.fileuploadJa = this.fileListJa.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessJa: function uploadSuccessJa(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListJa.splice(this.fileuploadJa, 1, file);
      this.dialogImageUrlJa = file.url;
      this.dialogVisibleJa = false;
    },
    closeJa: function closeJa() {
      this.dialogVisibleJa = false;
    },
    //移动端内容cn
    handleUploadSuccessMcn: function handleUploadSuccessMcn(res, file, fileList) {
      this.fileListMcn = fileList;
    },
    handleRemovesMcn: function handleRemovesMcn(file, fileList) {
      var _this9 = this;
      var mutiUrls = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this9.form.contentMobileCn = mutiUrls.join(",");
      });
    },
    handleRemoveMcn: function handleRemoveMcn(file, fileList) {
      var _this10 = this;
      var mutiUrl1 = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this10.form.contentMobileCn = mutiUrl1.join(',');
      });
    },
    changePictureMcn: function changePictureMcn(file) {
      this.dialogVisibleMcn = true;
      this.dialogImageUrlMcn = file.url;
      this.fileuploadMcn = this.fileListMcn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMcn: function uploadSuccessMcn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListMcn.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMcn = file.url;
      this.dialogVisibleMcn = false;
    },
    closeMcn: function closeMcn() {
      this.dialogVisibleMcn = false;
    },
    //移动端内容en
    handleUploadSuccessMen: function handleUploadSuccessMen(res, file, fileList) {
      this.fileListMen = fileList;
    },
    handleRemovesMen: function handleRemovesMen(file, fileList) {
      var _this11 = this;
      var mutiUrls = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this11.form.contentMobileEn = mutiUrls.join(",");
      });
    },
    handleRemoveMen: function handleRemoveMen(file, fileList) {
      var _this12 = this;
      var mutiUrl1 = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this12.form.contentMobileEn = mutiUrl1.join(',');
      });
    },
    changePictureMen: function changePictureMen(file) {
      this.dialogVisibleMen = true;
      this.dialogImageUrlMen = file.url;
      this.fileuploadMen = this.fileListMen.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMen: function uploadSuccessMen(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListMen.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMen = file.url;
      this.dialogVisibleMen = false;
    },
    closeMen: function closeMen() {
      this.dialogVisibleMen = false;
    },
    //移动端内容ja
    handleUploadSuccessMja: function handleUploadSuccessMja(res, file, fileList) {
      this.fileListMja = fileList;
    },
    handleRemovesMja: function handleRemovesMja(file, fileList) {
      var _this13 = this;
      var mutiUrls = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this13.form.contentMobileJa = mutiUrls.join(",");
      });
    },
    handleRemoveMja: function handleRemoveMja(file, fileList) {
      var _this14 = this;
      var mutiUrl1 = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this14.form.contentMobileJa = mutiUrl1.join(',');
      });
    },
    changePictureMja: function changePictureMja(file) {
      this.dialogVisibleMja = true;
      this.dialogImageUrlMja = file.url;
      this.fileuploadMja = this.fileListMja.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMja: function uploadSuccessMja(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListMja.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMja = file.url;
      this.dialogVisibleMja = false;
    },
    closeMja: function closeMja() {
      this.dialogVisibleMja = false;
    },
    submit: function submit() {
      var _this15 = this;
      if (!/(^[1-9]\d*$)/.test(this.form.seq) && this.form.seq !== "") {
        this.msgTip("warning", "序号只能为正整数");
        return false;
      }
      if (this.fileList == [] && this.form.contentPcCn == "") {
        this.msgTip("warning", "请上传PC内容CN");
        return false;
      } else if (this.fileListEn == [] && this.form.contentPcEn == "") {
        this.msgTip("warning", "请上传PC内容EN");
        return false;
      } else if (this.fileListJa == [] && this.form.contentPcJa == "") {
        this.msgTip("warning", "请上传PC内容JA");
        return false;
      } else if (this.fileListMcn == [] && this.form.contentMobileCn == "") {
        this.msgTip("warning", "请上传移动端内容CN");
        return false;
      } else if (this.fileListMen == [] && this.form.contentMobileEn == "") {
        this.msgTip("warning", "请上传移动端内容EN");
        return false;
      } else if (this.fileListMja == [] && this.form.contentMobileJa == "") {
        this.msgTip("warning", "请上传移动端内容JA");
        return false;
      } else {
        this.$refs.form.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            // this.isRepeat = true;
            var contentPcCn = [];
            _this15.fileList.map(function (res) {
              contentPcCn.push(res.response.voT.downloadUrl);
              _this15.form.contentPcCn = contentPcCn.join(",");
            });
            var contentPcEn = [];
            _this15.fileListEn.map(function (res) {
              contentPcEn.push(res.response.voT.downloadUrl);
              _this15.form.contentPcEn = contentPcEn.join(",");
            });
            var contentPcJa = [];
            _this15.fileListJa.map(function (res) {
              contentPcJa.push(res.response.voT.downloadUrl);
              _this15.form.contentPcJa = contentPcJa.join(",");
            });
            var contentMobileCn = [];
            _this15.fileListMcn.map(function (res) {
              contentMobileCn.push(res.response.voT.downloadUrl);
              _this15.form.contentMobileCn = contentMobileCn.join(",");
            });
            var contentMobileEn = [];
            _this15.fileListMen.map(function (res) {
              contentMobileEn.push(res.response.voT.downloadUrl);
              _this15.form.contentMobileEn = contentMobileEn.join(",");
            });
            var contentMobileJa = [];
            _this15.fileListMja.map(function (res) {
              contentMobileJa.push(res.response.voT.downloadUrl);
              _this15.form.contentMobileJa = contentMobileJa.join(",");
            });
            _this15.isDisable = false;
            _this15.form.serialNumber = _this15.form.serialNumber.split("_")[0];
            (0, _partlist.addList)(_this15.form).then(function (res) {
              if (res.success) {
                _this15.msgTip("success", "添加成功");
                _this15.$store.dispatch("tagsView/delView", _this15.$route).then(function () {
                  _this15.$nextTick(function () {
                    _this15.$router.replace({
                      path: "/parts/list",
                      query: {
                        cur: _this15.$route.query.cur,
                        serialNumber: _this15.$route.query.serialNumber,
                        line: _this15.$route.query.line,
                        type: _this15.$route.query.type
                      }
                    });
                  });
                });
                // this.recommend = []
              } else {
                _this15.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this15.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    }
  }
};