"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _detail = require("../../api/detail.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      activeName: "first",
      loading: false,
      isShow: false,
      form: {
        line: "",
        type: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      forms: {
        line: "",
        type: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      option: [{
        name: "说明书",
        type: "0"
      }, {
        name: "固件",
        type: "1"
      }],
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      total: 0,
      currentPage: this.$route.query.pageNo ? this.$route.query.pageNo : 1
    };
  },
  ceated: function ceated() {},
  mounted: function mounted() {
    this.form.type = this.$route.query.type ? this.$route.query.type : '';
    this.form.line = this.$route.query.line ? this.$route.query.line : '';
    this.form.pageNo = this.$route.query.pageNo ? this.$route.query.pageNo : 1;
    this.forms.type = this.$route.query.type ? this.$route.query.type : '';
    this.forms.line = this.$route.query.line ? this.$route.query.line : '';
    this.forms.pageNo = this.$route.query.pageNo ? this.$route.query.pageNo : 1;
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    //列表
    //请求列表数据
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _detail.getLists)(_this.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.forms.pageNo = 1;
            _this.currentPage = 1;
            (0, _detail.getLists)(_this.forms).then(function (res) {
              _this.tableData = res.voList;
              _this.total = res.total;
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //判断类型
    stateFormat: function stateFormat(row) {
      if (row.type === "0") {
        return "说明书";
      } else {
        return "固件";
      }
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line === '1') {
        return "预览";
      } else if (row.line === '2') {
        return "下线";
      }
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      this.isShow = e.id;
    },
    bindSeq: function bindSeq(val) {
      var _this2 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
      } else if (!/(^[1-9]\d*$)/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
      } else {
        (0, _detail.changeSeq)({
          seq: val.seq
        }, val.id).then(function (res) {
          if (res.success) {
            _this2.msgTip("success", "修改成功");
            _this2.isShow = false;
            _this2.getList();
          } else {
            _this2.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //搜索
    search: function search() {
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.type = this.form.type;
      this.forms.line = this.form.line;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.forms.type = "";
      this.currentPage = 1;
      this.form.type = "";
      this.forms.pageNo = 1;
      this.form.line = "";
      this.forms.line = "";
      window.location.href = window.location.href.split('?')[0];
      this.getList();
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "adddetail",
        query: {
          line: this.form.line,
          type: this.form.type,
          pageNo: this.currentPage
        }
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changedetail",
        query: {
          id: id,
          line: this.forms.line,
          type: this.forms.type,
          pageNo: this.currentPage
        }
      });
    },
    //上线
    on: function on(row) {
      var _this3 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _detail.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this3.msgTip("success", "上线成功");
            _this3.getList();
          } else {
            _this3.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //预览
    preview: function preview(row) {
      var _this4 = this;
      if (row.line == '1') {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _detail.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "操作成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下
    off: function off(row) {
      var _this5 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _detail.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "下线成功");
            _this5.getList();
          } else {
            _this5.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this6 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _detail.deleMess)(id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "删除成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      });
    }
  }
};