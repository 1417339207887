import { render, staticRenderFns } from "./dictionaries.vue?vue&type=template&id=3c06bf59&scoped=true"
import script from "./dictionaries.vue?vue&type=script&lang=js"
export * from "./dictionaries.vue?vue&type=script&lang=js"
import style0 from "./dictionaries.vue?vue&type=style&index=0&id=3c06bf59&lang=scss&scoped=true"
import style1 from "./dictionaries.vue?vue&type=style&index=1&id=3c06bf59&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c06bf59",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/www/prod-cn-snote-cms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c06bf59')) {
      api.createRecord('3c06bf59', component.options)
    } else {
      api.reload('3c06bf59', component.options)
    }
    module.hot.accept("./dictionaries.vue?vue&type=template&id=3c06bf59&scoped=true", function () {
      api.rerender('3c06bf59', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/systems/dictionaries.vue"
export default component.exports