var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-table",
        {
          staticStyle: {
            width: "100%",
            height: "100%",
            "padding-top": "120px",
            "padding-right": "30px",
          },
          attrs: {
            data: _vm.tableData,
            "header-cell-style": _vm.tableHeaderColor,
            "cell-style": { "text-align": "center" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "country", label: "国家", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "count", label: "销量", width: "180" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "price", label: "销售额" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v("\n        ￥" + _vm._s(scope.row.price) + "\n    "),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { prop: "proportionSales", label: "占比" },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }