"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _validate = require("@/utils/validate");
var _systemuser = require("../../api/systemuser.js");
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var req = new XMLHttpRequest();
req.open('GET', document.location, false);
req.send(null);
var userType = req.getResponseHeader('Domain') ? req.getResponseHeader('Domain') : '';
console.log("222222", userType);
var _default = exports.default = {
  name: "Login",
  data: function data() {
    var validateUsername = function validateUsername(rule, value, callback) {
      if (!(0, _validate.validUsername)(value)) {
        callback(new Error("请填写正确的用户名"));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error("请输入正确的密码"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible: false,
      form: {
        username: "",
        oldPassword: "",
        newPassword: "",
        repeatPassword: ""
      },
      loginForm: {
        username: "",
        password: ""
      },
      loginRules: {
        username: [{
          required: true,
          trigger: "blur",
          message: "请填写用户名"
        }],
        password: [{
          required: true,
          trigger: "blur",
          validator: validatePassword
        }]
      },
      loading: false,
      passwordType: "password",
      userType: "",
      redirect: undefined,
      formLabelWidth: '90px'
    };
  },
  computed: {
    backgroundImage: function backgroundImage() {
      var imageUrl = require("../../assets/img/banner-c.png");
      if (userType == 'com') {
        imageUrl = require("../../assets/img/banner-h.png");
      }
      if (userType == 'cn-out') {
        imageUrl = require("../../assets/img/banner.png");
      }
      return "url(".concat(imageUrl, ")");
    }
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getDomain();
  },
  methods: {
    close: function close() {
      this.loading = false;
    },
    getDomain: function getDomain() {
      this.userType = userType;
      console.log("333333", userType);
    },
    save: function save() {
      var _this = this;
      (0, _systemuser.checkOldPwd)({
        oldPwd: this.form.oldPassword,
        password: "1"
      }).then(function (res) {
        if (!res.success) {
          _this.$message({
            type: "error",
            message: "原密码不一致",
            center: true
          });
          return;
        } else {
          if (!/^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{6,20}$/.test(_this.form.newPassword)) {
            _this.$message({
              type: "error",
              message: "请输入密码为6到20位且由数字大小写字母组成",
              center: true
            });
            return;
          }
          if (_this.form.newPassword != _this.form.repeatPassword) {
            _this.$message({
              type: "error",
              message: "确认密码与新密码不一样",
              center: true
            });
            return;
          }
          (0, _systemuser.amendPasw)({
            oldPwd: _this.form.oldPassword,
            password: _this.form.newPassword
          }).then(function (res) {
            if (res.success) {
              _this.$message({
                type: "success",
                message: "更新密码成功,请重新登陆",
                center: true
              });
              _this.dialogVisible = false;
            } else {
              _this.$message({
                type: "error",
                message: res.errorMsg,
                center: true
              });
            }
          });
        }
      });
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          _this3.$store.dispatch("user/login", _this3.loginForm).then(function (res) {
            if (res.success) {
              window.localStorage.setItem("token", res.token);
              window.localStorage.setItem("username", _this3.loginForm.username);
              window.localStorage.setItem("name", res.name);
              (0, _systemuser.verifvPassword)().then(function (res) {
                if (res.success) {
                  _this3.$router.push({
                    path: _this3.redirect || "/"
                  });
                  _this3.loading = false;
                } else {
                  _this3.form.username = _this3.loginForm.username;
                  _this3.loginForm.username = "";
                  _this3.loginForm.password = "";
                  _this3.form.oldPassword = "";
                  _this3.form.newPassword = "";
                  _this3.form.repeatPassword = "";
                  (0, _auth.removeToken)();
                  _this3.dialogVisible = true;
                }
              });
            } else {
              _this3.$message({
                type: "error",
                message: res.errorMsg,
                center: true
              });
              _this3.loading = false;
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};