"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _partlist = require("../../api/partlist.js");
var _dictionary = require("../../api/dictionary.js");
var _index = require("../../utils/index.js");
var _timers = require("timers");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      form: {
        line: "",
        pageNo: 1,
        pageSize: 10,
        serialNumber: "",
        type: ""
      },
      forms: {
        line: "",
        pageNo: 1,
        pageSize: 10,
        serialNumber: "",
        type: ""
      },
      seq: "",
      options: [{
        name: '上线',
        type: '0'
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      type: [{
        name: "笔",
        type: "0"
      }, {
        name: "笔芯",
        type: "1"
      }, {
        name: "封套",
        type: "2"
      }],
      total: 0,
      currentPage: this.$route.query.cur ? this.$route.query.cur : 1,
      loading: false,
      isShow: false,
      serial: [],
      newList: [],
      proList: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.cur) {
      this.form.pageNo = this.$route.query.cur, this.form.serialNumber = this.$route.query.serialNumber, this.form.line = this.$route.query.line, this.form.type = this.$route.query.type;
      this.currentPage = this.$route.query.cur;
      this.forms.pageNo = this.$route.query.cur, this.forms.serialNumber = this.$route.query.serialNumber, this.forms.line = this.$route.query.line, this.forms.type = this.$route.query.type;
    }
    this.getList();
    (0, _dictionary.searchDiclist)({
      name: "",
      pageNo: 1,
      pageSize: 500,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      if (res.success) {
        var b = {};
        res.voList.forEach(function (obj) {
          var array = b[obj["name"]] || [];
          array.push(obj);
          b[obj["name"]] = array;
        });
        _this.newList = b["PART_SERIES"].map(function (v) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
            value: v.value + "_" + v.valueCn
          });
        });
        _this.serial = _this.newList;
      }
    });
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      (0, _timers.setTimeout)(function () {
        _this2.form.serialNumber = _this2.form.serialNumber.split("_")[0];
        _this2.forms.serialNumber = _this2.form.serialNumber;
        (0, _partlist.getLists)(_this2.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.forms.pageNo = 1;
            _this2.currentPage = 1;
            (0, _partlist.getLists)(_this2.forms).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = res.total;
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //判断类型
    stateFormat: function stateFormat(row) {
      if (row.type === "0") {
        return "笔";
      } else if (row.type === "1") {
        return "笔芯";
      } else {
        return "封套";
      }
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line === "1") {
        return "预览";
      } else {
        return "下线";
      }
    },
    //搜索
    search: function search() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.line = this.form.line;
      this.forms.serialNumber = this.form.serialNumber;
      this.forms.type = this.form.type;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.serialNumber = "";
      this.currentPage = 1;
      this.form.line = "";
      this.form.type = "";
      this.forms.serialNumber = "";
      this.forms.line = "";
      this.forms.type = "";
      this.forms.pageNo = 1;
      window.location.href = window.location.href.split('?')[0];
      this.getList();
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/part/series/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this3 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
      } else if (!/(^[1-9]\d*$)/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
      } else {
        (0, _partlist.changeSeq)({
          seq: val.seq
        }, val.id).then(function (res) {
          if (res.success) {
            _this3.msgTip("success", "修改成功");
            _this3.isShow = false;
            _this3.getList();
          } else {
            _this3.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "addlist",
        query: {
          cur: this.currentPage,
          serialNumber: this.form.serialNumber,
          line: this.form.line,
          type: this.form.type
        }
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changelist",
        query: {
          id: id,
          cur: this.currentPage,
          serialNumber: this.form.serialNumber,
          line: this.form.line,
          type: this.form.type
        }
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //上线
    online: function online(row) {
      var _this4 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _partlist.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "上线成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this5 = this;
      if (row.line == "1") {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _partlist.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "操作成功");
            _this5.getList();
          } else {
            _this5.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(row) {
      var _this6 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _partlist.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "下线成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this7 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _partlist.delePart)(id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "删除成功");
            _this7.getList();
          } else {
            _this7.msgTip("error", res.errorMsg);
          }
        });
      });
    }
  }
};