var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    {
      staticClass: "box-card-component",
      staticStyle: { "margin-left": "8px" },
    },
    [
      _c(
        "div",
        {
          staticClass: "box-card-header",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("img", {
            attrs: { src: require("../../assets/img/chartbanner.png") },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("pan-thumb", {
            staticClass: "panThumb",
            attrs: { image: _vm.avatar },
          }),
          _vm._v(" "),
          _c("mallki", {
            attrs: { "class-name": "mallki-text", text: "云服务" },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "padding-top": "35px" } },
            _vm._l(_vm.userArr, function (item) {
              return _c("div", [
                _c(
                  "div",
                  { staticClass: "progress-item" },
                  [
                    _c("span", [
                      _vm._v(_vm._s(item.name) + " - " + _vm._s(item.value)),
                    ]),
                    _vm._v(" "),
                    _c("el-progress", {
                      attrs: {
                        color: "#fdc864",
                        percentage: parseFloat(
                          (
                            (item.value / _vm.totalFileServerCounts) *
                            100
                          ).toFixed(1)
                        ),
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }