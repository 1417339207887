"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PanThumb = _interopRequireDefault(require("@/components/PanThumb"));
var _Mallki = _interopRequireDefault(require("@/components/TextHoverEffect/Mallki"));
var _charts = require("../../api/charts.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { mapGetters } from 'vuex'
var _default = exports.default = {
  components: {
    PanThumb: _PanThumb.default,
    Mallki: _Mallki.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        success: 'success',
        pending: 'danger'
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      statisticsData: {
        article_count: 1024,
        pageviews_count: 1024
      },
      userArr: [],
      totalFileServerCounts: '',
      avatar: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif'
    };
  },
  computed: {},
  mounted: function mounted() {
    var _this = this;
    (0, _charts.searchUser)().then(function (res) {
      if (res.success) {
        _this.totalFileServerCounts = res.voT.totalFileServerCounts;
        _this.userArr = res.voT.fileServerList;
      }
    });
  }
};