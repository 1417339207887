"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _standardPrice = require("../../api/standardPrice.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        language: "",
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      languages: [{
        language: "ZH-CN",
        label: "中文"
      }, {
        language: "EN",
        label: "英文"
      }, {
        language: "JA",
        label: "日文"
      }],
      types: [],
      loading: false,
      tableData: [],
      titletype: "",
      dialogFormVisible: false,
      addform: {
        coverNumber: "",
        language: "",
        penNumber: "",
        type: ""
      },
      rules: {
        coverNumber: [{
          required: false,
          trigger: "change"
        }],
        language: [{
          required: false,
          trigger: "change"
        }],
        penNumber: [{
          required: false,
          trigger: "change"
        }],
        type: [{
          required: true,
          trigger: "change"
        }]
      },
      formLabelWidth: "120px",
      currentPage: 1,
      total: 0,
      isDisable: false,
      id: '',
      jacket: [],
      newList: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.types = res.voList;
    });
    this.getList();
    (0, _dictionary.searchDiclist)({
      name: "PART",
      pageNo: 1,
      pageSize: 1000,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      if (res.success) {
        var b = {};
        res.voList.forEach(function (obj) {
          var array = b[obj["name"]] || [];
          array.push(obj);
          b[obj["name"]] = array;
        });
        _this.newList = b["PART"].map(function (v) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
            value: v.value
          });
        });
        _this.jacket = _this.newList;
      }
    });
  },
  methods: {
    //查询数据
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _standardPrice.getLists)(_this2.form).then(function (res) {
          if (res.success) {
            _this2.tableData = res.voList;
            _this2.total = Number(res.total);
            _this2.loading = false;
          }
        });
      }, 500);
    },
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.type = "";
      this.form.language = "";
      this.getList();
    },
    //新增
    add: function add() {
      this.titletype = "新增";
      this.dialogFormVisible = true;
    },
    addClose: function addClose() {
      this.dialogFormVisible = false;
      this.$refs.addform.resetFields();
    },
    //修改
    change: function change(id) {
      var _this3 = this;
      this.titletype = "修改";
      this.id = id;
      this.dialogFormVisible = true;
      (0, _standardPrice.aloneStandard)(id).then(function (res) {
        if (res.success) {
          _this3.addform = res.voT;
        } else {
          _this3.msgTip("error", res.errorMsg);
        }
      });
    },
    //删除
    dele: function dele(id) {
      var _this4 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _standardPrice.deleStandard)(id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "删除成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    //判断语言
    lang: function lang(row) {
      if (row.language.toLocaleLowerCase() == "zh-cn") {
        return "中文";
      } else if (row.language.toLocaleLowerCase() == "en") {
        return "英文";
      } else if (row.language.toLocaleLowerCase() == "ja") {
        return "日文";
      }
    },
    sure: function sure() {
      var _this5 = this;
      if (this.titletype == "新增") {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            _this5.isDisable = false;
            (0, _standardPrice.addList)(_this5.addform).then(function (res) {
              if (res.success) {
                _this5.msgTip("success", "添加成功");
                _this5.dialogFormVisible = false;
                _this5.getList();
              } else {
                _this5.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this5.msgTip("warning", "请填写完整");
            return false;
          }
        });
      } else if (this.titletype == "修改") {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            _this5.isDisable = false;
            (0, _standardPrice.changeStandard)(_this5.id, _this5.addform).then(function (res) {
              if (res.success) {
                _this5.msgTip("success", "修改成功");
                _this5.dialogFormVisible = false;
                _this5.getList();
              } else {
                _this5.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this5.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};