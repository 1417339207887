"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.changeOn = changeOn;
exports.changePart = changePart;
exports.changeSeq = changeSeq;
exports.delePart = delePart;
exports.getLists = getLists;
exports.recommend = recommend;
exports.searchSeq = searchSeq;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询配件系列(cms系统)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/part/series/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增配件系列
function addList(data) {
  return (0, _request.default)({
    url: 'cms/part/series',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改配件系列上线下线
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/part/series/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改配件系列序号
function changeSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/part/series/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个配件系列信息
function searchSeq(id) {
  return (0, _request.default)({
    url: 'cms/part/series/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改配件系列
function changePart(id, data) {
  return (0, _request.default)({
    url: 'cms/part/series/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除配件系列
function delePart(id) {
  return (0, _request.default)({
    url: 'cms/part/series/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//推荐

function recommend() {
  return (0, _request.default)({
    url: 'cms/part/series/recommend/box/list',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}