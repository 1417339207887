"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.split");
var _product = require("../../api/product.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      changeform: {
        descriptionCn: "",
        descriptionEn: "",
        descriptionJa: "",
        seoTitleCn: "",
        seoTitleEn: "",
        seoTitleJa: "",
        seoDescriptionCn: "",
        seoDescriptionEn: "",
        seoDescriptionJa: "",
        seoKeywordCn: "",
        seoKeywordEn: "",
        seoKeywordJa: "",
        titleCn: "",
        titleEn: "",
        titleJa: "",
        type: "",
        urlMobile: "",
        urlPc: "",
        contentMobileCn: '',
        contentMobileEn: '',
        contentMobileJa: '',
        contentPcCn: '',
        contentPcEn: '',
        contentPcJa: '',
        isPresell: ''
      },
      Presell: [{
        value: '0',
        valueCn: '预售'
      }, {
        value: '1',
        valueCn: '在售'
      }],
      changeRules: {
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        detailCn: [{
          required: true,
          trigger: "blur"
        }],
        detailEn: [{
          required: true,
          trigger: "blur"
        }],
        detailJa: [{
          required: true,
          trigger: "blur"
        }],
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        urlMobile: [{
          required: true,
          trigger: "blur"
        }],
        urlPc: [{
          required: true,
          trigger: "blur"
        }],
        isPresell: [{
          required: true,
          trigger: "change"
        }]
      },
      id: '',
      type: [],
      formLabelWidth: "120px",
      uploadUrl: "".concat(process.env.VUE_APP_BASE_API, "cms/picture/upload"),
      uplaod_data: {
        "x-access-token": window.localStorage.getItem("token")
      },
      fileList: [],
      fileListEn: [],
      fileListJa: [],
      fileListMen: [],
      fileListMcn: [],
      fileListMja: [],
      fileLists: [],
      fileListsEn: [],
      fileListsJa: [],
      fileListsMen: [],
      fileListsMcn: [],
      fileListsMja: [],
      dialogVisible: false,
      fileupload: "",
      dialogImageUrl: "",
      dialogVisibleEn: false,
      fileuploadEn: "",
      dialogImageUrlEn: "",
      dialogVisibleJa: false,
      fileuploadJa: "",
      dialogImageUrlJa: "",
      dialogVisibleMen: false,
      fileuploadMen: "",
      dialogImageUrlMen: "",
      dialogVisibleMcn: false,
      fileuploadMcn: "",
      dialogImageUrlMcn: "",
      dialogVisibleMja: false,
      fileuploadMja: "",
      dialogImageUrlMja: "",
      url: '',
      urlen: '',
      urlja: '',
      urlMcn: '',
      urlMen: '',
      urlMja: '',
      imageUrl1: '',
      imageUrl2: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.id = this.$route.query.id;
    (0, _product.aloneproduct)(this.id).then(function (res) {
      _this.changeform = res.voT;
      _this.imageUrl1 = res.voT.urlPc.split('===')[0];
      _this.imageUrl2 = res.voT.urlMobile.split('===')[0];
      _this.fileList = res.voT.contentPcCn.map(function (v) {
        _this.url = v.split('===')[0];
        return {
          name: "",
          url: _this.url,
          href: v
        };
      });
      _this.fileListEn = res.voT.contentPcEn.map(function (v) {
        _this.urlen = v.split('===')[0];
        return {
          name: "",
          url: _this.urlen,
          href: v
        };
      });
      _this.fileListJa = res.voT.contentPcJa.map(function (v) {
        _this.urlja = v.split('===')[0];
        return {
          name: "",
          url: _this.urlja,
          href: v
        };
      });
      _this.fileListMcn = res.voT.contentMobileCn.map(function (v) {
        _this.urlMcn = v.split('===')[0];
        return {
          name: "",
          url: _this.urlMcn,
          href: v
        };
      });
      _this.fileListMen = res.voT.contentMobileEn.map(function (v) {
        _this.urlMen = v.split('===')[0];
        return {
          name: "",
          url: _this.urlMen,
          href: v
        };
      });
      _this.fileListMja = res.voT.contentMobileJa.map(function (v) {
        _this.urlMja = v.split('===')[0];
        return {
          name: "",
          url: _this.urlMja,
          href: v
        };
      });
    });
  },
  methods: {
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      this.fileList = fileList;
    },
    handleRemoves: function handleRemoves(file, fileList) {
      var _this2 = this;
      var mutiUrls = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this2.changeform.contentPcCn = mutiUrls.join(",");
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var str = [];
      this.fileList.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileList = str;
    },
    changePicture: function changePicture(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      this.fileupload = this.fileList.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccess: function uploadSuccess(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileList.splice(this.fileupload, 1, file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    //pc内容en
    handleUploadSuccessEn: function handleUploadSuccessEn(res, file, fileList) {
      this.fileListEn = fileList;
      //this.fileListsEn.push(res.voT.downloadUrl);
    },
    handleRemovesEn: function handleRemovesEn(file, fileList) {
      var _this3 = this;
      var mutiUrls = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this3.changeform.contentPcEn = mutiUrls.join(",");
      });
    },
    handleRemoveEn: function handleRemoveEn(file, fileList) {
      var str = [];
      this.fileListEn.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListEn = str;
    },
    changePictureEn: function changePictureEn(file) {
      this.dialogVisibleEn = true;
      this.dialogImageUrlEn = file.url;
      this.fileuploadEn = this.fileListEn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessEn: function uploadSuccessEn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListEn.splice(this.fileuploadEn, 1, file);
      this.dialogImageUrlEn = file.url;
      this.dialogVisibleEn = false;
    },
    closeEn: function closeEn() {
      this.dialogVisibleEn = false;
    },
    //pc内容ja
    handleUploadSuccessJa: function handleUploadSuccessJa(res, file, fileList) {
      this.fileListJa = fileList;
      //this.fileListsJa.push(res.voT.downloadUrl);
    },
    handleRemovesJa: function handleRemovesJa(file, fileList) {
      var _this4 = this;
      var mutiUrls = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this4.changeform.contentPcJa = mutiUrls.join(",");
      });
    },
    handleRemoveJa: function handleRemoveJa(file, fileList) {
      var str = [];
      this.fileListJa.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListJa = str;
    },
    changePictureJa: function changePictureJa(file) {
      this.dialogVisibleJa = true;
      this.dialogImageUrlJa = file.url;
      this.fileuploadJa = this.fileListJa.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessJa: function uploadSuccessJa(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListJa.splice(this.fileuploadJa, 1, file);
      this.dialogImageUrlJa = file.url;
      this.dialogVisibleJa = false;
    },
    closeJa: function closeJa() {
      this.dialogVisibleJa = false;
    },
    //移动端内容cn
    handleUploadSuccessMcn: function handleUploadSuccessMcn(res, file, fileList) {
      this.fileListMcn = fileList;
      //this.fileListsMcn.push(res.voT.downloadUrl);
    },
    handleRemovesMcn: function handleRemovesMcn(file, fileList) {
      var _this5 = this;
      var mutiUrls = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this5.changeform.contentMobileCn = mutiUrls.join(",");
      });
    },
    handleRemoveMcn: function handleRemoveMcn(file, fileList) {
      var str = [];
      this.fileListMcn.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListMcn = str;
    },
    changePictureMcn: function changePictureMcn(file) {
      this.dialogVisibleMcn = true;
      this.dialogImageUrlMcn = file.url;
      this.fileuploadMcn = this.fileListMcn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMcn: function uploadSuccessMcn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListMcn.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMcn = file.url;
      this.dialogVisibleMcn = false;
    },
    closeMcn: function closeMcn() {
      this.dialogVisibleMcn = false;
    },
    //移动端内容en
    handleUploadSuccessMen: function handleUploadSuccessMen(res, file, fileList) {
      this.fileListMen = fileList;
      //this.fileListsMen.push(res.voT.downloadUrl);
    },
    handleRemovesMen: function handleRemovesMen(file, fileList) {
      var _this6 = this;
      var mutiUrls = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this6.changeform.contentMobileEn = mutiUrls.join(",");
      });
    },
    handleRemoveMen: function handleRemoveMen(file, fileList) {
      var str = [];
      this.fileListMen.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListEn = str;
    },
    changePictureMen: function changePictureMen(file) {
      this.dialogVisibleMen = true;
      this.dialogImageUrlMen = file.url;
      this.fileuploadMen = this.fileListMen.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMen: function uploadSuccessMen(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListMen.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMen = file.url;
      this.dialogVisibleMen = false;
    },
    closeMen: function closeMen() {
      this.dialogVisibleMen = false;
    },
    //移动端内容ja
    handleUploadSuccessMja: function handleUploadSuccessMja(res, file, fileList) {
      this.fileListMja = fileList;
      //this.fileListsMja.push(res.voT.downloadUrl);
    },
    handleRemovesMja: function handleRemovesMja(file, fileList) {
      var _this7 = this;
      var mutiUrls = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this7.changeform.contentMobileJa = mutiUrls.join(",");
      });
    },
    handleRemoveMja: function handleRemoveMja(file, fileList) {
      var str = [];
      this.fileListMja.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileListMja = str;
    },
    changePictureMja: function changePictureMja(file) {
      this.dialogVisibleMja = true;
      this.dialogImageUrlMja = file.url;
      this.fileuploadMja = this.fileListMja.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMja: function uploadSuccessMja(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileListMja.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMja = file.url;
      this.dialogVisibleMja = false;
    },
    closeMja: function closeMja() {
      this.dialogVisibleMja = false;
    },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this8 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png,gif,jpg,jpeg的图片");
        return;
      }
      // let size = file.size / 1024 / 1024 / 2;
      // if (size > 2) {
      //    this.msgTip("warning", "图片大小必须小于2M");
      //   return;
      // }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this8.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this8.changeform.urlMobile = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this9 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      // let size = file.size / 1024 / 1024 / 2;
      // if (size > 2) {
      //    this.msgTip("warning", "图片大小必须小于2M");
      //   return;
      // }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this9.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this9.changeform.urlPc = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //提交
    submit: function submit() {
      var _this10 = this;
      if (this.fileList == [] && this.changeform.contentPcCn == "") {
        this.msgTip("warning", "请上传PC内容CN");
        return false;
      } else if (this.fileListEn == [] && this.changeform.contentPcEn == "") {
        this.msgTip("warning", "请上传PC内容EN");
        return false;
      } else if (this.fileListJa == [] && this.changeform.contentPcJa == "") {
        this.msgTip("warning", "请上传PC内容JA");
        return false;
      } else if (this.fileListMcn == [] && this.changeform.contentMobileCn == "") {
        this.msgTip("warning", "请上传移动端内容CN");
        return false;
      } else if (this.fileListMen == [] && this.changeform.contentMobileEn == "") {
        this.msgTip("warning", "请上传移动端内容EN");
        return false;
      } else if (this.fileListMja == [] && this.changeform.contentMobileJa == "") {
        this.msgTip("warning", "请上传移动端内容JA");
        return false;
      } else {
        this.$refs.changeform.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            // this.isRepeat = true;
            _this10.isDisable = true;
            var str = [];
            _this10.fileList.map(function (item) {
              if (item.response) {
                str.push(item.response.voT.downloadUrl);
              } else {
                str.push(item.href);
              }
            });
            _this10.changeform.contentPcCn = str.toString();
            var arr = [];
            _this10.fileListEn.map(function (item) {
              if (item.response) {
                arr.push(item.response.voT.downloadUrl);
              } else {
                arr.push(item.href);
              }
            });
            // if(this.fileListsEn.length > 0){
            //   this.fileListsEn.map(item => {
            //     arr.push(item);
            //   });
            // }
            _this10.changeform.contentPcEn = arr.toString();
            var arrJa = [];
            _this10.fileListJa.map(function (item) {
              if (item.response) {
                arrJa.push(item.response.voT.downloadUrl);
              } else {
                arrJa.push(item.href);
              }
            });
            _this10.changeform.contentPcJa = arrJa.toString();
            var arrMcn = [];
            _this10.fileListMcn.map(function (item) {
              if (item.response) {
                arrMcn.push(item.response.voT.downloadUrl);
              } else {
                arrMcn.push(item.href);
              }
            });
            _this10.changeform.contentMobileCn = arrMcn.toString();
            var arrMen = [];
            _this10.fileListMen.map(function (item) {
              if (item.response) {
                arrMen.push(item.response.voT.downloadUrl);
              } else {
                arrMen.push(item.href);
              }
            });
            _this10.changeform.contentMobileEn = arrMen.toString();
            var arrMja = [];
            _this10.fileListMja.map(function (item) {
              if (item.response) {
                arrMja.push(item.response.voT.downloadUrl);
              } else {
                arrMja.push(item.href);
              }
            });
            _this10.changeform.contentMobileJa = arrMja.toString();
            (0, _product.changeproduct)(_this10.changeform, _this10.id).then(function (res) {
              if (res.success) {
                _this10.msgTip("success", "修改成功");
                _this10.$store.dispatch("tagsView/delView", _this10.$route).then(function () {
                  _this10.$nextTick(function () {
                    _this10.$router.replace({
                      path: "/product/productbuy",
                      query: {
                        line: _this10.$route.query.line,
                        type: _this10.$route.query.type,
                        cur: _this10.$route.query.cur
                      }
                    });
                  });
                });
              } else {
                _this10.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this10.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    }
  }
};