"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _awards = require("../../api/awards.js");
var _index = require("../../utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      isShow: false,
      rules: {
        nameCn: [{
          required: true,
          trigger: "blur"
        }],
        nameEn: [{
          required: true,
          trigger: "blur"
        }],
        nameJa: [{
          required: true,
          trigger: "blur"
        }],
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }]
      },
      imageUrl2: "",
      imageUrl1: "",
      imageUrl3: "",
      imageUrl4: "",
      imageUrl5: "",
      imageUrl6: "",
      addform: {
        id: "",
        seq: "",
        urlMobileCn: "",
        urlMobileEn: "",
        urlMobileJa: "",
        urlPcCn: "",
        urlPcEn: "",
        urlPcJa: "",
        nameCn: "",
        nameEn: "",
        nameJa: "",
        linkCn: "",
        linkEn: "",
        linkJa: "",
        descriptionCn: "",
        descriptionEn: "",
        descriptionJa: "",
        mechanismCn: "",
        mechanismEn: "",
        mechanismJa: "",
        line: "2"
      },
      isDisable: false,
      addApprove: {
        seq: ""
      },
      dialogFormVisible: false,
      dialogFormVisible1: false,
      dialogFormVisible2: false,
      lines: [{
        line: "0",
        label: "上线"
      }, {
        line: "1",
        label: "预览"
      }, {
        line: "2",
        label: "下线"
      }],
      tableData: [],
      currentPage: 1,
      total: 0,
      loading: false,
      form: {
        language: "",
        line: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      forms: {
        language: "",
        line: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: ""
      },
      lineStatus: {
        line: ""
      },
      isFlag: true,
      value: ""
    }, "dialogFormVisible", false), "addRules", []), "formLabelWidth", "150px"), "dialogFormVisibles", false), "changeRules", []);
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _awards.queryAward)(_this.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.forms.pageNo = 1;
            _this.currentPage = 1;
            (0, _awards.queryAward)(_this.forms).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //查询
    search: function search() {
      var _this2 = this;
      this.loading = true;
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.line = this.form.line;
      setTimeout(function () {
        (0, _awards.queryAward)({
          line: _this2.form.line,
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          language: "",
          sortRules: ""
        }).then(function (res) {
          _this2.form.pageNo = 1;
          _this2.tableData = res.voList;
          _this2.total = res.total;
        });
        _this2.currentPage = 1;
        _this2.loading = false;
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this3 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _awards.upload)(fd).then(function (res) {
        _this3.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this3.addform.urlPcCn = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this4 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _awards.upload)(fd).then(function (res) {
        _this4.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this4.addform.urlMobileCn = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture3: function beforeUploadPicture3(file) {
      var _this5 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _awards.upload)(fd).then(function (res) {
        _this5.imageUrl3 = res.voT.downloadUrl.split('===')[0];
        _this5.addform.urlPcEn = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture4: function beforeUploadPicture4(file) {
      var _this6 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _awards.upload)(fd).then(function (res) {
        _this6.imageUrl4 = res.voT.downloadUrl.split('===')[0];
        _this6.addform.urlMobileEn = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture5: function beforeUploadPicture5(file) {
      var _this7 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _awards.upload)(fd).then(function (res) {
        _this7.imageUrl5 = res.voT.downloadUrl.split('===')[0];
        _this7.addform.urlPcJa = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture6: function beforeUploadPicture6(file) {
      var _this8 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _awards.upload)(fd).then(function (res) {
        _this8.imageUrl6 = res.voT.downloadUrl.split('===')[0];
        _this8.addform.urlMobileJa = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //删除
    dele: function dele(v) {
      var _this9 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _awards.delAward)(v.id).then(function (res) {
          if (res.success) {
            _this9.msgTip("success", "操作成功");
            _this9.getList();
          } else {
            _this9.msgTip("warning", res.errorMsg);
          }
        });
      });
    },
    addApproveClose: function addApproveClose(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible1 = false;
    },
    empty: function empty() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.line = "";
      this.form.line = "";
      this.getList();
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //上线
    online: function online(v) {
      var _this10 = this;
      if (v.line == 0) {
        this.msgTip("error", "已是上线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 0;
        (0, _awards.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this10.msgTip("success", "操作成功");
            _this10.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "0",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this10.isFlag = true;
          } else {
            _this10.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this10.isFlag = true;
          }
        });
      }
    },
    //判断上下线
    lineFormatter: function lineFormatter(row, column) {
      if (row.line == "0") {
        return "上线";
      } else if (row.line == "1") {
        return "预览";
      } else if (row.line == "2") {
        return "下线";
      }
    },
    //下线
    offline: function offline(v) {
      var _this11 = this;
      if (v.line == 2) {
        this.msgTip("error", "已是下线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 2;
        (0, _awards.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this11.msgTip("success", "操作成功");
            _this11.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "2",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this11.isFlag = true;
          } else {
            _this11.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this11.isFlag = true;
          }
        });
      }
    },
    //预览
    preview: function preview(v) {
      var _this12 = this;
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 1;
        (0, _awards.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this12.msgTip("success", "操作成功");
            _this12.empty();
            // 前端刷新
            // dataManipulation(
            //   this.tableData,
            //   v.id,
            //   "1",
            //   getDate(),
            //   window.localStorage.getItem("username")
            // );
            _this12.isFlag = true;
          } else {
            _this12.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this12.isFlag = true;
          }
        });
      }
    },
    //新增
    add: function add() {
      this.addform.seq = "";
      this.addform.urlPcCn = "";
      this.addform.urlPcEn = "";
      this.addform.urlPcJa = "";
      this.addform.urlMobileCn = "";
      this.addform.urlMobileEn = "";
      this.addform.urlMobileJa = "";
      this.imageUrl2 = "";
      this.imageUrl1 = "";
      this.imageUrl4 = "";
      this.imageUrl3 = "";
      this.imageUrl6 = "";
      this.imageUrl5 = "";
      this.addform.nameCn = "";
      this.addform.nameEn = "";
      this.addform.nameJa = "";
      this.addform.descriptionCn = "";
      this.addform.descriptionEn = "";
      this.addform.descriptionJa = "";
      this.addform.mechanismCn = "";
      this.addform.mechanismEn = "";
      this.addform.mechanismJa = "";
      this.addform.seoKeywordCn = "";
      this.addform.seoKeywordEn = "";
      this.addform.seoKeywordJa = "";
      this.addform.seoDescriptionCn = "";
      this.addform.seoDescriptionEn = "";
      this.addform.seoDescriptionJa = "";
      this.dialogFormVisible = true;
      this.isDisable = false;
    },
    //新增关闭按钮
    addClose: function addClose(formName) {
      this.$refs[formName].resetFields();
      this.addform.linkCn = "";
      this.addform.linkEn = "";
      this.addform.linkJa = "";
      this.dialogFormVisible = false;
    },
    //新增确定按钮
    sure: function sure(formName) {
      var _this13 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!/^[1-9]{1,}[\d]*$/.test(_this13.addform.seq) && _this13.addform.seq !== "") {
            _this13.msgTip("warning", "序号只能为正整数");
            return;
          }
          if (_this13.imageUrl1 == "") {
            _this13.msgTip("warning", "必须上传PC端中文图片");
            return;
          }
          if (_this13.imageUrl2 == "") {
            _this13.msgTip("warning", "必须上传手机端中文图片");
            return;
          }
          if (_this13.imageUrl3 == "") {
            _this13.msgTip("warning", "必须上传PC端英文图片");
            return;
          }
          if (_this13.imageUrl4 == "") {
            _this13.msgTip("warning", "必须上传手机端英文图片");
            return;
          }
          if (_this13.imageUrl5 == "") {
            _this13.msgTip("warning", "必须上传PC端日文图片");
            return;
          }
          if (_this13.imageUrl6 == "") {
            _this13.msgTip("warning", "必须上传手机端日文图片");
            return;
          }
          _this13.isDisable = true;
          (0, _awards.addAward)(_this13.addform).then(function (res) {
            if (res.success) {
              _this13.msgTip("success", "添加成功");
              // 前端刷新
              (0, _awards.dataManipulation)(_this13.tableData, window.localStorage.getItem("username"));
              _this13.getList();
              _this13.addform.seq = "";
              _this13.dialogFormVisible = false;
              _this13.$refs[formName].resetFields();
            } else {
              _this13.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this13.isDisable = false;
            }
          });
        }
      });
    },
    //修改
    change: function change(v) {
      var _this14 = this;
      this.dialogFormVisible2 = true;
      this.isDisable = false;
      (0, _awards.getAwardById)(v.id).then(function (res) {
        _this14.addform = res;
        _this14.imageUrl2 = res.urlMobileCn.split('===')[0];
        _this14.imageUrl1 = res.urlPcCn.split('===')[0];
        _this14.imageUrl3 = res.urlPcEn.split('===')[0];
        _this14.imageUrl5 = res.urlPcJa.split('===')[0];
        _this14.imageUrl4 = res.urlMobileEn.split('===')[0];
        _this14.imageUrl6 = res.urlMobileJa.split('===')[0];
      });
    },
    //修改关闭按钮
    changeClose: function changeClose(formName) {
      this.$refs[formName].resetFields();
      this.addform.linkCn = "";
      this.addform.linkEn = "";
      this.addform.linkJa = "";
      this.dialogFormVisible2 = false;
    },
    //修改确定按钮
    keep: function keep(formName) {
      var _this15 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this15.imageUrl1 == "") {
            _this15.msgTip("warning", "必须上传PC端中文图片");
            return;
          }
          if (_this15.imageUrl2 == "") {
            _this15.msgTip("warning", "必须上传手机端中文图片");
            return;
          }
          if (_this15.imageUrl3 == "") {
            _this15.msgTip("warning", "必须上传PC端英文图片");
            return;
          }
          if (_this15.imageUrl4 == "") {
            _this15.msgTip("warning", "必须上传手机端英文图片");
            return;
          }
          if (_this15.imageUrl5 == "") {
            _this15.msgTip("warning", "必须上传PC端日文图片");
            return;
          }
          if (_this15.imageUrl6 == "") {
            _this15.msgTip("warning", "必须上传手机端日文图片");
            return;
          }
          _this15.isDisable = true;
          (0, _awards.updateAward)(_this15.addform).then(function (res) {
            if (res.success) {
              _this15.msgTip("success", "修改成功");
              // 前端刷新
              (0, _awards.dataManipulation)(_this15.tableData, window.localStorage.getItem("username"));
              _this15.getList();
              _this15.dialogFormVisible2 = false;
              _this15.$refs[formName].resetFields();
            } else {
              _this15.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this15.isDisable = false;
            }
          });
        }
      });
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/home/award/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this16 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
        return;
      }
      if (!/^[1-9]{1,}[\d]*$/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
        return;
      }
      this.addApprove.seq = val.seq;
      (0, _awards.updateSeq)(this.addApprove, val.id).then(function (res) {
        if (res.success) {
          _this16.msgTip("success", "修改成功");
          _this16.empty();
          _this16.isShow = false;
        } else {
          _this16.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}