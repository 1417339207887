"use strict";

module.exports = {
  title: 'CMS管理平台',
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,
  /**
   * @type {boolean} true | false
   * @description Whether show Settings
   */
  showSettings: true,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true
};