"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Breadcrumb = _interopRequireDefault(require("@/components/Breadcrumb"));
var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));
var _auth = require("@/utils/auth");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var req = new XMLHttpRequest();
req.open('GET', document.location, false);
req.send(null);
var userType = req.getResponseHeader('Domain') ? req.getResponseHeader('Domain') : '';
var _default = exports.default = {
  components: {
    Breadcrumb: _Breadcrumb.default,
    Hamburger: _Hamburger.default
  },
  data: function data() {
    return {
      userType: ""
    };
  },
  mounted: function mounted() {
    this.getDomain();
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["sidebar", "avatar"])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    getDomain: function getDomain() {
      this.userType = userType;
    },
    logout: function logout() {
      (0, _auth.removeToken)();
      this.$router.push("/login");
      location.reload();
    },
    preview: function preview() {
      window.open('https://preview.supernote.com.cn/');
    }
  }
};