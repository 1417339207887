"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _feedback = require("../../api/feedback.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      remarkForm: {
        remark: "",
        status: ""
      },
      formLabelWidth: "100px",
      options: [{
        value: "0",
        label: "待处理"
      }, {
        value: "1",
        label: "追踪中"
      }, {
        value: "2",
        label: "已处理"
      }],
      dialogFormVisible: false,
      form: {
        description: "",
        equipmentNumber: "",
        pageNo: 1,
        pageSize: 10,
        status: "",
        email: "",
        telephone: ""
      },
      forms: {
        description: "",
        equipmentNumber: "",
        pageNo: 1,
        pageSize: 10,
        status: "",
        email: "",
        telephone: ""
      },
      forms1: {
        pageNo: 0,
        pageSize: 0,
        id: ""
      },
      addform: {
        name: "",
        contact: "",
        equipmentNumber: "",
        description: ""
      },
      value: false,
      isDisable: false,
      loading: false,
      isShow: false,
      tableData: [],
      total: 0,
      currentPage: 1,
      option: [{
        name: "待处理",
        status: "0"
      }, {
        name: "追踪中",
        status: "1"
      }, {
        name: "已处理",
        status: "2"
      }],
      status: "",
      id: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _feedback.getLists)(_this.forms).then(function (res) {
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
        });
      }, 500);
    },
    //弹框关闭
    changeClose: function changeClose() {
      this.dialogFormVisible = false;
    },
    //查询
    search: function search() {
      this.forms.pageNo = 1;
      this.forms.description = this.form.description;
      this.forms.equipmentNumber = this.form.equipmentNumber;
      this.forms.status = this.form.status;
      this.forms.telephone = this.form.telephone;
      this.forms.email = this.form.email;
      this.getList();
    },
    //判断类型
    stateFormat: function stateFormat(row) {
      if (row.status == "0") {
        return "待处理";
      } else if (row.status == "1") {
        return "追踪中";
      } else if (row.status == "2") {
        return "已处理";
      }
    },
    //清空
    empty: function empty() {
      this.form.description = "";
      this.form.equipmentNumber = "";
      this.form.status = "";
      this.form.telephone = "";
      this.form.email = "";
      this.forms.description = "";
      this.forms.equipmentNumber = "";
      this.forms.status = "";
      this.forms.telephone = "";
      this.forms.email = "";
      this.forms.pageNo = 1;
      this.getList();
    },
    change: function change(e) {
      var _this2 = this;
      this.dialogFormVisible = true;
      this.id = e.id;
      this.forms1.id = e.id;
      this.isDisable = false;
      (0, _feedback.getLists)(this.forms1).then(function (res) {
        _this2.remarkForm = res.voList[0];
      });
    },
    keep: function keep() {
      var _this3 = this;
      this.isDisable = true;
      this.loading = true;
      (0, _feedback.changeList)(this.id, this.remarkForm).then(function (res) {
        if (res.success) {
          _this3.$message({
            type: "success",
            message: "修改成功",
            center: true
          });
          _this3.loading = false;
          _this3.dialogFormVisible = false;
          _this3.getList();
          (0, _feedback.dataManipulation)(_this3.tableData);
        } else {
          _this3.$message({
            type: "error",
            message: res.errorMsg,
            center: true
          });
          _this3.loading = false;
          _this3.isDisable = false;
        }
      });
    },
    // //新增
    // add(){
    // },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //审核
    isopen: function isopen(status, id) {
      var _this4 = this;
      (0, _feedback.changeList)(id, {
        status: status
      }).then(function (res) {
        if (res.success) {
          _this4.msgTip("success", "审核成功");
          // this.status = status
          _this4.getList();
        } else {
          _this4.msgTip("error", res.errorMsg);
        }
      });
    }
  }
};