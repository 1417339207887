"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//

var req = new XMLHttpRequest();
req.open('GET', document.location, false);
req.send(null);
var userType = req.getResponseHeader('Domain') ? req.getResponseHeader('Domain') : '';
window.sessionStorage.setItem("userType", userType);
var _default = exports.default = {
  name: "Dashboard",
  computed: {},
  data: function data() {
    return {
      userType: ""
    };
  },
  mounted: function mounted() {
    this.getDomain();
  },
  methods: {
    close: function close() {
      this.loading = false;
    },
    getDomain: function getDomain() {
      this.userType = window.sessionStorage.getItem("userType");
    }
  }
};