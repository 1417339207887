"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.find-index");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _productMess = require("../../api/productMess.js");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    color: {
      type: String,
      default: "#1890ff"
    }
  },
  data: function data() {
    var _ref;
    return _ref = {
      content: "",
      value: "",
      type: [],
      form: {
        contentMobileCn: '',
        contentMobileEn: '',
        contentMobileJa: '',
        contentPcCn: '',
        contentPcEn: '',
        contentPcJa: '',
        descriptionCn: '',
        descriptionEn: '',
        descriptionJa: '',
        titleCn: '',
        titleEn: '',
        titleJa: '',
        type: ''
      },
      formLabelWidth: "120px",
      formRules: {
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "change"
        }]
      },
      isDisable: false,
      imageUrl1: "",
      dialogVisible: false,
      listObj: {},
      uploadUrl: "".concat(process.env.VUE_APP_BASE_API, "cms/picture/upload"),
      uplaod_data: {
        "x-access-token": window.localStorage.getItem("token")
      },
      fileList: [],
      fileListEn: [],
      fileListJa: [],
      fileListMen: [],
      fileListMcn: [],
      fileListMja: []
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "dialogVisible", false), "fileupload", ""), "dialogImageUrl", ""), "dialogVisibleEn", false), "fileuploadEn", ""), "dialogImageUrlEn", ""), "dialogVisibleJa", false), "fileuploadJa", ""), "dialogImageUrlJa", ""), "dialogVisibleMen", false), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "fileuploadMen", ""), "dialogImageUrlMen", ""), "dialogVisibleMcn", false), "fileuploadMcn", ""), "dialogImageUrlMcn", ""), "dialogVisibleMja", false), "fileuploadMja", ""), "dialogImageUrlMja", "");
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      console.log(fileList, '成功');
      this.fileList = fileList;
    },
    handleRemoves: function handleRemoves(file, fileList) {
      var _this2 = this;
      //this.addform.mutiUrl = [];
      var mutiUrls = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this2.form.contentPcCn = mutiUrls.join(",");
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this3 = this;
      //    this.addform.mutiUrl = [];
      var mutiUrl1 = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this3.form.contentPcCn = mutiUrl1.join(',');
      });
    },
    changePicture: function changePicture(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      this.fileupload = this.fileList.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccess: function uploadSuccess(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileList.splice(this.fileupload, 1, file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    //pc内容en
    handleUploadSuccessEn: function handleUploadSuccessEn(res, file, fileList) {
      this.fileListEn = fileList;
    },
    handleRemovesEn: function handleRemovesEn(file, fileList) {
      var _this4 = this;
      var mutiUrls = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this4.form.contentPcEn = mutiUrls.join(",");
      });
    },
    handleRemoveEn: function handleRemoveEn(file, fileList) {
      var _this5 = this;
      var mutiUrl1 = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this5.form.contentPcEn = mutiUrl1.join(',');
      });
    },
    changePictureEn: function changePictureEn(file) {
      this.dialogVisibleEn = true;
      this.dialogImageUrlEn = file.url;
      this.fileuploadEn = this.fileListEn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessEn: function uploadSuccessEn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListEn.splice(this.fileuploadEn, 1, file);
      this.dialogImageUrlEn = file.url;
      this.dialogVisibleEn = false;
    },
    closeEn: function closeEn() {
      this.dialogVisibleEn = false;
    },
    //pc内容ja
    handleUploadSuccessJa: function handleUploadSuccessJa(res, file, fileList) {
      this.fileListJa = fileList;
    },
    handleRemovesJa: function handleRemovesJa(file, fileList) {
      var _this6 = this;
      var mutiUrls = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this6.form.contentPcJa = mutiUrls.join(",");
      });
    },
    handleRemoveJa: function handleRemoveJa(file, fileList) {
      var _this7 = this;
      var mutiUrl1 = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this7.form.contentPcJa = mutiUrl1.join(',');
      });
    },
    changePictureJa: function changePictureJa(file) {
      this.dialogVisibleJa = true;
      this.dialogImageUrlJa = file.url;
      this.fileuploadJa = this.fileListJa.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessJa: function uploadSuccessJa(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListJa.splice(this.fileuploadJa, 1, file);
      this.dialogImageUrlJa = file.url;
      this.dialogVisibleJa = false;
    },
    closeJa: function closeJa() {
      this.dialogVisibleJa = false;
    },
    //移动端内容cn
    handleUploadSuccessMcn: function handleUploadSuccessMcn(res, file, fileList) {
      this.fileListMcn = fileList;
    },
    handleRemovesMcn: function handleRemovesMcn(file, fileList) {
      var _this8 = this;
      var mutiUrls = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this8.form.contentMobileCn = mutiUrls.join(",");
      });
    },
    handleRemoveMcn: function handleRemoveMcn(file, fileList) {
      var _this9 = this;
      var mutiUrl1 = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this9.form.contentMobileCn = mutiUrl1.join(',');
      });
    },
    changePictureMcn: function changePictureMcn(file) {
      this.dialogVisibleMcn = true;
      this.dialogImageUrlMcn = file.url;
      this.fileuploadMcn = this.fileListMcn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMcn: function uploadSuccessMcn(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListMcn.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMcn = file.url;
      this.dialogVisibleMcn = false;
    },
    closeMcn: function closeMcn() {
      this.dialogVisibleMcn = false;
    },
    //移动端内容en
    handleUploadSuccessMen: function handleUploadSuccessMen(res, file, fileList) {
      this.fileListMen = fileList;
    },
    handleRemovesMen: function handleRemovesMen(file, fileList) {
      var _this10 = this;
      var mutiUrls = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this10.form.contentMobileEn = mutiUrls.join(",");
      });
    },
    handleRemoveMen: function handleRemoveMen(file, fileList) {
      var _this11 = this;
      var mutiUrl1 = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this11.form.contentMobileEn = mutiUrl1.join(',');
      });
    },
    changePictureMen: function changePictureMen(file) {
      this.dialogVisibleMen = true;
      this.dialogImageUrlMen = file.url;
      this.fileuploadMen = this.fileListMen.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMen: function uploadSuccessMen(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListMen.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMen = file.url;
      this.dialogVisibleMen = false;
    },
    closeMen: function closeMen() {
      this.dialogVisibleMen = false;
    },
    //移动端内容ja
    handleUploadSuccessMja: function handleUploadSuccessMja(res, file, fileList) {
      this.fileListMja = fileList;
    },
    handleRemovesMja: function handleRemovesMja(file, fileList) {
      var _this12 = this;
      var mutiUrls = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this12.form.contentMobileJa = mutiUrls.join(",");
      });
    },
    handleRemoveMja: function handleRemoveMja(file, fileList) {
      var _this13 = this;
      var mutiUrl1 = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this13.form.contentMobileJa = mutiUrl1.join(',');
      });
    },
    changePictureMja: function changePictureMja(file) {
      this.dialogVisibleMja = true;
      this.dialogImageUrlMja = file.url;
      this.fileuploadMja = this.fileListMja.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMja: function uploadSuccessMja(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileListMja.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMja = file.url;
      this.dialogVisibleMja = false;
    },
    closeMja: function closeMja() {
      this.dialogVisibleMja = false;
    },
    checkAllSuccess: function checkAllSuccess() {
      var _this14 = this;
      return Object.keys(this.listObj).every(function (item) {
        return _this14.listObj[item].hasSuccess;
      });
    },
    handleSubmit: function handleSubmit() {
      var _this15 = this;
      var arr = Object.keys(this.listObj).map(function (v) {
        return _this15.listObj[v];
      });
      if (!this.checkAllSuccess()) {
        this.$message("Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!");
        return;
      }
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    // //图片上传
    // beforeUploadPicture(file) {
    //   if (
    //     !(
    //       file.type === "image/png" ||
    //       file.type === "image/gif" ||
    //       file.type === "image/jpg" ||
    //       file.type === "image/jpeg"
    //     )
    //   ) {
    //     this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
    //     return;
    //   }
    //   let fd = new FormData();
    //   fd.append("file", file); //传文件
    //   uploads(fd).then(res => {
    //     this.imageUrl1 = res.voT.downloadUrl;
    //   });
    //   return false; //屏蔽了action的默认上传
    // },
    //提交
    submit: function submit() {
      var _this16 = this;
      if (this.fileList == [] && this.form.contentPcCn == "") {
        this.msgTip("warning", "请上传PC内容CN");
        return false;
      } else if (this.fileListEn == [] && this.form.contentPcEn == "") {
        this.msgTip("warning", "请上传PC内容EN");
        return false;
      } else if (this.fileListJa == [] && this.form.contentPcJa == "") {
        this.msgTip("warning", "请上传PC内容JA");
        return false;
      } else if (this.fileListMcn == [] && this.form.contentMobileCn == "") {
        this.msgTip("warning", "请上传移动端内容CN");
        return false;
      } else if (this.fileListMen == [] && this.form.contentMobileEn == "") {
        this.msgTip("warning", "请上传移动端内容EN");
        return false;
      } else if (this.fileListMja == [] && this.form.contentMobileJa == "") {
        this.msgTip("warning", "请上传移动端内容JA");
        return false;
      } else {
        this.$refs.form.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            // this.isRepeat = true;
            _this16.isDisable = true;
            var contentPcCn = [];
            _this16.fileList.map(function (res) {
              contentPcCn.push(res.response.voT.downloadUrl);
              _this16.form.contentPcCn = contentPcCn.join(",");
            });
            var contentPcEn = [];
            _this16.fileListEn.map(function (res) {
              contentPcEn.push(res.response.voT.downloadUrl);
              _this16.form.contentPcEn = contentPcEn.join(",");
            });
            var contentPcJa = [];
            _this16.fileListJa.map(function (res) {
              contentPcJa.push(res.response.voT.downloadUrl);
              _this16.form.contentPcJa = contentPcJa.join(",");
            });
            var contentMobileCn = [];
            _this16.fileListMcn.map(function (res) {
              contentMobileCn.push(res.response.voT.downloadUrl);
              _this16.form.contentMobileCn = contentMobileCn.join(",");
            });
            var contentMobileEn = [];
            _this16.fileListMen.map(function (res) {
              contentMobileEn.push(res.response.voT.downloadUrl);
              _this16.form.contentMobileEn = contentMobileEn.join(",");
            });
            var contentMobileJa = [];
            _this16.fileListMja.map(function (res) {
              contentMobileJa.push(res.response.voT.downloadUrl);
              _this16.form.contentMobileJa = contentMobileJa.join(",");
            });
            (0, _productMess.addList)(_this16.form).then(function (res) {
              if (res.success) {
                _this16.msgTip("success", "添加成功");
                _this16.$store.dispatch("tagsView/delView", _this16.$route).then(function () {
                  _this16.$nextTick(function () {
                    _this16.$router.replace({
                      path: "/product/summarize",
                      query: {
                        line: _this16.$route.query.line,
                        type: _this16.$route.query.type,
                        cur: _this16.$route.query.cur
                      }
                    });
                  });
                });
              } else {
                _this16.msgTip("error", res.errorMsg);
                // this.isRepeat = false;
                _this16.isDisable = false;
              }
            });
          } else {
            _this16.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    }
  }
};