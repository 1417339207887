"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _faqmess = require("../../api/faqmess.js");
var _faq = require("../../api/faq.js");
var _index = require("../../utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      table: {
        faqTitleId: "",
        line: "",
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      tables: {
        faqTitleId: "",
        line: "",
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      types: [{
        value: 0,
        name: "FAQ"
      }, {
        value: 1,
        name: "故障排除"
      }],
      tit: [],
      option: [{
        id: 0,
        name: "上线"
      }, {
        id: 1,
        name: "预览"
      }, {
        id: 2,
        name: "下线"
      }],
      total: 0,
      currentPage: this.$route.query.pageNo ? Number(this.$route.query.pageNo) : 1,
      loading: false,
      options: [],
      flag: false,
      isShow: false
    };
  },
  mounted: function mounted() {
    if (this.$route.query.pageNo) {
      this.table.type = this.$route.query.type;
      this.table.line = this.$route.query.line;
      // this.table.faqTitleId = this.$route.query.faqTitleId;
      this.table.pageNo = this.$route.query.pageNo;
      this.tables.type = this.$route.query.type;
      this.tables.line = this.$route.query.line;
      // this.tables.faqTitleId = this.$route.query.faqTitleId;
      this.tables.pageNo = Number(this.$route.query.pageNo);
    }
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    handleClick: function handleClick(tab, event) {},
    //列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _faqmess.getLists)(_this.tables).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.tables.pageNo = 1;
            _this.currentPage = 1;
            (0, _faqmess.getLists)(_this.tables).then(function (res) {
              _this.tableData = res.voList;
              _this.total = res.total;
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.tables.pageNo = currentPage;
      this.getList();
    },
    //判断类型
    stateFormat: function stateFormat(row) {
      if (row.type === "0") {
        return "FAQ";
      } else {
        return "故障排除";
      }
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line === "1") {
        return "预览";
      } else if (row.line === "2") {
        return "下线";
      }
    },
    //下拉改变出现标题
    handleChangeFirst: function handleChangeFirst(value) {
      var _this2 = this;
      this.table.faqTitleId = "";
      (0, _faq.faqType)(this.table.type).then(function (res) {
        if (res.success) {
          _this2.tit = res.voList;
        }
      });
    },
    //搜索
    search: function search() {
      this.tables.pageNo = 1;
      this.currentPage = 1;
      this.tables.line = this.table.line;
      this.tables.type = this.table.type;
      this.tables.faqTitleId = this.table.faqTitleId;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.table.type = "";
      this.currentPage = 1;
      this.table.titleCn = "";
      this.table.line = "";
      this.tables.type = "";
      this.tables.pageNo = 1;
      this.tables.faqTitleId = "";
      this.tables.line = "";
      window.location.href = window.location.href.split("?")[0];
      this.getList();
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "addqamess",
        query: {
          line: this.table.line,
          type: this.table.type,
          faqTitleId: this.table.faqTitleId,
          pageNo: this.currentPage
        }
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changeqamess",
        query: {
          id: id,
          line: this.table.line,
          type: this.table.type,
          faqTitleId: this.table.faqTitleId,
          pageNo: this.currentPage
        }
      });
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/faq/item/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this3 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
      } else if (!/(^[1-9]\d*$)/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
      } else {
        (0, _faqmess.changeSeq)({
          seq: val.seq
        }, val.id).then(function (res) {
          if (res.success) {
            _this3.msgTip("success", "修改成功");
            _this3.isShow = false;
            _this3.getList();
          } else {
            _this3.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //上线
    on: function on(row) {
      var _this4 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _faqmess.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "上线成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this5 = this;
      if (row.line == "1") {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _faqmess.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "操作成功");
            _this5.getList();
          } else {
            _this5.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下
    off: function off(row) {
      var _this6 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _faqmess.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "下线成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this7 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _faqmess.delefaq)(id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "删除成功");
            _this7.getList();
          } else {
            _this7.msgTip("error", res.errorMsg);
          }
        });
      });
    }
  }
};