"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _gallery = require("../../api/gallery.js");
var _faq = require("../../api/faq");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
var _index = require("../../utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      tableData: [],
      form: {
        line: "",
        pageNo: 1,
        pageSize: 10
      },
      forms: {
        line: "",
        pageNo: 1,
        pageSize: 10
      },
      searchBtn: false,
      total: 0,
      currentPage: this.$route.query.cur ? Number(this.$route.query.cur) : 1,
      loading: false,
      isDisable: false,
      type: [],
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      value: "",
      flag: false,
      changeid: "",
      radio: "",
      addApprove: {
        seq: ""
      }
    };
  },
  created: function created() {},
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  mounted: function mounted() {
    if (this.$route.query.cur) {
      this.form.line = this.$route.query.line;
      this.form.pageNo = this.$route.query.cur;
      this.forms.line = this.$route.query.line;
      this.forms.pageNo = this.$route.query.cur;
    }
    this.getList();
  },
  methods: {
    //请求列表数据
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _gallery.getLists)(_this.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.forms.pageNo = 1;
            _this.currentPage = 1;
            (0, _gallery.getLists)(_this.forms).then(function (res) {
              _this.tableData = res.voList;
              _this.total = res.total;
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //搜索
    search: function search() {
      this.searchBtn = true;
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.line = this.form.line;
      this.getList();
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line === "1") {
        return "预览";
      } else if (row.line === "2") {
        return "下线";
      }
    },
    //清空
    empty: function empty() {
      this.form.line = "";
      this.forms.line = "";
      this.currentPage = 1;
      this.forms.pageNo = 1;
      window.location.href = window.location.href.split('?')[0];
      this.getList();
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "/gallery/addgallery",
        query: {
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "/gallery/changegallery",
        query: {
          id: id,
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //上线
    online: function online(row) {
      var _this2 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _gallery.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this2.msgTip("success", "上线成功");
            _this2.getList();
          } else {
            _this2.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this3 = this;
      if (row.line == '1') {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _gallery.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this3.msgTip("success", "操作成功");
            _this3.getList();
          } else {
            _this3.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(row) {
      var _this4 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _gallery.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "下线成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this5 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _gallery.delegallery)(id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "删除成功");
            _this5.getList();
          } else {
            _this5.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/gallery/out/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    //判断上下线
    lineFormatter: function lineFormatter(row) {
      if (row.line == "0") {
        return "上线";
      } else if (row.line == "1") {
        return "预览";
      } else if (row.line == "2") {
        return "下线";
      }
    },
    bindSeq: function bindSeq(val) {
      var _this6 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
        return;
      }
      if (!/^[1-9]{1,}[\d]*$/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
        return;
      }
      this.addApprove.seq = val.seq;
      (0, _gallery.updateSeq)(this.addApprove, val.id).then(function (res) {
        if (res.success) {
          _this6.msgTip("success", "修改成功");
          _this6.empty();
          _this6.isShow = false;
        } else {
          _this6.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    }
  }
};