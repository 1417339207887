var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "change",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.changeRules,
            "status-icon": true,
            "show-message": false,
            "hide-required-asterisk": "",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "类型",
                "label-width": _vm.formLabelWidth,
                prop: "type",
              },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { disabled: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.option, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.type },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "缩略图(140*181)",
                "label-width": _vm.formLabelWidth,
                prop: "type",
              },
            },
            [
              _c(
                "el-upload",
                {
                  staticClass: "avatar-uploader",
                  attrs: {
                    action: "https://jsonplaceholder.typicode.com/posts/",
                    "show-file-list": false,
                    "before-upload": _vm.beforeUploadPicture1,
                  },
                },
                [
                  _vm.imageUrl1
                    ? _c("img", {
                        staticClass: "avatar",
                        attrs: { src: _vm.imageUrl1 },
                      })
                    : _c("i", {
                        staticClass: "el-icon-plus avatar-uploader-icon",
                      }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "版本号",
                "label-width": _vm.formLabelWidth,
                prop: "versionCn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.form.versionCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "versionCn", $$v)
                  },
                  expression: "form.versionCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "版本号EN",
                "label-width": _vm.formLabelWidth,
                prop: "versionEn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.form.versionEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "versionEn", $$v)
                  },
                  expression: "form.versionEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "版本号JA",
                "label-width": _vm.formLabelWidth,
                prop: "versionJa",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.form.versionJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "versionJa", $$v)
                  },
                  expression: "form.versionJa",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "文件大小", "label-width": _vm.formLabelWidth } },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  maxlength: "255",
                  disabled: true,
                },
                model: {
                  value: _vm.form.sizeCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sizeCn", $$v)
                  },
                  expression: "form.sizeCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "文件大小EN", "label-width": _vm.formLabelWidth },
            },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  maxlength: "255",
                  disabled: true,
                },
                model: {
                  value: _vm.form.sizeEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sizeEn", $$v)
                  },
                  expression: "form.sizeEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: { label: "文件大小JA", "label-width": _vm.formLabelWidth },
            },
            [
              _c("el-input", {
                attrs: {
                  autocomplete: "off",
                  type: "text",
                  maxlength: "255",
                  disabled: true,
                },
                model: {
                  value: _vm.form.sizeJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sizeJa", $$v)
                  },
                  expression: "form.sizeJa",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题CN",
                "label-width": _vm.formLabelWidth,
                prop: "titleCn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.form.titleCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "titleCn", $$v)
                  },
                  expression: "form.titleCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题EN",
                "label-width": _vm.formLabelWidth,
                prop: "titleEn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.form.titleEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "titleEn", $$v)
                  },
                  expression: "form.titleEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题JA",
                "label-width": _vm.formLabelWidth,
                prop: "titleJa",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.form.titleJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "titleJa", $$v)
                  },
                  expression: "form.titleJa",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commont" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "下载地址CN",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "upload-wrapper" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.form.urlCn,
                          target: _vm.form.type === "0" ? "_blank" : "",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_vm._v("下载")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "下载地址EN",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "upload-wrapper" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.form.urlEn,
                          target: _vm.form.type === "0" ? "_blank" : "",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_vm._v("下载")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "下载地址JA",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("div", { staticClass: "upload-wrapper" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.form.urlJa,
                          target: _vm.form.type === "0" ? "_blank" : "",
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "exprot-btn",
                            attrs: { size: "small", type: "primary" },
                          },
                          [_vm._v("下载")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commont" },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "视频CN", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrapper" },
                    [
                      _c("input", {
                        ref: "uploadFileCn",
                        staticClass: "upload-file-cn",
                        attrs: {
                          id: "fileCn",
                          type: "file",
                          name: "fileCn",
                          multiple: "",
                        },
                        on: { change: _vm.submitUploadCn },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingCn,
                              expression: "loadingCn",
                            },
                          ],
                          staticClass: "exprot-btn",
                          attrs: { size: "small", type: "primary" },
                        },
                        [_vm._v("上传")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.videoSrcCn
                    ? _c("video", {
                        staticClass: "video",
                        attrs: {
                          width: "300px",
                          src: _vm.videoSrcCn,
                          controls: "controls",
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "视频EN", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrapper" },
                    [
                      _c("input", {
                        ref: "uploadFileEn",
                        staticClass: "upload-file-en",
                        attrs: {
                          id: "fileEn",
                          type: "file",
                          name: "fileEn",
                          multiple: "",
                        },
                        on: { change: _vm.submitUploadEn },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingEn,
                              expression: "loadingEn",
                            },
                          ],
                          staticClass: "exprot-btn",
                          attrs: { size: "small", type: "primary" },
                        },
                        [_vm._v("上传")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.videoSrcEn
                    ? _c("video", {
                        staticClass: "video",
                        attrs: {
                          width: "300px",
                          src: _vm.videoSrcEn,
                          controls: "controls",
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "视频JA", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrapper" },
                    [
                      _c("input", {
                        ref: "uploadFileJa",
                        staticClass: "upload-file-ja",
                        attrs: {
                          id: "fileJa",
                          type: "file",
                          name: "fileJa",
                          multiple: "",
                        },
                        on: { change: _vm.submitUploadJa },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loadingJa,
                              expression: "loadingJa",
                            },
                          ],
                          staticClass: "exprot-btn",
                          attrs: { size: "small", type: "primary" },
                        },
                        [_vm._v("上传")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.videoSrcJa
                    ? _c("video", {
                        staticClass: "video",
                        attrs: {
                          width: "300px",
                          src: _vm.videoSrcJa,
                          controls: "controls",
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ul", [
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述CN")]),
            _vm._v(" "),
            _c(
              "el-upload",
              {
                staticClass: "avatar-uploader",
                staticStyle: { display: "none" },
                attrs: {
                  action: "",
                  "http-request": _vm.to_upload_img,
                  "show-file-list": false,
                },
              },
              [
                _vm._v("\n        >\n        "),
                _c("i", {
                  staticClass: "el-icon-plus avatar-uploader-icon",
                  attrs: { id: "imgInput" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.form.descriptionCn,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "descriptionCn", $$v)
                },
                expression: "form.descriptionCn",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述EN")]),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor1",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.form.descriptionEn,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "descriptionEn", $$v)
                },
                expression: "form.descriptionEn",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述JA")]),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor2",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.form.descriptionJa,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "descriptionJa", $$v)
                },
                expression: "form.descriptionJa",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }