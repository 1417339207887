"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneList = aloneList;
exports.changeList = changeList;
exports.changeOn = changeOn;
exports.dataManipulation = dataManipulation;
exports.deleList = deleList;
exports.getLists = getLists;
exports.updateSeq = updateSeq;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取创作图
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/home/creation/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改序号
function updateSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/home/creation/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增用户作品
function addList(data) {
  return (0, _request.default)({
    url: 'cms/home/creation',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改用户作品
function changeList(data) {
  return (0, _request.default)({
    url: 'cms/home/creation',
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据ID获取用户作品
function aloneList(id) {
  return (0, _request.default)({
    url: 'cms/home/creation/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除
function deleList(id) {
  return (0, _request.default)({
    url: 'cms/home/creation/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//上下线
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/home/creation/' + id,
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, line, updateTime, updateUser) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (updateUser != null && updateUser != "") {
        element.updateUser = updateUser;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
      if (line != null && line != "") {
        element.line = line;
      }
    }
  });
  return tableData;
}