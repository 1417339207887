"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _sallrules = require("../../api/sallrules.js");
var _dictionary = require("../../api/dictionary.js");
var _index = require("../../utils/index.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        enableFlag: "",
        promoType: "",
        pageNo: 1,
        pageSize: 10
      },
      type: [],
      start: [{
        value: "1",
        valueCn: "启用"
      }, {
        value: "0",
        valueCn: "停用"
      }],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      dialogFormVisible: false,
      formLabelWidth: "120px",
      isDisable: false,
      fixflag: false,
      percentFlag: false,
      giftFlag: false,
      isShow: false,
      addform: {
        currency: "",
        giftCode: "",
        limitValue: "",
        matchProduct: "",
        promoDiscount: "",
        promoType: "",
        promoValue: "",
        totalCount: "",
        giftPictureUrl: ''
      },
      goods: [],
      addRules: {
        currency: [{
          required: true,
          trigger: "blur"
        }],
        giftCode: [{
          required: true,
          trigger: "blur"
        }],
        limitValue: [{
          required: true,
          trigger: "blur"
        }],
        matchProduct: [{
          required: true,
          trigger: "change"
        }],
        promoDiscount: [{
          required: true,
          trigger: "blur"
        }],
        promoType: [{
          required: true,
          trigger: "change"
        }],
        promoValue: [{
          required: true,
          trigger: "blur"
        }],
        totalCount: [{
          required: true,
          trigger: "blur"
        }]
      },
      gift: [],
      imageUrl1: '',
      recommendParam: {
        enableFlag: "",
        id: ""
      },
      price: [{
        value: "RMB",
        label: "RMB"
      }, {
        value: "USD",
        label: "USD"
      }]
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDic)({
      name: "PROMO_RULE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _sallrules.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _sallrules.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
              res.voList.map(function (element) {
                if (element.enableFlag == "1") {
                  _this2.$set(element, "enableFlag", true);
                } else if (element.enableFlag == "0") {
                  _this2.$set(element, "enableFlag", false);
                }
              });
            });
          }
          _this2.tableData = res.voList;
          _this2.tableData.map(function (element) {
            if (element.enableFlag == "1") {
              _this2.$set(element, "enableFlag", true);
            } else if (element.enableFlag == "0") {
              _this2.$set(element, "enableFlag", false);
            }
          });
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //判断优惠规则类型
    types: function types(row) {
      if (row.promoType == "1") {
        return "优惠固定值";
      } else if (row.promoType == "2") {
        return "优惠折扣";
      } else if (row.promoType == "3") {
        return "免运费";
      } else if (row.promoType == "4") {
        return "礼品";
      }
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.enableFlag = "";
      this.form.promoType = "";
      this.getList();
    },
    //新增
    add: function add() {
      var _this3 = this;
      this.dialogFormVisible = true;
      (0, _dictionary.searchDiclist)({
        name: "MATCH_PRODUCT",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this3.goods = res.voList;
      });
      (0, _dictionary.searchDiclist)({
        name: "GIFT_CODE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this3.gift = res.voList;
      });
    },
    changeRule: function changeRule(v) {
      if (v == "1") {
        this.fixflag = true;
        this.percentFlag = false;
        this.giftFlag = false;
      } else if (v == "2") {
        this.fixflag = false;
        this.percentFlag = true;
        this.giftFlag = false;
      } else if (v == "3") {
        this.fixflag = false;
        this.percentFlag = false;
        this.giftFlag = false;
      } else if (v == "4") {
        this.fixflag = false;
        this.percentFlag = false;
        this.giftFlag = true;
      }
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this4 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this4.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this4.addform.giftPictureUrl = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //新增关闭
    close: function close() {
      this.dialogFormVisible = false;
      this.$refs.addform.resetFields();
      this.fixflag = false;
      this.percentFlag = false;
      this.giftFlag = false;
    },
    //新增确定
    sure: function sure() {
      var _this5 = this;
      this.$refs.addform.validate(function (valid) {
        if (valid) {
          _this5.isDisable = true;
          (0, _sallrules.addList)(_this5.addform).then(function (res) {
            if (res.success) {
              _this5.$message({
                type: "success",
                message: "添加成功",
                center: true
              });
              _this5.fixflag = false;
              _this5.percentFlag = false;
              _this5.giftFlag = false;
              _this5.dialogFormVisible = false;
              _this5.getList();
              _this5.isDisable = false;
              _this5.$refs.addform.resetFields();
            } else {
              _this5.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this5.isDisable = false;
            }
          });
        }
      });
    },
    //修改总数量
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/promo/code/rule/totalCount");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this6 = this;
      if (val.totalCount == "") {
        this.$message({
          type: "warning",
          message: "总数量不能为空",
          center: true
        });
      } else if (!/(^[1-9]\d*$)/.test(val.totalCount)) {
        this.$message({
          type: "warning",
          message: "总数量只能为正整数",
          center: true
        });
      } else {
        (0, _sallrules.changeRule)({
          totalCount: val.totalCount
        }, val.id).then(function (res) {
          if (res.success) {
            _this6.$message({
              type: "success",
              message: "修改成功",
              center: true
            });
            _this6.isShow = false;
            _this6.getList();
          } else {
            _this6.$message({
              type: "error",
              message: res.errorMsg,
              center: true
            });
          }
        });
      }
    },
    recommendeTrue: function recommendeTrue(v) {
      var _this7 = this;
      this.recommendParam.enableFlag = "0";
      (0, _sallrules.startRule)({
        enableFlag: this.recommendParam.enableFlag
      }, v.id).then(function (res) {
        if (res.success) {
          _this7.getList();
          _this7.msgTip("success", "操作成功");
        } else {
          _this7.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    recommendeFalse: function recommendeFalse(v) {
      var _this8 = this;
      this.recommendParam.enableFlag = "1";
      (0, _sallrules.startRule)({
        enableFlag: this.recommendParam.enableFlag
      }, v.id).then(function (res) {
        if (res.success) {
          _this8.getList();
          _this8.msgTip("success", "操作成功");
        } else {
          _this8.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    }
  }
};