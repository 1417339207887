"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aloneservice = aloneservice;
exports.changeOn = changeOn;
exports.changeservice = changeservice;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取订单信息
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/additional/service/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//上下线附加服务
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/additional/service/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询附加服务
function aloneservice(id) {
  return (0, _request.default)({
    url: 'cms/additional/service/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改附加服务
function changeservice(id, data) {
  return (0, _request.default)({
    url: 'cms/additional/service/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}