"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogisticsExport = LogisticsExport;
exports.LogisticsExportCsv = LogisticsExportCsv;
exports.LogisticsExportSku = LogisticsExportSku;
exports.LogisticsExportSkuCsv = LogisticsExportSkuCsv;
exports.addList = addList;
exports.aloneList = aloneList;
exports.changeList = changeList;
exports.deleList = deleList;
exports.deliverList = deliverList;
exports.dhlExport = dhlExport;
exports.dhlInvoiceExport = dhlInvoiceExport;
exports.dhlPush = dhlPush;
exports.dhlPushS = dhlPushS;
exports.dhlStockingExport = dhlStockingExport;
exports.getAllCountry = getAllCountry;
exports.getAllInvoice = getAllInvoice;
exports.getAllbox = getAllbox;
exports.getAllclearance = getAllclearance;
exports.getChange = getChange;
exports.getCount = getCount;
exports.getCountry = getCountry;
exports.getFreight = getFreight;
exports.getGatherType = getGatherType;
exports.getGerInvoice = getGerInvoice;
exports.getInvoice = getInvoice;
exports.getInvoices = getInvoices;
exports.getLists = getLists;
exports.getNoInvoice = getNoInvoice;
exports.getNobox = getNobox;
exports.getNoclearance = getNoclearance;
exports.getPrint = getPrint;
exports.getPrintUpdate = getPrintUpdate;
exports.getProvince = getProvince;
exports.getSplitclearance = getSplitclearance;
exports.getSplitinvoice = getSplitinvoice;
exports.getSplitpacking = getSplitpacking;
exports.getStop = getStop;
exports.getStopCancel = getStopCancel;
exports.getSupple = getSupple;
exports.getUpload = getUpload;
exports.getUploadDelivery = getUploadDelivery;
exports.getUploadDhlStocking = getUploadDhlStocking;
exports.getUploadRiya = getUploadRiya;
exports.getUploadTm = getUploadTm;
exports.getbox = getbox;
exports.getclearance = getclearance;
exports.locationChange = locationChange;
exports.orderExport = orderExport;
exports.otherDealer = otherDealer;
exports.returnMoney = returnMoney;
exports.returnlogistics = returnlogistics;
exports.searchBag = searchBag;
exports.sfExport = sfExport;
exports.shopExport = shopExport;
exports.skuCount = skuCount;
exports.toAccount = toAccount;
exports.tpush = tpush;
exports.tpushDelivery = tpushDelivery;
exports.updateList = updateList;
exports.upsExport = upsExport;
exports.waydownload = waydownload;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取订单信息
function getLists(data) {
  return (0, _request.default)({
    url: '/order/logistics/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 120000,
    data: data
  });
}

//发货
function deliverList(data) {
  return (0, _request.default)({
    url: '/order/logistics',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 120000
  });
}

//物流信息退换
function returnlogistics(data) {
  return (0, _request.default)({
    url: 'order/customer/logistics/number',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 120000
  });
}

//删除
function deleList(id) {
  return (0, _request.default)({
    url: '/order/logistics/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//到账
function toAccount(data) {
  return (0, _request.default)({
    url: 'order/logistics/account',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个订单物流
function aloneList(id) {
  return (0, _request.default)({
    url: '/order/logistics/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改单个订单物流
function changeList(data) {
  return (0, _request.default)({
    url: 'order/logistics/update',
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 120000
  });
}

//一键汇总
function getGatherType(data) {
  return (0, _request.default)({
    url: 'order/logistics/gather',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 120000
  });
}

//查询子菜单
function searchBag(id) {
  return (0, _request.default)({
    url: 'order/receive/address/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//打印
function getPrint(data) {
  return (0, _request.default)({
    url: 'order/logistics/stock/print',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 150000
  });
}

//修改打印编号
function getPrintUpdate(data) {
  return (0, _request.default)({
    url: 'order/logistics/print/update',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//导出
function LogisticsExport(data) {
  return (0, _request.default)({
    url: 'order/logistics/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}
//导出-CSV
function LogisticsExportCsv(data) {
  return (0, _request.default)({
    url: 'order/logistics/csv/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}
//导出SKU
function LogisticsExportSku(data) {
  return (0, _request.default)({
    url: 'order/logistics/sku/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}

//导出SKU-CSV
function LogisticsExportSkuCsv(data) {
  return (0, _request.default)({
    url: 'order/logistics/sku/csv/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}
//商品明细导出
function shopExport(data) {
  return (0, _request.default)({
    url: 'order/logistics/detail/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}

//运单导出
function dhlStockingExport(data) {
  return (0, _request.default)({
    url: 'order/dhl/stocking/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}

//DHL运单导出
function orderExport(data) {
  return (0, _request.default)({
    url: 'order/logistics/dhl/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}

//DHL运单下载
function waydownload(data) {
  return (0, _request.default)({
    url: 'order/logistics/waybill/download',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}

//dhl申报导出
function dhlExport(data) {
  return (0, _request.default)({
    url: 'order/dhl/declare/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}
//dhl申报导出
function dhlInvoiceExport(data) {
  return (0, _request.default)({
    url: 'order/logistics/dhl/invoice/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}
//ups运单导出
function upsExport(data) {
  return (0, _request.default)({
    url: 'order/ups/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}
//sf运单导出
function sfExport(data) {
  return (0, _request.default)({
    url: 'order/sf/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}

//装箱单
function getbox(id) {
  return (0, _request.default)({
    url: 'order/logistics/packing/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//发票单
function getInvoice(id) {
  return (0, _request.default)({
    url: 'order/logistics/invoice/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//报关单
function getclearance(id) {
  return (0, _request.default)({
    url: 'order/logistics/declare/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//装箱单（含运费-合）
function getAllbox(id) {
  return (0, _request.default)({
    url: 'order/logistics/packing/close/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//发票单（含运费-合）
function getAllInvoice(id) {
  return (0, _request.default)({
    url: 'order/logistics/invoice/close/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//报关单（含运费-合）
function getAllclearance(id) {
  return (0, _request.default)({
    url: 'order/logistics/declare/close/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//装箱单(不含运费)
function getNobox(id) {
  return (0, _request.default)({
    url: 'order/logistics/packing/no/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//发票单(不含运费)
function getNoInvoice(id) {
  return (0, _request.default)({
    url: 'order/logistics/invoice/no/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//报关单(不含运费)
function getNoclearance(id) {
  return (0, _request.default)({
    url: 'order/logistics/declare/no/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//报关单(含运费)-拆分
function getSplitclearance(id) {
  return (0, _request.default)({
    url: 'order/logistics/declare/split/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//发票单(含运费)-拆分
function getSplitinvoice(id) {
  return (0, _request.default)({
    url: 'order/logistics/invoice/split/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//报关单(含运费)-拆分
function getSplitpacking(id) {
  return (0, _request.default)({
    url: 'order/logistics/packing/split/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//GER运费
function getFreight(data) {
  return (0, _request.default)({
    url: 'order/ger/freight/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//GER发票
function getGerInvoice(data) {
  return (0, _request.default)({
    url: 'order/ger/invoice/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//DHL推送
function dhlPush(data) {
  return (0, _request.default)({
    url: 'order/logistics/dhl/push',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 8000000,
    data: data
  });
}

//DHL推送
function dhlPushS(data) {
  return (0, _request.default)({
    url: 'order/logistics/dhl/pushS',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 8000000,
    data: data
  });
}

//查询订单包含设备数
function getCount(orderNo) {
  return (0, _request.default)({
    url: 'order/info/equipment/counts/' + orderNo,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//查询单个订单发票信息
function getInvoices(data) {
  return (0, _request.default)({
    url: 'order/logistics/invoice',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//第三方平台订单数据录入
function addList(data) {
  return (0, _request.default)({
    url: 'order/other/dealer/add',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 120000
  });
}
//查询所有国家
function getCountry(language) {
  return (0, _request.default)({
    url: 'official/order/country/query/' + language,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//查询所有美国省
function getProvince(countryCode) {
  return (0, _request.default)({
    url: 'official/order/country/query/province/' + countryCode,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//第二个修改按钮
function otherDealer(orderNo) {
  return (0, _request.default)({
    url: 'order/other/dealer/' + orderNo,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//第二个修改按钮提交
function getChange(data) {
  return (0, _request.default)({
    url: 'order/other/dealer/update',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//批量修改打印编号
function updateList(data) {
  return (0, _request.default)({
    url: 'order/logistics/print/update/list',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//导入
function getUpload(data) {
  return (0, _request.default)({
    url: 'order/shopify/import',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1800000
  });
}
//dhl运单备货
function getUploadDhlStocking(data) {
  return (0, _request.default)({
    url: 'order/dhl/stocking/import',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1800000
  });
}
//批量发货
function getUploadDelivery(data) {
  return (0, _request.default)({
    url: 'order/batch/delivery',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1800000
  });
}
//天猫&京东导入
function getUploadTm(data) {
  return (0, _request.default)({
    url: 'order/tm/import',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1800000
  });
}
//日亚导入
function getUploadRiya(data) {
  return (0, _request.default)({
    url: 'order/riya/import',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1800000
  });
}
//查询国家
//批量修改打印编号
function getAllCountry() {
  return (0, _request.default)({
    url: 'order/country/query/all',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//截停
function getStop(id) {
  return (0, _request.default)({
    url: 'order/logistics/stop/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//取消截停
function getStopCancel(id) {
  return (0, _request.default)({
    url: 'order/logistics/stop/cancel/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//申请退款
function returnMoney(data) {
  return (0, _request.default)({
    url: 'order/customer/apply/return/money',
    method: "post",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//补录
function getSupple(data) {
  return (0, _request.default)({
    url: 'order/supplementary/add',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//仓位调整
function locationChange(data) {
  return (0, _request.default)({
    url: 'order/location/change',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1800000
  });
}

//查询实际库存
function skuCount(data) {
  return (0, _request.default)({
    url: 'order/sku/count',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 1800000
  });
}

//T+销售订单补推
function tpush() {
  return (0, _request.default)({
    url: 'order/push/again',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000
  });
}

//T+销货单补推
function tpushDelivery() {
  return (0, _request.default)({
    url: 'order/push/again/delivery',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000
  });
}