"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeEmail = changeEmail;
exports.changeOrder = changeOrder;
exports.changePrice = changePrice;
exports.changeProduct = changeProduct;
exports.emsExport = emsExport;
exports.getContains = getContains;
exports.getLists = getLists;
exports.getServe = getServe;
exports.orderDetail = orderDetail;
exports.orderInfoExport = orderInfoExport;
exports.orderPay = orderPay;
exports.returnMoney = returnMoney;
exports.searchCode = searchCode;
exports.searchList = searchList;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取订单信息
function getLists(data) {
  return (0, _request.default)({
    url: '/order/info/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}

//查询订单附加服务
function getServe(orderNo) {
  return (0, _request.default)({
    url: '/order/additional/service/' + orderNo,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//导出
function orderInfoExport(data) {
  return (0, _request.default)({
    url: '/order/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}
//导出
function emsExport(data) {
  return (0, _request.default)({
    url: '/order/ems/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 1800000,
    data: data
  });
}

//支付回调
function orderPay(data) {
  return (0, _request.default)({
    url: '/pay/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 30000
  });
}

//查询优惠码
function searchCode(orderNo) {
  return (0, _request.default)({
    url: '/order/promo/' + orderNo,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//查询订单信息
function orderDetail(id) {
  return (0, _request.default)({
    url: '/official/order/info/' + id,
    method: "get",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改运费
function changePrice(data) {
  return (0, _request.default)({
    url: 'order/info/update/logistics/price',
    method: "put",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//邮件补发
function changeEmail(orderNo) {
  return (0, _request.default)({
    url: 'order/info/email/send/' + orderNo,
    method: "post",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//查询订单详细信息
function searchList(data) {
  return (0, _request.default)({
    url: 'order/info/detail',
    method: "post",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 500000
  });
}
//修改套餐
function changeProduct(data) {
  return (0, _request.default)({
    url: 'order/info/replace/product',
    method: "post",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//修改订单
function changeOrder(data) {
  return (0, _request.default)({
    url: 'order/info/replace/product',
    method: "post",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//申请退款
function returnMoney(data) {
  return (0, _request.default)({
    url: 'order/customer/apply/return/money',
    method: "post",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//查询订单包信息
function getContains(orderNo) {
  return (0, _request.default)({
    url: 'order/info/line/contains/' + orderNo,
    method: "get",
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}