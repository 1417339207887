var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "waybill" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("订单号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("渠道")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.dealer,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "dealer", $$v)
                    },
                    expression: "form.dealer",
                  },
                },
                _vm._l(_vm.dealers, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("类型")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.flag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "flag", $$v)
                    },
                    expression: "form.flag",
                  },
                },
                _vm._l(_vm.types, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("物流单号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.logisticsNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "logisticsNumber", $$v)
                  },
                  expression: "form.logisticsNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("物流公司")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.logisticsName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "logisticsName", $$v)
                    },
                    expression: "form.logisticsName",
                  },
                },
                _vm._l(_vm.companylogistics, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.valueCn },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("账单日")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy-MM-dd",
                    type: "date",
                    editable: false,
                  },
                  model: {
                    value: _vm.form.billDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "billDate", $$v)
                    },
                    expression: "form.billDate",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("支付起始时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy-MM-dd",
                    type: "date",
                    editable: false,
                  },
                  model: {
                    value: _vm.form.startPayDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startPayDate", $$v)
                    },
                    expression: "form.startPayDate",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("支付截止时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    "value-format": "yyyy-MM-dd",
                    format: "yyyy-MM-dd",
                    type: "date",
                    editable: false,
                  },
                  model: {
                    value: _vm.form.endPayDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endPayDate", $$v)
                    },
                    expression: "form.endPayDate",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/waybill/import",
                  expression: "'/order/waybill/import'",
                },
              ],
              on: { click: _vm.importLists },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/exports.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("导入")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/waybill/export",
                  expression: "'/order/waybill/export'",
                },
              ],
              on: { click: _vm.derive },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/derive.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("导出")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/waybill/print",
                  expression: "'/order/waybill/print'",
                },
              ],
              on: { click: _vm.print },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/print.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("打印")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/waybill/delete",
                  expression: "'/order/waybill/delete'",
                },
              ],
              on: { click: _vm.dele },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("删除")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/waybill/undo",
                  expression: "'/order/waybill/undo'",
                },
              ],
              on: { click: _vm.undo },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/repeal.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("撤销")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/waybill/update",
                                expression: "'/order/waybill/update'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/amend.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.change(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "账单日",
              "show-overflow-tooltip": "",
              width: "100",
              prop: "billDate",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "物流公司",
              "show-overflow-tooltip": "",
              prop: "logisticsName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "物流单号",
              "show-overflow-tooltip": "",
              width: "170",
              prop: "logisticsNumber",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              width: "60",
              "show-overflow-tooltip": "",
              prop: "flag",
              formatter: _vm.typeflag,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "渠道",
              width: "90",
              "show-overflow-tooltip": "",
              prop: "dealer",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单号",
              width: "170",
              "show-overflow-tooltip": "",
              prop: "orderNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "实际运费",
              width: "100",
              prop: "realLogisticsPrice",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "进口税",
              width: "100",
              prop: "inTax",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "出口税",
              width: "100",
              prop: "outTax",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "参考信息",
              width: "170",
              prop: "referenceInformation",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "币种", prop: "unit" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单运费",
              "show-overflow-tooltip": "",
              prop: "orderLogisticsPrice",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "异常描述",
              width: "170",
              "show-overflow-tooltip": "",
              prop: "remark",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "支付时间",
              width: "170",
              prop: "payTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "创建时间",
              width: "170",
              prop: "createTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "修改时间",
              width: "170",
              prop: "updateTime",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next,jumper",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.changeClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "changeForm",
              attrs: {
                model: _vm.changeForm,
                rules: _vm.changeRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流单号",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", disabled: "", maxlength: "100" },
                    model: {
                      value: _vm.logisticsNumber,
                      callback: function ($$v) {
                        _vm.logisticsNumber = $$v
                      },
                      expression: "logisticsNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单/售后号",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", maxlength: "100" },
                    model: {
                      value: _vm.changeForm.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeForm, "orderNo", $$v)
                      },
                      expression: "changeForm.orderNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "type",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.changeForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.changeForm, "type", $$v)
                        },
                        expression: "changeForm.type",
                      },
                    },
                    _vm._l(_vm.changeType, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "进口税",
                    "label-width": _vm.formLabelWidth,
                    prop: "inTax",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", maxlength: "50" },
                    model: {
                      value: _vm.changeForm.inTax,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeForm, "inTax", $$v)
                      },
                      expression: "changeForm.inTax",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "出口税",
                    "label-width": _vm.formLabelWidth,
                    prop: "outTax",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "text", maxlength: "50" },
                    model: {
                      value: _vm.changeForm.outTax,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeForm, "outTax", $$v)
                      },
                      expression: "changeForm.outTax",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "异常描述",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", maxlength: "255" },
                    model: {
                      value: _vm.changeForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeForm, "remark", $$v)
                      },
                      expression: "changeForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.changeClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keep },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogImportForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogImportForm = $event
            },
            close: _vm.importClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "importForm",
              attrs: {
                model: _vm.importForm,
                rules: _vm.importRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流公司",
                    "label-width": _vm.formLabelWidth,
                    prop: "logisticsName",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.importForm.logisticsName,
                        callback: function ($$v) {
                          _vm.$set(_vm.importForm, "logisticsName", $$v)
                        },
                        expression: "importForm.logisticsName",
                      },
                    },
                    _vm._l(_vm.companylogistics, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.valueCn },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "账单日",
                    "label-width": _vm.formLabelWidth,
                    prop: "billDate",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "value-format": "yyyy-MM-dd",
                          format: "yyyy-MM-dd",
                          type: "date",
                          editable: false,
                        },
                        model: {
                          value: _vm.importForm.billDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.importForm, "billDate", $$v)
                          },
                          expression: "importForm.billDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.uploadFlag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备导入",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "upload-wrappers" },
                        [
                          _c("input", {
                            ref: "outFile",
                            staticClass: "upload-files",
                            attrs: {
                              id: "file",
                              type: "file",
                              name: "file",
                              multiple: "",
                            },
                            on: { change: _vm.outUpload },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticClass: "exprot-btn",
                              attrs: { size: "small", type: "primary" },
                            },
                            [_c("span", [_vm._v("点击上传")])]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.importClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _vm.keepFlag
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isDisable },
                      on: { click: _vm.keeps },
                    },
                    [_vm._v("保存")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "waybillPrint",
          attrs: {
            title: "打印",
            visible: _vm.dialogPrintForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogPrintForm = $event
            },
            close: _vm.printClose,
          },
        },
        [
          _c("div", { attrs: { id: "printForm" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  "align-items": "center",
                  "margin-bottom": "10px",
                  position: "relative",
                },
              },
              [
                _c("div", [
                  _c("img", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      src: "https://supernote.com.cn/user/ratta.png",
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "font-size": "24px",
                      "text-align": "center",
                    },
                  },
                  [_vm._v("付款申请表")]
                ),
                _vm._v(" "),
                _c("div", { staticStyle: { display: "flex" } }, [
                  _c(
                    "table",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: {
                        border: "1",
                        width: "200",
                        height: "30",
                        cellspacing: "0",
                      },
                    },
                    [
                      _c("tr", [
                        _c("td", { staticStyle: { "font-weight": "200" } }, [
                          _vm._v("使用部门"),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { "font-weight": "200" } }, [
                          _vm._v("各部门"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticStyle: { "font-weight": "200" } }, [
                          _vm._v("保存年限"),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { "font-weight": "200" } }, [
                          _vm._v("5年"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", { staticStyle: { "font-weight": "200" } }, [
                          _vm._v("编号"),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticStyle: { "font-weight": "200" } }, [
                          _vm._v("QR-7.5-16"),
                        ]),
                      ]),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "table",
              {
                staticStyle: {
                  "margin-top": "2px",
                  width: "100%",
                  "font-size": "16px",
                  "text-align": "center",
                },
                attrs: { border: "1", cellspacing: "0" },
              },
              [
                _c("tr", { attrs: { Height: "40px" } }, [
                  _c(
                    "td",
                    { staticStyle: { "font-weight": "700", width: "80px" } },
                    [_vm._v("申请日期")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "date" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.printform.applyDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.printform, "applyDate", $$v)
                              },
                              expression: "printform.applyDate",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: {
                              textContent: _vm._s(_vm.printform.applyDate),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticStyle: { "font-weight": "700" },
                      attrs: { colspan: "2" },
                    },
                    [_vm._v("申请部门")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "date" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.market,
                              callback: function ($$v) {
                                _vm.market = $$v
                              },
                              expression: "market",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.market) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("申请人"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "date", attrs: { colspan: "4" } },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.printform.applyUser,
                              callback: function ($$v) {
                                _vm.$set(_vm.printform, "applyUser", $$v)
                              },
                              expression: "printform.applyUser",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: {
                              textContent: _vm._s(_vm.printform.applyUser),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", { attrs: { Height: "40px" } }, [
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("申请款项"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "fund",
                      staticStyle: { "text-align": "left" },
                      attrs: { colspan: "10" },
                    },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.printform.applyMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.printform, "applyMoney", $$v)
                              },
                              expression: "printform.applyMoney",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: {
                              textContent: _vm._s(_vm.printform.applyMoney),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", { attrs: { Height: "40px" } }, [
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("付款合计"),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticStyle: { background: "#eee" } }, [
                    _vm._v("小写金额"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "date" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.printform.lowerTotalPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.printform, "lowerTotalPrice", $$v)
                              },
                              expression: "printform.lowerTotalPrice",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.printform.lowerTotalPrice
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticStyle: { background: "#eee", width: "120px" } },
                    [_vm._v("大写金额")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "upperTotal", attrs: { colspan: "8" } },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.printform.upperTotalPrice,
                              callback: function ($$v) {
                                _vm.$set(_vm.printform, "upperTotalPrice", $$v)
                              },
                              expression: "printform.upperTotalPrice",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: {
                              textContent: _vm._s(
                                _vm.printform.upperTotalPrice
                              ),
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", { attrs: { Height: "40px" } }, [
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("付款方式"),
                  ]),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "10" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("label", [
                          _c("input", {
                            attrs: { name: "Type", type: "radio", value: "1" },
                          }),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "200",
                                "margin-left": "3px",
                                "font-size": "13px",
                              },
                            },
                            [_vm._v("现金")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticStyle: { "margin-left": "20px" } },
                          [
                            _c("input", {
                              attrs: {
                                name: "Type",
                                type: "radio",
                                value: "2",
                                checked: "checked",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "200",
                                  "margin-left": "3px",
                                  "font-size": "13px",
                                },
                              },
                              [_vm._v("网银")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticStyle: { "margin-left": "20px" } },
                          [
                            _c("input", {
                              attrs: {
                                name: "Type",
                                type: "radio",
                                value: "3",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "200",
                                  "margin-left": "3px",
                                  "font-size": "13px",
                                },
                              },
                              [_vm._v("支票")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticStyle: { "margin-left": "20px" } },
                          [
                            _c("input", {
                              attrs: {
                                name: "Type",
                                type: "radio",
                                value: "3",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "200",
                                  "margin-left": "3px",
                                  "font-size": "13px",
                                },
                              },
                              [_vm._v("第三方")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          { staticStyle: { "margin-left": "20px" } },
                          [
                            _c("input", {
                              attrs: {
                                name: "Type",
                                type: "radio",
                                value: "3",
                              },
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "200",
                                  "margin-left": "3px",
                                  "font-size": "13px",
                                },
                              },
                              [_vm._v("其它")]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", { attrs: { Height: "35px" } }, [
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("收款人"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund", attrs: { colspan: "3" } },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.receiver,
                              callback: function ($$v) {
                                _vm.receiver = $$v
                              },
                              expression: "receiver",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.receiver) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("付款日期"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund", attrs: { colspan: "3" } },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.payDate,
                              callback: function ($$v) {
                                _vm.payDate = $$v
                              },
                              expression: "payDate",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.payDate) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", { attrs: { Height: "35px" } }, [
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("银行账号"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund", attrs: { colspan: "3" } },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bankNumber,
                              callback: function ($$v) {
                                _vm.bankNumber = $$v
                              },
                              expression: "bankNumber",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.bankNumber) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("开户银行"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund", attrs: { colspan: "3" } },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bank,
                              callback: function ($$v) {
                                _vm.bank = $$v
                              },
                              expression: "bank",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.bank) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "table",
              {
                staticStyle: { "margin-top": "2px", width: "100%" },
                attrs: { border: "1", cellspacing: "0" },
              },
              [
                _c("tr", [
                  _c("th", { staticStyle: { width: "50px", height: "50px" } }),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticStyle: { "font-weight": "700", background: "#eee" },
                    },
                    [
                      _c("div", { staticStyle: { "font-weight": "500" } }, [
                        _vm._v("渠道"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticStyle: { "font-weight": "700", background: "#eee" },
                    },
                    [
                      _c("div", { staticStyle: { "font-weight": "500" } }, [
                        _vm._v("运费"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticStyle: { "font-weight": "700", background: "#eee" },
                    },
                    [
                      _c("div", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v("进口税"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "th",
                    {
                      staticStyle: { "font-weight": "700", background: "#eee" },
                    },
                    [
                      _c("div", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v("出口税"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(this.printform.dealerPriceList, function (item, index) {
                  return _c("tr", { key: index }, [
                    _c(
                      "th",
                      { staticStyle: { width: "50px", height: "50px" } },
                      [_vm._v(_vm._s(index + 1))]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "none",
                        staticStyle: { "font-weight": "700", width: "150px" },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: item.name,
                                callback: function ($$v) {
                                  _vm.$set(item, "name", $$v)
                                },
                                expression: "item.name",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: { textContent: _vm._s(item.name) },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "model",
                        staticStyle: { "font-weight": "700", width: "150px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "center",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { blur: _vm.changeValue },
                                  model: {
                                    value: item.value,
                                    callback: function ($$v) {
                                      _vm.$set(item, "value", $$v)
                                    },
                                    expression: "item.value",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(item.value) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "unit",
                        staticStyle: { "font-weight": "700", width: "150px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "center",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { blur: _vm.changeValue },
                                  model: {
                                    value: item.inTax,
                                    callback: function ($$v) {
                                      _vm.$set(item, "inTax", $$v)
                                    },
                                    expression: "item.inTax",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(item.inTax) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "unit",
                        staticStyle: { "font-weight": "700", width: "150px" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "justify-content": "center",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { blur: _vm.changeValue },
                                  model: {
                                    value: item.outTax,
                                    callback: function ($$v) {
                                      _vm.$set(item, "outTax", $$v)
                                    },
                                    expression: "item.outTax",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.outTax),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "table",
              {
                staticStyle: {
                  "margin-top": "2px",
                  width: "100%",
                  "font-size": "16px",
                  "text-align": "center",
                },
                attrs: { border: "1", cellspacing: "0" },
              },
              [
                _c("tr", { attrs: { Height: "50px" } }, [
                  _c(
                    "td",
                    { staticStyle: { "font-weight": "700", width: "80px" } },
                    [_vm._v("经理签字")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.mannger,
                              callback: function ($$v) {
                                _vm.mannger = $$v
                              },
                              expression: "mannger",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.mannger) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticStyle: { "font-weight": "700", width: "80px" } },
                    [_vm._v("财务主管")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.finance,
                              callback: function ($$v) {
                                _vm.finance = $$v
                              },
                              expression: "finance",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.finance) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticStyle: { "font-weight": "700", width: "80px" } },
                    [_vm._v("部门主管")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund", attrs: { colspan: "3" } },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.section,
                              callback: function ($$v) {
                                _vm.section = $$v
                              },
                              expression: "section",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.section) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticStyle: { "font-weight": "700", width: "80px" } },
                    [_vm._v("现金收款")]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "fund" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.money,
                              callback: function ($$v) {
                                _vm.money = $$v
                              },
                              expression: "money",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.money) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.printClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.printSure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }