"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aloneCustomer = aloneCustomer;
exports.changeCustomer = changeCustomer;
exports.checkCustomer = checkCustomer;
exports.checkProduct = checkProduct;
exports.customerExportSkuCsv = customerExportSkuCsv;
exports.getBag = getBag;
exports.getFinance = getFinance;
exports.getLists = getLists;
exports.getOrderNo = getOrderNo;
exports.getPrint = getPrint;
exports.getRefundPrint = getRefundPrint;
exports.getReview = getReview;
exports.orderCustomerExport = orderCustomerExport;
exports.orderCustomerExportSku = orderCustomerExportSku;
exports.refundProduct = refundProduct;
exports.replaceProduct = replaceProduct;
exports.returnMoney = returnMoney;
exports.returnProduct = returnProduct;
exports.returnRegister = returnRegister;
exports.returnlogistics = returnlogistics;
exports.saveCustomer = saveCustomer;
exports.searchBag = searchBag;
exports.tpush = tpush;
exports.undoProduct = undoProduct;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取订单信息
function getLists(data) {
  return (0, _request.default)({
    url: '/order/customer/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据订单号查询订单包详情-目前用于售后的商品选择
function getBag(orderNo) {
  return (0, _request.default)({
    url: '/order/line/' + orderNo,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//退货
function returnProduct(data) {
  return (0, _request.default)({
    url: 'order/customer/return/product',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//换货
function replaceProduct(data) {
  return (0, _request.default)({
    url: 'order/customer/replace/product',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//放款
function refundProduct(customerNumber) {
  return (0, _request.default)({
    url: 'order/customer/refund/' + customerNumber,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 60000
  });
}

//退款
function returnMoney(data) {
  return (0, _request.default)({
    url: 'order/customer/return/money',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据售后编号查询售后服务包
function searchBag(customerNumber) {
  return (0, _request.default)({
    url: 'order/customer/line/' + customerNumber,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//物流信息退换
function returnlogistics(data) {
  return (0, _request.default)({
    url: 'order/customer/logistics/number',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//撤销
function undoProduct(customerNumber) {
  return (0, _request.default)({
    url: 'order/customer/undo/' + customerNumber,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//验货
function checkProduct(data) {
  return (0, _request.default)({
    url: 'order/customer/examine/goods',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//导出
function orderCustomerExport(data) {
  return (0, _request.default)({
    url: 'order/customer/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data,
    timeout: 1800000
  });
}
//导出SKU
function orderCustomerExportSku(data) {
  return (0, _request.default)({
    url: 'order/customer/sku/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data,
    timeout: 1800000
  });
}
//导出CSV
function customerExportSkuCsv(data) {
  return (0, _request.default)({
    url: 'order/customer/csv/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data,
    timeout: 1800000
  });
}
//打印
function getPrint(data) {
  return (0, _request.default)({
    url: 'order/customer/print',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 150000
  });
}
//退款单
function getRefundPrint(id) {
  return (0, _request.default)({
    url: 'order/customer/refund/print/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//售后审核详细信息
function checkCustomer(customerNumber) {
  return (0, _request.default)({
    url: 'order/customer/detail/' + customerNumber,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//售后审核保存
function saveCustomer(data) {
  return (0, _request.default)({
    url: 'order/customer/audit',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//根据售后编号查询单个售后记录
function aloneCustomer(customerNumber) {
  return (0, _request.default)({
    url: 'order/customer/query/' + customerNumber,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//修改
function changeCustomer(data) {
  return (0, _request.default)({
    url: 'order/customer/update',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//退回登记
function returnRegister(data) {
  return (0, _request.default)({
    url: 'order/customer/return/register',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//行政审核
function getReview(data) {
  return (0, _request.default)({
    url: 'order/customer/administration/review',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//财务审核
function getFinance(data) {
  return (0, _request.default)({
    url: 'order/customer/finance/review',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 60000
  });
}

//查询订单包信息
function getOrderNo(orderNo) {
  return (0, _request.default)({
    url: 'order/info/line/contains/' + orderNo,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//T+售后订单补推
function tpush() {
  return (0, _request.default)({
    url: 'order/customer/push/again',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000
  });
}