"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneStandard = aloneStandard;
exports.changeStandard = changeStandard;
exports.deleStandard = deleStandard;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询标准套餐(cms系统调用)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/standard/package/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加标准套餐
function addList(data) {
  return (0, _request.default)({
    url: 'cms/standard/package',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改标准套餐
function changeStandard(id, data) {
  return (0, _request.default)({
    url: 'cms/standard/package/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个标准套餐
function aloneStandard(id) {
  return (0, _request.default)({
    url: 'cms/standard/package/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除标准套餐
function deleStandard(id) {
  return (0, _request.default)({
    url: 'cms/standard/package/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}