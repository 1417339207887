"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.alonevideo = alonevideo;
exports.changeOn = changeOn;
exports.changeVideo = changeVideo;
exports.deleVideo = deleVideo;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取视频接口(cms)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/video/inner/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加视频接口
function addList(data) {
  return (0, _request.default)({
    url: 'cms/video',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//视频上下线接口
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/video/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改视频接口
function changeVideo(id, data) {
  return (0, _request.default)({
    url: 'cms/video/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据ID获取视频接口
function alonevideo(id) {
  return (0, _request.default)({
    url: 'cms/video/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除产品页详情接口
function deleVideo(id) {
  return (0, _request.default)({
    url: 'cms/video/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}