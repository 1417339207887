var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("区域仓")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.locationPosition,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "locationPosition", $$v)
                    },
                    expression: "form.locationPosition",
                  },
                },
                _vm._l(_vm.locationPositions, function (item, index) {
                  return _c("el-option", {
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("产品编号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.productNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productNo", $$v)
                  },
                  expression: "form.productNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("产品名称")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.productName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "productName", $$v)
                  },
                  expression: "form.productName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("发货起始时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "yyyy/MM/dd",
                    "value-format": "yyyy-MM-dd",
                    type: "date",
                    editable: false,
                  },
                  model: {
                    value: _vm.form.startPayTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startPayTime", $$v)
                    },
                    expression: "form.startPayTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("发货截止时间")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "yyyy/MM/dd",
                    "value-format": "yyyy-MM-dd",
                    type: "date",
                    editable: false,
                  },
                  model: {
                    value: _vm.form.endPayTime,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endPayTime", $$v)
                    },
                    expression: "form.endPayTime",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exports } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "区域仓",
              "show-overflow-tooltip": "",
              prop: "locationPosition",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品编号",
              "show-overflow-tooltip": "",
              prop: "productNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品名称",
              "show-overflow-tooltip": "",
              prop: "productName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "数量",
              "show-overflow-tooltip": "",
              prop: "counts",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "退货量",
              "show-overflow-tooltip": "",
              prop: "returnCounts",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "换货量",
              "show-overflow-tooltip": "",
              prop: "replaceCounts",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 100,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }