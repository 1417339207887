"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _contact = require("../../api/contact.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      lines: [{
        line: "0",
        label: "未处理"
      }, {
        line: "1",
        label: "已处理"
      }],
      istype: [{
        line: "0",
        label: "产品咨询"
      }, {
        line: "1",
        label: "订单问题"
      }, {
        line: "2",
        label: "技术支持"
      }, {
        line: "3",
        label: "其他"
      }],
      form: {
        email: "",
        status: "",
        pageNo: 1,
        pageSize: 10,
        type: ''
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      dialogFormVisible: false,
      formLabelWidth: '110px',
      addform: {
        content: ''
      }
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
    //查询列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _contact.getLists)(_this.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.form.pageNo = 1;
            _this.currentPage = 1;
            (0, _contact.getLists)(_this.form).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.email = "";
      this.form.status = "";
      this.form.type = "";
      this.getList();
    },
    //判断状态
    statusFormatter: function statusFormatter(row, column) {
      if (row.status == "0") {
        return "未处理";
      } else if (row.status == "1") {
        return "已处理";
      }
    },
    issource: function issource(row) {
      if (row.source == "1") {
        return "官网";
      } else if (row.source == "2") {
        return "终端";
      }
    },
    types: function types(row) {
      if (row.type == "0") {
        return "产品咨询";
      } else if (row.type == "1") {
        return "订单问题";
      } else if (row.type == "2") {
        return "技术支持";
      } else if (row.type == "3") {
        return "其他";
      }
    }
  }, "issource", function issource(row) {
    if (row.source == "1") {
      return "官网";
    } else if (row.source == "2") {
      return "终端";
    }
  }), "dispose", function dispose(v) {
    var _this2 = this;
    if (v.status == "1") {
      this.msgTip("warning", "该数据已处理");
    } else if (v.status == "0") {
      (0, _contact.disposeEmail)(v.id).then(function (res) {
        if (res.success) {
          _this2.msgTip("success", "操作成功");
          _this2.getList();
          // 前端刷新
          (0, _contact.dataManipulation)(_this2.tableData, v.id, '1', getData());
        } else {
          _this2.msgTip("warning", res.errorMsg);
        }
      });
    }
  }), "addClose", function addClose(formName) {
    this.dialogFormVisible = false;
  }), "look", function look(v) {
    var _this3 = this;
    this.dialogFormVisible = true;
    (0, _contact.aloneEmail)(v.id).then(function (res) {
      _this3.addform.content = res.message;
    });
  }), "derive", function derive() {
    var _this4 = this;
    this.loading = true;
    (0, _contact.exportemail)({
      email: this.form.email,
      status: this.form.status,
      acceptNo: ''
    }).then(function (res) {
      var data = new Blob([res], {
        type: "application/.xls"
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = "联系邮箱表.xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
      _this4.loading = false;
    });
  }), "msgTip", function msgTip(msgType, msgInfo) {
    this.$message({
      type: msgType,
      message: msgInfo,
      center: true
    });
  })
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}