"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneList = aloneList;
exports.changeList = changeList;
exports.getLists = getLists;
exports.orderEquipment = orderEquipment;
exports.repairRevoke = repairRevoke;
exports.repairShip = repairShip;
exports.repairUnRevoke = repairUnRevoke;
exports.verifyPrice = verifyPrice;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询售后维修（售后）
function getLists(data) {
  return (0, _request.default)({
    url: 'order/query/after/sale/repair/all',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增售后维修
function addList(data) {
  return (0, _request.default)({
    url: 'order/add/after/sale/repair',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//根据id查询售后维修
function aloneList(id) {
  return (0, _request.default)({
    url: 'order/query/after/sale/repair/' + id,
    method: 'GET',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//修改售后维修
function changeList(data) {
  return (0, _request.default)({
    url: 'order/after/sale/repair',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//验价
function verifyPrice(data) {
  return (0, _request.default)({
    url: 'order/repair/verify/price',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//发货
function repairShip(data) {
  return (0, _request.default)({
    url: 'order/repair/ship',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//撤销
function repairRevoke(data) {
  return (0, _request.default)({
    url: 'order/repair/revoke',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//解除撤销
function repairUnRevoke(data) {
  return (0, _request.default)({
    url: 'order/repair/unrevoke',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//根据设备号查询订单信息
function orderEquipment(equipmentNumber) {
  return (0, _request.default)({
    url: 'order/repair/info/' + equipmentNumber,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}