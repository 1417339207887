"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var Quill = _interopRequireWildcard(require("quill"));
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _blog = require("../../api/blog.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 标题
var titleConfig = [{
  Choice: ".ql-bold",
  title: "加粗"
}, {
  Choice: ".ql-italic",
  title: "斜体"
}, {
  Choice: ".ql-underline",
  title: "下划线"
}, {
  Choice: ".ql-header",
  title: "段落格式"
}, {
  Choice: ".ql-strike",
  title: "删除线"
}, {
  Choice: ".ql-blockquote",
  title: "块引用"
}, {
  Choice: ".ql-code",
  title: "插入代码"
}, {
  Choice: ".ql-code-block",
  title: "插入代码段"
}, {
  Choice: ".ql-font",
  title: "字体"
}, {
  Choice: ".ql-size",
  title: "字体大小"
}, {
  Choice: '.ql-list[value="ordered"]',
  title: "编号列表"
}, {
  Choice: '.ql-list[value="bullet"]',
  title: "项目列表"
}, {
  Choice: ".ql-direction",
  title: "文本方向"
}, {
  Choice: '.ql-header[value="1"]',
  title: "h1"
}, {
  Choice: '.ql-header[value="2"]',
  title: "h2"
}, {
  Choice: ".ql-align",
  title: "对齐方式"
}, {
  Choice: ".ql-color",
  title: "字体颜色"
}, {
  Choice: ".ql-background",
  title: "背景颜色"
}, {
  Choice: ".ql-image",
  title: "图像"
}, {
  Choice: ".ql-video",
  title: "视频"
}, {
  Choice: ".ql-link",
  title: "添加链接"
}, {
  Choice: ".ql-formula",
  title: "插入公式"
}, {
  Choice: ".ql-clean",
  title: "清除字体格式"
}, {
  Choice: '.ql-script[value="sub"]',
  title: "下标"
}, {
  Choice: '.ql-script[value="super"]',
  title: "上标"
}, {
  Choice: '.ql-indent[value="-1"]',
  title: "向左缩进"
}, {
  Choice: '.ql-indent[value="+1"]',
  title: "向右缩进"
}, {
  Choice: ".ql-header .ql-picker-label",
  title: "标题大小"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="1"]',
  title: "标题一"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="2"]',
  title: "标题二"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="3"]',
  title: "标题三"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="4"]',
  title: "标题四"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="5"]',
  title: "标题五"
}, {
  Choice: '.ql-header .ql-picker-item[data-value="6"]',
  title: "标题六"
}, {
  Choice: ".ql-header .ql-picker-item:last-child",
  title: "标准"
}, {
  Choice: '.ql-size .ql-picker-item[data-value="small"]',
  title: "小号"
}, {
  Choice: '.ql-size .ql-picker-item[data-value="large"]',
  title: "大号"
}, {
  Choice: '.ql-size .ql-picker-item[data-value="huge"]',
  title: "超大号"
}, {
  Choice: ".ql-size .ql-picker-item:nth-child(2)",
  title: "标准"
}, {
  Choice: ".ql-align .ql-picker-item:first-child",
  title: "居左对齐"
}, {
  Choice: '.ql-align .ql-picker-item[data-value="center"]',
  title: "居中对齐"
}, {
  Choice: '.ql-align .ql-picker-item[data-value="right"]',
  title: "居右对齐"
}, {
  Choice: '.ql-align .ql-picker-item[data-value="justify"]',
  title: "两端对齐"
}];
var _default = exports.default = {
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)({
      rules: {
        seq: [{
          required: true,
          message: "序号不能为空",
          trigger: "blur"
        }],
        title: [{
          required: true,
          message: "标题不能为空",
          trigger: "blur"
        }],
        author: [{
          required: true,
          message: "作者不能为空",
          trigger: "blur"
        }]
      },
      formLabelWidth: "200px",
      addblog: {
        seq: "",
        author: "",
        title: "",
        content: "",
        language: "ZH-CN",
        urlPc: "",
        urlMobile: "",
        seoTitle: '',
        seoKeyword: "",
        seoDescription: "",
        line: "2",
        viewCount: "",
        commentsCount: "",
        thumbsCount: "",
        recommendFlag: "N",
        awsContent: ''
      },
      imageUrl2: "",
      imageUrl1: "",
      addRange: [],
      Rangeflag: false,
      addRange1: [],
      Rangeflag1: false,
      value: ""
    }, "formLabelWidth", "150px"), "editorOption", {
      placeholder: "请输入文本信息..."
    });
  },
  methods: {
    // 点击图片ICON触发事件
    imgHandler: function imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (this.addRange != "" && this.addRange != null) {
        this.Rangeflag = true;
      }
      this.addRange1 = this.$refs.myQuillEditor1.quill.getSelection();
      if (this.addRange1 != "" && this.addRange1 != null) {
        this.Rangeflag1 = true;
      }
      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        if (_this.Rangeflag) {
          (0, _upload.uploads)(fd).then(function (res) {
            _this.addRange = _this.$refs.myQuillEditor.quill.getSelection();
            _this.$refs.myQuillEditor.quill.insertEmbed(_this.addRange !== null ? _this.addRange.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag = false;
          });
        }
        if (_this.Rangeflag1) {
          (0, _upload.awsupload)(fd).then(function (res) {
            _this.addRange1 = _this.$refs.myQuillEditor1.quill.getSelection();
            _this.$refs.myQuillEditor1.quill.insertEmbed(_this.addRange1 !== null ? _this.addRange1.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag1 = false;
          });
        }
      });
    },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this2 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this2.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this2.addblog.urlMobile = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this3 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this3.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this3.addblog.urlPc = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    back: function back(formName) {
      var _this4 = this;
      this.$refs[formName].resetFields();
      this.$store.dispatch("tagsView/delView", this.$route).then(function () {
        _this4.$nextTick(function () {
          _this4.$router.replace({
            path: "/blog/blogList"
          });
        });
      });
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    save: function save(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!/^[1-9]{1,}[\d]*$/.test(_this5.addblog.seq) && _this5.addblog.seq !== "") {
            _this5.msgTip("warning", "序号只能为正整数");
            return;
          }
          if (_this5.addblog.viewCount != "") {
            if (!/^[0-9]{1,}[\d]*$/.test(_this5.addblog.viewCount)) {
              _this5.msgTip("warning", "浏览数只能为正整数");
              return;
            }
          }
          if (_this5.addblog.commentsCount != "") {
            if (!/^[0-9]{1,}[\d]*$/.test(_this5.addblog.commentsCount)) {
              _this5.msgTip("warning", "评论数只能为正整数");
              return;
            }
          }
          if (_this5.addblog.thumbsCount != "") {
            if (!/^[0-9]{1,}[\d]*$/.test(_this5.addblog.thumbsCount)) {
              _this5.msgTip("warning", "点赞数只能为正整数");
              return;
            }
          }
          if (_this5.imageUrl1 == "") {
            _this5.msgTip("warning", "必须上传PC端图片");
            return;
          }
          if (_this5.imageUrl2 == "") {
            _this5.msgTip("warning", "必须上传手机端图片");
            return;
          }
          (0, _blog.addBlog)(_this5.addblog).then(function (res) {
            if (res.success) {
              _this5.msgTip("success", "操作成功");
              _this5.$refs[formName].resetFields();
              _this5.$store.dispatch("tagsView/delView", _this5.$route).then(function () {
                _this5.$nextTick(function () {
                  _this5.$router.replace({
                    path: "/blog/blogList",
                    query: {
                      line: _this5.$route.query.line,
                      titleId: _this5.$route.query.titleId,
                      recommendFlag: _this5.$route.query.recommendFlag,
                      pageNo: _this5.$route.query.pageNo
                    }
                  });
                });
              });
            } else {
              _this5.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
            }
          });
        }
      });
    }
  },
  mounted: function mounted() {
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor1.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    autotip: {
      document.getElementsByClassName("ql-editor")[0].dataset.placeholder = "";
      for (var _i = 0, _titleConfig = titleConfig; _i < _titleConfig.length; _i++) {
        var item = _titleConfig[_i];
        var tip = document.querySelector(".quill-editor " + item.Choice);
        if (!tip) continue;
        tip.setAttribute("title", item.title);
      }
    }
  } //使用upload组件上传图片
  // created() {
  //   this.editorOption = quillRedefine({
  //     uploadConfig: {
  //       action: this.uploadurl + "cms/picture/upload",
  //       res: respnse => {},
  //       name: "img",
  //       start: () => {}
  //     }
  //   });
  // }
};