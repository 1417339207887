"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.awsupload = awsupload;
exports.upload = upload;
exports.uploads = uploads;
var _request = _interopRequireDefault(require("@/utils/request"));
function uploads(data) {
  return (0, _request.default)({
    url: 'base/picture/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}
function upload(data) {
  return (0, _request.default)({
    url: 'cms/picture/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}

//上传境外字段
function awsupload(data) {
  return (0, _request.default)({
    url: 'base/picture/aws/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}