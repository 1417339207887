"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var echarts = _interopRequireWildcard(require("echarts"));
var _charts = require("../../api/charts.js");
//
//
//
//

require("echarts/theme/macarons"); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "97%"
    },
    height: {
      type: String,
      default: "500px"
    },
    volumeArr: {
      type: Object
      // default: []
    }
  },
  watch: {
    volumeArr: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(val, oldVal) {
        for (var i in val.listNameValueVO) {
          this.$set(val.listNameValueVO[i], "type", "line");
          // this.$set(val.listNameValueVO[i], "stack", "Total");
        }
        var name = [];
        val.listNameValueVO.map(function (item) {
          name.push(item.name);
        });
        this.chart.setOption({
          title: {
            text: ""
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: name
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },
          toolbox: {
            // feature: {
            //   saveAsImage: {}
            // }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: val.dayList
          },
          yAxis: {
            type: "value"
          },
          series: val.listNameValueVO
        });
      },
      deep: true //true 深度监听
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      for (var i in this.volumeArr.listNameValueVO) {
        this.$set(this.volumeArr.listNameValueVO[i], "type", "line");
        // this.$set(this.volumeArr.listNameValueVO[i], "stack", "Total");
      }
      var name = [];
      this.volumeArr.listNameValueVO.map(function (item) {
        name.push(item.name);
      });
      this.chart.setOption({
        title: {
          text: ""
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: name
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        toolbox: {
          // feature: {
          //   saveAsImage: {}
          // }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.volumeArr.dayList
        },
        yAxis: {
          type: "value"
        },
        series: this.volumeArr.listNameValueVO
      });
    }
  }
};