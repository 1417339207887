"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneadditional = aloneadditional;
exports.changeOn = changeOn;
exports.changeSeq = changeSeq;
exports.changeadditional = changeadditional;
exports.deleadditional = deleadditional;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询附加赠品接口（cms系统调用调用）
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/additional/item/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加附加赠品接口
function addList(data) {
  return (0, _request.default)({
    url: 'cms/additional/item',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//上线、下线附加赠品接口
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/additional/item/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个附加赠品
function aloneadditional(id) {
  return (0, _request.default)({
    url: 'cms/additional/item/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//修改附加赠品接口
function changeadditional(data, id) {
  return (0, _request.default)({
    url: 'cms/additional/item/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除附加赠品接口
function deleadditional(id) {
  return (0, _request.default)({
    url: 'cms/additional/item/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改序号
function changeSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/additional/item/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}