"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _orderSales = require("../../api/orderSales.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        equipmentNumber: "",
        orderNo: '',
        processingStatus: '',
        serialNumber: '',
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      processingStatu: [{
        value: '0',
        valueCn: '待核价'
      }, {
        value: '1',
        valueCn: '已核价'
      }, {
        value: '2',
        valueCn: '已发货'
      }, {
        value: '3',
        valueCn: '已撤销'
      }],
      types: [{
        value: '0',
        valueCn: '保内'
      }, {
        value: '1',
        valueCn: '保外'
      }],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      datas: [],
      loadings: false,
      load: false,
      sureLoading: false,
      dialogFormVisible: false,
      formLabelWidth: '100px',
      addrules: {
        badReason: [{
          required: true,
          trigger: "blur"
        }],
        count: [{
          required: true,
          trigger: "blur"
        }],
        dealer: [{
          required: true,
          trigger: "blur"
        }],
        //    equipmentNumber: [{ required: true, trigger: "blur" }],
        //    orderNo: [{ required: true, trigger: "blur" }],
        //    processingMethod: [{ required: true, trigger: "blur" }],
        productType: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "blur"
        }]
        //  repairPrice: [{ required: true, trigger: "blur" }],
      },
      addForm: {
        address: '',
        badReason: '',
        count: '',
        dealer: '',
        equipmentNumber: '',
        logisticsNumber: '',
        orderNo: '',
        processingMethod: '',
        productType: '',
        remark: '',
        telephone: '',
        type: '',
        userName: '',
        repairPrice: ''
      },
      isDisable: false,
      finsh: '',
      dealers: [],
      title: '',
      dialogFormVerifyVisible: false,
      verifyForm: {
        badReason: '',
        id: '',
        processingMethod: '',
        remark: '',
        repairPrice: '',
        type: ''
      },
      verifyrules: {
        repairPrice: [{
          required: true,
          trigger: "blur"
        }]
      },
      dialogFormRepairVisible: false,
      repairForm: {
        id: '',
        logisticsNumber: '',
        remark: '',
        processingMethod: ''
      },
      repairrules: {
        processingMethod: [{
          required: true,
          trigger: "blur"
        }]
      },
      dialogFormRevokeVisible: false,
      revokeForm: {
        id: '',
        remark: ''
      },
      revokerules: {},
      revokeTitle: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _orderSales.getLists)(_this.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.form.pageNo = 1;
            _this.currentPage = 1;
            (0, _orderSales.getLists)(_this.form).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //判断结算状态
    typeFlag: function typeFlag(v) {
      if (v.type == '0') {
        return '保内';
      } else if (v.type == '1') {
        return '保外';
      }
    },
    //判断处理状态
    status: function status(v) {
      if (v.processingStatus == '0') {
        return '待核价';
      } else if (v.processingStatus == '1') {
        return '已核价';
      } else if (v.processingStatus == '2') {
        return '已发货';
      } else if (v.processingStatus == '3') {
        return '已撤销';
      }
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.equipmentNumber = '';
      this.form.orderNo = "";
      this.form.processingStatus = "";
      this.form.serialNumber = "";
      this.form.type = "";
      this.getList();
    },
    //新增
    add: function add() {
      var _this2 = this;
      this.dialogFormVisible = true;
      this.title = "新增";
      (0, _dictionary.searchDic)({
        name: "DEALER",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this2.dealers = res.voList;
      });
    },
    //输入设备号
    changeEquipment: function changeEquipment() {
      var _this3 = this;
      if (this.addForm.equipmentNumber) {
        (0, _orderSales.orderEquipment)(this.addForm.equipmentNumber).then(function (res) {
          if (res.success) {
            _this3.addForm.address = res.voT.address;
            _this3.addForm.dealer = res.voT.dealer;
            _this3.addForm.orderNo = res.voT.orderNo;
            _this3.addForm.productType = res.voT.productType;
            _this3.addForm.telephone = res.voT.telephone;
            _this3.addForm.userName = res.voT.userName;
          } else {
            _this3.msgTip("error", res.errorMsg);
          }
        });
      } else {
        return false;
      }
    },
    addClose: function addClose() {
      this.dialogFormVisible = false;
      this.addForm.address = '';
      this.addForm.logisticsNumber = '';
      this.addForm.remark = '';
      this.addForm.telephone = '';
      this.addForm.userName = '';
      this.$refs.addForm.resetFields();
    },
    //修改
    change: function change(v) {
      var _this4 = this;
      this.title = "修改";
      this.dialogFormVisible = true;
      (0, _dictionary.searchDic)({
        name: "DEALER",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this4.dealers = res.voList;
      });
      (0, _orderSales.aloneList)(v.id).then(function (res) {
        if (res.success) {
          _this4.addForm = res.voT;
        }
      });
    },
    //确定按钮
    sure: function sure() {
      var _this5 = this;
      this.$refs.addForm.validate(function (valid) {
        if (valid) {
          _this5.sureLoading = true;
          if (_this5.title == '新增') {
            (0, _orderSales.addList)(_this5.addForm).then(function (res) {
              if (res.success) {
                _this5.msgTip("success", "操作成功");
                _this5.$refs.addForm.resetFields();
                _this5.dialogFormVisible = false;
                _this5.getList();
                _this5.sureLoading = false;
              } else {
                _this5.msgTip("error", res.errorMsg);
                _this5.sureLoading = false;
              }
            });
          } else if (_this5.title == '修改') {
            (0, _orderSales.changeList)(_this5.addForm).then(function (res) {
              if (res.success) {
                _this5.msgTip("success", "操作成功");
                _this5.$refs.addForm.resetFields();
                _this5.dialogFormVisible = false;
                _this5.getList();
                _this5.sureLoading = false;
              } else {
                _this5.msgTip("error", res.errorMsg);
                _this5.sureLoading = false;
              }
            });
          }
        }
      });
    },
    //验价
    verifyPrices: function verifyPrices(v) {
      this.dialogFormVerifyVisible = true;
      this.verifyForm.id = v.id;
      this.verifyForm.type = v.type;
      this.verifyForm.remark = v.remark;
      this.verifyForm.repairPrice = v.repairPrice;
      this.verifyForm.badReason = v.badReason;
      this.verifyForm.processingMethod = v.processingMethod;
    },
    //验价确定
    verifySure: function verifySure() {
      var _this6 = this;
      this.$refs.verifyForm.validate(function (valid) {
        if (valid) {
          _this6.sureLoading = true;
          (0, _orderSales.verifyPrice)(_this6.verifyForm).then(function (res) {
            if (res.success) {
              _this6.msgTip("success", "操作成功");
              _this6.$refs.verifyForm.resetFields();
              _this6.dialogFormVerifyVisible = false;
              _this6.getList();
              _this6.sureLoading = false;
            } else {
              _this6.msgTip("error", res.errorMsg);
              _this6.sureLoading = false;
            }
          });
        }
      });
    },
    verifyClose: function verifyClose() {
      this.dialogFormVerifyVisible = false;
      this.$refs.verifyForm.resetFields();
    },
    //发货
    repairShips: function repairShips(v) {
      this.repairForm.id = v.id;
      this.repairForm.remark = v.remark;
      this.repairForm.processingMethod = v.processingMethod;
      this.dialogFormRepairVisible = true;
    },
    //发货关闭
    repairClose: function repairClose() {
      this.dialogFormRepairVisible = false;
      this.repairForm.logisticsNumber = '';
      this.repairForm.remark = '';
    },
    //发货确定
    repairSure: function repairSure() {
      var _this7 = this;
      this.$refs.repairForm.validate(function (valid) {
        if (valid) {
          (0, _orderSales.repairShip)(_this7.repairForm).then(function (res) {
            if (res.success) {
              _this7.msgTip("success", "操作成功");
              _this7.repairForm.logisticsNumber = '';
              _this7.repairForm.remark = '';
              _this7.repairForm.processingMethod = '';
              _this7.dialogFormRepairVisible = false;
              _this7.getList();
              _this7.sureLoading = false;
            } else {
              _this7.msgTip("error", res.errorMsg);
              _this7.sureLoading = false;
            }
          });
        }
      });
    },
    //撤销
    revoke: function revoke(v) {
      this.dialogFormRevokeVisible = true;
      this.revokeForm.id = v.id;
      this.revokeTitle = '撤销';
      this.revokeForm.remark = v.remark;
    },
    //撤销关闭
    revokeClose: function revokeClose() {
      this.dialogFormRevokeVisible = false;
      this.revokeForm.remark = '';
    },
    //撤销确定
    revokeSure: function revokeSure() {
      var _this8 = this;
      if (this.revokeTitle == '撤销') {
        (0, _orderSales.repairRevoke)(this.revokeForm).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "操作成功");
            _this8.revokeForm.remark = '';
            _this8.dialogFormRevokeVisible = false;
            _this8.getList();
            _this8.sureLoading = false;
          } else {
            _this8.msgTip("error", res.errorMsg);
            _this8.sureLoading = false;
          }
        });
      } else if (this.revokeTitle == '解除撤销') {
        (0, _orderSales.repairUnRevoke)(this.revokeForm).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "操作成功");
            _this8.revokeForm.remark = '';
            _this8.dialogFormRevokeVisible = false;
            _this8.getList();
            _this8.sureLoading = false;
          } else {
            _this8.msgTip("error", res.errorMsg);
            _this8.sureLoading = false;
          }
        });
      }
    },
    //解除撤销
    unrevoke: function unrevoke(v) {
      this.dialogFormRevokeVisible = true;
      this.revokeForm.id = v.id;
      this.revokeForm.remark = v.remark;
      this.revokeTitle = '解除撤销';
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};