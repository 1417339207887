"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _dictionary = require("../../api/dictionary.js");
var _trends = require("../../api/trends.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      value: "",
      form: {
        id: "",
        serialNumber: "",
        type: "",
        priceRangeCn: "",
        priceRangeEn: "",
        priceRangeJa: ""
      },
      formLabelWidth: "120px",
      formRules: {
        priceRangeCn: [{
          required: true,
          trigger: "blur"
        }],
        priceRangeEn: [{
          required: true,
          trigger: "blur"
        }],
        priceRangeJa: [{
          required: true,
          trigger: "blur"
        }],
        serialNumber: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "blur"
        }]
      },
      isDisable: false,
      serial: [],
      type: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.loadAll();
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
  },
  methods: {
    loadAll: function loadAll() {
      var _this2 = this;
      (0, _dictionary.searchDiclist)({
        name: "",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this2.newList = b["PART_SERIES"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value,
              valueCn: v.value + "_" + v.valueCn
            });
          });
          _this2.serial = _this2.newList;
          _this2.proList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value,
              valueCn: v.value + "_" + v.valueCn
            });
          });
          _this2.product = _this2.proList;
        }
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //提交
    submit: function submit() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.isDisable = true;
          (0, _trends.addtrends)(_this3.form).then(function (res) {
            if (res.success) {
              _this3.msgTip("success", "添加成功");
              _this3.$store.dispatch("tagsView/delView", _this3.$route).then(function () {
                _this3.$nextTick(function () {
                  _this3.$router.replace({
                    path: "/trends",
                    query: {
                      cur: _this3.$route.query.cur,
                      line: _this3.$route.query.line
                    }
                  });
                });
              });
            } else {
              _this3.msgTip("error", res.errorMsg);
              _this3.isDisable = false;
            }
          });
        }
      });
    }
  }
};