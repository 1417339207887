"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _dealerDetail = require("../../api/dealerDetail.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        position: "",
        locationPosition: "",
        productNo: "",
        dealerSupplier: '',
        pageNo: 1,
        pageSize: 10,
        type: '',
        id: ''
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      dealers: [],
      locationPositions: [],
      multipleSelection: [],
      id: '',
      current: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDic)({
      name: "STOCK_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.dealers = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "LOCATION_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.locationPositions = res.voList;
    });
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _dealerDetail.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _dealerDetail.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      this.id = val[0].id;
    },
    handleClick: function handleClick(val) {
      this.current = val;
      // this.id = val[0].id;
      this.id = val.id;
    },
    //查询
    search: function search() {
      this.getList();
    },
    //导出
    derive: function derive() {
      var _this3 = this;
      this.loading = true;
      (0, _dealerDetail.stockDetailExport)(this.form).then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "stock-detail.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
        _this3.loading = false;
      });
    },
    //清空
    empty: function empty() {
      this.form.position = "";
      this.form.locationPosition = "";
      this.form.productNo = "";
      this.form.id = "";
      this.form.dealerSupplier = "";
      this.form.type = "";
      this.getList();
    },
    //撤销
    repeal: function repeal() {
      var _this4 = this;
      if (this.current == '' || this.current == null) {
        this.msgTip("warning", "请选择一条数据");
      } else {
        this.$confirm("您是否要撤销当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          (0, _dealerDetail.getUndo)(_this4.id).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "操作成功");
              _this4.getList();
            } else {
              _this4.msgTip("warning", res.errorMsg);
            }
          });
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};