"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addapp = addapp;
exports.changeList = changeList;
exports.changeOn = changeOn;
exports.deleList = deleList;
exports.getLists = getLists;
exports.searchList = searchList;
var _request = _interopRequireDefault(require("@/utils/request"));
//展示app列表
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/app/list',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//新增app
function addapp(data) {
  return (0, _request.default)({
    url: 'cms/app',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//修改查询
function searchList(id) {
  return (0, _request.default)({
    url: 'cms/app/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//删除app
function deleList(id) {
  return (0, _request.default)({
    url: 'cms/app/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改app
function changeList(id, data) {
  return (0, _request.default)({
    url: 'cms/app/' + id,
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//上线
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/app/line/' + id,
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}