var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "summarize" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ol", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("上线/下线")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.line,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "line", $$v)
                    },
                    expression: "form.line",
                  },
                },
                _vm._l(_vm.options, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.type },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("产品型号")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.type, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.search },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search($event)
                    },
                  },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/video/add",
                  expression: "'/video/add'",
                },
              ],
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.add } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "产品型号", prop: "type" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "上线/下线",
              formatter: _vm.state,
              prop: "line",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "视频类型CN",
              prop: "videoTypeCn",
              formatter: _vm.videoCn,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "视频链接或视频CN",
              "show-overflow-tooltip": "",
              prop: "videoCn",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "视频类型EN",
              prop: "videoTypeEn",
              formatter: _vm.videoEn,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "视频链接或视频EN",
              "show-overflow-tooltip": "",
              prop: "videoEn",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "视频类型JA",
              prop: "videoTypeJa",
              formatter: _vm.videoJa,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "视频链接或视频JA",
              "show-overflow-tooltip": "",
              prop: "videoJa",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作人", prop: "updateUser" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作时间", prop: "updateTime" },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/video/update",
                                expression: "'/video/update'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/amend.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.change(scope.row.id)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/video/delete",
                                expression: "'/video/delete'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/delete.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.dele(scope.row.id)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/video/online",
                                expression: "'/video/online'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "上线",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/online.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.online(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/video/unline",
                                expression: "'/video/unline'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "下线",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/offline.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.offline(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/video/preview",
                                expression: "'/video/preview'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "预览",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/view.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.preview(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: _vm.titletype, visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.addClose("addform")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.rules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品型号",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addform.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "type", $$v)
                        },
                        expression: "addform.type",
                      },
                    },
                    _vm._l(_vm.type, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "视频类型CN",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.addform.videoTypeCn,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "videoTypeCn", $$v)
                        },
                        expression: "addform.videoTypeCn",
                      },
                    },
                    [_vm._v("视频")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.addform.videoTypeCn,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "videoTypeCn", $$v)
                        },
                        expression: "addform.videoTypeCn",
                      },
                    },
                    [_vm._v("链接")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.addform.videoTypeCn == "0"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "视频CN",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "upload-wrapper" },
                        [
                          _c("input", {
                            ref: "uploadFileCn",
                            staticClass: "upload-file-cn",
                            attrs: {
                              id: "fileCn",
                              type: "file",
                              name: "fileCn",
                              multiple: "",
                            },
                            on: { change: _vm.submitUploadCn },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingCn,
                                  expression: "loadingCn",
                                },
                              ],
                              staticClass: "exprot-btn",
                              attrs: { size: "small", type: "primary" },
                            },
                            [_vm._v("上传")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.videoflagsCn
                        ? _c("video", {
                            staticClass: "video",
                            attrs: {
                              width: "300px",
                              src: _vm.videoSrcCn,
                              controls: "controls",
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.addform.videoTypeCn == "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "链接CN",
                        "label-width": _vm.formLabelWidth,
                        prop: "videoCn",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          autocomplete: "off",
                          type: "text",
                        },
                        model: {
                          value: _vm.addform.videoCn,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "videoCn", $$v)
                          },
                          expression: "addform.videoCn",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "视频类型EN",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.addform.videoTypeEn,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "videoTypeEn", $$v)
                        },
                        expression: "addform.videoTypeEn",
                      },
                    },
                    [_vm._v("视频")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.addform.videoTypeEn,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "videoTypeEn", $$v)
                        },
                        expression: "addform.videoTypeEn",
                      },
                    },
                    [_vm._v("链接")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.addform.videoTypeEn == "0"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "视频EN",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "upload-wrapper" },
                        [
                          _c("input", {
                            ref: "uploadFileEn",
                            staticClass: "upload-file-en",
                            attrs: {
                              id: "fileEn",
                              type: "file",
                              name: "fileEn",
                              multiple: "",
                            },
                            on: { change: _vm.submitUploadEn },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingEn,
                                  expression: "loadingEn",
                                },
                              ],
                              staticClass: "exprot-btn",
                              attrs: { size: "small", type: "primary" },
                            },
                            [_vm._v("上传")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.videoflagsEn
                        ? _c("video", {
                            staticClass: "video",
                            attrs: {
                              width: "300px",
                              src: _vm.videoSrcEn,
                              controls: "controls",
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.addform.videoTypeEn == "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "链接EN",
                        "label-width": _vm.formLabelWidth,
                        prop: "videoEn",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          autocomplete: "off",
                          type: "text",
                        },
                        model: {
                          value: _vm.addform.videoEn,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "videoEn", $$v)
                          },
                          expression: "addform.videoEn",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "视频类型JA",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.addform.videoTypeJa,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "videoTypeJa", $$v)
                        },
                        expression: "addform.videoTypeJa",
                      },
                    },
                    [_vm._v("视频")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.addform.videoTypeJa,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "videoTypeJa", $$v)
                        },
                        expression: "addform.videoTypeJa",
                      },
                    },
                    [_vm._v("链接")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.addform.videoTypeJa == "0"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "视频JA",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "upload-wrapper" },
                        [
                          _c("input", {
                            ref: "uploadFileJa",
                            staticClass: "upload-file-ja",
                            attrs: {
                              id: "fileJa",
                              type: "file",
                              name: "fileJa",
                              multiple: "",
                            },
                            on: { change: _vm.submitUploadJa },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loadingJa,
                                  expression: "loadingJa",
                                },
                              ],
                              staticClass: "exprot-btn",
                              attrs: { size: "small", type: "primary" },
                            },
                            [_vm._v("上传")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.videoflagsJa
                        ? _c("video", {
                            staticClass: "video",
                            attrs: {
                              width: "300px",
                              src: _vm.videoSrcJa,
                              controls: "controls",
                            },
                          })
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.addform.videoTypeJa == "1"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "链接JA",
                        "label-width": _vm.formLabelWidth,
                        prop: "videoCn",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          autocomplete: "off",
                          type: "text",
                        },
                        model: {
                          value: _vm.addform.videoJa,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "videoJa", $$v)
                          },
                          expression: "addform.videoJa",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addClose("addform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.sure("addform")
                    },
                  },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }