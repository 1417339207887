"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _comment = require("../../api/comment.js");
var _blog = require("../../api/blog.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      forms: {
        audit: ""
      },
      isDisable: false,
      auditId: "",
      dialogFormVisible: false,
      isFlag: true,
      currentPage: 1,
      total: 0,
      loading: false,
      seqStatus: {
        seq: "1",
        blogId: ""
      },
      lineStatus: {
        line: ""
      },
      recommendFlags: [{
        recommendFlag: "",
        label: "所有"
      }, {
        recommendFlag: "0",
        label: "置顶"
      }, {
        recommendFlag: "1",
        label: "不置顶"
      }],
      audits: [{
        audit: "",
        label: "所有"
      }, {
        audit: "0",
        label: "待审核"
      }, {
        audit: "1",
        label: "审核通过"
      }, {
        audit: "2",
        label: "审核不通过"
      }],
      auditflags: [{
        auditflag: "1",
        label: "审核通过"
      }, {
        auditflag: "2",
        label: "审核不通过"
      }],
      value: "",
      titleCns: [],
      form: {
        language: "",
        roofPlacement: "",
        audit: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        titleId: ""
      }
    }, "forms", {
      language: "",
      roofPlacement: "",
      audit: "",
      pageNo: 1,
      pageSize: 10,
      sortField: "",
      sortRules: "",
      titleId: ""
    }), "forms1", {
      language: "",
      line: "",
      titleCn: "",
      recommendFlag: "",
      pageNo: 0,
      pageSize: 0,
      sortField: "",
      sortRules: ""
    }), "value1", true), "tableData", [{
      name: "123",
      remark: "123"
    }]);
  },
  created: function created() {},
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    //新增关闭按钮
    addClose: function addClose() {
      this.dialogFormVisible = false;
    },
    sure: function sure() {
      var _this = this;
      if (this.forms.audit == null || this.forms.audit == "") {
        this.msgTip("warning", "审核标识不能为空");
        return;
      }
      this.isDisable = true;
      (0, _comment.updateAudit)(this.forms, this.auditId).then(function (res) {
        if (res.success) {
          _this.msgTip("success", "操作成功");
          // 前端刷新
          (0, _comment.dataManipulation)(_this.tableData, _this.auditId, "", _this.forms.audit, "", getDate(), window.localStorage.getItem("username"));
          _this.dialogFormVisible = false;
          _this.isFlag = true;
        } else {
          _this.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
          _this.dialogFormVisible = false;
          _this.isDisable = false;
        }
      });
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _comment.queryComment)(_this2.forms).then(function (res) {
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
        (0, _blog.queryBlog)(_this2.forms1).then(function (res) {
          _this2.titleCns = res.voList;
        });
      }, 500);
    },
    //查询
    search: function search() {
      var _this3 = this;
      this.loading = true;
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.titleId = this.form.titleId;
      this.forms.audit = this.form.audit;
      this.forms.recommendFlag = this.form.recommendFlag;
      this.forms.line = this.form.line;
      setTimeout(function () {
        (0, _comment.queryComment)({
          line: _this3.form.line,
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          language: "",
          sortRules: "",
          roofPlacement: _this3.form.roofPlacement,
          audit: _this3.form.audit,
          titleId: _this3.form.titleId
        }).then(function (res) {
          _this3.form.pageNo = 1;
          _this3.tableData = res.voList;
          _this3.total = res.total;
        });
        _this3.currentPage = 1;
        _this3.loading = false;
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    empty: function empty() {
      this.form.line = "";
      this.form.audit = "";
      this.form.roofPlacement = "";
      this.form.titleId = "";
      this.forms.line = "";
      this.forms.audit = "";
      this.forms.roofPlacement = "";
      this.forms.titleId = "";
      this.forms.pageNo = 1;
      this.getList();
    },
    roofPlacementFormatter: function roofPlacementFormatter(row, column) {
      if (row.roofPlacement == "0") {
        return "置顶";
      } else if (row.roofPlacement == "1") {
        return "不置顶";
      }
    },
    auditFormatter: function auditFormatter(row, column) {
      if (row.audit == "0") {
        return "待审核";
      } else if (row.audit == "1") {
        return "审核通过";
      } else if (row.audit == "2") {
        return "审核不通过";
      }
    },
    //审核
    remark: function remark(v) {
      this.auditId = v.id;
      this.isDisable = false;
      this.dialogFormVisible = true;
    },
    //置顶
    stick: function stick(v) {
      var _this4 = this;
      if (v.audit == 2) {
        this.msgTip("warning", "审核未通过不能置顶");
        return;
      }
      if (v.audit == 0) {
        this.msgTip("warning", "未审核不能置顶");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.seqStatus.blogId = v.blogId;
        (0, _comment.updateSeq)(this.seqStatus, v.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "置顶成功");
            _this4.empty();
            _this4.isFlag = true;
          } else {
            _this4.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this4.isFlag = true;
          }
        });
      }
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}