"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneproduct = aloneproduct;
exports.changeBanner = changeBanner;
exports.changeOn = changeOn;
exports.deleDescription = deleDescription;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询产品页详情接口(cms系统调用)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/product/overview/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加产品页详情接口
function addList(data) {
  return (0, _request.default)({
    url: 'cms/product/overview',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//上线、下线产品页详情接口
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/product/overview/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改产品页详情图接口
function changeBanner(id, data) {
  return (0, _request.default)({
    url: 'cms/product/overview/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个产品概述接口
function aloneproduct(id) {
  return (0, _request.default)({
    url: 'cms/product/overview/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除产品页详情接口
function deleDescription(id) {
  return (0, _request.default)({
    url: 'cms/product/overview/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}