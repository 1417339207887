"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
var _charts = require("../../api/charts.js");
//
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '50%'
    },
    height: {
      type: String,
      default: '700px'
    },
    globalArr: {
      type: Array
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    globalArr: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(val, oldVal) {
        var _this = this;
        var productList = [];
        var countList = [];
        val.map(function (item) {
          productList.push(item.countryCode);
          countList.push(item.counts);
          _this.chart.setOption({
            title: {
              text: '用户活跃分析图表',
              textStyle: {
                color: '#3d678f'
              },
              left: '20px'
            },
            xAxis: [{
              type: 'category',
              data: productList,
              axisLabel: {
                interval: 0,
                rotate: 30
              }
            }],
            yAxis: [{
              type: 'value'
            }],
            series: [{
              //name: 'pageA',
              type: 'bar',
              //   stack: 'vistors',
              data: countList,
              barWidth: '70%',
              animationDuration: animationDuration,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    //开启显示
                    position: 'top' //在上方显示
                  },
                  color: '#3d678f'
                }
              }
            }]
          });
        });
      },
      deep: true //true 深度监听
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this3 = this;
      this.chart = echarts.init(this.$el);
      var productList = [];
      var countList = [];
      this.globalArr.map(function (item) {
        productList.push(item.countryCode);
        countList.push(item.counts);
        _this3.chart.setOption({
          title: {
            text: '用户活跃分析图表',
            textStyle: {
              color: '#3d678f'
            },
            left: '20px'
          },
          xAxis: [{
            type: 'category',
            data: productList,
            axisLabel: {
              interval: 0,
              rotate: 30
            }
          }],
          yAxis: [{
            type: 'value'
          }],
          series: [{
            //name: 'pageA',
            type: 'bar',
            //   stack: 'vistors',
            data: countList,
            barWidth: '70%',
            animationDuration: animationDuration,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  //开启显示
                  position: 'top' //在上方显示
                },
                color: '#3d678f'
              }
            }
          }]
        });
      });
    }
  }
};