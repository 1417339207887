"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueQuillEditor = require("vue-quill-editor");
require("quill/dist/quill.snow.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//调用编辑器
var _default = exports.default = {
  name: 'common',
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  data: function data() {
    return {
      value: "",
      editorOption: {}
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onEditorBlur: function onEditorBlur() {},
    onEditorFocus: function onEditorFocus() {},
    onEditorChange: function onEditorChange() {}
  }
};