"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneTit = aloneTit;
exports.changeList = changeList;
exports.changeOn = changeOn;
exports.changeSeq = changeSeq;
exports.dataManipulation = dataManipulation;
exports.deleTit = deleTit;
exports.faqType = faqType;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询FAQ标题信息
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/faq/title/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增FAQ标题信息
function addList(data) {
  return (0, _request.default)({
    url: 'cms/faq/title',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改FAQ标题信息
function changeList(data, id) {
  return (0, _request.default)({
    url: 'cms/faq/title/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改FAQ标题上线下线
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/faq/title/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//修改固件说明书序号
function changeSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/faq/title/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//通过类型查询FAQ列表信息
function faqType(type) {
  return (0, _request.default)({
    url: 'cms/faq/title/list/' + type,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//查询单个FAQ标题信息
function aloneTit(id) {
  return (0, _request.default)({
    url: 'cms/faq/title/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除FAQ标题信息
function deleTit(id) {
  return (0, _request.default)({
    url: 'cms/faq/title/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, type, line, updateTime, updateUser) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (updateUser != null && updateUser != "") {
        element.updateUser = updateUser;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
      if (line != null && line != "") {
        element.line = line;
      }
      if (type != null && type != "") {
        element.type = type;
      }
    }
  });
  return tableData;
}