var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addpro" }, [
    _c(
      "div",
      { staticClass: "addhead" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              "status-icon": true,
              "show-message": false,
              "hide-required-asterisk": "",
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "产品型号",
                  "label-width": _vm.formLabelWidth,
                  prop: "type",
                },
              },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.form.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "type", $$v)
                      },
                      expression: "form.type",
                    },
                  },
                  _vm._l(_vm.type, function (item, index) {
                    return _c("el-option", {
                      key: index,
                      attrs: { label: item.valueCn, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "标题CN",
                  "label-width": _vm.formLabelWidth,
                  prop: "titleCn",
                },
              },
              [
                _c("el-input", {
                  attrs: { maxlength: "50", autocomplete: "off", type: "text" },
                  model: {
                    value: _vm.form.titleCn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "titleCn", $$v)
                    },
                    expression: "form.titleCn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "标题EN",
                  "label-width": _vm.formLabelWidth,
                  prop: "titleEn",
                },
              },
              [
                _c("el-input", {
                  attrs: { maxlength: "50", autocomplete: "off", type: "text" },
                  model: {
                    value: _vm.form.titleEn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "titleEn", $$v)
                    },
                    expression: "form.titleEn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "标题JA",
                  "label-width": _vm.formLabelWidth,
                  prop: "titleJa",
                },
              },
              [
                _c("el-input", {
                  attrs: { maxlength: "50", autocomplete: "off", type: "text" },
                  model: {
                    value: _vm.form.titleJa,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "titleJa", $$v)
                    },
                    expression: "form.titleJa",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "描述CN",
                  "label-width": _vm.formLabelWidth,
                  prop: "descriptionCn",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "255",
                    autocomplete: "off",
                    type: "text",
                  },
                  model: {
                    value: _vm.form.descriptionCn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "descriptionCn", $$v)
                    },
                    expression: "form.descriptionCn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "描述EN",
                  "label-width": _vm.formLabelWidth,
                  prop: "descriptionEn",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "255",
                    autocomplete: "off",
                    type: "text",
                  },
                  model: {
                    value: _vm.form.descriptionEn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "descriptionEn", $$v)
                    },
                    expression: "form.descriptionEn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "描述JA",
                  "label-width": _vm.formLabelWidth,
                  prop: "descriptionJa",
                },
              },
              [
                _c("el-input", {
                  attrs: {
                    maxlength: "255",
                    autocomplete: "off",
                    type: "text",
                  },
                  model: {
                    value: _vm.form.descriptionJa,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "descriptionJa", $$v)
                    },
                    expression: "form.descriptionJa",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "PC内容CN",
                  "label-width": _vm.formLabelWidth,
                  prop: "contentPcCn",
                },
              },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: ".png, .gif, .jpg, .jpeg",
                      action: _vm.uploadUrl,
                      headers: _vm.uplaod_data,
                      "file-list": _vm.fileList,
                      "list-type": "picture-card",
                      "on-success": _vm.handleUploadSuccess,
                      "on-remove": _vm.handleRemoves,
                      "on-preview": _vm.changePicture,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisible },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisible = $event
                      },
                      close: _vm.close,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "300px",
                        src: _vm.dialogImageUrl,
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              headers: _vm.uplaod_data,
                              "on-success": _vm.uploadSuccess,
                              "show-file-list": false,
                              "on-remove": _vm.handleRemove,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "PC内容EN",
                  "label-width": _vm.formLabelWidth,
                  prop: "contentPcEN",
                },
              },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: ".png, .gif, .jpg, .jpeg",
                      action: _vm.uploadUrl,
                      headers: _vm.uplaod_data,
                      "file-list": _vm.fileListEn,
                      "list-type": "picture-card",
                      "on-success": _vm.handleUploadSuccessEn,
                      "on-remove": _vm.handleRemovesEn,
                      "on-preview": _vm.changePictureEn,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisibleEn },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisibleEn = $event
                      },
                      close: _vm.closeEn,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "300px",
                        src: _vm.dialogImageUrlEn,
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              headers: _vm.uplaod_data,
                              "on-success": _vm.uploadSuccessEn,
                              "show-file-list": false,
                              "on-remove": _vm.handleRemoveEn,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "PC内容JA",
                  "label-width": _vm.formLabelWidth,
                  prop: "contentPcJa",
                },
              },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: ".png, .gif, .jpg, .jpeg",
                      action: _vm.uploadUrl,
                      headers: _vm.uplaod_data,
                      "file-list": _vm.fileListJa,
                      "list-type": "picture-card",
                      "on-success": _vm.handleUploadSuccessJa,
                      "on-remove": _vm.handleRemovesJa,
                      "on-preview": _vm.changePictureJa,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisibleJa },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisibleJa = $event
                      },
                      close: _vm.closeJa,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "300px",
                        src: _vm.dialogImageUrlJa,
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              headers: _vm.uplaod_data,
                              "on-success": _vm.uploadSuccessJa,
                              "show-file-list": false,
                              "on-remove": _vm.handleRemoveJa,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "移动端中文内容",
                  "label-width": _vm.formLabelWidth,
                  prop: "contentMobileCn",
                },
              },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: ".png, .gif, .jpg, .jpeg",
                      action: _vm.uploadUrl,
                      headers: _vm.uplaod_data,
                      "file-list": _vm.fileListMcn,
                      "list-type": "picture-card",
                      "on-success": _vm.handleUploadSuccessMcn,
                      "on-remove": _vm.handleRemovesMcn,
                      "on-preview": _vm.changePictureMcn,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisibleMcn },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisibleMcn = $event
                      },
                      close: _vm.closeMcn,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "300px",
                        src: _vm.dialogImageUrlMcn,
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              headers: _vm.uplaod_data,
                              "on-success": _vm.uploadSuccessMcn,
                              "show-file-list": false,
                              "on-remove": _vm.handleRemoveMcn,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "移动端英文内容",
                  "label-width": _vm.formLabelWidth,
                  prop: "contentMobileEn",
                },
              },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: ".png, .gif, .jpg, .jpeg",
                      action: _vm.uploadUrl,
                      headers: _vm.uplaod_data,
                      "file-list": _vm.fileListMen,
                      "list-type": "picture-card",
                      "on-success": _vm.handleUploadSuccessMen,
                      "on-remove": _vm.handleRemovesMen,
                      "on-preview": _vm.changePictureMen,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisibleMen },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisibleMen = $event
                      },
                      close: _vm.closeMen,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "300px",
                        src: _vm.dialogImageUrlMen,
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              headers: _vm.uplaod_data,
                              "on-success": _vm.uploadSuccessMen,
                              "show-file-list": false,
                              "on-remove": _vm.handleRemoveMen,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "移动端日文内容",
                  "label-width": _vm.formLabelWidth,
                  prop: "contentMobileJa",
                },
              },
              [
                _c(
                  "el-upload",
                  {
                    attrs: {
                      accept: ".png, .gif, .jpg, .jpeg",
                      action: _vm.uploadUrl,
                      headers: _vm.uplaod_data,
                      "file-list": _vm.fileListMja,
                      "list-type": "picture-card",
                      "on-success": _vm.handleUploadSuccessMja,
                      "on-remove": _vm.handleRemovesMja,
                      "on-preview": _vm.changePictureMja,
                    },
                  },
                  [_c("i", { staticClass: "el-icon-plus" })]
                ),
                _vm._v(" "),
                _c(
                  "el-dialog",
                  {
                    attrs: { visible: _vm.dialogVisibleMja },
                    on: {
                      "update:visible": function ($event) {
                        _vm.dialogVisibleMja = $event
                      },
                      close: _vm.closeMja,
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        width: "300px",
                        src: _vm.dialogImageUrlMja,
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "dialog-footer",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                        attrs: { slot: "footer" },
                        slot: "footer",
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "upload-demo",
                            attrs: {
                              action: _vm.uploadUrl,
                              headers: _vm.uplaod_data,
                              "on-success": _vm.uploadSuccessMja,
                              "show-file-list": false,
                              "on-remove": _vm.handleRemoveMja,
                            },
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { "margin-left": "20px" },
                                attrs: { size: "small", type: "primary" },
                              },
                              [_vm._v("点击上传")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: _vm.isDisable },
            on: { click: _vm.submit },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }