"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _vueQuillEditor = require("vue-quill-editor");
var Quill = _interopRequireWildcard(require("quill"));
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _abouts = require("../../api/abouts.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 标题
var titleConfig = [{
  Choice: '.ql-bold',
  title: '加粗'
}, {
  Choice: '.ql-italic',
  title: '斜体'
}, {
  Choice: '.ql-underline',
  title: '下划线'
}, {
  Choice: '.ql-header',
  title: '段落格式'
}, {
  Choice: '.ql-strike',
  title: '删除线'
}, {
  Choice: '.ql-blockquote',
  title: '块引用'
}, {
  Choice: '.ql-code',
  title: '插入代码'
}, {
  Choice: '.ql-code-block',
  title: '插入代码段'
}, {
  Choice: '.ql-font',
  title: '字体'
}, {
  Choice: '.ql-size',
  title: '字体大小'
}, {
  Choice: '.ql-list[value="ordered"]',
  title: '编号列表'
}, {
  Choice: '.ql-list[value="bullet"]',
  title: '项目列表'
}, {
  Choice: '.ql-direction',
  title: '文本方向'
}, {
  Choice: '.ql-header[value="1"]',
  title: 'h1'
}, {
  Choice: '.ql-header[value="2"]',
  title: 'h2'
}, {
  Choice: '.ql-align',
  title: '对齐方式'
}, {
  Choice: '.ql-color',
  title: '字体颜色'
}, {
  Choice: '.ql-background',
  title: '背景颜色'
}, {
  Choice: '.ql-image',
  title: '图像'
}, {
  Choice: '.ql-video',
  title: '视频'
}, {
  Choice: '.ql-link',
  title: '添加链接'
}, {
  Choice: '.ql-formula',
  title: '插入公式'
}, {
  Choice: '.ql-clean',
  title: '清除字体格式'
}, {
  Choice: '.ql-script[value="sub"]',
  title: '下标'
}, {
  Choice: '.ql-script[value="super"]',
  title: '上标'
}, {
  Choice: '.ql-indent[value="-1"]',
  title: '向左缩进'
}, {
  Choice: '.ql-indent[value="+1"]',
  title: '向右缩进'
}, {
  Choice: '.ql-header .ql-picker-label',
  title: '标题大小'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="1"]',
  title: '标题一'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="2"]',
  title: '标题二'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="3"]',
  title: '标题三'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="4"]',
  title: '标题四'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="5"]',
  title: '标题五'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="6"]',
  title: '标题六'
}, {
  Choice: '.ql-header .ql-picker-item:last-child',
  title: '标准'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="small"]',
  title: '小号'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="large"]',
  title: '大号'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="huge"]',
  title: '超大号'
}, {
  Choice: '.ql-size .ql-picker-item:nth-child(2)',
  title: '标准'
}, {
  Choice: '.ql-align .ql-picker-item:first-child',
  title: '居左对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="center"]',
  title: '居中对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="right"]',
  title: '居右对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="justify"]',
  title: '两端对齐'
}];
var _default = exports.default = {
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  data: function data() {
    return {
      value: "",
      form: {
        descriptionMobileCn: "",
        descriptionMobileEn: "",
        descriptionMobileJa: "",
        descriptionPcCn: "",
        descriptionPcEn: "",
        descriptionPcJa: "",
        seq: "",
        titleCn: "",
        titleEn: "",
        titleJa: ""
      },
      formLabelWidth: "120px",
      formRules: {
        seq: [{
          required: true,
          trigger: "blur"
        }],
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        descriptionMobileCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionMobileEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionPcCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionPcEn: [{
          required: true,
          trigger: "blur"
        }]
      },
      isDisable: false,
      editorOption: {
        placeholder: "请输入文本信息..."
      },
      addRange1: [],
      addRange2: [],
      addRange3: [],
      addRange4: [],
      addRange5: [],
      addRange6: [],
      Rangeflag1: false,
      Rangeflag2: false,
      Rangeflag3: false,
      Rangeflag4: false,
      Rangeflag5: false,
      Rangeflag6: false
    };
  },
  mounted: function mounted() {
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor1.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor2.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor3.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor4.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor5.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor6.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    autotip: {
      document.getElementsByClassName('ql-editor')[0].dataset.placeholder = '';
      for (var _i = 0, _titleConfig = titleConfig; _i < _titleConfig.length; _i++) {
        var item = _titleConfig[_i];
        var tip = document.querySelector('.quill-editor ' + item.Choice);
        if (!tip) continue;
        tip.setAttribute('title', item.title);
      }
    }
  },
  methods: {
    // 点击图片ICON触发事件
    imgHandler: function imgHandler(state) {
      this.addRange1 = this.$refs.myQuillEditor1.quill.getSelection();
      if (this.addRange1 != "" && this.addRange1 != null) {
        this.Rangeflag1 = true;
      }
      this.addRange2 = this.$refs.myQuillEditor2.quill.getSelection();
      if (this.addRange2 != "" && this.addRange2 != null) {
        this.Rangeflag2 = true;
      }
      this.addRange3 = this.$refs.myQuillEditor3.quill.getSelection();
      if (this.addRange3 != "" && this.addRange3 != null) {
        this.Rangeflag3 = true;
      }
      this.addRange4 = this.$refs.myQuillEditor4.quill.getSelection();
      if (this.addRange4 != "" && this.addRange4 != null) {
        this.Rangeflag4 = true;
      }
      this.addRange5 = this.$refs.myQuillEditor5.quill.getSelection();
      if (this.addRange5 != "" && this.addRange5 != null) {
        this.Rangeflag5 = true;
      }
      this.addRange6 = this.$refs.myQuillEditor6.quill.getSelection();
      if (this.addRange6 != "" && this.addRange6 != null) {
        this.Rangeflag6 = true;
      }
      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        (0, _upload.uploads)(fd).then(function (res) {
          if (_this.Rangeflag1) {
            _this.addRange1 = _this.$refs.myQuillEditor1.quill.getSelection();
            _this.$refs.myQuillEditor1.quill.insertEmbed(_this.addRange1 !== null ? _this.addRange1.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag1 = false;
          }
          if (_this.Rangeflag2) {
            _this.addRange2 = _this.$refs.myQuillEditor2.quill.getSelection();
            _this.$refs.myQuillEditor2.quill.insertEmbed(_this.addRange2 !== null ? _this.addRange2.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag2 = false;
          }
          if (_this.Rangeflag3) {
            _this.addRange3 = _this.$refs.myQuillEditor3.quill.getSelection();
            _this.$refs.myQuillEditor3.quill.insertEmbed(_this.addRange3 !== null ? _this.addRange3.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag3 = false;
          }
          if (_this.Rangeflag4) {
            _this.addRange4 = _this.$refs.myQuillEditor4.quill.getSelection();
            _this.$refs.myQuillEditor4.quill.insertEmbed(_this.addRange4 !== null ? _this.addRange4.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag4 = false;
          }
          if (_this.Rangeflag5) {
            _this.addRange5 = _this.$refs.myQuillEditor5.quill.getSelection();
            _this.$refs.myQuillEditor5.quill.insertEmbed(_this.addRange5 !== null ? _this.addRange5.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag5 = false;
          }
          if (_this.Rangeflag6) {
            _this.addRange6 = _this.$refs.myQuillEditor6.quill.getSelection();
            _this.$refs.myQuillEditor6.quill.insertEmbed(_this.addRange6 !== null ? _this.addRange6.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this.Rangeflag6 = false;
          }
        });
      });
    },
    alertValue: function alertValue(e, strValue, decimalNum) {
      e.quill.deleteText(decimalNum, 1, strValue); //保留 strValue 的 前 decimalNum 位字符，
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //提交
    submit: function submit() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (!valid) {
          _this2.msgTip("warning", "请填写完整");
          return false;
        } else {
          if (_this2.form.descriptionMobileCn == "" || _this2.form.descriptionMobileEn == "" || _this2.form.descriptionMobileJa == "" || _this2.form.descriptionPcCn == "" || _this2.form.descriptionPcEn == "" || _this2.form.descriptionPcJa == "") {
            _this2.msgTip("warning", "所有的描述都不能为空");
            return false;
          }
          if (!/(^[1-9]\d*$)/.test(_this2.form.seq) && _this2.form.seq !== "") {
            _this2.msgTip("warning", "序号只能为正整数");
            return false;
          }
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          _this2.isDisable = true;
          (0, _abouts.addAbout)(_this2.form).then(function (res) {
            if (res.success) {
              _this2.msgTip("success", "添加成功");
              _this2.$store.dispatch("tagsView/delView", _this2.$route).then(function () {
                _this2.$nextTick(function () {
                  _this2.$router.replace({
                    path: "/abouts",
                    query: {
                      cur: _this2.$route.query.cur,
                      line: _this2.$route.query.line
                    }
                  });
                });
              });
            } else {
              _this2.msgTip("error", res.errorMsg);
              // this.isRepeat = false;
              _this2.isDisable = false;
            }
          });
        }
      });
    }
  }
};