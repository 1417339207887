"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.areaNum = areaNum;
exports.countryNum = countryNum;
exports.dayNum = dayNum;
exports.globalModel = globalModel;
exports.monthNum = monthNum;
exports.productNum = productNum;
exports.searchActive = searchActive;
exports.searchAll = searchAll;
exports.searchGlobal = searchGlobal;
exports.searchUser = searchUser;
exports.volumeNum = volumeNum;
var _request = _interopRequireDefault(require("@/utils/request"));
//按商品统计数量
function productNum(data) {
  return (0, _request.default)({
    url: 'order/report/product',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000,
    data: data
  });
}
//按板块统计销量
function areaNum(data) {
  return (0, _request.default)({
    url: 'order/report/area',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000,
    data: data
  });
}
//按国家统计订单
function countryNum(data) {
  return (0, _request.default)({
    url: 'order/report/country',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000,
    data: data
  });
}
//按月份统计订单
function monthNum(data) {
  return (0, _request.default)({
    url: 'order/report/month',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000,
    data: data
  });
}
//日视图销售额
function dayNum(date) {
  return (0, _request.default)({
    url: 'order/report/day/sales/' + date,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000
  });
}
//日视图订单额，各设备型号台数
function volumeNum(date) {
  return (0, _request.default)({
    url: 'order/report/day/volume/' + date,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000
  });
}

//查询总库存数
function searchAll() {
  return (0, _request.default)({
    url: 'system/business/stock/report/query',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000
  });
}
//查询总激活数
function searchActive() {
  return (0, _request.default)({
    url: 'equipment/authorize/report',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000
  });
}

//查询总用户数
function searchUser() {
  return (0, _request.default)({
    url: 'user/report/query',
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000
  });
}

//全球用户活跃度
function searchGlobal(data) {
  return (0, _request.default)({
    url: 'system/business/query/equipment/dynamic',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000,
    data: data
  });
}

//查询全球用户活跃度-按型号
function globalModel(data) {
  return (0, _request.default)({
    url: 'system/business/query/model/dynamic',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 20000,
    data: data
  });
}