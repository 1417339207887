var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "common" },
    [
      _c("quill-editor", {
        ref: "updateData",
        attrs: { options: _vm.editorOption, "show-word-limit": "" },
        on: {
          blur: function ($event) {
            return _vm.onEditorBlur($event)
          },
          focus: function ($event) {
            return _vm.onEditorFocus($event)
          },
          change: function ($event) {
            return _vm.onEditorChange($event)
          },
        },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }