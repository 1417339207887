"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getExport = getExport;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取商品出货量
function getLists(data) {
  return (0, _request.default)({
    url: 'order/report/shipment',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}
//导出
function getExport(data) {
  return (0, _request.default)({
    url: 'order/report/shipment/export',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data,
    timeout: 1800000
  });
}