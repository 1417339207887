"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.array.find");
var _charts = require("../../api/charts.js");
var _dictionary = require("../../api/dictionary.js");
var _equipmentCharts = _interopRequireDefault(require("./equipmentCharts.vue"));
var _exchangeCharts = _interopRequireDefault(require("./exchangeCharts.vue"));
var _monthCharts = _interopRequireDefault(require("./monthCharts.vue"));
var _countryNum = _interopRequireDefault(require("./countryNum.vue"));
var _boxCard = _interopRequireDefault(require("../component/boxCard.vue"));
var _dayCharts = _interopRequireDefault(require("./dayCharts.vue"));
var _volumeCharts = _interopRequireDefault(require("./volumeCharts.vue"));
var _globalCharts = _interopRequireDefault(require("./globalCharts.vue"));
var _modelCharts = _interopRequireDefault(require("./modelCharts.vue"));
var _productNum = _interopRequireDefault(require("./productNum.vue"));
var _repairCharts = _interopRequireDefault(require("./repairCharts.vue"));
var _returnCharts = _interopRequireDefault(require("./returnCharts.vue"));
var _RaddarChart = _interopRequireDefault(require("./RaddarChart.vue"));
var _countryTable = _interopRequireDefault(require("./countryTable.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// echarts theme
// import resize from './mixins/resize'
var _default = exports.default = {
  //   mixins: [resize],
  components: {
    barGraph: _equipmentCharts.default,
    exchangeChart: _exchangeCharts.default,
    monthCharts: _monthCharts.default,
    country: _countryNum.default,
    boxCard: _boxCard.default,
    dayChart: _dayCharts.default,
    volumeChart: _volumeCharts.default,
    globalChart: _globalCharts.default,
    modelChart: _modelCharts.default,
    productNums: _productNum.default,
    repairChart: _repairCharts.default,
    returnChart: _returnCharts.default,
    raddarChart: _RaddarChart.default,
    countryTable: _countryTable.default
  },
  data: function data() {
    return {
      chart: null,
      form: {
        dealer: "",
        endPayTime: null,
        startPayTime: null
      },
      month: {
        dealer: "",
        years: ''
      },
      dealers: [],
      stockCounts: "",
      activeCounts: "",
      user: "",
      yearArr: [],
      dayArr: [],
      day: {
        date: ""
      },
      volumeDate: "",
      volumeArr: {},
      global: {
        startOpTime: null,
        endOpTime: null
      },
      globalArr: [],
      active: '',
      activeRate: '',
      rateArr: [],
      productArr: [],
      averageSalePrice: '',
      orderCounts: '',
      totalSalePrice: '',
      countryArr: []
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDic)({
      name: "DEALER",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.dealers = res.voList;
    });
    (0, _charts.searchAll)().then(function (res) {
      if (res.success) {
        _this.stockCounts = res.voT.stockCounts;
      }
    });
    (0, _charts.searchActive)().then(function (item) {
      if (item.success) {
        _this.activeCounts = item.voT.activeCounts;
      }
    });
    (0, _charts.searchUser)().then(function (res) {
      if (res.success) {
        _this.user = res.voT.totalUserCounts;
      }
    });
    setTimeout(function () {
      _this.searchs();
    }, 2000);
    setTimeout(function () {
      _this.searchInfo();
    }, 3000);
    this.find();
    // this.volumeFind();
    setTimeout(function () {
      _this.globalSearch();
    }, 4000);
  },
  methods: {
    //查询
    searchInfo: function searchInfo(v) {
      var _this2 = this;
      (0, _charts.productNum)(this.form).then(function (res) {
        _this2.productArr = res.voList;
      });
      (0, _charts.countryNum)(this.form).then(function (res) {
        _this2.countryArr = res.voList;
      });
    },
    //查询年
    searchs: function searchs() {
      var _this3 = this;
      var date = new Date();
      if (this.month.years) {
        this.month.years = this.month.years;
      } else {
        this.month.years = date.getFullYear().toString();
      }
      (0, _charts.monthNum)({
        dealer: this.month.dealer,
        year: this.month.years
      }).then(function (res) {
        _this3.yearArr = res.voList;
      });
    },
    //日试图查询
    find: function find() {
      var _this4 = this;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var mydate = year.toString() + "-" + month.toString();
      if (this.day.date) {
        this.day.date = this.day.date;
      } else {
        this.day.date = mydate;
      }
      (0, _charts.dayNum)(this.day.date).then(function (res) {
        _this4.dayArr = res.voT.nameValueList;
        _this4.averageSalePrice = res.voT.averageSalePrice;
        _this4.orderCounts = res.voT.orderCounts;
        _this4.totalSalePrice = res.voT.totalSalePrice;
      });
      (0, _charts.volumeNum)(this.day.date).then(function (res) {
        _this4.volumeArr = res.voT;
      });
    },
    //全球用户活跃度查询
    globalSearch: function globalSearch() {
      var _this5 = this;
      (0, _charts.searchGlobal)(this.global).then(function (res) {
        _this5.globalArr = res.voList;
      });
      (0, _charts.globalModel)(this.global).then(function (item) {
        _this5.rateArr = item.voT.nameValueList;
        _this5.active = item.voT.totalCounts;
        _this5.activeRate = Math.round(item.voT.totalCounts / _this5.activeCounts * 100);
      });
    }
  }
};