"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLists = getLists;
exports.getUndo = getUndo;
exports.stockDetailExport = stockDetailExport;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取库存明细
function getLists(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/detail/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}

//撤销
function getUndo(id) {
  return (0, _request.default)({
    url: 'order/dealer/stock/detail/undo/' + id,
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 600000
  });
}

//导出
function stockDetailExport(data) {
  return (0, _request.default)({
    url: 'order/dealer/stock/detail/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data,
    timeout: 30000
  });
}