"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _presellInfo = require("../../api/presellInfo.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        language: "",
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      languages: [{
        language: "ZH-CN",
        label: "中文"
      }, {
        language: "EN",
        label: "英文"
      }, {
        language: "JA",
        label: "日文"
      }],
      types: [],
      loading: false,
      tableData: [],
      titletype: "",
      dialogFormVisible: false,
      addform: {
        count: "",
        deliveryTime: "",
        threshold: "",
        type: ""
      },
      rules: {
        type: [{
          required: true,
          trigger: "change"
        }],
        count: [{
          required: true,
          trigger: "blur"
        }],
        deliveryTime: [{
          required: true,
          trigger: "blur"
        }],
        threshold: [{
          required: true,
          trigger: "blur"
        }]
      },
      formLabelWidth: "120px",
      currentPage: 1,
      total: 0,
      isDisable: false,
      id: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.types = res.voList;
    });
    this.getList();
  },
  methods: {
    //查询数据
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _presellInfo.getLists)(_this2.form).then(function (res) {
          if (res.success) {
            _this2.tableData = res.voList;
            _this2.total = Number(res.total);
            _this2.loading = false;
          }
        });
      }, 500);
    },
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.type = "";
      this.form.language = "";
    },
    //新增
    add: function add() {
      this.titletype = "新增";
      this.dialogFormVisible = true;
    },
    addClose: function addClose() {
      this.dialogFormVisible = false;
      this.$refs.addform.resetFields();
    },
    //修改
    change: function change(id) {
      var _this3 = this;
      this.titletype = "修改";
      this.id = id;
      this.dialogFormVisible = true;
      (0, _presellInfo.alonepresell)(id).then(function (res) {
        if (res.success) {
          _this3.addform = res.voT;
        } else {
          _this3.msgTip("error", res.errorMsg);
        }
      });
    },
    //删除
    dele: function dele(id) {
      var _this4 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _presellInfo.delePresell)(id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "删除成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    sure: function sure() {
      var _this5 = this;
      if (this.titletype == "新增") {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            _this5.isDisable = false;

            // const time = new Date(this.addform.deliveryTime).getTime() + 3600 * 8 * 1000;
            // this.addform.deliveryTime = new Date(time);

            _this5.addform.deliveryTime = _this5.$moment(_this5.addform.deliveryTime).format("YYYY-MM-DD HH:mm:ss");
            console.log(_this5.addform.deliveryTime, "0000");
            (0, _presellInfo.addList)(_this5.addform).then(function (res) {
              if (res.success) {
                _this5.msgTip("success", "添加成功");
                _this5.dialogFormVisible = false;
                _this5.getList();
              } else {
                _this5.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this5.msgTip("warning", "请填写完整");
            return false;
          }
        });
      } else if (this.titletype == "修改") {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            _this5.isDisable = false;
            _this5.addform.deliveryTime = _this5.$moment(_this5.addform.deliveryTime).format("YYYY-MM-DD HH:mm:ss");
            (0, _presellInfo.changepresell)(_this5.id, _this5.addform).then(function (res) {
              if (res.success) {
                _this5.msgTip("success", "修改成功");
                _this5.dialogFormVisible = false;
                _this5.getList();
              } else {
                _this5.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this5.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};