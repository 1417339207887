"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var Quill = _interopRequireWildcard(require("quill"));
var _faqmess = require("../../api/faqmess.js");
var _upload = require("../../api/upload.js");
var _faq = require("../../api/faq.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var titleConfig = [{
  Choice: '.ql-bold',
  title: '加粗'
}, {
  Choice: '.ql-italic',
  title: '斜体'
}, {
  Choice: '.ql-underline',
  title: '下划线'
}, {
  Choice: '.ql-header',
  title: '段落格式'
}, {
  Choice: '.ql-strike',
  title: '删除线'
}, {
  Choice: '.ql-blockquote',
  title: '块引用'
}, {
  Choice: '.ql-code',
  title: '插入代码'
}, {
  Choice: '.ql-code-block',
  title: '插入代码段'
}, {
  Choice: '.ql-font',
  title: '字体'
}, {
  Choice: '.ql-size',
  title: '字体大小'
}, {
  Choice: '.ql-list[value="ordered"]',
  title: '编号列表'
}, {
  Choice: '.ql-list[value="bullet"]',
  title: '项目列表'
}, {
  Choice: '.ql-direction',
  title: '文本方向'
}, {
  Choice: '.ql-header[value="1"]',
  title: 'h1'
}, {
  Choice: '.ql-header[value="2"]',
  title: 'h2'
}, {
  Choice: '.ql-align',
  title: '对齐方式'
}, {
  Choice: '.ql-color',
  title: '字体颜色'
}, {
  Choice: '.ql-background',
  title: '背景颜色'
}, {
  Choice: '.ql-image',
  title: '图像'
}, {
  Choice: '.ql-video',
  title: '视频'
}, {
  Choice: '.ql-link',
  title: '添加链接'
}, {
  Choice: '.ql-formula',
  title: '插入公式'
}, {
  Choice: '.ql-clean',
  title: '清除字体格式'
}, {
  Choice: '.ql-script[value="sub"]',
  title: '下标'
}, {
  Choice: '.ql-script[value="super"]',
  title: '上标'
}, {
  Choice: '.ql-indent[value="-1"]',
  title: '向左缩进'
}, {
  Choice: '.ql-indent[value="+1"]',
  title: '向右缩进'
}, {
  Choice: '.ql-header .ql-picker-label',
  title: '标题大小'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="1"]',
  title: '标题一'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="2"]',
  title: '标题二'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="3"]',
  title: '标题三'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="4"]',
  title: '标题四'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="5"]',
  title: '标题五'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="6"]',
  title: '标题六'
}, {
  Choice: '.ql-header .ql-picker-item:last-child',
  title: '标准'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="small"]',
  title: '小号'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="large"]',
  title: '大号'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="huge"]',
  title: '超大号'
}, {
  Choice: '.ql-size .ql-picker-item:nth-child(2)',
  title: '标准'
}, {
  Choice: '.ql-align .ql-picker-item:first-child',
  title: '居左对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="center"]',
  title: '居中对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="right"]',
  title: '居右对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="justify"]',
  title: '两端对齐'
}];
var _default = exports.default = {
  data: function data() {
    return {
      value: "",
      line: [{
        name: "上线",
        type: "0"
      }, {
        name: "下线",
        type: "1"
      }],
      typelist: [{
        name: "FAQ",
        type: "0"
      }, {
        name: "故障排除",
        type: "1"
      }],
      faqTit: [],
      type: "",
      loadingCn: false,
      loadingEn: false,
      loadingJa: false,
      videoflagCn: true,
      videoflagsCn: false,
      videoSrcCn: '',
      videoflagEn: true,
      videoflagsEn: false,
      videoSrcEn: '',
      videoflagJa: true,
      videoflagsJa: false,
      videoSrcJa: '',
      form: {
        answerCn: "",
        answerEn: "",
        answerJa: "",
        faqTitleId: "",
        seq: "",
        questionCn: "",
        questionEn: "",
        questionJa: "",
        videoCn: "",
        videoEn: "",
        videoJa: ""
      },
      formLabelWidth: "120px",
      formRules: {
        seq: [{
          required: true,
          trigger: "blur"
        }],
        questionEn: [{
          required: true,
          trigger: "blur"
        }],
        questionCn: [{
          required: true,
          trigger: "blur"
        }],
        questionJa: [{
          required: true,
          trigger: "blur"
        }],
        faqTitleId: [{
          required: true,
          trigger: "blur"
        }]
      },
      isDisable: false,
      editorOption: {
        placeholder: "请输入文本信息..."
      },
      addRange: [],
      addRange1: [],
      addRange2: [],
      Rangeflag: false,
      Rangeflag1: false,
      Rangeflag2: false,
      id: ""
    };
  },
  mounted: function mounted() {
    var _this = this;
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor1.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor2.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.id = this.$route.query.id;
    (0, _faqmess.aloneFaq)(this.id).then(function (res) {
      _this.form = res.voT;
      _this.videoSrcCn = res.voT.videoCn.split('===')[0];
      _this.videoSrcEn = res.voT.videoEn.split('===')[0];
      _this.videoSrcJa = res.voT.videoJa.split('===')[0];
    });
    autotip: {
      // this.$refs.myQuillEditor.dataset.placeholder=''
      document.getElementsByClassName('ql-editor')[0].dataset.placeholder = '';
      for (var _i = 0, _titleConfig = titleConfig; _i < _titleConfig.length; _i++) {
        var item = _titleConfig[_i];
        var tip = document.querySelector('.quill-editor ' + item.Choice);
        if (!tip) continue;
        tip.setAttribute('title', item.title);
      }
    }
  },
  methods: {
    imgHandler: function imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (this.addRange != "" && this.addRange != null) {
        this.Rangeflag = true;
      }
      this.addRange1 = this.$refs.myQuillEditor1.quill.getSelection();
      if (this.addRange1 != "" && this.addRange1 != null) {
        this.Rangeflag1 = true;
      }
      this.addRange2 = this.$refs.myQuillEditor2.quill.getSelection();
      if (this.addRange2 != "" && this.addRange2 != null) {
        this.Rangeflag2 = true;
      }
      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        (0, _upload.uploads)(fd).then(function (res) {
          if (_this2.Rangeflag) {
            _this2.addRange = _this2.$refs.myQuillEditor.quill.getSelection();
            _this2.$refs.myQuillEditor.quill.insertEmbed(_this2.addRange !== null ? _this2.addRange.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag = false;
          }
          if (_this2.Rangeflag1) {
            _this2.addRange1 = _this2.$refs.myQuillEditor1.quill.getSelection();
            _this2.$refs.myQuillEditor1.quill.insertEmbed(_this2.addRange1 !== null ? _this2.addRange1.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag1 = false;
          }
          if (_this2.Rangeflag2) {
            _this2.addRange2 = _this2.$refs.myQuillEditor2.quill.getSelection();
            _this2.$refs.myQuillEditor2.quill.insertEmbed(_this2.addRange2 !== null ? _this2.addRange2.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag2 = false;
          }
        });
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //获取标题
    handleChangeFirst: function handleChangeFirst(value) {
      var _this3 = this;
      (0, _faq.faqType)(value).then(function (res) {
        _this3.faqTit = res.voList;
      });
    },
    //上传视频
    submitUploadCn: function submitUploadCn() {
      var _this4 = this;
      var fileData = this.$refs.uploadFileCn.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingCn = true;
        this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _upload.upload)(formFile).then(function (res) {
        if (res.success) {
          _this4.loadingCn = false;
          if (res.valueCn !== '') {
            _this4.videoflagCn = false;
            _this4.videoflagsCn = true;
            _this4.videoSrcCn = res.voT.downloadUrl.split('===')[0];
            _this4.form.videoCn = res.voT.downloadUrl;
          }
        } else {
          _this4.loadingCn = false;
          _this4.msgTip("error", res.errorMsg);
          _this4.$refs.uploadFileCn.value = null;
        }
      });
    },
    //上传视频
    submitUploadEn: function submitUploadEn() {
      var _this5 = this;
      var fileData = this.$refs.uploadFileEn.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingEn = true;
        this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _upload.upload)(formFile).then(function (res) {
        if (res.success) {
          _this5.loadingEn = false;
          if (res.valueCn !== '') {
            _this5.videoflagEn = false;
            _this5.videoflagsEn = true;
            _this5.videoSrcEn = res.voT.downloadUrl.split('===')[0];
            _this5.form.videoEn = res.voT.downloadUrl;
          }
        } else {
          _this5.loadingEn = false;
          _this5.msgTip("error", res.errorMsg);
          _this5.$refs.uploadFileEn.value = null;
        }
      });
    },
    //上传视频JA
    submitUploadJa: function submitUploadJa() {
      var _this6 = this;
      var fileData = this.$refs.uploadFileJa.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingJa = true;
        this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _upload.upload)(formFile).then(function (res) {
        if (res.success) {
          _this6.loadingJa = false;
          if (res.valueCn !== '') {
            _this6.videoflagJa = false;
            _this6.videoflagsJa = true;
            _this6.videoSrcJa = res.voT.downloadUrl.split('===')[0];
            _this6.form.videoJa = res.voT.downloadUrl;
          }
        } else {
          _this6.loadingJa = false;
          _this6.msgTip("error", res.errorMsg);
          _this6.$refs.uploadFileJa.value = null;
        }
      });
    },
    //提交
    submit: function submit() {
      var _this7 = this;
      if (this.form.answerCn == "" || this.form.answerEn == "" || this.form.answerJa == "") {
        this.msgTip("warning", "答案不能为空");
      } else {
        this.$refs.form.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            // this.isRepeat = true;
            _this7.isDisable = true;
            (0, _faqmess.changeFaq)(_this7.id, _this7.form).then(function (res) {
              if (res.success) {
                _this7.msgTip("success", "修改成功");
                _this7.$store.dispatch("tagsView/delView", _this7.$route).then(function () {
                  _this7.$nextTick(function () {
                    _this7.$router.replace({
                      path: "/serve/qamess",
                      query: {
                        line: _this7.$route.query.line,
                        faqTitleId: _this7.$route.query.faqTitleId,
                        type: _this7.$route.query.type,
                        pageNo: _this7.$route.query.pageNo
                      }
                    });
                  });
                });
              } else {
                _this7.msgTip("error", res.errorMsg);
                // this.isRepeat = false;
              }
            });
          } else {
            _this7.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    }
  }
};