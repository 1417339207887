"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.changeRule = changeRule;
exports.getLists = getLists;
exports.startRule = startRule;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询优惠规则(cms系统调用)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/promo/rule/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增优惠规则(cms系统调用)
function addList(data) {
  return (0, _request.default)({
    url: 'cms/promo/rule/add',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//启用停用优惠规则(cms系统调用)
function startRule(data, id) {
  return (0, _request.default)({
    url: 'cms/promo/rule/enable/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改优惠规则(cms系统调用)
function changeRule(data, id) {
  return (0, _request.default)({
    url: 'cms/promo/rule/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}