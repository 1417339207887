"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _userworks = require("../../api/userworks.js");
var _index = require("../../utils/index.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      form: {
        line: "",
        pageNo: 1,
        pageSize: 10
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      lines: [{
        line: "0",
        label: "上线"
      }, {
        line: "1",
        label: "预览"
      }, {
        line: "2",
        label: "下线"
      }],
      addform: {
        line: "",
        seq: "",
        urlMobile: "",
        urlPc: ""
      },
      formLabelWidth: "120px",
      dialogFormVisible: false,
      imageUrl1: "",
      imageUrl2: "",
      isDisable: false,
      dialogFormVisibles: false,
      changeid: ""
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //获取数据
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _userworks.getLists)(_this.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.form.pageNo = 1;
            _this.currentPage = 1;
            (0, _userworks.getLists)(_this.form).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //判断上下线
    lineFormatter: function lineFormatter(row, column) {
      if (row.line == "0") {
        return "上线";
      } else if (row.line == "1") {
        return "预览";
      } else if (row.line == "2") {
        return "下线";
      }
    },
    //搜索
    search: function search() {
      this.currentPage = 1;
      this.form.pageNo = 1;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.line = "";
      this.getList();
    },
    //新增
    add: function add() {
      this.dialogFormVisible = true;
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this2 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this2.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this2.addform.urlPc = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this3 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this3.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this3.addform.urlMobile = res.voT.downloadUrl;
        console.log(_this3.addform.urlMobile, '909090');
      });
      return false; //屏蔽了action的默认上传
    },
    //新增确定
    sure: function sure(formName) {
      var _this4 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this4.imageUrl1 == "") {
            _this4.msgTip("warning", "必须上传pc端图片");
            return;
          }
          if (_this4.imageUrl2 == "") {
            _this4.msgTip("warning", "必须上传移动端图片");
            return;
          }
          _this4.isDisable = true;
          (0, _userworks.addList)(_this4.addform).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "添加成功");
              _this4.addform.seq = "";
              _this4.dialogFormVisible = false;
              //前端刷新
              (0, _userworks.dataManipulation)(_this4.tableData, getDate(), window.localStorage.getItem("username"));
              _this4.getList();
              _this4.$refs.addform.resetFields();
              _this4.imageUrl1 = "";
              _this4.imageUrl2 = "";
              _this4.isDisable = false;
            } else {
              _this4.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this4.isDisable = false;
            }
          });
        }
      });
    },
    //新增关闭按钮
    addClose: function addClose(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].resetFields();
    },
    //修改
    change: function change(v) {
      var _this5 = this;
      this.dialogFormVisibles = true;
      this.isDisable = false;
      (0, _userworks.aloneList)(v.id).then(function (res) {
        _this5.imageUrl1 = res.urlPc.split('===')[0];
        _this5.imageUrl2 = res.urlMobile.split('===')[0];
        _this5.addform.urlPc = res.urlPc;
        _this5.addform.urlMobile = res.urlMobile;
      });
      this.changeid = v.id;
    },
    //修改关闭按钮
    changeClose: function changeClose() {
      this.dialogFormVisibles = false;
      this.imageUrl1 = '';
      this.imageUrl2 = '';
    },
    //修改确定按钮
    keep: function keep() {
      var _this6 = this;
      if (this.imageUrl1 == "") {
        this.msgTip("warning", "必须上传pc端图片");
        return;
      }
      if (this.imageUrl2 == "") {
        this.msgTip("warning", "必须上传移动端图片");
        return;
      }
      this.isDisable = true;
      // this.addform.urlPc = this.imageUrl1;
      // this.addform.urlMobile = this.imageUrl2;
      (0, _userworks.changeList)({
        urlPc: this.addform.urlPc,
        urlMobile: this.addform.urlMobile,
        id: this.changeid
      }).then(function (res) {
        if (res.success) {
          _this6.msgTip("success", "修改成功");
          // 前端刷新
          (0, _userworks.dataManipulation)(_this6.tableData, getDate(), window.localStorage.getItem("username"));
          _this6.getList();
          _this6.dialogFormVisibles = false;
          _this6.isDisable = false;
        } else {
          _this6.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
          _this6.isDisable = false;
        }
      });
    },
    //删除
    dele: function dele(v) {
      var _this7 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _userworks.deleList)(v.id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "操作成功");
            _this7.getList();
          } else {
            _this7.msgTip("warning", res.errorMsg);
          }
        });
      });
    },
    //上线
    online: function online(v) {
      var _this8 = this;
      if (v.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _userworks.changeOn)({
          line: 0
        }, v.id).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "上线成功");
            _this8.getList();
          } else {
            _this8.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(v) {
      var _this9 = this;
      if (v.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _userworks.changeOn)({
          line: 2
        }, v.id).then(function (res) {
          if (res.success) {
            _this9.msgTip("success", "下线成功");
            _this9.getList();
          } else {
            _this9.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(v) {
      var _this10 = this;
      if (v.line == "1") {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _userworks.changeOn)({
          line: 1
        }, v.id).then(function (res) {
          if (res.success) {
            _this10.msgTip("success", "操作成功");
            _this10.getList();
          } else {
            _this10.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/home/creation/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this11 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
        return;
      }
      if (!/^[1-9]{1,}[\d]*$/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
        return;
      }
      (0, _userworks.updateSeq)({
        seq: val.seq
      }, val.id).then(function (res) {
        if (res.success) {
          _this11.msgTip("success", "修改成功");
          _this11.getList();
          _this11.isShow = false;
        } else {
          _this11.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}