"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _product = require("../../api/product.js");
var _faq = require("../../api/faq");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      tableData: [],
      form: {
        id: "",
        line: "",
        pageNo: 1,
        pageSize: 10
      },
      forms: {
        id: "",
        line: "",
        pageNo: 1,
        pageSize: 10
      },
      searchBtn: false,
      total: 0,
      currentPage: this.$route.query.cur ? Number(this.$route.query.cur) : 1,
      loading: false,
      isDisable: false,
      type: [],
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      value: "",
      flag: false,
      changeid: "",
      radio: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
    if (this.$route.query.cur) {
      this.form.line = this.$route.query.line;
      this.form.pageNo = this.$route.query.cur;
      this.forms.line = this.$route.query.line;
      this.forms.pageNo = this.$route.query.cur;
    }
    this.getList();
  },
  methods: {
    //请求列表数据
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _product.getLists)(_this2.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.forms.pageNo = 1;
            _this2.currentPage = 1;
            (0, _product.getLists)(_this2.forms).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = res.total;
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //搜索
    search: function search() {
      this.searchBtn = true;
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.id = this.form.id;
      this.forms.line = this.form.line;
      this.forms.type = this.form.type;
      this.getList();
    },
    //判断类型
    stateFormat: function stateFormat(row) {
      if (row.buttonFlag === "Y") {
        return "是";
      } else {
        return "否";
      }
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line === "1") {
        return "预览";
      } else if (row.line === "2") {
        return "下线";
      }
    },
    //清空
    empty: function empty() {
      this.form.line = "";
      this.forms.line = "";
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.searchBtn = false;
      window.location.href = window.location.href.split('?')[0];
      this.getList();
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "addproductbuy",
        query: {
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changeproductbuy",
        query: {
          id: id,
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //上线
    online: function online(row) {
      var _this3 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _product.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this3.msgTip("success", "上线成功");
            _this3.getList();
          } else {
            _this3.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this4 = this;
      if (row.line == '1') {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _product.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "操作成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(row) {
      var _this5 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _product.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "下线成功");
            _this5.getList();
          } else {
            _this5.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this6 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _product.deleproduct)(id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "删除成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    //判断是否预售
    isPresell: function isPresell(row) {
      if (row.isPresell == "0") {
        return '预售';
      } else if (row.isPresell == '1') {
        return '在售';
      }
    }
  }
};