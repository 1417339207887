"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _orderSettle = require("../../api/orderSettle.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        dateTime: "",
        type: "",
        pageNo: 1,
        pageSize: 10
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      datas: [],
      loadings: false,
      load: false,
      sureLoading: false,
      dialogFormVisible: false,
      formLabelWidth: '100px',
      settlerules: {
        dateTime: [{
          required: true,
          trigger: "change"
        }]
      },
      settleForm: {
        dateTime: '',
        type: ''
      },
      settleTitle: '',
      isDisable: false,
      finsh: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _orderSettle.getLists)(_this.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.form.pageNo = 1;
            _this.currentPage = 1;
            (0, _orderSettle.getLists)(_this.form).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //判断结算状态
    typeFlag: function typeFlag(v) {
      if (v.settleType == 'Y') {
        return '已结算';
      } else if (v.settleType == 'N') {
        return '未结算';
      }
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.dateTime = "";
      this.getList();
    },
    //结算
    settle: function settle() {
      this.dialogFormVisible = true;
      this.settleTitle = '结算';
      this.finsh = '结算';
    },
    //取消结算
    cancelSettle: function cancelSettle() {
      this.settleTitle = '取消结算';
      this.finsh = '取消结算';
      this.dialogFormVisible = true;
    },
    //确定按钮
    sure: function sure() {
      var _this2 = this;
      if (this.settleTitle == '结算') {
        this.$confirm("您是否要结算" + this.settleForm.dateTime + '数据', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          (0, _orderSettle.getSettle)({
            dateTime: _this2.settleForm.dateTime,
            type: 'Y'
          }).then(function (res) {
            if (res.success) {
              _this2.msgTip("success", '操作成功');
              _this2.dialogFormVisible = false;
              _this2.$refs.settleForm.resetFields();
              _this2.getList();
            } else {
              _this2.msgTip("error", res.errorMsg);
            }
          });
        });
      } else if (this.settleTitle == '取消结算') {
        this.$confirm("您是否要取消结算" + this.settleForm.dateTime + '数据', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          (0, _orderSettle.getSettle)({
            dateTime: _this2.settleForm.dateTime,
            type: 'N'
          }).then(function (res) {
            if (res.success) {
              _this2.msgTip("success", '操作成功');
              _this2.dialogFormVisible = false;
              _this2.$refs.settleForm.resetFields();
              _this2.getList();
            } else {
              _this2.msgTip("error", res.errorMsg);
            }
          });
        });
      }
    },
    settleClose: function settleClose() {
      this.dialogFormVisible = false;
      this.settleForm.dateTime = "";
      this.$refs.settleForm.resetFields();
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};