"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.find-index");
var _product = require("../../api/product.js");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      addform: {
        descriptionCn: "",
        descriptionEn: "",
        titleCn: "",
        titleEn: "",
        type: "",
        urlMobile: "",
        urlPc: "",
        seoTitleCn: "",
        seoTitleEn: "",
        seoKeywordCn: "",
        seoKeywordEn: "",
        seoDescriptionCn: "",
        seoDescriptionEn: "",
        contentMobileCn: '',
        contentMobileEn: '',
        contentMobileJa: '',
        contentPcCn: '',
        contentPcEn: '',
        contentPcJa: '',
        isPresell: ''
      },
      uploadUrl: "".concat(process.env.VUE_APP_BASE_API, "cms/picture/upload"),
      uplaod_data: {
        "x-access-token": window.localStorage.getItem("token")
      },
      fileList: [],
      fileListEn: [],
      fileListJa: [],
      fileListMen: [],
      fileListMcn: [],
      fileListMja: [],
      Presell: [{
        value: '0',
        valueCn: '预售'
      }, {
        value: '1',
        valueCn: '在售'
      }],
      addRules: {
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "change"
        }],
        urlMobile: [{
          required: true,
          trigger: "blur"
        }],
        urlPc: [{
          required: true,
          trigger: "blur"
        }],
        isPresell: [{
          required: true,
          trigger: "change"
        }]
      },
      formLabelWidth: "120px",
      imageUrl2: "",
      imageUrl1: "",
      type: [],
      dialogVisible: false,
      fileupload: "",
      dialogImageUrl: "",
      dialogVisibleEn: false,
      fileuploadEn: "",
      dialogImageUrlEn: "",
      dialogVisibleJa: false,
      fileuploadJa: "",
      dialogImageUrlJa: "",
      dialogVisibleMen: false,
      fileuploadMen: "",
      dialogImageUrlMen: "",
      dialogVisibleMcn: false,
      fileuploadMcn: "",
      dialogImageUrlMcn: "",
      dialogVisibleMja: false,
      fileuploadMja: "",
      dialogImageUrlMja: ""
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      this.fileList = fileList;
    },
    handleRemoves: function handleRemoves(file, fileList) {
      var _this2 = this;
      //this.addform.mutiUrl = [];
      var mutiUrls = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this2.addform.contentPcCn = mutiUrls.join(",");
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this3 = this;
      //    this.addform.mutiUrl = [];
      var mutiUrl1 = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this3.addform.contentPcCn = mutiUrl1.join(',');
      });
    },
    changePicture: function changePicture(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      this.fileupload = this.fileList.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccess: function uploadSuccess(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileList.splice(this.fileupload, 1, file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    //pc内容en
    handleUploadSuccessEn: function handleUploadSuccessEn(res, file, fileList) {
      this.fileListEn = fileList;
    },
    handleRemovesEn: function handleRemovesEn(file, fileList) {
      var _this4 = this;
      var mutiUrls = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this4.addform.contentPcEn = mutiUrls.join(",");
      });
    },
    handleRemoveEn: function handleRemoveEn(file, fileList) {
      var _this5 = this;
      var mutiUrl1 = [];
      this.fileListEn = fileList;
      this.fileListEn.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this5.addform.contentPcEn = mutiUrl1.join(',');
      });
    },
    changePictureEn: function changePictureEn(file) {
      this.dialogVisibleEn = true;
      this.dialogImageUrlEn = file.url;
      this.fileuploadEn = this.fileListEn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessEn: function uploadSuccessEn(res, file) {
      file.url = res.voT.downloadUrl;
      this.fileListEn.splice(this.fileuploadEn, 1, file);
      this.dialogImageUrlEn = file.url;
      this.dialogVisibleEn = false;
    },
    closeEn: function closeEn() {
      this.dialogVisibleEn = false;
    },
    //pc内容ja
    handleUploadSuccessJa: function handleUploadSuccessJa(res, file, fileList) {
      this.fileListJa = fileList;
    },
    handleRemovesJa: function handleRemovesJa(file, fileList) {
      var _this6 = this;
      var mutiUrls = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this6.addform.contentPcJa = mutiUrls.join(",");
      });
    },
    handleRemoveJa: function handleRemoveJa(file, fileList) {
      var _this7 = this;
      var mutiUrl1 = [];
      this.fileListJa = fileList;
      this.fileListJa.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this7.addform.contentPcJa = mutiUrl1.join(',');
      });
    },
    changePictureJa: function changePictureJa(file) {
      this.dialogVisibleJa = true;
      this.dialogImageUrlJa = file.url;
      this.fileuploadJa = this.fileListJa.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessJa: function uploadSuccessJa(res, file) {
      file.url = res.voT.downloadUrl;
      this.fileListJa.splice(this.fileuploadJa, 1, file);
      this.dialogImageUrlJa = file.url;
      this.dialogVisibleJa = false;
    },
    closeJa: function closeJa() {
      this.dialogVisibleJa = false;
    },
    //移动端内容cn
    handleUploadSuccessMcn: function handleUploadSuccessMcn(res, file, fileList) {
      this.fileListMcn = fileList;
    },
    handleRemovesMcn: function handleRemovesMcn(file, fileList) {
      var _this8 = this;
      var mutiUrls = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this8.addform.contentMobileCn = mutiUrls.join(",");
      });
    },
    handleRemoveMcn: function handleRemoveMcn(file, fileList) {
      var _this9 = this;
      var mutiUrl1 = [];
      this.fileListMcn = fileList;
      this.fileListMcn.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this9.addform.contentMobileCn = mutiUrl1.join(',');
      });
    },
    changePictureMcn: function changePictureMcn(file) {
      this.dialogVisibleMcn = true;
      this.dialogImageUrlMcn = file.url;
      this.fileuploadMcn = this.fileListMcn.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMcn: function uploadSuccessMcn(res, file) {
      file.url = res.voT.downloadUrl;
      this.fileListMcn.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMcn = file.url;
      this.dialogVisibleMcn = false;
    },
    closeMcn: function closeMcn() {
      this.dialogVisibleMcn = false;
    },
    //移动端内容en
    handleUploadSuccessMen: function handleUploadSuccessMen(res, file, fileList) {
      this.fileListMen = fileList;
    },
    handleRemovesMen: function handleRemovesMen(file, fileList) {
      var _this10 = this;
      var mutiUrls = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this10.addform.contentMobileEn = mutiUrls.join(",");
      });
    },
    handleRemoveMen: function handleRemoveMen(file, fileList) {
      var _this11 = this;
      var mutiUrl1 = [];
      this.fileListMen = fileList;
      this.fileListMen.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this11.addform.contentMobileEn = mutiUrl1.join(',');
      });
    },
    changePictureMen: function changePictureMen(file) {
      this.dialogVisibleMen = true;
      this.dialogImageUrlMen = file.url;
      this.fileuploadMen = this.fileListMen.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMen: function uploadSuccessMen(res, file) {
      file.url = res.voT.downloadUrl;
      this.fileListMen.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMen = file.url;
      this.dialogVisibleMen = false;
    },
    closeMen: function closeMen() {
      this.dialogVisibleMen = false;
    },
    //移动端内容ja
    handleUploadSuccessMja: function handleUploadSuccessMja(res, file, fileList) {
      this.fileListMja = fileList;
    },
    handleRemovesMja: function handleRemovesMja(file, fileList) {
      var _this12 = this;
      var mutiUrls = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this12.addform.contentMobileJa = mutiUrls.join(",");
      });
    },
    handleRemoveMja: function handleRemoveMja(file, fileList) {
      var _this13 = this;
      var mutiUrl1 = [];
      this.fileListMja = fileList;
      this.fileListMja.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this13.addform.contentMobileJa = mutiUrl1.join(',');
      });
    },
    changePictureMja: function changePictureMja(file) {
      this.dialogVisibleMja = true;
      this.dialogImageUrlMja = file.url;
      this.fileuploadMja = this.fileListMja.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccessMja: function uploadSuccessMja(res, file) {
      file.url = res.voT.downloadUrl;
      this.fileListMja.splice(this.fileuploadMen, 1, file);
      this.dialogImageUrlMja = file.url;
      this.dialogVisibleMja = false;
    },
    closeMja: function closeMja() {
      this.dialogVisibleMja = false;
    },
    checkAllSuccess: function checkAllSuccess() {
      var _this14 = this;
      return Object.keys(this.listObj).every(function (item) {
        return _this14.listObj[item].hasSuccess;
      });
    },
    handleSubmit: function handleSubmit() {
      var _this15 = this;
      var arr = Object.keys(this.listObj).map(function (v) {
        return _this15.listObj[v];
      });
      if (!this.checkAllSuccess()) {
        this.$message("Please wait for all images to be uploaded successfully. If there is a network problem, please refresh the page and upload again!");
        return;
      }
      this.$emit("successCBK", arr);
      this.listObj = {};
      this.fileList = [];
      this.dialogVisible = false;
    },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this16 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png,gif,jpg,jpeg的图片");
        return;
      }
      // let size = file.size / 1024 / 1024 / 2;
      // if (size > 2) {
      //    this.msgTip("warning", "图片大小必须小于2M");
      //   return;
      // }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this16.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this16.addform.urlMobile = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this17 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      // let size = file.size / 1024 / 1024 / 2;
      // if (size > 2) {
      //    this.msgTip("warning", "图片大小必须小于2M");
      //   return;
      // }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this17.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this17.addform.urlPc = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    submit: function submit() {
      var _this18 = this;
      if (this.fileList == [] && this.addform.contentPcCn == "") {
        this.msgTip("warning", "请上传PC内容CN");
        return false;
      } else if (this.fileListEn == [] && this.addform.contentPcEn == "") {
        this.msgTip("warning", "请上传PC内容EN");
        return false;
      } else if (this.fileListJa == [] && this.addform.contentPcJa == "") {
        this.msgTip("warning", "请上传PC内容JA");
        return false;
      } else if (this.fileListMcn == [] && this.addform.contentMobileCn == "") {
        this.msgTip("warning", "请上传移动端内容CN");
        return false;
      } else if (this.fileListMen == [] && this.addform.contentMobileEn == "") {
        this.msgTip("warning", "请上传移动端内容EN");
        return false;
      } else if (this.fileListMja == [] && this.addform.contentMobileJa == "") {
        this.msgTip("warning", "请上传移动端内容JA");
        return false;
      } else {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            var contentPcCn = [];
            _this18.fileList.map(function (res) {
              contentPcCn.push(res.response.voT.downloadUrl);
              _this18.addform.contentPcCn = contentPcCn.join(",");
            });
            var contentPcEn = [];
            _this18.fileListEn.map(function (res) {
              contentPcEn.push(res.response.voT.downloadUrl);
              _this18.addform.contentPcEn = contentPcEn.join(",");
            });
            var contentPcJa = [];
            _this18.fileListJa.map(function (res) {
              contentPcJa.push(res.response.voT.downloadUrl);
              _this18.addform.contentPcJa = contentPcJa.join(",");
            });
            var contentMobileCn = [];
            _this18.fileListMcn.map(function (res) {
              contentMobileCn.push(res.response.voT.downloadUrl);
              _this18.addform.contentMobileCn = contentMobileCn.join(",");
            });
            var contentMobileEn = [];
            _this18.fileListMen.map(function (res) {
              contentMobileEn.push(res.response.voT.downloadUrl);
              _this18.addform.contentMobileEn = contentMobileEn.join(",");
            });
            var contentMobileJa = [];
            _this18.fileListMja.map(function (res) {
              contentMobileJa.push(res.response.voT.downloadUrl);
              _this18.addform.contentMobileJa = contentMobileJa.join(",");
            });
            // 表单验证通过之后的操作
            //   // this.isRepeat = true;
            //   this.isDisable = false;
            (0, _product.addList)(_this18.addform).then(function (res) {
              if (res.success) {
                _this18.$message({
                  type: "success",
                  message: "添加成功",
                  center: true
                });
                _this18.$store.dispatch("tagsView/delView", _this18.$route).then(function () {
                  _this18.$nextTick(function () {
                    _this18.$router.replace({
                      path: "/product/productbuy",
                      query: {
                        line: _this18.$route.query.line,
                        type: _this18.$route.query.type,
                        cur: _this18.$route.query.cur
                      }
                    });
                  });
                });
              } else {
                _this18.$message({
                  type: "warning",
                  message: res.errorMsg,
                  center: true
                });
              }
            });
          } else {
            _this18.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    }
  }
};