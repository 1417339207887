var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderSales" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("受理编号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.serialNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "serialNumber", $$v)
                  },
                  expression: "form.serialNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("设备号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.equipmentNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "equipmentNumber", $$v)
                  },
                  expression: "form.equipmentNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("订单号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("处理状态")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.processingStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "processingStatus", $$v)
                    },
                    expression: "form.processingStatus",
                  },
                },
                _vm._l(_vm.processingStatu, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("保内/保外")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.types, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit",
                      value: "/after/sale/repair/add",
                      expression: "'/after/sale/repair/add'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/after/sale/repair/update",
                                expression: "'/after/sale/repair/update'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/amend.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.change(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        scope.row.processingStatus == "0" ||
                        scope.row.processingStatus == "1"
                          ? _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/after/sale/repair/verify/price",
                                    expression:
                                      "'/after/sale/repair/verify/price'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "验价",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/verifyPrice.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.verifyPrices(scope.row)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.processingStatus == "1"
                          ? _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/after/sale/repair/ship",
                                    expression: "'/after/sale/repair/ship'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "发货",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/car.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.repairShips(scope.row)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (
                          scope.row.processingStatus == "3" ||
                          scope.row.processingStatus == "2"
                            ? false
                            : true
                        )
                          ? _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/after/sale/repair/revoke",
                                    expression: "'/after/sale/repair/revoke'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "撤销",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/repeal.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.revoke(scope.row)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        scope.row.processingStatus == "3"
                          ? _c(
                              "el-tooltip",
                              {
                                directives: [
                                  {
                                    name: "permit",
                                    rawName: "v-permit",
                                    value: "/after/sale/repair/unrevoke",
                                    expression: "'/after/sale/repair/unrevoke'",
                                  },
                                ],
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "解除撤销",
                                  placement: "bottom",
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/unrevoke.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.unrevoke(scope.row)
                                    },
                                  },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "受理编号",
              width: "120",
              "show-overflow-tooltip": "",
              prop: "serialNumber",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "设备号",
              width: "150",
              "show-overflow-tooltip": "",
              prop: "equipmentNumber",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "产品型号",
              "show-overflow-tooltip": "",
              prop: "productType",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单号",
              width: "170",
              "show-overflow-tooltip": "",
              prop: "orderNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "渠道",
              "show-overflow-tooltip": "",
              prop: "dealer",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "用户名",
              "show-overflow-tooltip": "",
              prop: "userName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "手机号",
              "show-overflow-tooltip": "",
              prop: "telephone",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "地址",
              width: "120",
              "show-overflow-tooltip": "",
              prop: "address",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "问题原因",
              "show-overflow-tooltip": "",
              prop: "badReason",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "数量",
              "show-overflow-tooltip": "",
              prop: "count",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "保内/保外",
              width: "100",
              "show-overflow-tooltip": "",
              prop: "type",
              formatter: _vm.typeFlag,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "处理状态",
              "show-overflow-tooltip": "",
              prop: "processingStatus",
              formatter: _vm.status,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "处理方式",
              "show-overflow-tooltip": "",
              prop: "processingMethod",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "维修费用",
              "show-overflow-tooltip": "",
              prop: "repairPrice",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "物流单号",
              "show-overflow-tooltip": "",
              prop: "logisticsNumber",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "备注",
              "show-overflow-tooltip": "",
              prop: "remark",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申请人",
              "show-overflow-tooltip": "",
              prop: "createUser",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "申请时间",
              "show-overflow-tooltip": "",
              prop: "createTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "修改人",
              "show-overflow-tooltip": "",
              prop: "updateUser",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "修改时间",
              "show-overflow-tooltip": "",
              prop: "updateTime",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next,jumper",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.addClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.addForm,
                rules: _vm.addrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备号",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    on: { blur: _vm.changeEquipment },
                    model: {
                      value: _vm.addForm.equipmentNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "equipmentNumber", $$v)
                      },
                      expression: "addForm.equipmentNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addForm.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "orderNo", $$v)
                      },
                      expression: "addForm.orderNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "渠道",
                    "label-width": _vm.formLabelWidth,
                    prop: "dealer",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addForm.dealer,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "dealer", $$v)
                        },
                        expression: "addForm.dealer",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品型号",
                    "label-width": _vm.formLabelWidth,
                    prop: "productType",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addForm.productType,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "productType", $$v)
                      },
                      expression: "addForm.productType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "数量",
                    "label-width": _vm.formLabelWidth,
                    prop: "count",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addForm.count,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "count", $$v)
                      },
                      expression: "addForm.count",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "用户名",
                    "label-width": _vm.formLabelWidth,
                    prop: "userName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "userName", $$v)
                      },
                      expression: "addForm.userName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "手机号",
                    "label-width": _vm.formLabelWidth,
                    prop: "telephone",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addForm.telephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "telephone", $$v)
                      },
                      expression: "addForm.telephone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地址",
                    "label-width": _vm.formLabelWidth,
                    prop: "address",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addForm.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "address", $$v)
                      },
                      expression: "addForm.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "保内/保外",
                    "label-width": _vm.formLabelWidth,
                    prop: "type",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.addForm, "type", $$v)
                        },
                        expression: "addForm.type",
                      },
                    },
                    _vm._l(_vm.types, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "问题原因",
                    "label-width": _vm.formLabelWidth,
                    prop: "badReason",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.addForm.badReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "badReason", $$v)
                      },
                      expression: "addForm.badReason",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.title == "修改"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "维修费用",
                        "label-width": _vm.formLabelWidth,
                        prop: "repairPrice",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.addForm.repairPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "repairPrice", $$v)
                          },
                          expression: "addForm.repairPrice",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "修改"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "处理方式",
                        "label-width": _vm.formLabelWidth,
                        prop: "processingMethod",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.addForm.processingMethod,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "processingMethod", $$v)
                          },
                          expression: "addForm.processingMethod",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.title == "修改"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "物流单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "logisticsNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.addForm.logisticsNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "logisticsNumber", $$v)
                          },
                          expression: "addForm.logisticsNumber",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.addForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addForm, "remark", $$v)
                      },
                      expression: "addForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.addClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "验价",
            visible: _vm.dialogFormVerifyVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVerifyVisible = $event
            },
            close: _vm.verifyClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "verifyForm",
              attrs: {
                model: _vm.verifyForm,
                rules: _vm.verifyrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "保内/保外",
                    "label-width": _vm.formLabelWidth,
                    prop: "type",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.verifyForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.verifyForm, "type", $$v)
                        },
                        expression: "verifyForm.type",
                      },
                    },
                    _vm._l(_vm.types, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "维修费用",
                    "label-width": _vm.formLabelWidth,
                    prop: "repairPrice",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.verifyForm.repairPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.verifyForm, "repairPrice", $$v)
                      },
                      expression: "verifyForm.repairPrice",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "问题原因",
                    "label-width": _vm.formLabelWidth,
                    prop: "badReason",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.verifyForm.badReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.verifyForm, "badReason", $$v)
                      },
                      expression: "verifyForm.badReason",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "处理方式",
                    "label-width": _vm.formLabelWidth,
                    prop: "processingMethod",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.verifyForm.processingMethod,
                      callback: function ($$v) {
                        _vm.$set(_vm.verifyForm, "processingMethod", $$v)
                      },
                      expression: "verifyForm.processingMethod",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.verifyForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.verifyForm, "remark", $$v)
                      },
                      expression: "verifyForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.verifyClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.verifySure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发货",
            visible: _vm.dialogFormRepairVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormRepairVisible = $event
            },
            close: _vm.repairClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "repairForm",
              attrs: {
                model: _vm.repairForm,
                rules: _vm.repairrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "logisticsNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.repairForm.logisticsNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.repairForm, "logisticsNumber", $$v)
                      },
                      expression: "repairForm.logisticsNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "处理方式",
                    "label-width": _vm.formLabelWidth,
                    prop: "processingMethod",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.repairForm.processingMethod,
                      callback: function ($$v) {
                        _vm.$set(_vm.repairForm, "processingMethod", $$v)
                      },
                      expression: "repairForm.processingMethod",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.repairForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.repairForm, "remark", $$v)
                      },
                      expression: "repairForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.repairClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.repairSure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.revokeTitle,
            visible: _vm.dialogFormRevokeVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormRevokeVisible = $event
            },
            close: _vm.revokeClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "revokeForm",
              attrs: {
                model: _vm.revokeForm,
                rules: _vm.revokerules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.revokeForm.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.revokeForm, "remark", $$v)
                      },
                      expression: "revokeForm.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.revokeClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.revokeSure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }