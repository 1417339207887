"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBlog = addBlog;
exports.dataManipulation = dataManipulation;
exports.delBlog = delBlog;
exports.getBlogById = getBlogById;
exports.queryBlog = queryBlog;
exports.updateBlog = updateBlog;
exports.updateLowerLine = updateLowerLine;
exports.updateRecommend = updateRecommend;
exports.updateSeq = updateSeq;
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
function queryBlog(data) {
  return (0, _request.default)({
    url: 'cms/manage/blog/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function upload(data) {
  return (0, _request.default)({
    url: 'cms/picture/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}
function addBlog(data) {
  return (0, _request.default)({
    url: 'cms/manage/blog',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function updateBlog(data) {
  return (0, _request.default)({
    url: 'cms/manage/blog',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function getBlogById(id) {
  return (0, _request.default)({
    url: 'cms/manage/blog/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
function updateLowerLine(data, id) {
  return (0, _request.default)({
    url: 'cms/manage/blog/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function updateSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/manage/blog/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function delBlog(id) {
  return (0, _request.default)({
    url: 'cms/manage/blog/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
function updateRecommend(data) {
  return (0, _request.default)({
    url: 'cms/manage/blog/recommend',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, line, updateTime, updateUser) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (updateUser != null && updateUser != "") {
        element.updateUser = updateUser;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
      if (line != null && line != "") {
        element.line = line;
      }
    }
  });
  return tableData;
}