"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _overallseo = require("../../api/overallseo.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      rules: {
        seoTitleCn: [{
          required: true,
          trigger: "blur"
        }],
        seoTitleEn: [{
          required: true,
          trigger: "blur"
        }],
        seoTitleJa: [{
          required: true,
          trigger: "blur"
        }],
        seoKeywordCn: [{
          required: true,
          trigger: "blur"
        }],
        seoKeywordEn: [{
          required: true,
          trigger: "blur"
        }],
        seoKeywordJa: [{
          required: true,
          trigger: "blur"
        }],
        seoDescriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        seoDescriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        seoDescriptionJa: [{
          required: true,
          trigger: "blur"
        }]
      },
      form: {
        pageNo: 1,
        pageSize: 10,
        pageSerial: ""
      },
      seoId: "",
      pageSerial: [],
      currentPage: 1,
      total: 0,
      isDisable: false,
      addform: {
        seoTitleCn: "",
        seoTitleEn: "",
        seoTitleJa: "",
        pageSerial: "",
        seoKeywordCn: "",
        seoKeywordEn: "",
        seoKeywordJa: "",
        seoDescriptionCn: "",
        seoDescriptionEn: "",
        seoDescriptionJa: ""
      },
      loading: false,
      tableData: [],
      multipleSelection: [],
      value: "",
      dialogFormVisible: false,
      addRules: [],
      formLabelWidth: "150px",
      dialogFormVisibles: false,
      changeRules: [],
      dialogVisible: false,
      limitNum: 1,
      isFlag: true
    };
  },
  created: function created() {
    //this.getList();
  },
  mounted: function mounted() {
    var _this = this;
    (0, _overallseo.searchSeolist)({
      name: "PAGE_SERIAL",
      pageNo: 1,
      pageSize: 10,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.pageSerial = res.voList;
    });
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      (0, _overallseo.querySeo)(this.form).then(function (res) {
        _this2.tableData = res.voList;
        _this2.loading = false;
        _this2.total = res.total;
      });
    },
    //搜索
    search: function search() {
      this.getList();
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.pageSerial = "";
      this.getList();
    },
    //新增
    add: function add() {
      this.dialogFormVisible = true;
      this.isDisable = false;
    },
    //新增关闭按钮
    addClose: function addClose(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisible = false;
    },
    //新增确定按钮
    sure: function sure(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this3.addform.pageSerial == "") {
            _this3.msgTip("warning", "请选择页面编号");
            return false;
          }
          _this3.isDisable = true;
          (0, _overallseo.addSeo)(_this3.addform).then(function (res) {
            if (res.success) {
              _this3.msgTip("success", "添加成功");
              _this3.getList();
              _this3.dialogFormVisible = false;
              _this3.$refs[formName].resetFields();
              (0, _overallseo.dataManipulation)(_this3.tableData);
            } else {
              _this3.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this3.isDisable = false;
            }
          });
        }
      });
    },
    //修改
    change: function change(v) {
      var _this4 = this;
      this.dialogFormVisibles = true;
      this.isDisable = false;
      (0, _overallseo.getSeoById)(v.id).then(function (res) {
        _this4.addform = res.voT;
        _this4.seoId = res.voT.id;
      });
    },
    //修改关闭按钮
    changeClose: function changeClose(formName) {
      this.$refs[formName].resetFields();
      this.dialogFormVisibles = false;
    },
    //修改确定按钮
    keep: function keep(formName) {
      var _this5 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this5.isDisable = true;
          (0, _overallseo.updateSeo)(_this5.addform, _this5.seoId).then(function (res) {
            if (res.success) {
              _this5.msgTip("success", "修改成功");
              _this5.getList();
              _this5.dialogFormVisibles = false;
              (0, _overallseo.dataManipulation)(_this5.tableData);
              _this5.$refs[formName].resetFields();
            } else {
              _this5.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this5.isDisable = false;
            }
          });
        }
      });
    },
    //删除
    dele: function dele(v) {
      var _this6 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _overallseo.delSeo)(v.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "操作成功");
            _this6.empty();
          } else {
            _this6.msgTip("warning", res.errorMsg);
          }
        });
      });
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}