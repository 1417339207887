var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _vm.userType == "cn"
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: require("../../assets/img/funny.png") },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.userType == "com" || _vm.userType == "cn-out"
                  ? _c("img", {
                      staticClass: "user-avatar",
                      attrs: {
                        src: "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("i", { staticClass: "el-icon-caret-bottom" }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [_c("el-dropdown-item", [_vm._v("主页")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { staticStyle: { "border-top": "0.5px solid #EBEEF5" } },
                    [
                      _c("span", { on: { click: _vm.preview } }, [
                        _vm._v("预览"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-item",
                    { staticStyle: { border: "0" }, attrs: { divided: "" } },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            display: "block",
                            padding: "0 20px",
                            "border-top": "0.5px solid #EBEEF5",
                          },
                          on: { click: _vm.logout },
                        },
                        [_vm._v("退出")]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }