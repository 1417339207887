"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aloneCode = aloneCode;
exports.changeCode = changeCode;
exports.deleCode = deleCode;
exports.generateCode = generateCode;
exports.getLists = getLists;
exports.searchCode = searchCode;
exports.searchRule = searchRule;
exports.sendCodes = sendCodes;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询优惠码(cms系统调用)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/promo/code/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除优惠码(cms系统调用)
function deleCode(data) {
  return (0, _request.default)({
    url: 'cms/promo/code',
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询优惠码可生成数量
function searchCode(data) {
  return (0, _request.default)({
    url: 'cms/promo/code/count',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改优惠码(cms系统调用)
function changeCode(data, id) {
  return (0, _request.default)({
    url: 'cms/promo/code/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//生成优惠码
function generateCode(data) {
  return (0, _request.default)({
    url: 'cms/promo/code/generate',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个优惠码
function aloneCode(id) {
  return (0, _request.default)({
    url: 'cms/promo/code/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//查询优惠规则
function searchRule(id) {
  return (0, _request.default)({
    url: 'cms/promo/rule/list/box/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//发送优惠码
function sendCodes(data, id) {
  return (0, _request.default)({
    url: 'cms/promo/code/send/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}