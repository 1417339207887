"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isDisable: false,
      value: "",
      // param: {
      //   name: "",
      //   value: "",
      //   valueMeaning:""
      // },
      rowid: "",
      options: [{
        value: '0',
        label: '产品型号'
      }, {
        value: '1',
        label: '封套编号'
      }, {
        value: '2',
        label: '笔编号'
      }],
      addskutype: "0",
      types: [],
      jacket: [],
      form: {
        name: "",
        remark: "",
        value: "",
        valueCn: "",
        valueEn: "",
        valueJa: "",
        inventoryCode: "",
        gstinCode: "",
        priceUsd: "",
        priceRmb: "",
        priceJpy: "",
        priceUsRatta: "",
        priceJpRatta: "",
        priceCnRatta: ""
      },
      forms: {
        name: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        valueMeaning: "",
        value: ''
      },
      forms1: {
        name: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        valueMeaning: "",
        value: ''
      },
      dialogFormVisible: false,
      dialogskuVisible: false,
      dialogVisible: false,
      typeSnVisible: true,
      typeFcVisible: false,
      typeSpVisible: false,
      tableData: [],
      formLabelWidth: "120px",
      total: 0,
      currentPage: 1,
      flag: false,
      loading: false,
      isRepeat: false,
      //添加
      addForm: {
        value: [{
          required: true,
          trigger: "blur"
        }],
        name: [{
          required: true,
          trigger: "blur"
        }],
        remark: [{
          required: true,
          trigger: "blur"
        }],
        valueCn: [{
          required: true,
          trigger: "blur"
        }],
        valueEn: [{
          required: true,
          trigger: "blur"
        }],
        valueJa: [{
          required: true,
          trigger: "blur"
        }],
        inventoryCode: [{
          required: true,
          trigger: "blur"
        }],
        gstinCode: [{
          required: true,
          trigger: "blur"
        }],
        priceUsd: [{
          required: true,
          trigger: "blur"
        }],
        priceRmb: [{
          required: true,
          trigger: "blur"
        }],
        priceJpy: [{
          required: true,
          trigger: "blur"
        }],
        priceUsRatta: [{
          required: true,
          trigger: "blur"
        }],
        priceJpRatta: [{
          required: true,
          trigger: "blur"
        }],
        priceCnRatta: [{
          required: true,
          trigger: "blur"
        }]
      },
      addskuForm: {
        value: [{
          required: true,
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getList();
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.types = res.voList;
    });
    this.getList();
    (0, _dictionary.searchDiclist)({
      name: "PART",
      pageNo: 1,
      pageSize: 1000,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      if (res.success) {
        var b = {};
        res.voList.forEach(function (obj) {
          var array = b[obj["name"]] || [];
          array.push(obj);
          b[obj["name"]] = array;
        });
        _this.newList = b["PART"].map(function (v) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
            value: v.value
          });
        });
        _this.jacket = _this.newList;
      }
    });
  },
  methods: {
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _dictionary.searchDiclist)(_this2.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.forms.pageNo = 1;
            _this2.currentPage = 1;
            _this2.getList();
          }
          _this2.currentrow = null;
          _this2.tableData = res.voList;
          _this2.total = res.total;
          _this2.loading = false;
          _this2.isRepeat = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    selectSkuTypeChanged: function selectSkuTypeChanged(value) {
      this.addskutype = value;
      if ('0' == value) {
        this.typeSnVisible = true;
        this.typeFcVisible = false;
        this.typeSpVisible = false;
      }
      if ('1' == value) {
        this.typeSnVisible = false;
        this.typeFcVisible = false;
        this.typeSpVisible = true;
      }
      if ('2' == value) {
        this.typeSnVisible = false;
        this.typeFcVisible = true;
        this.typeSpVisible = false;
      }
    },
    selectSkuChanged: function selectSkuChanged(value) {
      var _this3 = this;
      (0, _dictionary.getDiclist)(value).then(function (res) {
        _this3.form.value = res.value;
        _this3.form.name = 'US_SKU';
        _this3.form.remark = res.remark;
        _this3.form.valueCn = res.valueCn;
        _this3.form.valueEn = res.valueEn;
        _this3.form.valueJa = res.valueJa;
      });
    },
    //查询
    search: function search() {
      var _this4 = this;
      this.loading = true;
      this.forms.pageNo = 1;
      setTimeout(function () {
        (0, _dictionary.searchDiclist)(_this4.forms).then(function (res) {
          if (res.success) {
            _this4.tableData = res.voList;
            _this4.total = res.total;
            _this4.currentPage = 1;
            _this4.loading = false;
          }
        });
      }, 500);
    },
    //清空
    empty: function empty() {
      this.forms1.pageNo = 1;
      this.currentPage = 1;
      this.forms.name = "";
      this.forms.valueMeaning = "";
      this.forms.value = "";
      this.forms1.name = "";
      this.forms1.valueMeaning = "";
      this.forms1.value = "";
      this.getList();
    },
    //添加
    add: function add() {
      this.dialogFormVisible = true;
      this.isDisable = false;
    },
    sure: function sure() {
      var _this5 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this5.isRepeat = true;
          _this5.isDisable = true;
          (0, _dictionary.addDiclist)(_this5.form).then(function (res) {
            if (res.success) {
              _this5.$message({
                type: "success",
                message: "添加成功",
                center: true
              });
              _this5.dialogFormVisible = false;
              _this5.empty();
            } else {
              _this5.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this5.isRepeat = false;
              _this5.isDisable = false;
            }
          });
        } else {
          _this5.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //添加
    addSku: function addSku() {
      this.addskuForm.value = "";
      this.dialogskuVisible = true;
      this.isDisable = false;
    },
    suresku: function suresku() {
      var _this6 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this6.isRepeat = true;
          _this6.isDisable = true;
          (0, _dictionary.addDiclist)(_this6.form).then(function (res) {
            if (res.success) {
              _this6.$message({
                type: "success",
                message: "添加成功",
                center: true
              });
              _this6.dialogskuVisible = false;
              _this6.empty();
            } else {
              _this6.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this6.isRepeat = false;
              _this6.isDisable = false;
            }
          });
        } else {
          _this6.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //弹框关闭
    close: function close() {
      this.dialogFormVisible = false;
      this.dialogskuVisible = false;
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
    //点击获取这行数据
    getRow: function getRow(row) {
      this.currentrow = row;
      this.rowid = row.id;
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //修改
    amend: function amend() {
      var _this7 = this;
      if (this.currentrow == null) {
        this.msgTip("error", "请选择需要修改的数据");
      } else {
        this.dialogVisible = true;
        this.isDisable = false;
        (0, _dictionary.getDiclist)(this.rowid).then(function (res) {
          _this7.form.value = res.value;
          _this7.form.name = res.name;
          _this7.form.remark = res.remark;
          _this7.form.valueCn = res.valueCn;
          _this7.form.valueEn = res.valueEn;
          _this7.form.valueJa = res.valueJa;
          _this7.form.inventoryCode = res.inventoryCode;
          _this7.form.gstinCode = res.gstinCode;
          _this7.form.priceUsd = res.priceUsd;
          _this7.form.priceRmb = res.priceRmb;
          _this7.form.priceJpy = res.priceJpy;
          _this7.form.priceUsRatta = res.priceUsRatta;
          _this7.form.priceJpRatta = res.priceJpRatta;
          _this7.form.priceCnRatta = res.priceCnRatta;
        });
      }
    },
    save: function save() {
      var _this8 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          _this8.isRepeat = true;
          _this8.isDisable = true;
          (0, _dictionary.amendDiclist)(_this8.rowid, _this8.form).then(function (res) {
            if (res.success) {
              _this8.$message({
                type: "success",
                message: "修改成功",
                center: true
              });
              _this8.dialogVisible = false;
              (0, _dictionary.dataManipulation)(_this8.tableData, _this8.currentrow, _this8.form.remark, _this8.form.valueCn, _this8.form.valueEn, window.localStorage.getItem("name"), getDate());
              _this8.getList();
            } else {
              _this8.$message({
                type: "success",
                message: res.errorMsg,
                center: true
              });
              _this8.isRepeat = false;
              _this8.isDisable = false;
            }
          });
        } else {
          _this8.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //删除
    delet: function delet() {
      var _this9 = this;
      if (this.currentrow == null) {
        this.msgTip("error", "请选择需要删除的数据！");
      } else {
        this.$confirm("您是否要删除当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this9.isRepeat = true;
          (0, _dictionary.deleteDiclist)(_this9.rowid).then(function (res) {
            if (res.success) {
              _this9.$message({
                type: "success",
                message: "删除成功",
                center: true
              });
              _this9.getList();
            } else {
              _this9.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this9.isRepeat = false;
            }
          });
        });
      }
    },
    indexMethod: function indexMethod(index) {
      return index + 1;
    },
    handleClick: function handleClick(row) {}
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}