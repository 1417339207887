var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderRepair" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("是否收费")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.areThereFees,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "areThereFees", $$v)
                    },
                    expression: "form.areThereFees",
                  },
                },
                _vm._l(_vm.isareThereFees, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("设备号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.equipmentNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "equipmentNumber", $$v)
                  },
                  expression: "form.equipmentNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("受理编号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.serialNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "serialNumber", $$v)
                  },
                  expression: "form.serialNumber",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("类型")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                },
                _vm._l(_vm.types, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/repair/add",
                  expression: "'/order/repair/add'",
                },
              ],
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.add } },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "受理编号",
              "show-overflow-tooltip": "",
              prop: "serialNumber",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "设备号",
              "show-overflow-tooltip": "",
              prop: "equipmentNumber",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单号",
              "show-overflow-tooltip": "",
              prop: "orderNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "类型",
              "show-overflow-tooltip": "",
              prop: "type",
              formatter: _vm.typeFlag,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "是否收费",
              "show-overflow-tooltip": "",
              prop: "areThereFees",
              formatter: _vm.areFlag,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "不良故障",
              "show-overflow-tooltip": "",
              prop: "badMalfunction",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "不良原因",
              "show-overflow-tooltip": "",
              prop: "badReason",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "维修措施",
              "show-overflow-tooltip": "",
              prop: "measure",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "物品清单",
              "show-overflow-tooltip": "",
              prop: "resList",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "维修时间",
              "show-overflow-tooltip": "",
              prop: "repairTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "备注",
              "show-overflow-tooltip": "",
              prop: "remark",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/repair/change",
                                expression: "'/order/repair/change'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/amend.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.change(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/repair/delete",
                                expression: "'/order/repair/delete'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/delete.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.dele(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next,jumper",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addTitle,
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.addClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.addrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备号",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentNumber",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.equipmentNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "equipmentNumber", $$v)
                      },
                      expression: "addform.equipmentNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "type",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addform.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "type", $$v)
                        },
                        expression: "addform.type",
                      },
                    },
                    _vm._l(_vm.types, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否收费",
                    "label-width": _vm.formLabelWidth,
                    prop: "areThereFees",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addform.areThereFees,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "areThereFees", $$v)
                        },
                        expression: "addform.areThereFees",
                      },
                    },
                    _vm._l(_vm.isareThereFees, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "不良故障",
                    "label-width": _vm.formLabelWidth,
                    prop: "badMalfunction",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.badMalfunction,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "badMalfunction", $$v)
                      },
                      expression: "addform.badMalfunction",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "不良原因",
                    "label-width": _vm.formLabelWidth,
                    prop: "badReason",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.badReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "badReason", $$v)
                      },
                      expression: "addform.badReason",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "维修措施",
                    "label-width": _vm.formLabelWidth,
                    prop: "measure",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.measure,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "measure", $$v)
                      },
                      expression: "addform.measure",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "维修时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "repairTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "default-time": "00:00:00",
                      placeholder: "选择",
                    },
                    model: {
                      value: _vm.addform.repairTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "repairTime", $$v)
                      },
                      expression: "addform.repairTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物品清单",
                    "label-width": _vm.formLabelWidth,
                    prop: "resList",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.resList,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "resList", $$v)
                      },
                      expression: "addform.resList",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "remark", $$v)
                      },
                      expression: "addform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.addClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sure },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }