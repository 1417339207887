"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _part = require("../../api/part.js");
var _upload = require("../../api/upload.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formLabelWidth: "130px",
      dialogImageUrl: "",
      dialogVisible: false,
      form: {
        colourUrl: "",
        homeFlag: "1",
        line: "2",
        productNumber: "",
        productType: [],
        seq: "",
        serialNumber: "",
        letteringFlag: "1",
        chooseUrl: "",
        weight: "",
        priceCn: "",
        priceCnUnit: "",
        priceEn: "",
        priceEnUnit: "",
        priceJa: "",
        priceJaUnit: "",
        type: "",
        valueCn: "",
        valueEn: "",
        valueJa: "",
        discountCn: "",
        discountEn: "",
        discountJa: "",
        startTime: '',
        endTime: ''
      },
      addRules: {
        seq: [{
          required: true,
          trigger: "blur"
        }],
        line: [{
          required: true,
          trigger: "change"
        }],
        productNumber: [{
          required: true,
          trigger: "change"
        }],
        productType: [{
          required: true,
          trigger: "change"
        }],
        serialNumber: [{
          required: true,
          trigger: "change"
        }],
        letteringFlag: [{
          required: true,
          trigger: "change"
        }],
        weight: [{
          required: true,
          trigger: "blur"
        }],
        priceCn: [{
          required: true,
          trigger: "change"
        }],
        priceCnUnit: [{
          required: true,
          trigger: "change"
        }],
        priceEn: [{
          required: true,
          trigger: "change"
        }],
        priceEnUnit: [{
          required: true,
          trigger: "change"
        }],
        priceJa: [{
          required: true,
          trigger: "change"
        }],
        priceJaUnit: [{
          required: true,
          trigger: "change"
        }],
        type: [{
          required: true,
          trigger: "change"
        }]
      },
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "下线",
        type: "1"
      }],
      homeFlag: [{
        name: "是",
        type: "0"
      }, {
        name: "否",
        type: "1"
      }],
      letterFlag: [{
        name: "是",
        type: "0"
      }, {
        name: "否",
        type: "1"
      }],
      price: [{
        value: "RMB",
        label: "RMB"
      }, {
        value: "USD",
        label: "USD"
      }, {
        value: "JPY",
        label: "JPY"
      }],
      types: [{
        value: "0",
        label: "无优惠"
      }, {
        value: "1",
        label: "减免优惠"
      }, {
        value: "2",
        label: "折扣优惠"
      }],
      productType: [],
      imageUrl1: "",
      imageUrl2: "",
      imageUrl3: "",
      imageUrl4: "",
      imageUrl5: "",
      rules: [],
      newList: [],
      proList: [],
      serial: [],
      product: []
    };
  },
  created: function created() {
    this.loadAll();
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.productType = res.voList;
    });
  },
  methods: {
    loadAll: function loadAll() {
      var _this2 = this;
      (0, _dictionary.searchDiclist)({
        name: "",
        pageNo: 1,
        pageSize: 500,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this2.newList = b["PART_SERIES"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this2.serial = _this2.newList;
          _this2.proList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this2.product = _this2.proList;
        }
      });
    },
    // 上传文件之前的钩子
    // beforeUploadPicture1(file) {
    //   if (
    //     !(
    //       file.type === "image/png" ||
    //       file.type === "image/gif" ||
    //       file.type === "image/jpg" ||
    //       file.type === "image/jpeg"
    //     )
    //   ) {
    //     this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
    //     return;
    //   }
    //   let size = file.size / 1024 / 1024 / 2;
    //   if (size > 2) {
    //     this.msgTip("warning", "图片大小必须小于2M");
    //     return;
    //   }
    //   let fd = new FormData();
    //   fd.append("file", file); //传文件
    //   uploads(fd).then(res => {
    //     this.imageUrl1 = res.voT.downloadUrl;
    //   });
    //   return false; //屏蔽了action的默认上传
    // },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this3 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var size = file.size / 1024 / 1024 / 2;
      if (size > 2) {
        this.msgTip("warning", "图片大小必须小于2M");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this3.imageUrl2 = res.voT.downloadUrl.split("===")[0];
        _this3.form.chooseUrl = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    submit: function submit() {
      var _this4 = this;
      // this.form.colourUrl = this.imageUrl1;

      if (!/(^[1-9]\d*$)/.test(this.form.seq) && this.form.seq !== "") {
        this.msgTip("warning", "序号只能为正整数");
        return false;
      }
      if (this.form.colourUrl == "") {
        this.msgTip("warning", "请上传色块图");
        return false;
      }
      if (this.form.chooseUrl == "") {
        this.msgTip("warning", "请上传配件选择图");
        return false;
      }
      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          _this4.isDisable = false;
          _this4.form.productType = _this4.form.productType.toString();
          _this4.form.productNumber = _this4.form.productNumber.split("_")[0];
          _this4.form.serialNumber = _this4.form.serialNumber.split("_")[0];
          (0, _part.addList)(_this4.form).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "添加成功");
              _this4.$store.dispatch("tagsView/delView", _this4.$route).then(function () {
                _this4.$nextTick(function () {
                  _this4.$router.replace({
                    path: "/parts/partsMess",
                    query: {
                      cur: _this4.$route.query.cur,
                      productNumber: _this4.$route.query.productNumber,
                      serialNumber: _this4.$route.query.serialNumber,
                      homeFlag: _this4.$route.query.homeFlag,
                      line: _this4.$route.query.line,
                      productType: _this4.$route.query.productType
                    }
                  });
                });
              });
            } else {
              _this4.msgTip("error", res.errorMsg);
              _this4.isDisable = false;
              _this4.form.productType = [];
            }
          });
        } else {
          _this4.msgTip("warning", "请填写完整");
          return false;
        }
      });
    }
  }
};