var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-container" }, [
    _c("div", { staticClass: "welcome" }, [_vm._v("WELCOME")]),
    _vm._v(" "),
    _vm.userType == "cn"
      ? _c("div", { staticClass: "title" }, [
          _vm._v("欢迎进入CMS管理系统 - 国内"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.userType == "com"
      ? _c("div", { staticClass: "title" }, [
          _vm._v("欢迎进入CMS管理系统 - 国外"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.userType == "cn-out"
      ? _c("div", { staticClass: "title" }, [_vm._v("欢迎进入CMS管理系统")])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }