var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("优惠码")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.promoCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "promoCode", $$v)
                  },
                  expression: "form.promoCode",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("优惠规则")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.promoRule,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "promoRule", $$v)
                    },
                    expression: "form.promoRule",
                  },
                },
                _vm._l(_vm.promoRules, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.promoRuleName, value: item.promoRule },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("发送状态")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.isSend,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "isSend", $$v)
                    },
                    expression: "form.isSend",
                  },
                },
                _vm._l(_vm.isSends, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("使用状态")]),
              _vm._v(" "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.form.useState,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "useState", $$v)
                    },
                    expression: "form.useState",
                  },
                },
                _vm._l(_vm.useStates, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.valueCn, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("订单号")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.form.orderNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "orderNo", $$v)
                  },
                  expression: "form.orderNo",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("有效期开始")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "yyyy/MM/dd/HH:mm:ss",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    placeholder: "选择日期时间",
                    type: "datetime",
                    editable: false,
                  },
                  model: {
                    value: _vm.form.startDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "startDate", $$v)
                    },
                    expression: "form.startDate",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("h5", [_vm._v("有效期结束")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "block" },
              [
                _c("el-date-picker", {
                  attrs: {
                    format: "yyyy/MM/dd/HH:mm:ss",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    placeholder: "选择日期时间",
                    type: "datetime",
                    editable: false,
                  },
                  model: {
                    value: _vm.form.endDate,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "endDate", $$v)
                    },
                    expression: "form.endDate",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", { on: { click: _vm.search } }, [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", { on: { click: _vm.empty } }, [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/promo/code/add",
                  expression: "'/promo/code/add'",
                },
              ],
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/generate.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { on: { click: _vm.add } }, [_vm._v("生成优惠码")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/promo/code/delete",
                  expression: "'/promo/code/delete'",
                },
              ],
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/delete.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { on: { click: _vm.dele } }, [_vm._v("删除")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "优惠码",
              "show-overflow-tooltip": "",
              prop: "promoCode",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "优惠规则",
              "show-overflow-tooltip": "",
              prop: "promoRuleName",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "发放状态",
              "show-overflow-tooltip": "",
              prop: "isSend",
              formatter: _vm.isSend,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "使用状态",
              "show-overflow-tooltip": "",
              prop: "useState",
              formatter: _vm.useState,
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "有效期开始",
              "show-overflow-tooltip": "",
              prop: "startDate",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "有效期结束",
              "show-overflow-tooltip": "",
              prop: "endDate",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "可用次数",
              "show-overflow-tooltip": "",
              prop: "useTimes",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "已用次数",
              "show-overflow-tooltip": "",
              prop: "usedTimes",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "共享链接",
              "show-overflow-tooltip": "",
              prop: "shareLink",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "邮箱号",
              "show-overflow-tooltip": "",
              prop: "email",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "订单号",
              "show-overflow-tooltip": "",
              prop: "orderNo",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "备注",
              "show-overflow-tooltip": "",
              prop: "remark",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作人",
              "show-overflow-tooltip": "",
              prop: "updateUser",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "操作时间",
              "show-overflow-tooltip": "",
              prop: "updateTime",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/promo/code/grant",
                                expression: "'/promo/code/grant'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "发放",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/send.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.grant(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/promo/code/change",
                                expression: "'/promo/code/change'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/iconfont/amend.png"),
                                alt: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.change(scope.row)
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "生成优惠码",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.addRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否自定义优惠码",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeCodes },
                      model: {
                        value: _vm.isCode,
                        callback: function ($$v) {
                          _vm.isCode = $$v
                        },
                        expression: "isCode",
                      },
                    },
                    _vm._l(_vm.isCodes, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.codeflag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "优惠码",
                        "label-width": _vm.formLabelWidth,
                        prop: "promoCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", maxlength: "8" },
                        model: {
                          value: _vm.addform.promoCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "promoCode", $$v)
                          },
                          expression: "addform.promoCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "优惠规则",
                    "label-width": _vm.formLabelWidth,
                    prop: "promoRule",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeNum },
                      model: {
                        value: _vm.addform.promoRule,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "promoRule", $$v)
                        },
                        expression: "addform.promoRule",
                      },
                    },
                    _vm._l(_vm.promoRule, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: {
                          label: item.promoRuleName,
                          value: item.promoRule,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: { label: "总数量", "label-width": _vm.formLabelWidth },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.totalCount,
                      callback: function ($$v) {
                        _vm.totalCount = $$v
                      },
                      expression: "totalCount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "可用数量",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.availableCount,
                      callback: function ($$v) {
                        _vm.availableCount = $$v
                      },
                      expression: "availableCount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "生成数量",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "10" },
                    on: { change: _vm.changeGenerateCount },
                    model: {
                      value: _vm.addform.generateCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "generateCount", $$v)
                      },
                      expression: "addform.generateCount",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "可使用次数",
                    "label-width": _vm.formLabelWidth,
                    prop: "useTimes",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "10" },
                    model: {
                      value: _vm.addform.useTimes,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "useTimes", $$v)
                      },
                      expression: "addform.useTimes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "有效期开始",
                    "label-width": _vm.formLabelWidth,
                    prop: "startDate",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      format: "yyyy/MM/dd/HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.addform.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "startDate", $$v)
                      },
                      expression: "addform.startDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "有效期结束",
                    "label-width": _vm.formLabelWidth,
                    prop: "endDate",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      format: "yyyy/MM/dd/HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "['23:59:59']",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.addform.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "endDate", $$v)
                      },
                      expression: "addform.endDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发放",
            visible: _vm.dialogFormVisibles,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibles = $event
            },
            close: _vm.closes,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "sendform",
              attrs: {
                model: _vm.sendform,
                rules: _vm.sendRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "优惠码",
                    "label-width": _vm.formLabelWidth,
                    prop: "promoCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.sendform.promoCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendform, "promoCode", $$v)
                      },
                      expression: "sendform.promoCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "语言",
                    "label-width": _vm.formLabelWidth,
                    prop: "language",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.sendform.language,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendform, "language", $$v)
                        },
                        expression: "sendform.language",
                      },
                    },
                    _vm._l(_vm.languages, function (item) {
                      return _c("el-option", {
                        key: item.language,
                        attrs: { label: item.label, value: item.language },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "共享链接",
                    "label-width": _vm.formLabelWidth,
                    prop: "isLink",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.link },
                      model: {
                        value: _vm.sendform.isLink,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendform, "isLink", $$v)
                        },
                        expression: "sendform.isLink",
                      },
                    },
                    [_vm._v("是")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      on: { change: _vm.link },
                      model: {
                        value: _vm.sendform.isLink,
                        callback: function ($$v) {
                          _vm.$set(_vm.sendform, "isLink", $$v)
                        },
                        expression: "sendform.isLink",
                      },
                    },
                    [_vm._v("否")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.flag
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "商品",
                            "label-width": _vm.formLabelWidth,
                            prop: "isPackage",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "0" },
                              on: { change: _vm.isPack },
                              model: {
                                value: _vm.sendform.isPackage,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sendform, "isPackage", $$v)
                                },
                                expression: "sendform.isPackage",
                              },
                            },
                            [_vm._v("套装")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: { change: _vm.isPack },
                              model: {
                                value: _vm.sendform.isPackage,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sendform, "isPackage", $$v)
                                },
                                expression: "sendform.isPackage",
                              },
                            },
                            [_vm._v("配件")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.flag1
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "产品",
                            "label-width": _vm.formLabelWidth,
                            prop: "productNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.sendform.productNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sendform, "productNo", $$v)
                                },
                                expression: "sendform.productNo",
                              },
                            },
                            _vm._l(_vm.type, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "封套",
                            "label-width": _vm.formLabelWidth,
                            prop: "coverNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.sendform.coverNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sendform, "coverNo", $$v)
                                },
                                expression: "sendform.coverNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return !item.value.includes("SP") &&
                                !item.value.includes("SR")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "笔",
                            "label-width": _vm.formLabelWidth,
                            prop: "penNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.sendform.penNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sendform, "penNo", $$v)
                                },
                                expression: "sendform.penNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return item.value.includes("SP")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.flag2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "配件",
                            "label-width": _vm.formLabelWidth,
                            prop: "productNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.sendform.productNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.sendform, "productNo", $$v)
                                },
                                expression: "sendform.productNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "备注", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.sendform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendform, "remark", $$v)
                      },
                      expression: "sendform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "邮箱", "label-width": _vm.formLabelWidth } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.sendform.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.sendform, "email", $$v)
                      },
                      expression: "sendform.email",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closes } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sendCode },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改",
            visible: _vm.dialogForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogForm = $event
            },
            close: _vm.changeclose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "changeform",
              attrs: {
                model: _vm.changeform,
                rules: _vm.changeRules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "可使用次数",
                    "label-width": _vm.formLabelWidth,
                    prop: "useTimes",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.changeform.useTimes,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "useTimes", $$v)
                      },
                      expression: "changeform.useTimes",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "有效期开始",
                    "label-width": _vm.formLabelWidth,
                    prop: "startDate",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      format: "yyyy/MM/dd/HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.changeform.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "startDate", $$v)
                      },
                      expression: "changeform.startDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "有效期结束",
                    "label-width": _vm.formLabelWidth,
                    prop: "endDate",
                  },
                },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      type: "datetime",
                      format: "yyyy/MM/dd/HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: "选择日期时间",
                    },
                    model: {
                      value: _vm.changeform.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "endDate", $$v)
                      },
                      expression: "changeform.endDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      type: "textarea",
                      maxlength: "100",
                    },
                    model: {
                      value: _vm.changeform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "remark", $$v)
                      },
                      expression: "changeform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.changeclose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keep },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }