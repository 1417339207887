var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "changetrends" }, [
    _c(
      "div",
      { staticClass: "addhead" },
      [
        _c(
          "el-form",
          {
            ref: "form",
            staticClass: "form",
            attrs: {
              model: _vm.form,
              rules: _vm.formRules,
              "status-icon": true,
              "hide-required-asterisk": "",
              "show-message": false,
            },
          },
          [
            _c(
              "el-form-item",
              {
                attrs: { label: "产品型号", "label-width": _vm.formLabelWidth },
              },
              [
                _c("el-input", {
                  attrs: {
                    autocomplete: "off",
                    type: "text",
                    disabled: "",
                    maxlength: "50",
                  },
                  model: {
                    value: _vm.form.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "type", $$v)
                    },
                    expression: "form.type",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: { label: "系列编号", "label-width": _vm.formLabelWidth },
              },
              [
                _c("el-input", {
                  attrs: {
                    autocomplete: "off",
                    type: "text",
                    disabled: "",
                    maxlength: "50",
                  },
                  model: {
                    value: _vm.form.serialNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "serialNumber", $$v)
                    },
                    expression: "form.serialNumber",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "价格区间中文",
                  "label-width": _vm.formLabelWidth,
                  prop: "priceRangeCn",
                },
              },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off", type: "text", maxlength: "50" },
                  model: {
                    value: _vm.form.priceRangeCn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "priceRangeCn", $$v)
                    },
                    expression: "form.priceRangeCn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "价格区间英文",
                  "label-width": _vm.formLabelWidth,
                  prop: "priceRangeEn",
                },
              },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off", type: "text", maxlength: "50" },
                  model: {
                    value: _vm.form.priceRangeEn,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "priceRangeEn", $$v)
                    },
                    expression: "form.priceRangeEn",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: {
                  label: "价格区间日文",
                  "label-width": _vm.formLabelWidth,
                  prop: "priceRangeJa",
                },
              },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off", type: "text", maxlength: "50" },
                  model: {
                    value: _vm.form.priceRangeJa,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "priceRangeJa", $$v)
                    },
                    expression: "form.priceRangeJa",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "btn" },
      [
        _c(
          "el-button",
          {
            attrs: { type: "primary", disabled: _vm.isDisable },
            on: { click: _vm.submit },
          },
          [_vm._v("提交")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }