"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _part = require("../../api/part.js");
var _dictionary = require("../../api/dictionary.js");
var _index = require("../../utils/index.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        homeFlag: "",
        line: "",
        pageNo: 1,
        pageSize: 10,
        productNumber: "",
        productType: "",
        serialNumber: "",
        letteringFlag: ""
      },
      forms: {
        homeFlag: "",
        line: "",
        pageNo: 1,
        pageSize: 10,
        productNumber: "",
        productType: "",
        serialNumber: "",
        letteringFlag: ""
      },
      tableData: [],
      homeFlag: [{
        name: "是",
        type: "0"
      }, {
        name: "否",
        type: "1"
      }],
      letterFlag: [{
        name: "是",
        type: "0"
      }, {
        name: "否",
        type: "1"
      }],
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      productType: [],
      loading: false,
      total: 0,
      currentPage: this.$route.query.cur ? this.$route.query.cur : 1,
      isShow: false,
      newList: [],
      proList: [],
      product: [],
      serial: [],
      multipleSelection: [],
      formLabelWidth: "130px",
      dialogFormVisibles: false,
      changesform: {
        idList: [],
        priceCn: "",
        priceCnUnit: "",
        priceEn: "",
        priceEnUnit: "",
        priceJa: "",
        priceJaUnit: "",
        // productNumber: "",
        weight: "",
        type: "",
        valueCn: "",
        valueEn: "",
        valueJa: "",
        discountCn: "",
        discountEn: "",
        discountJa: "",
        startTime: '',
        endTime: ''
      },
      price: [{
        value: "RMB",
        label: "RMB"
      }, {
        value: "USD",
        label: "USD"
      }, {
        value: "JPY",
        label: "JPY"
      }],
      salltype: [{
        value: "0",
        label: "无优惠"
      }, {
        value: "1",
        label: "减免优惠"
      }, {
        value: "2",
        label: "折扣优惠"
      }],
      isDisable: false,
      rules: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        productNumber: [{
          required: false,
          trigger: "blur"
        }],
        coverSerialNumber: [{
          required: false,
          trigger: "blur"
        }],
        penNumber: [{
          required: false,
          trigger: "blur"
        }],
        penSerialNumber: [{
          required: false,
          trigger: "blur"
        }],
        priceCn: [{
          required: false,
          trigger: "blur"
        }],
        priceEn: [{
          required: false,
          trigger: "blur"
        }],
        priceEnUnit: [{
          required: false,
          trigger: "blur"
        }],
        priceJa: [{
          required: false,
          trigger: "blur"
        }],
        priceJaUnit: [{
          required: false,
          trigger: "blur"
        }]
      }, "productNumber", [{
        required: false,
        trigger: "blur"
      }]), "weight", [{
        required: false,
        trigger: "blur"
      }]), "type", [{
        required: false,
        trigger: "blur"
      }]), "valueCn", [{
        required: false,
        trigger: "blur"
      }]), "valueEn", [{
        required: false,
        trigger: "blur"
      }]), "valueJa", [{
        required: false,
        trigger: "blur"
      }]), "discountCn", [{
        required: false,
        trigger: "blur"
      }]), "discountEn", [{
        required: false,
        trigger: "blur"
      }]), "discountJa", [{
        required: false,
        trigger: "blur"
      }])
    };
  },
  created: function created() {},
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.cur) {
      this.form.productNumber = this.$route.query.productNumber, this.form.serialNumber = this.$route.query.serialNumber, this.form.homeFlag = this.$route.query.homeFlag, this.form.line = this.$route.query.line, this.form.productType = this.$route.query.productType;
      this.form.pageNo = this.$route.query.cur;
      this.forms.productNumber = this.$route.query.productNumber, this.forms.serialNumber = this.$route.query.serialNumber, this.forms.homeFlag = this.$route.query.homeFlag, this.forms.line = this.$route.query.line, this.forms.productType = this.$route.query.productType;
      this.forms.pageNo = this.$route.query.cur;
    }
    this.getList();
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.productType = res.voList;
    });
    this.loadAll();
  },
  methods: {
    // //模糊搜索
    //   querySearch(queryString, cb, list){
    //     console.log(queryString, cb, list)
    //     this.loadAll();
    //     var restaurants = this[list];
    //     var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
    //     // 调用 callback 返回建议列表的数据
    //     cb(results);
    //   },
    //   createFilter(queryString) {
    //     return (restaurant) => {
    //       return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    //     };
    //   },
    //   handleSelect(e, type){
    //     this.form[type] = e.value
    //   },
    loadAll: function loadAll() {
      var _this2 = this;
      (0, _dictionary.searchDiclist)({
        name: "",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this2.newList = b["PART_SERIES"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value + "_" + v.valueCn
            });
          });
          _this2.serial = _this2.newList;
          _this2.proList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value + "_" + v.valueCn
            });
          });
          _this2.product = _this2.proList;
        }
      });
    },
    //优惠类型判断
    types: function types(row) {
      if (row.type == '0') {
        return '无优惠';
      } else if (row.type == '1') {
        return '减免优惠';
      } else if (row.type == '2') {
        return '折扣优惠';
      }
    },
    //列表
    getList: function getList() {
      var _this3 = this;
      this.loading = true;
      setTimeout(function () {
        var form = (0, _objectSpread2.default)({}, _this3.form);
        form.productNumber = form.productNumber.split("_")[0];
        form.serialNumber = form.serialNumber.split("_")[0];
        _this3.forms.productNumber = form.productNumber;
        _this3.forms.serialNumber = form.serialNumber;
        (0, _part.getLists)(_this3.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this3.forms.pageNo = 1;
            _this3.currentPage = 1;
            (0, _part.getLists)(_this3.forms).then(function (res) {
              _this3.tableData = res.voList;
              _this3.total = res.total;
              _this3.loading = false;
            });
          }
          _this3.tableData = res.voList;
          _this3.total = res.total;
          _this3.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //判断类型
    stateFormat: function stateFormat(row) {
      if (row.homeFlag === "0") {
        return "是";
      } else {
        return "否";
      }
    },
    letterFormat: function letterFormat(row) {
      if (row.letteringFlag === "0") {
        return "是";
      } else {
        return "否";
      }
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line === "1") {
        return "预览";
      } else {
        return "下线";
      }
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "addmess",
        query: {
          cur: this.currentPage,
          productNumber: this.form.productNumber,
          serialNumber: this.form.serialNumber,
          homeFlag: this.form.homeFlag,
          line: this.form.line,
          productType: this.form.productType
        }
      });
    },
    //查询
    search: function search() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.homeFlag = this.form.homeFlag;
      this.forms.letteringFlag = this.form.letteringFlag;
      this.forms.productNumber = this.form.productNumber;
      this.forms.productType = this.form.productType;
      this.forms.homeFlag = this.form.homeFlag;
      this.forms.serialNumber = this.form.serialNumber;
      this.forms.line = this.form.line;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.homeFlag = "";
      this.currentPage = 1;
      this.form.line = "";
      this.form.productNumber = "";
      this.form.productType = "";
      this.form.serialNumber = "";
      this.form.letteringFlag = "";
      this.forms.homeFlag = "";
      this.forms.line = "";
      this.forms.productNumber = "";
      this.forms.productType = "";
      this.forms.serialNumber = "";
      this.forms.letteringFlag = "";
      this.forms.pageNo = 1;
      window.location.href = window.location.href.split("?")[0];
      this.getList();
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changemess",
        query: {
          id: id,
          cur: this.currentPage,
          productNumber: this.form.productNumber,
          serialNumber: this.form.serialNumber,
          homeFlag: this.form.homeFlag,
          line: this.form.line,
          productType: this.form.productType
        }
      });
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //批量修改
    allchange: function allchange() {
      var _this4 = this;
      if (this.multipleSelection.length < 1) {
        this.msgTip("error", "请选择一条数据");
        return;
      } else {
        this.dialogFormVisibles = true;
        this.changesform.idList = [];
        this.multipleSelection.map(function (res) {
          _this4.changesform.idList.push(res.id);
        });
      }
    },
    //批量修改确定按钮
    keep: function keep() {
      var _this5 = this;
      this.isDisable = true;
      this.$refs.changesform.validate(function (valid) {
        if (valid) {
          (0, _part.changeall)(_this5.changesform).then(function (res) {
            if (res.success) {
              _this5.msgTip("success", "批量修改成功");
              _this5.getList();
              _this5.dialogFormVisibles = false;
              _this5.isDisable = false;
              _this5.changesform.idList = [];
              _this5.$refs.changesform.resetFields();
            } else {
              _this5.msgTip("warning", res.errorMsg);
              _this5.isDisable = false;
            }
          });
        }
      });
    },
    //批量修改取消按钮
    changesClose: function changesClose() {
      this.dialogFormVisibles = false;
      this.$refs.changesform.resetFields();
      this.changesform.startTime = '';
      this.changesform.endTime = '';
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/part/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this6 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
      } else if (!/(^[1-9]\d*$)/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
      } else {
        (0, _part.changeSeq)({
          seq: val.seq
        }, val.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "修改成功");
            _this6.isShow = false;
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //上线
    on: function on(row) {
      var _this7 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _part.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "上线成功");
            _this7.getList();
          } else {
            _this7.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    preview: function preview(row) {
      var _this8 = this;
      if (row.line == "1") {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _part.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "操作成功");
            _this8.getList();
          } else {
            _this8.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    off: function off(row) {
      var _this9 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _part.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this9.msgTip("success", "下线成功");
            _this9.getList();
          } else {
            _this9.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this10 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _part.delePart)(id).then(function (res) {
          if (res.success) {
            _this10.msgTip("success", "删除成功");
            _this10.getList();
          } else {
            _this10.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    cell: function cell(row, column, _cell, event) {
      // console.log(row,'1')
      // console.log(column,'2')
      // if(column.label =='序号'){
      //     row.name = '444'
      // }
      // console.log(cell,'3')
      // console.log(event,'4')
    }
  }
};