"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneMess = aloneMess;
exports.changeMess = changeMess;
exports.changeOn = changeOn;
exports.changeSeq = changeSeq;
exports.deleMess = deleMess;
exports.getLists = getLists;
exports.getUpload = getUpload;
exports.search = search;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询固件说明书信息
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/firmware/manual/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增固件说明书信息
function addList(data) {
  return (0, _request.default)({
    url: 'cms/firmware/manual',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改固件说明书上线下线
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/firmware/manual/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据语言和类型查询固件说明书列表信息
function search(data) {
  return (0, _request.default)({
    url: 'cms/firmware/manual/list/type',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改固件说明书序号
function changeSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/firmware/manual/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个固件说明书信息
function aloneMess(id) {
  return (0, _request.default)({
    url: 'cms/firmware/manual/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改固件说明书信息
function changeMess(data, id) {
  return (0, _request.default)({
    url: 'cms/firmware/manual/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除固件说明书信息
function deleMess(id) {
  return (0, _request.default)({
    url: 'cms/firmware/manual/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//上传固件包
function getUpload(data) {
  return (0, _request.default)({
    url: 'base/upload/apply',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}