"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _vueQuillEditor = require("vue-quill-editor");
var Quill = _interopRequireWildcard(require("quill"));
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
var _abouts = require("../../api/abouts.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  data: function data() {
    return {
      value: "",
      id: "",
      formDate: [],
      form: {
        descriptionMobileCn: "",
        descriptionMobileEn: "",
        descriptionMobileJa: "",
        descriptionPcCn: "",
        descriptionPcEn: "",
        descriptionPcJa: "",
        titleCn: "",
        titleEn: "",
        titleJa: ""
        // seoDescriptionCn: "",
        // seoDescriptionEn: "",
        // seoKeywordCn: "",
        // seoKeywordEn: ""
      },
      queryDate: {
        pageNo: 1,
        pageSize: 10,
        id: ""
      },
      formLabelWidth: "120px",
      formRules: {
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        descriptionMobileCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionMobileEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionMobileJa: [{
          required: true,
          trigger: "blur"
        }],
        descriptionPcCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionPcEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionPcJa: [{
          required: true,
          trigger: "blur"
        }]
      },
      isDisable: false,
      editorOption: {
        placeholder: "请输入文本信息..."
      },
      addRange1: [],
      addRange2: [],
      addRange3: [],
      addRange4: [],
      addRange5: [],
      addRange6: [],
      Rangeflag1: false,
      Rangeflag2: false,
      Rangeflag3: false,
      Rangeflag4: false,
      Rangeflag5: false,
      Rangeflag6: false
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.getList();
  },
  mounted: function mounted() {
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor1.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor2.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor3.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor4.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor5.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor6.quill.getModule("toolbar").addHandler("image", this.imgHandler);
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      this.queryDate.id = this.id;
      (0, _abouts.queryAboutAll)(this.queryDate).then(function (res) {
        _this.form = res.voList[0];
        _this.loading = false;
      });
    },
    // 点击图片ICON触发事件
    imgHandler: function imgHandler(state) {
      this.addRange1 = this.$refs.myQuillEditor1.quill.getSelection();
      if (this.addRange1 != "" && this.addRange1 != null) {
        this.Rangeflag1 = true;
      }
      this.addRange2 = this.$refs.myQuillEditor2.quill.getSelection();
      if (this.addRange2 != "" && this.addRange2 != null) {
        this.Rangeflag2 = true;
      }
      this.addRange3 = this.$refs.myQuillEditor3.quill.getSelection();
      if (this.addRange3 != "" && this.addRange3 != null) {
        this.Rangeflag3 = true;
      }
      this.addRange4 = this.$refs.myQuillEditor4.quill.getSelection();
      if (this.addRange4 != "" && this.addRange4 != null) {
        this.Rangeflag4 = true;
      }
      this.addRange5 = this.$refs.myQuillEditor5.quill.getSelection();
      if (this.addRange5 != "" && this.addRange5 != null) {
        this.Rangeflag5 = true;
      }
      this.addRange6 = this.$refs.myQuillEditor6.quill.getSelection();
      if (this.addRange6 != "" && this.addRange6 != null) {
        this.Rangeflag6 = true;
      }
      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        (0, _upload.uploads)(fd).then(function (res) {
          if (_this2.Rangeflag1) {
            _this2.addRange1 = _this2.$refs.myQuillEditor1.quill.getSelection();
            _this2.$refs.myQuillEditor1.quill.insertEmbed(_this2.addRange1 !== null ? _this2.addRange1.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag1 = false;
          }
          if (_this2.Rangeflag2) {
            _this2.addRange2 = _this2.$refs.myQuillEditor2.quill.getSelection();
            _this2.$refs.myQuillEditor2.quill.insertEmbed(_this2.addRange2 !== null ? _this2.addRange2.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag2 = false;
          }
          if (_this2.Rangeflag3) {
            _this2.addRange3 = _this2.$refs.myQuillEditor3.quill.getSelection();
            _this2.$refs.myQuillEditor3.quill.insertEmbed(_this2.addRange3 !== null ? _this2.addRange3.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag3 = false;
          }
          if (_this2.Rangeflag4) {
            _this2.addRange4 = _this2.$refs.myQuillEditor4.quill.getSelection();
            _this2.$refs.myQuillEditor4.quill.insertEmbed(_this2.addRange4 !== null ? _this2.addRange4.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag4 = false;
          }
          if (_this2.Rangeflag5) {
            _this2.addRange5 = _this2.$refs.myQuillEditor5.quill.getSelection();
            _this2.$refs.myQuillEditor5.quill.insertEmbed(_this2.addRange5 !== null ? _this2.addRange5.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag5 = false;
          }
          if (_this2.Rangeflag6) {
            _this2.addRange6 = _this2.$refs.myQuillEditor6.quill.getSelection();
            _this2.$refs.myQuillEditor6.quill.insertEmbed(_this2.addRange6 !== null ? _this2.addRange6.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag6 = false;
          }
        });
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //提交
    submit: function submit() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          if (_this3.form.descriptionMobileCn == "" || _this3.form.descriptionMobileEn == "" || _this3.form.descriptionMobileJa == "" || _this3.form.descriptionPcCn == "" || _this3.form.descriptionPcEn == "" || _this3.form.descriptionPcJa == "") {
            _this3.msgTip("warning", "所有的描述都不能为空");
            return false;
          }
          _this3.isDisable = true;
          (0, _abouts.updateAbout)(_this3.form, _this3.id).then(function (res) {
            if (res.success) {
              _this3.msgTip("success", "修改成功");
              _this3.$store.dispatch("tagsView/delView", _this3.$route).then(function () {
                _this3.$nextTick(function () {
                  _this3.$router.replace({
                    path: "/abouts",
                    query: {
                      cur: _this3.$route.query.cur,
                      line: _this3.$route.query.line
                    }
                  });
                });
              });
            } else {
              _this3.msgTip("error", res.errorMsg);
              // this.isRepeat = false;
              _this3.isDisable = false;
            }
          });
        } else {
          _this3.msgTip("warning", "请填写完整");
          return false;
        }
      });
    }
  }
};