"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var echarts = _interopRequireWildcard(require("echarts"));
var _charts = require("../../api/charts.js");
//
//
//
//

require("echarts/theme/macarons"); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "43.2%"
    },
    height: {
      type: String,
      default: "500px"
    }
  },
  data: function data() {
    return {
      chart: null,
      form: {
        dealer: "",
        endPayTime: "",
        startPayTime: ""
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this2 = this;
      this.chart = echarts.init(this.$el);
      var productList = [];
      var countList = [];
      (0, _charts.searchActive)().then(function (res) {
        res.voT.dropboxCounts.map(function (item) {
          productList.push(item.name);
          countList.push(item.value);
          _this2.chart.setOption({
            // tooltip: {
            //   trigger: 'axis',
            //   axisPointer: { // 坐标轴指示器，坐标轴触发有效
            //     type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
            //   }
            // },
            // grid: {
            //   top: 10,
            //   left: '2%',
            //   right: '2%',
            //   bottom: '3%',
            //   containLabel: false
            // },
            //  title: {
            //   text: '第三方应用授权图表',
            //   left:'43%',
            //   bottom:'10',
            //  textStyle: {
            //   color: '#c19674'
            // }
            // },
            xAxis: [{
              type: "category",
              data: productList
              // axisTick: {
              //   alignWithLabel: false
              // }
            }],
            yAxis: [{
              type: "value",
              axisTick: {
                show: false
              }
            }],
            series: [{
              //   name: 'pageA',
              type: "bar",
              stack: "vistors",
              barWidth: "20%",
              data: countList,
              animationDuration: animationDuration,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    //开启显示
                    position: "top" //在上方显示
                  },
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "#c19674"
                  }, {
                    offset: 0.5,
                    color: "#785f61"
                  }, {
                    offset: 1,
                    color: "#513232"
                  }])
                }
              }
            }]
          });
        });
      });
    }
  }
};