"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _orderInfo = require("../../api/orderInfo.js");
var _timers = require("timers");
var _dictionary = require("../../api/dictionary.js");
var _addorder = require("../../api/addorder.js");
var _methods; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      types: [],
      form: {
        orderNo: "",
        status: "",
        contactEmail: "",
        contactTelephone: "",
        pageNo: 1,
        pageSize: 10,
        serialNumber: "",
        orderType: "",
        payEquipment: "",
        startPayTime: "",
        endPayTime: "",
        payType: "",
        serial: "",
        promoCode: '',
        dealer: '',
        tradeNo: ''
      },
      callBacks: {
        payId: "",
        outTradeNo: ""
      },
      payrule: {
        outTradeNo: [{
          required: true,
          message: "交易号不能为空",
          trigger: "blur"
        }],
        payId: [{
          required: true,
          message: "支付方式不能为空",
          trigger: "change"
        }]
      },
      changerule: {
        //  orderNo: [{ required: true,  trigger: "blur" }],
        //  unit: [{ required: true, trigger: "change" }],
        logisticsPrice: [{
          required: true,
          trigger: "change"
        }]
        //remark: [{ required: true, trigger: "change" }],
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      tableDatas: [],
      dialogFormVisible: false,
      dialogForm: false,
      isDisable: false,
      formLabelWidth: "120px",
      type: [{
        value: "0",
        valueCn: "预售"
      }, {
        value: "1",
        valueCn: "订购"
      }],
      equipments: [{
        value: "1",
        valueCn: "PC"
      }, {
        value: "2",
        valueCn: "移动端"
      }],
      payTypes: [],
      loadings: false,
      copyData: null,
      dialogForms: false,
      changeform: {
        logisticsPrice: '',
        orderNo: '',
        remark: '',
        unit: ''
      },
      multipleSelection: [],
      orderNoList: [],
      detailList: [],
      dialogsForm: false,
      detailform: {},
      dealers: [],
      dialogFormVisibles: false,
      salesform: {
        costType: '',
        customerRequest: '',
        deliverType: '0',
        exchangeCustomerList: [],
        orderLineCustomerList: [],
        price: '',
        orderNo: '',
        remark: '',
        returnType: '',
        count: '',
        unit: '',
        productNo: '',
        productNos: '',
        cont: ''
      },
      saleOrderNo: '',
      typeflag: true,
      coverflag: false,
      penflag: false,
      refillflag: false,
      typeflags: true,
      coverflags: false,
      penflags: false,
      refillflags: false,
      product: '1',
      saletype: [],
      jacket: [],
      productName: '',
      productNo: '',
      salesproduct: '',
      salesproductName: '',
      sureLoading: false,
      exchangeproduct: '1',
      showflag: false,
      salesproductNo: '',
      productNos: '',
      orderLineNewList: [],
      orderLineOldList: [],
      flagList: [],
      searchInfoList: [{
        flag: true,
        value: '渠道'
      }, {
        flag: true,
        value: '订单号'
      }, {
        flag: true,
        value: '订单序号'
      }, {
        flag: false,
        value: '交易号'
      }, {
        flag: false,
        value: '优惠码'
      }, {
        flag: true,
        value: '类型'
      }, {
        flag: false,
        value: '联系人邮箱'
      }, {
        flag: false,
        value: '联系人手机号'
      }, {
        flag: false,
        value: '流水号'
      }, {
        flag: true,
        value: '订单状态'
      }, {
        flag: true,
        value: '支付方式'
      }, {
        flag: false,
        value: '下单设备'
      }, {
        flag: true,
        value: '支付起始时间'
      }, {
        flag: true,
        value: '支付截止时间'
      }],
      // 列的配置化对象，存储配置信息
      visible: false,
      showColumn: {
        operate: true,
        dealer: true,
        orderNo: true,
        serial: true,
        tradeNo: true,
        orderType: true,
        unit: true,
        price: true,
        productTotalPrice: true,
        discountPrice: true,
        contactEmail: true,
        contactTelephone: true,
        status: true,
        promoCode: true,
        payType: true,
        payEquipment: true,
        payCounts: true,
        payBrowser: true,
        repeatPayType: true,
        serialNumber: true,
        pendingCode: true,
        batchNo: true,
        deliverCycle: true,
        payTime: true,
        updateTime: true,
        remark: true
      },
      dialogFormSearch: false
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (localStorage.getItem("columnInfoSet")) {
      this.showColumn = JSON.parse(localStorage.getItem("columnInfoSet"));
    }
    if (localStorage.getItem('infoList')) {
      this.flagList = JSON.parse(localStorage.getItem('infoList'));
    } else {
      this.flagList = this.searchInfoList;
    }
    (0, _dictionary.searchDic)({
      name: "DEALER",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.dealers = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "ORDER_STATUS",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.types = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "PAY_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.payTypes = res.voList;
    });
    var that = this;
    var end = new Date();
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    // that.form.startPayTime = that.formatDate(start);
    // that.form.endPayTime = that.formatDate(end);
    this.getList();
  },
  methods: (_methods = {
    saveColumn: function saveColumn() {
      localStorage.setItem("columnInfoSet", JSON.stringify(this.showColumn));
      this.visible = false;
    },
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      (0, _timers.setTimeout)(function () {
        (0, _orderInfo.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _orderInfo.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCopy: function handleCopy(e) {
      var row = e.row,
        $index = e.$index;
      var url = '';
      url = 'https://supernote.com.cn/#/order/detail?id=';
      return url + this.tableData[$index].orderNo;
    },
    clipboardSuccessHandler: function clipboardSuccessHandler(_ref) {
      var value = _ref.value,
        event = _ref.event;
      this.$message({
        type: 'success',
        message: '复制成功',
        center: true
      });
    },
    //判断支付设备
    equipment: function equipment(row) {
      if (row.payEquipment == "1") {
        return "PC";
      } else {
        return "移动端";
      }
    },
    //设置搜索框
    setSearch: function setSearch() {},
    //查询
    search: function search() {
      this.getList();
    },
    //格式化日期
    formatDate: function formatDate(date) {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    //查询子菜单
    rowExpand: function rowExpand(row) {
      var _this3 = this;
      (0, _orderInfo.searchCode)(row.orderNo).then(function (res) {
        _this3.loadings = true;
        if (res.success) {
          _this3.tableData.map(function (item) {
            if (row.id == item.id) {
              item.children = res.voList;
              _this3.loadings = false;
            }
          });
        } else {
          _this3.$message({
            type: "error",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    //清空
    empty: function empty() {
      this.form.orderNo = "";
      this.form.status = "";
      this.form.contactEmail = "";
      this.form.contactTelephone = "";
      this.form.serialNumber = "";
      this.form.orderType = "";
      this.form.payEquipment = "";
      this.form.payType = "";
      this.form.startPayTime = "";
      this.form.endPayTime = "";
      this.form.serial = "";
      this.form.promoCode = "";
      this.form.dealer = "";
      this.form.tradeNo = '';
      this.form.pageNo = 1;
      this.currentPage = 1;
      this.getList();
    },
    //类型判断
    ordertype: function ordertype(row) {
      if (row.orderType == "1") {
        return "订购";
      } else {
        return "预售";
      }
    }
  }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "setSearch", function setSearch() {
    this.dialogFormSearch = true;
  }), "searchSure", function searchSure() {
    localStorage.setItem('infoList', []);
    localStorage.setItem('infoList', JSON.stringify(this.flagList));
    this.empty();
    this.dialogFormSearch = false;
  }), "searchClose", function searchClose() {
    this.dialogFormSearch = false;
  }), "emailReissue", function emailReissue(v) {
    var _this4 = this;
    this.$confirm("您确定要邮件补发吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      (0, _orderInfo.changeEmail)(v.orderNo).then(function (res) {
        if (res.success) {
          _this4.$message({
            type: 'success',
            message: '操作成功',
            center: true
          });
          _this4.getList();
        } else {
          _this4.$message({
            type: 'error',
            message: res.errorMsg,
            center: true
          });
        }
      });
    });
  }), "derive", function derive() {
    var _this5 = this;
    this.loading = true;
    (0, _orderInfo.orderInfoExport)(this.form).then(function (res) {
      var data = new Blob([res], {
        type: "application/vnd.ms-excel"
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = "OrderInfo.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(data);
      _this5.loading = false;
    });
  }), "emsderive", function emsderive() {
    var _this6 = this;
    this.loading = true;
    (0, _orderInfo.emsExport)(this.form).then(function (res) {
      var data = new Blob([res], {
        type: "application/vnd.ms-excel"
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = "EMS订单导入.xls";
      anchor.click();
      window.URL.revokeObjectURL(data);
      _this6.loading = false;
    });
  }), "details", function details() {
    var _this7 = this;
    if (this.multipleSelection.length > 0) {
      this.orderNoList = [];
      this.multipleSelection.map(function (res) {
        _this7.orderNoList.push(res.orderNo);
      });
      (0, _orderInfo.searchList)({
        orderNoList: this.orderNoList
      }).then(function (item) {
        if (item.success) {
          _this7.detailList = item.voList;
          _this7.dialogsForm = true;
        } else {
          _this7.dialogsForm = false;
          _this7.$message({
            type: 'error',
            message: item.errorMsg,
            center: true
          });
        }
      });
    } else {
      this.$message({
        type: 'warning',
        message: '请选择至少一条数据',
        center: true
      });
    }
  }), "detailClose", function detailClose() {
    this.dialogsForm = false;
  }), "callBack", function callBack() {
    this.dialogForm = true;
  }), "logisticsClose", function logisticsClose() {
    this.dialogForm = false;
    this.$refs.callBacks.resetFields();
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "keeps", function keeps() {
    var _this8 = this;
    this.$refs.callBacks.validate(function (valid) {
      if (valid) {
        (0, _orderInfo.orderPay)(_this8.callBacks).then(function (res) {
          _this8.isDisable = true;
          if (res.success) {
            _this8.dialogForm = false;
            _this8.isDisable = false;
            _this8.$message({
              type: 'success',
              message: '回调成功',
              center: true
            });
            _this8.$refs.callBacks.resetFields();
            _this8.getList();
          } else {
            _this8.isDisable = false;
            _this8.$message({
              type: 'error',
              message: res.errorMsg,
              center: true
            });
          }
        });
      }
    });
  }), "change", function change(row) {
    var _this9 = this;
    this.dialogForms = true;
    (0, _orderInfo.orderDetail)(row.orderNo).then(function (res) {
      _this9.changeform = res.voT;
    });
  }), "changeClose", function changeClose() {
    this.dialogForms = false;
  }), "sure", function sure() {
    var _this10 = this;
    console.log(this.changeform.logisticsPrice, '123123');
    this.$refs.changeform.validate(function (valid) {
      if (valid) {
        (0, _orderInfo.changePrice)({
          logisticsPrice: _this10.changeform.logisticsPrice,
          orderNo: _this10.changeform.orderNo,
          remark: _this10.changeform.remark,
          unit: _this10.changeform.unit
        }).then(function (res) {
          if (res.success) {
            _this10.$message({
              type: 'success',
              message: '修改成功',
              center: true
            });
            _this10.dialogForms = false;
            _this10.getList();
          } else {
            _this10.$message({
              type: 'error',
              message: res.errorMsg,
              center: true
            });
          }
        });
      }
    });
  }), "orderApply", function orderApply(row) {
    var _this11 = this;
    this.$confirm("您是否要申请退款？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      (0, _orderInfo.returnMoney)({
        orderNo: row.orderNo
      }).then(function (res) {
        if (res.success) {
          _this11.$message({
            type: 'success',
            message: '操作成功',
            center: true
          });
          _this11.getList();
        } else {
          _this11.$message({
            type: 'error',
            message: res.errorMsg,
            center: true
          });
        }
      });
    });
  }), "isNo", function isNo(v) {
    var _this12 = this;
    if (v == "1") {
      this.typeflag = true;
      this.coverflag = false;
      this.penflag = false;
      this.refillflag = false;
      (0, _addorder.Diclist)({
        name: "PRODUCT_TYPE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this12.saletype = res.voList;
      });
    } else if (v == "2") {
      this.exportFlag = false;
      this.coverflag = true;
      this.typeflag = false;
      this.penflag = false;
      this.refillflag = false;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this12.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this12.jacket = _this12.newList;
        }
      });
    } else if (v == "3") {
      this.exportFlag = false;
      this.coverflag = false;
      this.typeflag = false;
      this.penflag = true;
      this.refillflag = false;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this12.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this12.jacket = _this12.newList;
        }
      });
    } else if (v == '4') {
      this.coverflag = false;
      this.typeflag = false;
      this.penflag = false;
      this.refillflag = true;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this12.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this12.jacket = _this12.newList;
        }
      });
    }
  }), "exchangeNo", function exchangeNo(v) {
    var _this13 = this;
    if (v == "1") {
      this.salesform.productNo = "";
      this.typeflags = true;
      this.coverflags = false;
      this.penflags = false;
      this.refillflags = false;
      (0, _addorder.Diclist)({
        name: "PRODUCT_TYPE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this13.type = res.voList;
      });
    } else if (v == "2") {
      this.salesform.productNo = "";
      this.exportFlags = false;
      this.coverflags = true;
      this.typeflags = false;
      this.penflags = false;
      this.refillflags = false;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this13.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this13.jacket = _this13.newList;
        }
      });
    } else if (v == "3") {
      this.salesform.productNo = "";
      this.exportFlag = false;
      this.coverflags = false;
      this.typeflags = false;
      this.penflags = true;
      this.refillflags = false;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this13.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this13.jacket = _this13.newList;
        }
      });
    } else if (v == '4') {
      this.exportFlag = false;
      this.coverflags = false;
      this.typeflags = false;
      this.penflags = false;
      this.refillflags = true;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this13.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this13.jacket = _this13.newList;
        }
      });
    }
  }), "changeProduct", function changeProduct(data) {
    var value = data.value,
      label = data.label;
    this.productNo = value;
    this.productName = label;
  }), "changeProducts", function changeProducts(data) {
    var value = data.value,
      label = data.label;
    this.salesproduct = value;
    this.salesproductName = label;
  }), "changeOrder", function changeOrder(v) {
    var _this14 = this;
    this.dialogFormVisibles = true;
    (0, _addorder.Diclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this14.saletype = res.voList;
    });
    this.saleOrderNo = v.orderNo;
    this.salesform.remark = v.remark;
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "salesClose", function salesClose() {
    this.dialogFormVisibles = false;
    this.productNos = '';
    this.salesproductNo = '';
    this.orderLineNewList = [];
    this.orderLineOldList = [];
  }), "salesAdd", function salesAdd() {
    if (this.productNos == "") {
      this.$message({
        type: "error",
        message: "请填写完整",
        center: true
      });
    } else {
      this.showflag = true;
      var newArrs = [];
      this.equipmentNumberLists.map(function (item, index) {
        newArrs.push(item.text);
      });
      this.salesform.orderLineNewList.push({
        productName: this.productName,
        productNo: this.productNo
      });
      this.salesform.productNo = '';
    }
  }), "exchangeAdd", function exchangeAdd() {
    if (this.exchangeproduct == "") {
      this.$message({
        type: "error",
        message: "请填写完整",
        center: true
      });
    } else {
      this.showflag = true;
      this.salesform.orderLineOldList.push({
        productName: this.salesproductName,
        productNo: this.salesproduct
      });
      this.salesform.productNos = '';
    }
  }), "salesRemove", function salesRemove(index) {
    this.salesform.orderLineCustomerList.splice(index, 1);
  }), "exchangeRemove", function exchangeRemove(index) {
    this.salesform.exchangeCustomerList.splice(index, 1);
  }), "changeSure", function changeSure() {
    var _this15 = this;
    if (this.saleOrderNo == '') {
      this.$message({
        type: "warning",
        message: "请填写完整",
        center: true
      });
    } else {
      this.sureLoading = true;
      this.orderLineOldList.push(this.productNos);
      this.orderLineNewList.push(this.salesproductNo);
      (0, _orderInfo.changeOrder)({
        orderLineNewList: this.orderLineNewList,
        orderLineOldList: this.orderLineOldList,
        orderNo: this.saleOrderNo,
        remark: this.salesform.remark
      }).then(function (res) {
        if (res.success) {
          _this15.$message({
            type: 'success',
            message: '修改成功',
            center: true
          });
          _this15.dialogFormVisibles = false;
          _this15.getList();
          _this15.sureLoading = false;
          _this15.orderLineNewList = [];
          _this15.orderLineOldList = [];
          _this15.productNos = '';
          _this15.salesproductNo = '';
        } else {
          _this15.$message({
            type: 'error',
            message: res.errorMsg,
            center: true
          });
          _this15.sureLoading = false;
          _this15.orderLineOldList = [];
          _this15.orderLineNewList = [];
        }
      });
    }
  }))
};