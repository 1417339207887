"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
var _charts = require("../../api/charts.js");
//
//
//
//

require("echarts/theme/macarons"); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "50%"
    },
    height: {
      type: String,
      default: "800px"
    },
    countryArr: {
      type: Array
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    countryArr: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(val, oldVal) {
        var _this = this;
        console.log(val.length, '787878');
        if (val.length > 0) {
          var productList = [];
          var countList = [];
          val.map(function (item) {
            productList.push(item.country);
            countList.push(item.count);
            _this.chart.setOption({
              title: {
                text: "国家-销量图表(排名前10)",
                textStyle: {
                  color: "#83bff6"
                },
                top: "20px",
                left: "20px"
              },
              xAxis: [{
                type: "category",
                data: productList,
                axisLabel: {
                  interval: 0,
                  rotate: 30
                }
              }],
              yAxis: [{
                type: "value"
              }],
              series: [{
                //name: 'pageA',
                type: "bar",
                //   stack: 'vistors',
                data: countList,
                barWidth: "70%",
                animationDuration: animationDuration,
                itemStyle: {
                  normal: {
                    label: {
                      show: true,
                      //开启显示
                      position: "top" //在上方显示
                    },
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: "#83bff6"
                    }, {
                      offset: 0.5,
                      color: "#188df0"
                    }, {
                      offset: 1,
                      color: "#188df0"
                    }])
                  }
                }
              }]
            });
          });
        }
      },
      deep: true //true 深度监听
    }
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this3 = this;
      this.chart = echarts.init(this.$el);
      if (this.countryArr.length > 0) {
        var productList = [];
        var countList = [];
        this.countryArr.map(function (item) {
          productList.push(item.country);
          countList.push(item.count);
          _this3.chart.setOption({
            title: {
              text: "国家-销量图表(排名前10)",
              textStyle: {
                color: "#83bff6"
              },
              top: "20px",
              left: "20px"
            },
            xAxis: [{
              type: "category",
              data: productList,
              axisLabel: {
                interval: 0,
                rotate: 30
              }
            }],
            yAxis: [{
              type: "value"
            }],
            series: [{
              //name: 'pageA',
              type: "bar",
              //   stack: 'vistors',
              data: countList,
              barWidth: "70%",
              animationDuration: animationDuration,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    //开启显示
                    position: "top" //在上方显示
                  },
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: "#83bff6"
                  }, {
                    offset: 0.5,
                    color: "#188df0"
                  }, {
                    offset: 1,
                    color: "#188df0"
                  }])
                }
              }
            }]
          });
        });
      }
    }
  }
};