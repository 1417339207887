import { render, staticRenderFns } from "./changeBlog.vue?vue&type=template&id=155dc155&scoped=true"
import script from "./changeBlog.vue?vue&type=script&lang=js"
export * from "./changeBlog.vue?vue&type=script&lang=js"
import style0 from "./changeBlog.vue?vue&type=style&index=0&id=155dc155&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "155dc155",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/www/prod-cn-snote-cms/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('155dc155')) {
      api.createRecord('155dc155', component.options)
    } else {
      api.reload('155dc155', component.options)
    }
    module.hot.accept("./changeBlog.vue?vue&type=template&id=155dc155&scoped=true", function () {
      api.rerender('155dc155', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/blog/changeBlog.vue"
export default component.exports