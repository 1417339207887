"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _packagePrice = require("../../api/packagePrice.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return (0, _defineProperty2.default)({
      form: {
        coverSerialNumber: "",
        penSerialNumber: "",
        pageNo: 1,
        pageSize: 10,
        productNumber: "",
        penNumber: '',
        coverNumber: ''
      },
      price: [{
        value: "RMB",
        label: "RMB"
      }, {
        value: "USD",
        label: "USD"
      }, {
        value: "JPY",
        label: "JPY"
      }],
      salltype: [{
        value: "0",
        label: "无优惠"
      }, {
        value: "1",
        label: "减免优惠"
      }, {
        value: "2",
        label: "折扣优惠"
      }],
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      addform: {
        coverNumber: "",
        coverSerialNumber: "",
        penNumber: "",
        penSerialNumber: "",
        priceCn: "",
        priceCnUnit: "",
        priceEn: "",
        priceEnUnit: "",
        priceJa: "",
        priceJaUnit: "",
        productNumber: "",
        weight: "",
        type: '',
        valueCn: '',
        valueEn: '',
        valueJa: '',
        discountCn: '',
        discountEn: '',
        discountJa: '',
        startTime: '',
        endTime: ''
      },
      rules: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        productNumber: [{
          required: false,
          trigger: "blur"
        }],
        coverSerialNumber: [{
          required: false,
          trigger: "blur"
        }],
        penNumber: [{
          required: false,
          trigger: "blur"
        }],
        penSerialNumber: [{
          required: false,
          trigger: "blur"
        }],
        priceCn: [{
          required: false,
          trigger: "blur"
        }],
        priceEn: [{
          required: false,
          trigger: "blur"
        }],
        priceEnUnit: [{
          required: false,
          trigger: "blur"
        }],
        priceJa: [{
          required: false,
          trigger: "blur"
        }],
        priceJaUnit: [{
          required: false,
          trigger: "blur"
        }]
      }, "productNumber", [{
        required: false,
        trigger: "blur"
      }]), "weight", [{
        required: false,
        trigger: "blur"
      }]), "type", [{
        required: true,
        trigger: "blur"
      }]), "valueCn", [{
        required: false,
        trigger: "blur"
      }]), "valueEn", [{
        required: false,
        trigger: "blur"
      }]), "valueJa", [{
        required: false,
        trigger: "blur"
      }]), "discountCn", [{
        required: false,
        trigger: "blur"
      }]), "discountEn", [{
        required: false,
        trigger: "blur"
      }]), "discountJa", [{
        required: false,
        trigger: "blur"
      }]),
      changeform: {
        idList: [],
        coverNumber: "",
        coverSerialNumber: "",
        penNumber: "",
        penSerialNumber: "",
        priceCn: "",
        priceCnUnit: "",
        priceEn: "",
        priceEnUnit: "",
        priceJa: "",
        priceJaUnit: "",
        productNumber: "",
        weight: "",
        type: '',
        valueCn: '',
        valueEn: '',
        valueJa: '',
        discountCn: '',
        discountEn: '',
        discountJa: '',
        startTime: '',
        endTime: ''
      },
      changesform: {
        idList: [],
        priceCn: "",
        priceCnUnit: "",
        priceEn: "",
        priceEnUnit: "",
        priceJa: "",
        priceJaUnit: "",
        // productNumber: "",
        weight: "",
        type: '',
        valueCn: '',
        valueEn: '',
        valueJa: '',
        discountCn: '',
        discountEn: '',
        discountJa: '',
        startTime: '',
        endTime: ''
      },
      dialogFormVisible: false,
      formLabelWidth: "130px",
      isDisable: false,
      dialogFormVisibles: false,
      multipleSelection: [],
      dialogForm: false,
      changeid: "",
      newList: [],
      serial: [],
      jacket: [],
      pen: [],
      isShow: false,
      type: []
    }, "newList", []);
  },
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _packagePrice.getseries)("0").then(function (res) {
      if (res.success) {
        _this.pen = res.voList;
      } else {
        _this.msgTip("warning", res.errorMsg);
      }
    });
    (0, _packagePrice.getseries)("2").then(function (res) {
      if (res.success) {
        _this.serial = res.voList;
      } else {
        _this.msgTip("warning", res.errorMsg);
      }
    });
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
    (0, _dictionary.searchDiclist)({
      name: "PART",
      pageNo: 1,
      pageSize: 1000,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      if (res.success) {
        var b = {};
        res.voList.forEach(function (obj) {
          var array = b[obj["name"]] || [];
          array.push(obj);
          b[obj["name"]] = array;
        });
        _this.newList = b["PART"].map(function (v) {
          return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
            value: v.value
          });
        });
        _this.jacket = _this.newList;
        console.log(_this.newList);
      }
    });
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _packagePrice.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _packagePrice.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.productNumber = "";
      this.form.penSerialNumber = "";
      this.form.coverSerialNumber = "";
      this.form.coverNumber = "";
      this.form.penNumber = "";
      this.getList();
    },
    //新增
    add: function add() {
      this.dialogFormVisible = true;
    },
    //新增确定
    sure: function sure() {
      var _this3 = this;
      (0, _packagePrice.addList)(this.addform).then(function (res) {
        if (res.success) {
          _this3.msgTip("success", "添加成功");
          _this3.dialogFormVisible = false;
          _this3.getList();
          _this3.$refs[formName].resetFields();
        } else {
          _this3.msgTip("warning", res.errorMsg);
          _this3.isDisable = false;
        }
      });
    },
    //新增关闭按钮
    addClose: function addClose(formName) {
      this.dialogFormVisible = false;
      this.$refs.addform.resetFields();
    }
  }, "handleSelectionChange", function handleSelectionChange(val) {
    this.multipleSelection = val;
  }), "allchange", function allchange() {
    var _this4 = this;
    if (this.multipleSelection.length < 1) {
      this.msgTip("error", "请选择一条数据");
      return;
    } else {
      this.dialogFormVisibles = true;
      this.changesform.idList = [];
      this.multipleSelection.map(function (res) {
        _this4.changesform.idList.push(res.id);
      });
    }
  }), "keep", function keep() {
    var _this5 = this;
    this.isDisable = true;
    (0, _packagePrice.changePrice)(this.changesform).then(function (res) {
      if (res.success) {
        _this5.msgTip("success", "批量修改成功");
        _this5.getList();
        _this5.dialogFormVisibles = false;
        _this5.isDisable = false;
        _this5.changesform.idList = [];
      } else {
        _this5.msgTip("warning", res.errorMsg);
        _this5.isDisable = false;
      }
    });
  }), "changeClose", function changeClose() {
    this.dialogForm = false;
    this.$refs.changesform.resetFields();
  }), "changesClose", function changesClose() {
    this.dialogFormVisibles = false;
    this.$refs.changesform.resetFields();
    this.changesform.startTime = '';
    this.changesform.endTime = '';
  }), "change", function change(v) {
    var _this6 = this;
    this.dialogForm = true;
    (0, _packagePrice.aloneList)(v.id).then(function (res) {
      _this6.changeform = res.voT;
      _this6.changeform.coverNumber = res.voT.coverNumber + "_" + res.voT.coverNumberName;
      _this6.changeform.penNumber = res.voT.penNumber + "_" + res.voT.penNumberName;
    });
    this.changeid = v.id;
  }), "changes", function changes() {
    var _this7 = this;
    if (this.changeform.idList === undefined) {
      this.changeform.idList = [];
      this.changeform.idList.push(this.changeid);
    } else {
      this.changeform.idList.push(this.changeid);
    }
    this.changeform.coverNumber = this.changeform.coverNumber.split("_")[0];
    this.changeform.penNumber = this.changeform.penNumber.split("_")[0];
    this.isDisable = true;
    (0, _packagePrice.changePrice)(this.changeform).then(function (res) {
      if (res.success) {
        _this7.msgTip("success", "修改成功");
        _this7.getList();
        _this7.dialogForm = false;
        _this7.isDisable = false;
      } else {
        _this7.msgTip("warning", res.errorMsg);
        _this7.isDisable = false;
      }
    });
  }), "dele", function dele(v) {
    var _this8 = this;
    this.$confirm("您是否要删除当前选中的记录?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      (0, _packagePrice.delePrice)(v.id).then(function (res) {
        if (res.success) {
          _this8.msgTip("success", "操作成功");
          _this8.getList();
        } else {
          _this8.msgTip("warning", res.errorMsg);
        }
      });
    });
  }), "types", function types(row) {
    if (row.type == '0') {
      return '无优惠';
    } else if (row.type == '1') {
      return '减免优惠';
    } else if (row.type == '2') {
      return '折扣优惠';
    }
  }), "msgTip", function msgTip(msgType, msgInfo) {
    this.$message({
      type: msgType,
      message: msgInfo,
      center: true
    });
  })
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}