var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _vm.userType == "cn"
          ? _c(
              "div",
              { staticStyle: { background: "#262b36" } },
              [
                _vm.collapse
                  ? _c(
                      "router-link",
                      {
                        key: "collapse",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _vm.logo
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: _vm.logo },
                            })
                          : _c("h1", { staticClass: "sidebar-title" }, [
                              _vm._v(_vm._s(_vm.title1) + " "),
                            ]),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        key: "expand",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _vm.logo
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: _vm.logo },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h1", { staticClass: "sidebar-title" }, [
                          _vm._v(_vm._s(_vm.title1) + " "),
                        ]),
                      ]
                    ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.userType == "com"
          ? _c(
              "div",
              [
                _vm.collapse
                  ? _c(
                      "router-link",
                      {
                        key: "collapse",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _vm.logo
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: _vm.logo },
                            })
                          : _c("h1", { staticClass: "sidebar-title" }, [
                              _vm._v(_vm._s(_vm.title) + " "),
                            ]),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        key: "expand",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _vm.logo
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: _vm.logo },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h1", { staticClass: "sidebar-title" }, [
                          _vm._v(_vm._s(_vm.title) + " "),
                        ]),
                      ]
                    ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.userType == "cn-out"
          ? _c(
              "div",
              [
                _vm.collapse
                  ? _c(
                      "router-link",
                      {
                        key: "collapse",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _vm.logo
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: _vm.logo },
                            })
                          : _c("h1", { staticClass: "sidebar-title" }, [
                              _vm._v(_vm._s(_vm.title2) + " "),
                            ]),
                      ]
                    )
                  : _c(
                      "router-link",
                      {
                        key: "expand",
                        staticClass: "sidebar-logo-link",
                        attrs: { to: "/" },
                      },
                      [
                        _vm.logo
                          ? _c("img", {
                              staticClass: "sidebar-logo",
                              attrs: { src: _vm.logo },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("h1", { staticClass: "sidebar-title" }, [
                          _vm._v(_vm._s(_vm.title2) + " "),
                        ]),
                      ]
                    ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }