"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLists = getLists;
exports.getSettle = getSettle;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询结算信息
function getLists(data) {
  return (0, _request.default)({
    url: 'order/settle/query/all',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//结算
function getSettle(data) {
  return (0, _request.default)({
    url: 'order/settle',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}