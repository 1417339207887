"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
var _charts = require("../../api/charts.js");
//
//
//
//

require("echarts/theme/macarons"); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "50%"
    },
    height: {
      type: String,
      default: "600px"
    },
    rateArr: {
      type: Array
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    rateArr: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(val, oldVal) {
        this.chart.setOption({
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: "top"
          },
          // toolbox: {
          //   show: true,
          // },
          series: [{
            name: "数量",
            type: "pie",
            radius: [50, 200],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8
            },
            data: val
          }]
        });
      },
      deep: true //true 深度监听
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.chart.setOption({
        legend: {
          top: "top"
        },
        toolbox: {
          show: true
        },
        series: [{
          name: "Nightingale Chart",
          type: "pie",
          radius: [50, 200],
          center: ["50%", "50%"],
          roseType: "area",
          itemStyle: {
            borderRadius: 8
          },
          data: this.rateArr
        }]
      });
    }
  }
};