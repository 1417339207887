"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Layout",
  components: {
    Navbar: _components.Navbar,
    Sidebar: _components.Sidebar,
    AppMain: _components.AppMain,
    TagsView: _components.TagsView,
    Settings: _components.Settings
  },
  data: function data() {
    return {
      width: null
    };
  },
  mixins: [_ResizeHandler.default],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    },
    needTagsView: function needTagsView() {
      return this.$store.state.settings.tagsView;
    }
  },
  mounted: function mounted() {
    // window.addEventListener("scroll", this.handleScroll);
    // let aside = document.querySelector('.sidebar-container').style.width
    // this.width = (window.innerWidth - 210) + 'px'
  },
  methods: {
    // handleClickOutside() {
    //   this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    // },
    // handleScroll () {
    //     let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    //     if (scrollTop > 0) {
    //                   document.querySelector("#fixed-header").style.position = "fixed";

    //     } else {
    //                   document.querySelector("#fixed-header").style.position = "unset";

    //     }
    //   }
  }
  // destroyed () {
  //     window.removeEventListener("scroll", this.handleScroll);
  //   }
};