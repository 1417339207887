var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addapp" },
    [
      _c(
        "el-form",
        {
          ref: "addform",
          attrs: {
            model: _vm.addform,
            rules: _vm.addRules,
            "status-icon": true,
            "show-message": false,
            "hide-required-asterisk": "",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "版本号",
                "label-width": _vm.formLabelWidth,
                prop: "version",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.addform.version,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "version", $$v)
                  },
                  expression: "addform.version",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题CN",
                "label-width": _vm.formLabelWidth,
                prop: "titleCn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.addform.titleCn,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "titleCn", $$v)
                  },
                  expression: "addform.titleCn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题EN",
                "label-width": _vm.formLabelWidth,
                prop: "titleEn",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.addform.titleEn,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "titleEn", $$v)
                  },
                  expression: "addform.titleEn",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "标题JA",
                "label-width": _vm.formLabelWidth,
                prop: "titleJa",
              },
            },
            [
              _c("el-input", {
                attrs: { autocomplete: "off", type: "text", maxlength: "255" },
                model: {
                  value: _vm.addform.titleJa,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "titleJa", $$v)
                  },
                  expression: "addform.titleJa",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commont" },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "缩略图", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "show-file-list": false,
                        "before-upload": _vm.beforeUploadPicture1,
                      },
                    },
                    [
                      _vm.imageUrl1
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl1 },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "pc二维码地址",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-upload",
                    {
                      staticClass: "avatar-uploader",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "show-file-list": false,
                        "before-upload": _vm.beforeUploadPicture2,
                      },
                    },
                    [
                      _vm.imageUrl2
                        ? _c("img", {
                            staticClass: "avatar",
                            attrs: { src: _vm.imageUrl2 },
                          })
                        : _c("i", {
                            staticClass: "el-icon-plus avatar-uploader-icon",
                          }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commont" },
            [
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "区域",
                    "label-width": _vm.formLabelWidth,
                    prop: "area",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.addform.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "area", $$v)
                        },
                        expression: "addform.area",
                      },
                    },
                    _vm._l(_vm.areas, function (item) {
                      return _c("el-option", {
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "安卓上传",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "upload-wrapper" },
                    [
                      _c("input", {
                        ref: "uploadFile",
                        staticClass: "upload-file",
                        attrs: {
                          id: "file",
                          type: "file",
                          name: "file",
                          multiple: "",
                        },
                        on: { change: _vm.submitUpload },
                      }),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          staticClass: "exprot-btn",
                          attrs: { size: "small", type: "primary" },
                        },
                        [_vm._v(_vm._s(_vm.buttonUpload))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "安卓大小",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off", type: "text" },
                    model: {
                      value: _vm.showsize,
                      callback: function ($$v) {
                        _vm.showsize = $$v
                      },
                      expression: "showsize",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "commont" },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "ios跳转链接",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", type: "text" },
                    model: {
                      value: _vm.addform.urlIpa,
                      callback: function ($$v) {
                        _vm.$set(_vm.addform, "urlIpa", $$v)
                      },
                      expression: "addform.urlIpa",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ul", [
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述CN")]),
            _vm._v(" "),
            _c(
              "el-upload",
              {
                staticClass: "avatar-uploader",
                staticStyle: { display: "none" },
                attrs: {
                  action: "",
                  "http-request": _vm.to_upload_img,
                  "show-file-list": false,
                },
              },
              [
                _vm._v("\n      >\n      "),
                _c("i", {
                  staticClass: "el-icon-plus avatar-uploader-icon",
                  attrs: { id: "imgInput" },
                }),
              ]
            ),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.addform.descriptionCn,
                callback: function ($$v) {
                  _vm.$set(_vm.addform, "descriptionCn", $$v)
                },
                expression: "addform.descriptionCn",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述EN")]),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor1",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.addform.descriptionEn,
                callback: function ($$v) {
                  _vm.$set(_vm.addform, "descriptionEn", $$v)
                },
                expression: "addform.descriptionEn",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "li",
          [
            _c("h5", [_vm._v("描述JA")]),
            _vm._v(" "),
            _c("quill-editor", {
              ref: "myQuillEditor2",
              attrs: { options: _vm.editorOption, "show-word-limit": "" },
              model: {
                value: _vm.addform.descriptionJa,
                callback: function ($$v) {
                  _vm.$set(_vm.addform, "descriptionJa", $$v)
                },
                expression: "addform.descriptionJa",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.savedisab },
              on: { click: _vm.submit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }