"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _upload = require("../../api/upload.js");
var _app = require("../../api/app.js");
var Quill = _interopRequireWildcard(require("quill"));
require("quill/dist/quill.core.css");
require("quill/dist/quill.snow.css");
require("quill/dist/quill.bubble.css");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// 标题
var titleConfig = [{
  Choice: '.ql-bold',
  title: '加粗'
}, {
  Choice: '.ql-italic',
  title: '斜体'
}, {
  Choice: '.ql-underline',
  title: '下划线'
}, {
  Choice: '.ql-header',
  title: '段落格式'
}, {
  Choice: '.ql-strike',
  title: '删除线'
}, {
  Choice: '.ql-blockquote',
  title: '块引用'
}, {
  Choice: '.ql-code',
  title: '插入代码'
}, {
  Choice: '.ql-code-block',
  title: '插入代码段'
}, {
  Choice: '.ql-font',
  title: '字体'
}, {
  Choice: '.ql-size',
  title: '字体大小'
}, {
  Choice: '.ql-list[value="ordered"]',
  title: '编号列表'
}, {
  Choice: '.ql-list[value="bullet"]',
  title: '项目列表'
}, {
  Choice: '.ql-direction',
  title: '文本方向'
}, {
  Choice: '.ql-header[value="1"]',
  title: 'h1'
}, {
  Choice: '.ql-header[value="2"]',
  title: 'h2'
}, {
  Choice: '.ql-align',
  title: '对齐方式'
}, {
  Choice: '.ql-color',
  title: '字体颜色'
}, {
  Choice: '.ql-background',
  title: '背景颜色'
}, {
  Choice: '.ql-image',
  title: '图像'
}, {
  Choice: '.ql-video',
  title: '视频'
}, {
  Choice: '.ql-link',
  title: '添加链接'
}, {
  Choice: '.ql-formula',
  title: '插入公式'
}, {
  Choice: '.ql-clean',
  title: '清除字体格式'
}, {
  Choice: '.ql-script[value="sub"]',
  title: '下标'
}, {
  Choice: '.ql-script[value="super"]',
  title: '上标'
}, {
  Choice: '.ql-indent[value="-1"]',
  title: '向左缩进'
}, {
  Choice: '.ql-indent[value="+1"]',
  title: '向右缩进'
}, {
  Choice: '.ql-header .ql-picker-label',
  title: '标题大小'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="1"]',
  title: '标题一'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="2"]',
  title: '标题二'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="3"]',
  title: '标题三'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="4"]',
  title: '标题四'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="5"]',
  title: '标题五'
}, {
  Choice: '.ql-header .ql-picker-item[data-value="6"]',
  title: '标题六'
}, {
  Choice: '.ql-header .ql-picker-item:last-child',
  title: '标准'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="small"]',
  title: '小号'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="large"]',
  title: '大号'
}, {
  Choice: '.ql-size .ql-picker-item[data-value="huge"]',
  title: '超大号'
}, {
  Choice: '.ql-size .ql-picker-item:nth-child(2)',
  title: '标准'
}, {
  Choice: '.ql-align .ql-picker-item:first-child',
  title: '居左对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="center"]',
  title: '居中对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="right"]',
  title: '居右对齐'
}, {
  Choice: '.ql-align .ql-picker-item[data-value="justify"]',
  title: '两端对齐'
}];
var _default = exports.default = {
  data: function data() {
    return {
      editorOption: {
        placeholder: "请输入文本信息..."
      },
      formLabelWidth: "120px",
      showsize: '',
      loading: false,
      loadings: false,
      changeform: {
        descriptionCn: '',
        descriptionEn: '',
        descriptionJa: '',
        qrCodeUrl: '',
        thumbnailUrl: '',
        titleCn: '',
        titleEn: '',
        titleJa: '',
        urlIpa: '',
        area: ''
      },
      changeRules: {
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        qrCodeUrl: [{
          required: true,
          trigger: "blur"
        }],
        thumbnailUrl: [{
          required: true,
          trigger: "blur"
        }],
        area: [{
          required: true,
          trigger: "blur"
        }]
      },
      areas: [{
        value: "1",
        label: "中国区"
      }, {
        value: "2",
        label: "非中国区"
      }],
      id: '',
      imageUrl1: '',
      imageUrl2: '',
      savedisab: false,
      addRange: '',
      addRange1: '',
      addRange2: '',
      Rangeflag: '',
      Rangeflag1: '',
      Rangeflag2: '',
      urlApk: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    // 为图片ICON绑定事件  getModule 为编辑器的内部属性
    this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor1.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    this.$refs.myQuillEditor2.quill.getModule("toolbar").addHandler("image", this.imgHandler);
    autotip: {
      document.getElementsByClassName('ql-editor')[0].dataset.placeholder = '';
      for (var _i = 0, _titleConfig = titleConfig; _i < _titleConfig.length; _i++) {
        var item = _titleConfig[_i];
        var tip = document.querySelector('.quill-editor ' + item.Choice);
        if (!tip) continue;
        tip.setAttribute('title', item.title);
      }
    }
    this.id = this.$route.query.id;
    (0, _app.searchList)(this.id).then(function (res) {
      if (res.success) {
        _this.changeform = res.voT;
        _this.imageUrl1 = res.voT.thumbnailUrl.split('===')[0];
        _this.imageUrl2 = res.voT.qrCodeUrl.split('===')[0];
        _this.urlApk = res.voT.urlApk.split('===')[0];
        _this.urlIpa = res.voT.urlIpa.split('===')[0];
      }
    });
  },
  methods: {
    // 点击图片ICON触发事件
    imgHandler: function imgHandler(state) {
      this.addRange = this.$refs.myQuillEditor.quill.getSelection();
      if (this.addRange != "" && this.addRange != null) {
        this.Rangeflag = true;
      }
      this.addRange1 = this.$refs.myQuillEditor1.quill.getSelection();
      if (this.addRange1 != "" && this.addRange1 != null) {
        this.Rangeflag1 = true;
      }
      this.addRange2 = this.$refs.myQuillEditor2.quill.getSelection();
      if (this.addRange2 != "" && this.addRange2 != null) {
        this.Rangeflag2 = true;
      }
      if (state) {
        var fileInput = document.getElementById("imgInput");
        fileInput.click(); // 加一个触发事件
      }
    },
    to_upload_img: function to_upload_img(formdata) {
      var _this2 = this;
      return new Promise(function (resolve, reject) {
        var fd = new FormData();
        fd.append("file", formdata.file);
        (0, _upload.uploads)(fd).then(function (res) {
          if (_this2.Rangeflag) {
            _this2.addRange = _this2.$refs.myQuillEditor.quill.getSelection();
            _this2.$refs.myQuillEditor.quill.insertEmbed(_this2.addRange !== null ? _this2.addRange.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag = false;
          }
          if (_this2.Rangeflag1) {
            _this2.addRange1 = _this2.$refs.myQuillEditor1.quill.getSelection();
            _this2.$refs.myQuillEditor1.quill.insertEmbed(_this2.addRange1 !== null ? _this2.addRange1.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag1 = false;
          }
          if (_this2.Rangeflag2) {
            _this2.addRange2 = _this2.$refs.myQuillEditor2.quill.getSelection();
            _this2.$refs.myQuillEditor2.quill.insertEmbed(_this2.addRange2 !== null ? _this2.addRange2.index : 0, "image", res.voT.downloadUrl, Quill.sources.USER); // 调用编辑器的 insertEmbed 方法，插入URL
            _this2.Rangeflag2 = false;
          }
        });
      });
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this3 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this3.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this3.changeform.thumbnailUrl = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this4 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this4.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this4.changeform.qrCodeUrl = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    submit: function submit() {
      var _this5 = this;
      this.$refs.changeform.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          _this5.isDisable = false;
          if (_this5.changeform.descriptionCn == "" || _this5.changeform.descriptionEn == "" || _this5.changeform.descriptionJa == "") {
            _this5.msgTip("warning", "描述不能为空");
            return false;
          }
          (0, _app.changeList)(_this5.id, _this5.changeform).then(function (res) {
            if (res.success) {
              _this5.msgTip("success", "修改成功");
              _this5.$store.dispatch("tagsView/delView", _this5.$route).then(function () {
                _this5.$nextTick(function () {
                  _this5.$router.replace({
                    path: "/serve/app"
                  });
                });
              });
            } else {
              _this5.msgTip("error", res.errorMsg);
              // this.isRepeat = false;
              _this5.isDisable = true;
            }
          });
        } else {
          _this5.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};