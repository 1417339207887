"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.alonegallery = alonegallery;
exports.changeOn = changeOn;
exports.changegallery = changegallery;
exports.delegallery = delegallery;
exports.getLists = getLists;
exports.updateSeq = updateSeq;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取产品gallery接口(cms
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/gallery/inner/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加产品gallery接口
function addList(data) {
  return (0, _request.default)({
    url: 'cms/gallery',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//产品gallery上下线接口
function changeOn(data, id) {
  return (0, _request.default)({
    url: 'cms/gallery/line/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function updateSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/gallery/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据ID获取产品gallery接口
function alonegallery(id) {
  return (0, _request.default)({
    url: 'cms/gallery/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//修改产品gallery接口
function changegallery(data, id) {
  return (0, _request.default)({
    url: 'cms/gallery/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//删除产品gallery接口
function delegallery(id) {
  return (0, _request.default)({
    url: 'cms/gallery/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}