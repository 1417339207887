"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSeo = addSeo;
exports.dataManipulation = dataManipulation;
exports.delSeo = delSeo;
exports.getSeoById = getSeoById;
exports.querySeo = querySeo;
exports.searchSeolist = searchSeolist;
exports.updateSeo = updateSeo;
var _request = _interopRequireDefault(require("@/utils/request"));
function querySeo(data) {
  return (0, _request.default)({
    url: 'cms/seo/overall/list',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function addSeo(data) {
  return (0, _request.default)({
    url: 'cms/seo/overall',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function updateSeo(data, id) {
  return (0, _request.default)({
    url: 'cms/seo/overall/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function getSeoById(id) {
  return (0, _request.default)({
    url: 'cms/seo/overall/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
function delSeo(id) {
  return (0, _request.default)({
    url: 'cms/seo/overall/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//根据参数模糊查询接口
function searchSeolist(data) {
  return (0, _request.default)({
    url: 'system/base/dictionary',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, line, updateTime, updateUser) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (updateUser != null && updateUser != "") {
        element.updateUser = updateUser;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
      if (line != null && line != "") {
        element.line = line;
      }
    }
  });
  return tableData;
}