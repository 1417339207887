var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container",
      style: { backgroundImage: _vm.backgroundImage },
    },
    [
      _c(
        "div",
        { staticClass: "login" },
        [
          _c(
            "el-form",
            {
              ref: "loginForm",
              staticClass: "login-form",
              attrs: {
                model: _vm.loginForm,
                rules: _vm.loginRules,
                "auto-complete": "on",
                "label-position": "left",
              },
            },
            [
              _c("div", { staticClass: "logo" }),
              _vm._v(" "),
              _vm.userType == "cn"
                ? _c("div", { staticClass: "title-container" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        staticStyle: { color: "#262b36" },
                      },
                      [_vm._v("CMS管理平台 - 国内")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.userType == "com"
                ? _c("div", { staticClass: "title-container" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        staticStyle: { color: "#262b36" },
                      },
                      [_vm._v("CMS管理平台 - 国外")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.userType == "cn-out"
                ? _c("div", { staticClass: "title-container" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        staticStyle: { color: "#262b36" },
                      },
                      [_vm._v("CMS管理平台")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "username" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    ref: "username",
                    attrs: {
                      placeholder: "用户名",
                      name: "username",
                      type: "text",
                      tabindex: "1",
                      "auto-complete": "on",
                    },
                    model: {
                      value: _vm.loginForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "username", $$v)
                      },
                      expression: "loginForm.username",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "password" } },
                [
                  _c(
                    "span",
                    { staticClass: "svg-container" },
                    [_c("svg-icon", { attrs: { "icon-class": "password" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    key: _vm.passwordType,
                    ref: "password",
                    attrs: {
                      type: _vm.passwordType,
                      placeholder: "密码",
                      name: "password",
                      tabindex: "2",
                      "auto-complete": "on",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleLogin($event)
                      },
                    },
                    model: {
                      value: _vm.loginForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.loginForm, "password", $$v)
                      },
                      expression: "loginForm.password",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                    [
                      _c("svg-icon", {
                        attrs: {
                          "icon-class":
                            _vm.passwordType === "password"
                              ? "eye"
                              : "eye-open",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "load",
                  staticStyle: {
                    width: "100%",
                    "margin-bottom": "30px",
                    height: "52px",
                    "font-size": "18px",
                  },
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [_vm._v("登录")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }