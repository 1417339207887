"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _dealerStock = require("../../api/dealerStock.js");
var _dictionary = require("../../api/dictionary.js");
var _addorder = require("../../api/addorder.js");
var _orderLogistics = require("../../api/orderLogistics.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        position: "",
        productNo: "",
        locationPosition: "",
        pageNo: 1,
        pageSize: 10
      },
      loading: false,
      usCountloading: false,
      tableData: [],
      currentPage: 1,
      uscurrentPage: 1,
      total: 0,
      dealers: [],
      dialogFormVisible: false,
      addform: {
        productNo: "",
        dealerList: [{
          position: "",
          //residueCounts:'',
          counts: ""
        }],
        remark: ""
      },
      positionDialogsVisible: false,
      locationDialogsVisible: false,
      dialogSkuCount: false,
      dialogTitle: '美国仓库存',
      formUpdate: {
        formProductNo: "",
        formProductName: "",
        formPosition: "",
        formlocationPosition: '',
        formCount: "",
        id: "",
        position: "",
        locationPosition: '',
        counts: ""
      },
      productName: "",
      formLabelWidth: "120px",
      status: "1",
      type: [],
      jacket: [],
      title: "",
      typeflag: true,
      coverflag: false,
      refillflag: false,
      penflag: false,
      newList: [],
      isDisable: false,
      tableDatas: [],
      typeflags: true,
      nameflag: true,
      saveButton: "确定",
      savedisab: false,
      idList: [],
      dealerFlag: true,
      multipleSelection: [],
      equipmentPurposes: [],
      locationPositions: [],
      stockIn: {
        productNo: "",
        dealerList: [{
          position: "",
          //residueCounts:'',
          counts: ""
        }],
        supplier: "",
        remark: ""
      },
      rules: [],
      stockrules: [],
      dialogFormVisibles: false,
      types: [],
      exporttypes: '1',
      dialogExportForm: false,
      exportform: {
        position: '',
        remark: '',
        counts: '',
        dealer: '',
        equipmentPurpose: '',
        numberList: [],
        productNo: '',
        startNumber: '',
        stockOutType: '1',
        logisticsName: '',
        logisticsNumber: '',
        supplier: '',
        batchNo: '',
        locationPosition: ''
      },
      querySkuform: {
        sku: "",
        locationPosition: "",
        pageNo: 1,
        pageSize: 10
      },
      skuList: [],
      totalsku: 0,
      dealerLists: [],
      exportFlag: true,
      exportformrules: {
        position: [{
          required: true,
          trigger: "change"
        }],
        // remark: [{ required: true, trigger: "change" }],
        counts: [{
          required: true,
          trigger: "change"
        }],
        dealer: [{
          required: true,
          trigger: "change"
        }],
        equipmentPurpose: [{
          required: true,
          trigger: "change"
        }],
        productNo: [{
          required: true,
          trigger: "change"
        }],
        startNumber: [{
          required: true,
          trigger: "change"
        }],
        stockOutType: [{
          required: true,
          trigger: "change"
        }],
        supplier: [{
          required: true,
          trigger: "change"
        }],
        batchNo: [{
          required: true,
          trigger: "change"
        }]
      },
      formUpdateRules: {
        position: [{
          required: true,
          trigger: "blur"
        }],
        counts: [{
          required: true,
          trigger: "change"
        }]
      },
      locationRules: {
        locationPosition: [{
          required: true,
          trigger: "blur"
        }],
        counts: [{
          required: true,
          trigger: "change"
        }]
      },
      companylogistics: [],
      numberflag: false,
      dialogoutForm: false,
      outform: {
        dealerId: '',
        equipmentPurpose: '',
        position: '',
        remark: '',
        logistics: '',
        locationPosition: '',
        number: ''
      },
      outformRules: {
        dealerId: [{
          required: true,
          trigger: "change"
        }],
        equipmentPurpose: [{
          required: true,
          trigger: "change"
        }],
        position: [{
          required: true,
          trigger: "change"
        }],
        remark: [{
          required: true,
          trigger: "change"
        }],
        locationPosition: [{
          required: true,
          trigger: "blur"
        }]
      },
      removeFlag: false,
      numFlag: true,
      radios: '',
      addStatus: '1',
      surcLoading: false,
      outTitle: '',
      deaFlag: false,
      dealeFlags: false,
      batchFlag: false,
      exportFlags: false,
      allouts: '',
      aloneout: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDic)({
      name: "STOCK_POSITION",
      pageNo: 1,
      pageSize: 1000,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.dealers = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "LOCATION_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.locationPositions = res.voList;
      _this.locationPositions.forEach(function (item, index, arr) {
        if (item.value == "WAIT") {
          arr.splice(index, 1);
        }
      });
    });
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _dealerStock.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _dealerStock.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this3 = this;
      this.multipleSelection = val;
      this.idList = [];
      val.map(function (item) {
        _this3.formUpdate.formProductNo = item.productNo;
        _this3.formUpdate.formPosition = item.position;
        _this3.formUpdate.formProductName = item.productName;
        _this3.formUpdate.formlocationPosition = item.locationPosition;
        _this3.formUpdate.formCount = item.counts;
        _this3.formUpdate.id = item.id;
        _this3.idList.push(item.id);
      });
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.dealer = "";
      this.form.productNo = "";
      this.form.position = '';
      this.form.locationPosition = '';
      this.getList();
    },
    changeRowColor: function changeRowColor(_ref) {
      var row = _ref.row;
      if (row.usCounts != row.counts) {
        return {
          color: "red"
        };
      }
    },
    positionClose: function positionClose() {
      this.formUpdate.position = "";
      this.formUpdate.counts = "";
      this.formUpdate.locationPosition = "";
      this.positionDialogsVisible = false;
    },
    positionAdd: function positionAdd() {
      var _this4 = this;
      this.surcLoading = true;
      (0, _dealerStock.changelist)(this.formUpdate).then(function (res) {
        if (res.success) {
          _this4.msgTip("success", "品质仓调整完成完成！");
          _this4.positionDialogsVisible = false;
          _this4.isDisable = false;
          _this4.surcLoading = false;
          _this4.getList();
        } else {
          _this4.msgTip("error", res.errorMsg);
          _this4.isDisable = false;
          _this4.surcLoading = false;
        }
      });
    },
    locationClose: function locationClose() {
      this.formUpdate.position = "";
      this.formUpdate.counts = "";
      this.formUpdate.locationPosition = "";
      this.locationDialogsVisible = false;
    },
    locationAdd: function locationAdd() {
      var _this5 = this;
      this.surcLoading = true;
      this.savedisab = true;
      this.saveButton = "正在调整";
      (0, _dealerStock.locationChangelist)(this.formUpdate).then(function (res) {
        if (res.success) {
          _this5.msgTip("success", "区域仓调整完成完成！");
          _this5.locationDialogsVisible = false;
          _this5.surcLoading = false;
          _this5.saveButton = "确定";
          _this5.savedisab = false;
          _this5.getList();
        } else {
          _this5.msgTip("error", res.errorMsg);
          _this5.surcLoading = false;
          _this5.saveButton = "确定";
          _this5.savedisab = false;
        }
      });
    },
    //查询美国仓实际数量
    usCount: function usCount() {
      var _this6 = this;
      this.dialogTitle = "美国仓库存";
      this.dialogSkuCount = true;
      this.usCountloading = true;
      this.skuList = [];
      this.totalsku = 0;
      this.querySkuform.locationPosition = "US";
      (0, _dealerStock.querySkuList)(this.querySkuform).then(function (res) {
        if (res.success) {
          _this6.skuList = res.voList;
          _this6.totalsku = Number(res.total);
          _this6.usCountloading = false;
        }
      });
    },
    //查询美国仓实际数量
    usrwCount: function usrwCount() {
      var _this7 = this;
      this.dialogTitle = "美国售后仓库存";
      this.dialogSkuCount = true;
      this.usCountloading = true;
      this.skuList = [];
      this.totalsku = 0;
      this.querySkuform.locationPosition = "USRW";
      (0, _dealerStock.querySkuList)(this.querySkuform).then(function (res) {
        if (res.success) {
          _this7.skuList = res.voList;
          _this7.totalsku = Number(res.total);
          _this7.usCountloading = false;
        }
      });
    },
    //分页
    uschangepage: function uschangepage(uscurrentPage) {
      console.info("---------------" + uscurrentPage);
      this.uscurrentPage = uscurrentPage;
      this.querySkuform.pageNo = uscurrentPage;
      if (this.querySkuform.locationPosition == "USRW") {
        this.usrwCount();
      } else {
        this.usCount();
      }
    },
    //导入
    upload: function upload() {},
    //入库
    stockin: function stockin() {
      var _this8 = this;
      this.outTitle = '入库';
      this.dialogExportForm = true;
      this.refillflag = false;
      this.exporttypes = "1";
      //  this.typeflag = true
      this.exportFlag = true;
      this.exportFlags = false;
      this.deaFlag = false, this.dealeFlags = true, this.batchFlag = true;
      this.allouts = '批入';
      this.aloneout = '单入';
      (0, _addorder.Diclist)({
        name: "PRODUCT_TYPE",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this8.type = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "LOGISTICS",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this8.companylogistics = res.voList;
      });
      (0, _dealerStock.getLanList)({
        name: "EQUIPMENT_PURPOSE"
      }).then(function (res) {
        if (res.success) {
          _this8.equipmentPurposes = res.dictionaryVOList;
        }
      });
      (0, _dictionary.searchDic)({
        name: "DEALER",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this8.dealerLists = res.voList;
      });
    },
    //入库关闭
    stockClose: function stockClose() {
      this.dialogFormVisibles = false;
      this.status = '1';
      this.stockIn.supplier = '';
      this.typeflag = true;
    },
    //入库确定
    stockSure: function stockSure() {
      var _this9 = this;
      this.stockIn.dealerList = [];
      this.dealerFlag = true;
      this.$refs.singeltatbel.store.states.selection.map(function (item) {
        _this9.stockIn.dealerList.push({
          position: item.value,
          counts: item.counts
          // totalCounts:item.totalCounts,
          // residueCounts:item.residueCounts,
          // addSubtractCounts:item.addSubtractCounts
        });
      });
      if (this.stockIn.productNo == "") {
        this.msgTip("warning", "产品不能为空");
        return;
      } else {
        this.stockIn.dealerList.map(function (res) {
          if (res.counts === undefined || res.counts === "" || res.counts === null) {
            _this9.msgTip("warning", "勾选渠道的数量不能为空！");
            _this9.dealerFlag = false;
            //return false;
          }
        });
        if (this.dealerFlag) {
          this.surcLoading = true;
          (0, _dealerStock.getstockIn)(this.stockIn).then(function (item) {
            if (item.success) {
              _this9.msgTip("success", "入库成功");
              _this9.dialogFormVisibles = false;
              _this9.getList();
              _this9.stockIn.dealerList = [];
              _this9.stockIn.productNo = "";
              _this9.stockIn.remark = "";
              _this9.stockIn.supplier = "";
              _this9.status = "1";
              _this9.typeflag = true;
              _this9.surcLoading = false;
            } else {
              _this9.surcLoading = false;
              _this9.msgTip("error", item.errorMsg);
            }
          });
        }
      }
    },
    //品质仓调整
    add: function add() {
      var _this10 = this;
      if (this.multipleSelection.length < 1) {
        this.msgTip("warning", "请选择一条记录！");
      } else if (this.multipleSelection.length > 1) {
        this.msgTip("warning", "只能选择一条记录！");
      } else {
        this.positionDialogsVisible = true;
        (0, _dictionary.searchDic)({
          name: "STOCK_POSITION",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this10.tableDatas = res.voList;
        });
        (0, _addorder.Diclist)({
          name: "PRODUCT_TYPE",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this10.type = res.voList;
        });
      }
    },
    locationUpdate: function locationUpdate() {
      if (this.multipleSelection.length < 1) {
        this.msgTip("warning", "请选择一条记录！");
      } else if (this.multipleSelection.length > 1) {
        this.msgTip("warning", "只能选择一条记录！");
      } else {
        this.locationDialogsVisible = true;
      }
    },
    //渠道判断
    dealerList: function dealerList(row) {
      if (row.value == "1") {
        return "良品仓";
      } else if (row.value == "2") {
        return "B类品仓";
      } else if (row.value == "3") {
        return "客服仓";
      }
    },
    //选择类型
    isNo: function isNo(v) {
      var _this11 = this;
      this.addform.productNo = "";
      //   this.addform.dealerList= []

      this.$refs.singeltatbel.clearSelection();

      //   this.tableDatas = []
      if (v == "1") {
        this.typeflag = true;
        this.coverflag = false;
        this.penflag = false;
        this.refillflag = false;
        this.stockIn.productNo = "";
        (0, _addorder.Diclist)({
          name: "PRODUCT_TYPE",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this11.type = res.voList;
        });
        (0, _dictionary.searchDic)({
          name: "STOCK_POSITION",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this11.tableDatas = res.voList;
          _this11.tableDatas.map(function (v) {
            v.counts = 0;
          });
        });
      } else if (v == "2") {
        this.coverflag = true;
        this.typeflag = false;
        this.penflag = false;
        this.refillflag = false;
        this.stockIn.productNo = "";
        (0, _addorder.Diclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this11.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this11.jacket = _this11.newList;
          }
        });
        (0, _dictionary.searchDic)({
          name: "STOCK_POSITION",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this11.tableDatas = res.voList;
          _this11.tableDatas.map(function (v) {
            v.counts = 0;
          });
        });
      } else if (v == "3") {
        this.coverflag = false;
        this.typeflag = false;
        this.penflag = true;
        this.refillflag = false;
        this.stockIn.productNo = "";
        (0, _dictionary.searchDic)({
          name: "STOCK_POSITION",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this11.tableDatas = res.voList;
          _this11.tableDatas.map(function (v) {
            v.counts = 0;
          });
        });
        (0, _addorder.Diclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this11.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this11.jacket = _this11.newList;
          }
        });
      } else if (v == '4') {
        this.coverflag = false;
        this.typeflag = false;
        this.penflag = false;
        this.refillflag = true;
        this.stockIn.productNo = "";
        (0, _dictionary.searchDic)({
          name: "STOCK_POSITION",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this11.tableDatas = res.voList;
          _this11.tableDatas.map(function (v) {
            v.counts = 0;
          });
        });
        (0, _addorder.Diclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this11.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this11.jacket = _this11.newList;
          }
        });
      }
    },
    //出库关闭
    exportClose: function exportClose() {
      this.dialogExportForm = false;
      this, exportform.logisticsName = '';
      this.exportform.logisticsNumber = '';
      this.$refs.exportform.resetFields();
    },
    isProduct: function isProduct(v) {
      var _this12 = this;
      if (v == "1") {
        this.typeflag = true;
        this.coverflag = false;
        this.penflag = false;
        this.refillflag = false;
        this.exportform.productNo = '';
        (0, _addorder.Diclist)({
          name: "PRODUCT_TYPE",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          _this12.type = res.voList;
        });
        this.exportFlag = true;
      } else if (v == "2") {
        this.exportFlag = false;
        this.coverflag = true;
        this.typeflag = false;
        this.penflag = false;
        this.refillflag = false;
        this.numFlag = true;
        this.exportform.productNo = '';
        (0, _addorder.Diclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this12.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this12.jacket = _this12.newList;
          }
        });
      } else if (v == "3") {
        this.exportFlag = false;
        this.coverflag = false;
        this.typeflag = false;
        this.refillflag = false;
        this.penflag = true;
        this.numFlag = true;
        this.exportform.productNo = '';
        (0, _addorder.Diclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this12.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this12.jacket = _this12.newList;
          }
        });
      } else if (v == "4") {
        this.exportFlag = false;
        this.coverflag = false;
        this.typeflag = false;
        this.penflag = false;
        this.refillflag = true;
        this.numFlag = true;
        this.exportform.productNo = '';
        (0, _addorder.Diclist)({
          name: "PART",
          pageNo: 1,
          pageSize: 1000,
          sortField: "",
          sortRules: "",
          valueMeaning: ""
        }).then(function (res) {
          if (res.success) {
            var b = {};
            res.voList.forEach(function (obj) {
              var array = b[obj["name"]] || [];
              array.push(obj);
              b[obj["name"]] = array;
            });
            _this12.newList = b["PART"].map(function (v) {
              return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
                value: v.value
              });
            });
            _this12.jacket = _this12.newList;
          }
        });
      }
    },
    //批出
    sallout: function sallout() {
      var _this13 = this;
      this.dialogoutForm = true;
      (0, _dictionary.searchDic)({
        name: "STOCK_POSITION",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this13.dealers = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "DEALER",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this13.dealerLists = res.voList;
      });
      (0, _dealerStock.getLanList)({
        name: "EQUIPMENT_PURPOSE"
      }).then(function (res) {
        if (res.success) {
          _this13.equipmentPurposes = res.dictionaryVOList;
        }
      });
      (0, _dictionary.searchDic)({
        name: "LOGISTICS",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this13.companylogistics = res.voList;
      });
    },
    outUpload: function outUpload() {
      var _this14 = this;
      var fileData = this.$refs.outFile.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append('file', fileData);
      formFile.append("position", this.outform.position);
      formFile.append("dealer", this.outform.dealerId);
      formFile.append("equipmentPurpose", this.outform.equipmentPurpose);
      formFile.append("logisticsName", this.outform.logistics);
      formFile.append("logisticsNumber", this.outform.number);
      formFile.append("remark", this.outform.remark);
      formFile.append("locationPosition", this.outform.locationPosition);
      var name = fileData.name.split('.')[fileData.name.split('.').length - 1];
      // this.isRepeat = true;
      if (name !== 'xlsx') {
        this.msgTip("error", "文件格式仅支持xlsx！");
      } else {
        (0, _dealerStock.getOut)(formFile).then(function (res) {
          if (res.success) {
            _this14.msgTip("success", "操作成功");
            _this14.dialogoutForm = false;
            _this14.getList();
            _this14.$refs.outform.resetFields();
            _this14.$refs.outFile.value = [];
            // this.isDisable = false;
          } else {
            _this14.msgTip("error", res.errorMsg);
            _this14.$refs.outFile.value = [];
            // this.isDisable = false;
          }
        });
      }
    },
    //批出关闭
    outClose: function outClose() {
      this.dialogoutForm = false;
      this.$refs.outform.resetFields();
    },
    //出库
    exportant: function exportant() {
      var _this15 = this;
      this.dialogExportForm = true;
      this.outTitle = '出库';
      this.refillflag = false;
      this.exporttypes = "1";
      this.typeflag = true;
      this.exportFlag = true;
      this.exportFlags = true;
      this.deaFlag = true, this.dealeFlags = false, this.batchFlag = false;
      this.allouts = '批出';
      this.aloneout = '单出';
      (0, _addorder.Diclist)({
        name: "PRODUCT_TYPE",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this15.type = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "LOGISTICS",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this15.companylogistics = res.voList;
      });
      (0, _dealerStock.getLanList)({
        name: "EQUIPMENT_PURPOSE"
      }).then(function (res) {
        if (res.success) {
          _this15.equipmentPurposes = res.dictionaryVOList;
        }
      });
      (0, _dictionary.searchDic)({
        name: "DEALER",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this15.dealerLists = res.voList;
      });
    },
    //添加起始编码
    addNumber: function addNumber() {
      this.exportform.numberList.push({
        text: ''
      });
      this.removeFlag = true;
      this.$set(this.exportform, 'numberList', this.exportform.numberList);
    },
    removeNumber: function removeNumber(index) {
      this.exportform.numberList.splice(index, 1);
    },
    //选择批出淡出
    allout: function allout(v) {
      this.radios = v;
      if (v == '1') {
        this.numFlag = true;
        this.numberflag = false;
        this.exportform.numberList = [];
      } else if (v == '2') {
        this.numberflag = true;
        this.numFlag = false;
      }
    },
    //出库确定
    exportSure: function exportSure() {
      var _this16 = this;
      if (this.outTitle == '出库') {
        this.$refs.exportform.validate(function (valid) {
          if (valid) {
            _this16.surcLoading = true;
            if (_this16.exportform.stockOutType == '2') {
              _this16.isDisable = true;
              var data = (0, _objectSpread2.default)({}, _this16.exportform);
              data.numberList = data.numberList.map(function (v) {
                return v.text;
              });
              data.numberList.push(_this16.exportform.startNumber);
              (0, _dealerStock.getstockOut)({
                product: _this16.exportform.product,
                position: _this16.exportform.position,
                remark: _this16.exportform.remark,
                counts: '',
                dealer: _this16.exportform.dealer,
                equipmentPurpose: _this16.exportform.equipmentPurpose,
                numberList: data.numberList,
                productNo: _this16.exportform.productNo,
                startNumber: '',
                stockOutType: _this16.exportform.stockOutType,
                type: _this16.exportform.type,
                number: _this16.exportform.number,
                logistics: _this16.exportform.logistics,
                locationPosition: _this16.exportform.locationPosition
              }).then(function (res) {
                if (res.success) {
                  _this16.msgTip("success", "操作成功");
                  _this16.dialogExportForm = false;
                  _this16.getList();
                  _this16.$refs.exportform.resetFields();
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                  _this16.typeflag = true;
                  _this16.coverflag = false;
                  _this16.penflag = false;
                  _this16.refillflag = false;
                  _this16.exportform.dealer = '';
                  _this16.exportform.logisticsName = '';
                  _this16.exportform.logisticsNumber = '';
                  _this16.exportform.locationPosition = '';
                } else {
                  _this16.msgTip("error", res.errorMsg);
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                }
              });
            } else if (_this16.exportform.stockOutType == '1') {
              _this16.isDisable = true;
              var _data = (0, _objectSpread2.default)({}, _this16.exportform);
              _data.numberList = _data.numberList.map(function (v) {
                return v.text;
              });
              (0, _dealerStock.getstockOut)(_data).then(function (res) {
                if (res.success) {
                  _this16.msgTip("success", "操作成功");
                  _this16.dialogExportForm = false;
                  _this16.getList();
                  _this16.$refs.exportform.resetFields();
                  _this16.typeflag = true;
                  _this16.coverflag = false;
                  _this16.penflag = false;
                  _this16.refillflag = false;
                  _this16.exportform.dealer = '';
                  _this16.exportform.logisticsName = '';
                  _this16.exportform.logisticsNumber = '';
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                } else {
                  _this16.msgTip("error", res.errorMsg);
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                }
              });
            } else {
              _this16.isDisable = true;
              var _data2 = (0, _objectSpread2.default)({}, _this16.exportform);
              _data2.numberList = _data2.numberList.map(function (v) {
                return v.text;
              });
              (0, _dealerStock.getstockOut)(_data2).then(function (res) {
                if (res.success) {
                  _this16.msgTip("success", "操作成功");
                  _this16.dialogExportForm = false;
                  _this16.exportform.dealer = '';
                  _this16.exportform.logisticsName = '';
                  _this16.exportform.logisticsNumber = '';
                  _this16.getList();
                  _this16.$refs.exportform.resetFields();
                  _this16.typeflag = true;
                  _this16.coverflag = false;
                  _this16.penflag = false;
                  _this16.refillflag = false;
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                } else {
                  _this16.msgTip("error", res.errorMsg);
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                }
              });
            }
          }
        });
      } else if (this.outTitle == '入库') {
        this.$refs.exportform.validate(function (valid) {
          if (valid) {
            _this16.surcLoading = true;
            if (_this16.exportform.stockOutType == '2') {
              _this16.isDisable = true;
              var data = (0, _objectSpread2.default)({}, _this16.exportform);
              data.numberList = data.numberList.map(function (v) {
                return v.text;
              });
              data.numberList.push(_this16.exportform.startNumber);
              (0, _dealerStock.getstockIn)({
                product: _this16.exportform.product,
                position: _this16.exportform.position,
                remark: _this16.exportform.remark,
                counts: '',
                locationPosition: _this16.exportform.locationPosition,
                supplier: _this16.exportform.supplier,
                batchNo: _this16.exportform.batchNo,
                // dealer:this.exportform.dealer,
                // equipmentPurpose:this.exportform.equipmentPurpose,
                numberList: data.numberList,
                productNo: _this16.exportform.productNo,
                startNumber: '',
                stockOutType: _this16.exportform.stockOutType,
                type: _this16.exportform.type,
                number: _this16.exportform.number,
                logistics: _this16.exportform.logistics
              }).then(function (res) {
                if (res.success) {
                  _this16.msgTip("success", "操作成功");
                  _this16.dialogExportForm = false;
                  _this16.getList();
                  _this16.$refs.exportform.resetFields();
                  _this16.typeflag = true;
                  _this16.coverflag = false;
                  _this16.penflag = false;
                  _this16.refillflag = false;
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                  _this16.exportform.dealer = '';
                  _this16.exportform.logisticsName = '';
                  _this16.exportform.logisticsNumber = '';
                } else {
                  _this16.msgTip("error", res.errorMsg);
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                }
              });
            } else if (_this16.exportform.stockOutType == '1') {
              _this16.isDisable = true;
              var _data3 = (0, _objectSpread2.default)({}, _this16.exportform);
              _data3.numberList = _data3.numberList.map(function (v) {
                return v.text;
              });
              (0, _dealerStock.getstockIn)(_data3).then(function (res) {
                if (res.success) {
                  _this16.msgTip("success", "操作成功");
                  _this16.dialogExportForm = false;
                  _this16.getList();
                  _this16.$refs.exportform.resetFields();
                  _this16.typeflag = true;
                  _this16.coverflag = false;
                  _this16.penflag = false;
                  _this16.refillflag = false;
                  _this16.exportform.dealer = '';
                  _this16.exportform.logisticsName = '';
                  _this16.exportform.logisticsNumber = '';
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                } else {
                  _this16.msgTip("error", res.errorMsg);
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                }
              });
            } else {
              _this16.isDisable = true;
              var _data4 = (0, _objectSpread2.default)({}, _this16.exportform);
              _data4.numberList = _data4.numberList.map(function (v) {
                return v.text;
              });
              (0, _dealerStock.getstockIn)(_data4).then(function (res) {
                if (res.success) {
                  _this16.msgTip("success", "操作成功");
                  _this16.dialogExportForm = false;
                  _this16.exportform.dealer = '';
                  _this16.exportform.logisticsName = '';
                  _this16.exportform.logisticsNumber = '';
                  _this16.getList();
                  _this16.$refs.exportform.resetFields();
                  _this16.typeflag = true;
                  _this16.coverflag = false;
                  _this16.penflag = false;
                  _this16.refillflag = false;
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                } else {
                  _this16.msgTip("error", res.errorMsg);
                  _this16.isDisable = false;
                  _this16.surcLoading = false;
                }
              });
            }
          }
        });
      }
    },
    handleSelectionChanges: function handleSelectionChanges(val) {
      this.addform.dealerList = [];
    },
    changeTotal: function changeTotal(e) {
      // console.log(e,'00000')
    },
    changeCount: function changeCount(e) {},
    //调整关闭
    addClose: function addClose() {
      this.dialogFormVisible = false;
      this.addform.dealerList = [];
      this.addform.productNo = "";
      this.addform.remark = "";
      this.addStatus = "1";
      this.typeflag = true;
      this.coverflag = false;
      this.penflag = false;
    },
    //调整确定
    keep: function keep() {
      var _this17 = this;
      this.addform.dealerList = [];
      this.dealerFlag = true;
      this.$refs.singeltatbel.store.states.selection.map(function (item) {
        _this17.addform.dealerList.push({
          position: item.value,
          counts: item.counts
          // totalCounts:item.totalCounts,
          // residueCounts:item.residueCounts,
          // addSubtractCounts:item.addSubtractCounts
        });
      });
      if (this.addform.productNo == "") {
        this.msgTip("warning", "产品不能为空");
        return;
      } else {
        this.addform.dealerList.map(function (res) {
          if (res.counts === undefined || res.counts === "" || res.counts === null) {
            _this17.msgTip("warning", "勾选渠道的数量不能为空！");
            _this17.dealerFlag = false;
            //return false;
          }
        });
        if (this.title == "调整") {
          if (this.dealerFlag) {
            (0, _dealerStock.changelist)(this.addform).then(function (item) {
              if (item.success) {
                _this17.msgTip("success", "调整成功");
                _this17.dialogFormVisible = false;
                _this17.getList();
                _this17.addform.dealerList = [];
                _this17.addform.productNo = "";
                _this17.addform.remark = "";
                _this17.status = "1";
              } else {
                _this17.msgTip("error", item.errorMsg);
              }
            });
          }
        }
      }
    },
    changeProduct: function changeProduct(e) {
      var _this18 = this;
      this.addform.productNo = "";
      //   this.addform.dealerList= []

      this.$refs.singeltatbel.clearSelection();
      (0, _dictionary.searchDic)({
        name: "STOCK_POSITION",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this18.tableDatas = res.voList;
        _this18.tableDatas.map(function (v) {
          v.counts = 0;
        });
      });
      (0, _dealerStock.alonelist)(e).then(function (res) {
        _this18.addform.productNo = res.voT.productNo;
        _this18.addform.remark = res.voT.remark;
        _this18.productName = res.voT.productName;
        _this18.tableDatas.map(function (result) {
          res.voT.dealerList.map(function (item) {
            if (result.value == item.position) {
              result.counts = item.counts;
              // result.totalCounts = item.totalCounts
              // result.residueCounts = item.residueCounts
              if (result) {
                _this18.$refs.singeltatbel.toggleRowSelection(result);
              } else {
                _this18.$refs.singeltatbel.clearSelection();
              }
            }
          });
        });
      });
    },
    //修改
    change: function change(row) {
      var _this19 = this;
      this.dialogFormVisible = true;
      this.title = "修改";
      this.typeflags = false;
      this.typeflag = false;
      this.coverflag = false;
      this.penflag = false;
      this.nameflag = true;
      (0, _addorder.Diclist)({
        name: "PRODUCT_TYPE",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this19.type = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "STOCK_POSITION",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this19.tableDatas = res.voList;
      });
      (0, _dealerStock.alonelist)(row.productNo).then(function (res) {
        _this19.addform.productNo = res.voT.productNo;
        _this19.addform.remark = res.voT.remark;
        _this19.productName = res.voT.productName;
        _this19.tableDatas.forEach(function (result) {
          res.voT.dealerList.map(function (item) {
            if (result.value == item.dealer) {
              result.counts = item.counts;
              // result.totalCounts = item.totalCounts
              // result.residueCounts = item.residueCounts
              if (result) {
                _this19.$refs.singeltatbel.toggleRowSelection(result);
              } else {
                _this19.$refs.singeltatbel.clearSelection();
              }
            }
          });
        });
      });
    },
    //删除
    dele: function dele() {
      var _this20 = this;
      if (this.multipleSelection.length < 1) {
        this.msgTip("warning", "至少选择一条记录！");
      } else {
        this.$confirm("您是否要删除当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          (0, _dealerStock.deleLists)({
            idList: _this20.idList
          }).then(function (res) {
            if (res.success) {
              _this20.msgTip("success", "操作成功");
              _this20.getList();
            } else {
              _this20.msgTip("warning", res.errorMsg);
            }
          });
        });
      }
    },
    //批出模板
    downout: function downout() {
      var _this21 = this;
      this.loading = true;
      (0, _dealerStock.outdownload)().then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "批出模板.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
        _this21.loading = false;
      });
    },
    //批入模板下载
    down: function down() {
      var _this22 = this;
      this.loading = true;
      (0, _dealerStock.inModeldownload)().then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "批入模板.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
        _this22.loading = false;
      });
    },
    //批量调整
    submitUpload: function submitUpload() {
      var _this23 = this;
      var fileData = this.$refs.uploadFile.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var extension = fileData.name.split(".")[1] === "xlsx";
      if (!extension) {
        this.msgTip("error", "上传模板只能是xlsx格式!");
        return;
      } else {
        (0, _dealerStock.importLists)(formFile).then(function (res) {
          if (res.success) {
            _this23.msgTip("success", "批入成功");
            _this23.$refs.uploadFile.value = [];
            _this23.getList();
          } else {
            _this23.msgTip("error", res.errorMsg);
            _this23.$refs.uploadFile.value = [];
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};