"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _dictionary = require("../../api/dictionary.js");
var _trends = require("../../api/trends.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      value: "",
      show: "",
      currentPage: this.$route.query.cur ? this.$route.query.cur : 1,
      total: 0,
      value1: true,
      tableData: [],
      form: {
        pageNo: 1,
        pageSize: 10,
        id: "",
        serialNumber: "",
        type: "",
        priceRangeCn: "",
        priceRangeEn: "",
        priceRangeJa: "",
        updateTime: "",
        updateUser: ""
      },
      forms: {
        pageNo: 1,
        pageSize: 10,
        id: "",
        serialNumber: "",
        type: "",
        priceRangeCn: "",
        priceRangeEn: "",
        priceRangeJa: "",
        updateTime: "",
        updateUser: ""
      },
      isShow: false,
      serial: [],
      type: []
    };
  },
  created: function created() {
    this.getList();
  },
  mounted: function mounted() {
    var _this = this;
    if (this.$route.query.cur) {
      this.form.pageNo = this.$route.query.cur;
      this.forms.pageNo = this.$route.query.cur;
    }
    this.loadAll();
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    loadAll: function loadAll() {
      var _this2 = this;
      (0, _dictionary.searchDiclist)({
        name: "",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this2.newList = b["PART_SERIES"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value,
              valueCn: v.value + "_" + v.valueCn
            });
          });
          _this2.serial = _this2.newList;
          _this2.proList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value,
              valueCn: v.value + "_" + v.valueCn
            });
          });
          _this2.product = _this2.proList;
        }
      });
    },
    //查询
    search: function search() {
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.serialNumber = this.form.serialNumber;
      this.forms.type = this.form.type;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.forms.line = "";
      this.currentPage = 1;
      this.form.line = "";
      this.forms.pageNo = 1;
      this.forms.serialNumber = "";
      this.forms.type = "";
      this.form.serialNumber = "";
      this.form.type = "";
      window.location.href = window.location.href.split('?')[0];
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.loading = true;
      (0, _trends.priceRange)(this.forms).then(function (res) {
        if (res.voList == null || res.voList == "") {
          _this3.forms.pageNo = 1;
          _this3.currentPage = 1;
        }
        _this3.tableData = res.voList;
        _this3.total = res.total;
        _this3.loading = false;
      });
    },
    //新增
    add: function add() {
      this.$router.push({
        path: "addtrends",
        query: {
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changetrends",
        query: {
          id: id,
          line: this.form.line,
          cur: this.currentPage
        }
      });
    },
    //删除
    dele: function dele(id) {
      var _this4 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _trends.deletetrends)(id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "删除成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    //获取页码
    handleCurrentChange: function handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};