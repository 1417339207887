"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.array.find-index");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _upload = require("../../api/upload.js");
var _gallery = require("../../api/gallery.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
      addform: {
        seq: "",
        count: "",
        mutiUrl: "",
        line: "",
        id: ""
      },
      addRules: {
        seq: [{
          required: true,
          trigger: "blur"
        }],
        count: [{
          required: true,
          trigger: "blur"
        }]
      },
      imageUrl: false
    }, "imageUrl", ""), "formLabelWidth", "120px"), "multiple", true), "uploadUrl", "".concat(process.env.VUE_APP_BASE_API, "cms/picture/upload")), "uplaod_data", {
      "x-access-token": window.localStorage.getItem("token")
    }), "fileList", []), "dialogVisible", false), "fileupload", ""), "dialogImageUrl", ""), "count", 0);
  },
  mounted: function mounted() {},
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      console.log(fileList, '成功');
      this.fileList = fileList;
    },
    handleRemoves: function handleRemoves(file, fileList) {
      var _this = this;
      //this.addform.mutiUrl = [];
      var mutiUrls = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this.addform.mutiUrl = mutiUrls.join(",");
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var _this2 = this;
      //    this.addform.mutiUrl = [];
      var mutiUrl1 = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrl1.push(res.response.voT.downloadUrl);
        _this2.addform.mutiUrl = mutiUrl1.join(',');
      });
    },
    changePicture: function changePicture(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      this.fileupload = this.fileList.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccess: function uploadSuccess(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      this.fileList.splice(this.fileupload, 1, file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this3 = this;
      if (this.fileList == [] && this.addform.mutiUrl == "") {
        this.msgTip("warning", "请上传图片");
        return false;
      } else {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            var mutiUrl = [];
            _this3.fileList.map(function (res) {
              mutiUrl.push(res.response.voT.downloadUrl);
              _this3.addform.mutiUrl = mutiUrl.join(",");
            });
            (0, _gallery.addList)(_this3.addform).then(function (item) {
              if (item.success) {
                _this3.msgTip("success", "新增成功");
                _this3.$store.dispatch("tagsView/delView", _this3.$route).then(function () {
                  _this3.$nextTick(function () {
                    _this3.$router.replace({
                      path: "/gallery/gallerys"
                    });
                  });
                });
              } else {
                _this3.msgTip("error", item.errorMsg);
              }
            });
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};