var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderLogistics" },
    [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _vm.flagList[0].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("渠道")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.changeDealerType },
                      model: {
                        value: _vm.form.dealerList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dealerList", $$v)
                        },
                        expression: "form.dealerList",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[1].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("订单号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderNo", $$v)
                      },
                      expression: "form.orderNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[2].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("订单序号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.serial,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "serial", $$v)
                      },
                      expression: "form.serial",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[25].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("航线")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.airLine,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "airLine", $$v)
                        },
                        expression: "form.airLine",
                      },
                    },
                    _vm._l(_vm.airLines, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[22].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("区域仓")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.locationPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "locationPosition", $$v)
                        },
                        expression: "form.locationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositionTop, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[3].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("交易号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.tradeNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tradeNo", $$v)
                      },
                      expression: "form.tradeNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[4].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("设备号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.equipmentNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "equipmentNumber", $$v)
                      },
                      expression: "form.equipmentNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[5].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("是否到账")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.isToAccount,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "isToAccount", $$v)
                        },
                        expression: "form.isToAccount",
                      },
                    },
                    _vm._l(_vm.isAccount, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[6].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("类型")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.isType, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[24].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("T+销售状态")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.chanjetType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "chanjetType", $$v)
                        },
                        expression: "form.chanjetType",
                      },
                    },
                    _vm._l(_vm.chanjetTypeCode, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[26].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("T+销货状态")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.chanjetDeliveryType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "chanjetDeliveryType", $$v)
                        },
                        expression: "form.chanjetDeliveryType",
                      },
                    },
                    _vm._l(_vm.chanjetDeliveryTypeCode, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[7].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("截停标识")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.stopFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "stopFlag", $$v)
                        },
                        expression: "form.stopFlag",
                      },
                    },
                    _vm._l(_vm.isStop, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[23].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("汇总标识")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.gatherType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "gatherType", $$v)
                        },
                        expression: "form.gatherType",
                      },
                    },
                    _vm._l(_vm.isGather, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[8].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("收货国家")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.form.countryCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "countryCode", $$v)
                        },
                        expression: "form.countryCode",
                      },
                    },
                    _vm._l(_vm.countryList, function (item) {
                      return _c("el-option", {
                        attrs: { label: item.country, value: item.countryCode },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[9].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("支付方式")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择" },
                      on: { change: _vm.changePayType },
                      model: {
                        value: _vm.form.payTypeList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payTypeList", $$v)
                        },
                        expression: "form.payTypeList",
                      },
                    },
                    _vm._l(_vm.payTypes, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[27].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("退回")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.returnFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "returnFlag", $$v)
                        },
                        expression: "form.returnFlag",
                      },
                    },
                    _vm._l(_vm.returnFlagList, function (item) {
                      return _c("el-option", {
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[10].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("物流名称")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    },
                    _vm._l(_vm.names, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.valueCn },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[11].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("产品编号")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.productNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productNo", $$v)
                        },
                        expression: "form.productNo",
                      },
                    },
                    _vm._l(_vm.productNo, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[12].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("物流单号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[13].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("物流状态")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择" },
                      on: { change: _vm.changeStatus },
                      model: {
                        value: _vm.form.statusList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "statusList", $$v)
                        },
                        expression: "form.statusList",
                      },
                    },
                    _vm._l(_vm.status, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[14].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("流水号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.serialNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "serialNumber", $$v)
                      },
                      expression: "form.serialNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[15].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("联系人邮箱")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.contactEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactEmail", $$v)
                      },
                      expression: "form.contactEmail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[16].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("联系人手机号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.contactTelephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactTelephone", $$v)
                      },
                      expression: "form.contactTelephone",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[17].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("优惠码")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.promoCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "promoCode", $$v)
                      },
                      expression: "form.promoCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[18].flag
            ? _c("li", [
                _c("h5", [_vm._v("支付起始时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.startPayTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startPayTime", $$v)
                        },
                        expression: "form.startPayTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[19].flag
            ? _c("li", [
                _c("h5", [_vm._v("支付截止时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.endPayTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endPayTime", $$v)
                        },
                        expression: "form.endPayTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[20].flag
            ? _c("li", [
                _c("h5", [_vm._v("发货起始时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.startDeliverTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startDeliverTime", $$v)
                        },
                        expression: "form.startDeliverTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[21].flag
            ? _c("li", [
                _c("h5", [_vm._v("发货截止时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.endDeliverTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endDeliverTime", $$v)
                        },
                        expression: "form.endDeliverTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "setSearch" },
          [
            _c(
              "el-dropdown",
              { attrs: { trigger: "click" }, on: { command: _vm.setSearch } },
              [
                _c("span", { staticClass: "el-dropdown-link" }, [
                  _c("img", {
                    attrs: { src: require("../../assets/img/setting.png") },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-menu",
                  { attrs: { slot: "dropdown" }, slot: "dropdown" },
                  [
                    _c("el-dropdown-item", { attrs: { command: "1" } }, [
                      _vm._v("筛选框"),
                    ]),
                    _vm._v(" "),
                    _c("el-dropdown-item", { attrs: { command: "2" } }, [
                      _vm._v("条目"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _vm.exportFlag
          ? _c(
              "div",
              {
                staticClass: "export",
                style: {
                  left: _vm.outflagLeft + "px",
                  top: _vm.outflagTop + "px",
                },
              },
              [
                _c("div", { on: { click: _vm.derive } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/export.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("导出(EXCEL)")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.deriveCsv } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/csv.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("导出(CSV)")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.dhlStockingDerive } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/export.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("制单&备货导出")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.shopderive } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/export.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("商品明细导出")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.skuderive } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/sku.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("SKU导出(EXCEL)")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.skuderivecsv } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/csv.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("SKU导出(CSV)")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.orderderive } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/export.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("DHL运单导出")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.dhlderive } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/export.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("DHL申报导出")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.dhlInvoiceDerive } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/export.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("DHL发票导出")]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.invoiceShow
          ? _c(
              "div",
              {
                staticClass: "invoice",
                style: {
                  left: _vm.outflagLeft + "px",
                  top: _vm.outflagTop + "px",
                },
              },
              [
                _c("div", { on: { click: _vm.planInvoice } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/invoices.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("普通发票")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.firmInvoice } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/invoices.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("抬头发票")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.SFInvoice } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/invoices.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("SF合同")]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.gerFlag
          ? _c(
              "div",
              {
                staticClass: "invoice",
                style: {
                  left: _vm.outflagLeft + "px",
                  top: _vm.outflagTop + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.Freight(1)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/iconfont/ger.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("GER运费")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.Freight(2)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/iconfont/ger.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("GER发票")]),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.deriveFlag
          ? _c(
              "div",
              {
                staticClass: "invoice",
                style: {
                  left: _vm.outflagLeft + "px",
                  top: _vm.outflagTop + "px",
                },
              },
              [
                _c("div", { on: { click: _vm.foreign } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("境内外销售表")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.ditch } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("各渠道销售表")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.accounts } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("结算表")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.excountry } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("销量汇总")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.dealerDetail } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("销售明细")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.lamyDetail } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("凌美")]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.deriveFlagProduct
          ? _c(
              "div",
              {
                staticClass: "invoice",
                style: {
                  left: _vm.outflagLeft + "px",
                  top: _vm.outflagTop + "px",
                },
              },
              [
                _c("div", { on: { click: _vm.foreignProduct } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("单品境内外统计")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.ditchProduct } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("单品各渠道统计")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.dealerDetailProduct } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("单品销售明细")]),
                ]),
                _vm._v(" "),
                _c("div", { on: { click: _vm.jpSpecials } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/charts.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("日亚备货统计")]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.clearanceShow
          ? _c(
              "div",
              {
                staticClass: "logistiClear",
                style: {
                  left: _vm.outflagLeft + "px",
                  top: _vm.outflagTop + "px",
                },
              },
              [
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clearance(1)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/iconfont/clearance.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("报关材料-SKU")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.clearance(3)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/iconfont/clearance.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("报关材料-合")]),
                  ]
                ),
                _vm._v(" "),
                _c("div", { on: { click: _vm.splitClick } }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/clearance.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("报关材料(均等拆分)")]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("ol", { ref: "btnScroll", attrs: { id: "btnScroll" } }, [
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", { on: { click: _vm.search } }, [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", { on: { click: _vm.empty } }, [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.details } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/detail.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("订单详情")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/other/dealer/add",
                  expression: "'/other/dealer/add'",
                },
              ],
              on: { click: _vm.add },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/lu.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("录入")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/push/again",
                  expression: "'/order/logistics/push/again'",
                },
              ],
              on: { click: _vm.tpush },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/sale.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("销售补推")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/supplementary",
                  expression: "'/order/logistics/supplementary'",
                },
              ],
              on: { click: _vm.suppleAdd },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/supple.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("补录")]),
            ]
          ),
          _vm._v(" "),
          _vm.userType == "com"
            ? _c(
                "li",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.submitUploadloading,
                      expression: "submitUploadloading",
                    },
                    {
                      name: "permit",
                      rawName: "v-permit",
                      value: "/order/shopify/order/import",
                      expression: "'/order/shopify/order/import'",
                    },
                  ],
                  on: { click: _vm.submitUpload },
                },
                [_vm._m(0)]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/print",
                  expression: "'/order/logistics/print'",
                },
              ],
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/print.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { on: { click: _vm.print } }, [_vm._v("打印")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/custom/print",
                  expression: "'/order/logistics/custom/print'",
                },
              ],
              staticClass: "clearanli",
              on: { click: _vm.clearanContent },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/clearance.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("报关材料")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/shipment",
                  expression: "'/order/logistics/shipment'",
                },
              ],
              on: { click: _vm.sendcode },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/QR.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("扫码出货")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/push/again/delivery",
                  expression: "'/order/logistics/push/again/delivery'",
                },
              ],
              on: { click: _vm.tpushDelivery },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/goods.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("销货补推")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/us/batch/delivery",
                  expression: "'/order/us/batch/delivery'",
                },
              ],
            },
            [
              _c("div", { staticClass: "upload-wrapper" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/iconfont/batch-delivery.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  ref: "uploadDeliveryFile",
                  staticClass: "upload-file",
                  attrs: {
                    id: "file",
                    type: "file",
                    name: "file",
                    multiple: "",
                  },
                  on: { change: _vm.submitDeliveryUpload },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("批量出货")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/location/change",
                  expression: "'/location/change'",
                },
              ],
              on: { click: _vm.locationChange },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/stock.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("仓位调整")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dhl/push",
                  expression: "'/order/dhl/push'",
                },
              ],
              on: { click: _vm.dhlPush },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/beihuo.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("DHL推送")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dhl/download",
                  expression: "'/order/dhl/download'",
                },
              ],
              on: { click: _vm.waybilldownload },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/export.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("DHL运单下载")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/dhl/stocking/import",
                  expression: "'/order/dhl/stocking/import'",
                },
              ],
            },
            [
              _c("div", { staticClass: "upload-wrapper" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/iconfont/beihuo.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  ref: "uploadDhlStockingFile",
                  staticClass: "upload-file",
                  attrs: {
                    id: "file",
                    type: "file",
                    name: "file",
                    multiple: "",
                  },
                  on: { change: _vm.submitDhlStockingUpload },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("DHL运单制单")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm.userType == "com"
            ? _c(
                "li",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit",
                      value: "/order/logistics/gather",
                      expression: "'/order/logistics/gather'",
                    },
                  ],
                  on: { click: _vm.gather },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/iconfont/gather.png"),
                      alt: "",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("一键汇总")]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/account",
                  expression: "'/order/logistics/account'",
                },
              ],
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/account.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { on: { click: _vm.account } }, [_vm._v("到账")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/info/pay/callback",
                  expression: "'/order/info/pay/callback'",
                },
              ],
              on: { click: _vm.callBack },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/pay.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("支付回调")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/logistics/invoice/all",
                  expression: "'/logistics/invoice/all'",
                },
              ],
              staticClass: "lastli",
              on: { click: _vm.invoiceContent },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/invoice.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("发票")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/logistics/export",
                  expression: "'/order/logistics/export'",
                },
              ],
              staticClass: "lastli",
              on: { click: _vm.exportContent },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/export.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("导出")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/riya/import",
                  expression: "'/riya/import'",
                },
              ],
            },
            [
              _c("div", { staticClass: "upload-wrapper" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/iconfont/upload.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  ref: "uploadFileRiya",
                  staticClass: "upload-file",
                  attrs: {
                    id: "file",
                    type: "file",
                    name: "file",
                    multiple: "",
                  },
                  on: { change: _vm.submitUploadRiya },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("日亚导入")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/tm/import",
                  expression: "'/tm/import'",
                },
              ],
            },
            [
              _c("div", { staticClass: "upload-wrapper" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/iconfont/trade.png"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  ref: "uploadTmFile",
                  staticClass: "upload-file",
                  attrs: {
                    id: "file",
                    type: "file",
                    name: "file",
                    multiple: "",
                  },
                  on: { change: _vm.submitUploadTm },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("天猫&京东导入")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/logistics/chart",
                  expression: "'/logistics/chart'",
                },
              ],
              staticClass: "lastlis",
              on: { click: _vm.derives },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/charts.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("报表")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/product/logistics",
                  expression: "'/product/logistics'",
                },
              ],
              staticClass: "lastli",
              on: { click: _vm.derivesProduct },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/charts.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("单品统计")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page", staticStyle: { "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next,jumper",
              "page-size": 20,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: {
            width: "100%",
            "margin-top": "10px",
            "margin-bottom": "30px",
          },
          attrs: { data: _vm.tableData, border: "" },
          on: {
            "selection-change": _vm.handleSelectionChange,
            "expand-change": _vm.rowExpand,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loadings,
                            expression: "loadings",
                          },
                        ],
                        staticStyle: { width: "100%", "margin-top": "5px" },
                        attrs: { data: props.row.children, border: "" },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "姓名",
                            prop: "receiveName",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "国家",
                            prop: "country",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "省",
                            "show-overflow-tooltip": "",
                            prop: "provinceName",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "市",
                            "show-overflow-tooltip": "",
                            prop: "city",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "详细地址",
                            "show-overflow-tooltip": "",
                            prop: "address",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "城市邮编",
                            prop: "cityPostalCode",
                          },
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            align: "center",
                            label: "收货人手机号",
                            prop: "telephone",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { align: "center", label: "操作", width: "150" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/logistics/stop",
                                expression: "'/order/logistics/stop'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "截停",
                              placement: "bottom",
                            },
                          },
                          [
                            (scope.row.status == "1" ||
                              scope.row.status == "19" ||
                              scope.row.status == "20") &&
                            scope.row.stopFlag == "Y" &&
                            !scope.row.orderNo.includes("~") &&
                            scope.row.locationPosition != "US" &&
                            scope.row.locationPosition != "USRW"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/stops.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.logisticsStop(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/logistics/stop",
                                expression: "'/order/logistics/stop'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "取消截停",
                              placement: "bottom",
                            },
                          },
                          [
                            (scope.row.status == "1" ||
                              scope.row.status == "19" ||
                              scope.row.status == "20") &&
                            scope.row.stopFlag == "N" &&
                            scope.row.locationPosition != "US" &&
                            scope.row.locationPosition != "USRW"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/stopCancel.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.logisticsStopCan(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/all/money",
                                expression: "'/all/money'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "申请退款",
                              placement: "bottom",
                            },
                          },
                          [
                            scope.row.status == "1" &&
                            !scope.row.orderNo.includes("~") &&
                            scope.row.locationPosition != "US" &&
                            scope.row.locationPosition != "USRW" &&
                            scope.row.dealerId != "18"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/returnMoney.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderApply(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/logistics/delivery",
                                expression: "'/order/logistics/delivery'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "出货",
                              placement: "bottom",
                            },
                          },
                          [
                            scope.row.status == "19" &&
                            !scope.row.orderNo.includes("~") &&
                            scope.row.locationPosition != "US" &&
                            scope.row.locationPosition != "USRW"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/car.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.shipments(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/logistics/update",
                                expression: "'/order/logistics/update'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            scope.row.locationPosition != "US" &&
                            scope.row.locationPosition != "USRW"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/amend.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeDealer(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "order/logistics/delete",
                                expression: "'order/logistics/delete'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "删除",
                              placement: "bottom",
                            },
                          },
                          [
                            (scope.row.status == "1" &&
                              scope.row.locationPosition != "US" &&
                              scope.row.locationPosition != "USRW") ||
                            scope.row.dealerId == "17"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/delete.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.dele(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/logistics/copy/detail",
                                expression: "'/logistics/copy/detail'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "复制链接",
                              placement: "bottom",
                            },
                          },
                          [
                            !scope.row.orderNo.includes("~") &&
                            (scope.row.dealerId == "3" ||
                              scope.row.dealerId == "4")
                              ? _c("img", {
                                  directives: [
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard",
                                      value: function () {
                                        return _vm.handleCopy(scope)
                                      },
                                      expression: "() => handleCopy(scope)",
                                    },
                                    {
                                      name: "clipboard",
                                      rawName: "v-clipboard:success",
                                      value: _vm.clipboardSuccessHandler,
                                      expression: "clipboardSuccessHandler",
                                      arg: "success",
                                    },
                                  ],
                                  attrs: {
                                    src: require("../../assets/iconfont/copy.png"),
                                    alt: "",
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/logistics/email/send",
                                expression: "'/logistics/email/send'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "邮件补发",
                              placement: "bottom",
                            },
                          },
                          [
                            !scope.row.orderNo.includes("~")
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/emails.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emailReissue(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.show2Column[0].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "渠道",
                  width: "150",
                  "show-overflow-tooltip": "",
                  prop: "dealer",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[28].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "区域仓",
                  width: "150",
                  "show-overflow-tooltip": "",
                  prop: "locationPositionName",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[32].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "航线",
                  width: "150",
                  "show-overflow-tooltip": "",
                  prop: "airLine",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[1].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单号",
                  width: "150",
                  "show-overflow-tooltip": "",
                  prop: "orderNo",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[2].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单序号",
                  width: "150",
                  "show-overflow-tooltip": "",
                  prop: "serial",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[31].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "T+销售状态",
                  formatter: _vm.chanjetTypes,
                  "show-overflow-tooltip": "",
                  prop: "chanjetType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[33].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "T+销货状态",
                  formatter: _vm.chanjetDeliveryTypes,
                  "show-overflow-tooltip": "",
                  prop: "chanjetDeliveryType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[3].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "截停标识",
                  formatter: _vm.stops,
                  "show-overflow-tooltip": "",
                  prop: "stopFlag",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[34].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "试用标识",
                  formatter: _vm.trialTypes,
                  "show-overflow-tooltip": "",
                  prop: "trialType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[30].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "汇总标识",
                  formatter: _vm.gathers,
                  "show-overflow-tooltip": "",
                  prop: "gatherType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[35].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "退回",
                  "show-overflow-tooltip": "",
                  prop: "returnFlag",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[15].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "状态", prop: "statusName" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[4].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "打印次数",
                  "show-overflow-tooltip": "",
                  prop: "counts",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[5].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "交易号",
                  "show-overflow-tooltip": "",
                  prop: "tradeNo",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[6].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "类型",
                  prop: "type",
                  formatter: _vm.types,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[7].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "支付方式", prop: "payType" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[8].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "售后编号",
                  "show-overflow-tooltip": "",
                  prop: "customerNumber",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[9].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "物流名称", prop: "name" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[10].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "物流单号",
                  "show-overflow-tooltip": "",
                  prop: "number",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[11].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "币种", prop: "unit" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[12].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "运费", prop: "price" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[13].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "商品总价",
                  prop: "productTotalPrice",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[32].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "税费", prop: "dutyTaxes" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[14].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单总价",
                  prop: "orderPrice",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[16].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "到账",
                  prop: "isToAccount",
                  formatter: _vm.isToAccount,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[17].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "设备号",
                  "show-overflow-tooltip": "",
                  prop: "equipmentNumber",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[18].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "流水号",
                  "show-overflow-tooltip": "",
                  prop: "serialNumber",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[19].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系人邮箱",
                  "show-overflow-tooltip": "",
                  prop: "contactEmail",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[20].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "联系人手机号",
                  "show-overflow-tooltip": "",
                  prop: "contactTelephone",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[21].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "优惠码",
                  "show-overflow-tooltip": "",
                  prop: "promoCode",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[22].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "发货时间",
                  "show-overflow-tooltip": "",
                  prop: "deliverTime",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[23].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "支付时间",
                  "show-overflow-tooltip": "",
                  prop: "payTime",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[24].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "创建人", prop: "createUser" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[25].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "创建时间",
                  "show-overflow-tooltip": "",
                  prop: "createTime",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[26].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "修改人", prop: "updateUser" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.show2Column[27].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "修改时间",
                  "show-overflow-tooltip": "",
                  prop: "updateTime",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发货",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: function ($event) {
              return _vm.putClose("putform")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "putform",
              attrs: {
                model: _vm.putform,
                rules: _vm.putrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: "",
                      maxlength: "30",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.putform.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.putform, "orderNo", $$v)
                      },
                      expression: "putform.orderNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showEl
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "渠道",
                        "label-width": _vm.formLabelWidth,
                        prop: "dealer",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: "",
                          maxlength: "30",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.putform.dealer,
                          callback: function ($$v) {
                            _vm.$set(_vm.putform, "dealer", $$v)
                          },
                          expression: "putform.dealer",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "姓名",
                    "label-width": _vm.formLabelWidth,
                    prop: "receiveName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.receiveName,
                      callback: function ($$v) {
                        _vm.receiveName = $$v
                      },
                      expression: "receiveName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "国家",
                    "label-width": _vm.formLabelWidth,
                    prop: "country",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.country,
                      callback: function ($$v) {
                        _vm.country = $$v
                      },
                      expression: "country",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "省",
                    "label-width": _vm.formLabelWidth,
                    prop: "provinceName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "40",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.putform.provinceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.putform, "provinceName", $$v)
                      },
                      expression: "putform.provinceName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "市/区/县",
                    "label-width": _vm.formLabelWidth,
                    prop: "city",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "64",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.putform.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.putform, "city", $$v)
                      },
                      expression: "putform.city",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "城市邮编",
                    "label-width": _vm.formLabelWidth,
                    prop: "cityPostalCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.cityPostalCode,
                      callback: function ($$v) {
                        _vm.cityPostalCode = $$v
                      },
                      expression: "cityPostalCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "详细地址",
                    "label-width": _vm.formLabelWidth,
                    prop: "address",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.address,
                      callback: function ($$v) {
                        _vm.address = $$v
                      },
                      expression: "address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "收货人手机号",
                    "label-width": _vm.formLabelWidth,
                    prop: "telephone",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.telephone,
                      callback: function ($$v) {
                        _vm.telephone = $$v
                      },
                      expression: "telephone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.name,
                      callback: function ($$v) {
                        _vm.name = $$v
                      },
                      expression: "name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "币种",
                    "label-width": _vm.formLabelWidth,
                    prop: "unit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.unit,
                      callback: function ($$v) {
                        _vm.unit = $$v
                      },
                      expression: "unit",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "运费",
                    "label-width": _vm.formLabelWidth,
                    prop: "price",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      disabled: "",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.price,
                      callback: function ($$v) {
                        _vm.price = $$v
                      },
                      expression: "price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "number",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", autocomplete: "off" },
                    model: {
                      value: _vm.putform.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.putform, "number", $$v)
                      },
                      expression: "putform.number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isShow
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "equiment",
                      attrs: {
                        label: "设备号",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    _vm._l(_vm.equipmentCounts, function (v, i) {
                      return _c("el-input", {
                        attrs: { maxlength: "30", autocomplete: "off" },
                        on: {
                          blur: function ($event) {
                            return _vm.equimentNumber($event)
                          },
                        },
                        model: {
                          value: _vm.putform.equipmentNumberList[i].value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.putform.equipmentNumberList[i],
                              "value",
                              $$v
                            )
                          },
                          expression: "putform.equipmentNumberList[i].value",
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTables",
                  staticStyle: { width: "100%", "margin-top": "30px" },
                  attrs: { data: _vm.tableDatas1, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loadings,
                                    expression: "loadings",
                                  },
                                ],
                                ref: "multipleTable_" + props.row.productNo,
                                staticStyle: {
                                  width: "100%",
                                  "margin-top": "5px",
                                },
                                attrs: {
                                  data: props.row.productContainsVOList,
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "产品",
                                    prop: "productNameCn",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "产品编号",
                                    prop: "productNo",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "数量",
                                    "show-overflow-tooltip": "",
                                    prop: "count",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "仓位",
                                    "show-overflow-tooltip": "",
                                    prop: "position",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: scope.row.position,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "position",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.position",
                                                },
                                              },
                                              _vm._l(
                                                _vm.positionList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.valueCn,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单号",
                      "show-overflow-tooltip": "",
                      prop: "orderNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品编号",
                      "show-overflow-tooltip": "",
                      prop: "productNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "数量",
                      "show-overflow-tooltip": "",
                      prop: "count",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "币种",
                      "show-overflow-tooltip": "",
                      prop: "unit",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "金额",
                      "show-overflow-tooltip": "",
                      prop: "price",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.putClose("putform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.sure("putform")
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "扫码出货",
            visible: _vm.dialogCodeVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogCodeVisible = $event
            },
            close: _vm.codeClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "codeform",
              attrs: {
                model: _vm.codeform,
                rules: _vm.coderules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderNo",
                  },
                },
                [
                  _c("el-input", {
                    ref: "codeorder",
                    attrs: { maxlength: "30", autocomplete: "on" },
                    on: { input: _vm.changecode },
                    model: {
                      value: _vm.codeform.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.codeform, "orderNo", $$v)
                      },
                      expression: "codeform.orderNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showEl
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "渠道",
                        "label-width": _vm.formLabelWidth,
                        prop: "dealer",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30" },
                        model: {
                          value: _vm.codeform.dealer,
                          callback: function ($$v) {
                            _vm.$set(_vm.codeform, "dealer", $$v)
                          },
                          expression: "codeform.dealer",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "number",
                  },
                },
                [
                  _c("el-input", {
                    ref: "codenumber",
                    attrs: { maxlength: "50", autocomplete: "off" },
                    on: { input: _vm.changenumber },
                    model: {
                      value: _vm.codeform.number,
                      callback: function ($$v) {
                        _vm.$set(_vm.codeform, "number", $$v)
                      },
                      expression: "codeform.number",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isShow
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "equiment",
                      attrs: {
                        label: "设备号",
                        "label-width": _vm.formLabelWidth,
                        prop: "equipmentNumberList",
                      },
                    },
                    _vm._l(_vm.equipmentCount, function (v, i) {
                      return _c("el-input", {
                        key: i,
                        ref: "code" + i,
                        refInFor: true,
                        attrs: { maxlength: "30", autocomplete: "off" },
                        on: {
                          input: function () {
                            return _vm.changequipment(i + 1)
                          },
                        },
                        model: {
                          value: _vm.codeform.equipmentNumberList[i].value,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.codeform.equipmentNumberList[i],
                              "value",
                              $$v
                            )
                          },
                          expression: "codeform.equipmentNumberList[i].value",
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.returnflags
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          ref: "multipleTables",
                          staticStyle: { width: "100%", "margin-top": "30px" },
                          attrs: { data: _vm.tableDatas, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.loadings,
                                              expression: "loadings",
                                            },
                                          ],
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: {
                                            data: props.row
                                              .productContainsVOList,
                                            border: "",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "产品",
                                              prop: "productNameCn",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "产品编号",
                                              prop: "productNo",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "数量",
                                              "show-overflow-tooltip": "",
                                              prop: "count",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "仓位",
                                              "show-overflow-tooltip": "",
                                              prop: "position",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function (scope) {
                                                    return [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                          },
                                                          attrs: {
                                                            placeholder:
                                                              "请选择",
                                                          },
                                                          model: {
                                                            value:
                                                              scope.row
                                                                .position,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                scope.row,
                                                                "position",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "scope.row.position",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.positionList,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: index,
                                                                attrs: {
                                                                  label:
                                                                    item.valueCn,
                                                                  value:
                                                                    item.value,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4097744502
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "订单号",
                              "show-overflow-tooltip": "",
                              prop: "orderNo",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "产品编号",
                              "show-overflow-tooltip": "",
                              prop: "productNo",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "数量",
                              "show-overflow-tooltip": "",
                              prop: "count",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "币种",
                              "show-overflow-tooltip": "",
                              prop: "unit",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "金额",
                              "show-overflow-tooltip": "",
                              prop: "price",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.save },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.entersave($event)
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改",
            visible: _vm.dialogFormChangeVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormChangeVisible = $event
            },
            close: function ($event) {
              return _vm.changeClose("changeform")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "changeform",
              attrs: {
                model: _vm.changeform,
                rules: _vm.rules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "订单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "orderNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.changeform.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "orderNo", $$v)
                      },
                      expression: "changeform.orderNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "姓名",
                        "label-width": _vm.formLabelWidth,
                        prop: "receiveName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.receiveName,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "receiveName", $$v)
                          },
                          expression: "changeform.receiveName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "国家",
                        "label-width": _vm.formLabelWidth,
                        prop: "country",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "255", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.country,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "country", $$v)
                          },
                          expression: "changeform.country",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "省",
                        "label-width": _vm.formLabelWidth,
                        prop: "provinceName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "40", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.provinceName,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "provinceName", $$v)
                          },
                          expression: "changeform.provinceName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "市/区/县",
                        "label-width": _vm.formLabelWidth,
                        prop: "city",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "64", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.city,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "city", $$v)
                          },
                          expression: "changeform.city",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "城市邮编",
                        "label-width": _vm.formLabelWidth,
                        prop: "cityPostalCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.cityPostalCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "cityPostalCode", $$v)
                          },
                          expression: "changeform.cityPostalCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "详细地址",
                        "label-width": _vm.formLabelWidth,
                        prop: "address",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "255", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "address", $$v)
                          },
                          expression: "changeform.address",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "收货人手机号",
                        "label-width": _vm.formLabelWidth,
                        prop: "telephone",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.telephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "telephone", $$v)
                          },
                          expression: "changeform.telephone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系人手机号",
                        "label-width": _vm.formLabelWidth,
                        prop: "contactTelephone",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "20", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.contactTelephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "contactTelephone", $$v)
                          },
                          expression: "changeform.contactTelephone",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "联系人邮箱",
                        "label-width": _vm.formLabelWidth,
                        prop: "contactEmail",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.contactEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "contactEmail", $$v)
                          },
                          expression: "changeform.contactEmail",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "物流名称",
                        "label-width": _vm.formLabelWidth,
                        prop: "logisticsName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "60", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.logisticsName,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "logisticsName", $$v)
                          },
                          expression: "changeform.logisticsName",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isSupplementaryShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "物流单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "number",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.number,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "number", $$v)
                          },
                          expression: "changeform.number",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isShow
                ? _c(
                    "el-form-item",
                    {
                      staticClass: "equiment",
                      attrs: {
                        label: "设备号",
                        "label-width": _vm.formLabelWidth,
                      },
                    },
                    _vm._l(_vm.changeform.equipmentNumberList, function (v, i) {
                      return _c("el-input", {
                        attrs: { maxlength: "30", autocomplete: "off" },
                        on: {
                          blur: function ($event) {
                            return _vm.equimentNumber($event)
                          },
                        },
                        model: {
                          value: v.value,
                          callback: function ($$v) {
                            _vm.$set(v, "value", $$v)
                          },
                          expression: "v.value",
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注",
                        "label-width": _vm.formLabelWidth,
                        prop: "remark",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.changeform.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "remark", $$v)
                          },
                          expression: "changeform.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.changeClose("changeform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.changeloading,
                      expression: "changeloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.keep("changeform")
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "到账",
            visible: _vm.dialogFormAccount,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormAccount = $event
            },
            close: _vm.accountClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "accountform",
              attrs: {
                model: _vm.accountform,
                rules: _vm.accountrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "是否到账",
                    "label-width": _vm.formLabelWidth,
                    prop: "isToAccount",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.accountform.isToAccount,
                        callback: function ($$v) {
                          _vm.$set(_vm.accountform, "isToAccount", $$v)
                        },
                        expression: "accountform.isToAccount",
                      },
                    },
                    _vm._l(_vm.isAccount, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.accountClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.accountSure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "proforminvoice",
          attrs: {
            title: _vm.tit,
            visible: _vm.dialogFormInvoice,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormInvoice = $event
            },
            close: _vm.invoiceClose,
          },
        },
        [
          _c("div", { attrs: { id: "invoiceForm" } }, [
            this.tit == "抬头发票"
              ? _c("div", [
                  _c("img", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      src: "https://supernote.com.cn/user/ratta.png",
                      alt: "",
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "protitle",
                staticStyle: { display: "flex", "justify-content": "center" },
              },
              [
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.profoma,
                        callback: function ($$v) {
                          _vm.profoma = $$v
                        },
                        expression: "profoma",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.profoma) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "invoicetit",
                staticStyle: {
                  display: "flex",
                  "justify-content": "flex-end",
                  "font-weight": "700",
                  "font-size": "12px",
                },
              },
              [
                _vm._v("INVOICE\n        NO:\n        "),
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.invoiceNo,
                        callback: function ($$v) {
                          _vm.invoiceNo = $$v
                        },
                        expression: "invoiceNo",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.invoiceNo) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "invoicetit",
                staticStyle: {
                  "margin-top": "5px",
                  display: "flex",
                  "justify-content": "flex-end",
                  "font-weight": "700",
                  "font-size": "12px",
                },
              },
              [
                _vm._v("INVOICE DATE:\n        "),
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.date) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sender", staticStyle: { "font-weight": "bold" } },
              [_vm._v("SENDER:")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "cont" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("COMPANY NAME:\n            "),
                    _c("div", { staticStyle: { "margin-left": "78px" } }, [
                      _vm._v("Ratta Global LLC"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("ADDERSS:\n            "),
                    _c("div", { staticStyle: { "margin-left": "120px" } }, [
                      _vm._v(
                        "Room 301,Building No.1,168 Jixin Road,Minhang\n              District,Shanghai,P.R.China\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("PHONE:\n            "),
                    _c("div", { staticStyle: { "margin-left": "135px" } }, [
                      _vm._v("(+86)21-34537701"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div", { staticStyle: { opacity: "0" } }, [
                _c("div", { staticClass: "cont1" }, [_vm._v("COMPANY NAME:")]),
                _vm._v(" "),
                _c("div", { staticClass: "cont1" }, [_vm._v("ADDERSS:")]),
                _vm._v(" "),
                _c("div", { staticClass: "cont1" }, [_vm._v("PHONE:")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "sender",
                staticStyle: { "margin-top": "15px", "font-weight": "bold" },
              },
              [_vm._v("RECEIVER:")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "cont" }, [
              _c("div", [
                this.tit == "抬头发票"
                  ? _c(
                      "div",
                      {
                        staticClass: "cont1",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _vm._v("COMPANY NAME:\n            "),
                        this.tit == "抬头发票"
                          ? _c(
                              "div",
                              {
                                staticClass: "cont2",
                                staticStyle: { "margin-left": "83px" },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: _vm.receiverCompanyName,
                                        callback: function ($$v) {
                                          _vm.receiverCompanyName = $$v
                                        },
                                        expression: "receiverCompanyName",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.receiverCompanyName
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("ADDRESS:\n            "),
                    _c(
                      "div",
                      {
                        staticClass: "cont2",
                        staticStyle: { "margin-left": "140px" },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.receiverAddress,
                                callback: function ($$v) {
                                  _vm.receiverAddress = $$v
                                },
                                expression: "receiverAddress",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: {
                                textContent: _vm._s(_vm.receiverAddress),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("Phone:\n            "),
                    _c(
                      "div",
                      {
                        staticClass: "cont2",
                        staticStyle: { "margin-left": "167px" },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.receiverPhone,
                                callback: function ($$v) {
                                  _vm.receiverPhone = $$v
                                },
                                expression: "receiverPhone",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: {
                                textContent: _vm._s(_vm.receiverPhone),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("Contact:\n            "),
                    _c(
                      "div",
                      {
                        staticClass: "cont2",
                        staticStyle: { "margin-left": "155px" },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.contact,
                                callback: function ($$v) {
                                  _vm.contact = $$v
                                },
                                expression: "contact",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: { textContent: _vm._s(_vm.contact) },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont0", staticStyle: { display: "flex" } },
                  [
                    _vm.printFlag
                      ? _c("el-input", {
                          staticClass: "contc",
                          attrs: { type: "text" },
                          model: {
                            value: _vm.contact0,
                            callback: function ($$v) {
                              _vm.contact0 = $$v
                            },
                            expression: "contact0",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.printFlag
                      ? _c("el-input", {
                          staticClass: "contc",
                          attrs: { type: "text" },
                          domProps: { textContent: _vm._s(_vm.contact0) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.printFlag
                      ? _c("el-input", {
                          staticClass: "cont3",
                          staticStyle: { "margin-left": "130px" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.contact1,
                            callback: function ($$v) {
                              _vm.contact1 = $$v
                            },
                            expression: "contact1",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.printFlag
                      ? _c("el-input", {
                          staticClass: "cont3",
                          staticStyle: { "margin-left": "130px" },
                          attrs: { type: "text" },
                          domProps: { textContent: _vm._s(_vm.contact1) },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont0", staticStyle: { display: "flex" } },
                  [
                    _vm.printFlag
                      ? _c("el-input", {
                          staticClass: "contc",
                          attrs: { type: "text" },
                          model: {
                            value: _vm.contact2,
                            callback: function ($$v) {
                              _vm.contact2 = $$v
                            },
                            expression: "contact2",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.printFlag
                      ? _c("el-input", {
                          staticClass: "contc",
                          attrs: { type: "text" },
                          domProps: { textContent: _vm._s(_vm.contact2) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.printFlag
                      ? _c("el-input", {
                          staticClass: "cont3",
                          staticStyle: { "margin-left": "130px" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.contact3,
                            callback: function ($$v) {
                              _vm.contact3 = $$v
                            },
                            expression: "contact3",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.printFlag
                      ? _c("el-input", {
                          staticClass: "cont3",
                          staticStyle: { "margin-left": "130px" },
                          attrs: { type: "text" },
                          domProps: { textContent: _vm._s(_vm.contact3) },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { position: "relative" } }, [
              _c(
                "table",
                {
                  staticStyle: { "margin-top": "10px", width: "100%" },
                  attrs: { border: "1", cellspacing: "0" },
                },
                [
                  _c("tr", [
                    _c("th", {
                      staticStyle: { width: "50px", height: "50px" },
                    }),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "font-weight": "200",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("Product name"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "font-weight": "200",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("Model"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "font-weight": "200",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("Unit Price"),
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v("(USD)")]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "font-weight": "200",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("Quantity"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "font-weight": "200",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("Unit"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "font-weight": "200",
                          "font-size": "13px",
                          color: "#000",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-weight": "500" } }, [
                          _vm._v("Amount"),
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v("(USD)")]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.invoiceVolist, function (item, index) {
                    return _c("tr", [
                      _c(
                        "th",
                        { staticStyle: { width: "50px", height: "50px" } },
                        [_vm._v(_vm._s(index + 1))]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "none",
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            width: "150px",
                          },
                        },
                        [
                          _vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: item.productName,
                                  callback: function ($$v) {
                                    _vm.$set(item, "productName", $$v)
                                  },
                                  expression: "item.productName",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                domProps: {
                                  textContent: _vm._s(item.productName),
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "model",
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                            },
                            [
                              _vm.printFlag
                                ? _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: item.model,
                                      callback: function ($$v) {
                                        _vm.$set(item, "model", $$v)
                                      },
                                      expression: "item.model",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.printFlag
                                ? _c("el-input", {
                                    attrs: { type: "text" },
                                    domProps: {
                                      textContent: _vm._s(item.model),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "unit",
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                            },
                            [
                              _vm.printFlag
                                ? _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: item.unitPrice,
                                      callback: function ($$v) {
                                        _vm.$set(item, "unitPrice", $$v)
                                      },
                                      expression: "item.unitPrice",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.printFlag
                                ? _c("el-input", {
                                    attrs: { type: "text" },
                                    domProps: {
                                      textContent: _vm._s(item.unitPrice),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "unit",
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                            },
                            [
                              _vm.printFlag
                                ? _c("el-input", {
                                    attrs: { type: "text" },
                                    model: {
                                      value: item.quantity,
                                      callback: function ($$v) {
                                        _vm.$set(item, "quantity", $$v)
                                      },
                                      expression: "item.quantity",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.printFlag
                                ? _c("el-input", {
                                    attrs: { type: "text" },
                                    domProps: {
                                      textContent: _vm._s(item.quantity),
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "unit",
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                        },
                        [
                          _vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: item.unit,
                                  callback: function ($$v) {
                                    _vm.$set(item, "unit", $$v)
                                  },
                                  expression: "item.unit",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                domProps: { textContent: _vm._s(item.unit) },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "unit",
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                        },
                        [
                          _vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: item.amount,
                                  callback: function ($$v) {
                                    _vm.$set(item, "amount", $$v)
                                  },
                                  expression: "item.amount",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                domProps: { textContent: _vm._s(item.amount) },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  }),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", {
                      staticStyle: { "border-right": "none", height: "50px" },
                    }),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "shipping shippings",
                        staticStyle: {
                          "border-right": "none",
                          "border-left": "none",
                        },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.shippingCost,
                                callback: function ($$v) {
                                  _vm.shippingCost = $$v
                                },
                                expression: "shippingCost",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: {
                                textContent: _vm._s(_vm.shippingCost),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("th", {
                      staticStyle: {
                        "border-right": "none",
                        "border-left": "none",
                      },
                    }),
                    _vm._v(" "),
                    _c("th", {
                      staticStyle: {
                        "border-right": "none",
                        "border-left": "none",
                      },
                    }),
                    _vm._v(" "),
                    _c("th", { staticStyle: { "border-left": "none" } }),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "shipping" },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              on: { blur: _vm.changeShipping },
                              model: {
                                value: _vm.shipping,
                                callback: function ($$v) {
                                  _vm.shipping = $$v
                                },
                                expression: "shipping",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: { textContent: _vm._s(_vm.shipping) },
                              on: { blur: _vm.changeShipping },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th"),
                    _vm._v(" "),
                    _c("th", { staticStyle: { "border-right": "none" } }),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticStyle: {
                          "border-right": "none",
                          "border-left": "none",
                          width: "300px",
                          height: "50px",
                        },
                      },
                      [_vm._v("Total Cost:(USD)")]
                    ),
                    _vm._v(" "),
                    _c("th", {
                      staticStyle: {
                        "border-right": "none",
                        "border-left": "none",
                      },
                    }),
                    _vm._v(" "),
                    _c("th", {
                      staticStyle: {
                        "border-right": "none",
                        "border-left": "none",
                      },
                    }),
                    _vm._v(" "),
                    _c("th", { staticStyle: { "border-left": "none" } }),
                    _vm._v(" "),
                    _c(
                      "th",
                      { staticClass: "units" },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.invoicePrice,
                                callback: function ($$v) {
                                  _vm.invoicePrice = $$v
                                },
                                expression: "invoicePrice",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: {
                                textContent: _vm._s(_vm.invoicePrice),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("img", {
                staticStyle: {
                  width: "200px",
                  height: "150px",
                  position: "absolute",
                  bottom: "-98px",
                  right: "160px",
                  "z-index": "99",
                },
                attrs: { src: "https://supernote.com.cn/user/chapter.png" },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "pric",
                staticStyle: {
                  "text-align": "center",
                  "margin-top": "10px",
                  "border-bottom": "2px solid #000",
                  "padding-bottom": "10px",
                  display: "flex",
                  "align-items": "center",
                },
              },
              [
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.totalPriceUpper,
                        callback: function ($$v) {
                          _vm.totalPriceUpper = $$v
                        },
                        expression: "totalPriceUpper",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.totalPriceUpper) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "tit2",
                staticStyle: { display: "flex", "margin-top": "20px" },
              },
              [
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.email,
                        callback: function ($$v) {
                          _vm.email = $$v
                        },
                        expression: "email",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.email) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            this.tit == "抬头发票"
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "tit2" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bank,
                              callback: function ($$v) {
                                _vm.bank = $$v
                              },
                              expression: "bank",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.bank) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tit2" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.number,
                              callback: function ($$v) {
                                _vm.number = $$v
                              },
                              expression: "number",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.number) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tit2" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bankname,
                              callback: function ($$v) {
                                _vm.bankname = $$v
                              },
                              expression: "bankname",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.bankname) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tit2" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.bankaddress,
                              callback: function ($$v) {
                                _vm.bankaddress = $$v
                              },
                              expression: "bankaddress",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.bankaddress) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tit2" },
                    [
                      _vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            model: {
                              value: _vm.swiftcode,
                              callback: function ($$v) {
                                _vm.swiftcode = $$v
                              },
                              expression: "swiftcode",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.printFlag
                        ? _c("el-input", {
                            attrs: { type: "text" },
                            domProps: { textContent: _vm._s(_vm.swiftcode) },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.invoiceClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.invoiceSure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "print",
          attrs: {
            visible: _vm.dialogFormPrint,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormPrint = $event
            },
            close: _vm.printClose,
          },
        },
        [
          _vm.sureFlag
            ? _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.accountloading,
                          expression: "accountloading",
                        },
                      ],
                      attrs: { type: "primary", disabled: _vm.isDisable },
                      on: { click: _vm.printHtml },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { ref: "orderTable", attrs: { id: "orderTable" } },
            _vm._l(this.printform, function (res) {
              return _c(
                "div",
                {
                  staticClass: "printform",
                  staticStyle: {
                    "padding-right": "15px",
                    "margin-bottom": "900px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "align-items": "center",
                        "margin-bottom": "10px",
                        position: "relative",
                      },
                    },
                    [
                      _c("div"),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "24px",
                            "text-align": "center",
                          },
                        },
                        [_vm._v("出库申请单（" + _vm._s(res.orderType) + "）")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px solid #000",
                            "font-size": "24px",
                            "border-radius": "50%",
                            position: "absolute",
                            right: "185px",
                            padding: "3px 10px",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(res.serialNumber) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "25px",
                            "font-weight": "bold",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "128px",
                                overflow: "hidden",
                                "text-overflow": "ellipsis",
                                "white-space": "nowrap",
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(res.ordePrintLineList[0].receiveName) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "font-size": "13px",
                        "font-weight": "200",
                        "text-align": "right",
                      },
                    },
                    [
                      _vm._v(
                        "\n              制单日期：" +
                          _vm._s(res.deliverDate) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form",
                    {
                      ref: "printforms",
                      refInFor: true,
                      staticClass: "prints",
                      attrs: {
                        model: _vm.printforms,
                        rules: _vm.printrules,
                        "status-icon": true,
                        "show-message": false,
                        "hide-required-asterisk": "",
                      },
                    },
                    [
                      _vm._l(res.ordePrintLineList, function (item) {
                        return _c("div", [
                          !item.orderNo.includes("~")
                            ? _c("div", [
                                _c(
                                  "table",
                                  { staticStyle: { "margin-top": "2px" } },
                                  [
                                    _c("th", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "200" },
                                        },
                                        [
                                          _vm._v(
                                            "渠道：" + _vm._s(item.salesType)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "300" },
                                        },
                                        [
                                          _vm._v(
                                            "订单编号： " + _vm._s(item.orderNo)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " 付款日期： " +
                                              _vm._s(item.payTime)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  { staticStyle: { "margin-top": "-8px" } },
                                  [
                                    _c("th", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "200" },
                                        },
                                        [
                                          _vm._v(
                                            " 收件人：" +
                                              _vm._s(item.receiveName) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "300" },
                                        },
                                        [
                                          _vm._v(
                                            " 交易号：" + _vm._s(item.tradeNo)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " 付款渠道：" + _vm._s(item.payType)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  { staticStyle: { "margin-top": "-8px" } },
                                  [
                                    _c("th", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "200" },
                                        },
                                        [
                                          _vm._v(
                                            " 手机号：" + _vm._s(item.telephone)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "300" },
                                        },
                                        [
                                          _vm._v(
                                            " 邮箱：" +
                                              _vm._s(item.contactEmail)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "结算方式： " + _vm._s(item.unit)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  { staticStyle: { "margin-top": "-8px" } },
                                  [
                                    _c("th", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "200" },
                                        },
                                        [
                                          _vm._v(
                                            " 区域仓：" +
                                              _vm._s(item.locationPosition)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "200" },
                                        },
                                        [
                                          _vm._v(
                                            " 退回：" + _vm._s(item.returnFlag)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  { staticStyle: { "margin-top": "-8px" } },
                                  [
                                    _c("th", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " 收方地址： " +
                                              _vm._s(item.address)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticStyle: {
                                      "margin-top": "2px",
                                      width: "100%",
                                    },
                                    attrs: { border: "1", cellspacing: "0" },
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("序号")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v("商品名称")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "80" },
                                        },
                                        [_vm._v("规格型号")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("数量")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("单价")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("金额")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v("备注")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      item.orderDetailList,
                                      function (result, index) {
                                        return _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(result.serialNumber)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.productName))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.productNo))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.count))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.price))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(result.productTotalPrice)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.remark))]
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                res.flag == "Y"
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "margin-top": "15px",
                                          "margin-bottom": "7px",
                                          color: "#409EFF",
                                          "font-size": "18px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                补录单信息：\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          item.orderNo.includes("~")
                            ? _c("div", [
                                _c("table", [
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "205" },
                                      },
                                      [
                                        _vm._v(
                                          "订单编号： " + _vm._s(item.orderNo)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "300" },
                                      },
                                      [
                                        _vm._v(
                                          "金额：" + _vm._s(item.orderPrice)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "200" },
                                      },
                                      [
                                        _vm._v(
                                          " 付款日期： " + _vm._s(item.payTime)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  { staticStyle: { "margin-top": "-8px" } },
                                  [
                                    _c("th", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "200" },
                                        },
                                        [
                                          _vm._v(
                                            " 付款渠道：" + _vm._s(item.payType)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "text-align": "left",
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "300" },
                                        },
                                        [
                                          _vm._v(
                                            " 交易号：" + _vm._s(item.tradeNo)
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticStyle: {
                                      border: "1px solid skyblue",
                                      "margin-top": "2px",
                                      width: "100%",
                                    },
                                    attrs: { border: "1", cellspacing: "0" },
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("序号")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v("商品名称")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "80" },
                                        },
                                        [_vm._v("规格型号")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("数量")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("单价")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                          attrs: { width: "50" },
                                        },
                                        [_vm._v("金额")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v("备注")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(
                                      item.orderDetailList,
                                      function (result, index) {
                                        return _c("tr", [
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(result.serialNumber)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.productName))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.productNo))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.count))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.price))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(result.productTotalPrice)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                              },
                                            },
                                            [_vm._v(_vm._s(result.remark))]
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ])
                            : _vm._e(),
                        ])
                      }),
                      _vm._v(" "),
                      res.flag == "Y"
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "15px",
                                  "margin-bottom": "15px",
                                  color: "#115c3b",
                                  "font-size": "18px",
                                },
                              },
                              [_vm._v("订单实际商品：")]
                            ),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticStyle: {
                                  border: "1px solid #e8f2ee",
                                  "margin-top": "2px",
                                  width: "100%",
                                },
                                attrs: { border: "1", cellspacing: "0" },
                              },
                              [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "50" },
                                    },
                                    [_vm._v("产品名称")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "50" },
                                    },
                                    [_vm._v("产品型号")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "50" },
                                    },
                                    [_vm._v("数量")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  res.realOrderList,
                                  function (real, index) {
                                    return _c("tr", { key: index }, [
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(real.productName))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(real.productNo))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(real.count))]
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            "padding-right": "100px",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v("申请部门审批:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v("财务:"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v("申请人: " + _vm._s(res.applicantUser)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v("制单人: " + _vm._s(_vm.description)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "13px",
                                "margin-top": "-50px",
                              },
                            },
                            [_vm._v("客户回签:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "13px",
                                "margin-top": "-50px",
                              },
                            },
                            [_vm._v("回签日期:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "1px" } },
                            [
                              res.masterOrderNo
                                ? _c("barcode", {
                                    attrs: {
                                      value: res.masterOrderNo,
                                      width: 2.5,
                                      height: 15,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("div"),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormClearance,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormClearance = $event
            },
            close: _vm.clearanceClose,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-bottom": "5px" },
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.clearanceHtml },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { ref: "clearance", attrs: { id: "clearance" } },
            _vm._l(_vm.productList, function (result, i) {
              return _c("div", { key: i }, [
                _vm.clearanceFlag[i]
                  ? _c("div", { attrs: { id: _vm.myclearance(i, "clear") } }, [
                      _c(
                        "div",
                        {
                          style: {
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "15px",
                            marginTop: i !== 0 ? "500px" : "0px",
                          },
                        },
                        [
                          _c("div", [
                            _c("img", {
                              staticStyle: { width: "60px", height: "60px" },
                              attrs: {
                                src: "https://supernote.com.cn/user/logos.png",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "24px",
                                color: "#000",
                              },
                            },
                            [_vm._v("中华人民共和国海关出口货物报关单")]
                          ),
                          _vm._v(" "),
                          _vm.clearances[i]
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-weight": "600",
                                    "font-size": "24px",
                                    "text-align": "right",
                                    "margin-right": "10px",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                 " +
                                      _vm._s(_vm.clearances[i].orderSerial) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div"),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "justify-content": "space-between",
                            "align-items": "center",
                            "font-size": "10px",
                          },
                        },
                        [
                          _c("div", [_vm._v("预录入编号：")]),
                          _vm._v(" "),
                          _c("div", [_vm._v("海关编号：")]),
                          _vm._v(" "),
                          _c("div", [_vm._v("页码/页数：")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "table",
                        {
                          staticStyle: {
                            "margin-top": "2px",
                            width: "100%",
                            "font-size": "10px",
                          },
                          attrs: { border: "1", cellspacing: "0" },
                        },
                        [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("境内发货人 "),
                                _c("span", { staticClass: "cont" }, [
                                  _vm._v("  91310112777122285A"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cont" }, [
                                _vm._v("上海雷塔智能科技有限公司  311196945X"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("出境关别  "),
                                _c("span", { staticClass: "cont" }, [
                                  _vm._v("2244"),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cont" }, [
                                _vm._v("上海快件"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("出口日期"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "cont",
                                  staticStyle: { opacity: "0" },
                                },
                                [_vm._v("123")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("span", { staticClass: "conts" }, [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("申报日期"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cont",
                                    staticStyle: { opacity: "0" },
                                  },
                                  [_vm._v("123")]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("备案号"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "cont",
                                  staticStyle: { opacity: "0" },
                                },
                                [_vm._v("123")]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("境外收货人"),
                              ]),
                              _vm._v(" "),
                              _vm.clearances[i]
                                ? _c(
                                    "div",
                                    { staticClass: "cont name" },
                                    [
                                      _vm.printFlag && _vm.clearances[i]
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: { input: _vm.changeReceive },
                                            model: {
                                              value:
                                                _vm.clearances[i].receiveName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.clearances[i],
                                                  "receiveName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "clearances[i].receiveName",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.clearances[i].receiveName
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("运输方式"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cont" }, [
                                _vm._v("航空运输"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("运输工具名称及航次号"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "cont",
                                  staticStyle: { opacity: "0" },
                                },
                                [_vm._v("123")]
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-right": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("提运单号"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cont",
                                    staticStyle: { opacity: "0" },
                                  },
                                  [_vm._v("123")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: { "border-left": "none" },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("生产销售单位"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cont" }, [
                                _vm._v("上海雷塔智能科技有限公司"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("监管方式"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cont" }, [
                                _vm._v("一般贸易"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("征免性质"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "cont" }, [
                                _vm._v("一般征税"),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-right": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("许可证号"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cont",
                                    staticStyle: { opacity: "0" },
                                  },
                                  [_vm._v("123")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: { "border-left": "none" },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("合同协议号"),
                              ]),
                              _vm._v(" "),
                              _vm.clearances[i]
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cont airport",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                        width: "150px",
                                        "border-right": "none",
                                        "border-left": "none",
                                      },
                                    },
                                    [
                                      _vm.printFlag && _vm.clearances[i]
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: { input: _vm.changeReceive },
                                            model: {
                                              value:
                                                _vm.clearances[i].invoiceNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.clearances[i],
                                                  "invoiceNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "clearances[i].invoiceNo",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(
                                                _vm.clearances[i].invoiceNo
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("贸易国（地区）"),
                              ]),
                              _vm._v(" "),
                              _vm.clearances[i]
                                ? _c("div", { staticClass: "cont" }, [
                                    _vm._v(_vm._s(_vm.clearances[i].country)),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("运抵国（地区）"),
                              ]),
                              _vm._v(" "),
                              _vm.clearances[i]
                                ? _c("div", { staticClass: "cont" }, [
                                    _vm._v(_vm._s(_vm.clearances[i].country)),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("指运港"),
                              ]),
                              _vm._v(" "),
                              _vm.clearances[i]
                                ? _c("div", { staticClass: "cont" }, [
                                    _vm._v(_vm._s(_vm.clearances[i].country)),
                                  ])
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c("div", { staticClass: "conts" }, [
                                _vm._v("离境口岸"),
                              ]),
                              _vm._v(" "),
                              _vm.airport
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "cont airport",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                        width: "150px",
                                        "border-right": "none",
                                        "border-left": "none",
                                      },
                                    },
                                    [
                                      _vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: { input: _vm.changeReceive },
                                            model: {
                                              value: _vm.airport,
                                              callback: function ($$v) {
                                                _vm.airport = $$v
                                              },
                                              expression: "airport",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(_vm.airport),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "table",
                        {
                          staticStyle: {
                            width: "100%",
                            "border-top": "none",
                            "font-size": "10px",
                          },
                          attrs: { border: "1", cellspacing: "0" },
                        },
                        [
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("包装种类"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "cont" }, [
                                  _vm._v("纸箱"),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("件数"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "cont num" },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: _vm.num,
                                            callback: function ($$v) {
                                              _vm.num = $$v
                                            },
                                            expression: "num",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(_vm.num),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("毛重（千克）"),
                                ]),
                                _vm._v(" "),
                                _vm.clearances[i]
                                  ? _c(
                                      "div",
                                      { staticClass: "cont num" },
                                      [
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value:
                                                  _vm.clearances[i].grossWeight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.clearances[i],
                                                    "grossWeight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "clearances[i].grossWeight",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.clearances[i].grossWeight
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("净重（千克）"),
                                ]),
                                _vm._v(" "),
                                _vm.clearances[i]
                                  ? _c(
                                      "div",
                                      { staticClass: "cont num" },
                                      [
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value:
                                                  _vm.clearances[i].netWeight,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.clearances[i],
                                                    "netWeight",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "clearances[i].netWeight",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.clearances[i].netWeight
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("成交方式"),
                                ]),
                                _vm._v(" "),
                                _vm.cfr
                                  ? _c(
                                      "div",
                                      { staticClass: "cont num" },
                                      [
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value: _vm.cfr,
                                                callback: function ($$v) {
                                                  _vm.cfr = $$v
                                                },
                                                expression: "cfr",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(_vm.cfr),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("运费"),
                                ]),
                                _vm._v(" "),
                                _vm.clearances[i]
                                  ? _c(
                                      "div",
                                      { staticClass: "cont num" },
                                      [
                                        _vm._v(_vm._s(_vm.dollar) + " "),
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value:
                                                  _vm.clearances[i]
                                                    .logisticsPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.clearances[i],
                                                    "logisticsPrice",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "clearances[i].logisticsPrice",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.clearances[i]
                                                    .logisticsPrice
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("保费"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cont",
                                    staticStyle: { opacity: "0" },
                                  },
                                  [_vm._v("123")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "border-top": "none" } },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("杂费"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cont",
                                    staticStyle: { opacity: "0" },
                                  },
                                  [_vm._v("123")]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-top": "none",
                                  "border-bottom": "none",
                                  "font-size": "12px",
                                  height: "40px",
                                },
                              },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("随附单位及编号"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cont",
                                    staticStyle: { opacity: "0" },
                                  },
                                  [_vm._v("123")]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "border-right": "none",
                                  "font-size": "12px",
                                  height: "40px",
                                },
                              },
                              [
                                _c("div", { staticClass: "conts" }, [
                                  _vm._v("标记唛码及备注"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "cont",
                                    staticStyle: { opacity: "0" },
                                  },
                                  [_vm._v("123")]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-left": "none",
                                "border-right": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-left": "none",
                                "border-right": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-left": "none",
                                "border-right": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-left": "none",
                                "border-right": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-left": "none",
                                "border-right": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-left": "none",
                                "border-right": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: { "border-left": "none" },
                            }),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "table",
                        {
                          staticStyle: { width: "100%", "border-top": "none" },
                          attrs: { border: "1", cellspacing: "0" },
                        },
                        [
                          _c(
                            "tr",
                            {
                              staticStyle: {
                                "text-align": "center",
                                "font-size": "12px",
                                color: "#000",
                              },
                            },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("项号")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("商品编号")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("商品名称及规格号")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("数量及单位")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("单价/总价/币值")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("原产国（地区）")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("最终目的国（地区）")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("境内货源地")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                },
                                [_vm._v("征免")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.clearanceList[i], function (item) {
                            return _c(
                              "tr",
                              {
                                staticStyle: {
                                  "text-align": "center",
                                  color: "#000",
                                  "font-size": "12px",
                                  "border-bottom": "none",
                                  height: "250px",
                                },
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "30px",
                                      "border-right": "none",
                                      "font-size": "12px",
                                    },
                                  },
                                  [_vm._v("（）")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-weight": "150",
                                      "font-size": "12px",
                                      width: "115px",
                                      "border-right": "none",
                                      "border-left": "none",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: item.declareProductNo,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "declareProductNo",
                                                $$v
                                              )
                                            },
                                            expression: "item.declareProductNo",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.declareProductNo
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-weight": "150",
                                      "font-size": "12px",
                                      width: "260px",
                                      "border-right": "none",
                                      "border-left": "none",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: item.productName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "productName", $$v)
                                            },
                                            expression: "item.productName",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.productName
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                item.count <= 1
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "unit",
                                        staticStyle: {
                                          width: "60px",
                                          "font-weight": "150",
                                          "font-size": "12px",
                                          height: "200px",
                                          display: "flex",
                                          "justify-content": "center",
                                          "border-bottom": "none",
                                          "border-right": "none",
                                          "border-left": "none",
                                        },
                                      },
                                      [
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "textarea" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value: item.count,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "count", $$v)
                                                },
                                                expression: "item.count",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "textarea" },
                                              domProps: {
                                                textContent: _vm._s(item.count),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" SET\n              "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.count > 1
                                  ? _c(
                                      "td",
                                      {
                                        staticClass: "unit",
                                        staticStyle: {
                                          width: "80px",
                                          "font-weight": "150",
                                          "font-size": "12px",
                                          height: "200px",
                                          display: "flex",
                                          "justify-content": "center",
                                          "border-bottom": "none",
                                          "border-right": "none",
                                          "border-left": "none",
                                        },
                                      },
                                      [
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "textarea" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value: item.count,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "count", $$v)
                                                },
                                                expression: "item.count",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "textarea" },
                                              domProps: {
                                                textContent: _vm._s(item.count),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" SETS\n              "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "none",
                                    staticStyle: {
                                      "font-weight": "150",
                                      "font-size": "12px",
                                      "border-right": "none",
                                      "border-left": "none",
                                      width: "150px",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: item.unitTotalPrice,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "unitTotalPrice",
                                                $$v
                                              )
                                            },
                                            expression: "item.unitTotalPrice",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.unitTotalPrice
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "none",
                                    staticStyle: {
                                      "font-weight": "150",
                                      "font-size": "12px",
                                      "border-right": "none",
                                      "border-left": "none",
                                      width: "80px",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: item.productCountry,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "productCountry",
                                                $$v
                                              )
                                            },
                                            expression: "item.productCountry",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.productCountry
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "none",
                                    staticStyle: {
                                      "font-weight": "150",
                                      "font-size": "12px",
                                      "border-right": "none",
                                      "border-left": "none",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: item.country,
                                            callback: function ($$v) {
                                              _vm.$set(item, "country", $$v)
                                            },
                                            expression: "item.country",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          domProps: {
                                            textContent: _vm._s(item.country),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "none",
                                    staticStyle: {
                                      "font-weight": "150",
                                      "font-size": "12px",
                                      "border-right": "none",
                                      "border-left": "none",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: _vm.other,
                                            callback: function ($$v) {
                                              _vm.other = $$v
                                            },
                                            expression: "other",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          domProps: {
                                            textContent: _vm._s(_vm.other),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass: "none",
                                    staticStyle: {
                                      "font-weight": "150",
                                      "font-size": "12px",
                                      "border-right": "none",
                                      "border-left": "none",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: _vm.others,
                                            callback: function ($$v) {
                                              _vm.others = $$v
                                            },
                                            expression: "others",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "textarea" },
                                          domProps: {
                                            textContent: _vm._s(_vm.others),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "tr",
                            { staticStyle: { "border-bottom": "none" } },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    height: "40px",
                                  },
                                },
                                [_vm._v(" （）")]
                              ),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "tr",
                            { staticStyle: { "border-bottom": "none" } },
                            [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    height: "40px",
                                  },
                                },
                                [_vm._v(" （）")]
                              ),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                              _vm._v(" "),
                              _c("td", {
                                staticStyle: {
                                  "border-right": "none",
                                  "border-left": "none",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              {
                                staticStyle: {
                                  "border-right": "none",
                                  height: "40px",
                                },
                              },
                              [_vm._v(" （）")]
                            ),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                            _vm._v(" "),
                            _c("td", {
                              staticStyle: {
                                "border-right": "none",
                                "border-left": "none",
                              },
                            }),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticStyle: { position: "relative" } }, [
                        _c(
                          "table",
                          {
                            staticStyle: {
                              width: "100%",
                              "border-top": "none",
                              color: "#000",
                            },
                            attrs: { border: "1", cellspacing: "0" },
                          },
                          [
                            _c("tr", { staticStyle: { "font-size": "12px" } }, [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-right": "none",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("特殊关系确认：")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-left": "none",
                                    "border-right": "none",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("价格影响确认：")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-left": "none",
                                    "border-right": "none",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("支付特权使用费确认:")]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "border-left": "none",
                                    "border-right": "none",
                                    "text-align": "center",
                                  },
                                },
                                [_vm._v("自报自缴:")]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("img", {
                          staticStyle: {
                            width: "200px",
                            height: "150px",
                            position: "absolute",
                            bottom: "-40px",
                            right: "118px",
                            "z-index": "99",
                          },
                          attrs: {
                            src: "https://supernote.com.cn/user/chapter.png",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            width: "99.3%",
                            "border-left": "1px solid #000",
                            "border-right": "1px solid #000",
                            "border-bottom": "1px solid #000",
                            "padding-left": "5px",
                            color: "#000",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                "border-right": "1px solid #000",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "10px",
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "margin-top": "5px",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("报关人员")]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("报关人员证号")]),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("电话")]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "padding-right": "20px" },
                                    },
                                    [
                                      _vm._v(
                                        "兹申明对以上内容承担如实申报,依法纳税之法律责任"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "10px",
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "申报单位    3122280239   DHL空运服务(上海)有限公司"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: { "padding-right": "20px" },
                                    },
                                    [_vm._v("申报单位(签章)")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "50%",
                                padding: "5px 0px",
                                "font-size": "10px",
                              },
                            },
                            [
                              _vm._v(
                                "\n              海关批注及签章\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.boxFlag[i]
                  ? _c(
                      "div",
                      {
                        staticStyle: { "margin-top": "500px" },
                        attrs: { id: _vm.myclearance(i, "box") },
                      },
                      [
                        _c("div", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("SHIPPER'S COMPANY:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "font-weight": "600",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("Shanghai Ratta Smart Technology Co.,Ltd.")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("SHIPPER'S ADDRESS:")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "font-weight": "600",
                              "margin-top": "5px",
                            },
                          },
                          [
                            _vm._v(
                              "Room 301 Building No.1,168 JiXin Road,Minhang\n            District,Shanghai,P.R.China\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("SHIPPER'S TELEPHONE:")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "font-weight": "600",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("(+86)21-34537701")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#000",
                              "text-align": "center",
                            },
                          },
                          [_vm._v("装箱单")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#000",
                              "text-align": "center",
                            },
                          },
                          [_vm._v("PACKING LIST")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "font-size": "14px" },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.to,
                                    callback: function ($$v) {
                                      _vm.to = $$v
                                    },
                                    expression: "to",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(_vm.to) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.boxs[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-top": "5px",
                                  color: "#000",
                                  display: "flex",
                                },
                              },
                              [
                                _vm._v("CONSIGNEE'S\n            COMPANY:  "),
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 1 },
                                      },
                                      on: { input: _vm.changeReceive },
                                      model: {
                                        value: _vm.boxs[i].receiveName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.boxs[i],
                                            "receiveName",
                                            $$v
                                          )
                                        },
                                        expression: "boxs[i].receiveName",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 1 },
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.boxs[i].receiveName
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.boxs[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-top": "20px",
                                  color: "#000",
                                  display: "flex",
                                },
                              },
                              [
                                _vm._v("CONSIGNEE'S\n            ADDRESS:  "),
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 2 },
                                      },
                                      on: { input: _vm.changeReceive },
                                      model: {
                                        value: _vm.boxs[i].address,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.boxs[i], "address", $$v)
                                        },
                                        expression: "boxs[i].address",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 2 },
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.boxs[i].address
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.clearances[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-top": "40px",
                                  color: "#000",
                                  display: "flex",
                                },
                              },
                              [
                                _vm._v("\n            INVOICE NO:  "),
                                _vm.printFlag && _vm.clearances[i]
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      on: { input: _vm.changeReceive },
                                      model: {
                                        value: _vm.clearances[i].invoiceNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.clearances[i],
                                            "invoiceNo",
                                            $$v
                                          )
                                        },
                                        expression: "clearances[i].invoiceNo",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.clearances[i].invoiceNo
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              "font-size": "14px",
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                              color: "#000",
                            },
                          },
                          [
                            _vm.boxs[i]
                              ? _c("div", [
                                  _vm._v(
                                    "DATE:  " + _vm._s(_vm.boxs[i].deliverDate)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [_vm._v("FORM:  CHINA")]),
                            _vm._v(" "),
                            _vm.boxs[i]
                              ? _c(
                                  "div",
                                  { staticStyle: { "padding-right": "40px" } },
                                  [
                                    _vm._v(
                                      "TO:  " + _vm._s(_vm.boxs[i].country)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { position: "relative" } }, [
                          _c(
                            "table",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                width: "100%",
                                color: "#000",
                                position: "relative",
                              },
                              attrs: { border: "1", cellspacing: "0" },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("唛头号码")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("MARKS&NO.")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("货品名称")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("DESCRIPTIONS")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("数量")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("QTY")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("体积")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("MEAS")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("净重")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("N.W")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("毛重")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("G.W")]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.boxlist[i], function (item, index) {
                                return _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk bulks",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: { input: _vm.changeReceive },
                                            model: {
                                              value: _vm.penNum,
                                              callback: function ($$v) {
                                                _vm.penNum = $$v
                                              },
                                              expression: "penNum",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(_vm.penNum),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "none",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: { input: _vm.changeReceive },
                                            model: {
                                              value: item.productName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "productName",
                                                  $$v
                                                )
                                              },
                                              expression: "item.productName",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.productName
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  item.count <= 1
                                    ? _c(
                                        "th",
                                        {
                                          staticClass: "unit",
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      input: _vm.changeReceive,
                                                    },
                                                    model: {
                                                      value: item.count,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "count",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.count",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.count
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("SET")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.count > 1
                                    ? _c(
                                        "th",
                                        {
                                          staticClass: "unit",
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      input: _vm.changeReceive,
                                                    },
                                                    model: {
                                                      value: item.count,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "count",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.count",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.count
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                " SETS\n                  "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                        "padding-left": "30px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                on: {
                                                  input: _vm.changeReceive,
                                                },
                                                model: {
                                                  value: item.volume,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "volume",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.volume",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.volume
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("CBM")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk bulks",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                on: {
                                                  input: _vm.changeReceive,
                                                },
                                                model: {
                                                  value: item.netWeight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "netWeight",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.netWeight",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.netWeight
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("KG")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk bulks",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                on: {
                                                  input: _vm.changeReceive,
                                                },
                                                model: {
                                                  value: item.grossWeight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "grossWeight",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.grossWeight",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.grossWeight
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("KG")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: {
                              width: "200px",
                              height: "150px",
                              position: "absolute",
                              bottom: "-195px",
                              right: "118px",
                              "z-index": "99",
                            },
                            attrs: {
                              src: "https://supernote.com.cn/user/chapter.png",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              float: "right",
                              "padding-right": "100px",
                              "margin-top": "20px",
                            },
                          },
                          [_vm._v("COMPANY:")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.boxFlags[i]
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "1000px",
                          "margin-bottom": "800px",
                        },
                        attrs: { id: _vm.myclearance(i, "boxs") },
                      },
                      [
                        _c("div", { staticStyle: { "font-size": "14px" } }, [
                          _vm._v("SHIPPER'S COMPANY:"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "font-weight": "600",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("Shanghai Ratta Smart Technology Co.,Ltd.")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("SHIPPER'S ADDRESS:")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "font-weight": "600",
                              "margin-top": "5px",
                            },
                          },
                          [
                            _vm._v(
                              "Room 301 Building No.1,168 JiXin Road,Minhang\n            District,Shanghai,P.R.China\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("SHIPPER'S TELEPHONE:")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              "font-weight": "600",
                              "margin-top": "5px",
                            },
                          },
                          [_vm._v("(+86)21-34537701")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#000",
                              "text-align": "center",
                            },
                          },
                          [_vm._v("装箱单")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#000",
                              "text-align": "center",
                            },
                          },
                          [_vm._v("PACKING LIST")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "title",
                            staticStyle: { "font-size": "14px" },
                          },
                          [
                            _c("el-input", {
                              attrs: { type: "text" },
                              on: { input: _vm.changeReceive },
                              model: {
                                value: _vm.to,
                                callback: function ($$v) {
                                  _vm.to = $$v
                                },
                                expression: "to",
                              },
                            }),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(_vm.to) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.boxs[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-top": "5px",
                                  color: "#000",
                                  display: "flex",
                                },
                              },
                              [
                                _vm._v("CONSIGNEE'S\n            COMPANY:  "),
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 1 },
                                      },
                                      on: { input: _vm.changeReceive },
                                      model: {
                                        value: _vm.boxs[i].receiveName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.boxs[i],
                                            "receiveName",
                                            $$v
                                          )
                                        },
                                        expression: "boxs[i].receiveName",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 1 },
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.boxs[i].receiveName
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.boxs[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-top": "20px",
                                  color: "#000",
                                  display: "flex",
                                },
                              },
                              [
                                _vm._v("CONSIGNEE'S\n            ADDRESS:  "),
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 2 },
                                      },
                                      on: { input: _vm.changeReceive },
                                      model: {
                                        value: _vm.boxs[i].address,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.boxs[i], "address", $$v)
                                        },
                                        expression: "boxs[i].address",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: {
                                        type: "textarea",
                                        width: "70%",
                                        autosize: { minRows: 1, maxRows: 2 },
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.boxs[i].address
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.clearances[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "title",
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-top": "40px",
                                  color: "#000",
                                  display: "flex",
                                },
                              },
                              [
                                _vm._v("\n            INVOICE NO:  "),
                                _vm.printFlag && _vm.clearances[i]
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      on: { input: _vm.changeReceive },
                                      model: {
                                        value: _vm.clearances[i].invoiceNo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.clearances[i],
                                            "invoiceNo",
                                            $$v
                                          )
                                        },
                                        expression: "clearances[i].invoiceNo",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.clearances[i].invoiceNo
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              "font-size": "14px",
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                              color: "#000",
                            },
                          },
                          [
                            _vm.boxs[i]
                              ? _c("div", [
                                  _vm._v(
                                    "DATE:  " + _vm._s(_vm.boxs[i].deliverDate)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", [_vm._v("FORM:  CHINA")]),
                            _vm._v(" "),
                            _vm.boxs[i]
                              ? _c(
                                  "div",
                                  { staticStyle: { "padding-right": "40px" } },
                                  [
                                    _vm._v(
                                      "TO:  " + _vm._s(_vm.boxs[i].country)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { position: "relative" } }, [
                          _c(
                            "table",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                width: "100%",
                                color: "#000",
                                position: "relative",
                              },
                              attrs: { border: "1", cellspacing: "0" },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("唛头号码")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("MARKS&NO.")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("货品名称")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("DESCRIPTIONS")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("数量")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("QTY")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("体积")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("MEAS")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("净重")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("N.W")]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c("div", [_vm._v("毛重")]),
                                    _vm._v(" "),
                                    _c("div", [_vm._v("G.W")]),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.boxlist[i], function (item, index) {
                                return _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk bulks",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: { input: _vm.changeReceive },
                                            model: {
                                              value: _vm.penNum,
                                              callback: function ($$v) {
                                                _vm.penNum = $$v
                                              },
                                              expression: "penNum",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(_vm.penNum),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "none",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: { input: _vm.changeReceive },
                                            model: {
                                              value: item.productName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "productName",
                                                  $$v
                                                )
                                              },
                                              expression: "item.productName ",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(
                                                item.productName
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  item.count <= 1
                                    ? _c(
                                        "th",
                                        {
                                          staticClass: "unit",
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      input: _vm.changeReceive,
                                                    },
                                                    model: {
                                                      value: item.count,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "count",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.count",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.count
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("span", [_vm._v("SET")]),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.count > 1
                                    ? _c(
                                        "th",
                                        {
                                          staticClass: "unit",
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                            },
                                            [
                                              _vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      input: _vm.changeReceive,
                                                    },
                                                    model: {
                                                      value: item.count,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "count",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.count",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        item.count
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(
                                                " SETS\n                  "
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                        "padding-left": "30px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                on: {
                                                  input: _vm.changeReceive,
                                                },
                                                model: {
                                                  value: item.volume,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "volume",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.volume",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.volume
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("CBM")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk bulks",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                on: {
                                                  input: _vm.changeReceive,
                                                },
                                                model: {
                                                  value: item.netWeight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "netWeight",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.netWeight",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.netWeight
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("KG")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "bulk bulks",
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                on: {
                                                  input: _vm.changeReceive,
                                                },
                                                model: {
                                                  value: item.grossWeight,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "grossWeight",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "item.grossWeight",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !_vm.printFlag
                                            ? _c("el-input", {
                                                attrs: { type: "text" },
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.grossWeight
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("span", [_vm._v("KG")]),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: {
                              width: "200px",
                              height: "150px",
                              position: "absolute",
                              bottom: "-195px",
                              right: "118px",
                              "z-index": "99",
                            },
                            attrs: {
                              src: "https://supernote.com.cn/user/chapter.png",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              float: "right",
                              "padding-right": "100px",
                              "margin-top": "20px",
                            },
                          },
                          [_vm._v("COMPANY:")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.invoiceFlag[i]
                  ? _c(
                      "div",
                      {
                        style: {
                          marginTop: i !== 0 ? "500px" : "0",
                          clear: "both",
                        },
                        attrs: { id: _vm.myclearance(i, "openinvoice") },
                      },
                      [
                        _c("div", { staticClass: "tit" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "14px",
                                color: "#000",
                              },
                            },
                            [_vm._v("COMPANY NAME:")]
                          ),
                          _vm._v(
                            "  Shanghai\n            Ratta Smart Technology Co.,Ltd.\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit",
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "14px",
                                  color: "#000",
                                },
                              },
                              [_vm._v("ADRESS:")]
                            ),
                            _vm._v(
                              "  Room 301 Building No.1,168\n            JiXin Road,Minhang District,Shanghai,P.R.China\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit",
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "14px",
                                  color: "#000",
                                },
                              },
                              [_vm._v("TEL:")]
                            ),
                            _vm._v("  (+86)21-34537701\n          "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "invoicetitle",
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "bold",
                              "text-align": "center",
                              "margin-top": "15px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoiceTitle,
                                    callback: function ($$v) {
                                      _vm.invoiceTitle = $$v
                                    },
                                    expression: "invoiceTitle",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(_vm.invoiceTitle),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "30px",
                            },
                          },
                          [
                            _vm.clearances[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("INVOICE NO.: ")]
                                )
                              : _vm._e(),
                            _vm._v("  \n            "),
                            _vm.printFlag && _vm.clearances[i]
                              ? _c("el-input", {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "20px",
                                    color: "#000",
                                  },
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.clearances[i].invoiceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.clearances[i],
                                        "invoiceNo",
                                        $$v
                                      )
                                    },
                                    expression: "clearances[i].invoiceNo",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.clearances[i].invoiceNo
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("CONSIGNEE COMPANY:")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _vm.printFlag && _vm.invoices[i]
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 1 },
                                  },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoices[i].receiveName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoices[i],
                                        "receiveName",
                                        $$v
                                      )
                                    },
                                    expression: "invoices[i].receiveName",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 1 },
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.invoices[i].receiveName
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit ",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("SHIP DATE:")]
                                )
                              : _vm._e(),
                            _vm._v(
                              "  " +
                                _vm._s(
                                  _vm.invoices[i] && _vm.invoices[i].deliverDate
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("ADDRESS:")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _vm.printFlag && _vm.invoices[i]
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 2 },
                                  },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoices[i].address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.invoices[i], "address", $$v)
                                    },
                                    expression: "invoices[i].address",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 2 },
                                  },
                                  model: {
                                    value: _vm.invoices[i].address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.invoices[i], "address", $$v)
                                    },
                                    expression: "invoices[i].address",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("ATTENTION:")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _vm.printFlag && _vm.invoices[i]
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoices[i].receiveName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoices[i],
                                        "receiveName",
                                        $$v
                                      )
                                    },
                                    expression: "invoices[i].receiveName",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.invoices[i].receiveName
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              width: "100%",
                            },
                            attrs: { border: "1", cellspacing: "0" },
                          },
                          [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Full description Of Goods")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(品名)")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Quantity")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(数量)")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Item Value")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(单价)")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Total Value For Customs")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(总价)")]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.invoiceList[i], function (item) {
                              return _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "none",
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                      width: "250px",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: item.productName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "productName", $$v)
                                            },
                                            expression: "item.productName",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.productName
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                item.count <= 1
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "unit",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                    blur: function ($event) {
                                                      return _vm.changeprice(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "count",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.count",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.count
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("SET")]),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.count > 1
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "unit",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                    blur: function ($event) {
                                                      return _vm.changeprice(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "count",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.count",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.count
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("SETS")]),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "price",
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                      display: "flex",
                                      "align-items": "center",
                                      "padding-left": "10px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.invoices.unit) +
                                        " "
                                    ),
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: {
                                            input: _vm.changeReceive,
                                            blur: function ($event) {
                                              return _vm.changeprice(item)
                                            },
                                          },
                                          model: {
                                            value: item.unitPrice,
                                            callback: function ($$v) {
                                              _vm.$set(item, "unitPrice", $$v)
                                            },
                                            expression: "item.unitPrice",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(item.unitPrice),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "price",
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                        },
                                      },
                                      [
                                        _vm._v(_vm._s(_vm.invoices.unit) + " "),
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value: item.totalPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "totalPrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.totalPrice",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.totalPrice
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { position: "relative" } }, [
                          _c(
                            "table",
                            {
                              staticStyle: {
                                "border-top": "none",
                                width: "100%",
                                position: "relative",
                              },
                              attrs: { border: "1", cellspacing: "0" },
                            },
                            [
                              _c("tr", { staticStyle: { height: "30px" } }, [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "border-right": "none",
                                    },
                                  },
                                  [_vm._v("Sub total(小计)")]
                                ),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.invoices[i]
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "price",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                          "padding-left": "10px",
                                          "border-left": "none",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.invoices[i].unit) + " "
                                            ),
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.invoices[i]
                                                        .subTotalPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.invoices[i],
                                                        "subTotalPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoices[i].subTotalPrice",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.invoices[i]
                                                        .subTotalPrice
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticStyle: { height: "20px" } }, [
                                _c("th", {
                                  staticStyle: { "border-right": "none" },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: { "border-left": "none" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticStyle: { height: "30px" } }, [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "border-right": "none",
                                      "text-decoration": "underline",
                                      color: "#000",
                                      "font-weight": "600",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Total invoice value\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.invoices[i]
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "price",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                          "padding-left": "10px",
                                          "border-left": "none",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.invoices[i].unit) +
                                                " "
                                            ),
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.invoices[i]
                                                        .subTotalPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.invoices[i],
                                                        "subTotalPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoices[i].subTotalPrice",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.invoices[i]
                                                        .subTotalPrice
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: {
                              width: "200px",
                              height: "150px",
                              position: "absolute",
                              bottom: "-396px",
                              left: "48px",
                              "z-index": "99",
                            },
                            attrs: {
                              src: "https://supernote.com.cn/user/chapter.png",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits weight",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm._v("GROSS WT(KG):  "),
                            _vm.printFlag && _vm.invoices[i]
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoices[i].grossWeight,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoices[i],
                                        "grossWeight",
                                        $$v
                                      )
                                    },
                                    expression: "invoices[i].grossWeight",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.invoices[i].grossWeight
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v("\n            KG\n          "),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm._v("DIMENSIONS:  "),
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.size,
                                    callback: function ($$v) {
                                      _vm.size = $$v
                                    },
                                    expression: "size",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(_vm.size) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [_vm._v("Reason for Export:  permanent")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c("span", { staticStyle: { width: "100px" } }, [
                              _vm._v("Terms of trade:"),
                            ]),
                            _vm._v("   "),
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.getCfr,
                                    callback: function ($$v) {
                                      _vm.getCfr = $$v
                                    },
                                    expression: "getCfr",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(_vm.getCfr) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [_vm._v("Terms of payment:  T/T")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [_vm._v("COUNTRY OF ORIGIN:  CHINA")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#000",
                              "font-size": "16px",
                              "margin-top": "20px",
                              "padding-right": "20px",
                            },
                          },
                          [
                            _vm._v(
                              "I/We hereby\n            certify that the information on this invoice is true and correct and that the contents of this shipment\n            are as stated above.\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "titcomment",
                            staticStyle: { "margin-top": "20px" },
                          },
                          [
                            _vm._v("Name and Title of Authorized Person"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#ccc",
                                  "font-size": "16px",
                                },
                              },
                              [_vm._v("(签名)")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.invoices[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "titcomment",
                                staticStyle: { "margin-top": "20px" },
                              },
                              [
                                _vm._v(
                                  "\n            Date:   " +
                                    _vm._s(
                                      _vm.invoices[i] &&
                                        _vm.invoices[i].deliverDate
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "titcomment",
                            staticStyle: { "margin-top": "20px" },
                          },
                          [
                            _vm._v("Signature of Authorized Person"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#ccc",
                                  "font-size": "16px",
                                },
                              },
                              [_vm._v("(授权人签名/盖公章)")]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.invoiceFlags[i]
                  ? _c(
                      "div",
                      {
                        staticStyle: { "margin-top": "500px", clear: "both" },
                        attrs: { id: _vm.myclearance(i, "openinvoices") },
                      },
                      [
                        _c("div", { staticClass: "tit" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "14px",
                                color: "#000",
                              },
                            },
                            [_vm._v("COMPANY NAME:")]
                          ),
                          _vm._v(
                            "  Shanghai\n            Ratta Smart Technology Co.,Ltd.\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit",
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "14px",
                                  color: "#000",
                                },
                              },
                              [_vm._v("ADRESS:")]
                            ),
                            _vm._v(
                              "  Room 301 Building No.1,168\n            JiXin Road,Minhang District,Shanghai,P.R.China\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit",
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "14px",
                                  color: "#000",
                                },
                              },
                              [_vm._v("TEL:")]
                            ),
                            _vm._v("  (+86)21-34537701\n          "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "invoicetitle",
                            staticStyle: {
                              "font-size": "18px",
                              "font-weight": "bold",
                              "text-align": "center",
                              "margin-top": "15px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoiceTitle,
                                    callback: function ($$v) {
                                      _vm.invoiceTitle = $$v
                                    },
                                    expression: "invoiceTitle",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(_vm.invoiceTitle),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "30px",
                            },
                          },
                          [
                            _vm.clearances[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("INVOICE NO.:")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _vm.printFlag && _vm.clearances[i]
                              ? _c("el-input", {
                                  staticStyle: {
                                    "font-weight": "bold",
                                    "font-size": "20px",
                                    color: "#000",
                                  },
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.clearances[i].invoiceNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.clearances[i],
                                        "invoiceNo",
                                        $$v
                                      )
                                    },
                                    expression: "clearances[i].invoiceNo",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.clearances[i].invoiceNo
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("CONSIGNEE COMPANY:")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _vm.printFlag && _vm.invoices[i]
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 1 },
                                  },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoices[i].receiveName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoices[i],
                                        "receiveName",
                                        $$v
                                      )
                                    },
                                    expression: "invoices[i].receiveName",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 1 },
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.invoices[i].receiveName
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit ",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("SHIP DATE:")]
                                )
                              : _vm._e(),
                            _vm._v(
                              "  " +
                                _vm._s(
                                  _vm.invoices[i] && _vm.invoices[i].deliverDate
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("ADDRESS:")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _vm.printFlag && _vm.invoices[i]
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 2 },
                                  },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoices[i].address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.invoices[i], "address", $$v)
                                    },
                                    expression: "invoices[i].address",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  staticStyle: { width: "99.3%" },
                                  attrs: {
                                    type: "textarea",
                                    width: "70%",
                                    autosize: { minRows: 1, maxRows: 2 },
                                  },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.invoices[i].address
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tit title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm.invoices[i]
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bold",
                                      "font-size": "14px",
                                      color: "#000",
                                    },
                                  },
                                  [_vm._v("ATTENTION:")]
                                )
                              : _vm._e(),
                            _vm._v("  "),
                            _vm.printFlag && _vm.invoices[i]
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.invoices[i].receiveName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.invoices[i],
                                        "receiveName",
                                        $$v
                                      )
                                    },
                                    expression: "invoices[i].receiveName",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.invoices[i].receiveName
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              "margin-top": "10px",
                              width: "100%",
                            },
                            attrs: { border: "1", cellspacing: "0" },
                          },
                          [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Full description Of Goods")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(品名)")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Quantity")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(数量)")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Item Value")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(单价)")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "th",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                    color: "#000",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-weight": "500" } },
                                    [_vm._v("Total Value For Customs")]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [_vm._v("(总价)")]),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.invoiceList[i], function (item) {
                              return _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticClass: "none",
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                      width: "250px",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: item.productName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "productName", $$v)
                                            },
                                            expression: "item.productName",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(
                                              item.productName
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                item.count <= 1
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "unit",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                    blur: function ($event) {
                                                      return _vm.changeprice(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "count",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.count",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.count
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("SET")]),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                item.count > 1
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "unit",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                    blur: function ($event) {
                                                      return _vm.changeprice(
                                                        item
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.count,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "count",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "item.count",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      item.count
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("SETS")]),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "price",
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                      display: "flex",
                                      "align-items": "center",
                                      "padding-left": "10px",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.invoices.unit) +
                                        " "
                                    ),
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: {
                                            input: _vm.changeReceive,
                                            blur: function ($event) {
                                              return _vm.changeprice(item)
                                            },
                                          },
                                          model: {
                                            value: item.unitPrice,
                                            callback: function ($$v) {
                                              _vm.$set(item, "unitPrice", $$v)
                                            },
                                            expression: "item.unitPrice",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(item.unitPrice),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticClass: "price",
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "flex",
                                          "align-items": "center",
                                          "justify-content": "center",
                                        },
                                      },
                                      [
                                        _vm._v(_vm._s(_vm.invoices.unit) + " "),
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value: item.totalPrice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "totalPrice",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.totalPrice",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  item.totalPrice
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ])
                            }),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticStyle: { position: "relative" } }, [
                          _c(
                            "table",
                            {
                              staticStyle: {
                                "border-top": "none",
                                width: "100%",
                                position: "relative",
                              },
                              attrs: { border: "1", cellspacing: "0" },
                            },
                            [
                              _c("tr", { staticStyle: { height: "30px" } }, [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "border-right": "none",
                                    },
                                  },
                                  [_vm._v("Sub total(小计)")]
                                ),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.invoices[i]
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "price",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                          "padding-left": "10px",
                                          "border-left": "none",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.invoices[i].unit) + " "
                                            ),
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.invoices[i]
                                                        .subTotalPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.invoices[i],
                                                        "subTotalPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoices[i].subTotalPrice",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.invoices[i]
                                                        .subTotalPrice
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticStyle: { height: "20px" } }, [
                                _c("th", {
                                  staticStyle: { "border-right": "none" },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: { "border-left": "none" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("tr", { staticStyle: { height: "30px" } }, [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "border-right": "none",
                                      "text-decoration": "underline",
                                      color: "#000",
                                      "font-weight": "600",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Total invoice value\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _c("th", {
                                  staticStyle: {
                                    "border-right": "none",
                                    "border-left": "none",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.invoices[i]
                                  ? _c(
                                      "th",
                                      {
                                        staticClass: "price",
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                          "padding-left": "10px",
                                          "border-left": "none",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              "justify-content": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.invoices[i].unit) +
                                                " "
                                            ),
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.invoices[i]
                                                        .subTotalPrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.invoices[i],
                                                        "subTotalPrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoices[i].subTotalPrice",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.invoices[i]
                                                        .subTotalPrice
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c("img", {
                            staticStyle: {
                              width: "200px",
                              height: "150px",
                              position: "absolute",
                              bottom: "-396px",
                              left: "48px",
                              "z-index": "99",
                            },
                            attrs: {
                              src: "https://supernote.com.cn/user/chapter.png",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.invoices[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "tits weight",
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "margin-top": "10px",
                                },
                              },
                              [
                                _vm._v("GROSS\n            WT(KG):  "),
                                _vm.printFlag && _vm.invoices[i]
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      on: { input: _vm.changeReceive },
                                      model: {
                                        value: _vm.invoices[i].grossWeight,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.invoices[i],
                                            "grossWeight",
                                            $$v
                                          )
                                        },
                                        expression: "invoices[i].grossWeight",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.invoices[i].grossWeight
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v("\n            KG\n          "),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _vm._v("DIMENSIONS:  "),
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.size,
                                    callback: function ($$v) {
                                      _vm.size = $$v
                                    },
                                    expression: "size",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(_vm.size) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [_vm._v("Reason for Export:  permanent")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits title",
                            staticStyle: {
                              display: "flex",
                              "margin-top": "10px",
                            },
                          },
                          [
                            _c("span", { staticStyle: { width: "100px" } }, [
                              _vm._v("Terms of trade:"),
                            ]),
                            _vm._v("  "),
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  on: { input: _vm.changeReceive },
                                  model: {
                                    value: _vm.getCfr,
                                    callback: function ($$v) {
                                      _vm.getCfr = $$v
                                    },
                                    expression: "getCfr",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(_vm.getCfr) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [_vm._v("Terms of payment:  T/T")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tits",
                            staticStyle: { "margin-top": "10px" },
                          },
                          [_vm._v("COUNTRY OF ORIGIN:  CHINA")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-weight": "600",
                              color: "#000",
                              "font-size": "16px",
                              "margin-top": "20px",
                              "padding-right": "20px",
                            },
                          },
                          [
                            _vm._v(
                              "I/We hereby\n            certify that the information on this invoice is true and correct and that the contents of this shipment\n            are as stated above.\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "titcomment",
                            staticStyle: { "margin-top": "20px" },
                          },
                          [
                            _vm._v("Name and Title of Authorized Person"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#ccc",
                                  "font-size": "16px",
                                },
                              },
                              [_vm._v("(签名)")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.invoices[i]
                          ? _c(
                              "div",
                              {
                                staticClass: "titcomment",
                                staticStyle: { "margin-top": "20px" },
                              },
                              [
                                _vm._v(
                                  "\n            Date:   " +
                                    _vm._s(
                                      _vm.invoices[i] &&
                                        _vm.invoices[i].deliverDate
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "titcomment",
                            staticStyle: { "margin-top": "20px" },
                          },
                          [
                            _vm._v("Signature of Authorized Person"),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#ccc",
                                  "font-size": "16px",
                                },
                              },
                              [_vm._v("(授权人签名/盖公章)")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.contractFlag[i]
                          ? _c(
                              "div",
                              { staticStyle: { "margin-top": "500px" } },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "protitle",
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "center",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: _vm.profoma,
                                            callback: function ($$v) {
                                              _vm.profoma = $$v
                                            },
                                            expression: "profoma",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(_vm.profoma),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.clearances[i]
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "invoicetit",
                                        staticStyle: {
                                          display: "flex",
                                          "justify-content": "flex-end",
                                          "font-weight": "700",
                                          "font-size": "12px",
                                        },
                                      },
                                      [
                                        _vm._v("INVOICE NO:\n              "),
                                        _vm.printFlag && _vm.clearances[i]
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value:
                                                  _vm.clearances[i].invoiceNo,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.clearances[i],
                                                    "invoiceNo",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "clearances[i].invoiceNo",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.clearances[i].invoiceNo
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "invoicetit",
                                    staticStyle: {
                                      "margin-top": "5px",
                                      display: "flex",
                                      "justify-content": "flex-end",
                                      "font-weight": "700",
                                      "font-size": "12px",
                                    },
                                  },
                                  [
                                    _vm._v("INVOICE DATE:\n              "),
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: _vm.date,
                                            callback: function ($$v) {
                                              _vm.date = $$v
                                            },
                                            expression: "date",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(_vm.date),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "sender",
                                    staticStyle: { "font-weight": "bold" },
                                  },
                                  [_vm._v("SENDER:")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "cont" }, [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont1",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm._v(
                                          "COMPANY NAME:\n                  "
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "78px",
                                            },
                                          },
                                          [_vm._v("Ratta Global LLC")]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont1",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm._v("ADDERSS:\n                  "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "120px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Room 301,Building No.1,168 Jixin Road,Minhang\n                    District,Shanghai,P.R.China\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont1",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm._v("PHONE:\n                  "),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "margin-left": "135px",
                                            },
                                          },
                                          [_vm._v("(+86)21-34537701")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div"),
                                  _vm._v(" "),
                                  _c("div", { staticStyle: { opacity: "0" } }, [
                                    _c("div", { staticClass: "cont1" }, [
                                      _vm._v("COMPANY NAME:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "cont1" }, [
                                      _vm._v("ADDERSS:"),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "cont1" }, [
                                      _vm._v("PHONE:"),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "sender",
                                    staticStyle: {
                                      "margin-top": "15px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v("RECEIVER:")]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "cont" }, [
                                  _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont1",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm._v("ADDRESS:\n                  "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cont2",
                                            staticStyle: {
                                              "margin-left": "140px",
                                            },
                                          },
                                          [
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.invoice[i]
                                                        .receiverAddress,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.invoice[i],
                                                        "receiverAddress",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoice[i].receiverAddress",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.invoice[i]
                                                        .receiverAddress
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont1",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm._v("Phone:\n                  "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cont2",
                                            staticStyle: {
                                              "margin-left": "167px",
                                            },
                                          },
                                          [
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.invoice[i]
                                                        .receiverPhone,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.invoice[i],
                                                        "receiverPhone",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoice[i].receiverPhone",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.invoice[i]
                                                        .receiverPhone
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont1",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm._v("Contact:\n                  "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cont2",
                                            staticStyle: {
                                              "margin-left": "155px",
                                            },
                                          },
                                          [
                                            _vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  on: {
                                                    input: _vm.changeReceive,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.invoice[i].contact,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.invoice[i],
                                                        "contact",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "invoice[i].contact",
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !_vm.printFlag
                                              ? _c("el-input", {
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.invoice[i].contact
                                                    ),
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont0",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "contc",
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value: _vm.contact0,
                                                callback: function ($$v) {
                                                  _vm.contact0 = $$v
                                                },
                                                expression: "contact0",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "contc",
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.contact0
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "cont3",
                                              staticStyle: {
                                                "margin-left": "130px",
                                              },
                                              attrs: { type: "text" },
                                              model: {
                                                value: _vm.contact1,
                                                callback: function ($$v) {
                                                  _vm.contact1 = $$v
                                                },
                                                expression: "contact1",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "cont3",
                                              staticStyle: {
                                                "margin-left": "130px",
                                              },
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.contact1
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "cont0",
                                        staticStyle: { display: "flex" },
                                      },
                                      [
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "contc",
                                              attrs: { type: "text" },
                                              on: { input: _vm.changeReceive },
                                              model: {
                                                value: _vm.contact2,
                                                callback: function ($$v) {
                                                  _vm.contact2 = $$v
                                                },
                                                expression: "contact2",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "contc",
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.contact2
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "cont3",
                                              staticStyle: {
                                                "margin-left": "130px",
                                              },
                                              attrs: { type: "text" },
                                              model: {
                                                value: _vm.contact3,
                                                callback: function ($$v) {
                                                  _vm.contact3 = $$v
                                                },
                                                expression: "contact3",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.printFlag
                                          ? _c("el-input", {
                                              staticClass: "cont3",
                                              staticStyle: {
                                                "margin-left": "130px",
                                              },
                                              attrs: { type: "text" },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.contact3
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { position: "relative" } },
                                  [
                                    _c(
                                      "table",
                                      {
                                        staticStyle: {
                                          "margin-top": "10px",
                                          width: "100%",
                                        },
                                        attrs: {
                                          border: "1",
                                          cellspacing: "0",
                                        },
                                      },
                                      [
                                        _c("tr", [
                                          _c("th", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                                color: "#000",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("Product name")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                                color: "#000",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("Model")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                                color: "#000",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("Unit Price")]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [_vm._v("(USD)")]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                                color: "#000",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("Quantity")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                                color: "#000",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("Unit")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "font-weight": "200",
                                                "font-size": "13px",
                                                color: "#000",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500",
                                                  },
                                                },
                                                [_vm._v("Amount")]
                                              ),
                                              _vm._v(" "),
                                              _c("div", [_vm._v("(USD)")]),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.invoiceVolist[i],
                                          function (item, index) {
                                            return _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticStyle: {
                                                    width: "50px",
                                                    height: "50px",
                                                  },
                                                },
                                                [_vm._v(_vm._s(index + 1))]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "none",
                                                  staticStyle: {
                                                    "font-weight": "200",
                                                    "font-size": "13px",
                                                    width: "150px",
                                                  },
                                                },
                                                [
                                                  _vm.printFlag
                                                    ? _c("el-input", {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          input:
                                                            _vm.changeReceive,
                                                        },
                                                        model: {
                                                          value:
                                                            item.productName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "productName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.productName",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.printFlag
                                                    ? _c("el-input", {
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.productName
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "model",
                                                  staticStyle: {
                                                    "font-weight": "200",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _vm.printFlag
                                                        ? _c("el-input", {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.changeReceive,
                                                            },
                                                            model: {
                                                              value: item.model,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "model",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.model",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      !_vm.printFlag
                                                        ? _c("el-input", {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  item.model
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "unit",
                                                  staticStyle: {
                                                    "font-weight": "200",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _vm.printFlag
                                                        ? _c("el-input", {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.changeReceive,
                                                            },
                                                            model: {
                                                              value:
                                                                item.unitPrice,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "unitPrice",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.unitPrice",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      !_vm.printFlag
                                                        ? _c("el-input", {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  item.unitPrice
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "unit",
                                                  staticStyle: {
                                                    "font-weight": "200",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        display: "flex",
                                                        "align-items": "center",
                                                        "justify-content":
                                                          "center",
                                                      },
                                                    },
                                                    [
                                                      _vm.printFlag
                                                        ? _c("el-input", {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              input:
                                                                _vm.changeReceive,
                                                            },
                                                            model: {
                                                              value:
                                                                item.quantity,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "quantity",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.quantity",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      !_vm.printFlag
                                                        ? _c("el-input", {
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  item.quantity
                                                                ),
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "unit",
                                                  staticStyle: {
                                                    "font-weight": "200",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _vm.printFlag
                                                    ? _c("el-input", {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          input:
                                                            _vm.changeReceive,
                                                        },
                                                        model: {
                                                          value: item.unit,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "unit",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.unit",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.printFlag
                                                    ? _c("el-input", {
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.unit
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "unit",
                                                  staticStyle: {
                                                    "font-weight": "200",
                                                    "font-size": "13px",
                                                  },
                                                },
                                                [
                                                  _vm.printFlag
                                                    ? _c("el-input", {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          input:
                                                            _vm.changeReceive,
                                                        },
                                                        model: {
                                                          value: item.amount,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "amount",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.amount",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  !_vm.printFlag
                                                    ? _c("el-input", {
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            item.amount
                                                          ),
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ])
                                          }
                                        ),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th"),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-right": "none",
                                              height: "50px",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticClass: "shipping shippings",
                                              staticStyle: {
                                                "border-right": "none",
                                                "border-left": "none",
                                              },
                                            },
                                            [
                                              _vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      input: _vm.changeReceive,
                                                    },
                                                    model: {
                                                      value: _vm.shippingCost,
                                                      callback: function ($$v) {
                                                        _vm.shippingCost = $$v
                                                      },
                                                      expression:
                                                        "shippingCost",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.shippingCost
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-right": "none",
                                              "border-left": "none",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-right": "none",
                                              "border-left": "none",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-left": "none",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { staticClass: "shipping" },
                                            [
                                              _vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      input: _vm.changeReceive,
                                                      blur: _vm.changeShipping,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.invoice[i].shipping,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.invoice[i],
                                                          "shipping",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "invoice[i].shipping",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.invoice[i].shipping
                                                      ),
                                                    },
                                                    on: {
                                                      blur: _vm.changeShipping,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th"),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-right": "none",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            {
                                              staticStyle: {
                                                "border-right": "none",
                                                "border-left": "none",
                                                width: "300px",
                                                height: "50px",
                                              },
                                            },
                                            [_vm._v("Total Cost:(USD)")]
                                          ),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-right": "none",
                                              "border-left": "none",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-right": "none",
                                              "border-left": "none",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("th", {
                                            staticStyle: {
                                              "border-left": "none",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "th",
                                            { staticClass: "units" },
                                            [
                                              _vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    on: {
                                                      input: _vm.changeReceive,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.invoice[i]
                                                          .totalPrice,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.invoice[i],
                                                          "totalPrice",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "invoice[i].totalPrice",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              !_vm.printFlag
                                                ? _c("el-input", {
                                                    attrs: { type: "text" },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        _vm.invoice[i]
                                                          .totalPrice
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c("img", {
                                      staticStyle: {
                                        width: "200px",
                                        height: "150px",
                                        position: "absolute",
                                        bottom: "-98px",
                                        right: "118px",
                                        "z-index": "99",
                                      },
                                      attrs: {
                                        src: "https://supernote.com.cn/user/chapter.png",
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "pric",
                                    staticStyle: {
                                      "text-align": "center",
                                      "margin-top": "10px",
                                      "border-bottom": "2px solid #000",
                                      "padding-bottom": "10px",
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: { change: _vm.changeReceive },
                                          model: {
                                            value: _vm.totalPriceUpper,
                                            callback: function ($$v) {
                                              _vm.totalPriceUpper = $$v
                                            },
                                            expression: "totalPriceUpper",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.totalPriceUpper
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tit2",
                                    staticStyle: {
                                      display: "flex",
                                      "margin-top": "20px",
                                    },
                                  },
                                  [
                                    _vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: { input: _vm.changeReceive },
                                          model: {
                                            value: _vm.email,
                                            callback: function ($$v) {
                                              _vm.email = $$v
                                            },
                                            expression: "email",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.printFlag
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          domProps: {
                                            textContent: _vm._s(_vm.email),
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.changeTitle,
            visible: _vm.dialogFormVisibles,
            "close-on-click-modal": false,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibles = $event
            },
            close: function ($event) {
              return _vm.addClose("addform")
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.addrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _vm.isStockingShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "销售渠道",
                            "label-width": _vm.formLabelWidth,
                            prop: "dealer",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择",
                                disabled: _vm.orderFlag,
                              },
                              on: { change: _vm.showCurrency },
                              model: {
                                value: _vm.addform.dealer,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "dealer", $$v)
                                },
                                expression: "addform.dealer",
                              },
                            },
                            _vm._l(_vm.dealers, function (item, index) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "订单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderNo",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.orderFlag,
                          autocomplete: "off",
                          type: "text",
                        },
                        model: {
                          value: _vm.addform.orderNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addform,
                              "orderNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addform.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isStockingShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "币种",
                            "label-width": _vm.formLabelWidth,
                            prop: "unit",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.addform.unit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "unit", $$v)
                                },
                                expression: "addform.unit",
                              },
                            },
                            _vm._l(_vm.currencys, function (item) {
                              return _c("el-option", {
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "订单总价",
                            "label-width": _vm.formLabelWidth,
                            prop: "totalPrice",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              onkeyup:
                                "this.value = this.value.replace(/[^\\d.]/g,'');",
                              autocomplete: "off",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.totalPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "totalPrice",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.totalPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "运费",
                            "label-width": _vm.formLabelWidth,
                            prop: "logisticsPrice",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autocomplete: "off",
                              onkeyup:
                                "this.value = this.value.replace(/[^\\d.]/g,'');",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.logisticsPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "logisticsPrice",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.logisticsPrice",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "税费",
                            "label-width": _vm.formLabelWidth,
                            prop: "dutyTaxes",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              autocomplete: "off",
                              onkeyup:
                                "this.value = this.value.replace(/[^\\d.]/g,'');",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.dutyTaxes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "dutyTaxes",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.dutyTaxes",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "支付方式",
                            "label-width": _vm.formLabelWidth,
                            prop: "payType",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { multiple: "", placeholder: "请选择" },
                              model: {
                                value: _vm.addform.payType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "payType", $$v)
                                },
                                expression: "addform.payType",
                              },
                            },
                            _vm._l(_vm.payTypes, function (item) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "支付时间",
                            "label-width": _vm.formLabelWidth,
                            prop: "payTime",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "default-time": "00:00:00",
                              "value-format": " yyyy-MM-dd HH:mm:ss",
                              format: "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              editable: false,
                            },
                            model: {
                              value: _vm.addform.payTime,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "payTime", $$v)
                              },
                              expression: "addform.payTime",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isStockingShow
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: "申请人",
                                "label-width": _vm.formLabelWidth,
                                prop: "applicantUser",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "50",
                                  autocomplete: "off",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.addform.applicantUser,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addform,
                                      "applicantUser",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "addform.applicantUser",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "退回",
                        "label-width": _vm.formLabelWidth,
                        prop: "returnFlag",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.addform.returnFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.addform, "returnFlag", $$v)
                            },
                            expression: "addform.returnFlag",
                          },
                        },
                        _vm._l(_vm.returnFlagList, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isRemarkShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "物流公司",
                            "label-width": _vm.formLabelWidth,
                            prop: "logistics",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.addform.logistics,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "logistics", $$v)
                                },
                                expression: "addform.logistics",
                              },
                            },
                            _vm._l(
                              _vm.companylogistics,
                              function (item, index) {
                                return _c("el-option", {
                                  attrs: {
                                    label: item.valueCn,
                                    value: item.valueCn,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSupplementaryShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "物流单号",
                            "label-width": _vm.formLabelWidth,
                            prop: "logisticsNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "30",
                              autocomplete: "off",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.logisticsNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "logisticsNumber",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.logisticsNumber",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _vm.isRemarkShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "联系人手机号",
                            "label-width": _vm.formLabelWidth,
                            prop: "contactTelephone",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off", type: "text" },
                            model: {
                              value: _vm.addform.contactTelephone,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "contactTelephone", $$v)
                              },
                              expression: "addform.contactTelephone",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRemarkShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "联系人邮箱",
                            "label-width": _vm.formLabelWidth,
                            prop: "contactEmail",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off", type: "text" },
                            model: {
                              value: _vm.addform.contactEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.addform, "contactEmail", $$v)
                              },
                              expression: "addform.contactEmail",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRemarkShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "收货人",
                            "label-width": _vm.formLabelWidth,
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "50",
                              autocomplete: "off",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.name",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _vm.isRemarkShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "国家",
                            "label-width": _vm.formLabelWidth,
                            prop: "country",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "country",
                              staticClass: "el-select-border-no",
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "" },
                              on: { change: _vm.countrySelect },
                              model: {
                                value: _vm.addform.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "country", $$v)
                                },
                                expression: "addform.country",
                              },
                            },
                            _vm._l(_vm.countrys, function (item, index) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.country,
                                  value: {
                                    value: item.id,
                                    label: item.country,
                                    code: item.countryCode,
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAmericaShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "省",
                            "label-width": _vm.formLabelWidth,
                            prop: "provinceName",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "provinceName",
                              staticClass: "el-select-border-no",
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "" },
                              on: { change: _vm.provinceSelect },
                              model: {
                                value: _vm.addform.provinceName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "provinceName", $$v)
                                },
                                expression: "addform.provinceName",
                              },
                            },
                            _vm._l(_vm.provinceNames, function (item, index) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.provinceName,
                                  value: {
                                    value: item.provinceName,
                                    code: item.province,
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isProvinceShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "省",
                            "label-width": _vm.formLabelWidth,
                            prop: "provinceName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off", type: "text" },
                            model: {
                              value: _vm.addform.provinceName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "provinceName",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.provinceName",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isRemarkShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "市",
                            "label-width": _vm.formLabelWidth,
                            prop: "city",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off", type: "text" },
                            model: {
                              value: _vm.addform.city,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "city",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.city",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isRemarkShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "详细地址",
                        "label-width": _vm.formLabelWidth,
                        prop: "address",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", type: "text" },
                        model: {
                          value: _vm.addform.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "address", $$v)
                          },
                          expression: "addform.address",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _vm.isRemarkShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "邮编",
                            "label-width": _vm.formLabelWidth,
                            prop: "cityPostalCode",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              autocomplete: "off",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.cityPostalCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "cityPostalCode",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.cityPostalCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isStockingShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "交易号",
                            "label-width": _vm.formLabelWidth,
                            prop: "tradeNo",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "50",
                              autocomplete: "off",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.tradeNo,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "tradeNo",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.tradeNo",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isStockingShow
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "流水号",
                            "label-width": _vm.formLabelWidth,
                            prop: "serialNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "50",
                              autocomplete: "off",
                              type: "text",
                            },
                            model: {
                              value: _vm.addform.serialNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "serialNumber",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.serialNumber",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _vm.changeTitle === "销售录入"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "区域仓",
                            "label-width": _vm.formLabelWidth,
                            prop: "locationPosition",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.forceUpdateCount },
                              model: {
                                value: _vm.addform.locationPosition,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "locationPosition", $$v)
                                },
                                expression: "addform.locationPosition",
                              },
                            },
                            _vm._l(
                              _vm.locationPositionSplice,
                              function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    label: item.valueCn,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isRemarkShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "备注",
                        "label-width": _vm.formLabelWidth,
                        prop: "remark",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", type: "text" },
                        model: {
                          value: _vm.addform.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "remark", $$v)
                          },
                          expression: "addform.remark",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.equimentFlag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备号",
                        "label-width": _vm.formLabelWidth,
                        prop: "startNumber",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("el-input", {
                            attrs: { minlength: "7", autocomplete: "off" },
                            on: {
                              blur: function ($event) {
                                return _vm.equimentNumber($event)
                              },
                            },
                            model: {
                              value: _vm.startNumber,
                              callback: function ($$v) {
                                _vm.startNumber = $$v
                              },
                              expression: "startNumber",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-size": "30px",
                                "padding-left": "10px",
                                cursor: "pointer",
                              },
                              on: { click: _vm.addNumber },
                            },
                            [_vm._v("+")]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.equimentFlag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        "label-width": _vm.formLabelWidth,
                        prop: "equipmentNumberList",
                      },
                    },
                    _vm._l(
                      _vm.addform.equipmentNumberList,
                      function (item, index) {
                        return _c(
                          "div",
                          { staticStyle: { display: "flex" } },
                          [
                            _c("el-input", {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { minlength: "7", autocomplete: "off" },
                              on: {
                                blur: function ($event) {
                                  return _vm.equimentNumber($event)
                                },
                              },
                              model: {
                                value: item.text,
                                callback: function ($$v) {
                                  _vm.$set(item, "text", $$v)
                                },
                                expression: "item.text",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "font-size": "30px",
                                  "padding-left": "10px",
                                  cursor: "pointer",
                                  "margin-top": "10px",
                                },
                                on: {
                                  click: function () {
                                    return _vm.removeNumber(index)
                                  },
                                },
                              },
                              [_vm._v("-\n          ")]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "套装/配件",
                        "label-width": _vm.formLabelWidth,
                        prop: "isPackage",
                      },
                    },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "0" },
                          on: { change: _vm.isPack },
                          model: {
                            value: _vm.isPackage,
                            callback: function ($$v) {
                              _vm.isPackage = $$v
                            },
                            expression: "isPackage",
                          },
                        },
                        [_vm._v("套装")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: "1" },
                          on: { change: _vm.isPack },
                          model: {
                            value: _vm.isPackage,
                            callback: function ($$v) {
                              _vm.isPackage = $$v
                            },
                            expression: "isPackage",
                          },
                        },
                        [_vm._v("配件")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.packageFlag && _vm.isStockingShow
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "产品编号",
                            "label-width": _vm.formLabelWidth,
                            prop: "productNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.changeproductNo },
                              model: {
                                value: _vm.addform.productNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "productNo", $$v)
                                },
                                expression: "addform.productNo",
                              },
                            },
                            _vm._l(_vm.type, function (item, index) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.usRattaFlag
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "笔编号",
                                "label-width": _vm.formLabelWidth,
                                prop: "penNo",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.changepenNo },
                                  model: {
                                    value: _vm.addform.penNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addform, "penNo", $$v)
                                    },
                                    expression: "addform.penNo",
                                  },
                                },
                                _vm._l(_vm.jacket, function (item, index1) {
                                  return item.value.includes("SP")
                                    ? _c("el-option", {
                                        attrs: {
                                          label:
                                            item.value + "_" + item.valueCn,
                                          value: item.value,
                                        },
                                      })
                                    : _vm._e()
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.usRattaFlag
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "封套编号",
                                "label-width": _vm.formLabelWidth,
                                prop: "coverNo",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                  },
                                  on: { change: _vm.changecoverNo },
                                  model: {
                                    value: _vm.addform.coverNo,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.addform, "coverNo", $$v)
                                    },
                                    expression: "addform.coverNo",
                                  },
                                },
                                _vm._l(_vm.jacket, function (item, index2) {
                                  return !item.value.includes("SP") &&
                                    !item.value.includes("SR")
                                    ? _c("el-option", {
                                        attrs: {
                                          label:
                                            item.value + "_" + item.valueCn,
                                          value: item.value,
                                        },
                                      })
                                    : _vm._e()
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.flag2 && _vm.isStockingShow
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "配件",
                            "label-width": _vm.formLabelWidth,
                            prop: "productNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeproductNo },
                              model: {
                                value: _vm.addform.productNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "productNo", $$v)
                                },
                                expression: "addform.productNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index3) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "数量",
                            "label-width": _vm.formLabelWidth,
                            prop: "count",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              autocomplete: "off",
                              type: "text",
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.forceUpdateCount($event)
                              },
                            },
                            model: {
                              value: _vm.addform.count,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "count",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.count",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单价",
                            "label-width": _vm.formLabelWidth,
                            prop: "price",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "10",
                              autocomplete: "off",
                              type: "text",
                            },
                            nativeOn: {
                              input: function ($event) {
                                return _vm.forceUpdatePrice($event)
                              },
                            },
                            model: {
                              value: _vm.addform.price,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addform,
                                  "price",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "addform.price",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addOrder },
                            },
                            [_vm._v("添加")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.addreaload },
                            },
                            [_vm._v("刷新")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c("h2", { staticStyle: { "padding-left": "50px" } }, [
                    _vm._v("订单摘要"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isStockingShow
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    _vm._l(_vm.addform.productList, function (result, index) {
                      return _c("div", [
                        _c(
                          "table",
                          {
                            staticStyle: {
                              width: "100%",
                              "border-spacing": "0px 10px",
                            },
                            attrs: { border: "0", cellspacing: "1" },
                          },
                          [
                            _c(
                              "tr",
                              {
                                staticStyle: {
                                  color: "#000",
                                  "font-weight": "500",
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c("td", { staticStyle: { width: "300px" } }, [
                                  _vm._v(
                                    "产品名称：" + _vm._s(result.productName)
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticStyle: { width: "200px" } }, [
                                  _vm._v("单价：" + _vm._s(result.price)),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticStyle: { width: "200px" } }, [
                                  _vm._v("数量：" + _vm._s(result.count)),
                                ]),
                                _vm._v(" "),
                                _vm.deleFlag
                                  ? _c(
                                      "td",
                                      { staticStyle: { width: "200px" } },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: { type: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.remove(index)
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-top": "15px",
                              "line-height": "20px",
                            },
                          },
                          [
                            _c("div", [_vm._v("包含：")]),
                            _vm._v(" "),
                            _vm._l(
                              result.productContainsList,
                              function (ite, index) {
                                return _c("div", [
                                  ite
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(index + 1) +
                                            "." +
                                            _vm._s(ite.productName) +
                                            "-1"
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            ),
                          ],
                          2
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.addClose("addform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.addsure("addform")
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "supple",
          attrs: {
            title: "补录",
            visible: _vm.suppleDialog,
            "close-on-click-modal": false,
            customClass: "customWidth",
          },
          on: {
            "update:visible": function ($event) {
              _vm.suppleDialog = $event
            },
            close: _vm.suppleClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "suppleform",
              attrs: {
                model: _vm.suppleform,
                rules: _vm.supplerules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "订单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderNo",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", type: "text" },
                        on: { blur: _vm.suppleSearch },
                        model: {
                          value: _vm.suppleform.orderNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.suppleform,
                              "orderNo",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "suppleform.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "金额",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderPrice",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", type: "text" },
                        model: {
                          value: _vm.suppleform.orderPrice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.suppleform,
                              "orderPrice",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "suppleform.orderPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "税费",
                        "label-width": _vm.formLabelWidth,
                        prop: "dutyTaxes",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off", type: "text" },
                        model: {
                          value: _vm.suppleform.dutyTaxes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.suppleform,
                              "dutyTaxes",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "suppleform.dutyTaxes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "费用类型",
                        "label-width": _vm.formLabelWidth,
                        prop: "costType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "93%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.suppleform.costType,
                            callback: function ($$v) {
                              _vm.$set(_vm.suppleform, "costType", $$v)
                            },
                            expression: "suppleform.costType",
                          },
                        },
                        _vm._l(_vm.costTypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "支付方式",
                        "label-width": _vm.formLabelWidth,
                        prop: "payType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.suppleform.payType,
                            callback: function ($$v) {
                              _vm.$set(_vm.suppleform, "payType", $$v)
                            },
                            expression: "suppleform.payType",
                          },
                        },
                        _vm._l(_vm.payTypes, function (item) {
                          return _c("el-option", {
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        label: "支付时间",
                        "label-width": _vm.formLabelWidth,
                        prop: "payTime",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          "default-time": "00:00:00",
                          "value-format": " yyyy-MM-dd HH:mm:ss",
                          format: "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          editable: false,
                        },
                        model: {
                          value: _vm.suppleform.payTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.suppleform, "payTime", $$v)
                          },
                          expression: "suppleform.payTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "交易号",
                    "label-width": _vm.formLabelWidth,
                    prop: "tradeNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.suppleform.tradeNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.suppleform, "tradeNo", $$v)
                      },
                      expression: "suppleform.tradeNo",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.suppleform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.suppleform, "remark", $$v)
                      },
                      expression: "suppleform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { background: "#e8f2ee" } },
                [
                  _vm.suppleFlag
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-left": "25px",
                            "margin-bottom": "15px",
                            color: "#115c3b",
                            "font-size": "18px",
                          },
                        },
                        [_vm._v("订单信息：")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.suppleList, function (item) {
                    return _vm.suppleFlag
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              padding: "0 20px",
                              "margin-bottom": "8px",
                            },
                          },
                          [
                            _c("table", [
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "600",
                                      "font-size": "13px",
                                      color: "#115c3b",
                                    },
                                    attrs: { width: "200" },
                                  },
                                  [
                                    _vm._v(
                                      "订单号：\n              " +
                                        _vm._s(item.orderNo) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "200" },
                                  },
                                  [_vm._v("渠道： " + _vm._s(item.dealer))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "200" },
                                  },
                                  [_vm._v("币种：" + _vm._s(item.unit))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "200" },
                                  },
                                  [_vm._v(" 订单总价： " + _vm._s(item.price))]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "table",
                              {
                                staticStyle: {
                                  border: "1px solid #c3d1ce",
                                  "margin-top": "2px",
                                  width: "100%",
                                },
                                attrs: { border: "1", cellspacing: "0" },
                              },
                              [
                                _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "50" },
                                    },
                                    [_vm._v("序号")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v("商品名称")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "80" },
                                    },
                                    [_vm._v("规格型号")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "50" },
                                    },
                                    [_vm._v("数量")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "50" },
                                    },
                                    [_vm._v("金额")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v("备注")]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  item.productList,
                                  function (result, index) {
                                    return _c("tr", { key: index }, [
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(index + 1))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(result.productName))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(result.productNo))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(result.count))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(result.price))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "th",
                                        {
                                          staticStyle: {
                                            "font-weight": "200",
                                            "font-size": "13px",
                                          },
                                        },
                                        [_vm._v(_vm._s(result.productContains))]
                                      ),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      : _vm._e()
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "isDele",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      model: {
                        value: _vm.isDele,
                        callback: function ($$v) {
                          _vm.isDele = $$v
                        },
                        expression: "isDele",
                      },
                    },
                    [_vm._v("追加")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      model: {
                        value: _vm.isDele,
                        callback: function ($$v) {
                          _vm.isDele = $$v
                        },
                        expression: "isDele",
                      },
                    },
                    [_vm._v("删减")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "套装/配件",
                    "label-width": _vm.formLabelWidth,
                    prop: "isPackage",
                  },
                },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "0" },
                      on: { change: _vm.isPack },
                      model: {
                        value: _vm.isPackage,
                        callback: function ($$v) {
                          _vm.isPackage = $$v
                        },
                        expression: "isPackage",
                      },
                    },
                    [_vm._v("套装")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.isPack },
                      model: {
                        value: _vm.isPackage,
                        callback: function ($$v) {
                          _vm.isPackage = $$v
                        },
                        expression: "isPackage",
                      },
                    },
                    [_vm._v("配件")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.packageFlag
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "产品编号",
                            "label-width": _vm.formLabelWidth,
                            prop: "productNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { placeholder: "请选择" },
                              on: { change: _vm.changeproductNo },
                              model: {
                                value: _vm.addform.productNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "productNo", $$v)
                                },
                                expression: "addform.productNo",
                              },
                            },
                            _vm._l(_vm.type, function (item, index) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "笔编号",
                            "label-width": _vm.formLabelWidth,
                            prop: "penNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changepenNo },
                              model: {
                                value: _vm.addform.penNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "penNo", $$v)
                                },
                                expression: "addform.penNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index1) {
                              return item.value.includes("SP")
                                ? _c("el-option", {
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "封套编号",
                            "label-width": _vm.formLabelWidth,
                            prop: "coverNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changecoverNo },
                              model: {
                                value: _vm.addform.coverNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "coverNo", $$v)
                                },
                                expression: "addform.coverNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index2) {
                              return !item.value.includes("SP") &&
                                !item.value.includes("SR")
                                ? _c("el-option", {
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.flag2
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "配件",
                            "label-width": _vm.formLabelWidth,
                            prop: "productNo",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "50%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeproductNo },
                              model: {
                                value: _vm.addform.productNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addform, "productNo", $$v)
                                },
                                expression: "addform.productNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index3) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.value + "_" + item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数量",
                        "label-width": _vm.formLabelWidth,
                        prop: "count",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          autocomplete: "off",
                          type: "text",
                        },
                        nativeOn: {
                          input: function ($event) {
                            return _vm.forceUpdateCount($event)
                          },
                        },
                        model: {
                          value: _vm.addform.count,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addform,
                              "count",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addform.count",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "单价",
                        "label-width": _vm.formLabelWidth,
                        prop: "price",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          autocomplete: "off",
                          type: "text",
                        },
                        nativeOn: {
                          input: function ($event) {
                            return _vm.forceUpdatePrice($event)
                          },
                        },
                        model: {
                          value: _vm.addform.price,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addform,
                              "price",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addform.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": _vm.formLabelWidth } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addOrder },
                        },
                        [_vm._v("添加")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addreaload },
                        },
                        [_vm._v("刷新")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h2", { staticStyle: { "padding-left": "50px" } }, [
                _vm._v("订单摘要"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { "margin-top": "20px" } },
                _vm._l(_vm.addform.productList, function (result, index) {
                  return _c("div", [
                    _c(
                      "table",
                      {
                        staticStyle: {
                          width: "100%",
                          "border-spacing": "0px 10px",
                        },
                        attrs: { border: "0", cellspacing: "1" },
                      },
                      [
                        _c(
                          "tr",
                          {
                            staticStyle: {
                              color: "#000",
                              "font-weight": "500",
                              display: "flex",
                              "justify-content": "space-between",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("td", { staticStyle: { width: "300px" } }, [
                              _vm._v("产品名称：" + _vm._s(result.productName)),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "150px" } }, [
                              _vm._v("单价：" + _vm._s(result.price)),
                            ]),
                            _vm._v(" "),
                            _c("td", { staticStyle: { width: "100px" } }, [
                              _vm._v("数量：" + _vm._s(result.count)),
                            ]),
                            _vm._v(" "),
                            _vm.deleFlag
                              ? _c(
                                  "td",
                                  { staticStyle: { width: "200px" } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.remove(index)
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "15px",
                          "line-height": "20px",
                        },
                      },
                      [
                        _c("div", [_vm._v("包含：")]),
                        _vm._v(" "),
                        _vm._l(
                          result.productContainsList,
                          function (ite, index) {
                            return _c("div", [
                              ite
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(index + 1) +
                                        "." +
                                        _vm._s(ite.productName) +
                                        "-1\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.suppleClose("addform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.supplesure("suppleform")
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "一键汇总",
            visible: _vm.gatherDialog,
            "close-on-click-modal": false,
            width: "26%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.gatherDialog = $event
            },
            close: _vm.gatherClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "gatherform",
              attrs: {
                model: _vm.gatherform,
                rules: _vm.gatherrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "渠道", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        filterable: "",
                        multiple: "",
                        placeholder: "请选择",
                      },
                      on: { change: _vm.changeDeaType },
                      model: {
                        value: _vm.gatherform.deaList,
                        callback: function ($$v) {
                          _vm.$set(_vm.gatherform, "deaList", $$v)
                        },
                        expression: "gatherform.deaList",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "产品编号",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.gatherform.productNo,
                        callback: function ($$v) {
                          _vm.$set(_vm.gatherform, "productNo", $$v)
                        },
                        expression: "gatherform.productNo",
                      },
                    },
                    _vm._l(_vm.newProductNo, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "支付起始时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "startPayTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "default-time": "00:00:00",
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      type: "date",
                      editable: false,
                    },
                    model: {
                      value: _vm.gatherform.startPayTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.gatherform, "startPayTime", $$v)
                      },
                      expression: "gatherform.startPayTime",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "支付截止时间",
                    "label-width": _vm.formLabelWidth,
                    prop: "endPayTime",
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "default-time": "00:00:00",
                      "value-format": "yyyy-MM-dd",
                      format: "yyyy-MM-dd",
                      type: "date",
                      editable: false,
                    },
                    model: {
                      value: _vm.gatherform.endPayTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.gatherform, "endPayTime", $$v)
                      },
                      expression: "gatherform.endPayTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.gatherClose("gatherform")
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: {
                    click: function ($event) {
                      return _vm.gathersure("gatherform")
                    },
                  },
                },
                [_vm._v("保存\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogGerForm, "close-on-click-modal": false },
          on: {
            "update:visible": function ($event) {
              _vm.dialogGerForm = $event
            },
            close: _vm.gerClose,
          },
        },
        [
          _c("div", { attrs: { id: "gerForm" } }, [
            _c("div", [
              _c("img", {
                staticStyle: { width: "100px" },
                attrs: {
                  src: "https://supernote.com.cn/user/ratta.png",
                  alt: "",
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "h3",
              {
                staticClass: "protitle",
                staticStyle: { display: "flex", "justify-content": "center" },
              },
              [
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.gertitle,
                        callback: function ($$v) {
                          _vm.gertitle = $$v
                        },
                        expression: "gertitle",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.gertitle) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "invoicetits",
                staticStyle: {
                  display: "flex",
                  "justify-content": "flex-end",
                  "font-weight": "700",
                  "font-size": "12px",
                },
              },
              [
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.invoiceNo,
                        callback: function ($$v) {
                          _vm.invoiceNo = $$v
                        },
                        expression: "invoiceNo",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.invoiceNo) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "invoicetits",
                staticStyle: {
                  display: "flex",
                  "justify-content": "flex-end",
                  "font-weight": "700",
                  "font-size": "12px",
                },
              },
              [
                _vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.printFlag
                  ? _c("el-input", {
                      attrs: { type: "text" },
                      domProps: { textContent: _vm._s(_vm.date) },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sender", staticStyle: { "font-weight": "bold" } },
              [_vm._v("SENDER:")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "cont" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("COMPANY NAME:\n            "),
                    _c("div", { staticStyle: { "margin-left": "78px" } }, [
                      _vm._v("Shanghai Ratta Smart Technology Co.,Ltd."),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("ADDERSS:\n            "),
                    _c("div", { staticStyle: { "margin-left": "120px" } }, [
                      _vm._v(
                        "Room 301,Building No.1,168 Jixin Road,Minhang\n              District,Shanghai,P.R.China\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("PHONE:\n            "),
                    _c("div", { staticStyle: { "margin-left": "135px" } }, [
                      _vm._v("(+86)21-34537701"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div"),
              _vm._v(" "),
              _c("div", { staticStyle: { opacity: "0" } }, [
                _c("div", { staticClass: "cont1" }, [_vm._v("COMPANY NAME:")]),
                _vm._v(" "),
                _c("div", { staticClass: "cont1" }, [_vm._v("ADDERSS:")]),
                _vm._v(" "),
                _c("div", { staticClass: "cont1" }, [_vm._v("PHONE:")]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "sender",
                staticStyle: { "margin-top": "15px", "font-weight": "bold" },
              },
              [_vm._v("RECEIVER:")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "cont" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("CONTACT PERSONP：\n            "),
                    _c(
                      "div",
                      {
                        staticClass: "cont2",
                        staticStyle: { "margin-left": "155px" },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.contacts,
                                callback: function ($$v) {
                                  _vm.contacts = $$v
                                },
                                expression: "contacts",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: { textContent: _vm._s(_vm.contacts) },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("ADDRESS:\n            "),
                    _c(
                      "div",
                      {
                        staticClass: "cont2",
                        staticStyle: { "margin-left": "140px" },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.receiverAddress,
                                callback: function ($$v) {
                                  _vm.receiverAddress = $$v
                                },
                                expression: "receiverAddress",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: {
                                textContent: _vm._s(_vm.receiverAddress),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "cont1", staticStyle: { display: "flex" } },
                  [
                    _vm._v("Phone:\n            "),
                    _c(
                      "div",
                      {
                        staticClass: "cont2",
                        staticStyle: { "margin-left": "167px" },
                      },
                      [
                        _vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              model: {
                                value: _vm.receiverPhone,
                                callback: function ($$v) {
                                  _vm.receiverPhone = $$v
                                },
                                expression: "receiverPhone",
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.printFlag
                          ? _c("el-input", {
                              attrs: { type: "text" },
                              domProps: {
                                textContent: _vm._s(_vm.receiverPhone),
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.rateFlag
                  ? _c(
                      "div",
                      {
                        staticClass: "cont1",
                        staticStyle: { display: "flex" },
                      },
                      [
                        _vm._v("Rate:\n            "),
                        _c(
                          "div",
                          {
                            staticClass: "cont2",
                            staticStyle: { "margin-left": "167px" },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: _vm.rate,
                                    callback: function ($$v) {
                                      _vm.rate = $$v
                                    },
                                    expression: "rate",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(_vm.rate) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _vm.FreightFlag
              ? _c(
                  "table",
                  {
                    staticStyle: { "margin-top": "10px", width: "100%" },
                    attrs: { border: "1", cellspacing: "0" },
                  },
                  [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("No."),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Product name"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Ratta no"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Item"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Logistics"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Tracking number"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Amount"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("(CNY)")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Amount"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("(USD)")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Import tariff"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("(USD)")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Export tariff"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("(USD)")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("th", {
                        staticStyle: {
                          "font-weight": "200",
                          "font-size": "13px",
                          color: "#000",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.gerList, function (item, index) {
                      return _c("tr", [
                        _c(
                          "th",
                          { staticStyle: { width: "50px", height: "50px" } },
                          [_vm._v(_vm._s(index + 1))]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "none",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                              width: "150px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.productName,
                                    callback: function ($$v) {
                                      _vm.$set(item, "productName", $$v)
                                    },
                                    expression: "item.productName",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.productName),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "models",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.rattaNo,
                                        callback: function ($$v) {
                                          _vm.$set(item, "rattaNo", $$v)
                                        },
                                        expression: "item.rattaNo",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(item.rattaNo),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "item",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.item,
                                        callback: function ($$v) {
                                          _vm.$set(item, "item", $$v)
                                        },
                                        expression: "item.item",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(item.item),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.logisticsName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "logisticsName", $$v)
                                        },
                                        expression: "item.logisticsName",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(item.logisticsName),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.trackingNumber,
                                        callback: function ($$v) {
                                          _vm.$set(item, "trackingNumber", $$v)
                                        },
                                        expression: "item.trackingNumber",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(
                                          item.trackingNumber
                                        ),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.amountRmb,
                                    callback: function ($$v) {
                                      _vm.$set(item, "amountRmb", $$v)
                                    },
                                    expression: "item.amountRmb",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.amountRmb),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.amountUsd,
                                    callback: function ($$v) {
                                      _vm.$set(item, "amountUsd", $$v)
                                    },
                                    expression: "item.amountUsd",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.amountUsd),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.inTax,
                                    callback: function ($$v) {
                                      _vm.$set(item, "inTax", $$v)
                                    },
                                    expression: "item.inTax",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(item.inTax) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.outTax,
                                    callback: function ($$v) {
                                      _vm.$set(item, "outTax", $$v)
                                    },
                                    expression: "item.outTax",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.outTax),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.delFlag
                          ? _c("th", [
                              _c(
                                "span",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gerDel(index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { attrs: { colspan: "7" } }, [
                        _vm._v(
                          "Total Cost:(USD) " + _vm._s(_vm.totalCostUpper)
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "units", attrs: { colspan: "2" } },
                        [
                          _vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.gerTotal,
                                  callback: function ($$v) {
                                    _vm.gerTotal = $$v
                                  },
                                  expression: "gerTotal",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                domProps: { textContent: _vm._s(_vm.gerTotal) },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.gerInFlag
              ? _c(
                  "table",
                  {
                    staticStyle: { "margin-top": "10px", width: "100%" },
                    attrs: { border: "1", cellspacing: "0" },
                  },
                  [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("No."),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Product name"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Ratta no"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Item"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Unit Price"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("(USD)")]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Quantity"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Unit"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                            color: "#000",
                          },
                        },
                        [
                          _c("div", { staticStyle: { "font-weight": "500" } }, [
                            _vm._v("Amount"),
                          ]),
                          _vm._v(" "),
                          _c("div", [_vm._v("(USD)")]),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.delFlag
                        ? _c(
                            "th",
                            {
                              staticStyle: {
                                "font-weight": "200",
                                "font-size": "13px",
                                color: "#000",
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { "font-weight": "500" } },
                                [_vm._v("操作")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.gerList, function (item, index) {
                      return _c("tr", [
                        _c(
                          "th",
                          { staticStyle: { width: "50px", height: "50px" } },
                          [_vm._v(_vm._s(index + 1))]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "none",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                              width: "150px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.productName,
                                    callback: function ($$v) {
                                      _vm.$set(item, "productName", $$v)
                                    },
                                    expression: "item.productName",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.productName),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "models",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.rattaNo,
                                        callback: function ($$v) {
                                          _vm.$set(item, "rattaNo", $$v)
                                        },
                                        expression: "item.rattaNo",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(item.rattaNo),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "item",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.item,
                                        callback: function ($$v) {
                                          _vm.$set(item, "item", $$v)
                                        },
                                        expression: "item.item",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(item.item),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                },
                              },
                              [
                                _vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      model: {
                                        value: item.unitPrice,
                                        callback: function ($$v) {
                                          _vm.$set(item, "unitPrice", $$v)
                                        },
                                        expression: "item.unitPrice",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.printFlag
                                  ? _c("el-input", {
                                      attrs: { type: "text" },
                                      domProps: {
                                        textContent: _vm._s(item.unitPrice),
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.quantity,
                                    callback: function ($$v) {
                                      _vm.$set(item, "quantity", $$v)
                                    },
                                    expression: "item.quantity",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.quantity),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.unit,
                                    callback: function ($$v) {
                                      _vm.$set(item, "unit", $$v)
                                    },
                                    expression: "item.unit",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: { textContent: _vm._s(item.unit) },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticClass: "unit",
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [
                            _vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  model: {
                                    value: item.amountUsd,
                                    callback: function ($$v) {
                                      _vm.$set(item, "amountUsd", $$v)
                                    },
                                    expression: "item.amountUsd",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.printFlag
                              ? _c("el-input", {
                                  attrs: { type: "text" },
                                  domProps: {
                                    textContent: _vm._s(item.amountUsd),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.delFlag
                          ? _c("th", [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#409EFF",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.gerDel(index)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { attrs: { colspan: "7" } }, [
                        _vm._v(
                          "Total Cost:(USD) " + _vm._s(_vm.totalCostUpper)
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "th",
                        { staticClass: "units", attrs: { colspan: "2" } },
                        [
                          _vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.gerTotal,
                                  callback: function ($$v) {
                                    _vm.gerTotal = $$v
                                  },
                                  expression: "gerTotal",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.printFlag
                            ? _c("el-input", {
                                attrs: { type: "text" },
                                domProps: { textContent: _vm._s(_vm.gerTotal) },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                { staticClass: "tit2" },
                [
                  _vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.bank,
                          callback: function ($$v) {
                            _vm.bank = $$v
                          },
                          expression: "bank",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        domProps: { textContent: _vm._s(_vm.bank) },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tit2" },
                [
                  _vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.numbers,
                          callback: function ($$v) {
                            _vm.numbers = $$v
                          },
                          expression: "numbers",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        domProps: { textContent: _vm._s(_vm.numbers) },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tit2" },
                [
                  _vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.banknames,
                          callback: function ($$v) {
                            _vm.banknames = $$v
                          },
                          expression: "banknames",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        domProps: { textContent: _vm._s(_vm.bankname) },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tit2" },
                [
                  _vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.bankaddresss,
                          callback: function ($$v) {
                            _vm.bankaddresss = $$v
                          },
                          expression: "bankaddresss",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        domProps: { textContent: _vm._s(_vm.bankaddress) },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "tit2" },
                [
                  _vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.swiftcodes,
                          callback: function ($$v) {
                            _vm.swiftcodes = $$v
                          },
                          expression: "swiftcodes",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.printFlag
                    ? _c("el-input", {
                        attrs: { type: "text" },
                        domProps: { textContent: _vm._s(_vm.swiftcode) },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.gerHtml },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "条目配置", visible: _vm.visible },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: function ($event) {
              _vm.visible = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.visible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveColumn },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              _vm._l(_vm.show2Column, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticStyle: { width: "25%", display: "inline-block" },
                    style: { marginRight: (i + 1) % 3 === 0 ? 0 : "10%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-top": "10px",
                          "font-weight": "500",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "140px",
                              "margin-right": "10px",
                              "text-align": "right",
                            },
                          },
                          [_vm._v(_vm._s(item.value))]
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#bfbfbf",
                          },
                          model: {
                            value: item.flag,
                            callback: function ($$v) {
                              _vm.$set(item, "flag", $$v)
                            },
                            expression: "item.flag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "筛选框配置", visible: _vm.dialogFormSearch },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormSearch = $event
            },
            close: function ($event) {
              _vm.dialogFormSearch = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogFormSearch = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.searchSure },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              _vm._l(_vm.flagList, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticStyle: { width: "25%", display: "inline-block" },
                    style: { marginRight: (i + 1) % 3 === 0 ? 0 : "10%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-top": "10px",
                          "font-weight": "500",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "120px",
                              "margin-right": "10px",
                              "text-align": "right",
                            },
                          },
                          [_vm._v(_vm._s(item.value))]
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#bfbfbf",
                          },
                          model: {
                            value: item.flag,
                            callback: function ($$v) {
                              _vm.$set(item, "flag", $$v)
                            },
                            expression: "item.flag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "仓位调整",
            visible: _vm.locationDialog,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.locationDialog = $event
            },
            close: _vm.locationclose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.locationForm,
                rules: _vm.locationForm,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: { label: "区域仓", "label-width": _vm.formLabelWidth },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "请选择",
                        prop: "locationPosition",
                      },
                      model: {
                        value: _vm.locationForm.locationPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.locationForm, "locationPosition", $$v)
                        },
                        expression: "locationForm.locationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositions, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.locationclose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.locationClickloading,
                      expression: "locationClickloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.locationClick },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "支付回调",
            visible: _vm.dialogForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogForm = $event
            },
            close: _vm.logisticsClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "callBacks",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.callBacks,
                rules: _vm.payrule,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "支付方式",
                    "label-width": _vm.formLabelWidth,
                    prop: "payId",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.callBacks.payId,
                        callback: function ($$v) {
                          _vm.$set(_vm.callBacks, "payId", $$v)
                        },
                        expression: "callBacks.payId",
                      },
                    },
                    _vm._l(_vm.payTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "交易号",
                    "label-width": _vm.formLabelWidth,
                    prop: "outTradeNo",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off" },
                    model: {
                      value: _vm.callBacks.outTradeNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.callBacks, "outTradeNo", $$v)
                      },
                      expression: "callBacks.outTradeNo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.logisticsClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keeps },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "数量不足提示",
            visible: _vm.usSkuCountVisible,
            "close-on-click-modal": false,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.usSkuCountVisible = $event
            },
            close: _vm.usSkuCountClose,
          },
        },
        [
          _c("br"),
          _c("span", [
            _vm._v("以下编号的产品美国仓数量不足，需及时补仓，请尽快处理！"),
          ]),
          _c("br"),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.skus))]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("input", {
                ref: "uploadFile",
                staticClass: "upload-file",
                attrs: {
                  id: "usSkuInput",
                  type: "file",
                  name: "file",
                  multiple: "",
                },
                on: { change: _vm.shopfiyUpload },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.usSkuInput },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "detail",
          attrs: {
            title: "订单详情",
            visible: _vm.dialogsForm,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogsForm = $event
            },
            close: _vm.detailClose,
          },
        },
        [
          _vm._l(_vm.detailList, function (res) {
            return _c(
              "el-form",
              {
                ref: "detailform",
                refInFor: true,
                staticStyle: {
                  "border-bottom": "5px solid #000",
                  "padding-bottom": "20px",
                },
                attrs: {
                  model: _vm.detailform,
                  "status-icon": true,
                  "show-message": false,
                  "hide-required-asterisk": "",
                },
              },
              [
                _vm._l(res.orderInfoDetailList, function (item) {
                  return _c("div", [
                    !item.orderNo.includes("~")
                      ? _c(
                          "div",
                          [
                            _c(
                              "table",
                              {
                                staticStyle: {
                                  width: "100%",
                                  "border-spacing": "0px 10px",
                                  "margin-top": "10px",
                                },
                                attrs: { border: "0", cellspacing: "1" },
                              },
                              [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { staticStyle: { width: "400px" } },
                                    [
                                      _vm._v("订单号："),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            background: "rgb(83,217,213)",
                                            padding: "5px 10px",
                                            "font-weight": "bold",
                                            color: "#fff",
                                          },
                                        },
                                        [_vm._v(_vm._s(item.orderNo))]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  item.orderType == "0"
                                    ? _c("td", [_vm._v("订单类型：预售")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.orderType == "1"
                                    ? _c("td", [_vm._v("订单类型：订购")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.orderType == "2"
                                    ? _c("td", [_vm._v("订单类型：换货")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("状态：" + _vm._s(item.status)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v("订单总价：" + _vm._s(item.price)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "商品总价： " + _vm._s(item.productPrice)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("币种：" + _vm._s(item.unit)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v("渠道：" + _vm._s(item.dealer)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "支付方式： " + _vm._s(item.payType)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("税费： " + _vm._s(item.dutyTaxes)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v("交易号：" + _vm._s(item.tradeNo)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("支付时间：" + _vm._s(item.payTime)),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v("收货人：" + _vm._s(item.name)),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      "收货人手机号：" + _vm._s(item.telephone)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "联系人邮箱：" + _vm._s(item.contactEmail)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "联系人手机号：" +
                                        _vm._s(item.contactTelephone)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      "物流单号(名称)：" +
                                        _vm._s(item.logisticsNumber) +
                                        "(" +
                                        _vm._s(item.logisticsName) +
                                        ")"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "发货时间：" + _vm._s(item.deliverTime)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "运费：" + _vm._s(item.logisticsPrice)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      "区域仓：" + _vm._s(item.locationPosition)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  item.trialType == "0"
                                    ? _c("td", [_vm._v("试用标识：试用")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.trialType == "1"
                                    ? _c("td", [_vm._v("试用标识：售卖")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "多次支付方式：" +
                                        _vm._s(item.repeatPayType)
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("tr", [
                                  _c("td", [
                                    _vm._v(
                                      "优惠金额：" + _vm._s(item.discountPrice)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "流水号：" + _vm._s(item.serialNumber)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "支付次数：" + _vm._s(item.payCounts)
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "word-break": "break-all" } },
                              [
                                _vm._v(
                                  "原单设备号：" + _vm._s(item.equipmentNumber)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "word-break": "break-all",
                                },
                              },
                              [
                                _vm._v(
                                  "实际设备号：" +
                                    _vm._s(item.realEquipmentNumber)
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "word-break": "break-all",
                                },
                              },
                              [_vm._v("地址：" + _vm._s(item.address))]
                            ),
                            _vm._v(" "),
                            item.promoDecide
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "20px",
                                      color: "#000",
                                      "font-size": "16px",
                                    },
                                  },
                                  [_vm._v("优惠码")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            item.promoDecide
                              ? _c(
                                  "el-table",
                                  {
                                    directives: [
                                      {
                                        name: "loading",
                                        rawName: "v-loading",
                                        value: _vm.loading,
                                        expression: "loading",
                                      },
                                    ],
                                    staticStyle: {
                                      width: "100%",
                                      "margin-top": "30px",
                                    },
                                    attrs: { data: item.promoList, border: "" },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "优惠类型",
                                        "show-overflow-tooltip": "",
                                        prop: "promoType",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "优惠码",
                                        "show-overflow-tooltip": "",
                                        prop: "promoCode",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "币种",
                                        "show-overflow-tooltip": "",
                                        prop: "unit",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "金额",
                                        "show-overflow-tooltip": "",
                                        prop: "promoPrice",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        align: "center",
                                        label: "礼品名称",
                                        "show-overflow-tooltip": "",
                                        prop: "giftName",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "20px",
                                  color: "#000",
                                  "font-size": "18px",
                                },
                              },
                              [_vm._v("订单摘要")]
                            ),
                            _vm._v(" "),
                            _vm._l(item.productList, function (result) {
                              return _c(
                                "div",
                                { staticStyle: { "margin-top": "20px" } },
                                [
                                  _c(
                                    "table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "border-spacing": "0px 10px",
                                      },
                                      attrs: { border: "0", cellspacing: "1" },
                                    },
                                    [
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: {
                                            color: "#000",
                                            "font-weight": "500",
                                          },
                                        },
                                        [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "400px" } },
                                            [
                                              _vm._v(
                                                "产品名称：" +
                                                  _vm._s(result.productName)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "金额：" + _vm._s(result.price)
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "数量：" + _vm._s(result.count)
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "15px",
                                        "line-height": "20px",
                                      },
                                    },
                                    [
                                      _c("div", [_vm._v("包含：")]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("序号 产品编号   产品名称-数量"),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                        },
                                        [_vm._v("平台价")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                        },
                                        [_vm._v("结算价")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                        },
                                        [_vm._v("仓位")]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        result.productContainsVOList,
                                        function (ite, index) {
                                          return _c("div", [
                                            ite
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(index + 1) +
                                                      ". " +
                                                      _vm._s(ite.productNo) +
                                                      "   " +
                                                      _vm._s(
                                                        ite.productNameCn
                                                      ) +
                                                      "-" +
                                                      _vm._s(ite.count)
                                                  ),
                                                ])
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [_vm._v(_vm._s(ite.price))]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [_vm._v(_vm._s(ite.realPrice))]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [_vm._v(_vm._s(ite.position))]
                                            ),
                                          ])
                                        }
                                      ),
                                      _vm._v(" "),
                                      result.additionalVOList &&
                                      result.additionalVOList.length > 0
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "auto",
                                                borderTop: "1px solid #707070",
                                                marginTop: "20px",
                                              },
                                            },
                                            [
                                              _vm._l(
                                                result.additionalVOList,
                                                function (ite) {
                                                  return ite.type == 1
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            paddingTop: "5px",
                                                            borderBottom:
                                                              "1px solid #707070",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                width: "100%",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "space-between",
                                                                alignItems:
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    fontSize:
                                                                      "32",
                                                                    fontWeight:
                                                                      "400",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "免费激光镌刻"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                padding:
                                                                  "5px 0",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "space-between",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v(
                                                                  "内容：" +
                                                                    _vm._s(
                                                                      ite.content
                                                                    )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "字体：" +
                                                                    _vm._s(
                                                                      ite.font
                                                                    )
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c("div", [
                                                                _vm._v(
                                                                  "位置：" +
                                                                    _vm._s(
                                                                      ite.position
                                                                    )
                                                                ),
                                                              ]),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                result.additionalVOList,
                                                function (ite) {
                                                  return ite.type == 3
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            height: "50px",
                                                            display: "flex",
                                                            justifyContent:
                                                              "space-between",
                                                            alignItems:
                                                              "center",
                                                            borderBottom:
                                                              "1px solid #707070",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                fontSize:
                                                                  "16px",
                                                                fontWeight:
                                                                  "400",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "免费包装选择"
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                alignItems:
                                                                  "center",
                                                                paddingLeft:
                                                                  "13px",
                                                                border:
                                                                  "1px solid #707070",
                                                                paddingRight:
                                                                  "13px",
                                                                height: "35px",
                                                                fontSize:
                                                                  "13px",
                                                                backgroundColor:
                                                                  "#707070",
                                                                fontWeight:
                                                                  "400",
                                                                marginRight:
                                                                  "16px",
                                                              },
                                                            },
                                                            [
                                                              _c("img", {
                                                                attrs: {
                                                                  src: require("../../assets/img/check-white.png"),
                                                                  alt: "",
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    marginLeft:
                                                                      "10px",
                                                                    color:
                                                                      "#ffffff",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "礼品包装"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e()
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "备注：",
                                  "label-width": "58px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.remark) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            res.flag == "Y"
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "15px",
                                      "margin-bottom": "7px",
                                      color: "#409EFF",
                                      "font-size": "18px",
                                    },
                                  },
                                  [_vm._v("补录单信息：\n          ")]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item.orderNo.includes("~")
                      ? _c("div", [
                          _c("table", [
                            _c("th", [
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "font-weight": "200",
                                    "font-size": "13px",
                                  },
                                  attrs: { width: "205" },
                                },
                                [_vm._v("订单编号： " + _vm._s(item.orderNo))]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "text-align": "left",
                                    "font-weight": "200",
                                    "font-size": "13px",
                                  },
                                  attrs: { width: "300" },
                                },
                                [_vm._v("金额：" + _vm._s(item.price))]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "font-weight": "200",
                                    "font-size": "13px",
                                  },
                                  attrs: { width: "200" },
                                },
                                [_vm._v(" 付款日期： " + _vm._s(item.payTime))]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "table",
                            { staticStyle: { "margin-top": "-8px" } },
                            [
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "200" },
                                  },
                                  [_vm._v(" 付款渠道：" + _vm._s(item.payType))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "300" },
                                  },
                                  [_vm._v(" 交易号：" + _vm._s(item.tradeNo))]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "300" },
                                  },
                                  [_vm._v(" 税费：" + _vm._s(item.dutyTaxes))]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            { staticStyle: { "margin-top": "-8px" } },
                            [
                              _c("th", [
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(" 备注：" + _vm._s(item.remark))]
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              key: _vm.index,
                              staticStyle: {
                                border: "1px solid skyblue",
                                "margin-top": "2px",
                                width: "100%",
                              },
                              attrs: { border: "1", cellspacing: "0" },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("序号")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v("商品名称")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "80" },
                                  },
                                  [_vm._v("规格型号")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("数量")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("金额")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v("备注")]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                item.productList,
                                function (result, index) {
                                  return _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(index + 1))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.productName))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.productNo))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.count))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.price))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(_vm._s(result.productContains))]
                                    ),
                                  ])
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ])
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "15px",
                      "margin-bottom": "15px",
                      color: "#115c3b",
                      "font-size": "18px",
                    },
                  },
                  [_vm._v("订单实际商品：")]
                ),
                _vm._v(" "),
                _c(
                  "table",
                  {
                    staticStyle: {
                      border: "1px solid #e8f2ee",
                      "margin-top": "2px",
                      width: "100%",
                    },
                    attrs: { border: "1", cellspacing: "0" },
                  },
                  [
                    _c("tr", [
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                          attrs: { width: "50" },
                        },
                        [_vm._v("产品名称")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                          attrs: { width: "50" },
                        },
                        [_vm._v("产品型号")]
                      ),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticStyle: {
                            "font-weight": "200",
                            "font-size": "13px",
                          },
                          attrs: { width: "50" },
                        },
                        [_vm._v("数量")]
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._l(res.realOrderList, function (real) {
                      return _c("tr", [
                        _c(
                          "th",
                          {
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(real.productName))]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(real.productNo))]
                        ),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            staticStyle: {
                              "font-weight": "200",
                              "font-size": "13px",
                            },
                          },
                          [_vm._v(_vm._s(real.count))]
                        ),
                      ])
                    }),
                  ],
                  2
                ),
              ],
              2
            )
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.detailClose } }, [
                _vm._v("关闭"),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "upload-wrapper" }, [
      _c("img", {
        attrs: { src: require("../../assets/iconfont/to.png"), alt: "" },
      }),
      _vm._v(" "),
      _c("span", [_vm._v("Shopify导入")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }