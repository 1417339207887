"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBanner = addBanner;
exports.dataManipulation = dataManipulation;
exports.delBanner = delBanner;
exports.getBannerById = getBannerById;
exports.queryBanner = queryBanner;
exports.queryRecommend = queryRecommend;
exports.updateBanner = updateBanner;
exports.updateLowerLine = updateLowerLine;
exports.updateSeq = updateSeq;
exports.upload = upload;
var _request = _interopRequireDefault(require("@/utils/request"));
function queryBanner(data) {
  return (0, _request.default)({
    url: 'cms/manage/homeBanner/query',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function queryRecommend(data) {
  return (0, _request.default)({
    url: 'cms/inner/link',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function addBanner(data) {
  return (0, _request.default)({
    url: 'cms/manage/homeBanner',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function updateBanner(data) {
  return (0, _request.default)({
    url: 'cms/manage/homeBanner',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function upload(data) {
  return (0, _request.default)({
    url: 'cms/picture/upload',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    timeout: 3600000
  });
}
function getBannerById(id) {
  return (0, _request.default)({
    url: 'cms/manage/homeBanner/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
function updateLowerLine(data, id) {
  return (0, _request.default)({
    url: 'cms/manage/homeBanner/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function updateSeq(data, id) {
  return (0, _request.default)({
    url: 'cms/manage/homeBanner/seq/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
function delBanner(id) {
  return (0, _request.default)({
    url: 'cms/manage/homeBanner/' + id,
    method: 'delete',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, line, updateTime, updateUser) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (updateUser != null && updateUser != "") {
        element.updateUser = updateUser;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
      if (line != null && line != "") {
        element.line = line;
      }
    }
  });
  return tableData;
}