"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.aloneList = aloneList;
exports.changePrice = changePrice;
exports.dataManipulation = dataManipulation;
exports.delePrice = delePrice;
exports.getLists = getLists;
exports.getseries = getseries;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取套餐价格
function getLists(data) {
  return (0, _request.default)({
    url: '/cms/package/price/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//新增套餐价格
function addList(data) {
  return (0, _request.default)({
    url: '/cms/package/price',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//查询单个套餐价格
function aloneList(id) {
  return (0, _request.default)({
    url: 'cms/package/price/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除套餐价格
function delePrice(id) {
  return (0, _request.default)({
    url: 'cms/package/price/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//修改套餐价格
function changePrice(data) {
  return (0, _request.default)({
    url: 'cms/package/price',
    method: 'PUT',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询类型
function getseries(type) {
  return (0, _request.default)({
    url: 'cms/part/series/type/' + type,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, status, publishTime, updateTime) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (status != null && status != "") {
        element.status = status;
      }
      if (publishTime != null && publishTime != "") {
        element.publishTime = publishTime;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
    }
  });
  return tableData;
}