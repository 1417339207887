"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _orderStatement = require("../../api/orderStatement.js");
var _dictionary = require("../../api/dictionary.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      languages: [{
        language: "zh-CN",
        label: "中文"
      }, {
        language: "EN",
        label: "英文"
      }, {
        language: "JA",
        label: "日文"
      }],
      form: {
        endPayTime: "",
        productNo: "",
        locationPosition: "",
        pageNo: 1,
        pageSize: 100,
        startPayTime: '',
        productName: ''
      },
      loading: false,
      tableData: [],
      locationPositions: [],
      currentPage: 1,
      total: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    (0, _dictionary.searchDic)({
      name: "LOCATION_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.locationPositions = res.voList;
    });
    var end = new Date();
    var start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.form.startPayTime = this.formatDate(start);
    this.form.endPayTime = this.formatDate(end);
    this.getList();
  },
  methods: {
    //格式化日期
    formatDate: function formatDate(date) {
      var myyear = date.getFullYear();
      var mymonth = date.getMonth() + 1;
      var myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _orderStatement.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _orderStatement.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      //this.form.startPayTime = "";
      this.form.productNo = "";
      this.form.locationPosition = "";
      // this.form.endPayTime = ""
      this.form.productName = "";
      this.getList();
    },
    //导出
    exports: function exports() {
      var _this3 = this;
      this.loading = true;
      (0, _orderStatement.getExport)(this.form).then(function (res) {
        var data = new Blob([res], {
          type: "application/vnd.ms-excel"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "order-report-shipment.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(data);
        _this3.loading = false;
      });
    }
  }
};