"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aloneList = aloneList;
exports.changeList = changeList;
exports.deleLists = deleLists;
exports.getLists = getLists;
exports.importList = importList;
exports.orderWaybillExport = orderWaybillExport;
exports.printList = printList;
exports.undoList = undoList;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询问题反馈信息
function getLists(data) {
  return (0, _request.default)({
    url: 'order/waybill/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//导入
function importList(data) {
  return (0, _request.default)({
    url: 'order/waybill/import',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}
//打印
function printList(data) {
  return (0, _request.default)({
    url: 'order/waybill/print',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个信息
function aloneList(id) {
  return (0, _request.default)({
    url: 'order/waybill/query/' + id,
    method: 'GET',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//导出
function orderWaybillExport(data) {
  return (0, _request.default)({
    url: 'order/waybill/export',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    data: data,
    timeout: 1800000
  });
}

//修改
function changeList(data) {
  return (0, _request.default)({
    url: 'order/waybill/update',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//删除
function deleLists(data) {
  return (0, _request.default)({
    url: 'order/waybill/delete',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}
//撤销
function undoList(data) {
  return (0, _request.default)({
    url: 'order/waybill/undo',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}