"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _video = require("../../api/video.js");
var _dictionary = require("../../api/dictionary.js");
var _index = require("../../utils/index.js");
var _awards = require("../../api/awards.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return (0, _defineProperty2.default)((0, _defineProperty2.default)({
      tableData: [],
      loading: false,
      form: {
        line: "",
        pageNo: 1,
        pageSize: 10,
        type: ""
      },
      forms: {
        line: "",
        pageNo: 1,
        pageSize: 10,
        type: ""
      },
      type: [],
      options: [{
        name: "上线",
        type: "0"
      }, {
        name: "预览",
        type: "1"
      }, {
        name: "下线",
        type: "2"
      }],
      total: 0,
      currentPage: this.$route.query.cur ? Number(this.$route.query.cur) : 1,
      isShow: false,
      isFocus: true,
      titletype: '',
      dialogFormVisible: false,
      loadingCn: false,
      loadingEn: false,
      loadingJa: false,
      videoflagCn: true,
      videoflagsCn: false,
      videoSrcCn: '',
      videoflagEn: true,
      videoflagsEn: false,
      videoSrcEn: '',
      videoflagJa: true,
      videoflagsJa: false,
      videoSrcJa: '',
      addform: {
        type: '',
        videoCn: '',
        videoEn: '',
        videoJa: '',
        videoTypeCn: '0',
        videoTypeEn: '0',
        videoTypeJa: '0'
      },
      formLabelWidth: '120px',
      isDisable: false,
      rules: {
        type: [{
          required: true,
          trigger: "blur"
        }],
        videoCn: [{
          required: true,
          trigger: "blur"
        }],
        videoEn: [{
          required: true,
          trigger: "blur"
        }],
        videoJa: [{
          required: true,
          trigger: "blur"
        }]
      }
    }, "dialogFormVisible", false), "id", '');
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _dictionary.searchDiclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.type = res.voList;
    });
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //请求列表数据
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _video.getLists)(_this2.forms).then(function (res) {
          if (res.success) {
            _this2.tableData = res.voList;
            _this2.loading = false;
          }
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //查找
    search: function search() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.type = this.form.type;
      this.forms.line = this.form.line;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.forms.type = "";
      this.currentPage = 1;
      this.forms.line = "";
      this.forms.pageNo = 1;
      this.form.line = "";
      this.form.type = "";
      //   window.location.href=window.location.href.split('?')[0]
      this.getList();
    },
    //新增
    add: function add() {
      this.titletype = '新增';
      this.dialogFormVisible = true;
    },
    //修改
    change: function change(id) {
      var _this3 = this;
      this.id = id;
      this.titletype = '修改';
      this.dialogFormVisible = true;
      (0, _video.alonevideo)(id).then(function (res) {
        console.log(res.voT.videoEn, '0-0-0');
        if (res.success) {
          _this3.addform.type = res.voT.type;
          if (res.voT.videoCn !== "") {
            _this3.videoflagCn = false;
            _this3.videoflagsCn = true;
            _this3.videoSrcCn = res.voT.videoCn.split('===')[0];
            _this3.addform.videoSrcCn = res.voT.videoCn;
          }
          if (res.voT.videoEn !== "") {
            _this3.videoflagEn = false;
            _this3.videoflagsEn = true;
            _this3.videoSrcEn = res.voT.videoEn.split('===')[0];
            _this3.addform.videoSrcEn = res.voT.videoEn;
          }
          if (res.voT.videoJa !== "") {
            _this3.videoflagJa = false;
            _this3.videoflagsJa = true;
            _this3.videoSrcJa = res.voT.videoJa.split('===')[0];
            _this3.addform.videoJa = res.voT.videoJa;
          }
          _this3.addform.videoTypeCn = res.voT.videoTypeCn;
          _this3.addform.videoTypeEn = res.voT.videoTypeEn;
          _this3.addform.videoTypeJa = res.voT.videoTypeJa;
        } else {
          _this3.msgTip("error", res.errorMsg);
        }
      });
    },
    addClose: function addClose() {
      this.dialogFormVisible = false;
      this.addform.type = '';
      this.videoflagsCn = false;
      this.videoflagsEn = false;
      this.videoflagsJa = false;
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line == '2') {
        return "下线";
      } else if (row.line == '1') {
        return "预览";
      }
    },
    //判断视频类型
    videoCn: function videoCn(row) {
      if (row.videoTypeCn == "0") {
        return '视频';
      } else if (row.videoTypeCn == "1") {
        return '链接';
      }
    },
    videoEn: function videoEn(row) {
      if (row.videoTypeEn == "0") {
        return '视频';
      } else if (row.videoTypeEn == "1") {
        return '链接';
      }
    },
    videoJa: function videoJa(row) {
      if (row.videoTypeJa == "0") {
        return '视频';
      } else if (row.videoTypeJa == "1") {
        return '链接';
      }
    },
    //上线
    online: function online(row) {
      var _this4 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _video.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "上线成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this5 = this;
      if (row.line == '1') {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _video.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "操作成功");
            _this5.getList();
          } else {
            _this5.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(row) {
      var _this6 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _video.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "下线成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this7 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _video.deleVideo)(id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "删除成功");
            _this7.getList();
          } else {
            _this7.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    //上传视频
    submitUploadCn: function submitUploadCn() {
      var _this8 = this;
      var fileData = this.$refs.uploadFileCn.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingCn = true;
        this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _awards.upload)(formFile).then(function (res) {
        if (res.success) {
          _this8.loadingCn = false;
          if (res.valueCn !== '') {
            _this8.videoflagCn = false;
            _this8.videoflagsCn = true;
            _this8.videoSrcCn = res.voT.downloadUrl.split('===')[0];
            _this8.addform.videoCn = res.voT.downloadUrl;
          }
        } else {
          _this8.loadingCn = false;
          _this8.msgTip("error", res.errorMsg);
          _this8.$refs.uploadFileCn.value = null;
        }
      });
    },
    //上传视频
    submitUploadEn: function submitUploadEn() {
      var _this9 = this;
      var fileData = this.$refs.uploadFileEn.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingEn = true;
        this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _awards.upload)(formFile).then(function (res) {
        if (res.success) {
          _this9.loadingEn = false;
          if (res.valueCn !== '') {
            _this9.videoflagEn = false;
            _this9.videoflagsEn = true;
            _this9.videoSrcEn = res.voT.downloadUrl.split('===')[0];
            _this9.addform.videoEn = res.voT.downloadUrl;
          }
        } else {
          _this9.loadingEn = false;
          _this9.msgTip("error", res.errorMsg);
          _this9.$refs.uploadFileEn.value = null;
        }
      });
    },
    //上传视频JA
    submitUploadJa: function submitUploadJa() {
      var _this10 = this;
      var fileData = this.$refs.uploadFileJa.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      var index = fileData.name.lastIndexOf(".");
      var str = fileData.name.substring(index + 1, fileData.name.length);
      var extension0 = str === "mp4";
      var extension1 = str === "avi";
      var extension2 = str === "mov";
      var extension3 = str === "rmvb";
      var extension4 = str === "rm";
      var extension5 = str === "flv";
      var extension6 = str === "3gp";
      if (extension0 || extension1 || extension2 || extension3 || extension4 || extension5 || extension6) {
        this.loadingJa = true;
        this.savedisab = true;
      } else {
        this.msgTip("error", "上传文件只能是视频格式!");
        return;
      }
      (0, _awards.upload)(formFile).then(function (res) {
        if (res.success) {
          _this10.loadingJa = false;
          if (res.valueCn !== '') {
            _this10.videoflagJa = false;
            _this10.videoflagsJa = true;
            _this10.videoSrcJa = res.voT.downloadUrl.split('===')[0];
            _this10.addform.videoJa = res.voT.downloadUrl;
          }
        } else {
          _this10.loadingJa = false;
          _this10.msgTip("error", res.errorMsg);
          _this10.$refs.uploadFileJa.value = null;
        }
      });
    },
    //提交
    sure: function sure() {
      var _this11 = this;
      if (this.titletype == '新增') {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            _this11.isDisable = false;
            (0, _video.addList)(_this11.addform).then(function (res) {
              if (res.success) {
                _this11.msgTip("success", "添加成功");
                _this11.dialogFormVisible = false;
                _this11.getList();
              } else {
                _this11.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this11.msgTip("warning", "请填写完整");
            return false;
          }
        });
      } else if (this.titletype == '修改') {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            // 表单验证通过之后的操作
            _this11.isDisable = false;
            (0, _video.changeVideo)(_this11.id, _this11.addform).then(function (res) {
              if (res.success) {
                _this11.msgTip("success", "修改成功");
                _this11.dialogFormVisible = false;
                _this11.getList();
              } else {
                _this11.msgTip("error", res.errorMsg);
              }
            });
          } else {
            _this11.msgTip("warning", "请填写完整");
            return false;
          }
        });
      }
    }
  }
};