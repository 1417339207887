var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dictionaries" },
    [
      _c("div", { staticClass: "dic-head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("h5", [_vm._v("业务码")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.forms.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.forms, "name", $$v)
                  },
                  expression: "forms.name",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("中文编码名称")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.forms.valueMeaning,
                  callback: function ($$v) {
                    _vm.$set(_vm.forms, "valueMeaning", $$v)
                  },
                  expression: "forms.valueMeaning",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("h5", [_vm._v("编码")]),
              _vm._v(" "),
              _c("el-input", {
                model: {
                  value: _vm.forms.value,
                  callback: function ($$v) {
                    _vm.$set(_vm.forms, "value", $$v)
                  },
                  expression: "forms.value",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.search } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.empty } },
                [_vm._v("清空")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit",
                      value: "/dictionary/add",
                      expression: "'/dictionary/add'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.add },
                },
                [_vm._v("添加")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit",
                      value: "/dictionary/addSku",
                      expression: "'/dictionary/addSku'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.addSku },
                },
                [_vm._v("添加SKU")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit",
                      value: "/dictionary/update",
                      expression: "'/dictionary/update'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.amend },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permit",
                      rawName: "v-permit",
                      value: "/dictionary/delete",
                      expression: "'/dictionary/delete'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.delet },
                },
                [_vm._v("删除")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tab" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                "highlight-current-row": "",
              },
              on: { "row-click": _vm.getRow },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", index: _vm.indexMethod },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "name", label: "业务码", width: "200" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "业务码名称", width: "150" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "value",
                  label: "编码",
                  "show-overflow-tooltip": "",
                  width: "150",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "valueCn",
                  label: "中文编码名称",
                  "show-overflow-tooltip": "",
                  width: "230",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "valueEn",
                  label: "英文编码名称",
                  "show-overflow-tooltip": "",
                  width: "220",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "valueJa",
                  label: "日文编码名称",
                  "show-overflow-tooltip": "",
                  width: "220",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "inventoryCode",
                  label: "T+对接编号",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "gstinCode",
                  label: "GSTIN码",
                  "show-overflow-tooltip": "",
                  width: "120",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "priceUsd",
                  label: "美元-Shopify",
                  "show-overflow-tooltip": "",
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "priceRmb",
                  label: "人民币-Shopify",
                  "show-overflow-tooltip": "",
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "priceJpy",
                  label: "日元-Shopify",
                  "show-overflow-tooltip": "",
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "priceUsRatta",
                  label: "美元-US-Ratta",
                  "show-overflow-tooltip": "",
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "priceJpRatta",
                  label: "日元-JP-Ratta",
                  "show-overflow-tooltip": "",
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  prop: "priceCnRatta",
                  label: "人民币-CN-Ratta",
                  "show-overflow-tooltip": "",
                  width: "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opUser", label: "操作人" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { prop: "opTime", label: "操作时间", width: "200" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next",
              "page-size": 10,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.addForm,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "编码",
                    "label-width": _vm.formLabelWidth,
                    prop: "value",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "30" },
                    model: {
                      value: _vm.form.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "value", $$v)
                      },
                      expression: "form.value",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "业务码",
                    "label-width": _vm.formLabelWidth,
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "50" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "业务码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中文编码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueCn",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.valueCn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "valueCn", $$v)
                      },
                      expression: "form.valueCn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "英文编码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueEn",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.valueEn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "valueEn", $$v)
                      },
                      expression: "form.valueEn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "日文编码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueJa",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.valueJa,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "valueJa", $$v)
                      },
                      expression: "form.valueJa",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "T+对接编号",
                    "label-width": _vm.formLabelWidth,
                    prop: "inventoryCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.inventoryCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "inventoryCode", $$v)
                      },
                      expression: "form.inventoryCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "GSTIN码",
                    "label-width": _vm.formLabelWidth,
                    prop: "gstinCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.gstinCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gstinCode", $$v)
                      },
                      expression: "form.gstinCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "美元-Shopify",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceUsd",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceUsd,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceUsd",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceUsd",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "人民币-Shopify",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceRmb",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceRmb,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceRmb",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceRmb",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "日元-Shopify",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceJpy",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceJpy,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceJpy",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceJpy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "美元-US-Ratta",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceUsRatta",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceUsRatta,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceUsRatta",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceUsRatta",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "日元-JP-Ratta",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceJpRatta",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceJpRatta,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceJpRatta",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceJpRatta",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "人民币-CN-Ratta",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceCnRatta",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceCnRatta,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceCnRatta",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceCnRatta",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sure },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加SKU",
            visible: _vm.dialogskuVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogskuVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.addskuForm,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "添加类型",
                    "label-width": _vm.formLabelWidth,
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.selectSkuTypeChanged },
                      model: {
                        value: _vm.addskutype,
                        callback: function ($$v) {
                          _vm.addskutype = $$v
                        },
                        expression: "addskutype",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.typeSnVisible == true
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "产品编号",
                        "label-width": _vm.formLabelWidth,
                      },
                      model: {
                        value: _vm.addskutype,
                        callback: function ($$v) {
                          _vm.addskutype = $$v
                        },
                        expression: "addskutype",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.selectSkuChanged },
                          model: {
                            value: _vm.addskuForm.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.addskuForm, "value", $$v)
                            },
                            expression: "addskuForm.value",
                          },
                        },
                        _vm._l(_vm.types, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.typeFcVisible == true
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "封套编号",
                        "label-width": _vm.formLabelWidth,
                      },
                      model: {
                        value: _vm.addskutype,
                        callback: function ($$v) {
                          _vm.addskutype = $$v
                        },
                        expression: "addskutype",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.selectSkuChanged },
                          model: {
                            value: _vm.addskuForm.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.addskuForm, "value", $$v)
                            },
                            expression: "addskuForm.value",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.value + "_" + item.valueCn,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.typeSpVisible == true
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "笔编号",
                        "label-width": _vm.formLabelWidth,
                      },
                      model: {
                        value: _vm.addskutype,
                        callback: function ($$v) {
                          _vm.addskutype = $$v
                        },
                        expression: "addskutype",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.selectSkuChanged },
                          model: {
                            value: _vm.addskuForm.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.addskuForm, "value", $$v)
                            },
                            expression: "addskuForm.value",
                          },
                        },
                        _vm._l(_vm.jacket, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.value + "_" + item.valueCn,
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.suresku },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.addForm,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "编码",
                    "label-width": _vm.formLabelWidth,
                    prop: "value",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      disabled: "",
                      maxlength: "10",
                    },
                    model: {
                      value: _vm.form.value,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "value", $$v)
                      },
                      expression: "form.value",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "业务码",
                    "label-width": _vm.formLabelWidth,
                    prop: "name",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      autocomplete: "off",
                      disabled: "",
                      maxlength: "50",
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "业务码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中文编码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueCn",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.valueCn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "valueCn", $$v)
                      },
                      expression: "form.valueCn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "英文编码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueEn",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.valueEn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "valueEn", $$v)
                      },
                      expression: "form.valueEn",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "日文编码名称",
                    "label-width": _vm.formLabelWidth,
                    prop: "valueJa",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.valueJa,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "valueJa", $$v)
                      },
                      expression: "form.valueJa",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "T+对接编号",
                    "label-width": _vm.formLabelWidth,
                    prop: "inventoryCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.inventoryCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "inventoryCode", $$v)
                      },
                      expression: "form.inventoryCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "GSTIN码",
                    "label-width": _vm.formLabelWidth,
                    prop: "gstinCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "100" },
                    model: {
                      value: _vm.form.gstinCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "gstinCode", $$v)
                      },
                      expression: "form.gstinCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "美元-Shopify",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceUsd",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceUsd,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceUsd",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceUsd",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "人民币-Shopify",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceRmb",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceRmb,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceRmb",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceRmb",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "日元-Shopify",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceJpy",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceJpy,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceJpy",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceJpy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "美元-US-Ratta",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceUsRatta",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceUsRatta,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceUsRatta",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceUsRatta",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "日元-JP-Ratta",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceJpRatta",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceJpRatta,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceJpRatta",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceJpRatta",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "人民币-CN-Ratta",
                    "label-width": _vm.formLabelWidth,
                    prop: "priceCnRatta",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      onkeyup:
                        "this.value = this.value.replace(/[^\\d.]/g,'');",
                      autocomplete: "off",
                      type: "text",
                    },
                    model: {
                      value: _vm.form.priceCnRatta,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "priceCnRatta",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.priceCnRatta",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.save },
                },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }