"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
//
//
//
//
//

require('echarts/theme/macarons'); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '31%'
    },
    height: {
      type: String,
      default: '500px'
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.chart.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        radar: {
          radius: '66%',
          center: ['50%', '42%'],
          splitNumber: 8,
          splitArea: {
            areaStyle: {
              color: 'rgba(127,95,132,.3)',
              opacity: 1,
              shadowBlur: 45,
              shadowColor: 'rgba(0,0,0,.5)',
              shadowOffsetX: 0,
              shadowOffsetY: 15
            }
          },
          indicator: [{
            name: '',
            max: 10000
          }, {
            name: '',
            max: 20000
          }, {
            name: '',
            max: 20000
          }, {
            name: '',
            max: 20000
          }, {
            name: '',
            max: 20000
          }, {
            name: '',
            max: 20000
          }]
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['Simpleness', 'Smart', 'Technology', 'Dexterous', '111']
        },
        series: [{
          type: 'radar',
          symbolSize: 0,
          areaStyle: {
            normal: {
              shadowBlur: 13,
              shadowColor: 'rgba(0,0,0,.2)',
              shadowOffsetX: 0,
              shadowOffsetY: 10,
              opacity: 1
            }
          },
          data: [{
            value: [5000, 7000, 12000, 11000, 15000, 14000],
            name: 'Simpleness',
            itemStyle: {
              color: '#ffcb91'
            }
          }, {
            value: [4000, 9000, 15000, 15000, 13000, 11000],
            name: 'Smart',
            itemStyle: {
              color: '#57c2fc'
            }
          }, {
            value: [7000, 11000, 12000, 15000, 12000, 12000],
            name: 'Technology',
            itemStyle: {
              color: '#cabaf1'
            }
          }, {
            value: [5500, 11000, 10000, 13000, 11000, 11000],
            name: 'Dexterous',
            itemStyle: {
              color: '#00c7c8'
            }
          }],
          animationDuration: animationDuration
        }]
      });
    }
  }
};