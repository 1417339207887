var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderCustomer" },
    [
      _c(
        "div",
        { staticClass: "setSearch" },
        [
          _c(
            "el-dropdown",
            { attrs: { trigger: "click" }, on: { command: _vm.setSearch } },
            [
              _c("span", { staticClass: "el-dropdown-link" }, [
                _c("img", {
                  attrs: { src: require("../../assets/img/setting.png") },
                }),
              ]),
              _vm._v(" "),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "1" } }, [
                    _vm._v("筛选框"),
                  ]),
                  _vm._v(" "),
                  _c("el-dropdown-item", { attrs: { command: "2" } }, [
                    _vm._v("条目"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _vm.flagList[0].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("渠道")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择" },
                      on: { change: _vm.changeDealer },
                      model: {
                        value: _vm.form.dealerList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "dealerList", $$v)
                        },
                        expression: "form.dealerList",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[1].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("订单号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderNo", $$v)
                      },
                      expression: "form.orderNo",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[2].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("订单序号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.orderSerial,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "orderSerial", $$v)
                      },
                      expression: "form.orderSerial",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[3].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("售后编号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.customerNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "customerNumber", $$v)
                      },
                      expression: "form.customerNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[4].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("售后序号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.customerSerial,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "customerSerial", $$v)
                      },
                      expression: "form.customerSerial",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[21].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("T+销售状态")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.chanjetType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "chanjetType", $$v)
                        },
                        expression: "form.chanjetType",
                      },
                    },
                    _vm._l(_vm.chanjetTypeCode, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[17].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("退回区域仓")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.locationPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "locationPosition", $$v)
                        },
                        expression: "form.locationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositions, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[18].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("换出区域仓")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.exchangeLocationPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "exchangeLocationPosition", $$v)
                        },
                        expression: "form.exchangeLocationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositions, function (item, index) {
                      return _c("el-option", {
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[5].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("设备号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.equipmentNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "equipmentNumber", $$v)
                      },
                      expression: "form.equipmentNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[6].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("物流单号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.logisticsNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "logisticsNumber", $$v)
                      },
                      expression: "form.logisticsNumber",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[7].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("收货人邮箱")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.contactEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contactEmail", $$v)
                      },
                      expression: "form.contactEmail",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[8].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("收货人手机号")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.telephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "telephone", $$v)
                      },
                      expression: "form.telephone",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[9].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("退回类型")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.returnType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "returnType", $$v)
                        },
                        expression: "form.returnType",
                      },
                    },
                    _vm._l(_vm.returnTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[10].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("状态")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { multiple: "", placeholder: "请选择" },
                      on: { change: _vm.changeStatus },
                      model: {
                        value: _vm.form.statusList,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "statusList", $$v)
                        },
                        expression: "form.statusList",
                      },
                    },
                    _vm._l(_vm.status, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[11].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("支付方式")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.payType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "payType", $$v)
                        },
                        expression: "form.payType",
                      },
                    },
                    _vm._l(_vm.payTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[12].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("类型")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    _vm._l(_vm.types, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[13].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("申请人")]),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.form.createUser,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "createUser", $$v)
                      },
                      expression: "form.createUser",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[14].flag
            ? _c(
                "li",
                [
                  _c("h5", [_vm._v("涉及商品")]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.involveProductType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "involveProductType", $$v)
                        },
                        expression: "form.involveProductType",
                      },
                    },
                    _vm._l(_vm.involveProductTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[19].flag
            ? _c("li", [
                _c("h5", [_vm._v("退回起始时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.startReturnTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startReturnTime", $$v)
                        },
                        expression: "form.startReturnTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[20].flag
            ? _c("li", [
                _c("h5", [_vm._v("退回截止时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.endReturnTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endReturnTime", $$v)
                        },
                        expression: "form.endReturnTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[15].flag
            ? _c("li", [
                _c("h5", [_vm._v("修改起始时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.startCreateTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startCreateTime", $$v)
                        },
                        expression: "form.startCreateTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.flagList[16].flag
            ? _c("li", [
                _c("h5", [_vm._v("修改截止时间")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "block" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        type: "date",
                        editable: false,
                      },
                      model: {
                        value: _vm.form.endCreateTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endCreateTime", $$v)
                        },
                        expression: "form.endCreateTime",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "function" }, [
        _c("ol", [
          _c("li", { on: { click: _vm.search } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/find.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("查询")]),
          ]),
          _vm._v(" "),
          _c("li", { on: { click: _vm.empty } }, [
            _c("img", {
              attrs: {
                src: require("../../assets/iconfont/empty.png"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("span", [_vm._v("清空")]),
          ]),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/push/again",
                  expression: "'/order/customer/push/again'",
                },
              ],
              on: { click: _vm.tpush },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/sale.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("售后补推")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/return/money",
                  expression: "'/order/customer/return/money'",
                },
              ],
              on: { click: _vm.backs },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/back.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("部分退款")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/return/product",
                  expression: "'/order/customer/return/product'",
                },
              ],
              on: { click: _vm.salesReturn },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/return.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("退货")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/replace/product",
                  expression: "'/order/customer/replace/product'",
                },
              ],
              on: { click: _vm.barter },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/exchange.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("换货")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/print",
                  expression: "'/order/customer/print'",
                },
              ],
              on: { click: _vm.print },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/print.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("客诉单")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/export",
                  expression: "'/order/customer/export'",
                },
              ],
              on: { click: _vm.derive },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/derive.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("导出")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/export",
                  expression: "'/order/customer/export'",
                },
              ],
              on: { click: _vm.deriveSku },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/sku.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("SKU导出")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/order/customer/export",
                  expression: "'/order/customer/export'",
                },
              ],
              on: { click: _vm.deriveCsv },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/csv.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("SKU导出(CSV)")]),
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "/equipment/warranty",
                  expression: "'/equipment/warranty'",
                },
              ],
              on: { click: _vm.warrantyCard },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/iconfont/card.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", [_vm._v("保修卡")]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page", staticStyle: { "margin-top": "10px" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, prev, pager, next,jumper",
              "page-size": 20,
              total: _vm.total,
              "current-page": _vm.currentPage,
            },
            on: { "current-change": _vm.changepage },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "margin-top": "30px" },
          attrs: { data: _vm.tableData, border: "" },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "55" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { width: "150px", align: "center", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      { staticClass: "function-icon" },
                      [
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/customer/audit",
                                expression: "'/order/customer/audit'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "客服审核",
                              placement: "bottom",
                            },
                          },
                          [
                            scope.row.status == "9" &&
                            (scope.row.type == "2" ||
                              ((scope.row.type == "0" ||
                                scope.row.type == "1") &&
                                scope.row.dutyType !== "" &&
                                scope.row.dutyType !== null &&
                                scope.row.returnType == "1") ||
                              scope.row.returnType !== "1")
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/customer.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.customer(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/customer/check",
                                expression: "'/order/customer/check'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "验货",
                              placement: "bottom",
                            },
                          },
                          [
                            (scope.row.type == "0" || scope.row.type == "1") &&
                            scope.row.status !== "5" &&
                            scope.row.returnType == "1" &&
                            (scope.row.dutyType == "" ||
                              scope.row.dutyType == null)
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/inspection.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goodsclick(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/customer/administration",
                                expression: "'/order/customer/administration'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "行政审核",
                              placement: "bottom",
                            },
                          },
                          [
                            scope.row.administrationReviewResult !== "0" &&
                            scope.row.status == "0" &&
                            (scope.row.type == "0" ||
                              scope.row.type == "1" ||
                              scope.row.type == "3")
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/administrative.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.administrative(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/customer/financeReviewResult",
                                expression:
                                  "'/order/customer/financeReviewResult'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "财务审核",
                              placement: "bottom",
                            },
                          },
                          [
                            (scope.row.administrationReviewResult == "0" ||
                              (scope.row.type == "2" &&
                                scope.row.status == "0")) &&
                            scope.row.financeReviewResult !== "0" &&
                            scope.row.status !== "5"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/credit.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.finance(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/customer/returnRegister",
                                expression: "'/order/customer/returnRegister'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "退回登记",
                              placement: "bottom",
                            },
                          },
                          [
                            (scope.row.type == "0" || scope.row.type == "1") &&
                            scope.row.returnType == "3" &&
                            scope.row.status !== "5"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/returnRe.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.returnRegister(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/customer/change",
                                expression: "'/order/customer/change'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "修改",
                              placement: "bottom",
                            },
                          },
                          [
                            scope.row.status !== "5"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/amend.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.change(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          {
                            directives: [
                              {
                                name: "permit",
                                rawName: "v-permit",
                                value: "/order/customer/undo",
                                expression: "'/order/customer/undo'",
                              },
                            ],
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "撤销",
                              placement: "bottom",
                            },
                          },
                          [
                            scope.row.status == "0" || scope.row.status == "9"
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../assets/iconfont/repeal.png"),
                                    alt: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.undo(scope.row)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _vm.showCusterColumn[0].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "130px",
                  label: "渠道",
                  "show-overflow-tooltip": "",
                  prop: "dealer",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[1].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "支付方式",
                  "show-overflow-tooltip": "",
                  prop: "payType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[2].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "150px",
                  label: "订单号",
                  "show-overflow-tooltip": "",
                  prop: "orderNo",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[3].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "订单序号",
                  "show-overflow-tooltip": "",
                  prop: "orderSerial",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[4].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "170px",
                  label: "售后编号",
                  "show-overflow-tooltip": "",
                  prop: "customerNumber",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[5].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "售后序号",
                  "show-overflow-tooltip": "",
                  prop: "customerSerial",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[27].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "T+销售状态",
                  formatter: _vm.chanjetTypes,
                  "show-overflow-tooltip": "",
                  prop: "chanjetType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[6].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "类型名称",
                  "show-overflow-tooltip": "",
                  prop: "typeName",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[7].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "状态名称",
                  width: "130",
                  "show-overflow-tooltip": "",
                  prop: "statusName",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[8].flag
            ? _c("el-table-column", {
                attrs: { align: "center", label: "币种", prop: "unit" },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[9].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "价格",
                  "show-overflow-tooltip": "",
                  prop: "price",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[10].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "税费",
                  "show-overflow-tooltip": "",
                  prop: "logisticsTaxes",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[19].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "退回类型",
                  "show-overflow-tooltip": "",
                  prop: "returnTypeName",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[26].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "退回时间",
                  "show-overflow-tooltip": "",
                  prop: "returnTime",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[24].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "130px",
                  label: "退回区域仓",
                  "show-overflow-tooltip": "",
                  prop: "locationPositionName",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[25].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "130px",
                  label: "换出区域仓",
                  "show-overflow-tooltip": "",
                  prop: "exchangeLocationPosition",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[11].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "申请人",
                  "show-overflow-tooltip": "",
                  prop: "createUser",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[12].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "申请时间",
                  "show-overflow-tooltip": "",
                  prop: "createTime",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[13].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "行政审核",
                  "show-overflow-tooltip": "",
                  prop: "administrationReviewResult",
                  formatter: _vm.isAamin,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[14].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "财务审核",
                  "show-overflow-tooltip": "",
                  prop: "financeReviewResult",
                  formatter: _vm.isFinance,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[15].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "责任类型",
                  "show-overflow-tooltip": "",
                  prop: "dutyType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[16].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "费用类型",
                  "show-overflow-tooltip": "",
                  prop: "costType",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[17].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "发货类型",
                  "show-overflow-tooltip": "",
                  prop: "deliverType",
                  formatter: _vm.deliversType,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[18].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "涉及商品",
                  "show-overflow-tooltip": "",
                  prop: "involveProductType",
                  formatter: _vm.involvesProductType,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[20].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "退货设备号",
                  width: "130",
                  "show-overflow-tooltip": "",
                  prop: "equipmentNumber",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[21].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "换货设备号",
                  width: "130",
                  "show-overflow-tooltip": "",
                  prop: "exchangeEquipmentNumber",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[22].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "备注",
                  "show-overflow-tooltip": "",
                  prop: "remark",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showCusterColumn[23].flag
            ? _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: "修改时间",
                  "show-overflow-tooltip": "",
                  prop: "updateTime",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退货",
            visible: _vm.dialogFormVisible,
            "close-on-click-modal": false,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
            close: _vm.returnClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "returnform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.returnform,
                rules: _vm.returnrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "订单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderNo",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled: _vm.orderFlag,
                          placeholder: "请输入订单号",
                        },
                        on: { blur: _vm.changeOrder },
                        model: {
                          value: _vm.returnform.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnform, "orderNo", $$v)
                          },
                          expression: "returnform.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.seariNumFlag
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "售后编号",
                            "label-width": _vm.formLabelWidth,
                            prop: "returnsearinumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.orderFlag },
                            model: {
                              value: _vm.returnsearinumber,
                              callback: function ($$v) {
                                _vm.returnsearinumber = $$v
                              },
                              expression: "returnsearinumber",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退款金额",
                        "label-width": _vm.formLabelWidth,
                        prop: "price",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          min: 1,
                          oninput:
                            "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.returnform.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.returnform, "price", $$v)
                          },
                          expression: "returnform.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退回",
                        "label-width": _vm.formLabelWidth,
                        prop: "returnType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changereturn },
                          model: {
                            value: _vm.returnform.returnType,
                            callback: function ($$v) {
                              _vm.$set(_vm.returnform, "returnType", $$v)
                            },
                            expression: "returnform.returnType",
                          },
                        },
                        _vm._l(_vm.returntypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.returnflags
                ? _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _vm.locationflag
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "33.3%" },
                              attrs: {
                                label: "退回区域仓",
                                "label-width": _vm.formLabelWidth,
                                prop: "locationPosition",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "94%" },
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.returnform.locationPosition,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.returnform,
                                        "locationPosition",
                                        $$v
                                      )
                                    },
                                    expression: "returnform.locationPosition",
                                  },
                                },
                                _vm._l(_vm.locationPositions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备号",
                    "label-width": _vm.formLabelWidth,
                    prop: "returnstartNumber",
                  },
                },
                [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "30px",
                          "padding-left": "10px",
                          cursor: "pointer",
                          color: "#409EFF",
                        },
                        on: { click: _vm.addReturnNumber },
                      },
                      [_vm._v("+")]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentReturnNumberList",
                  },
                },
                _vm._l(_vm.equipmentReturnNumberList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { minlength: "7", autocomplete: "off" },
                        on: {
                          blur: function ($event) {
                            return _vm.equimentNumber($event)
                          },
                        },
                        model: {
                          value: item.value,
                          callback: function ($$v) {
                            _vm.$set(item, "value", $$v)
                          },
                          expression: "item.value",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "30px",
                            "padding-left": "10px",
                            cursor: "pointer",
                            "margin-top": "10px",
                          },
                          on: {
                            click: function () {
                              return _vm.removeReturnNumber(index)
                            },
                          },
                        },
                        [_vm._v("-")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "异常描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "1000",
                      type: "textarea",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.returnform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.returnform, "remark", $$v)
                      },
                      expression: "returnform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.returnflags
                ? _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.loading,
                              expression: "loading",
                            },
                          ],
                          ref: "multipleTables",
                          staticStyle: { width: "100%", "margin-top": "30px" },
                          attrs: {
                            data: _vm.tableDatas,
                            border: "",
                            "default-expand-all": true,
                          },
                          on: {
                            "selection-change": _vm.handleSelectionChanges,
                            select: _vm.tableSelect,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: { type: "expand" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.loadings,
                                              expression: "loadings",
                                            },
                                          ],
                                          ref:
                                            "multipleTable_" +
                                            props.row.productNo,
                                          staticStyle: {
                                            width: "100%",
                                            "margin-top": "5px",
                                          },
                                          attrs: {
                                            data: props.row
                                              .productContainsVOList,
                                            border: "",
                                          },
                                          on: {
                                            "selection-change":
                                              _vm.handleSelectionChange1,
                                            select: _vm.tableSelects,
                                            "select-all": function ($event) {
                                              return _vm.tableAll(props.row)
                                            },
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              width: "55",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "产品",
                                              prop: "productNameCn",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "产品编号",
                                              prop: "productNo",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "数量",
                                              "show-overflow-tooltip": "",
                                              prop: "count",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "平台价",
                                              "show-overflow-tooltip": "",
                                              prop: "price",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              align: "center",
                                              label: "结算价",
                                              "show-overflow-tooltip": "",
                                              prop: "realPrice",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.positionFlag
                                            ? _c("el-table-column", {
                                                attrs: {
                                                  align: "center",
                                                  label: "仓位",
                                                  prop: "position",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1937573535
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "订单号",
                              "show-overflow-tooltip": "",
                              prop: "orderNo",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "产品编号",
                              "show-overflow-tooltip": "",
                              prop: "productNo",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "数量",
                              "show-overflow-tooltip": "",
                              prop: "count",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            on: {
                                              input: function ($event) {
                                                return _vm.changeCount(
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.count,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "count",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.count",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: { type: "text" },
                                            domProps: {
                                              textContent: _vm._s(
                                                scope.row.count
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              377759106
                            ),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "币种",
                              "show-overflow-tooltip": "",
                              prop: "unit",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              align: "center",
                              label: "金额",
                              "show-overflow-tooltip": "",
                              prop: "price",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm.printFlag
                                        ? _c("el-input", {
                                            attrs: {
                                              disabled: true,
                                              type: "text",
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.changeCount1(
                                                  scope.row
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.price,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "price",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.price",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      !_vm.printFlag
                                        ? _c("el-input", {
                                            attrs: {
                                              disabled: true,
                                              type: "text",
                                            },
                                            domProps: {
                                              textContent: _vm._s(
                                                scope.row.price
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              795442739
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "15px",
                            "margin-bottom": "15px",
                            color: "#115c3b",
                            "font-size": "18px",
                          },
                        },
                        [_vm._v("订单实际商品：")]
                      ),
                      _vm._v(" "),
                      _c(
                        "table",
                        {
                          staticStyle: {
                            border: "1px solid #e8f2ee",
                            "margin-top": "2px",
                            width: "100%",
                          },
                          attrs: { border: "1", cellspacing: "0" },
                        },
                        [
                          _c("tr", { staticStyle: { height: "30px" } }, [
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "font-weight": "200",
                                  "font-size": "13px",
                                },
                                attrs: { width: "50" },
                              },
                              [_vm._v("产品名称")]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "font-weight": "200",
                                  "font-size": "13px",
                                },
                                attrs: { width: "50" },
                              },
                              [_vm._v("产品型号")]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticStyle: {
                                  "font-weight": "200",
                                  "font-size": "13px",
                                },
                                attrs: { width: "50" },
                              },
                              [_vm._v("数量")]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.realProductList, function (real) {
                            return _c(
                              "tr",
                              { staticStyle: { height: "30px" } },
                              [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(real.productName))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(real.productNo))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                  },
                                  [_vm._v(_vm._s(real.count))]
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.returnClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sure },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "退回登记",
            visible: _vm.dialogFormVisibleRegister,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibleRegister = $event
            },
            close: _vm.registerClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "registerform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.registerform,
                rules: _vm.registerrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "订单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderNo",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "请输入订单号" },
                        on: { blur: _vm.changeOrder },
                        model: {
                          value: _vm.registerform.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.registerform, "orderNo", $$v)
                          },
                          expression: "registerform.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "售后编号",
                        "label-width": _vm.formLabelWidth,
                        prop: "returnsearinumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.returnsearinumber,
                          callback: function ($$v) {
                            _vm.returnsearinumber = $$v
                          },
                          expression: "returnsearinumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退回",
                        "label-width": _vm.formLabelWidth,
                        prop: "returnType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changereturn },
                          model: {
                            value: _vm.registerform.returnType,
                            callback: function ($$v) {
                              _vm.$set(_vm.registerform, "returnType", $$v)
                            },
                            expression: "registerform.returnType",
                          },
                        },
                        _vm._l(_vm.returntypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.returnflags
                ? _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _vm.locationflag
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退回区域仓",
                                "label-width": _vm.formLabelWidth,
                                prop: "locationPosition",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.registerform.locationPosition,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.registerform,
                                        "locationPosition",
                                        $$v
                                      )
                                    },
                                    expression: "registerform.locationPosition",
                                  },
                                },
                                _vm._l(_vm.locationPositions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.valueCn,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货物流公司",
                            "label-width": _vm.formLabelWidth,
                            prop: "logisticsName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.registerform.logisticsName,
                              callback: function ($$v) {
                                _vm.$set(_vm.registerform, "logisticsName", $$v)
                              },
                              expression: "registerform.logisticsName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退货物流单号",
                            "label-width": _vm.formLabelWidth,
                            prop: "logisticsNumber",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.registerform.logisticsNumber,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.registerform,
                                  "logisticsNumber",
                                  $$v
                                )
                              },
                              expression: "registerform.logisticsNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.returnflags
                ? _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "币种",
                            "label-width": _vm.formLabelWidth,
                            prop: "logisticsUnit",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.registerform.logisticsUnit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.registerform,
                                    "logisticsUnit",
                                    $$v
                                  )
                                },
                                expression: "registerform.logisticsUnit",
                              },
                            },
                            _vm._l(_vm.returnLogisticsUnits, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退回税费",
                            "label-width": _vm.formLabelWidth,
                            prop: "logisticsTaxes",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.registerform.logisticsTaxes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.registerform,
                                  "logisticsTaxes",
                                  $$v
                                )
                              },
                              expression: "registerform.logisticsTaxes",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "退回运费",
                            prop: "logisticsPrice",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off" },
                            model: {
                              value: _vm.registerform.logisticsPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.registerform,
                                  "logisticsPrice",
                                  $$v
                                )
                              },
                              expression: "registerform.logisticsPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "异常描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "1000",
                      type: "textarea",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.registerform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.registerform, "remark", $$v)
                      },
                      expression: "registerform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.registerClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.sureRegister },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "部分退款",
            visible: _vm.dialogMoneyVisible,
            "close-on-click-modal": false,
            width: "55%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogMoneyVisible = $event
            },
            close: _vm.moneyClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "moneyform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.moneyform,
                rules: _vm.moneyrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "订单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderNo",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入订单号" },
                        model: {
                          value: _vm.moneyform.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.moneyform, "orderNo", $$v)
                          },
                          expression: "moneyform.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "金额",
                        "label-width": _vm.formLabelWidth,
                        prop: "price",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          min: 1,
                          oninput:
                            "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.moneyform.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.moneyform, "price", $$v)
                          },
                          expression: "moneyform.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "支付方式",
                        "label-width": _vm.formLabelWidth,
                        prop: "payType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.moneyform.payType,
                            callback: function ($$v) {
                              _vm.$set(_vm.moneyform, "payType", $$v)
                            },
                            expression: "moneyform.payType",
                          },
                        },
                        _vm._l(_vm.payTypes, function (item) {
                          return _c("el-option", {
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "异常描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "1000",
                      type: "textarea",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.moneyform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.moneyform, "remark", $$v)
                      },
                      expression: "moneyform.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.moneyClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.moneysure },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "换货",
            visible: _vm.dialogFormVisibles,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisibles = $event
            },
            close: _vm.salesClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "salesform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.salesform,
                "status-icon": true,
                "show-message": false,
                rules: _vm.salsesRule,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "订单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderNo",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        on: { blur: _vm.searchDeatil },
                        model: {
                          value: _vm.salesform.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.salesform, "orderNo", $$v)
                          },
                          expression: "salesform.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "退回",
                        "label-width": _vm.formLabelWidth,
                        prop: "returnType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "94%" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changereturn },
                          model: {
                            value: _vm.salesform.returnType,
                            callback: function ($$v) {
                              _vm.$set(_vm.salesform, "returnType", $$v)
                            },
                            expression: "salesform.returnType",
                          },
                        },
                        _vm._l(_vm.returntypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "客户要求",
                        "label-width": _vm.formLabelWidth,
                        prop: "customerRequest",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "94%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.salesform.customerRequest,
                            callback: function ($$v) {
                              _vm.$set(_vm.salesform, "customerRequest", $$v)
                            },
                            expression: "salesform.customerRequest",
                          },
                        },
                        _vm._l(_vm.customerRequests, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "金额",
                        "label-width": _vm.formLabelWidth,
                        prop: "price",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.salesform.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.salesform, "price", $$v)
                          },
                          expression: "salesform.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "费用类型",
                        "label-width": _vm.formLabelWidth,
                        prop: "costType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "94%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.salesform.costType,
                            callback: function ($$v) {
                              _vm.$set(_vm.salesform, "costType", $$v)
                            },
                            expression: "salesform.costType",
                          },
                        },
                        _vm._l(_vm.costTypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.nameFlag
                ? _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "33.3%" },
                          attrs: {
                            label: "收货人",
                            "label-width": _vm.formLabelWidth,
                            prop: "name",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "50", autocomplete: "off" },
                            model: {
                              value: _vm.salesform.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.salesform, "name", $$v)
                              },
                              expression: "salesform.name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "33.3%" },
                          attrs: {
                            label: "收货人手机号",
                            "label-width": _vm.formLabelWidth,
                            prop: "telephone",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "30", autocomplete: "off" },
                            model: {
                              value: _vm.salesform.telephone,
                              callback: function ($$v) {
                                _vm.$set(_vm.salesform, "telephone", $$v)
                              },
                              expression: "salesform.telephone",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "33.3%" },
                          attrs: {
                            label: "收货人邮箱",
                            "label-width": _vm.formLabelWidth,
                            prop: "contactEmail",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { maxlength: "50", autocomplete: "off" },
                            model: {
                              value: _vm.salesform.contactEmail,
                              callback: function ($$v) {
                                _vm.$set(_vm.salesform, "contactEmail", $$v)
                              },
                              expression: "salesform.contactEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.nameFlag
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "国家",
                            "label-width": _vm.formLabelWidth,
                            prop: "country",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              ref: "country",
                              staticClass: "el-select-border-no",
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "" },
                              on: { change: _vm.countrySelect },
                              model: {
                                value: _vm.salesform.country,
                                callback: function ($$v) {
                                  _vm.$set(_vm.salesform, "country", $$v)
                                },
                                expression: "salesform.country",
                              },
                            },
                            _vm._l(_vm.countrys, function (item, index) {
                              return _c("el-option", {
                                attrs: {
                                  label: item.country,
                                  value: {
                                    value: item.id,
                                    label: item.country,
                                    code: item.countryCode,
                                  },
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isAmericaShow
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: "省",
                                "label-width": _vm.formLabelWidth,
                                prop: "provinceName",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  ref: "provinceName",
                                  staticClass: "el-select-border-no",
                                  staticStyle: { width: "100%" },
                                  attrs: { filterable: "" },
                                  on: { change: _vm.provinceSelect },
                                  model: {
                                    value: _vm.salesform.provinceName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.salesform,
                                        "provinceName",
                                        $$v
                                      )
                                    },
                                    expression: "salesform.provinceName",
                                  },
                                },
                                _vm._l(
                                  _vm.provinceNames,
                                  function (item, index) {
                                    return _c("el-option", {
                                      attrs: {
                                        label: item.provinceName,
                                        value: {
                                          value: item.provinceName,
                                          code: item.province,
                                        },
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isProvinceShow
                        ? _c(
                            "el-form-item",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                label: "省",
                                "label-width": _vm.formLabelWidth,
                                prop: "provinceName",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { autocomplete: "off", type: "text" },
                                model: {
                                  value: _vm.salesform.provinceName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.salesform,
                                      "provinceName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "salesform.provinceName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            label: "市",
                            "label-width": _vm.formLabelWidth,
                            prop: "city",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { autocomplete: "off", type: "text" },
                            model: {
                              value: _vm.salesform.city,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.salesform,
                                  "city",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "salesform.city",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.nameFlag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "邮编",
                        "label-width": _vm.formLabelWidth,
                        prop: "cityPostalCode",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          autocomplete: "off",
                          type: "text",
                        },
                        model: {
                          value: _vm.salesform.cityPostalCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.salesform,
                              "cityPostalCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "salesform.cityPostalCode",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.nameFlag
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "地址",
                        "label-width": _vm.formLabelWidth,
                        prop: "address",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "255", autocomplete: "off" },
                        model: {
                          value: _vm.salesform.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.salesform, "address", $$v)
                          },
                          expression: "salesform.address",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "原单设备号",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentNumber",
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.salesform.equipmentNumber) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "异常描述",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "1000",
                      type: "textarea",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.salesform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.salesform, "remark", $$v)
                      },
                      expression: "salesform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.returnflags
                ? _c(
                    "div",
                    [
                      _c("h2", { staticStyle: { "padding-left": "50px" } }, [
                        _vm._v("退回摘要"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "类型",
                            "label-width": _vm.formLabelWidth,
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "1" },
                              on: { change: _vm.isNo },
                              model: {
                                value: _vm.product,
                                callback: function ($$v) {
                                  _vm.product = $$v
                                },
                                expression: "product",
                              },
                            },
                            [_vm._v("设备")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "2" },
                              on: { change: _vm.isNo },
                              model: {
                                value: _vm.product,
                                callback: function ($$v) {
                                  _vm.product = $$v
                                },
                                expression: "product",
                              },
                            },
                            [_vm._v("封套")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "3" },
                              on: { change: _vm.isNo },
                              model: {
                                value: _vm.product,
                                callback: function ($$v) {
                                  _vm.product = $$v
                                },
                                expression: "product",
                              },
                            },
                            [_vm._v("笔")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: "4" },
                              on: { change: _vm.isNo },
                              model: {
                                value: _vm.product,
                                callback: function ($$v) {
                                  _vm.product = $$v
                                },
                                expression: "product",
                              },
                            },
                            [_vm._v("笔芯")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退货产品",
                                "label-width": _vm.formLabelWidth,
                                prop: "product",
                              },
                            },
                            [
                              _vm.typeflag
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "请选择",
                                        filterable: "",
                                      },
                                      on: { change: _vm.changeProduct },
                                      model: {
                                        value: _vm.productNos,
                                        callback: function ($$v) {
                                          _vm.productNos = $$v
                                        },
                                        expression: "productNos",
                                      },
                                    },
                                    _vm._l(_vm.type, function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.valueCn,
                                          value: {
                                            value: item.value,
                                            label: item.valueCn,
                                          },
                                        },
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.coverflag
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.changeProduct },
                                      model: {
                                        value: _vm.productNos,
                                        callback: function ($$v) {
                                          _vm.productNos = $$v
                                        },
                                        expression: "productNos",
                                      },
                                    },
                                    _vm._l(_vm.jacket, function (item, index) {
                                      return !item.value.includes("SP") &&
                                        !item.value.includes("SR")
                                        ? _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label:
                                                item.value + "_" + item.valueCn,
                                              value: {
                                                value: item.value,
                                                label: item.valueCn,
                                              },
                                            },
                                          })
                                        : _vm._e()
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.penflag
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.changeProduct },
                                      model: {
                                        value: _vm.productNos,
                                        callback: function ($$v) {
                                          _vm.productNos = $$v
                                        },
                                        expression: "productNos",
                                      },
                                    },
                                    _vm._l(_vm.jacket, function (item, index) {
                                      return item.value.includes("SP")
                                        ? _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label:
                                                item.value + "_" + item.valueCn,
                                              value: {
                                                value: item.value,
                                                label: item.valueCn,
                                              },
                                            },
                                          })
                                        : _vm._e()
                                    }),
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.refillflag
                                ? _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                      },
                                      on: { change: _vm.changeProduct },
                                      model: {
                                        value: _vm.productNos,
                                        callback: function ($$v) {
                                          _vm.productNos = $$v
                                        },
                                        expression: "productNos",
                                      },
                                    },
                                    _vm._l(_vm.jacket, function (item, index) {
                                      return item.value.includes("SR")
                                        ? _c("el-option", {
                                            key: index,
                                            attrs: {
                                              label:
                                                item.value + "_" + item.valueCn,
                                              value: {
                                                value: item.value,
                                                label: item.valueCn,
                                              },
                                            },
                                          })
                                        : _vm._e()
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "数量",
                                "label-width": _vm.formLabelWidth,
                                prop: "count",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "10",
                                  autocomplete: "off",
                                  type: "text",
                                },
                                model: {
                                  value: _vm.salesform.count,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.salesform,
                                      "count",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "salesform.count",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                "label-width": _vm.formLabelWidth,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.salesAdd },
                                },
                                [_vm._v("添加")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "33.3%" },
                          attrs: {
                            label: "退回区域仓",
                            "label-width": _vm.formLabelWidth,
                            prop: "locationPosition",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "94%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.salesform.locationPosition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.salesform,
                                    "locationPosition",
                                    $$v
                                  )
                                },
                                expression: "salesform.locationPosition",
                              },
                            },
                            _vm._l(_vm.locationPositions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.nameFlag
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "退回设备号",
                                "label-width": _vm.formLabelWidth,
                                prop: "startNumber",
                              },
                            },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "30px",
                                      "padding-left": "10px",
                                      cursor: "pointer",
                                      color: "#409EFF",
                                    },
                                    on: { click: _vm.addNumbers },
                                  },
                                  [_vm._v("+")]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.nameFlag
                        ? _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "",
                                "label-width": _vm.formLabelWidth,
                                prop: "equipmentNumberLists",
                              },
                            },
                            _vm._l(
                              _vm.equipmentNumberLists,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticStyle: { display: "flex" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { "margin-top": "10px" },
                                      attrs: {
                                        minlength: "7",
                                        autocomplete: "off",
                                      },
                                      on: {
                                        blur: function ($event) {
                                          return _vm.equimentNumber($event)
                                        },
                                      },
                                      model: {
                                        value: item.value,
                                        callback: function ($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "30px",
                                          "padding-left": "10px",
                                          cursor: "pointer",
                                          "margin-top": "10px",
                                        },
                                        on: {
                                          click: function () {
                                            return _vm.removeNumbers(index)
                                          },
                                        },
                                      },
                                      [_vm._v("-")]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showflag
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "20px" } },
                            _vm._l(
                              this.salesform.orderLineCustomerList,
                              function (result, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "table",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "border-spacing": "0px 10px",
                                      },
                                      attrs: { border: "0", cellspacing: "1" },
                                    },
                                    [
                                      _c(
                                        "tr",
                                        {
                                          staticStyle: {
                                            color: "#000",
                                            "font-weight": "500",
                                            display: "flex",
                                            "justify-content": "space-between",
                                            "align-items": "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "td",
                                            { staticStyle: { width: "150px" } },
                                            [
                                              _vm._v(
                                                "产品编号：" +
                                                  _vm._s(result.productNo)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "200px" } },
                                            [
                                              _vm._v(
                                                "产品名称：" +
                                                  _vm._s(result.productName)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            { staticStyle: { width: "100px" } },
                                            [
                                              _vm._v(
                                                "数量：" + _vm._s(result.count)
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { type: "primary" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.salesRemove(
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("h2", { staticStyle: { "padding-left": "50px" } }, [
                _vm._v("换出摘要"),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类型", "label-width": _vm.formLabelWidth } },
                [
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "1" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("设备")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "2" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("封套")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "3" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("笔")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-radio",
                    {
                      attrs: { label: "4" },
                      on: { change: _vm.exchangeNo },
                      model: {
                        value: _vm.exchangeproduct,
                        callback: function ($$v) {
                          _vm.exchangeproduct = $$v
                        },
                        expression: "exchangeproduct",
                      },
                    },
                    [_vm._v("笔芯")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "换货产品",
                        "label-width": _vm.formLabelWidth,
                        prop: "product",
                      },
                    },
                    [
                      _vm.typeflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请选择", filterable: "" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.type, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: {
                                  label: item.valueCn,
                                  value: {
                                    value: item.value,
                                    label: item.valueCn,
                                  },
                                },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.coverflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return !item.value.includes("SP") &&
                                !item.value.includes("SR")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: {
                                        value: item.value,
                                        label: item.valueCn,
                                      },
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.penflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return item.value.includes("SP")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: {
                                        value: item.value,
                                        label: item.valueCn,
                                      },
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.refillflags
                        ? _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { filterable: "", placeholder: "请选择" },
                              on: { change: _vm.changeProducts },
                              model: {
                                value: _vm.salesproductNo,
                                callback: function ($$v) {
                                  _vm.salesproductNo = $$v
                                },
                                expression: "salesproductNo",
                              },
                            },
                            _vm._l(_vm.jacket, function (item, index) {
                              return item.value.includes("SR")
                                ? _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.value + "_" + item.valueCn,
                                      value: {
                                        value: item.value,
                                        label: item.valueCn,
                                      },
                                    },
                                  })
                                : _vm._e()
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "数量",
                        "label-width": _vm.formLabelWidth,
                        prop: "count",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          autocomplete: "off",
                          type: "text",
                        },
                        model: {
                          value: _vm.counts,
                          callback: function ($$v) {
                            _vm.counts =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "counts",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "仓位",
                        "label-width": _vm.formLabelWidth,
                        prop: "position",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.changePosition },
                          model: {
                            value: _vm.salesform.position,
                            callback: function ($$v) {
                              _vm.$set(_vm.salesform, "position", $$v)
                            },
                            expression: "salesform.position",
                          },
                        },
                        _vm._l(_vm.positions, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.valueCn,
                              value: { value: item.value, label: item.valueCn },
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "", "label-width": _vm.formLabelWidth } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.exchangeAdd },
                        },
                        [_vm._v("添加")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "33.3%" },
                  attrs: {
                    label: "换出区域仓",
                    "label-width": _vm.formLabelWidth,
                    prop: "exchangeLocationPosition",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "94%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.salesform.exchangeLocationPosition,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.salesform,
                            "exchangeLocationPosition",
                            $$v
                          )
                        },
                        expression: "salesform.exchangeLocationPosition",
                      },
                    },
                    _vm._l(_vm.locationPositions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.showflag
                ? _c(
                    "div",
                    { staticStyle: { "margin-top": "20px" } },
                    _vm._l(
                      this.salesform.exchangeCustomerList,
                      function (result, index) {
                        return _c("div", { key: index }, [
                          _c(
                            "table",
                            {
                              staticStyle: {
                                width: "100%",
                                "border-spacing": "0px 10px",
                              },
                              attrs: { border: "0", cellspacing: "1" },
                            },
                            [
                              _c(
                                "tr",
                                {
                                  staticStyle: {
                                    color: "#000",
                                    "font-weight": "500",
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c(
                                    "td",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _vm._v(
                                        "产品编号：" + _vm._s(result.productNo)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticStyle: { width: "200px" } },
                                    [
                                      _vm._v(
                                        "产品名称：" +
                                          _vm._s(result.productName)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticStyle: { width: "100px" } },
                                    [
                                      _vm._v(
                                        "仓位：" + _vm._s(result.positionCn)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticStyle: { width: "100px" } },
                                    [_vm._v("数量：" + _vm._s(result.count))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.exchangeRemove(index)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      }
                    ),
                    0
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.salesClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keep },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "物流",
            visible: _vm.dialogForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogForm = $event
            },
            close: _vm.logisticsClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "logisticsform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.logisticsform,
                rules: _vm.logisticsrule,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售后单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "customerNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.logisticsform.customerNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.logisticsform, "customerNumber", $$v)
                      },
                      expression: "logisticsform.customerNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流公司",
                    "label-width": _vm.formLabelWidth,
                    prop: "logisticsName",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.logisticsform.logisticsName,
                        callback: function ($$v) {
                          _vm.$set(_vm.logisticsform, "logisticsName", $$v)
                        },
                        expression: "logisticsform.logisticsName",
                      },
                    },
                    _vm._l(_vm.companylogistics, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.valueCn },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "物流单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "logisticsNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "60", autocomplete: "off" },
                    model: {
                      value: _vm.logisticsform.logisticsNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.logisticsform, "logisticsNumber", $$v)
                      },
                      expression: "logisticsform.logisticsNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "设备号",
                    "label-width": _vm.formLabelWidth,
                    prop: "startNumber",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        attrs: { minlength: "7", autocomplete: "off" },
                        on: {
                          blur: function ($event) {
                            return _vm.equimentNumber($event)
                          },
                        },
                        model: {
                          value: _vm.startNumber1,
                          callback: function ($$v) {
                            _vm.startNumber1 = $$v
                          },
                          expression: "startNumber1",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "30px",
                            "padding-left": "10px",
                            cursor: "pointer",
                          },
                          on: { click: _vm.addLogisticsNumber },
                        },
                        [_vm._v("+")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "",
                    "label-width": _vm.formLabelWidth,
                    prop: "equipmentNumberList1",
                  },
                },
                _vm._l(_vm.equipmentNumberList1, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { display: "flex" } },
                    [
                      _c("el-input", {
                        staticStyle: { "margin-top": "10px" },
                        attrs: { minlength: "7", autocomplete: "off" },
                        on: {
                          blur: function ($event) {
                            return _vm.equimentNumber($event)
                          },
                        },
                        model: {
                          value: item.text,
                          callback: function ($$v) {
                            _vm.$set(item, "text", $$v)
                          },
                          expression: "item.text",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "30px",
                            "padding-left": "10px",
                            cursor: "pointer",
                            "margin-top": "10px",
                          },
                          on: {
                            click: function () {
                              return _vm.removeLogisticsNumber(index)
                            },
                          },
                        },
                        [_vm._v("-")]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.logisticsClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.keeps },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "验货",
            visible: _vm.dialoggoodsForm,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialoggoodsForm = $event
            },
            close: _vm.goodsClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "goodsform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.goodsform,
                rules: _vm.goodsrules,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售后单号",
                    "label-width": _vm.formLabelWidth,
                    prop: "customerNumber",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.goodsform.customerNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsform, "customerNumber", $$v)
                      },
                      expression: "goodsform.customerNumber",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "责任类型",
                    "label-width": _vm.formLabelWidth,
                    prop: "dutyType",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.goodsform.dutyType,
                        callback: function ($$v) {
                          _vm.$set(_vm.goodsform, "dutyType", $$v)
                        },
                        expression: "goodsform.dutyType",
                      },
                    },
                    _vm._l(_vm.dutyTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.goodsform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.goodsform, "remark", $$v)
                      },
                      expression: "goodsform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTables",
                  staticStyle: { width: "100%", "margin-top": "30px" },
                  attrs: {
                    data: _vm.tablegoodsData,
                    border: "",
                    "default-expand-all": true,
                  },
                  on: {
                    "selection-change": _vm.handleSelectionChanges,
                    select: _vm.tableSelect,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "el-table",
                              {
                                directives: [
                                  {
                                    name: "loading",
                                    rawName: "v-loading",
                                    value: _vm.loadings,
                                    expression: "loadings",
                                  },
                                ],
                                ref: "multipleTable_" + props.row.productNo,
                                staticStyle: {
                                  width: "100%",
                                  "margin-top": "5px",
                                },
                                attrs: {
                                  data: props.row.customerLineContainsList,
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "产品",
                                    prop: "productName",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "产品编号",
                                    prop: "productNo",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "数量",
                                    "show-overflow-tooltip": "",
                                    prop: "count",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "仓位",
                                    "show-overflow-tooltip": "",
                                    prop: "position",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder: "请选择",
                                                },
                                                model: {
                                                  value: scope.row.position,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "position",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.position",
                                                },
                                              },
                                              _vm._l(
                                                _vm.positionList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.valueCn,
                                                      value: item.value,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "订单号",
                      "show-overflow-tooltip": "",
                      prop: "orderNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品",
                      "show-overflow-tooltip": "",
                      prop: "productName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "产品编号",
                      "show-overflow-tooltip": "",
                      prop: "productNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "数量",
                      "show-overflow-tooltip": "",
                      prop: "count",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.goodsClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.goodskeep },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改",
            visible: _vm.dialogChangeForm,
            "close-on-click-modal": false,
            width: "65%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogChangeForm = $event
            },
            close: _vm.changeClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "changeform",
              staticClass: "changeform",
              attrs: {
                model: _vm.changeform,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "售后编号",
                        "label-width": _vm.formLabelWidth,
                        prop: "customerNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.customerNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "customerNumber", $$v)
                          },
                          expression: "changeform.customerNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "售后金额",
                        "label-width": _vm.formLabelWidth,
                        prop: "price",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          disabled: _vm.priceFlag,
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.changeform.price,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "price", $$v)
                          },
                          expression: "changeform.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "运费",
                        "label-width": _vm.formLabelWidth,
                        prop: "freight",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          disabled: _vm.priceFlag,
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.changeform.freight,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "freight", $$v)
                          },
                          expression: "changeform.freight",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "其它金额",
                        "label-width": _vm.formLabelWidth,
                        prop: "otherPrice",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "10",
                          disabled: _vm.priceFlag,
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.changeform.otherPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "otherPrice", $$v)
                          },
                          expression: "changeform.otherPrice",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "费用类型",
                        "label-width": _vm.formLabelWidth,
                        prop: "costType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.changeform.costType,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeform, "costType", $$v)
                            },
                            expression: "changeform.costType",
                          },
                        },
                        _vm._l(_vm.costTypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "退回类型",
                        "label-width": _vm.formLabelWidth,
                        prop: "returnType",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            disabled: _vm.typeFlag,
                            disabled: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.changeform.returnType,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeform, "returnType", $$v)
                            },
                            expression: "changeform.returnType",
                          },
                        },
                        _vm._l(_vm.returnTypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.returnflag
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "33.3%" },
                          attrs: {
                            label: "退回区域仓",
                            "label-width": _vm.formLabelWidth,
                            prop: "locationPosition",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "94%" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.changeform.locationPosition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.changeform,
                                    "locationPosition",
                                    $$v
                                  )
                                },
                                expression: "changeform.locationPosition",
                              },
                            },
                            _vm._l(_vm.locationPositions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.exchangeLocationflag
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { width: "33.3%" },
                          attrs: {
                            label: "换出区域仓",
                            "label-width": _vm.formLabelWidth,
                            prop: "exchangeLocationPosition",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "94%" },
                              attrs: { disabled: "", placeholder: "请选择" },
                              model: {
                                value: _vm.changeform.exchangeLocationPosition,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.changeform,
                                    "exchangeLocationPosition",
                                    $$v
                                  )
                                },
                                expression:
                                  "changeform.exchangeLocationPosition",
                              },
                            },
                            _vm._l(_vm.locationPositions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  label: item.valueCn,
                                  value: item.value,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "客户要求",
                        "label-width": _vm.formLabelWidth,
                        prop: "customerRequest",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.changeform.customerRequest,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeform, "customerRequest", $$v)
                            },
                            expression: "changeform.customerRequest",
                          },
                        },
                        _vm._l(_vm.customerRequests, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "税费",
                        "label-width": _vm.formLabelWidth,
                        prop: "logisticsTaxes",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "60", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.logisticsTaxes,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "logisticsTaxes", $$v)
                          },
                          expression: "changeform.logisticsTaxes",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "收货人",
                        "label-width": _vm.formLabelWidth,
                        prop: "receiveName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "60",
                          disabled: "",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.changeform.receiveName,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "receiveName", $$v)
                          },
                          expression: "changeform.receiveName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "收货人手机号",
                        "label-width": _vm.formLabelWidth,
                        prop: "telephone",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "60",
                          disabled: "",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.changeform.telephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "telephone", $$v)
                          },
                          expression: "changeform.telephone",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "33.3%" },
                      attrs: {
                        label: "收货人邮箱",
                        "label-width": _vm.formLabelWidth,
                        prop: "contactEmail",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "50",
                          disabled: "",
                          autocomplete: "off",
                        },
                        model: {
                          value: _vm.changeform.contactEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "contactEmail", $$v)
                          },
                          expression: "changeform.contactEmail",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    label: "收货人地址",
                    "label-width": _vm.formLabelWidth,
                    prop: "address",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "", autocomplete: "off" },
                    model: {
                      value: _vm.changeform.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.changeform, "address", $$v)
                      },
                      expression: "changeform.address",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退货物流公司",
                        "label-width": _vm.formLabelWidth,
                        prop: "logisticsName",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.changeform.logisticsName,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeform, "logisticsName", $$v)
                            },
                            expression: "changeform.logisticsName",
                          },
                        },
                        _vm._l(_vm.companylogistics, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.valueCn },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "退货物流单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "logisticsNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.changeform.logisticsNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "logisticsNumber", $$v)
                          },
                          expression: "changeform.logisticsNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "币种/退回运费",
                        "label-width": _vm.formLabelWidth,
                        prop: "logisticsUnit",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.changeform.logisticsUnit,
                            callback: function ($$v) {
                              _vm.$set(_vm.changeform, "logisticsUnit", $$v)
                            },
                            expression: "changeform.logisticsUnit",
                          },
                        },
                        _vm._l(_vm.logisticsUnits, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        "label-width": "20px",
                        prop: "logisticsPrice",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { autocomplete: "off" },
                        model: {
                          value: _vm.changeform.logisticsPrice,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeform, "logisticsPrice", $$v)
                          },
                          expression: "changeform.logisticsPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "换货物流公司",
                        "label-width": _vm.formLabelWidth,
                        prop: "exchangeLogisticsName",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.changeform.exchangeLogisticsName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.changeform,
                                "exchangeLogisticsName",
                                $$v
                              )
                            },
                            expression: "changeform.exchangeLogisticsName",
                          },
                        },
                        _vm._l(_vm.companylogistics, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.valueCn, value: item.valueCn },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "换货物流单号",
                        "label-width": _vm.formLabelWidth,
                        prop: "exchangeLogisticsNumber",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { maxlength: "60", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.exchangeLogisticsNumber,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.changeform,
                              "exchangeLogisticsNumber",
                              $$v
                            )
                          },
                          expression: "changeform.exchangeLogisticsNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "币种/寄出运费",
                        "label-width": _vm.formLabelWidth,
                        prop: "exchangeLogisticsUnit",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.changeform.exchangeLogisticsUnit,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.changeform,
                                "exchangeLogisticsUnit",
                                $$v
                              )
                            },
                            expression: "changeform.exchangeLogisticsUnit",
                          },
                        },
                        _vm._l(_vm.exchangeLogisticsUnits, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.valueCn, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "",
                        "label-width": "20px",
                        prop: "exchangeLogisticsPrice",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "60", autocomplete: "off" },
                        model: {
                          value: _vm.changeform.exchangeLogisticsPrice,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.changeform,
                              "exchangeLogisticsPrice",
                              $$v
                            )
                          },
                          expression: "changeform.exchangeLogisticsPrice",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("h3", [_vm._v("异常描述")]),
              _vm._v(" "),
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.changeform.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.changeform, "remark", $$v)
                  },
                  expression: "changeform.remark",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.changeClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.changesure },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "客服审核",
            visible: _vm.dialogFormCustomer,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormCustomer = $event
            },
            close: _vm.CustomerClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "Customerform",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.Customerform,
                rules: _vm.Customerrule,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "类型:",
                        "label-width": _vm.formLabelWidth,
                        prop: "type",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.Customerform.type) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "售后编号:",
                        "label-width": _vm.formLabelWidth,
                        prop: "customerNumber",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.Customerform.customerNumber) +
                          "\n          "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "订单序号:",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderSerial",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.Customerform.serialNumber) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "币种:",
                        "label-width": _vm.formLabelWidth,
                        prop: "customerUnit",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.customerUnit) + "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "责任类型:",
                        "label-width": _vm.formLabelWidth,
                        prop: "dutyType",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(_vm.Customerform.dutyType)
                      ),
                      _c("span", { staticStyle: { opacity: "0" } }, [
                        _vm._v("1231231231231"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { opacity: "0" },
                      attrs: {
                        label: "订单序号:",
                        "label-width": _vm.formLabelWidth,
                        prop: "orderSerial",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.Customerform.serialNumber) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "金额:",
                    "label-width": _vm.formLabelWidth,
                    prop: "price",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "10", autocomplete: "off" },
                    model: {
                      value: _vm.Customerform.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.Customerform, "price", $$v)
                      },
                      expression: "Customerform.price",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "备注:",
                    "label-width": _vm.formLabelWidth,
                    prop: "remark",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", autocomplete: "off" },
                    model: {
                      value: _vm.Customerform.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.Customerform, "remark", $$v)
                      },
                      expression: "Customerform.remark",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "margin-top": "20px",
                    color: "#000",
                    "font-size": "18px",
                    "margin-left": "55px",
                  },
                },
                [_vm._v("订单摘要")]
              ),
              _vm._v(" "),
              _vm._l(_vm.productList, function (result) {
                return _c("div", { staticStyle: { "margin-top": "20px" } }, [
                  _c(
                    "table",
                    {
                      staticStyle: {
                        width: "100%",
                        "border-spacing": "0px 10px",
                        "margin-left": "55px",
                      },
                      attrs: { border: "0", cellspacing: "1" },
                    },
                    [
                      _c(
                        "tr",
                        {
                          staticStyle: {
                            color: "#000",
                            "font-weight": "500",
                            display: "flex",
                            "justify-content": "space-between",
                          },
                        },
                        [
                          _c("td", { staticStyle: { width: "150px" } }, [
                            _vm._v("产品编号：" + _vm._s(result.productNo)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticStyle: { width: "200px" } }, [
                            _vm._v("产品名称：" + _vm._s(result.productName)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticStyle: { width: "100px" } }, [
                            _vm._v("数量：" + _vm._s(result.count)),
                          ]),
                          _vm._v(" "),
                          _c("td", { staticStyle: { width: "200px" } }, [
                            _vm._v("仓位：" + _vm._s(result.position)),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.CustomerClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.sureLoading,
                      expression: "sureLoading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.customerKeep },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormPrint,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormPrint = $event
            },
            close: _vm.printClose,
          },
        },
        [
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.printHtml },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { ref: "orderTable", attrs: { id: "orderTable" } },
            _vm._l(this.forms, function (printform) {
              return _c("div", [
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                          "margin-bottom": "10px",
                          position: "relative",
                        },
                      },
                      [
                        _c("div"),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "24px",
                              "text-align": "center",
                            },
                          },
                          [_vm._v(_vm._s(_vm.ordertitle))]
                        ),
                        _vm._v(" "),
                        (
                          _vm.ordertitle == "行政审核" ||
                          _vm.ordertitle == "财务审核"
                            ? false
                            : true
                        )
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "24px",
                                      "border-radius": "50%",
                                      position: "absolute",
                                      right: "220px",
                                      padding: "3px 10px",
                                    },
                                  },
                                  [_vm._v(_vm._s(printform.serialNumber))]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticStyle: { display: "flex" } }, [
                          _c(
                            "table",
                            {
                              staticStyle: { "text-align": "center" },
                              attrs: {
                                border: "1",
                                width: "200",
                                height: "30",
                                cellspacing: "0",
                              },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "200" } },
                                  [_vm._v("使用部门")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "200" } },
                                  [_vm._v("生产部")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "200" } },
                                  [_vm._v("保存年限")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "200" } },
                                  [_vm._v("5年")]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "200" } },
                                  [_vm._v("编号")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticStyle: { "font-weight": "200" } },
                                  [_vm._v("QR-8.4-08")]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form",
                      {
                        ref: "printform",
                        refInFor: true,
                        staticClass: "prints",
                        attrs: {
                          model: printform,
                          "status-icon": true,
                          "show-message": false,
                          "hide-required-asterisk": "",
                        },
                      },
                      [
                        _vm.checkFlag
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-bottom": "20px",
                                    "font-weight": "bold",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("审核：")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "0" },
                                      model: {
                                        value: _vm.reviewResult,
                                        callback: function ($$v) {
                                          _vm.reviewResult = $$v
                                        },
                                        expression: "reviewResult",
                                      },
                                    },
                                    [_vm._v("通过")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: { label: "1" },
                                      model: {
                                        value: _vm.reviewResult,
                                        callback: function ($$v) {
                                          _vm.reviewResult = $$v
                                        },
                                        expression: "reviewResult",
                                      },
                                    },
                                    [_vm._v("不通过")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "eror",
                                  staticStyle: {
                                    display: "flex",
                                    "align-items": "center",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "h3",
                                    {
                                      staticStyle: {
                                        width: "100px",
                                        color: "red",
                                      },
                                    },
                                    [_vm._v("异常描述:")]
                                  ),
                                  _vm.printFlag
                                    ? _c("el-input", {
                                        attrs: { type: "textarea" },
                                        model: {
                                          value: _vm.printremark,
                                          callback: function ($$v) {
                                            _vm.printremark = $$v
                                          },
                                          expression: "printremark",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.printFlag
                                    ? _c("el-input", {
                                        attrs: { type: "textarea" },
                                        domProps: {
                                          textContent: _vm._s(_vm.printremark),
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticStyle: {
                                  "border-bottom": "3px solid #000",
                                },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              width: "100%",
                              "border-spacing": "10px 10px",
                              padding: "5px 5px 5px 5px",
                            },
                            attrs: {
                              border: "1",
                              rules: "none",
                              cellspacing: "1",
                            },
                          },
                          [
                            _c("tr", [
                              _c("td", { staticStyle: { padding: "5px 0" } }, [
                                _vm._v("订单号：" + _vm._s(printform.orderNo)),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v("渠道：" + _vm._s(printform.dealer)),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "支付日期：" + _vm._s(printform.payTime)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "付款渠道：" +
                                      _vm._s(printform.originalPayType)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "订单总价：" + _vm._s(printform.orderPrice)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "发货日期：" + _vm._s(printform.deliverDate)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [_vm._v("交易号：" + _vm._s(printform.tradeNo))]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "流水号：" + _vm._s(printform.serialNo)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticStyle: {
                                    "padding-bottom": "5px",
                                    opacity: "0",
                                  },
                                },
                                [
                                  _vm._v(
                                    "流水号：" + _vm._s(printform.serialNo)
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "table",
                          {
                            staticStyle: {
                              width: "100%",
                              "border-spacing": "10px 10px",
                              "margin-top": "5px",
                              padding: "5px 5px 5px 5px",
                            },
                            attrs: {
                              border: "1",
                              rules: "none",
                              cellspacing: "1",
                            },
                          },
                          [
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "售后编号：" +
                                      _vm._s(printform.customerNumber)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [_vm._v("售后类型：" + _vm._s(printform.type))]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "申请时间：" + _vm._s(printform.createTime)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "售后状态：" + _vm._s(printform.status)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [_vm._v("售后金额：" + _vm._s(printform.price))]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "发货类型：" + _vm._s(printform.deliverType)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "退回类型：" + _vm._s(printform.returnType)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "费用类型：" + _vm._s(printform.costType)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "涉及商品：" +
                                      _vm._s(printform.involveProductType)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "付款渠道：" + _vm._s(printform.payType)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "责任类型：" + _vm._s(printform.dutyType)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "发货日期：" + _vm._s(printform.deliverDate)
                                  ),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            (
                              _vm.ordertitle == "行政审核" &&
                              printform.involveProductType === "1"
                                ? false
                                : true
                            )
                              ? _c("tr", [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: { "padding-bottom": "5px" },
                                    },
                                    [
                                      _vm._v(
                                        "客户要求：" +
                                          _vm._s(printform.customerRequest)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "税费：" +
                                        _vm._s(printform.logisticsTaxes)
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: { "padding-bottom": "5px" },
                                    },
                                    [
                                      _vm._v(
                                        "操作时间：" +
                                          _vm._s(printform.updateTime)
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("tr", [
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "退回时间：" + _vm._s(printform.returnTime)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "退回区域仓：" +
                                      _vm._s(printform.locationPosition)
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { "padding-bottom": "5px" } },
                                [
                                  _vm._v(
                                    "换出区域仓：" +
                                      _vm._s(printform.exchangeLocationPosition)
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        (
                          _vm.ordertitle == "行政审核" &&
                          printform.involveProductType === "1"
                            ? false
                            : true
                        )
                          ? _c("div", { staticStyle: { width: "100%" } }, [
                              _c(
                                "table",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "border-spacing": "0px 10px",
                                    "margin-top": "5px",
                                    padding: "5px 5px 5px 5px",
                                  },
                                  attrs: {
                                    border: "1",
                                    rules: "none",
                                    cellspacing: "0",
                                  },
                                },
                                [
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "收货人：" +
                                            _vm._s(printform.receiveName)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "收货人手机号：" +
                                            _vm._s(printform.telephone)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v("联系人邮箱："),
                                        _c(
                                          "a",
                                          {
                                            attrs: { contentEditable: "true" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(printform.contactEmail)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                        attrs: { colspan: "3" },
                                      },
                                      [
                                        _vm._v(
                                          "收货人地址：" +
                                            _vm._s(printform.address)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "退货物流公司：" +
                                            _vm._s(printform.logisticsName)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "退货物流单号：" +
                                            _vm._s(printform.logisticsNumber)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "退回运费：" +
                                            _vm._s(printform.logisticsPrice)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "换货物流公司：" +
                                            _vm._s(
                                              printform.exchangeLogisticsName
                                            )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "换货物流单号：" +
                                            _vm._s(
                                              printform.exchangeLogisticsNumber
                                            )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "寄出运费：" +
                                            _vm._s(
                                              printform.exchangeLogisticsPrice
                                            )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "padding-bottom": "5px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "寄出时间：" +
                                            _vm._s(
                                              printform.exchangeLogisticsTime
                                            )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        (
                          _vm.ordertitle == "行政审核" &&
                          printform.involveProductType === "1"
                            ? false
                            : true
                        )
                          ? _c(
                              "div",
                              [
                                _vm.checkflages
                                  ? _c(
                                      "div",
                                      { staticStyle: { "padding-top": "5px" } },
                                      [
                                        _vm._v(
                                          "异常描述:" + _vm._s(printform.remark)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "border-spacing": "0px 10px",
                                      "margin-top": "5px",
                                      padding: "5px 5px 5px 5px",
                                    },
                                    attrs: {
                                      border: "1",
                                      rules: "none",
                                      cellspacing: "1",
                                    },
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "申请人：" +
                                              _vm._s(printform.createUser)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "申请时间：" +
                                              _vm._s(printform.createTime)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                            opacity: "0",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "客服审核时间：" +
                                              _vm._s(
                                                printform.customerReviewTime
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "验货人：" +
                                              _vm._s(printform.examineGoods)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "验货时间：" +
                                              _vm._s(printform.examineGoodsTime)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                            opacity: "0",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "客服审核时间：" +
                                              _vm._s(
                                                printform.customerReviewTime
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "客服审核：" +
                                              _vm._s(printform.customerReview)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "客服审核时间：" +
                                              _vm._s(
                                                printform.customerReviewTime
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                            opacity: "0",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "客服审核时间：" +
                                              _vm._s(
                                                printform.customerReviewTime
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "行政审核：" +
                                              _vm._s(
                                                printform.administrationReview
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "行政审核时间：" +
                                              _vm._s(
                                                printform.administrationReviewTime
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          style: {
                                            color:
                                              printform.administrationReviewResult ==
                                              "不通过"
                                                ? "red"
                                                : "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "行政审核结果：" +
                                              _vm._s(
                                                printform.administrationReviewResult
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "财务审核：" +
                                              _vm._s(printform.financeReview)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "财务审核时间：" +
                                              _vm._s(
                                                printform.financeReviewTime
                                              )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        {
                                          style: {
                                            color:
                                              printform.financeReviewResult ==
                                              "不通过"
                                                ? "red"
                                                : "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "财务审核结果：" +
                                              _vm._s(
                                                printform.financeReviewResult
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "table",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      "border-spacing": "0px 10px",
                                      "margin-top": "5px",
                                      padding: "5px 5px 5px 5px",
                                    },
                                    attrs: {
                                      border: "1",
                                      rules: "none",
                                      cellspacing: "1",
                                    },
                                  },
                                  [
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                            "word-break": "break-all",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "退回设备号：" +
                                              _vm._s(printform.equipmentNumber)
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "5px",
                                            "word-break": "break-all",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "换出设备号：" +
                                              _vm._s(
                                                printform.exchangeEquipmentNumber
                                              )
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "padding-top": "5px" } },
                                  [_vm._v("售后产品明细：")]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  printform.customerReturnDetailList,
                                  function (item, index) {
                                    return _c(
                                      "table",
                                      {
                                        key: index,
                                        staticStyle: {
                                          width: "100%",
                                          "border-spacing": "0px 0px",
                                        },
                                        attrs: {
                                          border: "0",
                                          cellspacing: "1",
                                        },
                                      },
                                      [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              color: "#000",
                                              display: "flex",
                                              "justify-content":
                                                "space-between",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "150px" },
                                              },
                                              [
                                                _vm._v(
                                                  "编号：" +
                                                    _vm._s(item.productNo)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "200px" },
                                              },
                                              [
                                                _vm._v(
                                                  "名称：" +
                                                    _vm._s(item.productName)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _vm._v(
                                                  "仓位：" +
                                                    _vm._s(item.position)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _vm._v(
                                                  "数量：" + _vm._s(item.count)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _vm._v(
                                                  "金额：" + _vm._s(item.price)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tr"),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticStyle: { "margin-top": "5px" } },
                                  [_vm._v("换出产品明细：")]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  printform.customerExchangeDetailList,
                                  function (item, index) {
                                    return _c(
                                      "table",
                                      {
                                        key: index,
                                        staticStyle: {
                                          width: "100%",
                                          "border-spacing": "0px 0px",
                                        },
                                        attrs: {
                                          border: "0",
                                          cellspacing: "1",
                                        },
                                      },
                                      [
                                        _c(
                                          "tr",
                                          {
                                            staticStyle: {
                                              color: "#000",
                                              display: "flex",
                                              "justify-content":
                                                "space-between",
                                              "align-items": "center",
                                            },
                                          },
                                          [
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "150px" },
                                              },
                                              [
                                                _vm._v(
                                                  "编号：" +
                                                    _vm._s(item.productNo)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "200px" },
                                              },
                                              [
                                                _vm._v(
                                                  "名称：" +
                                                    _vm._s(item.productName)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _vm._v(
                                                  "仓位：" +
                                                    _vm._s(item.position)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _vm._v(
                                                  "数量：" + _vm._s(item.count)
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticStyle: { width: "100px" },
                                              },
                                              [
                                                _vm._v(
                                                  "金额：" + _vm._s(item.price)
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("tr"),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "15px" } },
                          [
                            printform.customerNumber
                              ? _c("barcode", {
                                  attrs: {
                                    value: printform.customerNumber,
                                    width: 2.5,
                                    height: 15,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogFormPrints,
            "close-on-click-modal": false,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormPrints = $event
            },
            close: _vm.printClose,
          },
        },
        [
          _c("div", { ref: "orderTable", attrs: { id: "orderTables" } }, [
            _c(
              "div",
              { staticStyle: { "padding-right": "15px" } },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "space-between",
                      "align-items": "center",
                      "margin-bottom": "10px",
                      position: "relative",
                    },
                  },
                  [
                    _c("div"),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "24px",
                          "text-align": "center",
                        },
                      },
                      [
                        _vm._v(
                          "退款单（" + _vm._s(_vm.printforms.orderType) + "）"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "24px",
                              "border-radius": "50%",
                              position: "absolute",
                              right: "220px",
                              padding: "3px 10px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.printforms.serialNumber))]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "table",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: {
                            border: "1",
                            width: "200",
                            height: "30",
                            cellspacing: "0",
                          },
                        },
                        [
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "font-weight": "200" } },
                              [_vm._v("使用部门")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "font-weight": "200" } },
                              [_vm._v("生产部")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "font-weight": "200" } },
                              [_vm._v("保存年限")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "font-weight": "200" } },
                              [_vm._v("5年")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c(
                              "td",
                              { staticStyle: { "font-weight": "200" } },
                              [_vm._v("编号")]
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticStyle: { "font-weight": "200" } },
                              [_vm._v("QR-8.4-08")]
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form",
                  {
                    ref: "printform",
                    staticClass: "prints",
                    attrs: {
                      model: _vm.printform,
                      "status-icon": true,
                      "show-message": false,
                      "hide-required-asterisk": "",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                          "margin-bottom": "2px",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "font-size": "13px",
                              "font-weight": "200",
                            },
                          },
                          [
                            _vm._v("申请部门：\n              "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.edit,
                                    expression: "!edit",
                                  },
                                ],
                              },
                              [_vm._v(_vm._s(_vm.prod))]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.edit,
                                  expression: "edit",
                                },
                              ],
                              model: {
                                value: _vm.prod,
                                callback: function ($$v) {
                                  _vm.prod = $$v
                                },
                                expression: "prod",
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              class: {
                                "el-icon-edit": !_vm.edit,
                                "el-icon-check": _vm.edit,
                              },
                              staticStyle: {
                                "margin-left": "10px",
                                "font-size": "16px",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.edit = !_vm.edit
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "font-size": "13px",
                              "font-weight": "200",
                            },
                          },
                          [
                            _vm._v("\n              申请人："),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !_vm.edits,
                                    expression: "!edits",
                                  },
                                ],
                              },
                              [_vm._v(_vm._s(_vm.description))]
                            ),
                            _vm._v(" "),
                            _c("el-input", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.edits,
                                  expression: "edits",
                                },
                              ],
                              model: {
                                value: _vm.description,
                                callback: function ($$v) {
                                  _vm.description = $$v
                                },
                                expression: "description",
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              class: {
                                "el-icon-edit": !_vm.edits,
                                "el-icon-check": _vm.edits,
                              },
                              staticStyle: {
                                "margin-left": "10px",
                                "font-size": "16px",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.edits = !_vm.edits
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "13px",
                              "font-weight": "200",
                            },
                          },
                          [
                            _vm._v(
                              "\n              制单日期：" +
                                _vm._s(_vm.printforms.deliverDate) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.returnOrderprint, function (item) {
                      return _c("div", [
                        !item.orderNo.includes("~")
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    "align-items": "center",
                                  },
                                },
                                [
                                  _c("label", [
                                    _c("input", {
                                      attrs: {
                                        name: "Type",
                                        type: "radio",
                                        value: "1",
                                        checked: "checked",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "margin-left": "3px",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v("全额退款")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("input", {
                                      attrs: {
                                        name: "Type",
                                        type: "radio",
                                        value: "3",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "margin-left": "3px",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v("部分退款")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("input", {
                                      attrs: {
                                        name: "Type",
                                        type: "radio",
                                        value: "2",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "margin-left": "3px",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v(" 退货")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("label", [
                                    _c("input", {
                                      attrs: {
                                        name: "Type",
                                        type: "radio",
                                        value: "4",
                                      },
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "margin-left": "3px",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v("换货")]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticStyle: { "margin-top": "2px" } },
                                [
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "200" },
                                      },
                                      [
                                        _vm._v(
                                          "渠道：" + _vm._s(item.salesType)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "260" },
                                      },
                                      [
                                        _vm._v(
                                          "订单编号：" + _vm._s(item.orderNo)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " 付款日期： " + _vm._s(item.payTime)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticStyle: { "margin-top": "-8px" } },
                                [
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "200" },
                                      },
                                      [
                                        _vm._v(
                                          " 收件人：" + _vm._s(item.receiveName)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "260" },
                                      },
                                      [
                                        _vm._v(
                                          " 交易号：" + _vm._s(item.tradeNo)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "  付款渠道：" + _vm._s(item.payType)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticStyle: { "margin-top": "-8px" } },
                                [
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "200" },
                                      },
                                      [
                                        _vm._v(
                                          " 收件人手机号：" +
                                            _vm._s(item.telephone)
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "260" },
                                      },
                                      [
                                        (
                                          item.deliverCycle == "" ||
                                          item.deliverCycle == null
                                            ? false
                                            : true
                                        )
                                          ? _c("span", [
                                              _vm._v(
                                                "提货日期：" +
                                                  _vm._s(item.deliverCycle)
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "结算方式： " + _vm._s(item.unit)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticStyle: { "margin-top": "-8px" } },
                                [
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "200" },
                                      },
                                      [
                                        _vm._v(
                                          " 流水号：" + _vm._s(item.serialNo)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticStyle: { "margin-top": "-8px" } },
                                [
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "  收方地址： " + _vm._s(item.address)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticStyle: {
                                    "margin-top": "2px",
                                    width: "100%",
                                  },
                                  attrs: { border: "1", cellspacing: "0" },
                                },
                                [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("序号")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v("商品名称")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "80" },
                                      },
                                      [_vm._v("规格型号")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("数量")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("单价")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("金额")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v("备注")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    item.orderDetailList,
                                    function (res, index) {
                                      return _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(res.serialNumber))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(res.productName))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(res.productNo))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(res.count))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(res.orderPrice))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(res.productTotalPrice)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(res.remark))]
                                        ),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _vm.printforms.flag == "Y"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "margin-top": "15px",
                                        "margin-bottom": "7px",
                                        color: "#409EFF",
                                        "font-size": "18px",
                                      },
                                    },
                                    [_vm._v("补录单信息：")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        item.orderNo.includes("~")
                          ? _c("div", [
                              _c("table", [
                                _c("th", [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "205" },
                                    },
                                    [
                                      _vm._v(
                                        "订单编号：  " + _vm._s(item.orderNo)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "text-align": "left",
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "300" },
                                    },
                                    [_vm._v("金额：" + _vm._s(item.orderPrice))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                      attrs: { width: "200" },
                                    },
                                    [
                                      _vm._v(
                                        " 付款日期： " + _vm._s(item.payTime)
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "table",
                                { staticStyle: { "margin-top": "-8px" } },
                                [
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "200" },
                                      },
                                      [
                                        _vm._v(
                                          " 付款渠道：" + _vm._s(item.payType)
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", [
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "text-align": "left",
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "300" },
                                      },
                                      [
                                        _vm._v(
                                          " 交易号：" + _vm._s(item.tradeNo)
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "table",
                                {
                                  staticStyle: {
                                    border: "1px solid skyblue",
                                    "margin-top": "2px",
                                    width: "100%",
                                  },
                                  attrs: { border: "1", cellspacing: "0" },
                                },
                                [
                                  _c("tr", [
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("序号")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v("商品名称")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "80" },
                                      },
                                      [_vm._v("规格型号")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("数量")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("单价")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                        attrs: { width: "50" },
                                      },
                                      [_vm._v("金额")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "th",
                                      {
                                        staticStyle: {
                                          "font-weight": "200",
                                          "font-size": "13px",
                                        },
                                      },
                                      [_vm._v("备注")]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    item.orderDetailList,
                                    function (result, index) {
                                      return _c("tr", [
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(result.serialNumber))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(result.productName))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(result.productNo))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(result.count))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(result.orderPrice))]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(result.productTotalPrice)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "th",
                                          {
                                            staticStyle: {
                                              "font-weight": "200",
                                              "font-size": "13px",
                                            },
                                          },
                                          [_vm._v(_vm._s(result.remark))]
                                        ),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ])
                    }),
                    _vm._v(" "),
                    _vm.printforms.flag == "Y"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "15px",
                                "margin-bottom": "15px",
                                color: "#115c3b",
                                "font-size": "18px",
                              },
                            },
                            [_vm._v("订单实际商品：")]
                          ),
                          _vm._v(" "),
                          _c(
                            "table",
                            {
                              staticStyle: {
                                border: "1px solid #e8f2ee",
                                "margin-top": "2px",
                                width: "100%",
                              },
                              attrs: { border: "1", cellspacing: "0" },
                            },
                            [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("产品名称")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("产品型号")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "font-weight": "200",
                                      "font-size": "13px",
                                    },
                                    attrs: { width: "50" },
                                  },
                                  [_vm._v("数量")]
                                ),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.returnReal, function (real) {
                                return _c("tr", [
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(real.productName))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(real.productNo))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "th",
                                    {
                                      staticStyle: {
                                        "font-weight": "200",
                                        "font-size": "13px",
                                      },
                                    },
                                    [_vm._v(_vm._s(real.count))]
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                          "padding-right": "100px",
                          "margin-top": "10px",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v("仓库:"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v("财务:"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v("发货人:"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v("申请部门审批:"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                          "align-items": "center",
                          "margin-top": "10px",
                        },
                      },
                      [
                        _c("div", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v("客户回签:"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-size": "13px" } }, [
                          _vm._v("回签日期:"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { opacity: "0" } }, [
                          _vm._v("回签日期1222111111111111111"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { opacity: "0" } }, [
                          _vm._v("回签日期:"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "15px" } },
                      [
                        _vm.printforms.serialNumber
                          ? _c("barcode", {
                              attrs: {
                                value: _vm.printforms.serialNumber,
                                width: 2.5,
                                height: 15,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { float: "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.accountloading,
                      expression: "accountloading",
                    },
                  ],
                  attrs: { type: "primary", disabled: _vm.isDisable },
                  on: { click: _vm.printHtmls },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "条目配置", visible: _vm.visible },
          on: {
            "update:visible": function ($event) {
              _vm.visible = $event
            },
            close: function ($event) {
              _vm.visible = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.visible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.saveColumn },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              _vm._l(_vm.showCusterColumn, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticStyle: { width: "25%", display: "inline-block" },
                    style: { marginRight: (i + 1) % 3 === 0 ? 0 : "10%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-top": "10px",
                          "font-weight": "500",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "140px",
                              "margin-right": "10px",
                              "text-align": "right",
                            },
                          },
                          [_vm._v(_vm._s(item.value))]
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#bfbfbf",
                          },
                          model: {
                            value: item.flag,
                            callback: function ($$v) {
                              _vm.$set(item, "flag", $$v)
                            },
                            expression: "item.flag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "筛选框配置", visible: _vm.dialogFormSearch },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormSearch = $event
            },
            close: function ($event) {
              _vm.dialogFormSearch = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "span",
                    { staticClass: "dialog-footer" },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogFormSearch = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.searchSure },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              _vm._l(_vm.flagList, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticStyle: { width: "25%", display: "inline-block" },
                    style: { marginRight: (i + 1) % 3 === 0 ? 0 : "10%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "margin-top": "10px",
                          "font-weight": "500",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "120px",
                              "margin-right": "10px",
                              "text-align": "right",
                            },
                          },
                          [_vm._v(_vm._s(item.value))]
                        ),
                        _vm._v(" "),
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#bfbfbf",
                          },
                          model: {
                            value: item.flag,
                            callback: function ($$v) {
                              _vm.$set(item, "flag", $$v)
                            },
                            expression: "item.flag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "保修卡",
            visible: _vm.dialogWarrantyCard,
            "close-on-click-modal": false,
            width: "30%",
            height: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogWarrantyCard = $event
            },
            close: _vm.warrantyCardClose,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "warrantyCard",
              staticStyle: { "margin-top": "20px" },
              attrs: {
                model: _vm.warrantyCardform,
                "status-icon": true,
                "show-message": false,
                "hide-required-asterisk": "",
              },
            },
            [
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "设备号",
                        "label-width": _vm.formLabelWidth,
                        prop: "equipmentNo",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入设备号" },
                        model: {
                          value: _vm.warrantyCardform.equipmentNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.warrantyCardform, "equipmentNo", $$v)
                          },
                          expression: "warrantyCardform.equipmentNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-left": "30px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.warrantyCardSearch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.warrantyCardflag
                ? _c("div", { staticClass: "warrantyCard" }, [
                    _c("div", { staticClass: "label-style" }, [
                      _c("a", { staticClass: "a-style" }, [
                        _vm._v("设备号：  "),
                      ]),
                      _vm._v(_vm._s(_vm.warrantyCard.equipmentNumber)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "label-style" }, [
                      _c("a", { staticClass: "a-style" }, [
                        _vm._v("设备型号： "),
                      ]),
                      _vm._v(_vm._s(_vm.warrantyCard.equipmentModel)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "label-style" }, [
                      _c("a", { staticClass: "a-style" }, [
                        _vm._v("保修截止日："),
                      ]),
                      _vm._v(_vm._s(_vm.warrantyCard.warrantyEndTime)),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "50px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.warrantyCardClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }