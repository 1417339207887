"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _banner = require("../../api/banner.js");
var _index = require("../../utils/index.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      isShow: false,
      rules: {
        name: [{
          required: true,
          trigger: "blur"
        }],
        description: [{
          required: true,
          trigger: "blur"
        }],
        buttonLink: [{
          required: true,
          trigger: "blur"
        }],
        buttonName: [{
          required: true,
          trigger: "blur"
        }]
      },
      addApprove: {
        seq: ""
      },
      dialogFormVisible1: false,
      isDisable: false,
      addform: {
        id: "",
        seq: "",
        colorPc: "",
        colorMobile: "",
        buttonFlag: "",
        urlPc: "",
        urlMobile: "",
        name: "",
        description: "",
        buttonName: "",
        line: "2",
        buttonLink: "",
        productId: "",
        buttonInnerOut: "",
        language: 'ZH-CN'
      },
      recommendType: [],
      doUpload: "",
      pppss: "",
      lines: [{
        line: "0",
        label: "上线"
      }, {
        line: "1",
        label: "预览"
      }, {
        line: "2",
        label: "下线"
      }],
      imageUrl1: "",
      imageUrl2: "",
      languages: [{
        language: "ZH-CN",
        label: "中文"
      }, {
        language: "EN",
        label: "英文"
      }, {
        language: "JA",
        label: "日文"
      }],
      currentPage: 1,
      total: 0,
      loading: false,
      form: {
        language: "",
        line: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        type: ""
      },
      forms: {
        language: "",
        line: "",
        pageNo: 1,
        pageSize: 10,
        sortField: "",
        sortRules: "",
        type: ""
      },
      lineStatus: {
        line: ""
      },
      tableData: [],
      multipleSelection: [],
      value: "",
      dialogFormVisible: false,
      addRules: [],
      formLabelWidth: "160px",
      dialogFormVisibles: false,
      changeRules: [],
      radio: "",
      dialogVisible: false,
      limitNum: 1,
      isFlag: true
    };
  },
  created: function created() {
    //this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _banner.queryBanner)(_this.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.forms.pageNo = 1;
            _this.currentPage = 1;
            (0, _banner.queryBanner)(_this.forms).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    buttonFlag: function buttonFlag() {
      if (this.addform.buttonInnerOut == "") {
        this.addform.buttonInnerOut = "0";
      }
    },
    //判断上下轮播图
    typeFormatter: function typeFormatter(row, column) {
      if (row.type == "0") {
        return "上轮播图";
      } else if (row.type == "1") {
        return "下轮播图";
      }
    },
    // 上传图片出错
    uploadError: function uploadError(err, file, fileList) {
      console.log(file);
    },
    //判断上下线
    lineFormatter: function lineFormatter(row, column) {
      if (row.line == "0") {
        return "上线";
      } else if (row.line == "1") {
        return "预览";
      } else if (row.line == "2") {
        return "下线";
      }
    },
    //判断按钮
    buttonFlagFormatter: function buttonFlagFormatter(row, column) {
      if (row.buttonFlag == "Y") {
        return "是";
      } else if (row.buttonFlag == "N") {
        return "否";
      }
    },
    //判断语言
    languageFormatter: function languageFormatter(row, column) {
      if (row.language == "ZH-CN" || row.language == "zh-cn") {
        return "中文";
      } else if (row.language == "EN" || row.language == "en") {
        return "英文";
      } else if (row.language == "JA" || row.language == "ja") {
        return "日文";
      } else {
        return row.language;
      }
    },
    //新增
    add: function add() {
      var _this2 = this;
      this.addform.seq = "";
      this.addform.colorPc = "";
      this.addform.colorMobile = "";
      this.addform.urlPc = "";
      this.addform.urlMobile = "";
      this.imageUrl1 = "";
      this.imageUrl2 = "";
      this.addform.name = "";
      this.addform.buttonFlag = "N";
      this.addform.description = "";
      this.addform.buttonName = "";
      this.addform.seoKeyword = "";
      this.addform.seoDescription = "";
      this.addform.buttonLink = "";
      this.addform.productId = "";
      this.addform.buttonInnerOut = "0";
      this.dialogFormVisible = true;
      this.isDisable = false;
      (0, _banner.queryRecommend)().then(function (res) {
        _this2.recommendType = res.voList;
      });
    },
    //新增关闭按钮
    addClose: function addClose(formName) {
      this.dialogFormVisible = false;
      this.addform.language = "ZH-CN";
      this.$refs[formName].resetFields();
    },
    //新增确定按钮
    sure: function sure(formName) {
      var _this3 = this;
      if (this.addform.buttonFlag == "N") {
        this.addform.productId = "";
        this.addform.buttonInnerOut = "";
        this.addform.buttonLink = "";
        this.addform.buttonName = "";
      }
      if (this.addform.buttonFlag == "Y") {
        if (this.addform.buttonInnerOut == "0") {
          this.addform.buttonLink = "";
        }
        if (this.addform.buttonInnerOut == "1") {
          this.addform.productId = "";
        }
      }
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (!/^[1-9]{1,}[\d]*$/.test(_this3.addform.seq) && _this3.addform.seq !== "") {
            _this3.msgTip("warning", "序号只能为正整数");
            return;
          }
          if (_this3.addform.colorPc == "" || _this3.addform.colorMobile == "") {
            _this3.msgTip("warning", "请选择字体颜色");
            return;
          }
          if (_this3.addform.buttonFlag == "Y") {
            if (_this3.addform.buttonInnerOut == "0") {
              if (_this3.addform.productId == "") {
                _this3.msgTip("warning", "请选择链接类型");
                return;
              }
            }
          }
          if (_this3.imageUrl1 == "") {
            _this3.msgTip("warning", "必须上传pc端图片");
            return;
          }
          if (_this3.imageUrl2 == "") {
            _this3.msgTip("warning", "必须上传移动端图片");
            return;
          }
          _this3.addform.line = '2';
          _this3.isDisable = true;
          (0, _banner.addBanner)(_this3.addform).then(function (res) {
            if (res.success) {
              _this3.msgTip("success", "添加成功");
              _this3.addform.seq = "";
              // 前端刷新
              (0, _banner.dataManipulation)(_this3.tableData, getDate(), window.localStorage.getItem("username"));
              _this3.getList();
              _this3.dialogFormVisible = false;
              _this3.$refs[formName].resetFields();
            } else {
              _this3.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this3.isDisable = false;
            }
          });
        }
      });
    },
    //修改
    change: function change(v) {
      var _this4 = this;
      this.dialogFormVisibles = true;
      this.isDisable = false;
      (0, _banner.getBannerById)(v.id).then(function (res) {
        _this4.addform = res;
        _this4.imageUrl1 = res.urlPc.split('===')[0];
        _this4.imageUrl2 = res.urlMobile.split('===')[0];
      });
      (0, _banner.queryRecommend)().then(function (res) {
        _this4.recommendType = res.voList;
      });
    },
    //修改关闭按钮
    changeClose: function changeClose(formName) {
      this.dialogFormVisibles = false;
      this.addform.language = "ZH-CN";
      this.$refs[formName].resetFields();
    },
    // 上传文件之前的钩子
    beforeUploadPicture1: function beforeUploadPicture1(file) {
      var _this5 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this5.imageUrl1 = res.voT.downloadUrl.split('===')[0];
        _this5.addform.urlPc = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    // 上传文件之前的钩子
    beforeUploadPicture2: function beforeUploadPicture2(file) {
      var _this6 = this;
      if (!(file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpg" || file.type === "image/jpeg")) {
        this.msgTip("warning", "请上传格式为png, gif, jpg, jpeg的图片");
        return;
      }
      var fd = new FormData();
      fd.append("file", file); //传文件
      (0, _upload.upload)(fd).then(function (res) {
        _this6.imageUrl2 = res.voT.downloadUrl.split('===')[0];
        _this6.addform.urlMobile = res.voT.downloadUrl;
      });
      return false; //屏蔽了action的默认上传
    },
    //修改确定按钮
    keep: function keep(formName) {
      var _this7 = this;
      if (this.addform.buttonFlag == "N") {
        this.addform.productId = "";
        this.addform.buttonInnerOut = "";
        this.addform.buttonLink = "";
        this.addform.buttonName = "";
      }
      if (this.addform.buttonFlag == "Y") {
        if (this.addform.buttonInnerOut == "0") {
          this.addform.buttonLink = "";
        }
        if (this.addform.buttonInnerOut == "1") {
          this.addform.productId = "";
        }
      }
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          if (_this7.addform.colorPc == "" || _this7.addform.colorMobile == "") {
            _this7.msgTip("warning", "请选择字体颜色");
            return;
          }
          if (_this7.addform.buttonFlag == "Y") {
            if (_this7.addform.buttonInnerOut == "0") {
              if (_this7.addform.productId == "") {
                _this7.msgTip("warning", "请选择链接类型");
                return;
              }
            }
          }
          if (_this7.imageUrl1 == "") {
            _this7.msgTip("warning", "必须上传pc端图片");
            return;
          }
          if (_this7.imageUrl2 == "") {
            _this7.msgTip("warning", "必须上传移动端图片");
            return;
          }
          _this7.isDisable = true;
          _this7.addform.line = '';
          (0, _banner.updateBanner)(_this7.addform).then(function (res) {
            if (res.success) {
              _this7.msgTip("success", "修改成功");
              // 前端刷新
              (0, _banner.dataManipulation)(_this7.tableData, getDate(), window.localStorage.getItem("username"));
              _this7.getList();
              _this7.dialogFormVisibles = false;
              _this7.$refs[formName].resetFields();
            } else {
              _this7.$message({
                type: "warning",
                message: res.errorMsg,
                center: true
              });
              _this7.isDisable = false;
            }
          });
        }
      });
    },
    //删除
    dele: function dele(v) {
      var _this8 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _banner.delBanner)(v.id).then(function (res) {
          if (res.success) {
            _this8.msgTip("success", "操作成功");
            _this8.getList();
          } else {
            _this8.msgTip("warning", res.errorMsg);
          }
        });
      });
    },
    empty: function empty() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.form.line = "";
      this.form.language = "";
      this.forms.line = "";
      this.forms.language = "";
      this.getList();
    },
    //查询
    search: function search() {
      var _this9 = this;
      this.loading = true;
      this.currentPage = 1;
      this.forms.pageNo = 1;
      this.forms.line = this.form.line;
      this.forms.language = this.form.language;
      setTimeout(function () {
        (0, _banner.queryBanner)((0, _defineProperty2.default)({
          line: _this9.form.line,
          pageNo: 1,
          pageSize: 10,
          sortField: "",
          language: "",
          sortRules: ""
        }, "language", _this9.form.language)).then(function (res) {
          _this9.form.pageNo = 1;
          _this9.tableData = res.voList;
          _this9.total = res.total;
        });
        _this9.currentPage = 1;
        _this9.loading = false;
      }, 500);
    },
    //上线
    online: function online(v) {
      var _this10 = this;
      if (v.line == 0) {
        this.msgTip("error", "已是上线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 0;
        (0, _banner.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this10.msgTip("success", "操作成功");
            _this10.empty();
            _this10.isFlag = true;
          } else {
            _this10.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this10.isFlag = true;
          }
        });
      }
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //下线
    offline: function offline(v) {
      var _this11 = this;
      if (v.line == 2) {
        this.msgTip("error", "已是下线状态");
        return;
      }
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 2;
        (0, _banner.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this11.msgTip("success", "操作成功");
            _this11.empty();
            _this11.isFlag = true;
          } else {
            _this11.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this11.isFlag = true;
          }
        });
      }
    },
    //预览
    preview: function preview(v) {
      var _this12 = this;
      if (this.isFlag) {
        this.isFlag = false;
        this.lineStatus.line = 1;
        (0, _banner.updateLowerLine)(this.lineStatus, v.id).then(function (res) {
          if (res.success) {
            _this12.msgTip("success", "操作成功");
            _this12.empty();
            _this12.isFlag = true;
          } else {
            _this12.$message({
              type: "warning",
              message: res.errorMsg,
              center: true
            });
            _this12.isFlag = true;
          }
        });
      }
    },
    // 文件超出个数限制时的钩子
    handleExceed: function handleExceed(files, fileList) {},
    // 文件列表移除文件时的钩子
    handleRemove: function handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 点击文件列表中已上传的文件时的钩子
    handlePictureCardPreview: function handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/home/banner/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this13 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
        return;
      }
      if (!/^[1-9]{1,}[\d]*$/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
        return;
      }
      this.addApprove.seq = val.seq;
      (0, _banner.updateSeq)(this.addApprove, val.id).then(function (res) {
        if (res.success) {
          _this13.msgTip("success", "修改成功");
          _this13.empty();
          _this13.isShow = false;
        } else {
          _this13.$message({
            type: "warning",
            message: res.errorMsg,
            center: true
          });
        }
      });
    }
  }
};
/**
 * 获取当前时间
 */
function getDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  var hours = date.getHours(); //获取当前小时数(0-23)
  var minutes = date.getMinutes(); //获取当前分钟数(0-59)
  var seconds = date.getSeconds();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hours + ":" + minutes + ":" + seconds;
  return currentdate;
}