var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "add" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.addRules,
            "status-icon": true,
            "show-message": false,
            "hide-required-asterisk": "",
          },
        },
        [
          _c("ol", [
            _c(
              "li",
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "系列编号",
                      "label-width": _vm.formLabelWidth,
                      prop: "serialNumber",
                    },
                  },
                  [
                    _c("el-autocomplete", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "fetch-suggestions": function (queryString, cb) {
                          return _vm.querySearch(queryString, cb, "newList")
                        },
                        placeholder: "请输入内容",
                        disabled: "",
                      },
                      on: {
                        select: function (e) {
                          return _vm.handleSelect(e, "serialNumber")
                        },
                      },
                      model: {
                        value: _vm.form.serialNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "serialNumber", $$v)
                        },
                        expression: "form.serialNumber",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "产品编号",
                      "label-width": _vm.formLabelWidth,
                      prop: "productNumber",
                    },
                  },
                  [
                    _c("el-autocomplete", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "fetch-suggestions": function (queryString, cb) {
                          return _vm.querySearch(queryString, cb, "proList")
                        },
                        placeholder: "请输入内容",
                        disabled: "",
                      },
                      on: {
                        select: function (e) {
                          return _vm.handleSelect(e, "productNumber")
                        },
                      },
                      model: {
                        value: _vm.form.productNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "productNumber", $$v)
                        },
                        expression: "form.productNumber",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "重量",
                      "label-width": _vm.formLabelWidth,
                      prop: "weight",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "4",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.weight,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "weight", $$v)
                        },
                        expression: "form.weight",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "价格中文",
                      "label-width": _vm.formLabelWidth,
                      prop: "priceCn",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.priceCn,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "priceCn", $$v)
                        },
                        expression: "form.priceCn",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "价格中文单位",
                      "label-width": _vm.formLabelWidth,
                      prop: "priceCnUnit",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.priceCnUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "priceCnUnit", $$v)
                          },
                          expression: "form.priceCnUnit",
                        },
                      },
                      _vm._l(_vm.price, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "价格英文",
                      "label-width": _vm.formLabelWidth,
                      prop: "priceEn",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.priceEn,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "priceEn", $$v)
                        },
                        expression: "form.priceEn",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "价格英文单位",
                      "label-width": _vm.formLabelWidth,
                      prop: "priceEnUnit",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.priceEnUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "priceEnUnit", $$v)
                          },
                          expression: "form.priceEnUnit",
                        },
                      },
                      _vm._l(_vm.price, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "价格日文",
                      "label-width": _vm.formLabelWidth,
                      prop: "priceJa",
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.priceJa,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "priceJa", $$v)
                        },
                        expression: "form.priceJa",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "价格日文单位",
                      "label-width": _vm.formLabelWidth,
                      prop: "priceJaUnit",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.priceJaUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "priceJaUnit", $$v)
                          },
                          expression: "form.priceJaUnit",
                        },
                      },
                      _vm._l(_vm.price, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "commont" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "色块图(32*32)",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _c("el-color-picker", {
                          model: {
                            value: _vm.form.colourUrl,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "colourUrl", $$v)
                            },
                            expression: "form.colourUrl",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "配件选择图",
                          "label-width": _vm.formLabelWidth,
                        },
                      },
                      [
                        _c(
                          "el-upload",
                          {
                            staticClass: "avatar-uploader",
                            attrs: {
                              action:
                                "https://jsonplaceholder.typicode.com/posts/",
                              "show-file-list": false,
                              "before-upload": _vm.beforeUploadPicture2,
                            },
                          },
                          [
                            _vm.imageUrl2
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.imageUrl2 },
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠类型",
                      "label-width": _vm.formLabelWidth,
                      prop: "type",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      },
                      _vm._l(_vm.types, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.label, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠值中文",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.valueCn,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "valueCn", $$v)
                        },
                        expression: "form.valueCn",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠值英文",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.valueEn,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "valueEn", $$v)
                        },
                        expression: "form.valueEn",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠值日文",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.valueJa,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "valueJa", $$v)
                        },
                        expression: "form.valueJa",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠折扣中文",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.discountCn,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "discountCn", $$v)
                        },
                        expression: "form.discountCn",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠折扣英文",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.discountEn,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "discountEn", $$v)
                        },
                        expression: "form.discountEn",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠折扣日文",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: {
                        oninput:
                          "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                        maxlength: "7",
                        autocomplete: "off",
                      },
                      model: {
                        value: _vm.form.discountJa,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "discountJa", $$v)
                        },
                        expression: "form.discountJa",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "首页显示",
                      "label-width": _vm.formLabelWidth,
                      prop: "homeFlag",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.homeFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "homeFlag", $$v)
                          },
                          expression: "form.homeFlag",
                        },
                      },
                      _vm._l(_vm.homeFlag, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.type },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "产品型号",
                      "label-width": _vm.formLabelWidth,
                      prop: "productType",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { disabled: "", placeholder: "请选择" },
                        model: {
                          value: _vm.form.productType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "productType", $$v)
                          },
                          expression: "form.productType",
                        },
                      },
                      _vm._l(_vm.type, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.valueCn, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "是否刻字",
                      "label-width": _vm.formLabelWidth,
                      prop: "letteringFlag",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.form.letteringFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "letteringFlag", $$v)
                          },
                          expression: "form.letteringFlag",
                        },
                      },
                      _vm._l(_vm.letterFlag, function (item) {
                        return _c("el-option", {
                          key: item.value,
                          attrs: { label: item.name, value: item.type },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠开始时间",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "default-time": "00:00:00",
                        "value-format": " yyyy-MM-dd HH:mm:ss",
                        format: "yyyy-MM-dd HH:mm:ss",
                        type: "datetime",
                        editable: false,
                        placeholder: "选择日期时间",
                      },
                      model: {
                        value: _vm.form.startTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startTime", $$v)
                        },
                        expression: "form.startTime",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "优惠结束时间",
                      "label-width": _vm.formLabelWidth,
                    },
                  },
                  [
                    _c("el-date-picker", {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "default-time": "23:59:59",
                        "value-format": " yyyy-MM-dd HH:mm:ss",
                        format: "yyyy-MM-dd HH:mm:ss",
                        type: "datetime",
                        editable: false,
                        placeholder: "选择日期时间",
                      },
                      model: {
                        value: _vm.form.endTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endTime", $$v)
                        },
                        expression: "form.endTime",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }