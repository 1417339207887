"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aloneEmail = aloneEmail;
exports.dataManipulation = dataManipulation;
exports.disposeEmail = disposeEmail;
exports.exportemail = exportemail;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//获取订单信息
function getLists(data) {
  return (0, _request.default)({
    url: '/cms/contact/email/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//处理邮箱查询接口
function disposeEmail(id) {
  return (0, _request.default)({
    url: '/cms/deal/contact/email/' + id,
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//根据ID邮箱查询
function aloneEmail(id) {
  return (0, _request.default)({
    url: '/cms/contact/email/' + id,
    method: 'GET',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//导出
function exportemail(data) {
  return (0, _request.default)({
    url: '/cms/export/email',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data,
    responseType: 'blob'
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, status, updateTime, updateUser) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (status != null && status != "") {
        element.status = status;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
      if (updateUser != null && updateUser != "") {
        element.updateUser = updateUser;
      }
    }
  });
  return tableData;
}