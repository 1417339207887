"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _app = require("../../api/app.js");
var _index = require("../../utils/index.js");
var _upload = require("../../api/upload.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      value: "",
      tableData: [],
      tableData1: [],
      activeName: "first",
      formLabelWidth: "120px",
      dialogFormVisible: false,
      faq: [],
      dialogFormVisibles: false,
      buttonUpload: "上传",
      buttonUploads: "上传",
      showsize: '',
      showsizes: '',
      loading: false,
      loadings: false,
      isDisable: false,
      flag: false,
      loading1: false,
      addform: {
        descriptionCn: '',
        descriptionEn: '',
        descriptionJa: '',
        qrCodeUrl: '',
        size: '',
        thumbnailUrl: '',
        titleCn: '',
        titleEn: '',
        titleJa: '',
        urlApk: '',
        urlIpa: '',
        version: ''
      },
      addRules: (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)({
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        qrCodeUrl: [{
          required: true,
          trigger: "blur"
        }],
        thumbnailUrl: [{
          required: true,
          trigger: "blur"
        }],
        urlApk: [{
          required: true,
          trigger: "blur"
        }]
      }, "titleEn", [{
        required: true,
        trigger: "blur"
      }]), "urlIpa", [{
        required: true,
        trigger: "blur"
      }]), "version", [{
        required: true,
        trigger: "blur"
      }]),
      changeform: {
        descriptionCn: '',
        descriptionEn: '',
        descriptionJa: '',
        qrCodeUrl: '',
        thumbnailUrl: '',
        titleCn: '',
        titleEn: '',
        titleJa: ''
      },
      changeRules: {
        titleCn: [{
          required: true,
          trigger: "blur"
        }],
        titleEn: [{
          required: true,
          trigger: "blur"
        }],
        titleJa: [{
          required: true,
          trigger: "blur"
        }],
        descriptionCn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionEn: [{
          required: true,
          trigger: "blur"
        }],
        descriptionJa: [{
          required: true,
          trigger: "blur"
        }],
        qrCodeUrl: [{
          required: true,
          trigger: "blur"
        }],
        thumbnailUrl: [{
          required: true,
          trigger: "blur"
        }]
      },
      changeid: "",
      form: {
        line: "",
        area: "",
        pageNo: 1,
        pageSize: 10
      },
      forms: {
        line: "",
        area: "",
        pageNo: 1,
        pageSize: 10
      },
      option: [{
        type: 0,
        name: "上线"
      }, {
        type: 1,
        name: "预览"
      }, {
        type: 2,
        name: "下线"
      }],
      areas: [{
        type: 1,
        name: "中国区"
      }, {
        type: 2,
        name: "非中国区"
      }],
      total: 0,
      currentPage: 1,
      isShow: false,
      imageUrl1: '',
      imageUrl2: ''
    };
  },
  ceated: function ceated() {},
  mounted: function mounted() {
    this.getList();
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //请求列表数据
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _app.getLists)(_this.forms).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.forms.pageNo = 1;
            _this.currentPage = 1;
            (0, _app.getLists)(_this.forms).then(function (res) {
              _this.tableData = res.voList;
              _this.total = res.total;
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = res.total;
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    handleClick: function handleClick(tab, event) {
      console.log(tab, event);
    },
    //判断上下线
    state: function state(row) {
      if (row.line === "0") {
        return "上线";
      } else if (row.line == '1') {
        return "预览";
      } else {
        return "下线";
      }
    },
    areaType: function areaType(row) {
      if (row.area === "1") {
        return "中国区";
      } else {
        return "非中国区";
      }
    },
    //查询
    search: function search() {
      this.forms.pageNo = 1;
      this.currentPage = 1;
      this.forms.line = this.form.line;
      this.forms.area = this.form.area;
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.line = "";
      this.form.area = "";
      this.currentPage = 1;
      this.currentPage = 1;
      this.forms.line = "";
      this.forms.area = "";
      this.forms.pageNo = 1;
      this.getList();
    },
    //新增
    add: function add() {
      //   this.dialogFormVisible = true;
      this.$router.push({
        path: "addapp"
      });
    },
    //修改序号
    changeSeq: function changeSeq(e) {
      var url = (0, _index.stopClick)("/faq/title/seq");
      if (url) {
        this.isShow = e.id;
      }
    },
    bindSeq: function bindSeq(val) {
      var _this2 = this;
      if (val.seq == "") {
        this.msgTip("warning", "序号不能为空");
      } else if (!/(^[1-9]\d*$)/.test(val.seq)) {
        this.msgTip("warning", "序号只能为正整数");
      } else {
        changeSeq({
          seq: val.seq
        }, val.id).then(function (res) {
          if (res.success) {
            _this2.msgTip("success", "修改成功");
            _this2.isShow = false;
            _this2.getList();
          } else {
            _this2.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    },
    //修改
    change: function change(id) {
      this.$router.push({
        path: "changeapp",
        query: {
          id: id
        }
      });
      //   this.dialogFormVisibles = true;
      //   this.changeid = id;
      //   searchList(id).then(res => {
      //     if (res.success) {
      //       this.changeform = res.voT;
      //       this.imageUrl1 = res.voT.thumbnailUrl
      //       this.imageUrl2 = res.voT.qrCodeUrl
      //     }
      //   });
    },
    //修改关闭
    changeClose: function changeClose() {
      this.dialogFormVisibles = false;
      this.$refs.changeform.resetFields();
    },
    //修改确定
    keep: function keep() {
      var _this3 = this;
      this.$refs.changeform.validate(function (valid) {
        if (valid) {
          // 表单验证通过之后的操作
          // this.isRepeat = true;
          _this3.isDisable = false;
          _this3.changeform.qrCodeUrl = _this3.imageUrl2;
          _this3.changeform.thumbnailUrl = _this3.imageUrl1;
          (0, _app.changeList)(_this3.changeform, _this3.changeid).then(function (res) {
            if (res.success) {
              _this3.msgTip("success", "修改成功");
              _this3.dialogFormVisibles = false;
              _this3.getList();
              dataManipulation(_this3.tableData);
            } else {
              _this3.msgTip("error", res.errorMsg);
              // this.isRepeat = false;
              _this3.isDisable = true;
            }
          });
        } else {
          _this3.msgTip("warning", "请填写完整");
          return false;
        }
      });
    },
    //上线
    online: function online(row) {
      var _this4 = this;
      if (row.line == "0") {
        this.msgTip("warning", "该数据已上线");
      } else {
        (0, _app.changeOn)({
          line: 0
        }, row.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "上线成功");
            _this4.getList();
          } else {
            _this4.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //预览
    preview: function preview(row) {
      var _this5 = this;
      if (row.line == '1') {
        this.msgTip("warning", "该数据已预览");
      } else {
        (0, _app.changeOn)({
          line: 1
        }, row.id).then(function (res) {
          if (res.success) {
            _this5.msgTip("success", "操作成功");
            _this5.getList();
          } else {
            _this5.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //下线
    offline: function offline(row) {
      var _this6 = this;
      if (row.line == "2") {
        this.msgTip("warning", "该数据已下线");
      } else {
        (0, _app.changeOn)({
          line: 2
        }, row.id).then(function (res) {
          if (res.success) {
            _this6.msgTip("success", "下线成功");
            _this6.getList();
          } else {
            _this6.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //删除
    dele: function dele(id) {
      var _this7 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _app.deleList)(id).then(function (res) {
          if (res.success) {
            _this7.msgTip("success", "删除成功");
            _this7.getList();
          } else {
            _this7.msgTip("error", res.errorMsg);
          }
        });
      });
    },
    changes: function changes(limit) {
      var size = "";
      if (limit < 0.1 * 1024) {
        //小于0.1KB，则转化成B
        size = limit.toFixed(2) + "B";
      } else if (limit < 0.1 * 1024 * 1024) {
        //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + "KB";
      } else if (limit < 0.1 * 1024 * 1024 * 1024) {
        //小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      var sizeStr = size + ""; //转成字符串
      var index = sizeStr.indexOf("."); //获取小数点处的索引
      var dou = sizeStr.substr(index + 1, 2); //获取小数点后两位的值
      if (dou == "00") {
        //判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2);
      }
      return size;
    }
  }
};