var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addgallery" },
    [
      _c(
        "el-form",
        {
          ref: "addform",
          attrs: {
            model: _vm.addform,
            rules: _vm.addRules,
            "status-icon": true,
            "show-message": false,
            "hide-required-asterisk": "",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "序号",
                "label-width": _vm.formLabelWidth,
                prop: "seq",
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: "50", autocomplete: "off", type: "text" },
                model: {
                  value: _vm.addform.seq,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "seq", $$v)
                  },
                  expression: "addform.seq",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "数量",
                "label-width": _vm.formLabelWidth,
                prop: "count",
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: "50", autocomplete: "off", type: "text" },
                model: {
                  value: _vm.addform.count,
                  callback: function ($$v) {
                    _vm.$set(_vm.addform, "count", $$v)
                  },
                  expression: "addform.count",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "多图",
                "label-width": _vm.formLabelWidth,
                prop: "count",
              },
            },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    accept: ".png, .gif, .jpg, .jpeg",
                    action: _vm.uploadUrl,
                    headers: _vm.uplaod_data,
                    "file-list": _vm.fileList,
                    "list-type": "picture-card",
                    "on-success": _vm.handleUploadSuccess,
                    "on-remove": _vm.handleRemoves,
                    "on-preview": _vm.changePicture,
                  },
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: { visible: _vm.dialogVisible },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                    close: _vm.close,
                  },
                },
                [
                  _c("img", {
                    attrs: { width: "300px", src: _vm.dialogImageUrl, alt: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                      },
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "upload-demo",
                          attrs: {
                            action: _vm.uploadUrl,
                            headers: _vm.uplaod_data,
                            "on-success": _vm.uploadSuccess,
                            "show-file-list": false,
                            "on-remove": _vm.handleRemove,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { size: "small", type: "primary" },
                            },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn" },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submit } },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }