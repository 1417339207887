"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.array.find-index");
require("core-js/modules/es6.regexp.split");
var _upload = require("../../api/upload.js");
var _gallery = require("../../api/gallery.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      addform: {
        seq: "",
        count: "",
        mutiUrl: '',
        line: "",
        id: ""
      },
      addRules: {
        seq: [{
          required: true,
          trigger: "blur"
        }],
        count: [{
          required: true,
          trigger: "blur"
        }]
      },
      formLabelWidth: "120px",
      multiple: true,
      uploadUrl: "".concat(process.env.VUE_APP_BASE_API, "cms/picture/upload"),
      uplaod_data: {
        "x-access-token": window.localStorage.getItem("token")
      },
      fileList: [],
      dialogVisible: false,
      fileupload: "",
      dialogImageUrl: "",
      count: 0,
      id: "",
      fileLists: [],
      url: '',
      fileList1: [],
      changeimage: ''
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.id = this.$route.query.id;
    (0, _gallery.alonegallery)(this.id).then(function (res) {
      if (res.success) {
        _this.addform = res.voT;
        _this.fileList = res.voT.mutiUrlList.map(function (v) {
          _this.url = v.split('===')[0];
          return {
            name: "",
            url: _this.url,
            href: v
          };
        });
        console.log(_this.fileList);
      }
    });
  },
  methods: {
    handleUploadSuccess: function handleUploadSuccess(res, file, fileList) {
      this.fileList = fileList;
    },
    handleRemoves: function handleRemoves(file, fileList) {
      var _this2 = this;
      var mutiUrls = [];
      this.fileList = fileList;
      this.fileList.map(function (res) {
        mutiUrls.push(res.response.voT.downloadUrl);
        _this2.addform.mutiUrl = mutiUrls.join(",");
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      var str = [];
      this.fileList.map(function (item) {
        if (item.url !== file.url) {
          str.push(item);
        }
      });
      this.fileList = str;
    },
    changePicture: function changePicture(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
      this.fileupload = this.fileList.findIndex(function (v) {
        return v.uid === file.uid;
      });
    },
    uploadSuccess: function uploadSuccess(res, file) {
      file.url = res.voT.downloadUrl.split('===')[0];
      file.href = res.voT.downloadUrl;
      this.fileList.splice(this.fileupload, 1, file);
      console.log(this.fileList, '上传');
      this.dialogImageUrl = file.url;
      this.dialogVisible = false;
    },
    close: function close() {
      this.dialogVisible = false;
    },
    submit: function submit() {
      var _this3 = this;
      if (this.fileList == [] && this.addform.mutiUrl == "") {
        this.msgTip("warning", "请上传图片");
        return false;
      } else {
        this.$refs.addform.validate(function (valid) {
          if (valid) {
            var str = [];
            _this3.fileList.map(function (item) {
              if (item.response) {
                str.push(item.response.voT.downloadUrl);
              } else {
                str.push(item.href);
              }
            });
            // if(this.fileLists.length > 0){
            //   this.fileLists.map((item) => {
            //        str.push(item);
            //   });
            // }
            console.log(str, '123');
            _this3.addform.mutiUrl = str.toString();
            (0, _gallery.changegallery)(_this3.addform, _this3.id).then(function (item) {
              if (item.success) {
                _this3.msgTip("success", "修改成功");
                _this3.$store.dispatch("tagsView/delView", _this3.$route).then(function () {
                  _this3.$nextTick(function () {
                    _this3.$router.replace({
                      path: "/gallery/gallerys"
                    });
                  });
                });
              } else {
                _this3.msgTip("error", item.errorMsg);
              }
            });
          }
        });
      }
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};