"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addList = addList;
exports.alonepresell = alonepresell;
exports.changepresell = changepresell;
exports.delePresell = delePresell;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询预售信息(cms系统调用)
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/presell/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加预售信息
function addList(data) {
  return (0, _request.default)({
    url: 'cms/presell',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改预售
function changepresell(id, data) {
  return (0, _request.default)({
    url: 'cms/presell/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//查询单个预售信息
function alonepresell(id) {
  return (0, _request.default)({
    url: 'cms/presell/' + id,
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//删除产品页详情接口
function delePresell(id) {
  return (0, _request.default)({
    url: 'cms/presell/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}