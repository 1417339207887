"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var echarts = _interopRequireWildcard(require("echarts"));
//
//
//
//

require("echarts/theme/macarons"); // echarts theme
// import resize from './mixins/resize'

var animationDuration = 6000;
// import {monthNum} from '../../api/charts.js'
var _default = exports.default = {
  //   mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "97%"
    },
    height: {
      type: String,
      default: "500px"
    },
    yearArr: {
      type: Array
      // default: []
    }
    // month: {
    //     type: Object,
    //     default: () => {
    //         return {}
    //     }
    // }
  },
  watch: {
    yearArr: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(val, oldVal) {
        var _this = this;
        var productList = [];
        var countList = [];
        var date = new Date();
        val.map(function (item) {
          productList.push(item.time);
          countList.push(item.count);
          _this.chart.setOption({
            title: {
              text: '月份-销量图表',
              textStyle: {
                color: '#8bcd7a'
              },
              left: '20px'
            },
            xAxis: [{
              type: "category",
              data: productList,
              axisLabel: {
                interval: 0,
                rotate: 0
              }
            }],
            yAxis: [{
              type: "value",
              axisTick: {
                show: false
              }
            }],
            series: [{
              type: "bar",
              barWidth: "20%",
              data: countList,
              animationDuration: animationDuration,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    //开启显示
                    position: 'top' //在上方显示
                  },
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: '#83bff6'
                  }, {
                    offset: 0.5,
                    color: '#8bcd7b'
                  }, {
                    offset: 1,
                    color: '#8bcd7a'
                  }])
                }
              }
            }]
          });
        });
      },
      deep: true //true 深度监听
    }
  },
  data: function data() {
    return {
      chart: null,
      form: {
        dealer: "",
        year: ""
      }
    };
  },
  mounted: function mounted() {
    var _this2 = this;
    this.$nextTick(function () {
      _this2.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      var _this3 = this;
      this.chart = echarts.init(this.$el);
      var productList = [];
      var countList = [];
      this.yearArr.map(function (item) {
        productList.push(item.time);
        countList.push(item.count);
        _this3.chart.setOption({
          title: {
            text: '月份-销量图表',
            textStyle: {
              color: '#8bcd7a'
            },
            left: '20px'
          },
          xAxis: [{
            type: "category",
            data: productList,
            axisLabel: {
              interval: 0,
              rotate: 0
            }
          }],
          yAxis: [{
            type: "value",
            axisTick: {
              show: false
            }
          }],
          series: [{
            //   name: 'pageA',
            type: "bar",
            //   stack: 'vistors',
            barWidth: "15%",
            data: countList,
            animationDuration: animationDuration,
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  //开启显示
                  position: 'top' //在上方显示
                },
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#83bff6'
                }, {
                  offset: 0.5,
                  color: '#8bcd7b'
                }, {
                  offset: 1,
                  color: '#8bcd7a'
                }])
              }
            }
          }]
        });
      });
      // })
    }
  }
};