var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "allcharts" }, [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "month" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 30 } },
              [
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "grid-content" }, [
                    _c("div", [
                      _c("img", {
                        staticStyle: { width: "85px", padding: "8px 0" },
                        attrs: {
                          src: require("../../assets/img/totalsales.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "tit" }, [
                        _vm._v("日 / 月销量（单）"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.orderCounts)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "grid-content" }, [
                    _c("div", [
                      _c("img", {
                        staticStyle: { width: "85px" },
                        attrs: {
                          src: require("../../assets/img/sale.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "tit" }, [
                        _vm._v("日 / 月销售额（￥）"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.totalSalePrice)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-col", { attrs: { span: 8 } }, [
                  _c("div", { staticClass: "grid-content" }, [
                    _c("div", [
                      _c("img", {
                        staticStyle: { width: "80px" },
                        attrs: {
                          src: require("../../assets/img/average.png"),
                          alt: "",
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("div", { staticClass: "tit" }, [
                        _vm._v("日 / 月平均销售额（￥）"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "count" }, [
                        _vm._v(_vm._s(_vm.averageSalePrice)),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "head", staticStyle: { background: "#fff" } },
              [
                _c("ul", [
                  _c(
                    "li",
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "month",
                          "value-format": "yyyy-MM",
                          format: "yyyy-MM",
                          placeholder: "时间",
                        },
                        on: { change: _vm.find },
                        model: {
                          value: _vm.day.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.day, "date", $$v)
                          },
                          expression: "day.date",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("dayChart", {
              ref: "children",
              staticClass: "day",
              attrs: { dayArr: _vm.dayArr },
            }),
            _vm._v(" "),
            _c("volumeChart", {
              staticClass: "volume",
              attrs: { volumeArr: _vm.volumeArr },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "month" }, [
          _c("div", { staticClass: "head" }, [
            _c("ul", [
              _c(
                "li",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%", "z-index": "999" },
                      attrs: { clearable: "", placeholder: "渠道" },
                      on: { change: _vm.searchs },
                      model: {
                        value: _vm.month.dealer,
                        callback: function ($$v) {
                          _vm.$set(_vm.month, "dealer", $$v)
                        },
                        expression: "month.dealer",
                      },
                    },
                    _vm._l(_vm.dealers, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.valueCn, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "year",
                      "value-format": "yyyy",
                      format: "yyyy",
                      placeholder: "时间",
                    },
                    on: { change: _vm.searchs },
                    model: {
                      value: _vm.month.years,
                      callback: function ($$v) {
                        _vm.$set(_vm.month, "years", $$v)
                      },
                      expression: "month.years",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("monthCharts", {
                ref: "children",
                staticStyle: { "margin-top": "-40px" },
                attrs: { yearArr: _vm.yearArr },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              background: "#fff",
              "margin-left": "20px",
              "margin-right": "20px",
            },
          },
          [
            _c("div", { staticClass: "head" }, [
              _c("ul", [
                _c(
                  "li",
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100%", "z-index": "99" },
                        attrs: { clearable: "", placeholder: "渠道" },
                        on: { change: _vm.searchInfo },
                        model: {
                          value: _vm.form.dealer,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dealer", $$v)
                          },
                          expression: "form.dealer",
                        },
                      },
                      _vm._l(_vm.dealers, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.valueCn, value: item.value },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        placeholder: "起始时间",
                      },
                      on: { change: _vm.searchInfo },
                      model: {
                        value: _vm.form.startPayTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startPayTime", $$v)
                        },
                        expression: "form.startPayTime",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "li",
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        placeholder: "结束时间",
                      },
                      on: { change: _vm.searchInfo },
                      model: {
                        value: _vm.form.endPayTime,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "endPayTime", $$v)
                        },
                        expression: "form.endPayTime",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "flex", "margin-right": "20px" } },
              [
                _c("country", {
                  staticClass: "country",
                  staticStyle: { width: "50%" },
                  attrs: { countryArr: _vm.countryArr },
                }),
                _vm._v(" "),
                _c("country-table", {
                  staticClass: "country",
                  staticStyle: { width: "50%" },
                  attrs: { countryArr: _vm.countryArr },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "sale" }, [
            _c(
              "div",
              { staticClass: "productcharts" },
              [
                _c("productNums", {
                  staticClass: "productNum",
                  attrs: { productArr: _vm.productArr },
                }),
                _vm._v(" "),
                _c("exchangeChart", {
                  staticClass: "exchange",
                  attrs: { productArr: _vm.productArr },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "productcharts",
                staticStyle: { "margin-top": "20px", "padding-bottom": "20px" },
              },
              [
                _c("returnChart", { attrs: { productArr: _vm.productArr } }),
                _vm._v(" "),
                _c("repair-chart", { attrs: { productArr: _vm.productArr } }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "el-row",
          { attrs: { gutter: 30 } },
          [
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c("div", [
                  _c("img", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      src: require("../../assets/img/repertory.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "tit" }, [_vm._v("总库存数")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.stockCounts)),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c("div", [
                  _c("img", {
                    staticStyle: { width: "85px", padding: "8px 0" },
                    attrs: {
                      src: require("../../assets/img/user.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "tit" }, [_vm._v("总用户数")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.user)),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 4 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c("div", [
                  _c("img", {
                    staticStyle: { width: "100px" },
                    attrs: {
                      src: require("../../assets/img/activate.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "tit" }, [_vm._v("总激活数")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.activeCounts)),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c("div", [
                  _c("img", {
                    staticStyle: { width: "85px", padding: "8px 0" },
                    attrs: {
                      src: require("../../assets/img/dynamicUser.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "tit" }, [_vm._v("总活跃数")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.active)),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("el-col", { attrs: { span: 5 } }, [
              _c("div", { staticClass: "grid-content" }, [
                _c("div", [
                  _c("img", {
                    staticStyle: { width: "85px", padding: "8px 0" },
                    attrs: {
                      src: require("../../assets/img/dynamic.png"),
                      alt: "",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "tit" }, [_vm._v("活跃率")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "count" }, [
                    _vm._v(_vm._s(_vm.activeRate) + "%"),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "show" },
          [
            _c("box-card", { staticClass: "barGraph" }),
            _vm._v(" "),
            _c("raddarChart", {
              staticClass: "barGraph",
              staticStyle: { "padding-bottom": "35px" },
            }),
            _vm._v(" "),
            _c("barGraph", { staticClass: "barGraph" }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "global" }, [
      _c("div", { staticClass: "head" }, [
        _c("ul", [
          _c(
            "li",
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy-MM-dd",
                  placeholder: "起始时间",
                },
                on: { change: _vm.globalSearch },
                model: {
                  value: _vm.global.startOpTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.global, "startOpTime", $$v)
                  },
                  expression: "global.startOpTime",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy-MM-dd",
                  placeholder: "结束时间",
                },
                on: { change: _vm.globalSearch },
                model: {
                  value: _vm.global.endOpTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.global, "endOpTime", $$v)
                  },
                  expression: "global.endOpTime",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { display: "flex" } },
        [
          _c("globalChart", {
            staticStyle: { "margin-top": "-35px" },
            attrs: { globalArr: _vm.globalArr },
          }),
          _vm._v(" "),
          _c("modelChart", {
            staticClass: "modelChart",
            attrs: { rateArr: _vm.rateArr },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }