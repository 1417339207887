"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addUsermess = addUsermess;
exports.amendPasw = amendPasw;
exports.amendUsermess = amendUsermess;
exports.authorUsermess = authorUsermess;
exports.checkOldPwd = checkOldPwd;
exports.closeUsermess = closeUsermess;
exports.dataManipulation = dataManipulation;
exports.deleteUsermess = deleteUsermess;
exports.getCheckuser = getCheckuser;
exports.getIduser = getIduser;
exports.getUsermess = getUsermess;
exports.pasdUsermess = pasdUsermess;
exports.startUsermess = startUsermess;
exports.unblockUser = unblockUser;
exports.unblockUsermess = unblockUsermess;
exports.verifvPassword = verifvPassword;
var _request = _interopRequireDefault(require("@/utils/request"));
var _coreJs = require("core-js");
//查询用户信息
function getUsermess(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/role',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//添加用户
function addUsermess(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/add',
    method: 'post',
    data: data,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}

//批量授权接口
function authorUsermess(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/grant',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//批量启用
function startUsermess(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/open',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//批量停用
function closeUsermess(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/close',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改用户信息
function amendUsermess(id, data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//解锁用户信息
function unblockUsermess(id) {
  return (0, _request.default)({
    url: 'system/base/sys/user/unlock/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//用户解锁验证是否是自己接口
function unblockUser(id) {
  return (0, _request.default)({
    url: 'system/base/sys/user/erificationUnlock/' + id,
    method: 'get',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//删除用户信息
function deleteUsermess(id) {
  return (0, _request.default)({
    url: 'system/base/sys/user/' + id,
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    }
  });
}
//根据用户ID查询用户信息接口
function getIduser(id) {
  return (0, _request.default)({
    url: 'system/base/sys/user/' + id,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'get'
  });
}
function getCheckuser(id) {
  return (0, _request.default)({
    url: 'system/base/sys/user/role/' + id,
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'get'
  });
}

//修改密码
function pasdUsermess(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//用户标识无效时修改密码
function amendPasw(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/pwd',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'put',
    data: data
  });
}

//验证原密码是否正确
function checkOldPwd(data) {
  return (0, _request.default)({
    url: 'system/base/sys/user/check',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'post',
    data: data
  });
}
//验证登录的用户是否是可用
function verifvPassword() {
  return (0, _request.default)({
    url: 'system/base/sys/user/verifvPassword',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    method: 'get'
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, rowData, name, phone, email, updateUser, updateTime, status) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    rowData.map(function (row) {
      if (element.id == row.id) {
        if (name != null && name != "") {
          element.name = name;
        }
        if (phone != null && phone != "") {
          element.phone = phone;
        }
        if (email != null && email != "") {
          element.email = email;
        }
        if (updateUser != null && updateUser != "") {
          element.updateUser = updateUser;
        }
        if (updateTime != null && updateTime != "") {
          element.updateTime = updateTime;
        }
        if (status != null && status != "") {
          element.status = status;
        }
      }
    });
  });
  return tableData;
}