"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
require("core-js/modules/es6.array.find-index");
var _objectSpread2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
var _defineProperty2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _ordercustomer = require("../../api/ordercustomer.js");
var _orderInfo = require("../../api/orderInfo.js");
var _orderLogistics = require("../../api/orderLogistics.js");
var _dictionary = require("../../api/dictionary.js");
var _timers = require("timers");
var _addorder = require("../../api/addorder.js");
var _warrantyCard = require("../../api/warrantyCard.js");
var _vueBarcode = _interopRequireDefault(require("vue-barcode"));
var _vueQr = _interopRequireDefault(require("vue-qr"));
var _methods; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    barcode: _vueBarcode.default,
    VueQr: _vueQr.default
  },
  data: function data() {
    var _ref;
    return _ref = {
      form: {
        orderNo: "",
        pageNo: 1,
        pageSize: 20,
        statusList: [],
        type: "",
        checkStatus: "",
        customerNumber: "",
        telephone: "",
        orderSerial: "",
        customerSerial: "",
        dealer: "",
        involveProductType: "",
        endCreateTime: "",
        startCreateTime: "",
        payType: '',
        equipmentNumber: '',
        locationPosition: '',
        exchangeLocationPosition: '',
        createUser: '',
        dealerList: [],
        logisticsNumber: '',
        contactEmail: '',
        returnType: '',
        endReturnTime: "",
        startReturnTime: "",
        chanjetType: ""
      },
      involveProductTypes: [{
        valueCn: "是",
        value: "1"
      }, {
        valueCn: "否",
        value: "0"
      }],
      dealers: [],
      locationPositions: [],
      status: [],
      types: [],
      loading: false,
      isAmericaShow: false,
      isProvinceShow: true,
      tableData: [],
      idList: [],
      currentPage: 1,
      total: 0,
      dialogFormVisible: false,
      dialogFormVisibleRegister: false,
      formLabelWidth: "120px",
      tableDatas: [],
      datas: [],
      returnform: {
        orderNo: "",
        price: "",
        remark: "",
        returnType: "",
        locationPosition: "",
        orderLineCustomerList: [],
        freight: "",
        otherPrice: ""
      },
      registerform: {
        orderNo: "",
        logisticsName: "",
        logisticsNumber: "",
        logisticsUnit: "",
        logisticsPrice: "",
        logisticsTaxes: "",
        remark: "",
        returnType: "",
        locationPosition: ""
      },
      equipmentNumberList: [],
      returntypes: [],
      costTypes: [],
      returnrules: {
        orderNo: [{
          required: true,
          trigger: "blur"
        }],
        price: [{
          required: true,
          trigger: "blur"
        }],
        returnType: [{
          required: true,
          trigger: "blur"
        }],
        freight: [{
          required: true,
          trigger: "blur"
        }],
        otherPrice: [{
          required: true,
          trigger: "blur"
        }],
        locationPosition: [{
          required: true,
          trigger: "blur"
        }]
      },
      registerrules: {
        returnType: [{
          required: true,
          trigger: "blur"
        }],
        locationPosition: [{
          required: true,
          trigger: "blur"
        }]
      },
      returncount: "",
      returnproductNum: "",
      isDisable: false,
      //   orderLineCustomerList: [],
      equipmentNumber: "",
      titletype: "",
      payTypes: [],
      dialogFormVisibles: false,
      isShow: false,
      show: true,
      moredatas: [],
      loadings: false,
      dialogForm: false,
      logisticsform: {
        customerNumber: "",
        logisticsName: "",
        logisticsNumber: "",
        logisticsUnit: "",
        logisticsPrice: "",
        type: "1",
        exchangeEquipmentNumberList: [],
        exchangeLogisticsUnit: '',
        exchangeLogisticsPrice: ''
      },
      logisticsrule: {
        customerNumber: [{
          required: true,
          trigger: "blur"
        }],
        logisticsName: [{
          required: true,
          trigger: "blur"
        }],
        logisticsNumber: [{
          required: true,
          trigger: "blur"
        }],
        logisticsUnit: [{
          required: true,
          trigger: "blur"
        }],
        logisticsPrice: [{
          required: true,
          trigger: "blur"
        }],
        type: [{
          required: true,
          trigger: "blur"
        }],
        exchangeLogisticsUnit: [{
          required: true,
          trigger: "blur"
        }],
        exchangeLogisticsPrice: [{
          required: true,
          trigger: "blur"
        }]
      },
      isequiment: "",
      dialogformed: false,
      checkstatus: [{
        valueCn: "通过",
        value: "1"
      }, {
        valueCn: "不通过",
        value: "0"
      }],
      checkform: {
        customerNumber: "",
        checkStatus: "",
        checkNopassReason: ""
      },
      checkrule: {
        customerNumber: [{
          required: true,
          trigger: "blur"
        }],
        checkStatus: [{
          required: true,
          trigger: "change"
        }],
        checkNopassReason: [{
          required: false,
          trigger: "blur"
        }],
        price: [{
          required: true,
          trigger: "blur"
        }]
      },
      unit: "",
      count: "",
      counts: "",
      multipleSelection: [],
      orderPrice: "",
      dialogFormPrint: false,
      dialogFormPrints: false,
      printrules: [],
      countrys: [],
      provinceNames: [],
      edit: false,
      edits: false,
      description: "",
      prod: "市场部",
      accountloading: false,
      orderLineContainsCustomerList: [],
      repairCounts: "",
      nextflag: null,
      dialogFormCustomer: false,
      Customerform: {
        orderSerial: "",
        customerSerial: "",
        customerNumber: "",
        type: "",
        unit: "",
        price: "",
        remark: ""
      },
      productList: [],
      Customerrule: {
        customerNumber: [{
          required: true,
          trigger: "blur"
        }],
        remark: [{
          required: false,
          trigger: "change"
        }],
        price: [{
          required: true,
          trigger: "change"
        }]
      },
      dialogChangeForm: false,
      dialogMoneyVisible: false,
      dialogWarrantyCard: false,
      moneyform: {
        // deliverType: "1",
        // involveProductType: "0",
        orderNo: "",
        payType: "",
        price: "",
        remark: "",
        orderLineCustomerList: []
      },
      moneycount: "",
      product: "1",
      typeflag: true,
      penflag: false,
      coverflag: false,
      type: [],
      jacket: [],
      isProduct: true,
      productName: "",
      productNo: "",
      productNos: "",
      showflag: false,
      moneyrules: {
        deliverType: [{
          required: true,
          trigger: "blur"
        }],
        involveProductType: [{
          required: true,
          trigger: "blur"
        }],
        orderNo: [{
          required: true,
          trigger: "blur"
        }],
        price: [{
          required: true,
          trigger: "blur"
        }],
        payType: [{
          required: true,
          trigger: "blur"
        }]
      },
      warrantyCardflag: false,
      warrantyCardform: {
        equipmentNo: ""
      },
      warrantyCardList: [],
      equipmentNo: "",
      warrantyCardrules: {
        equipmentNo: [{
          required: true,
          trigger: "blur"
        }]
      },
      chanjetTypeCode: [{
        value: "ND",
        valueCn: "不上送"
      }, {
        value: "Y",
        valueCn: "已上送"
      }, {
        value: "N",
        valueCn: "未上送"
      }],
      changeform: {
        address: "",
        locationPosition: "",
        exchangeLocationPosition: "",
        costType: "",
        customerNumber: "",
        customerRequest: "",
        deliverType: "",
        exchangeLogisticsName: "",
        exchangeLogisticsNumber: "",
        exchangeLogisticsPrice: "",
        exchangeLogisticsUnit: "",
        involveProductType: "",
        logisticsName: "",
        logisticsNumber: "",
        logisticsPrice: "",
        logisticsUnit: "",
        price: "",
        remark: "",
        returnType: "",
        telephone: "",
        contactEmail: "",
        freight: "",
        otherPrice: "",
        receiveName: '',
        logisticsTaxes: ''
      },
      returnTypes: []
    }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "locationPositions", []), "deliverTypes", [{
      value: "0",
      valueCn: "已发货"
    }, {
      value: "1",
      valueCn: "未发货"
    }]), "costTypes", []), "customerRequests", []), "involveProductTypes", [{
      value: "0",
      valueCn: "是"
    }, {
      value: "1",
      valueCn: "否"
    }]), "logisticsUnits", [{
      value: "RMB",
      label: "RMB"
    }, {
      value: "USD",
      label: "USD"
    }, {
      value: "JPY",
      label: "JPY"
    }]), "returnLogisticsUnits", [{
      value: "RMB",
      label: "RMB"
    }, {
      value: "USD",
      label: "USD"
    }, {
      value: "JPY",
      label: "JPY"
    }, {
      value: "EUR",
      label: "EUR"
    }]), "exchangeLogisticsUnits", [{
      value: "RMB",
      label: "RMB"
    }, {
      value: "USD",
      label: "USD"
    }, {
      value: "JPY",
      label: "JPY"
    }]), "logisticsName", ''), "logisticsNumber", ''), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "logisticsUnit", ''), "logisticsPrice", ''), "registerproductNum", ""), "printform", {
      address: "",
      locationPosition: "",
      exchangeLocationPosition: "",
      administrationReview: "",
      administrationReviewTime: "",
      costType: "",
      createTime: "",
      dutyType: "",
      customerExchangeDetailList: [],
      customerNumber: "",
      customerRequest: "",
      customerReturnDetailList: [],
      customerReview: "",
      customerReviewTime: "",
      dealer: "",
      deliverDate: "",
      deliverType: "",
      exchangeLogisticsName: "",
      exchangeLogisticsNumber: "",
      exchangeLogisticsPrice: "",
      financeReview: "",
      financeReviewTime: "",
      involveProductType: "",
      logisticsName: "",
      logisticsNumber: "",
      logisticsPrice: "",
      orderNo: "",
      orderPrice: "",
      orderSerialNumber: "",
      payTime: "",
      payType: "",
      price: "",
      receiveName: "",
      remark: "",
      returnType: "",
      serialNumber: "",
      status: "",
      telephone: "",
      type: "",
      serialNo: '',
      tradeNo: '',
      contactEmail: '',
      administrationReviewResult: '',
      financeReviewResult: '',
      logisticsTaxes: '',
      updateTime: ''
    }), "ordertitle", ""), "id", ""), "checkFlag", false), "reviewResult", "0"), "salesform", {
      costType: "",
      customerRequest: "",
      deliverType: "0",
      exchangeCustomerList: [],
      orderLineCustomerList: [],
      price: "0",
      orderNo: "",
      remark: "",
      returnType: "",
      locationPosition: "",
      exchangeLocationPosition: "",
      count: "",
      unit: "",
      productNo: "",
      productNos: "",
      cont: "",
      telephone: "",
      contactEmail: "",
      name: "",
      address: "",
      countryId: "",
      country: "",
      countryCode: "",
      cityPostalCode: "",
      province: "",
      provinceName: "",
      city: "",
      position: '',
      equipmentNumber: ''
    }), "salsesRule", {
      costType: [{
        required: true,
        trigger: "change"
      }],
      name: [{
        required: true,
        trigger: "change"
      }],
      telephone: [{
        required: true,
        trigger: "change"
      }],
      contactEmail: [{
        required: false,
        trigger: "change"
      }],
      address: [{
        required: true,
        trigger: "change"
      }],
      returnType: [{
        required: true,
        trigger: "change"
      }],
      exchangeLocationPosition: [{
        required: true,
        trigger: "change"
      }]
    }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "equipmentNumberLists", []), "typeflags", true), "penflags", false), "coverflags", false), "salesproductNo", ""), "salesproduct", ""), "salesproductName", ""), "exchangeproduct", "1"), "dialoggoodsForm", false), "goodsform", {
      customerNumber: "",
      dutyType: "",
      remark: "",
      orderLineCustomerList: []
    }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "dutyTypes", []), "goodsrules", {
      customerNumber: [{
        required: true,
        trigger: "change"
      }],
      dutyType: [{
        required: true,
        trigger: "change"
      }]
      //  remark: [{ required: true, trigger: "blur" }],
    }), "refillflags", false), "refillflag", false), "printremark", ""), "checkflages", ""), "checkFlag", false), "printFlag", true), "address", ""), "sureLoading", false), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "priceFlag", false), "startNumber", ""), "numberL", ""), "startNumbers", ""), "numberLs", ""), "startNumber1", ""), "startNumber2", ""), "numberL1", ""), "equipmentNumberLists", []), "equipmentNumberList1", []), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "positions", []), "customerUnit", ""), "returnflag", true), "exchangeFlag", true), "returnNumber", ""), "exchangeNumber", ""), "rFlag", false), "deliverFlag", true), "registerproductNums", ""), "equipmentNumberList2", []), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "returnprice", ""), "priceFlag", false), "printforms", {
      serialNumber: '',
      orderType: '',
      flag: '',
      orderSerialNumber: ''
    }), "printform", []), "forms", []), "orderSerial", ""), "productContainsVOLists", []), "returnstartNumber", ""), "equipmentReturnNumberList", []), "returnstartNumber", ""), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "parentVal", []), "updateDto", []), "logisticsNumber", ""), "nameFlag", false), "titletypes", ""), "orderFlag", false), "returnsearinumber", ""), "seariNumFlag", false), "returnflags", false), "exchangeLocationflag", true), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "locationflag", false), "returncount", ""), "typeFlag", false), "countflag", false), "positionValue", ''), "positionValueCn", ''), "returnOrderprint", []), "returnReal", []), "realProductList", []), "logisticsTaxes", ''), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_ref, "dialogFormSearch", false), "flagList", [{
      flag: true,
      value: '渠道'
    }, {
      flag: true,
      value: '订单号'
    }, {
      flag: true,
      value: '订单序号'
    }, {
      flag: true,
      value: '售后编号'
    }, {
      flag: true,
      value: '售后序号'
    }, {
      flag: true,
      value: '设备号'
    }, {
      flag: false,
      value: '物流单号'
    }, {
      flag: false,
      value: '收货人邮箱'
    }, {
      flag: false,
      value: '收货人手机号'
    }, {
      flag: false,
      value: '退回类型'
    }, {
      flag: true,
      value: '状态'
    }, {
      flag: false,
      value: '支付方式'
    }, {
      flag: true,
      value: '类型'
    }, {
      flag: false,
      value: '申请人'
    }, {
      flag: false,
      value: '涉及商品'
    }, {
      flag: true,
      value: '修改起始时间'
    }, {
      flag: true,
      value: '修改截止时间'
    }, {
      flag: true,
      value: '退回区域仓'
    }, {
      flag: true,
      value: '换出区域仓'
    }, {
      flag: true,
      value: '退回起始时间'
    }, {
      flag: true,
      value: '退回截止时间'
    }, {
      flag: true,
      value: 'T+销售状态'
    }]), "searchInfoList", [{
      flag: true,
      value: '渠道'
    }, {
      flag: true,
      value: '订单号'
    }, {
      flag: true,
      value: '订单序号'
    }, {
      flag: true,
      value: '售后编号'
    }, {
      flag: true,
      value: '售后序号'
    }, {
      flag: true,
      value: '设备号'
    }, {
      flag: false,
      value: '物流单号'
    }, {
      flag: false,
      value: '收货人邮箱'
    }, {
      flag: false,
      value: '收货人手机号'
    }, {
      flag: false,
      value: '退回类型'
    }, {
      flag: true,
      value: '状态'
    }, {
      flag: false,
      value: '支付方式'
    }, {
      flag: true,
      value: '类型'
    }, {
      flag: false,
      value: '申请人'
    }, {
      flag: false,
      value: '涉及商品'
    }, {
      flag: true,
      value: '修改起始时间'
    }, {
      flag: true,
      value: '修改截止时间'
    }, {
      flag: true,
      value: '退回区域仓'
    }, {
      flag: true,
      value: '换出区域仓'
    }, {
      flag: true,
      value: '退回起始时间'
    }, {
      flag: true,
      value: '退回截止时间'
    }, {
      flag: true,
      value: 'T+销售状态'
    }]), "positionFlag", false), "tablegoodsData", []), "positionList", []), "companylogistics", []), "visible", false), "showMColumn", [{
      value: '渠道',
      flag: true
    }, {
      value: '支付方式',
      flag: true
    }, {
      value: '订单号',
      flag: true
    }, {
      value: '订单序号',
      flag: true
    }, {
      value: '售后编号',
      flag: true
    }, {
      value: '售后序号',
      flag: true
    }, {
      value: '类型名称',
      flag: true
    }, {
      value: '状态名称',
      flag: true
    }, {
      value: '币种',
      flag: true
    }, {
      value: '价格',
      flag: true
    }, {
      value: '税费',
      flag: true
    }, {
      value: '申请人',
      flag: true
    }, {
      value: '申请时间',
      flag: true
    }, {
      value: '行政审核',
      flag: true
    }, {
      value: '财务审核',
      flag: true
    }, {
      value: '责任类型',
      flag: true
    }, {
      value: '费用类型',
      flag: true
    }, {
      value: '发货类型',
      flag: true
    }, {
      value: '涉及商品',
      flag: true
    }, {
      value: '退回类型',
      flag: true
    }, {
      value: '退货设备号',
      flag: true
    }, {
      value: '换货设备号',
      flag: true
    }, {
      value: '备注',
      flag: true
    }, {
      value: '修改时间',
      flag: true
    }, {
      value: '退回区域仓',
      flag: true
    }, {
      value: '换出区域仓',
      flag: true
    }, {
      value: '退回时间',
      flag: true
    }, {
      value: 'T+销售状态',
      flag: true
    }]), "showCusterColumn", [{
      value: '渠道',
      flag: true
    }, {
      value: '支付方式',
      flag: true
    }, {
      value: '订单号',
      flag: true
    }, {
      value: '订单序号',
      flag: true
    }, {
      value: '售后编号',
      flag: true
    }, {
      value: '售后序号',
      flag: true
    }, {
      value: '类型名称',
      flag: true
    }, {
      value: '状态名称',
      flag: true
    }, {
      value: '币种',
      flag: true
    }, {
      value: '价格',
      flag: true
    }, {
      value: '税费',
      flag: true
    }, {
      value: '申请人',
      flag: true
    }, {
      value: '申请时间',
      flag: true
    }, {
      value: '行政审核',
      flag: true
    }, {
      value: '财务审核',
      flag: true
    }, {
      value: '责任类型',
      flag: true
    }, {
      value: '费用类型',
      flag: true
    }, {
      value: '发货类型',
      flag: true
    }, {
      value: '涉及商品',
      flag: true
    }, {
      value: '退回类型',
      flag: true
    }, {
      value: '退货设备号',
      flag: true
    }, {
      value: '换货设备号',
      flag: true
    }, {
      value: '备注',
      flag: true
    }, {
      value: '修改时间',
      flag: true
    }, {
      value: '退回区域仓',
      flag: true
    }, {
      value: '换出区域仓',
      flag: true
    }, {
      value: '退回时间',
      flag: true
    }, {
      value: 'T+销售状态',
      flag: true
    }]);
  },
  mounted: function mounted() {
    var _this = this;
    if (localStorage.getItem('showCusterColumn')) {
      this.showCusterColumn = JSON.parse(localStorage.getItem('showCusterColumn'));
      if (this.showCusterColumn.toString() != this.showMColumn.toString()) {
        this.showCusterColumn = this.showMColumn;
      }
    } else {
      this.showCusterColumn = this.showMColumn;
    }
    if (localStorage.getItem('searchList')) {
      this.flagList = JSON.parse(localStorage.getItem('searchList'));
      if (this.flagList.toString() != this.searchInfoList.toString()) {
        this.flagList = this.searchInfoList;
      }
    } else {
      this.flagList = this.searchInfoList;
    }
    (0, _dictionary.searchDic)({
      name: "DEALER",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.dealers = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "CUSTOMER_STATUS",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.status = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "PAY_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.payTypes = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "LOCATION_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.locationPositions = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "CUSTOMER_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.types = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "RETURN_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.returnTypes = res.voList;
    });
    this.getList();
  },
  methods: (_methods = {
    saveColumn: function saveColumn() {
      localStorage.setItem("showCusterColumn", JSON.stringify(this.showCusterColumn));
      this.visible = false;
    },
    //T+状态
    chanjetTypes: function chanjetTypes(row) {
      if (row.chanjetType == "Y") {
        return "已上送";
      } else if (row.chanjetType == "N") {
        return "未上送";
      } else if (row.chanjetType == "ND") {
        return "不上送";
      }
    },
    //查询列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      (0, _timers.setTimeout)(function () {
        (0, _ordercustomer.getLists)(_this2.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this2.form.pageNo = 1;
            _this2.currentPage = 1;
            (0, _ordercustomer.getLists)(_this2.form).then(function (res) {
              _this2.tableData = res.voList;
              _this2.total = Number(res.total);
              _this2.loading = false;
            });
          }
          _this2.tableData = res.voList;
          _this2.total = Number(res.total);
          _this2.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.orderLineCustomerList = [];
      this.multipleSelection = val;
      this.orderSerial = val[0].orderSerial;
      this.id = val[0].id;
    },
    rowExpand: function rowExpand() {},
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.orderNo = "";
      this.form.statusList = [];
      this.form.checkStatus = "";
      this.form.type = "";
      this.form.customerNumber = "";
      this.form.contactEmail = "";
      this.form.telephone = "";
      this.form.customerSerial = "";
      this.form.orderSerial = "";
      this.form.dealer = "";
      this.form.startCreateTime = "";
      this.form.endCreateTime = "";
      this.form.startReturnTime = "";
      this.form.endReturnTime = "";
      this.form.involveProductType = "";
      this.form.payType = "";
      this.form.equipmentNumber = '';
      this.form.locationPosition = '';
      this.form.exchangeLocationPosition = '';
      this.form.chanjetType = "";
      this.form.createUser = "";
      this.form.logisticsNumber = '';
      this.form.returnType = '';
      this.form.dealerList = [];
      this.form.pageNo = 1;
      this.currentPage = 1;
      this.getList();
    },
    //渠道多选
    changeDealer: function changeDealer(val) {
      this.form.dealerList = val;
    },
    //状态多选
    changeStatus: function changeStatus(val) {
      this.form.statusList = val;
    },
    // costsType(v){
    //   if(v.costType == '1'){
    //     return '退'
    //   }else if(v.costType == '2'){
    //     return '补'
    //   }else if(v.costType =='3'){
    //     return '无费用'
    //   }
    // },
    //发货类型
    deliversType: function deliversType(v) {
      if (v.deliverType == "0") {
        return "已发货";
      } else if (v.deliverType == "1") {
        return "未发货";
      }
    },
    //涉及商品
    involvesProductType: function involvesProductType(v) {
      if (v.involveProductType == "0") {
        return "是";
      } else if (v.involveProductType == "1") {
        return "否";
      }
    },
    //设置搜索框
    setSearch: function setSearch(e) {
      // this.dialogFormSearch = true;
      if (e == '1') {
        this.dialogFormSearch = true;
      } else if (e == '2') {
        this.visible = true;
      }
    },
    //搜索框确定
    searchSure: function searchSure() {
      localStorage.setItem('searchList', []);
      localStorage.setItem('searchList', JSON.stringify(this.flagList));
      this.empty();
      this.dialogFormSearch = false;
    },
    //搜索框关闭
    searchClose: function searchClose() {
      this.dialogFormSearch = false;
    },
    //验货
    goodsclick: function goodsclick(row) {
      var _this3 = this;
      this.dialoggoodsForm = true;
      (0, _dictionary.searchDic)({
        name: "DUTY_TYPE ",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this3.dutyTypes = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "STOCK_POSITION",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this3.positionList = res.voList;
      });
      this.goodsform.remark = row.remark;
      this.goodsform.customerNumber = row.customerNumber;
      (0, _ordercustomer.aloneCustomer)(row.customerNumber).then(function (res) {
        if (res.success) {
          _this3.tablegoodsData = res.voT.customerLineList;
        } else {
          _this3.msgTip("error", res.errorMsg);
        }
      });
    },
    //验货关闭
    goodsClose: function goodsClose() {
      this.dialoggoodsForm = false;
    },
    //验货确定
    goodskeep: function goodskeep() {
      var _this4 = this;
      this.$refs.goodsform.validate(function (valid) {
        if (valid) {
          _this4.isDisable = true;
          _this4.sureLoading = true;
          var keyMap = {
            customerLineContainsList: 'orderLineContainsCustomerList'
          };
          for (var i = 0; i < _this4.tablegoodsData.length; i++) {
            var obj = _this4.tablegoodsData[i];
            for (var key in obj) {
              var newKey = keyMap[key];
              if (newKey) {
                obj[newKey] = obj[key];
                delete obj[key];
              }
            }
          }
          _this4.goodsform.orderLineCustomerList = _this4.tablegoodsData;
          (0, _ordercustomer.checkProduct)(_this4.goodsform).then(function (res) {
            if (res.success) {
              _this4.msgTip("success", "操作成功");
              _this4.dialoggoodsForm = false;
              _this4.getList();
              _this4.$refs.goodsform.resetFields();
              _this4.isDisable = false;
              _this4.sureLoading = false;
            } else {
              _this4.msgTip("error", res.errorMsg);
              _this4.isDisable = false;
              _this4.sureLoading = false;
            }
          });
        }
      });
    },
    //修改
    change: function change(row) {
      var _this5 = this;
      this.dialogChangeForm = true;
      (0, _dictionary.searchDic)({
        name: "COST_TYPE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this5.costTypes = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "RETURN_TYPE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this5.returnTypes = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "LOCATION_POSITION",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this5.locationPositions = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "CUSTOMER_REQUEST",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this5.customerRequests = res.voList;
      });
      (0, _dictionary.searchDic)({
        name: "LOGISTICS",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this5.companylogistics = res.voList;
      });
      if (row.status == "3" || row.status == "4" || row.status == '7') {
        this.typeFlag = true;
        this.returnflag = false;
        this.exchangeLocationflag = false;
      } else {
        this.typeFlag = false;
      }
      (0, _ordercustomer.aloneCustomer)(row.customerNumber).then(function (res) {
        if (res.success) {
          _this5.changeform = res.voT;
          _this5.address = res.voT.address;
          _this5.priceFlag = true;
          if (res.voT.returnType == '2') {
            _this5.returnflag = false;
          }

          // if (res.voT.financeReviewResult == "0") {
          //   this.priceFlag = true;
          // } else {
          //   this.priceFlag = false;
          // }
        } else {
          _this5.msgTip("error", res.errorMsg);
        }
      });
    },
    changeClose: function changeClose() {
      this.dialogChangeForm = false;
      this.returnflag = true;
      this.exchangeLocationflag = true;
      this.$refs.changeform.resetFields();
    },
    //修改确定
    changesure: function changesure() {
      var _this6 = this;
      this.$refs.changeform.validate(function (valid) {
        if (valid) {
          _this6.isDisable = true;
          _this6.sureLoading = true;
          (0, _ordercustomer.changeCustomer)(_this6.changeform).then(function (res) {
            if (res.success) {
              _this6.msgTip("success", "修改成功");
              _this6.dialogChangeForm = false;
              _this6.getList();
              _this6.$refs.changeform.resetFields();
              _this6.isDisable = false;
              _this6.sureLoading = false;
              _this6.returnflag = true;
            } else {
              _this6.msgTip("error", res.errorMsg);
              _this6.isDisable = false;
              _this6.sureLoading = false;
            }
          });
        }
      });
    },
    //退回登记
    returnRegister: function returnRegister(row) {
      var _this7 = this;
      this.dialogFormVisibleRegister = true;
      this.returnflags = false;
      this.registerform.orderNo = row.orderNo;
      this.returnsearinumber = row.customerNumber;
      this.registerform.remark = row.remark;
      this.registerform.locationPosition = row.locationPosition;
      (0, _dictionary.searchDic)({
        name: "RETURN_TYPE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this7.returntypes = res.voList;
      });
    },
    //添加起始编码
    addNumber2: function addNumber2() {
      this.equipmentNumberList2.push({
        text: ""
      });
    },
    removeNumber2: function removeNumber2(index) {
      this.equipmentNumberList2.splice(index, 1);
    },
    //全额退款
    backs: function backs() {
      //this.dialogFormVisible = true;
      // this.titletype = "全额退款";
      this.isShow = false;
    },
    //T+补推
    tpush: function tpush() {
      var _this8 = this;
      this.loading = true;
      (0, _ordercustomer.tpush)().then(function (res) {
        _this8.loading = false;
        if (res.success) {
          _this8.msgTip("success", "操作成功");
          _this8.getList();
        } else {
          _this8.msgTip("warning", res.errorMsg);
        }
      });
    },
    //保修卡
    warrantyCard: function warrantyCard() {
      this.dialogWarrantyCard = true;
    },
    //保修卡关闭
    warrantyCardClose: function warrantyCardClose() {
      this.warrantyCardflag = false;
      this.warrantyCard.equipmentNumber = "";
      this.warrantyCard.equipmentModel = "";
      this.warrantyCard.warrantyEndTime = "";
      this.warrantyCardform.equipmentNo = "";
      this.dialogWarrantyCard = false;
    },
    warrantyCardSearch: function warrantyCardSearch() {
      var _this9 = this;
      this.warrantyCardflag = false;
      this.warrantyCard.equipmentNumber = "";
      this.warrantyCard.equipmentModel = "";
      this.warrantyCard.warrantyEndTime = "";
      if (this.warrantyCardform.equipmentNo == "") {
        this.msgTip("error", "请输入设备号！");
      } else {
        (0, _warrantyCard.getWarrantyCard)({
          equipmentNumber: this.warrantyCardform.equipmentNo
        }).then(function (res) {
          if (res.success) {
            _this9.warrantyCardflag = true;
            _this9.warrantyCard.equipmentNumber = res.equipmentNumber;
            _this9.warrantyCard.equipmentModel = res.equipmentModel;
            _this9.warrantyCard.warrantyEndTime = res.warrantyEndTime;
          } else {
            _this9.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //判断行政审核
    isAamin: function isAamin(v) {
      if (v.administrationReviewResult == "0") {
        return "通过";
      } else if (v.administrationReviewResult == "1") {
        return "不通过";
      }
    },
    //判断财务审核
    isFinance: function isFinance(v) {
      if (v.financeReviewResult == "0") {
        return "通过";
      } else if (v.financeReviewResult == "1") {
        return "不通过";
      }
    },
    //客服审核
    customer: function customer(row) {
      var _this10 = this;
      this.dialogFormCustomer = true;
      this.idList = [];
      this.idList.push(row.id);
      this.idList = this.idList.reverse();
      (0, _ordercustomer.getPrint)({
        idList: this.idList
      }).then(function (item) {
        if (item.success) {
          _this10.Customerform = item.voList[0];
          _this10.productList = item.voList[0].customerReturnDetailList;
          _this10.customerUnit = item.voList[0].price.slice(0, 3);
          _this10.Customerform.price = item.voList[0].price.slice(item.voList[0].price.indexOf(" ") + 1);
        } else {
          _this10.dialogFormCustomer = false;
          _this10.msgTip("error", item.errorMsg);
        }
      });
    },
    //客服审核保存
    customerKeep: function customerKeep() {
      var _this11 = this;
      this.$refs.Customerform.validate(function (valid) {
        if (valid) {
          _this11.isDisable = true;
          _this11.sureLoading = true;
          (0, _ordercustomer.saveCustomer)({
            customerNumber: _this11.Customerform.customerNumber,
            price: _this11.Customerform.price,
            remark: _this11.Customerform.remark
          }).then(function (res) {
            if (res.success) {
              _this11.msgTip("success", "操作成功");
              _this11.dialogFormCustomer = false;
              _this11.getList();
              _this11.$refs.Customerform.resetFields();
              _this11.isDisable = false;
              _this11.sureLoading = false;
            } else {
              _this11.msgTip("error", res.errorMsg);
              _this11.sureLoading = false;
            }
          });
        }
      });
    },
    CustomerClose: function CustomerClose() {
      this.dialogFormCustomer = false;
    },
    //input框失去焦点
    equiment: function equiment(e, id) {
      var _this12 = this;
      // this.counts = e;
      console.log(this.count, "input框失去焦点");
      (0, _ordercustomer.getBag)(this.returnform.orderNo).then(function (res) {
        res.voList.map(function (item) {
          if (item.orderLineId == id) {
            if (parseInt(e) > item.count) {
              _this12.msgTip("warning", "超过最大数量");
              _this12.isDisable = true;
              return;
            } else if (e == "") {
              _this12.msgTip("warning", "数量不能为空");
              _this12.isDisable = true;
              return;
            } else if (!/(^[1-9]\d*$)/.test(e)) {
              _this12.msgTip("warning", "数量只能为正整数");
              _this12.isDisable = true;
              return;
            } else {
              _this12.isDisable = false;
              _this12.orderLineCustomerList.map(function (item) {
                if (item.count !== "") {
                  item.count = e;
                }
              });
            }
          }
        });
      });
    },
    //
    addOrder: function addOrder() {}
  }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "backs", function backs() {
    var _this13 = this;
    this.dialogMoneyVisible = true;
    (0, _addorder.Diclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this13.type = res.voList;
    });
  }), "deliver", function deliver(v) {
    if (v == "0") {
      this.deliverFlag = false;
    } else {
      this.deliverFlag = true;
    }
  }), "addMoney", function addMoney() {
    if (this.productNos == "" || this.moneycount == "") {
      this.$message({
        type: "error",
        message: "请填写完整",
        center: true
      });
    } else {
      this.showflag = true;
      var productList = [];
      // this.addform.productList = [];
      this.moneyform.orderLineCustomerList.push({
        count: this.moneycount,
        orderLineContainsCustomerList: [{
          count: this.moneycount,
          pictureUrl: "",
          productName: this.productName,
          productNameCn: this.productName,
          productNo: this.productNo,
          type: this.product
        }],
        orderLineId: 0,
        orderNo: this.moneyform.orderNo,
        productName: this.productName,
        productNo: this.productNo
      });
    }
  }), "remove", function remove(index) {
    this.moneyform.orderLineCustomerList.splice(index, 1);
  }), "isShows", function isShows(v) {
    if (v == "0") {
      this.isProduct = true;
    } else if (v == "1") {
      this.isProduct = false;
    }
  }), "isNo", function isNo(v) {
    var _this14 = this;
    this.returnNumber = v;
    if (v == "1") {
      this.moneyform.productNo = "";
      this.typeflag = true;
      this.coverflag = false;
      this.penflag = false;
      this.refillflag = false;
      this.returnflag = true;
      this.productNos = "";
      (0, _addorder.Diclist)({
        name: "PRODUCT_TYPE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this14.type = res.voList;
      });
    } else if (v == "2") {
      this.moneyform.productNo = "";
      this.exportFlag = false;
      this.coverflag = true;
      this.typeflag = false;
      this.penflag = false;
      this.refillflag = false;
      this.returnflag = false;
      this.productNos = "";
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this14.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this14.jacket = _this14.newList;
        }
      });
    } else if (v == "3") {
      this.moneyform.productNo = "";
      this.exportFlag = false;
      this.coverflag = false;
      this.typeflag = false;
      this.penflag = true;
      this.refillflag = false;
      this.returnflag = false;
      this.productNos = "";
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this14.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this14.jacket = _this14.newList;
        }
      });
    } else if (v == "4") {
      this.coverflag = false;
      this.typeflag = false;
      this.penflag = false;
      this.refillflag = true;
      this.returnflag = false;
      this.productNos = "";
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this14.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this14.jacket = _this14.newList;
        }
      });
    }
  }), "exchangeNo", function exchangeNo(v) {
    var _this15 = this;
    this.exchangeproduct = v;
    if (v == "1") {
      this.salesform.productNo = "";
      this.salesproductNo = "";
      this.typeflags = true;
      this.coverflags = false;
      this.penflags = false;
      this.refillflags = false;
      this.exchangeFlag = true;
      (0, _addorder.Diclist)({
        name: "PRODUCT_TYPE",
        pageNo: 1,
        pageSize: 100,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        _this15.type = res.voList;
      });
    } else if (v == "2") {
      this.salesform.productNo = "";
      this.salesproductNo = "";
      this.exportFlags = false;
      this.coverflags = true;
      this.typeflags = false;
      this.penflags = false;
      this.refillflags = false;
      this.exchangeFlag = false;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this15.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this15.jacket = _this15.newList;
        }
      });
    } else if (v == "3") {
      this.salesform.productNo = "";
      this.salesproductNo = "";
      this.exportFlag = false;
      this.coverflags = false;
      this.typeflags = false;
      this.penflags = true;
      this.refillflags = false;
      this.exchangeFlag = false;
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this15.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this15.jacket = _this15.newList;
        }
      });
    } else if (v == "4") {
      this.exportFlag = false;
      this.coverflags = false;
      this.typeflags = false;
      this.penflags = false;
      this.refillflags = true;
      this.exchangeFlag = false;
      this.salesproductNo = "";
      (0, _addorder.Diclist)({
        name: "PART",
        pageNo: 1,
        pageSize: 1000,
        sortField: "",
        sortRules: "",
        valueMeaning: ""
      }).then(function (res) {
        if (res.success) {
          var b = {};
          res.voList.forEach(function (obj) {
            var array = b[obj["name"]] || [];
            array.push(obj);
            b[obj["name"]] = array;
          });
          _this15.newList = b["PART"].map(function (v) {
            return (0, _objectSpread2.default)((0, _objectSpread2.default)({}, v), {}, {
              value: v.value
            });
          });
          _this15.jacket = _this15.newList;
        }
      });
    }
  }), "changeProduct", function changeProduct(data) {
    var value = data.value,
      label = data.label;
    this.productNo = value;
    this.productName = label;
  }), "changeProducts", function changeProducts(data) {
    var value = data.value,
      label = data.label;
    this.salesproduct = value;
    this.salesproductName = label;
  }), "moneysure", function moneysure() {
    var _this16 = this;
    this.$refs.moneyform.validate(function (valid) {
      if (valid) {
        _this16.isDisable = true;
        _this16.sureLoading = true;
        (0, _ordercustomer.returnMoney)({
          deliverType: _this16.moneyform.deliverType,
          involveProductType: _this16.moneyform.involveProductType,
          orderNo: _this16.moneyform.orderNo,
          payType: _this16.moneyform.payType,
          price: _this16.moneyform.price,
          remark: _this16.moneyform.remark,
          orderLineCustomerList: _this16.moneyform.orderLineCustomerList,
          checkService: "0"
        }).then(function (res) {
          if (res.success) {
            _this16.msgTip("success", "操作成功");
            _this16.dialogMoneyVisible = false;
            _this16.getList();
            _this16.$refs.moneyform.resetFields();
            _this16.sureLoading = false;
            _this16.isDisable = false;
          } else if (res.errorCode == "E1621") {
            _this16.$confirm(res.errorMsg, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            }).then(function () {
              (0, _ordercustomer.returnMoney)({
                deliverType: _this16.moneyform.deliverType,
                involveProductType: _this16.moneyform.involveProductType,
                orderNo: _this16.moneyform.orderNo,
                payType: _this16.moneyform.payType,
                price: _this16.moneyform.price,
                remark: _this16.moneyform.remark,
                orderLineCustomerList: _this16.moneyform.orderLineCustomerList,
                checkService: "1"
              }).then(function (item) {
                if (item.success) {
                  _this16.dialogMoneyVisible = false;
                  _this16.msgTip("success", "申请通过");
                  _this16.getList();
                  _this16.moneyform.orderLineCustomerList = [];
                  _this16.isDisable = false;
                  _this16.sureLoading = false;
                } else {
                  _this16.msgTip("error", res.errorMsg);
                  _this16.isDisable = false;
                }
              });
            }).catch(function () {
              _this16.isDisable = false;
              _this16.sureLoading = false;
            });
          } else {
            _this16.msgTip("error", res.errorMsg);
            _this16.isDisable = false;
            _this16.sureLoading = false;
          }
        });
      }
    });
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "moneyClose", function moneyClose() {
    this.dialogMoneyVisible = false;
    this.$refs.moneyform.resetFields();
    this.productNos = "";
    this.moneycount = "";
    this.payType = "";
    this.moneyform.orderLineCustomerList = [];
  }), "salesReturn", function salesReturn() {
    var _this17 = this;
    this.dialogFormVisible = true;
    this.seariNumFlag = false;
    this.orderFlag = false;
    this.isShow = true;
    this.returnform.locationPosition == "";
    (0, _addorder.Diclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this17.type = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "LOCATION_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this17.locationPositions = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "RETURN_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this17.returntypes = res.voList;
    });
  }), "addReturnNumber", function addReturnNumber() {
    this.equipmentReturnNumberList.push({
      value: ""
    });
  }), "removeReturnNumber", function removeReturnNumber(index) {
    this.equipmentReturnNumberList.splice(index, 1);
  }), "changeOrder", function changeOrder(v) {
    var _this18 = this;
    this.returnform.orderLineCustomerList = [];
    if (this.returnform.orderNo.length > 4) {
      (0, _ordercustomer.getOrderNo)(this.returnform.orderNo).then(function (res) {
        if (res.success) {
          _this18.returnform.price = res.voT.realPrice;
          _this18.equipmentReturnNumberList = res.voT.equipmentNumberList;
          var newarr = [];
          _this18.equipmentReturnNumberList.map(function (res) {
            newarr.push({
              value: res
            });
          });
          _this18.equipmentReturnNumberList = newarr;
          res.voT.productList.map(function (v) {
            v.productContainsVOList.map(function (val) {
              val.parentId = v.orderLineId;
            });
          });
          _this18.tableDatas = res.voT.productList;
          _this18.realProductList = res.voT.realOrderList;
          _this18.returnflags = true;
        } else {
          _this18.$message({
            type: "error",
            message: res.errorMsg,
            center: true
          });
        }
      });
    } else {
      this.returnflags = false;
      return false;
    }
  }), "changeCount", function changeCount(val) {
    var idx = this.returnform.orderLineCustomerList.findIndex(function (v) {
      return v.orderLineId === val.orderLineId;
    });
    console.log(idx, 'idx');
    if (idx !== -1) {
      this.returnform.orderLineCustomerList[idx].count = val.count;
    }
  }), "changeCount1", function changeCount1(val) {
    console.log(val);
    var idx = this.returnform.orderLineCustomerList.findIndex(function (v) {
      return v.orderLineId === val.orderLineId;
    });
    if (idx !== -1) {
      this.returnform.orderLineCustomerList[idx].price = val.price;
    }
  }), "tableSelect", function tableSelect(selection, row) {}), "handleSelectionChanges", function handleSelectionChanges(val) {}), "tableSelects", function tableSelects(selection, row) {
    if (selection.length) {
      var newFDatas = this.$refs["multipleTables"].data;
      var newFItems = null;
      // console.log(row,'888888')
      for (var i = 0; i < newFDatas.length; i++) {
        if (newFDatas[i].orderLineId == selection[0].parentId) {
          newFItems = newFDatas[i];
        }
      }
      // this.returnform.orderLineCustomerList = [];
      var idx = this.returnform.orderLineCustomerList.findIndex(function (val) {
        return val.orderLineId === row.parentId;
      });
      if (idx === -1) {
        selection.map(function (item) {
          item.productName = item.productNameCn;
        });
        this.returnform.orderLineCustomerList.push({
          count: newFItems.count,
          orderLineContainsCustomerList: selection,
          orderLineId: newFItems.orderLineId,
          orderNo: newFItems.orderNo,
          price: newFItems.price,
          productName: newFItems.productName,
          productNo: newFItems.productNo
        });
      } else {
        this.returnform.orderLineCustomerList[idx].orderLineContainsCustomerList = selection;
      }

      //console.log(this.returnform.orderLineCustomerList, "0-0-0-0-");
    }
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "handleSelectionChange1", function handleSelectionChange1(val) {
    var _this19 = this;
    if (val.length) {
      var newFData = this.$refs["multipleTables"].data;
      var newFItem = null;
      for (var i = 0; i < newFData.length; i++) {
        if (newFData[i].orderLineId == val[0].parentId) {
          newFItem = newFData[i];
        }
      }
      (0, _timers.setTimeout)(function () {
        val.map(function (res) {
          res.productName = res.productNameCn;
          var idx = _this19.returnform.orderLineCustomerList.findIndex(function (vals) {
            return vals.orderLineId === res.parentId;
          });
          if (idx === -1) {
            _this19.returnform.orderLineCustomerList.push({
              count: newFItem.count,
              orderLineContainsCustomerList: val,
              orderLineId: newFItem.orderLineId,
              orderNo: newFItem.orderNo,
              price: newFItem.price,
              productName: newFItem.productName,
              productNo: newFItem.productNo
            });
          } else {
            _this19.returnform.orderLineCustomerList[idx].orderLineContainsCustomerList = val;
          }
        });
      }, 200);
      //onsole.log(this.returnform.orderLineCustomerList, "1-0-0-0-");
    }
  }), "tableAll", function tableAll(row) {
    var idx = this.returnform.orderLineCustomerList.findIndex(function (val) {
      return val.orderLineId === row.orderLineId;
    });
    if (idx !== -1) {
      this.returnform.orderLineCustomerList.splice(idx, 1);
    }
  }), "changereturn", function changereturn(v) {
    this.productNos = "";
    if (v !== "2") {
      this.returnflags = true;
      this.locationflag = true;
    } else {
      this.returnflags = false;
      this.locationflag = false;
      // this.salesform.exchangeCustomerList = []
      this.salesform.orderLineCustomerList = [];
      this.returnform.orderLineCustomerList = [];
    }
  }), "returnAdd", function returnAdd() {
    if (this.productNos == "" || this.returncount == "" || this.startNumber == "" && this.product == "1" || this.returnprice == "") {
      this.$message({
        type: "error",
        message: "请填写完整",
        center: true
      });
    } else {
      this.showflag = true;
      var newArr = [];
      this.equipmentNumberList.map(function (item, index) {
        newArr.push(item.text);
      });
      this.returnform.orderLineCustomerList.push({
        count: this.returncount,
        equipmentNumberList: this.product !== "1" ? [] : [this.startNumber].concat(newArr),
        orderLineContainsCustomerList: [{
          count: this.returncount,
          pictureUrl: "",
          productName: this.productNameCn,
          productNameCn: this.productNameCn,
          productNo: this.productNo,
          type: this.product
        }],
        orderLineId: 0,
        orderNo: this.returnform.orderNo,
        productName: this.productName,
        productNo: this.productNo,
        price: this.returnprice
      });
      this.returncount = "";
      this.startNumber = "";
      this.equipmentNumberList = [];
      this.returnprice = "";
      // this.returnproductNum = ''
    }
  }), "addNumber", function addNumber() {
    this.equipmentNumberList.push({
      text: ""
    });
  }), "removeNumber", function removeNumber(index) {
    this.equipmentNumberList.splice(index, 1);
  }), "equimentNumber", function equimentNumber(event) {
    this.numberL = event.target.value;
    if (this.numberL.length < 7) {
      this.$message({
        type: "warning",
        message: "设备号不能小于7位",
        center: true
      });
      return false;
      this.isDisable = true;
    } else {
      this.isDisable = false;
    }
  }), "returnRemove", function returnRemove(index) {
    this.returnform.orderLineCustomerList.splice(index, 1);
  }), "sure", function sure() {
    var _this20 = this;
    this.$refs.returnform.validate(function (valid) {
      if (valid) {
        // this.isDisable = true;
        // this.sureLoading = true;
        if (_this20.returnform.orderLineCustomerList.length == 0 && _this20.returnNumber == "1") {
          _this20.$message({
            type: "warning",
            message: "设备号不能为空",
            center: true
          });
          _this20.sureLoading = false;
          _this20.isDisable = false;
          return false;
        } else if (_this20.returnform.orderLineCustomerList.length == 0 && _this20.returnform.returnType != "2") {
          _this20.$message({
            type: "warning",
            message: "退回摘要不能为空",
            center: true
          });
          _this20.sureLoading = false;
          _this20.isDisable = false;
          return false;
        } else if (_this20.returnform.returnType != '2' && _this20.returnform.locationPosition == "") {
          _this20.$message({
            type: "warning",
            message: "请选择退回区域仓",
            center: true
          });
          _this20.sureLoading = false;
          _this20.isDisable = false;
          return false;
        } else {
          var newArr3 = [];
          _this20.equipmentReturnNumberList.map(function (item, index) {
            newArr3.push(item.value);
          });
          var locationPosition = _this20.returnform.locationPosition;
          var returnType = _this20.returnform.returnType;
          for (var i = _this20.returnform.orderLineCustomerList.length - 1; i >= 0; i--) {
            if (!_this20.returnform.orderLineCustomerList[i].orderLineContainsCustomerList.length) {
              _this20.returnform.orderLineCustomerList.splice(i, 1);
            }
          }
          if (returnType == '2') {
            locationPosition = '';
          }
          (0, _ordercustomer.returnProduct)({
            orderNo: _this20.returnform.orderNo,
            price: _this20.returnform.price,
            remark: _this20.returnform.remark,
            returnType: _this20.returnform.returnType,
            locationPosition: locationPosition,
            orderLineCustomerList: _this20.returnform.orderLineCustomerList,
            equipmentNumberList: newArr3,
            checkService: "0",
            freight: _this20.returnform.freight,
            otherPrice: _this20.returnform.otherPrice,
            logisticsTaxes: _this20.logisticsTaxes
          }).then(function (res) {
            if (res.success) {
              _this20.msgTip("success", "操作成功");
              _this20.dialogFormVisible = false;
              _this20.getList();
              _this20.returnform.orderLineCustomerList = [];
              _this20.$refs.returnform.resetFields();
              _this20.isDisable = false;
              _this20.sureLoading = false;
              _this20.returnform.locationPosition = '';
            } else if (res.errorCode == "E1621") {
              _this20.$confirm(res.errorMsg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              }).then(function () {
                (0, _ordercustomer.returnProduct)({
                  orderNo: _this20.returnform.orderNo,
                  price: _this20.returnform.price,
                  remark: _this20.returnform.remark,
                  locationPosition: locationPosition,
                  returnType: returnType,
                  orderLineCustomerList: _this20.returnform.orderLineCustomerList,
                  equipmentNumberList: newArr3,
                  checkService: "1",
                  freight: _this20.returnform.freight,
                  otherPrice: _this20.returnform.otherPrice,
                  logisticsTaxes: _this20.logisticsTaxes
                }).then(function (item) {
                  if (item.success) {
                    _this20.dialogFormVisible = false;
                    _this20.msgTip("success", "申请通过");
                    _this20.getList();
                    _this20.returnform.orderLineCustomerList = [];
                    _this20.isDisable = false;
                    _this20.sureLoading = false;
                    _this20.returnform.locationPosition = '';
                  } else {
                    _this20.$message({
                      showClose: true,
                      type: "error",
                      message: item.errorMsg,
                      center: true,
                      duration: 10000
                    });
                    _this20.isDisable = false;
                    _this20.sureLoading = false;
                  }
                });
              }).catch(function () {
                _this20.isDisable = false;
                _this20.dialogFormVisible = false;
              });
            } else {
              _this20.msgTip("error", res.errorMsg);
              _this20.isDisable = false;
              _this20.sureLoading = false;
            }
          });
        }
      }
    });
  }), "sureRegister", function sureRegister() {
    var _this21 = this;
    this.$refs.registerform.validate(function (valid) {
      if (valid) {
        var locationPosition = _this21.registerform.locationPosition;
        var returnType = _this21.registerform.returnType;
        if (returnType != '2' && locationPosition == "") {
          _this21.$message({
            type: "warning",
            message: "请选择退回区域仓",
            center: true
          });
          _this21.sureLoading = false;
          _this21.isDisable = false;
          return false;
        } else {
          (0, _ordercustomer.returnRegister)({
            logisticsName: _this21.registerform.logisticsName,
            logisticsNumber: _this21.registerform.logisticsNumber,
            logisticsUnit: _this21.registerform.logisticsUnit,
            returnType: returnType,
            locationPosition: locationPosition,
            logisticsPrice: _this21.registerform.logisticsPrice,
            customerNumber: _this21.returnsearinumber,
            remark: _this21.registerform.remark,
            logisticsTaxes: _this21.registerform.logisticsTaxes
          }).then(function (res) {
            if (res.success) {
              _this21.msgTip("success", "操作成功");
              _this21.dialogFormVisibleRegister = false;
              _this21.$refs.registerform.resetFields();
              _this21.getList();
              _this21.isDisable = false;
              _this21.sureLoading = false;
              _this21.registerform.locationPosition = '';
            } else {
              _this21.msgTip("error", res.errorMsg);
              _this21.isDisable = false;
              _this21.sureLoading = false;
            }
          });
        }
      }
    });
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "registerClose", function registerClose() {
    this.dialogFormVisibleRegister = false;
    this.registerform.remark = "";
    this.registerform.locationPosition = "";
    this.registerform.orderNo = "";
    this.registerform.returnType = "";
    this.registerform.logisticsTaxes = "";
    this.registerform.logisticsPrice = "";
    this.registerform.logisticsNumber = "";
    this.registerform.logisticsName = "";
    this.returnflags = false;
    this.$refs.registerform.resetFields();
  }), "returnClose", function returnClose() {
    this.dialogFormVisible = false;
    this.productNos = "";
    this.returnform.orderLineCustomerList = [];
    this.orderPrice = "";
    this.returnform.price = "";
    this.returnform.remark = "";
    this.returnform.locationPosition = "";
    this.returnform.orderNo = "";
    this.returnform.returnType = "";
    this.logisticsTaxes = "";
    this.returnprice = "";
    this.equipmentReturnNumberList = [];
    this.returnflags = false;
    this.locationflag = false;
    this.$refs.returnform.resetFields();
  }), "isSure", function isSure() {}), "barter", function barter() {
    var _this22 = this;
    this.dialogFormVisibles = true;
    this.rFlag = true;
    (0, _addorder.Diclist)({
      name: "PRODUCT_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this22.type = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "CUSTOMER_REQUEST",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this22.customerRequests = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "RETURN_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this22.returntypes = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "COST_TYPE",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this22.costTypes = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "LOCATION_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this22.locationPositions = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "STOCK_POSITION",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this22.positions = res.voList;
    });
  }), "searchDeatil", function searchDeatil() {
    var _this23 = this;
    var orderNoList = [];
    if (this.salesform.orderNo.length > 4) {
      orderNoList.push(this.salesform.orderNo);
      (0, _orderInfo.searchList)({
        orderNoList: orderNoList
      }).then(function (item) {
        if (item.success) {
          (0, _orderLogistics.getCountry)("zh-cn").then(function (res) {
            if (res.success) {
              (0, _orderLogistics.getCountry)("en").then(function (item) {
                if (item.success) {
                  var new2 = [].concat((0, _toConsumableArray2.default)(res.voList), (0, _toConsumableArray2.default)(item.voList));
                  _this23.countrys = new2;
                }
              });
            }
          });
          (0, _orderLogistics.getProvince)("US").then(function (res) {
            if (res.success) {
              var new2 = (0, _toConsumableArray2.default)(res.voList);
              _this23.provinceNames = new2;
            }
          });
          if (item.voList[0].orderInfoDetailList[0].countryCode == 'US') {
            _this23.isAmericaShow = true;
            _this23.isProvinceShow = false;
          } else {
            _this23.isAmericaShow = false;
            _this23.isProvinceShow = true;
          }
          _this23.nameFlag = true;
          _this23.salesform.name = item.voList[0].orderInfoDetailList[0].name;
          _this23.salesform.telephone = item.voList[0].orderInfoDetailList[0].telephone;
          _this23.salesform.contactEmail = item.voList[0].orderInfoDetailList[0].contactEmail;
          _this23.salesform.address = item.voList[0].orderInfoDetailList[0].addressSingle;
          _this23.salesform.country = item.voList[0].orderInfoDetailList[0].country;
          _this23.salesform.countryId = item.voList[0].orderInfoDetailList[0].countryId;
          _this23.salesform.countryCode = item.voList[0].orderInfoDetailList[0].countryCode;
          _this23.salesform.provinceName = item.voList[0].orderInfoDetailList[0].provinceName;
          _this23.salesform.province = item.voList[0].orderInfoDetailList[0].province;
          _this23.salesform.city = item.voList[0].orderInfoDetailList[0].city;
          _this23.salesform.cityPostalCode = item.voList[0].orderInfoDetailList[0].cityPostalCode;
          _this23.salesform.equipmentNumber = item.voList[0].orderInfoDetailList[0].equipmentNumber;
          // this.equipmentNumberLists = item.voList[0].orderInfoDetailList[0].equipmentNumber.split(',');
          // let arr = [];
          // this.equipmentNumberLists.map((res) => {
          //   arr.push({ value: res });
          // });
          // this.equipmentNumberLists = arr;
        } else {
          _this23.$message({
            type: "error",
            message: item.errorMsg,
            center: true
          });
        }
      });
    } else {
      return false;
    }
  }), "addNumbers", function addNumbers() {
    this.equipmentNumberLists.push({
      value: ""
    });
  }), "removeNumbers", function removeNumbers(index) {
    this.equipmentNumberLists.splice(index, 1);
  }), "salesClose", function salesClose() {
    this.dialogFormVisibles = false;
    this.productNos = "";
    this.salesproductNo = "";
    this.salesform.orderLineCustomerList = [];
    this.showflag = false;
    this.nameFlag = false;
    this.counts = "";
    this.salesform.locationPosition = "";
    this.salesform.exchangeLocationPosition = "";
    this.startNumbers = "";
    this.startNumber1 = "";
    this.product = "1";
    this.exchangeproduct = "1";
    this.equipmentNumberLists = [];
    this.salesform.count = "";
    this.salesform.equipmentNumber = "";
    this.$refs.salesform.resetFields();
  }), "salesAdd", function salesAdd() {
    if (this.salesform.count == "" || this.productNos == "") {
      this.$message({
        type: "error",
        message: "请填写完整",
        center: true
      });
    } else {
      this.showflag = true;
      this.salesform.orderLineCustomerList.push({
        count: this.salesform.count,
        orderLineContainsCustomerList: [{
          count: '1',
          pictureUrl: "",
          productName: this.productName,
          productNameCn: this.productName,
          productNo: this.productNo,
          type: this.product
        }],
        orderLineId: 0,
        orderNo: this.salesform.orderNo,
        productName: this.productName,
        productNo: this.productNo
      });
      this.salesform.count = "";
      this.productNos = "";
      this.salesform.productNo = "";
      // this.startNumbers = ''
      // this.equipmentNumberLists = []
    }
  }), "changePosition", function changePosition(data) {
    var value = data.value,
      label = data.label;
    this.positionValue = value;
    this.positionValueCn = label;
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "exchangeAdd", function exchangeAdd() {
    if (this.counts == "" || this.salesform.position == '' || this.salesproductNo == '') {
      this.$message({
        type: "error",
        message: "请填写完整",
        center: true
      });
    } else {
      this.showflag = true;
      this.salesform.exchangeCustomerList.push({
        count: this.counts,
        orderLineContainsCustomerList: [{
          count: '1',
          pictureUrl: "",
          productName: this.salesproductName,
          productNameCn: this.salesproductName,
          productNo: this.salesproduct,
          type: this.exchangeproduct,
          position: this.positionValue,
          positionCn: this.positionValueCn
        }],
        positionCn: this.positionValueCn,
        orderLineId: 0,
        orderNo: this.salesform.orderNo,
        productName: this.salesproductName,
        productNo: this.salesproduct
      });
      this.counts = "";
      this.salesproductNo = "";
      this.salesform.position = '';
      // this.startNumber1 = ''
      // this.equipmentNumberList1 =[]
    }
  }), "salesRemove", function salesRemove(index) {
    this.salesform.orderLineCustomerList.splice(index, 1);
  }), "exchangeRemove", function exchangeRemove(index) {
    this.salesform.exchangeCustomerList.splice(index, 1);
  }), "keep", function keep() {
    var _this24 = this;
    this.$refs.salesform.validate(function (valid) {
      if (valid) {
        _this24.isDisable = true;
        _this24.sureLoading = true;
        if (_this24.salesform.exchangeCustomerList.length == 0 && _this24.exchangeNumber == "1") {
          _this24.$message({
            type: "warning",
            message: "设备号不能为空",
            center: true
          });
          _this24.sureLoading = false;
          _this24.isDisable = false;
          return false;
        } else if (_this24.salesform.exchangeCustomerList.length == 0) {
          _this24.$message({
            type: "warning",
            message: "换出摘要不能为空",
            center: true
          });
          _this24.sureLoading = false;
          _this24.isDisable = false;
          return false;
        } else if (_this24.salesform.orderLineCustomerList.length == 0 && _this24.salesform.returnType != '2') {
          _this24.$message({
            type: "warning",
            message: "退回摘要不能为空",
            center: true
          });
          _this24.sureLoading = false;
          _this24.isDisable = false;
          return false;
        } else {
          var newArrs = [];
          _this24.equipmentNumberLists.map(function (item, index) {
            newArrs.push(item.value);
          });
          var locationPosition = _this24.salesform.locationPosition;
          if (_this24.salesform.returnType != '2') {
            if (locationPosition == "") {
              _this24.$message({
                type: "warning",
                message: "请选择退回区域仓",
                center: true
              });
              _this24.sureLoading = false;
              _this24.isDisable = false;
              return false;
            }
          } else {
            locationPosition = '';
          }
          (0, _ordercustomer.replaceProduct)({
            costType: _this24.salesform.costType,
            customerRequest: _this24.salesform.customerRequest,
            deliverType: _this24.salesform.deliverType,
            exchangeCustomerList: _this24.salesform.exchangeCustomerList,
            orderLineCustomerList: _this24.salesform.orderLineCustomerList,
            orderNo: _this24.salesform.orderNo,
            locationPosition: locationPosition,
            exchangeLocationPosition: _this24.salesform.exchangeLocationPosition,
            price: _this24.salesform.price,
            remark: _this24.salesform.remark,
            returnType: _this24.salesform.returnType,
            checkService: "0",
            equipmentNumberList: newArrs,
            address: _this24.salesform.address,
            country: _this24.salesform.country,
            countryCode: _this24.salesform.countryCode,
            countryId: _this24.salesform.countryId,
            cityPostalCode: _this24.salesform.cityPostalCode,
            province: _this24.salesform.province,
            provinceName: _this24.salesform.provinceName,
            city: _this24.salesform.city,
            telephone: _this24.salesform.telephone,
            contactEmail: _this24.salesform.contactEmail,
            name: _this24.salesform.name,
            exchangeEquipmentNumberList: _this24.exchangeproduct == "1" && _this24.startNumber1 !== "" ? [_this24.startNumber1].concat((0, _toConsumableArray2.default)(newArr1)) : []
          }).then(function (res) {
            if (res.success) {
              _this24.msgTip("success", "操作成功");
              _this24.dialogFormVisibles = false;
              _this24.getList();
              _this24.salesform.orderLineCustomerList = [];
              _this24.salesform.exchangeCustomerList = [];
              _this24.$refs.salesform.resetFields();
              _this24.sureLoading = false;
              _this24.isDisable = false;
              _this24.isAmericaShow = false;
              _this24.isProvinceShow = true;
            } else if (res.errorCode == "E1621") {
              _this24.$confirm(res.errorMsg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
              }).then(function () {
                (0, _ordercustomer.replaceProduct)({
                  costType: _this24.salesform.costType,
                  customerRequest: _this24.salesform.customerRequest,
                  deliverType: _this24.salesform.deliverType,
                  exchangeCustomerList: _this24.salesform.exchangeCustomerList,
                  orderLineCustomerList: _this24.salesform.orderLineCustomerList,
                  orderNo: _this24.salesform.orderNo,
                  price: _this24.salesform.price,
                  remark: _this24.salesform.remark,
                  address: _this24.salesform.address,
                  country: _this24.salesform.country,
                  countryCode: _this24.salesform.countryCode,
                  countryId: _this24.salesform.countryId,
                  cityPostalCode: _this24.salesform.cityPostalCode,
                  province: _this24.salesform.province,
                  provinceName: _this24.salesform.provinceName,
                  city: _this24.salesform.city,
                  locationPosition: locationPosition,
                  exchangeLocationPosition: _this24.salesform.exchangeLocationPosition,
                  telephone: _this24.salesform.telephone,
                  contactEmail: _this24.salesform.contactEmail,
                  name: _this24.salesform.name,
                  returnType: _this24.salesform.returnType,
                  checkService: "1"
                }).then(function (item) {
                  if (item.success) {
                    _this24.dialogFormVisibles = false;
                    _this24.msgTip("success", "申请通过");
                    _this24.getList();
                    _this24.salesform.orderLineCustomerList = [];
                    _this24.isDisable = false;
                    _this24.sureLoading = false;
                    _this24.isAmericaShow = false;
                    _this24.isProvinceShow = true;
                  } else {
                    _this24.msgTip("error", res.errorMsg);
                    _this24.isDisable = false;
                    _this24.sureLoading = false;
                  }
                });
              }).catch(function () {
                _this24.isDisable = false;
              });
            } else {
              _this24.msgTip("error", res.errorMsg);
              _this24.isDisable = false;
              _this24.sureLoading = false;
            }
          });
        }
      }
    });
  }), "credit", function credit(v) {
    var _this25 = this;
    this.$confirm("您是否要放款?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      _this25.loading = true;
      (0, _ordercustomer.refundProduct)(v.customerNumber).then(function (res) {
        if (res.success) {
          _this25.loading = false;
          _this25.msgTip("success", "放款成功");
          _this25.getList();
        } else if (res.success == false) {
          _this25.loading = false;
          _this25.msgTip("warning", res.errorMsg);
        }
      });
    });
  }), "deriveCsv", function deriveCsv() {
    var _this26 = this;
    this.loading = true;
    (0, _ordercustomer.customerExportSkuCsv)(this.form).then(function (res) {
      var data = new Blob([res], {
        type: "application/csv;charset=GBK"
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = "order-customer-sku.csv";
      anchor.click();
      window.URL.revokeObjectURL(data);
      _this26.loading = false;
    });
  }), "logistics", function logistics(v) {
    var _this27 = this;
    this.dialogForm = true;
    this.logisticsform.customerNumber = v.customerNumber;
    (0, _dictionary.searchDic)({
      name: "LOGISTICS",
      pageNo: 1,
      pageSize: 1000,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this27.companylogistics = res.voList;
    });
  }), "addLogisticsNumber", function addLogisticsNumber() {
    this.equipmentNumberList1.push({
      text: ""
    });
  }), "removeLogisticsNumber", function removeLogisticsNumber(index) {
    this.equipmentNumberList1.splice(index, 1);
  }), "logisticsClose", function logisticsClose() {
    this.dialogForm = false;
    this.startNumber1 = "";
    this.equipmentNumberList1 = [];
    this.$refs.logisticsform.resetFields();
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "countrySelect", function countrySelect(data) {
    var value = data.value,
      label = data.label,
      code = data.code;
    this.salesform.countryId = value;
    this.salesform.country = label;
    this.salesform.countryCode = code;
    if (code == 'US') {
      this.isAmericaShow = true;
      this.salesform.provinceName = "";
      this.isProvinceShow = false;
    } else {
      this.isAmericaShow = false;
      this.salesform.provinceName = "";
      this.salesform.province = "";
      this.isProvinceShow = true;
    }
  }), "provinceSelect", function provinceSelect(data) {
    var value = data.value,
      code = data.code;
    this.salesform.provinceName = value;
    this.salesform.province = code;
  }), "keeps", function keeps() {
    var _this28 = this;
    this.$refs.logisticsform.validate(function (valid) {
      if (valid) {
        _this28.isDisable = true;
        _this28.sureLoading = true;
        var _newArr = [];
        _this28.equipmentNumberList1.map(function (item, index) {
          _newArr.push(item.text);
        });
        if (_this28.startNumber1 !== "") {
          _this28.logisticsform.exchangeEquipmentNumberList = [_this28.startNumber1].concat(_newArr);
        } else {
          _this28.logisticsform.exchangeEquipmentNumberList = [];
        }
        (0, _ordercustomer.returnlogistics)(_this28.logisticsform).then(function (res) {
          if (res.success) {
            _this28.msgTip("success", "操作成功");
            _this28.dialogForm = false;
            _this28.startNumber1 = "";
            _this28.equipmentNumberList1 = [];
            _this28.getList();
            _this28.$refs.logisticsform.resetFields();
            _this28.isDisable = false;
            _this28.sureLoading = false;
          } else {
            _this28.msgTip("error", res.errorMsg);
            _this28.isDisable = true;
            _this28.sureLoading = false;
          }
        });
      }
    });
  }), "undo", function undo(v) {
    var _this29 = this;
    //该售后订单为换出、且财务已审核、且换出仓是美国仓或者美国售后仓，不允许撤销
    if (v.type === "1" && v.financeReviewResult === "0" && (v.exchangeLocationPosition === "美国仓" || v.exchangeLocationPosition === "美国售后仓")) {
      this.$message({
        type: "warning",
        message: "换出区域仓是美国仓/美设售后仓，请操作完仓位调整后，重新撤销！",
        duration: '10000',
        center: true
      });
      return;
    }
    ;
    this.$confirm("您是否要撤销当前选中的记录?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }).then(function () {
      (0, _ordercustomer.undoProduct)(v.customerNumber).then(function (res) {
        if (res.success) {
          _this29.msgTip("success", "撤销成功");
          _this29.getList();
        } else if ("E1767" === res.errorCode) {
          _this29.$message({
            type: "success",
            message: res.errorMsg,
            duration: '10000',
            center: true
          });
          _this29.getList();
        } else {
          _this29.msgTip("error", res.errorMsg);
        }
      });
    });
  }), "msgTip", function msgTip(msgType, msgInfo) {
    this.$message({
      type: msgType,
      message: msgInfo,
      center: true
    });
  }), "examistatus", function examistatus(row) {
    if (row.checkStatus == "0") {
      return "不通过";
    } else if (row.checkStatus == "1") {
      return "通过";
    }
  }), "examine", function examine(v) {
    if (v.type == "0" && v.status == "1") {
      this.dialogformed = true;
      this.show = true;
      this.checkform.customerNumber = v.customerNumber;
      // this.unit = v.unit;
      // this.checkform.price = v.price;
    } else if (v.type == "1" && v.status == "1") {
      this.dialogformed = true;
      this.show = false;
      // this.checkform.price = "";
      // this.unit = "";
      this.checkform.customerNumber = v.customerNumber;
    } else {
      this.msgTip("warning", "仅售后状态是寄回状态可以验货");
      return;
    }
  }), "checkClose", function checkClose() {
    this.dialogformed = false;
    this.$refs.checkform.resetFields();
  }), "derive", function derive() {
    var _this30 = this;
    this.loading = true;
    (0, _ordercustomer.orderCustomerExport)(this.form).then(function (res) {
      var data = new Blob([res], {
        type: "application/vnd.ms-excel"
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = "OrderCustomer.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(data);
      _this30.loading = false;
    });
  }), "deriveSku", function deriveSku() {
    var _this31 = this;
    this.loading = true;
    (0, _ordercustomer.orderCustomerExportSku)(this.form).then(function (res) {
      var data = new Blob([res], {
        type: "application/vnd.ms-excel"
      });
      var downloadUrl = window.URL.createObjectURL(data);
      var anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = "orderCustomer-sku.xlsx";
      anchor.click();
      window.URL.revokeObjectURL(data);
      _this31.loading = false;
    });
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "administrative", function administrative(res) {
    var _this32 = this;
    this.dialogFormPrint = true;
    this.checkFlag = true;
    this.checkflages = false;
    this.ordertitle = "行政审核";
    this.idList = [];
    this.idList.push(res.id);
    this.idList = this.idList.reverse();
    (0, _ordercustomer.getPrint)({
      idList: this.idList
    }).then(function (item) {
      if (item.success) {
        _this32.forms = item.voList;
        _this32.printremark = item.voList[0].remark;
      } else {
        _this32.dialogFormPrint = false;
        _this32.msgTip("error", item.errorMsg);
      }
    });
  }), "finance", function finance(res) {
    var _this33 = this;
    this.dialogFormPrint = true;
    this.checkFlag = true;
    this.checkflages = false;
    this.ordertitle = "财务审核";
    this.idList = [];
    this.idList.push(res.id);
    this.idList = this.idList.reverse();
    (0, _ordercustomer.getPrint)({
      idList: this.idList
    }).then(function (item) {
      if (item.success) {
        _this33.forms = item.voList;
        _this33.printremark = item.voList[0].remark;
      } else {
        _this33.dialogFormPrint = false;
        _this33.msgTip("error", item.errorMsg);
      }
    });
  }), "prints", function prints() {
    var _this34 = this;
    if (this.multipleSelection.length < 1) {
      this.msgTip("error", "请选择一条数据");
      return;
    } else if (this.multipleSelection.length > 1) {
      this.msgTip("error", "只能选择一条数据");
      return;
    } else {
      this.multipleSelection.map(function (res) {
        if (res.type != "2") {
          _this34.msgTip("error", "目前仅支持全额退款打印！");
          return;
        }
        if (res.status == "5") {
          _this34.msgTip("error", "撤销状态不支持此操作！");
          return;
        }
        (0, _ordercustomer.getRefundPrint)(res.id).then(function (item) {
          if (item.success) {
            _this34.description = window.localStorage.getItem("name");
            _this34.dialogFormPrints = true;
            _this34.printforms = item.voT;
            _this34.returnOrderprint = item.voT.ordePrintLineList;
            _this34.returnReal = item.voT.realOrderList;
          } else {
            _this34.dialogFormPrints = false;
            _this34.msgTip("error", item.errorMsg);
          }
        });
      });
    }
  }), "printHtmls", function printHtmls() {
    var _this35 = this;
    this.dialogFormPrints = false;

    //取得浏览器的userAgent字符串
    var userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("trident") > -1) {
      alert("请使用google或者360浏览器打印");
      this.dialogFormPrint = false;
      return false;
    } else if (userAgent.indexOf("msie") > -1) {
      var onlyChoseAlert = simpleAlert({
        content: "请使用Google或者360浏览器打印",
        buttons: {
          确定: function 确定() {
            onlyChoseAlert.close();
          }
        }
      });
      alert("请使用google或者360浏览器打印");
      this.dialogFormPrints = false;
      return false;
    } else {
      //其它浏览器使用lodop
      // this.updateDto = []
      this.printforms.map(function (item) {
        item.ordePrintLineList.map(function (res) {
          console.log('1111111', _this35.serialNumber);
          /*this.updateDto.push({
            orderNo: res.orderNo,
            customerNumber: res.customerNumber,
            serialNumber: this.serialNumber,
            printType: "2",
          });*/
        });
      });
      /* updateList({orderPrintSerialDTO: this.updateDto}).then((res) => {
         if (res.success) {
           this.accountloading = false;
           this.$print(this.$refs.orderTable)
           localStorage.setItem("form", JSON.stringify(this.form));
          }
       });*/
    }
  }), "print", function print() {
    var _this36 = this;
    if (this.multipleSelection.length < 1) {
      this.msgTip("error", "请选择一条数据");
      return;
    } else {
      this.idList = [];
      this.multipleSelection.map(function (res, index) {
        _this36.idList.push(res.id);
      });
      this.idList = this.idList.reverse();
      (0, _ordercustomer.getPrint)({
        idList: this.idList
      }).then(function (item) {
        if (item.success) {
          _this36.dialogFormPrint = true;
          _this36.checkflages = true;
          _this36.ordertitle = "客诉单";
          _this36.checkFlag = false;
          _this36.forms = item.voList;
        } else {
          _this36.dialogFormPrint = false;
          _this36.msgTip("error", item.errorMsg);
        }
      });
    }
  }), "printClose", function printClose() {
    window.close();
    this.dialogFormPrint = false;
    this.accountloading = false;
    this.getList();
  }), "printHtml", function printHtml() {
    var _this37 = this;
    if (this.ordertitle == "客诉单") {
      this.dialogFormPrint = false;
      this.accountloading = true;
      //取得浏览器的userAgent字符串
      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf("trident") > -1) {
        alert("请使用google或者360浏览器打印");
        this.dialogFormPrint = false;
        this.accountloading = false;
        return false;
      } else if (userAgent.indexOf("msie") > -1) {
        var onlyChoseAlert = simpleAlert({
          content: "请使用Google或者360浏览器打印",
          buttons: {
            确定: function 确定() {
              onlyChoseAlert.close();
            }
          }
        });
        alert("请使用google或者360浏览器打印");
        this.dialogFormPrint = false;
        this.accountloading = false;
        return false;
      } else {
        //其它浏览器使用lodop
        this.forms.map(function (item) {
          _this37.updateDto.push({
            customerNumber: item.customerNumber,
            orderNo: item.orderNo,
            serialNumber: item.serialNumber,
            printType: "2"
          });
        });
        (0, _orderLogistics.updateList)({
          orderPrintSerialDTO: this.updateDto
        }).then(function (res) {
          if (res.success) {
            _this37.accountloading = false;
            _this37.$print(_this37.$refs.orderTable);
            localStorage.setItem("form", JSON.stringify(_this37.form));
          }
        });
      }
    } else if (this.ordertitle == "行政审核") {
      this.accountloading = true;
      (0, _ordercustomer.getReview)({
        customerNumber: this.forms[0].customerNumber,
        remark: this.printremark,
        reviewResult: this.reviewResult
      }).then(function (res) {
        if (res.success) {
          _this37.msgTip("success", "操作成功");
          _this37.dialogFormPrint = false;
          _this37.accountloading = false;
          _this37.getList();
        } else {
          _this37.msgTip("error", res.errorMsg);
          _this37.accountloading = false;
        }
      });
    } else if (this.ordertitle == "财务审核") {
      this.accountloading = true;
      (0, _ordercustomer.getFinance)({
        customerNumber: this.forms[0].customerNumber,
        remark: this.printremark,
        reviewResult: this.reviewResult,
        continueResult: "1"
      }).then(function (res) {
        if (res.success) {
          _this37.msgTip("success", "操作成功");
          _this37.dialogFormPrint = false;
          _this37.accountloading = false;
          _this37.getList();
        } else if (res.errorCode == "E1653" || res.errorCode == "E1654" || res.errorCode == "E1670") {
          _this37.$confirm(res.errorMsg, "提示", {
            confirmButtonText: "是",
            cancelButtonText: "否",
            type: "warning"
          }).then(function () {
            (0, _ordercustomer.getFinance)({
              customerNumber: _this37.forms[0].customerNumber,
              remark: _this37.printremark,
              reviewResult: _this37.reviewResult,
              continueResult: "0"
            }).then(function (res) {
              if (res.success) {
                _this37.msgTip("success", "操作成功");
                _this37.dialogFormPrint = false;
                _this37.accountloading = false;
                _this37.getList();
              } else {
                _this37.msgTip("error", res.errorMsg);
                _this37.accountloading = false;
              }
            });
          }).catch(function () {
            _this37.accountloading = false;
          });
        } else {
          _this37.msgTip("error", res.errorMsg);
          _this37.accountloading = false;
        }
      });
    }
  }))
};