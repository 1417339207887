"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notFound = exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
_vue.default.use(_vueRouter.default);

/* Layout */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/404'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  hidden: true,
  children: [{
    path: 'dashboard',
    name: 'Dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/index'));
      });
    },
    meta: {
      title: '主页',
      icon: 'dashboard',
      affix: true
    }
  }]
}];
var asyncRoutes = exports.asyncRoutes = [
//系统管理
{
  path: '/systems',
  component: _layout.default,
  redirect: '/systems/parameter',
  alwaysShow: true,
  // will always show the root menu
  name: 'parameter',
  meta: {
    title: '参数管理',
    icon: 'da' // you can set roles in root nav
  },
  children: [{
    path: 'dictionaries',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/systems/dictionaries.vue'));
      });
    },
    name: 'Dictionaries',
    meta: {
      title: '数据字典'
    }
  }]
},
//首页
{
  path: '/home',
  component: _layout.default,
  redirect: '/home/banner',
  alwaysShow: true,
  // will always show the root menu
  name: 'Home',
  meta: {
    title: '首页',
    icon: 'home' // you can set roles in root nav
  },
  children: [{
    path: 'banner',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/banner.vue'));
      });
    },
    name: 'Banner',
    meta: {
      title: '轮播图'
    }
  }, {
    path: 'userworks',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/userworks.vue'));
      });
    },
    name: 'Userworks',
    meta: {
      title: '用户作品'
    }
  }, {
    path: 'awards',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/awards.vue'));
      });
    },
    name: 'Awards',
    meta: {
      title: '荣获奖项轮播图'
    }
  }]
},
//超级笔记页
{
  path: '/product',
  component: _layout.default,
  redirect: '/product/productbuy',
  alwaysShow: true,
  // will always show the root menu
  name: 'product',
  meta: {
    title: '超级笔记页',
    icon: 'super' // you can set roles in root nav
  },
  children: [{
    path: 'productbuy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/productbuy.vue'));
      });
    },
    name: 'productbuy',
    meta: {
      title: '产品购买'
    }
  }, {
    path: 'addproductbuy',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/addproductbuy.vue'));
      });
    },
    name: 'Addproductbuy',
    meta: {
      title: '新增产品购买页'
    }
  }, {
    path: 'changeproductbuy',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/changeproductbuy.vue'));
      });
    },
    name: 'Changeproductbuy',
    meta: {
      title: '修改产品购买页'
    }
  }, {
    path: 'summarize',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/summarize.vue'));
      });
    },
    name: 'Summarize',
    meta: {
      title: '产品概述'
    }
  }, {
    path: 'addpro',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/addpro.vue'));
      });
    },
    name: 'Addpro',
    meta: {
      title: '新增产品概述页面'
    }
  }, {
    path: 'changepro',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/changepro.vue'));
      });
    },
    name: 'Addpro',
    meta: {
      title: '修改产品概述页面'
    }
  }, {
    path: 'video',
    hidden: false,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/video.vue'));
      });
    },
    name: 'Video',
    meta: {
      title: '产品视频'
    }
  }, {
    path: 'accservice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/accservice.vue'));
      });
    },
    name: 'Accservice',
    meta: {
      title: '附加服务'
    }
  },
  // {
  //     path: 'letterfont',
  //     component: () =>
  //         import ('@/views/product/letterfont.vue'),
  //     name: 'Letterfont',
  //     meta: {
  //         title: '刻字服务',
  //     }
  // },
  {
    path: 'additional',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/product/additional.vue'));
      });
    },
    name: 'Additional',
    meta: {
      title: '附加赠品'
    }
  }]
},
// //用户选配
// {
//     path: '/change',
//     component: Layout,
//     redirect: '/change/customization',
//     alwaysShow: true, // will always show the root menu
//     name: 'Change',
//     meta: {
//         title: '套餐定制',
//         icon: 'user', // you can set roles in root nav
//     },
//     children: [{
//             path: 'customization',
//             component: () =>
//                 import ('@/views/change/customization.vue'),
//             name: 'Customization',
//             meta: {
//                 title: '套餐定制',
//             }
//         },
//         // {
//         //     path: 'email',
//         //     component: () =>
//         //         import ('@/views/change/email.vue'),
//         //     name: 'Email',
//         //     meta: {
//         //         title: '邮件管理',
//         //     }
//         // },
//     ]
// },

//配件页
{
  path: '/parts',
  component: _layout.default,
  redirect: '/parts/list',
  alwaysShow: true,
  // will always show the root menu
  name: 'Parts',
  meta: {
    title: '配件页',
    icon: 'parts' // you can set roles in root nav
  },
  children: [{
    path: 'list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parts/list.vue'));
      });
    },
    name: 'List',
    meta: {
      title: '配件系列'
    }
  }, {
    path: 'partsMess',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parts/partsMess.vue'));
      });
    },
    name: 'PartsMess',
    meta: {
      title: '配件信息'
    }
  }, {
    path: 'addmess',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parts/addmess.vue'));
      });
    },
    name: 'Addmess',
    meta: {
      title: '新增配件信息页面'
    }
  }, {
    path: 'changemess',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parts/changemess.vue'));
      });
    },
    name: 'Changemess',
    meta: {
      title: '修改配件信息页面'
    }
  }, {
    path: 'addlist',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parts/addlist.vue'));
      });
    },
    name: 'Addlist',
    meta: {
      title: '新增配件系列页面'
    }
  }, {
    path: 'changelist',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/parts/changelist.vue'));
      });
    },
    name: 'Changelist',
    meta: {
      title: '修改配件系列页面'
    }
  }]
}, {
  path: '/standard',
  component: _layout.default,
  redirect: '/standard/standardPrice',
  alwaysShow: true,
  // will always show the root menu
  name: 'standard',
  meta: {
    title: '标准套餐',
    icon: 'standard' // you can set roles in root nav
  },
  children: [{
    path: '/standardPrice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/standard/standardPrice.vue'));
      });
    },
    name: 'standardPrice',
    meta: {
      title: '标准套餐'
    }
  }]
}, {
  path: '/price',
  component: _layout.default,
  redirect: '/price/packagePrice',
  alwaysShow: true,
  // will always show the root menu
  name: 'Price',
  meta: {
    title: '价格管理',
    icon: 'price' // you can set roles in root nav
  },
  children: [{
    path: '/packagePrice',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/price/packagePrice.vue'));
      });
    },
    name: 'packagePrice',
    meta: {
      title: '套餐价格'
    }
  }
  // {
  //     path: '/aloneprice',
  //     component: () =>
  //         import ('@/views/price/aloneprice.vue'),
  //     name: 'aloneprice',
  //     meta: {
  //         title: '单品价格',
  //     }
  // },
  ]
}, {
  path: '/presell',
  component: _layout.default,
  redirect: '/presell/presellInfo',
  alwaysShow: true,
  // will always show the root menu
  name: 'presell',
  meta: {
    title: '预售管理',
    icon: 'presell' // you can set roles in root nav
  },
  children: [{
    path: '/presellInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/presell/presellInfo.vue'));
      });
    },
    name: 'presellInfo',
    meta: {
      title: '预售信息'
    }
  }]
}, {
  path: '/gallery',
  component: _layout.default,
  redirect: '/gallery/gallerys',
  alwaysShow: true,
  // will always show the root menu
  name: 'gallery',
  meta: {
    title: '产品画廊',
    icon: 'gallery' // you can set roles in root nav
  },
  children: [{
    path: '/gallery/gallerys',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/gallery/gallerys.vue'));
      });
    },
    name: 'gallerys',
    meta: {
      title: '产品画廊'
    }
  }, {
    path: '/gallery/addgallery',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/gallery/addgallery.vue'));
      });
    },
    name: 'addgallery',
    meta: {
      title: '新增产品画廊页面'
    }
  }, {
    path: '/gallery/changegallery',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/gallery/changegallery.vue'));
      });
    },
    name: 'changegallery',
    meta: {
      title: '修改产品画廊页面'
    }
  }]
},
//服务页
{
  path: '/serve',
  component: _layout.default,
  redirect: '/serve/search',
  alwaysShow: true,
  // will always show the root menu
  name: 'Serve',
  meta: {
    title: '服务页',
    icon: 'serve' // you can set roles in root nav
  },
  children: [{
    path: 'faq',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/faq.vue'));
      });
    },
    name: 'Faq',
    meta: {
      title: 'FAQ/FMQ标题'
    }
  }, {
    path: 'qamess',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/qamess.vue'));
      });
    },
    name: 'Qamess',
    meta: {
      title: 'FAQ/FMQ内容'
    }
  }, {
    path: 'detail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/detail.vue'));
      });
    },
    name: 'Detail',
    meta: {
      title: '说明书/固件'
    }
  }, {
    path: 'addqamess',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/addqamess.vue'));
      });
    },
    name: 'Addqamess',
    meta: {
      title: '新增内容页面'
    }
  }, {
    path: 'changeqamess',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/changeqamess.vue'));
      });
    },
    name: 'Changeqamess',
    meta: {
      title: '修改内容页面'
    }
  }, {
    path: 'adddetail',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/adddetail.vue'));
      });
    },
    name: 'Adddetail',
    meta: {
      title: '新增说明书/固件页面'
    }
  }, {
    path: 'changedetail',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/changedetail.vue'));
      });
    },
    name: 'Changedetail',
    meta: {
      title: '修改说明书/固件页面'
    }
  }, {
    path: 'feedback',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/feedback.vue'));
      });
    },
    name: 'Feedback',
    meta: {
      title: '反馈信息'
    }
  }, {
    path: 'app',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/app.vue'));
      });
    },
    name: 'App',
    meta: {
      title: 'App'
    }
  }, {
    path: 'addapp',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/addapp.vue'));
      });
    },
    name: 'addapp',
    meta: {
      title: '新增APP页面'
    }
  }, {
    path: 'changeapp',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/serve/changeapp.vue'));
      });
    },
    name: 'Changeapp',
    meta: {
      title: '修改APP页面'
    }
  }]
},
//博客
{
  path: '/blog',
  component: _layout.default,
  redirect: '/blog/blogList',
  alwaysShow: true,
  // will always show the root menu
  name: 'Blog',
  meta: {
    title: '博客',
    icon: 'blog' // you can set roles in root nav
  },
  children: [{
    path: 'blogList',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/blog/blogList.vue'));
      });
    },
    name: 'BlogList',
    meta: {
      title: '博客列表'
    }
  }, {
    path: 'comment',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/blog/comment.vue'));
      });
    },
    name: 'Comment',
    meta: {
      title: '评论'
    }
  }, {
    path: 'addblog',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/blog/addBlog.vue'));
      });
    },
    name: 'Addblog',
    meta: {
      title: '新增博客页面'
    }
  }, {
    path: 'changeblog',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/blog/changeBlog.vue'));
      });
    },
    name: 'Changeblog',
    meta: {
      title: '修改博客页面'
    }
  }]
},
//关于
{
  path: '/about',
  component: _layout.default,
  redirect: '/about/abouts',
  alwaysShow: true,
  // will always show the root menu
  name: 'About',
  meta: {
    title: '关于',
    icon: 'about' // you can set roles in root nav
  },
  children: [{
    path: '/abouts',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/about/abouts.vue'));
      });
    },
    name: 'Abouts',
    meta: {
      title: '关于'
    }
  }, {
    path: '/addabout',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/about/addabout.vue'));
      });
    },
    name: 'AddAbout',
    meta: {
      title: '添加关于页面'
    }
  }, {
    path: '/changeabout',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/about/changeabout.vue'));
      });
    },
    name: 'Changeabout',
    meta: {
      title: '修改关于页面'
    }
  }]
},
//seo
{
  path: '/seo',
  component: _layout.default,
  redirect: '/seo/overallseo',
  alwaysShow: true,
  // will always show the root menu
  name: 'Seo',
  meta: {
    title: 'SEO',
    icon: 'seo' // you can set roles in root nav
  },
  children: [{
    path: '/overallseo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/seo/overallseo.vue'));
      });
    },
    name: 'Resourc',
    meta: {
      title: '全局SEO'
    }
  }]
},
//优惠管理
{
  path: '/sall',
  component: _layout.default,
  redirect: '/sall/sallRules',
  alwaysShow: true,
  // will always show the root menu
  name: 'sall',
  meta: {
    title: '优惠码管理',
    icon: 'sall' // you can set roles in root nav
  },
  children: [{
    path: '/sallRules',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sall/sallRules.vue'));
      });
    },
    name: 'sallRules',
    meta: {
      title: '优惠规则'
    }
  }, {
    path: '/sallCode',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/sall/sallCode.vue'));
      });
    },
    name: 'sallCode',
    meta: {
      title: '优惠码'
    }
  }]
},
//库存管理
{
  path: '/inventory',
  component: _layout.default,
  redirect: '/inventory/dealerStock',
  alwaysShow: true,
  // will always show the root menu
  name: 'inventory',
  meta: {
    title: '库存管理',
    icon: 'dealer' // you can set roles in root nav
  },
  children: [{
    path: '/dealerStock',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/inventory/dealerStock.vue'));
      });
    },
    name: 'dealerStock',
    meta: {
      title: '渠道库存管理'
    }
  }, {
    path: '/dealerDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/inventory/dealerDetail.vue'));
      });
    },
    name: 'dealerDetail',
    meta: {
      title: '渠道出入库明细'
    }
  }]
},
//订单信息
{
  path: '/order',
  component: _layout.default,
  redirect: '/order/orderInfo',
  alwaysShow: true,
  // will always show the root menu
  name: 'order',
  meta: {
    title: '订单服务',
    icon: 'info' // you can set roles in root nav
  },
  children: [{
    path: '/orderInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderInfo.vue'));
      });
    },
    name: 'orderInfo',
    meta: {
      title: '订单信息'
    }
  },
  // {
  //     path: '/orderInvoice',
  //     component: () =>
  //         import ('@/views/order/orderInvoice.vue'),
  //     name: 'orderInvoice',
  //     meta: {
  //         title: '订单发票',
  //     }
  // }, 
  {
    path: '/orderLine',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderLine.vue'));
      });
    },
    name: 'orderLine',
    meta: {
      title: '订单包'
    }
  }, {
    path: '/orderLogistics',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderLogistics.vue'));
      });
    },
    name: 'orderLogistics',
    meta: {
      title: '订单物流'
    }
  },
  // {
  //     path: '/orderReceive',
  //     component: () =>
  //         import ('@/views/order/orderReceive.vue'),
  //     name: 'orderReceive',
  //     meta: {
  //         title: '订单收货信息',
  //     }
  // },
  {
    path: '/orderCustomer',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderCustomer.vue'));
      });
    },
    name: 'orderCustomer',
    meta: {
      title: '订单售后服务'
    }
  }, {
    path: '/orderLife',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderLife.vue'));
      });
    },
    name: 'orderLife',
    meta: {
      title: '订单生命周期'
    }
  }, {
    path: '/waybillInfo',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/waybillInfo.vue'));
      });
    },
    name: 'waybillInfo',
    meta: {
      title: '运单信息'
    }
  }, {
    path: '/orderSettle',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderSettle.vue'));
      });
    },
    name: 'orderSettle',
    meta: {
      title: '财务结算'
    }
  }, {
    path: '/orderRepair',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderRepair.vue'));
      });
    },
    name: 'orderRepair',
    meta: {
      title: '维修记录'
    }
  }, {
    path: '/orderSales',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/order/orderSales.vue'));
      });
    },
    name: 'orderSales',
    meta: {
      title: '售后维修'
    }
  }
  // {
  //     path: '/orderSaleMessage',
  //     component: () =>
  //         import ('@/views/order/orderSaleMessage.vue'),
  //     name: 'orderSaleMessage',
  //     meta: {
  //         title: '售后维修信息',
  //     }
  // },
  // {
  //     path: '/ordersale',
  //     component: () =>
  //         import ('@/views/order/ordersale.vue'),
  //     name: 'ordersale',
  //     meta: {
  //         title: '订单售后包',
  //     }
  // }
  ]
},
//邮箱管理
{
  path: '/email',
  component: _layout.default,
  redirect: '/email/contact',
  alwaysShow: true,
  // will always show the root menu
  name: 'Email',
  meta: {
    title: '邮箱管理',
    icon: 'emails' // you can set roles in root nav
  },
  children: [{
    path: '/contact',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/email/contact.vue'));
      });
    },
    name: 'Contact',
    meta: {
      title: '联系邮箱'
    }
  }
  // {
  //     path: '/content',
  //     component: () =>
  //         import ('@/views/email/content.vue'),
  //     name: 'Contact',
  //     meta: {
  //         title: '邮箱发布内容',
  //     }
  // }, {
  //     path: '/addcontent',
  //     hidden: true,
  //     component: () =>
  //         import ('@/views/email/addcontent.vue'),
  //     name: 'AddContent',
  //     meta: {
  //         title: '新增邮箱发布内容页面',
  //     }
  // }, {
  //     path: '/changecontent',
  //     hidden: true,
  //     component: () =>
  //         import ('@/views/email/changecontent.vue'),
  //     name: 'Changecontent',
  //     meta: {
  //         title: '修改邮箱发布内容页面',
  //     }
  // }, {
  //     path: '/subscribe',
  //     component: () =>
  //         import ('@/views/email/subscribe.vue'),
  //     name: 'Subscribe',
  //     meta: {
  //         title: '订阅邮箱列表',
  //     }
  // }
  ]
}, {
  path: '/pricetrend',
  component: _layout.default,
  redirect: '/pricetrend/trends',
  alwaysShow: true,
  // will always show the root menu
  name: 'PriceTrend',
  meta: {
    title: '价格走势',
    icon: 'trend' // you can set roles in root nav
  },
  children: [{
    path: '/trends',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pricetrend/trends.vue'));
      });
    },
    name: 'trends',
    meta: {
      title: '价格走势'
    }
  }, {
    path: '/addtrends',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pricetrend/addtrends.vue'));
      });
    },
    name: 'Addtrends',
    meta: {
      title: '新增价格走势页面'
    }
  }, {
    path: '/changetrends',
    hidden: true,
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/pricetrend/changetrends.vue'));
      });
    },
    name: 'Chanegtrends',
    meta: {
      title: '修改价格走势页面'
    }
  }]
},
//订单报表统计
{
  path: '/statement',
  component: _layout.default,
  redirect: '/statement/orderStatement',
  alwaysShow: true,
  // will always show the root menu
  name: 'Statement',
  meta: {
    title: '订单报表统计',
    icon: 'statement' // you can set roles in root nav
  },
  children: [{
    path: '/orderStatement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/statement/orderStatement.vue'));
      });
    },
    name: 'orderStatement',
    meta: {
      title: '商品出货量'
    }
  }]
},
//图标统计
{
  path: '/charts',
  component: _layout.default,
  redirect: '/charts/allcharts',
  alwaysShow: true,
  // will always show the root menu
  name: 'Charts',
  meta: {
    title: '图表统计',
    icon: 'charts' // you can set roles in root nav
  },
  children: [{
    path: '/allcharts',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/charts/allcharts.vue'));
      });
    },
    name: 'allcharts',
    meta: {
      title: '图表统计'
    }
  }]
}

// 404 page must be placed at the end !!!
];
var notFound = exports.notFound = [{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;