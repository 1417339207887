"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeList = changeList;
exports.dataManipulation = dataManipulation;
exports.getLists = getLists;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询问题反馈信息
function getLists(data) {
  return (0, _request.default)({
    url: 'cms/feedback/record/list',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改问题反馈处理状态和备注
function changeList(id, data) {
  return (0, _request.default)({
    url: 'cms/feedback/record/part/' + id,
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

/**
 * 前端刷新公共方法
 */
function dataManipulation(tableData, id, type, line, updateTime, updateUser) {
  tableData.map(function (element) {
    //如果是单选的直接用if判断id是否相等就可以了,如果是复选框的就需要嵌套for循环判断
    if (element.id == id) {
      if (updateUser != null && updateUser != "") {
        element.updateUser = updateUser;
      }
      if (updateTime != null && updateTime != "") {
        element.updateTime = updateTime;
      }
      if (line != null && line != "") {
        element.line = line;
      }
      if (type != null && type != "") {
        element.type = type;
      }
    }
  });
  return tableData;
}