"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _orderRepair = require("../../api/orderRepair.js");
var _orderLogistics = require("../../api/orderLogistics.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      form: {
        areThereFees: "",
        type: "",
        pageNo: 1,
        pageSize: 10,
        equipmentNumber: '',
        serialNumber: ''
      },
      isareThereFees: [{
        value: "Y",
        valueCn: "是"
      }, {
        value: "N",
        valueCn: "否"
      }],
      types: [{
        value: "0",
        valueCn: "保内"
      }, {
        value: "1",
        valueCn: "保外"
      }],
      addrules: {
        areThereFees: [{
          required: true,
          trigger: "change"
        }],
        type: [{
          required: true,
          trigger: "change"
        }]
      },
      loading: false,
      tableData: [],
      currentPage: 1,
      total: 0,
      datas: [],
      loadings: false,
      load: false,
      sureLoading: false,
      dialogFormVisible: false,
      formLabelWidth: '100px',
      isDisable: false,
      addform: {
        areThereFees: '',
        badMalfunction: '',
        badReason: '',
        equipmentNumber: '',
        measure: '',
        remark: '',
        repairTime: '',
        resList: '',
        type: ''
      },
      addTitle: ''
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    //查询列表
    getList: function getList() {
      var _this = this;
      this.loading = true;
      setTimeout(function () {
        (0, _orderRepair.getLists)(_this.form).then(function (res) {
          if (res.voList == null || res.voList == "") {
            _this.form.pageNo = 1;
            _this.currentPage = 1;
            (0, _orderRepair.getLists)(_this.form).then(function (res) {
              _this.tableData = res.voList;
              _this.total = Number(res.total);
              _this.loading = false;
            });
          }
          _this.tableData = res.voList;
          _this.total = Number(res.total);
          _this.loading = false;
        });
      }, 500);
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.getList();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //判断是否收费
    areFlag: function areFlag(v) {
      if (v.areThereFees == 'Y') {
        return '是';
      } else if (v.areThereFees == 'N') {
        return '否';
      }
    },
    //判断结算状态
    typeFlag: function typeFlag(v) {
      if (v.type == '0') {
        return '保内';
      } else if (v.type == '1') {
        return '保外';
      }
    },
    //查询
    search: function search() {
      this.getList();
    },
    //清空
    empty: function empty() {
      this.form.areThereFees = '';
      this.form.equipmentNumber = '';
      this.form.serialNumber = '';
      this.form.type = '';
      this.getList();
    },
    //新增
    add: function add() {
      this.dialogFormVisible = true;
      this.addTitle = '新增';
    },
    //确定按钮
    sure: function sure() {
      var _this2 = this;
      this.$refs.addform.validate(function (valid) {
        if (valid) {
          _this2.isDisable = true;
          _this2.sureLoading = true;
          if (_this2.addTitle == '新增') {
            (0, _orderRepair.addRepair)(_this2.addform).then(function (res) {
              if (res.success) {
                _this2.msgTip("success", "操作成功");
                _this2.dialogFormVisible = false;
                _this2.getList();
                _this2.$refs.addform.resetFields();
                _this2.isDisable = false;
                _this2.sureLoading = false;
              } else {
                _this2.msgTip("error", res.errorMsg);
                _this2.isDisable = false;
                _this2.sureLoading = false;
              }
            });
          } else {
            (0, _orderRepair.changeRepair)(_this2.addform).then(function (res) {
              if (res.success) {
                _this2.msgTip("success", "操作成功");
                _this2.dialogFormVisible = false;
                _this2.getList();
                _this2.$refs.addform.resetFields();
                _this2.isDisable = false;
                _this2.sureLoading = false;
              } else {
                _this2.msgTip("error", res.errorMsg);
                _this2.isDisable = false;
                _this2.sureLoading = false;
              }
            });
          }
        }
      });
    },
    addClose: function addClose() {
      this.$refs.addform.resetFields();
      this.dialogFormVisible = false;
      this.addform.remark = '';
      this.addform.areThereFees = '';
      this.addform.badMalfunction = '';
      this.addform.equipmentNumber = '';
      this.addform.measure = '';
      this.addform.repairTime = '';
      this.addform.resList = '';
      this.addform.type = '';
    },
    //修改
    change: function change(v) {
      var _this3 = this;
      this.dialogFormVisible = true;
      this.addTitle = '修改';
      (0, _orderRepair.aloneRepair)(v.id).then(function (res) {
        if (res.success) {
          _this3.addform = res.voT;
        } else {
          _this3.msgTip("warning", res.errorMsg);
        }
      });
    },
    //删除
    dele: function dele(v) {
      var _this4 = this;
      this.$confirm("您是否要删除当前选中的记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _orderRepair.deleRepair)(v.id).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "操作成功");
            _this4.getList();
          } else {
            _this4.msgTip("warning", res.errorMsg);
          }
        });
      });
    },
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};