"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _charts = require("../../api/charts.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  props: {
    countryArr: {
      type: Array
    }
  },
  data: function data() {
    return {
      tableData: []
    };
  },
  watch: {
    countryArr: {
      //深度监听，可监听到对象、数组的变化
      handler: function handler(val, oldVal) {
        this.tableData = val;
      }
    }
  },
  mounted: function mounted() {
    this.tableData = this.countryArr;
  },
  methods: {
    tableHeaderColor: function tableHeaderColor(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (rowIndex === 0) {
        return 'color: #188df0;font-weight: 800;font-size:16px;text-align:center';
      }
    }
  }
};