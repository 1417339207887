"use strict";

var _interopRequireWildcard = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _waybillInfo = require("../../api/waybillInfo.js");
var _dictionary = require("../../api/dictionary.js");
var math = _interopRequireWildcard(require("mathjs"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  data: function data() {
    return {
      formLabelWidth: "100px",
      dialogFormVisible: false,
      form: {
        billDate: "",
        dealer: "",
        pageNo: 1,
        pageSize: 10,
        flag: "",
        logisticsName: "",
        logisticsNumber: "",
        orderNo: "",
        startPayDate: "",
        endPayDate: ""
      },
      forms: {
        billDate: "",
        dealer: "",
        pageNo: 1,
        pageSize: 10,
        flag: "",
        logisticsName: "",
        logisticsNumber: "",
        orderNo: "",
        startPayDate: "",
        endPayDate: ""
      },
      forms1: {
        pageNo: 0,
        pageSize: 0,
        id: ""
      },
      isDisable: false,
      loading: false,
      isShow: false,
      tableData: [],
      total: 0,
      currentPage: 1,
      status: "",
      id: "",
      dealers: [],
      types: [{
        value: "0",
        valueCn: "正常"
      }, {
        value: "1",
        valueCn: "异常"
      }],
      changeForm: {
        orderNo: "",
        remark: "",
        type: "",
        outTax: "",
        inTax: ""
      },
      changeRules: {
        // orderNo: [{ required: true, trigger: "change" }],
        type: [{
          required: true,
          trigger: "blur"
        }],
        inTax: [{
          required: true,
          trigger: "blur"
        }],
        outTax: [{
          required: true,
          trigger: "blur"
        }]
      },
      changeType: [{
        value: "1",
        valueCn: "销售"
      }, {
        value: "2",
        valueCn: "售后"
      }, {
        value: "3",
        valueCn: "行政"
      }, {
        value: "4",
        valueCn: "售后维修"
      }],
      dialogPrintForm: false,
      radio: 2,
      companylogistics: [],
      printform: {
        applyDate: "",
        applyMoney: "",
        dealerPriceList: [],
        lowerTotalPrice: "",
        upperTotalPrice: "",
        applyUser: ""
      },
      dialogImportForm: false,
      importForm: {
        billDate: "",
        logisticsName: ""
      },
      importRules: {
        billDate: [{
          required: true,
          trigger: "change"
        }],
        logisticsName: [{
          required: true,
          trigger: "change"
        }]
      },
      multipleSelection: [],
      idList: [],
      title: "",
      uploadFlag: false,
      keepFlag: false,
      logisticsNumber: "",
      printFlag: true,
      market: "市场部",
      people: "",
      receiver: "",
      payDate: "",
      bankNumber: "",
      bank: "",
      dealerAll: "渠道汇总",
      mannger: "",
      finance: "",
      section: "",
      money: ""
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    this.getList();
    (0, _dictionary.searchDic)({
      name: "DEALER",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.dealers = res.voList;
    });
    (0, _dictionary.searchDic)({
      name: "LOGISTICS",
      pageNo: 1,
      pageSize: 100,
      sortField: "",
      sortRules: "",
      valueMeaning: ""
    }).then(function (res) {
      _this.companylogistics = res.voList;
    });
  },
  directives: {
    // 注册一个局部的自定义指令 v-focus
    focus: {
      // 指令的定义
      inserted: function inserted(el) {
        // 聚焦元素
        el.querySelector("input").focus();
      }
    }
  },
  methods: {
    //列表
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        (0, _waybillInfo.getLists)(_this2.form).then(function (res) {
          if (res.success) {
            _this2.tableData = res.voList;
            _this2.total = res.total;
            _this2.loading = false;
          }
        });
      }, 500);
    },
    //弹框关闭
    changeClose: function changeClose() {
      this.dialogFormVisible = false;
      this.$refs.changeForm.resetFields();
    },
    //查询
    search: function search() {
      this.getList();
    },
    //判断类型
    typeflag: function typeflag(row) {
      if (row.flag == "0") {
        return "正常";
      } else if (row.flag == "1") {
        return "异常";
      }
    },
    //清空
    empty: function empty() {
      this.form.billDate = "";
      this.form.dealer = "";
      this.form.flag = "";
      this.form.logisticsName = "";
      this.form.logisticsNumber = "";
      this.form.orderNo = "";
      this.forms.billDate = "";
      this.forms.dealer = "";
      this.forms.flag = "";
      this.forms.logisticsName = "";
      this.forms.logisticsNumber = "";
      this.forms.orderNo = "";
      this.forms.pageNo = 1;
      this.form.startPayDate = "", this.form.endPayDate = "";
      this.getList();
    },
    //导入
    importLists: function importLists() {
      this.dialogImportForm = true;
      this.title = "导入";
      this.uploadFlag = true;
      this.keepFlag = false;
    },
    importClose: function importClose() {
      this.dialogImportForm = false;
      this.importForm.billDate = "";
      this.importForm.logisticsName = "";
    },
    //导出
    derive: function derive() {
      var _this3 = this;
      this.loading = true;
      (0, _waybillInfo.orderWaybillExport)(this.form).then(function (res) {
        var data = new Blob([res], {
          type: "application/csv;charset=GBK"
        });
        var downloadUrl = window.URL.createObjectURL(data);
        var anchor = document.createElement("a");
        anchor.href = downloadUrl;
        anchor.download = "waybill.csv";
        anchor.click();
        window.URL.revokeObjectURL(data);
        _this3.loading = false;
      });
    },
    outUpload: function outUpload() {
      var _this4 = this;
      var fileData = this.$refs.outFile.files[0];
      var formFile = new FormData(); //加入文件对象
      formFile.append("file", fileData);
      formFile.append("billDate", this.importForm.billDate);
      formFile.append("logisticsName", this.importForm.logisticsName);
      var name = fileData.name.split(".")[fileData.name.split(".").length - 1];
      // this.isRepeat = true;
      if (name !== "xlsx") {
        this.msgTip("error", "文件格式仅支持xlsx！");
        return false;
      } else if (this.importForm.billDate == "" || this.importForm.logisticsName == "") {
        this.msgTip("error", "请填写完整！");
        this.$refs.outFile.value = [];
        return false;
      } else {
        this.loading = true;
        (0, _waybillInfo.importList)(formFile).then(function (res) {
          if (res.success) {
            _this4.msgTip("success", "操作成功");
            _this4.dialogImportForm = false;
            _this4.getList();
            _this4.$refs.importForm.resetFields();
            _this4.$refs.outFile.value = [];
            // this.isDisable = false;
            _this4.loading = false;
          } else if (res.errorCode == "E1671") {
            _this4.$message({
              showClose: true,
              type: "error",
              message: res.errorMsg,
              center: true,
              duration: 600000
            });
          } else {
            _this4.msgTip("error", res.errorMsg);
            _this4.$refs.outFile.value = [];
            _this4.loading = false;
            // this.isDisable = false;
          }
        });
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      var _this5 = this;
      this.multipleSelection = val;
      this.idList = [];
      val.map(function (item) {
        _this5.idList.push(item.id);
      });
    },
    arrTrans: function arrTrans(num, arr) {
      // 一维数组转换为二维数组
      var iconsArr = []; // 声明数组
      arr.forEach(function (item, index) {
        var page = Math.floor(index / num); // 计算该元素为第几个素组内
        if (!iconsArr[page]) {
          // 判断是否存在
          iconsArr[page] = [];
        }
        iconsArr[page].push(item);
      });
      return iconsArr;
    },
    //打印
    print: function print() {
      var _this6 = this;
      if (this.form.billDate == "" || this.form.logisticsName == "") {
        this.msgTip("error", "请选择物流公司与账单日");
        return false;
      } else {
        (0, _waybillInfo.printList)({
          billDate: this.form.billDate,
          logisticsName: this.form.logisticsName
        }).then(function (res) {
          if (res.success) {
            _this6.dialogPrintForm = true;
            _this6.printform = res.voT;

            // this.printform.arr1 = this.arrTrans(3, this.printform.dealerPriceList)
            // this.printform.arr1 =
          } else {
            _this6.dialogPrintForm = false;
            _this6.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //更改运费
    changeValue: function changeValue() {
      var _this7 = this;
      this.printform.lowerTotalPrice = 0;
      this.printform.dealerPriceList.map(function (item) {
        _this7.printform.lowerTotalPrice += Number(item.value) + Number(item.inTax) + Number(item.outTax);
      });
      this.printform.upperTotalPrice = this.digitUppercase(this.printform.lowerTotalPrice);
      this.printform.lowerTotalPrice = Number(math.format(this.printform.lowerTotalPrice, 14));
    },
    //更改进口关税
    //  changeIntax(v){
    //   this.printform.dealerPriceList.map(item=>{
    //       const reducer = (accumulator, currentValue) => accumulator + Number(currentValue.value);
    //       this.printform.lowerTotalPrice =math.format(this.printform.dealerPriceList.reduce(reducer,0) + Number(item.inTax) + Number(item.outTax),14)
    //        this.printform.upperTotalPrice = this.digitUppercase(this.printform.lowerTotalPrice)
    //     })
    // },
    // //更改出口关税
    // changeOuttax(val){
    //    this.printform.dealerPriceList.map(item=>{
    //         const reducer = (accumulator, currentValue) => accumulator + Number(currentValue.outTax);
    //       this.printform.lowerTotalPrice = this.printform.dealerPriceList.reduce(reducer, 0) + Number(item.inTax) + Number(item.value)
    //        this.printform.upperTotalPrice = this.digitUppercase(this.printform.lowerTotalPrice)
    //     })
    // },
    //小写转大写
    digitUppercase: function digitUppercase(amount) {
      //形参
      // 汉字的数字
      var cnNums = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
      // 基本单位
      var cnIntRadice = ["", "拾", "佰", "仟"];
      // 对应整数部分扩展单位
      var cnIntUnits = ["", "万", "亿", "兆"];
      // 对应小数部分单位
      var cnDecUnits = ["角", "分"];
      // 整数金额时后面跟的字符
      var cnInteger = "整";
      // 整型完以后的单位
      var cnIntLast = "元";
      // 最大处理的数字
      var maxNum = 9999999999999999.99;
      // 金额整数部分
      var integerNum;
      // 金额小数部分
      var decimalNum;
      // 输出的中文金额字符串
      var chineseStr = "";
      // 分离金额后用的数组，预定义
      var parts;
      if (amount === "") {
        return "";
      }
      amount = parseFloat(amount);
      if (amount >= maxNum) {
        // 超出最大处理数字
        return "";
      }
      if (amount === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      // 转换为字符串
      amount = amount.toString();
      if (amount.indexOf(".") === -1) {
        integerNum = amount;
        decimalNum = "";
      } else {
        parts = amount.split(".");
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      // 获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n === "0") {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            // 归零
            zeroCount = 0;
            //alert(cnNums[parseInt(n)])
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m === 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      // 小数部分
      if (decimalNum !== "") {
        var decLen = decimalNum.length;
        for (var _i = 0; _i < decLen; _i++) {
          var _n = decimalNum.substr(_i, 1);
          if (_n !== "0") {
            chineseStr += cnNums[Number(_n)] + cnDecUnits[_i];
          }
        }
      }
      if (chineseStr === "") {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum === "") {
        chineseStr += cnInteger;
      }
      return chineseStr;
    },
    //打印关闭
    printClose: function printClose() {
      this.dialogPrintForm = false;
    },
    printSure: function printSure() {
      var _this8 = this;
      this.dialogPrintForm = false;
      //取得浏览器的userAgent字符串
      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf("trident") > -1) {
        alert("请使用google或者360浏览器打印");
        this.dialogPrintForm = false;
        return false;
      } else if (userAgent.indexOf("msie") > -1) {
        var onlyChoseAlert = simpleAlert({
          content: "请使用Google或者360浏览器打印",
          buttons: {
            确定: function 确定() {
              onlyChoseAlert.close();
            }
          }
        });
        alert("请使用google或者360浏览器打印");
        this.dialogPrintForm = false;
        return false;
      } else {
        //其它浏览器使用lodop
        this.printFlag = false;
        setTimeout(function () {
          var oldstr = document.body.innerHTML;
          var headstr = "<html><head><title></title></head><body>";
          var footstr = "</body></html>";
          //执行隐藏打印区域不需要打印的内容
          //document.getElementById("otherpho").style.display="none";
          var checkInputs = document.querySelectorAll("input:checked"); // 获取选中的input
          var substr = "";
          var check = "";
          for (var i = 0; i < checkInputs.length; i++) {
            var v = checkInputs[i].value;
            if (v) {
              substr = new RegExp("value=\"".concat(v, "\""));
              check = v;
            }
          }
          var printData = document.getElementById("printForm").innerHTML; //获得 div 里的所有 html 数据
          // var str = printData.replace('checked="checked"', '')
          // var str1 = str.replace(substr, `value="${check}" checked="checked"`)
          var wind = window.open("", "newwin", "toolbar=no,scrollbars=yes,menubar=no");
          wind.document.body.innerHTML = printData;
          wind.print();
          //打印结束后，放开隐藏内容
          _this8.dialogPrintForm = false;
          //  localStorage.setItem('form',JSON.stringify(this.formArr))
          //document.getElementById("otherpho").style.display="block";
          //window.close();
          parent.location.reload();
          // window.document.body.innerHTML = oldstr;
        }, 1000);
      }
    },
    change: function change(e) {
      var _this9 = this;
      this.id = e.id;
      this.dialogFormVisible = true;
      (0, _waybillInfo.aloneList)(e.id).then(function (res) {
        if (res.success) {
          _this9.changeForm = res.voT;
          _this9.logisticsNumber = res.voT.logisticsNumber;
        } else {
          _this9.$message({
            type: "error",
            message: res.errorMsg,
            center: true
          });
        }
      });
    },
    keep: function keep() {
      var _this10 = this;
      this.$refs.changeForm.validate(function (valid) {
        if (valid) {
          _this10.isDisable = true;
          _this10.loading = true;
          (0, _waybillInfo.changeList)({
            id: _this10.id,
            orderNo: _this10.changeForm.orderNo,
            remark: _this10.changeForm.remark,
            type: _this10.changeForm.type,
            inTax: _this10.changeForm.inTax,
            outTax: _this10.changeForm.outTax
          }).then(function (res) {
            if (res.success) {
              _this10.$message({
                type: "success",
                message: "修改成功",
                center: true
              });
              _this10.loading = false;
              _this10.isDisable = false;
              _this10.dialogFormVisible = false;
              _this10.$refs.changeForm.resetFields();
              _this10.getList();
            } else {
              _this10.$message({
                type: "error",
                message: res.errorMsg,
                center: true
              });
              _this10.loading = false;
              _this10.isDisable = false;
            }
          });
        }
      });
    },
    //删除
    dele: function dele() {
      var _this11 = this;
      if (this.multipleSelection.length < 1) {
        this.msgTip("warning", "至少选择一条记录！");
      } else {
        this.$confirm("您是否要删除当前选中的记录?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          (0, _waybillInfo.deleLists)({
            idList: _this11.idList
          }).then(function (res) {
            if (res.success) {
              _this11.msgTip("success", "操作成功");
              _this11.getList();
            } else {
              _this11.msgTip("error", res.errorMsg);
            }
          });
        });
      }
    },
    //撤销
    undo: function undo() {
      this.dialogImportForm = true;
      this.title = "撤销";
      this.uploadFlag = false;
      this.keepFlag = true;
    },
    keeps: function keeps() {
      var _this12 = this;
      if (this.importForm.billDate == "" || this.importForm.logisticsName == "") {
        this.msgTip("error", "请填写完整！");
        return false;
      } else {
        (0, _waybillInfo.undoList)({
          logisticsName: this.importForm.logisticsName,
          billDate: this.importForm.billDate
        }).then(function (res) {
          if (res.success) {
            _this12.msgTip("success", "操作成功");
            _this12.dialogImportForm = false;
            _this12.getList();
          } else {
            _this12.msgTip("error", res.errorMsg);
          }
        });
      }
    },
    //分页
    changepage: function changepage(currentPage) {
      this.currentPage = currentPage;
      this.form.pageNo = currentPage;
      this.forms.pageNo = currentPage;
      this.getList();
    },
    //弹框封装
    msgTip: function msgTip(msgType, msgInfo) {
      this.$message({
        type: msgType,
        message: msgInfo,
        center: true
      });
    }
  }
};