"use strict";

var _interopRequireDefault = require("/data/www/prod-cn-snote-cms/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Diclist = Diclist;
exports.addList = addList;
exports.aloneList = aloneList;
exports.changeList = changeList;
exports.deleList = deleList;
exports.exportArea = exportArea;
exports.exportAreaProduct = exportAreaProduct;
exports.exportCountry = exportCountry;
exports.exportDealer = exportDealer;
exports.exportDealerProduct = exportDealerProduct;
exports.exportDetail = exportDetail;
exports.exportDetailProduct = exportDetailProduct;
exports.exportLamy = exportLamy;
exports.exportSpecialsDealer = exportSpecialsDealer;
exports.exportSum = exportSum;
exports.getLists = getLists;
exports.searchList = searchList;
var _request = _interopRequireDefault(require("@/utils/request"));
//查询第三方接口
function getLists(data) {
  return (0, _request.default)({
    url: 'order/query/area',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}

//查询第三方订单数据详细信息
function searchList(data) {
  return (0, _request.default)({
    url: 'order/record/line/orderno',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    timeout: 1800000,
    data: data
  });
}

//录入第三方订单数据详细信息
function addList(data) {
  return (0, _request.default)({
    url: 'order/add/record',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//境内外销售表
function exportArea(data) {
  return (0, _request.default)({
    url: 'order/export/area/new',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}
//各渠道报表
function exportDealer(data) {
  return (0, _request.default)({
    url: 'order/export/dealer/new',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}

//渠道结算报表
function exportSum(data) {
  return (0, _request.default)({
    url: 'order/export/dealer/sum/new',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}
//销量汇总-国家/地区
function exportCountry(data) {
  return (0, _request.default)({
    url: 'order/export/country/new',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}
//销量汇总-国家/地区
function exportDetail(data) {
  return (0, _request.default)({
    url: 'order/export/dealer/detail',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}

//lamy报表
function exportLamy(data) {
  return (0, _request.default)({
    url: 'order/export/lamy',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}

//删除
function deleList(data) {
  return (0, _request.default)({
    url: 'order/area',
    method: 'DELETE',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据ID查询订单详细信息
function aloneList(data) {
  return (0, _request.default)({
    url: 'order/record/line',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//根据参数模糊查询接口
function Diclist(data) {
  return (0, _request.default)({
    url: 'order/dictionary/series',
    method: 'post',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//修改订单接口
function changeList(data) {
  return (0, _request.default)({
    url: 'order/record',
    method: 'put',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    data: data
  });
}

//境内外单品统计表
function exportAreaProduct(data) {
  return (0, _request.default)({
    url: 'order/export/product/statistics/area',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}
//各渠道单品统计表
function exportDealerProduct(data) {
  return (0, _request.default)({
    url: 'order/export/product/statistics/dealer',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}

//单品销售明细
function exportDetailProduct(data) {
  return (0, _request.default)({
    url: 'order/export/product/statistics/detail',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}

//US-RATTA、日亚备货统计
function exportSpecialsDealer(data) {
  return (0, _request.default)({
    url: 'order/export/specials/dealer',
    method: 'POST',
    headers: {
      "x-access-token": window.localStorage.getItem('token')
    },
    responseType: 'blob',
    timeout: 360000,
    data: data
  });
}